import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { connect } from 'react-redux';
import { setCBMdata, setCBMtype } from '@/redux/actions/cbm'
import { withRouter } from 'react-router-dom';
import { userCheckDevice, deviceDetail } from '@/redux/actions/device';


class AnnuallifemaintenanceNoline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesData: {},
            CBMtype: this.props.getCBMtype,
            lifedata: {
                visible: false
            },
            cbmdata: props.cbmdata
        };
    }

    componentDidMount() {
        // this.fnCBMdata();
        this.chartsData(this.state.cbmdata, this.state.CBMtype);

    }
    
    componentDidUpdate(nextProps, prevState) {
        if (prevState.CBMtype !== this.props.getCBMtype) {
            this.setState({
                CBMtype: this.props.getCBMtype
            }, () => {
                this.chartsData(this.props.getCBMdata, this.props.getCBMtype)
            })
        }
    }
    chartsData = (data, type) => {

        if (data !== "") {
            let resdata = data.data;
            resdata.map(item => {
                if (item.unitType === type) {
                    this.optiondata(item);
                }

            })
        }

    }
    starttime = (starttime) => {
        let date = new Date(starttime);
        let year = date.getFullYear();
        let _year = Number(year);
        let newdate = _year + "-01-01";
        let _startPointDate = Date.parse(newdate);
        return _startPointDate;
    }

    endtime = (endtime) => {
        let date = new Date(endtime);
        let year = date.getFullYear();
        let _year = Number(year) + 1;
        let newdate = _year + "-01-01";

        let _endPointDate = Date.parse(newdate);
        
        return _endPointDate;
    }

    datetime = (starttime, endtime) => {

        let _endPointDate = this.endtime(endtime);

        let _startPointDate = this.starttime(starttime);

        let total = (_endPointDate - _startPointDate) / 1000 / 60 / 60 / 24;

        return total;
    }
    optiondata = (item) => {
        let xAxisDatanum = [];
        let activationDate = Date.parse(item.activationDate);//绿色
        let predictedStartPlanDate = Date.parse(item.predictedStartPlanDate);//黄
        let predictedRunDate = Date.parse(item.predictedRunDate);//红色
        let startPointDate = Date.parse(item.startPointDate);//色
        let endPointDate = Date.parse(item.endPointDate);//色
        
        let starttime = this.starttime(item.startPointDate);

        
        let redbar = 0;
        let yellowbar = 0;
        let greenbar = 0;
        let graybar = 0;


        if (item.isExistRunPreventivePlan === true) {
            redbar = (endPointDate - starttime) / 1000 / 60 / 60 / 24;
            if(item.isExistStartPreventivePlan == true)
            {
                yellowbar = (predictedRunDate - starttime) / 1000 / 60 / 60 / 24;
                if(item.isExistNoNeedPreventivePlan == true)
                {
                    greenbar = (predictedStartPlanDate - starttime) / 1000 / 60 / 60 / 24;
                    graybar = (activationDate - starttime) / 1000 / 60 / 60 / 24;
                }
                else
                {
                    graybar = (predictedStartPlanDate - starttime) / 1000 / 60 / 60 / 24;
                }
            }
            else
            {
                graybar = (predictedRunDate - starttime) / 1000 / 60 / 60 / 24;
            }
            
        }
        else 
        {
            graybar = (endPointDate - starttime) / 1000 / 60 / 60 / 24; 
        }

        let firstdate = Math.ceil((new Date(startPointDate) - new Date(new Date(item.startPointDate).getFullYear().toString())) / (24 * 60 * 60 * 1000)) + 1;

        let RulPointsdate = [0, graybar, greenbar, yellowbar, redbar];
        // let RulPointsdate = [0,graybar]
        let total = this.datetime(item.startPointDate, item.endPointDate);

        xAxisDatanum = Array.from(new Array(total).keys())


        let xAxisData = []

        var DateYearstart = new Date(startPointDate);
        var startyear = DateYearstart.getFullYear();

        var DateYearend = new Date(endPointDate);
        var endtyear = DateYearend.getFullYear();


        for (let i = startyear; i <= endtyear + 1; i++) {
            xAxisData.push(i);
        }


        this.setState({

            seriesData: {
                title: {
                    text: '',
                    subtext: ''
                },
                tooltip: {
                    trigger: '',
                    showContent: false,
                    show: false
                },
                grid: {
                    left: '45px',
                    right: '15px',
                    top: '20px',
                    bottom: '0px',
                    containLabel: true
                },

                xAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            show: false,
                            formatter: "{value}",
                            color: '#686868',
                            interval: 0,
                        },
                        max: total,
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        // interval: 0,
                        data: xAxisDatanum,
                        // splitNumber: 20
                    },
                    {
                        type: 'category',
                        data: xAxisData,
                        boundaryGap: false,
                        position: "bottom",
                        axisLabel: {
                            show: false,
                            formatter: "{value}",
                            color: '#686868',
                            interval: 0,
                        },
                        max: total,
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        // splitNumber: 20
                    }
                ],
                yAxis: {
                    type: 'category',
                    axisLabel: {
                        show: false
                    },
                    axisLine: {
                        show: false,
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                },
                series: [
                    {
                        name: 'data',
                        type: 'bar',
                        // stack: 'total',
                        barWidth: 30, //柱图宽度 
                        // label: {
                        //     show: true
                        // },
                        itemStyle: {
                            normal: {
                                borderColor: '#ffffff',
                                //这里是重点
                                color: '#F13040'
                            }
                        },
                        xAxisIndex: 0,
                        emphasis: {
                            focus: 'series'
                        },
                        data: [RulPointsdate[4]],
                        barGap: '-100%',
                        silent: true,

                    },
                    {
                        name: 'size',
                        type: 'bar',
                        // stack: 'total',
                        barWidth: 30, //柱图宽度 
                        // label: {
                        //     show: true
                        // },
                        itemStyle: {
                            normal: {
                                borderColor: '#ffffff',
                                //这里是重点
                                color: '#FFD800'
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        xAxisIndex: 0,
                        data: [RulPointsdate[3]],
                        barGap: '-100%',
                        silent: true
                    },
                    {
                        name: 'name',
                        type: 'bar',
                        // stack: 'total',
                        barWidth: 30, //柱图宽度 
                        // label: {
                        //     show: true
                        // },
                        itemStyle: {
                            normal: {
                                borderColor: '#ffffff',
                                //这里是重点
                                color: '#477A43'
                            }
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        xAxisIndex: 0,
                        data: [RulPointsdate[2]],
                        barGap: '-100%',
                        silent: true
                    },
                    {
                        name: 'Direct',
                        type: 'bar',
                        // stack: 'total',
                        barWidth: 30, //柱图宽度 
                        itemStyle: {
                            normal: {
                                borderColor: '#ffffff',
                                //这里是重点
                                color: '#BABABA'
                            }
                        },
                        // label: {
                        //     show: true
                        // },
                        emphasis: {
                            focus: 'series'
                        },
                        xAxisIndex: 0,
                        data: [RulPointsdate[1]],
                        barGap: '-100%',
                        silent: true
                    },
                    {
                        name: 'life',
                        type: 'bar',
                        // stack: 'total',
                        barWidth: 45, //柱图宽度 
                        itemStyle: {
                            normal: {
                                borderColor: '#ffffff',
                                //这里是重点
                                color: '#ffffff'
                            }
                        },
                        // label: {
                        //     show: true
                        // },
                        emphasis: {
                            focus: 'series'
                        },
                        xAxisIndex: 0,
                        data: [firstdate],
                        barGap: '-100%',
                        silent: true
                    }
                ]
            }
        })


    }

    render() {
        return (

            <div className="charts_flex" style={{ minHeight: 80 }}>
                <div className="charts_flex_left_annuallife" style={{ height: 80 }}>
                    <ReactEcharts
                        option={this.state.seriesData}
                        style={{ height: '100%', width: '100%' }}
                        opts={{ renderer: 'svg' }}
                        className='react_for_echarts'
                    />
                </div>

                <div className="charts_flex_right">

                </div>
            </div>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setCBMdata: data => {
        dispatch(setCBMdata(data))
    },
    setCBMtype: data => {
        dispatch(setCBMtype(data))
    },
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AnnuallifemaintenanceNoline));
// export default AnnuallifemaintenanceNoline;