import React, { Component } from 'react';
import { Input, Table, Tooltip, message, Row, Col, Spin } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { APICheckCMDDeviceInfo } from '@/api/tools'
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { flatGroup } from 'd3';


const icon_center = {
	display: 'inline',
	verticalAlign: 'middle'
};
const div_nowrap = {
	margin: '0 10px 0px 0px',
	display: 'inline'
};

const { Search } = Input;
class CheckCMD extends Component {
	state = {
		pagination: {},
		loading: false,
		modal2Visible: false,
		selectedRowKeys: [],
		pageindex: 1,
		pageSize: 10,
		location: '###',
		trendData: [],
		eventData: [],
		deviceData: [],
		conditionData: {
			Availability: "Unknown",
			Environment: "Unknown",
			Reliability: "Unknown",
			Stress: "Unknown"
		},
		conditionColor: {
			"Unknown": "#BABABA",
			"Good": "#0CA919",
			"Tolerable": "#FFD800",
			"Poor": "#F03040"
		},
		signal_columns: [
			{
				title: '状态',
				dataIndex: '',
				key: '1',
				width: '3%',
				render: (text, record, index) => {
					const state = this.state.trendData[index].status;
					return (
						<div style={div_nowrap}>
							{/* <span>{index + 1}</span> */}
							<ABB.Icon name="abb/information-circle-1" sizeClass="small" color={state === 1 ? 'green' : 'red'} style={icon_center} />
						</div>
					);
				}
			},
			{
				title: '信号类型',
				dataIndex: 'label',
				key: '2',
				width: '5%'
			},
			{
				title: '数值',
				dataIndex: 'value',
				key: '3',
				width: '5%'
			},
			{
				title: '单位',
				dataIndex: 'unit',
				key: '4',
				width: '5%'
			},
			{
				title: '最新数据时间',
				dataIndex: 'datetime',
				key: '5',
				width: '40%'
			}
		],
		event_columns: [
			{
				title: '编号',
				dataIndex: '',
				key: '1',
				width: '3%',
				render: (text, record, index) => {
					// const state = this.state.mock_data[index].status;
					return <>{index + 1}</>;
				}
			},
			{
				title: '事件类型',
				dataIndex: 'type',
				key: '2',
				width: '5%'
			},
			{
				title: '事件',
				dataIndex: 'event_name',
				key: '3',
				width: '35%'
			},
			{
				title: '事件发生时间',
				dataIndex: 'utc_date',
				key: '4',
				width: '25%'
			}
		],
		device_columns: [
			{
				title: '编号',
				dataIndex: '',
				key: '1',
				width: '3%',
				render: (text, record, index) => {
					// const state = this.state.mock_data[index].status;
					return <>{index + 1}</>;
				}
			},
			{
				title: '来源',
				dataIndex: 'source',
				key: '2',
				width: '10%'
			},
			{
				title: '创建时间',
				dataIndex: 'create_time',
				key: '3',
				width: '35%'
			}
			
		],

	};

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}


	// 搜索数据
	onserch = value => {
		this.setState({
			loading: true
		})
		this.fetch(value.replace(/\s*/g, ''));
	};
	//接口数据
	fetch = (value) => {
		let url = `?drive_sn=${value}`;
		let that = this;

		APICheckCMDDeviceInfo(url)
			.then(res => {
				if (res !== undefined) {

					let resdata = res.data.results;
					resdata.trends.forEach((item, index) => {
						item.datetime = item.datetime ? moment.parseZone(item.datetime).local().format('YYYY-MM-DD HH:mm:ss') : '-';
						item.status = item.value === null ? 0 : 1;


					});
					resdata.events.forEach((item, index) => {
						item.utc_date = item.utc_date ? moment.parseZone(item.utc_date).local().format('YYYY-MM-DD HH:mm:ss') : '-';
						// item.state = item.value;

					});
					this.setState({

						trendData: resdata.trends,
						eventData: resdata.events,
						conditionData: resdata.conditions,
						deviceData: [{
							"source": resdata.source,
							"create_time": resdata.created_on ? moment.parseZone(resdata.created_on).local().format('YYYY-MM-DD HH:mm:ss') : '-'
						}],
						loading: false

					});
					// console.log(resdata);
					// console.log(this.state.deviceData);
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data.error.message);
				} else {
					message.error('查询CMD数据接口异常');
				}
				that.setState({
					trendData: [],
					eventData: [],
					conditionData: {
						Availability: "Unknown",
						Environment: "Unknown",
						Reliability: "Unknown",
						Stress: "Unknown"
					},
					loading: false
				})

			});
		// this.setState({
		// 	loading: false
		// })
	};

	render() {
		return (
			this.state.loading ? (
				<div style={{ textAlign: 'center', padding: '0 0' }}>
					<Spin spinning={this.state.loading} size="large">
						<div className="user_box ABB_tab_marginTop">
							<Search placeholder="输入序列号" onSearch={this.onserch} style={{ width: 190 }} className="search_input" />
						</div>
						{/* <div className="EUorg_title">
							<span>健康度指标</span>
						</div> */}
					</Spin>
				</div>
			) : (
				<>
					<div className="user_box ABB_tab_marginTop">
							<Search placeholder="输入序列号" onSearch={this.onserch} style={{ width: 190 }} className="search_input" />
					</div>
					<Row>
						<Col span={10}>
							<div className="EUorg_title">
								<span>健康度指标</span>
							</div>


							<Row>
								<Col span={3}>
									<div className="cmd_api_icon">
										<ABB.Icon style={{ color: this.state.conditionColor[this.state.conditionData.Availability] }} name="abb/warning-circle-2" sizeClass="large" />
									</div>
									<div className="cmd_api">
										<span style={{ fontSize: '14px' }}>可用性</span>
									</div>
								</Col>
								<Col span={3}>
									<>
										<div className="cmd_api_icon">
											<ABB.Icon style={{ color: this.state.conditionColor[this.state.conditionData.Environment] }} name="abb/temperature" sizeClass="large" />
										</div>
										<div className="cmd_api">
											<span style={{ fontSize: '14px' }}>环境</span>
										</div>
									</>
								</Col>
								<Col span={3}>
									<div className="cmd_api_icon">
										<ABB.Icon style={{ color: this.state.conditionColor[this.state.conditionData.Reliability] }} name="abb/pie-chart" sizeClass="large" />
									</div>
									<div className="cmd_api">
										<span style={{ fontSize: '14px' }}>可靠性</span>
									</div>
								</Col>
								<Col span={3}>
									<div className="cmd_api_icon">
										<ABB.Icon style={{ color: this.state.conditionColor[this.state.conditionData.Stress] }} name="abb/trend-3" sizeClass="large" />
									</div>
									<div className="cmd_api">
										<span style={{ fontSize: '14px' }}>运行压力</span>
									</div>
								</Col>
							</Row>


							<div className="EUorg_title">
								<span>最新信号数据（最近3天之内）</span>
							</div>
							<Table
								size="medium"
								scroll={{ x: 1140 }}
								columns={this.state.signal_columns}
								dataSource={this.state.trendData}
								pagination={false}

								rowKey={(record, index) => index}
							/>
						</Col>
						<Col span={12} offset={1}>
							<div className="EUorg_title">
								<span>设备信息</span>
							</div>
							<Table
								size="small"
								scroll={{ x: 1140 }}
								columns={this.state.device_columns}
								dataSource={this.state.deviceData}
								pagination={false}

								rowKey={(record, index) => index}
							/>
							<div className="EUorg_title">
								<span>最新10条事件（最近7天之内）</span>
							</div>
							<Table
								size="small"
								scroll={{ x: 1140 }}
								columns={this.state.event_columns}
								dataSource={this.state.eventData}
								pagination={false}

								rowKey={(record, index) => index}
							/>
						</Col>
					</Row>
				</>
			)

		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckCMD));
