import React, { Component } from 'react';
import {
    Modal, Button, Input, message, Form, Col,
    Row, Checkbox
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import Column from 'antd/lib/table/Column';
import { batchAddDevice } from '@/api/device'
import { subscriptionInformation } from './../../../../../api/subscription';

class addDeviceList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: [],
            disabled: false,
            isZn_CH: false,
            orgId: '',
            modelProps: {
                visible: true,
                title: '',
                value: '',
                width: 354,
                centered: true,
                deviceGroupName: '',
                movegrouplist: []
            },
            indeterminate: true,
            checkAll: false,
            checkoutList: [],
            deviceList: [],
            isAddSuccess: false,
            addSuccessDeviceList: []
        };
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    getDeviceList = (data, orgId) => {
        console.log("getDeviceList", data)
        let checkoutList = []
        data.map((item, index) => {
            checkoutList.push(index)
        })
        this.setState({
            orgId: orgId,
            deviceList: data,
            checkoutList: checkoutList
        })
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
    }
    submitData = () => {
        let euId = this.state.orgId;
        let data = []
        if (this.state.inputval.length <= 0) {
            message.warning("请选择要添加的设备！")
            return false
        }
        let hide = message.loading('批量添加中...', 0);
        this.state.inputval.map((item) => {
            if (item < this.state.deviceList.length) {
                const device = this.state.deviceList[item]
                let submitDevice = {
                    "sn": device.sn,
                    "name": device.name,
                    "applyType": device.applyType,
                    "address": device.address
                }
                data.push(submitDevice)
            }
        })
       
        batchAddDevice(euId, data).then((res) => {
            if (res.data.code === 200) {
                hide()
                this.setState({
                    isAddSuccess: true,
                    addSuccessDeviceList: res.data.data
                })
            }
            else {
                hide()
            }

        }).catch(err => {
            if (err.status === 400) {
                message.warning(err.data)
            }
            else if (err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('添加失败')
            }
            hide()
        })
    }
    submitModel = () => {
        this.props.submitmodel()
        this.setState({
            isAddSuccess: false
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
            }
        });
    };
    onCheckAllChange = (e) => {
        let { checkoutList } = this.state;
        this.setState({
            inputval: e.target.checked ? checkoutList : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    }
    onChange = (e) => {
        console.log("111111111", e)
        this.setState({
            inputval: e
        })
    }

    render() {
        const that = this;
        const { showassetGroupmodel, disabled } = this.props;
        const { getFieldDecorator } = that.props.form;
        const { deviceList, isAddSuccess, addSuccessDeviceList } = that.state;

        let modelProps = {
            title: "批量添加",
            maskClosable: false,
            centered: true,
            width: 756,
            visible: showassetGroupmodel.visible,
        }
        if (isAddSuccess === false) {
            modelProps = {
                ...modelProps,
                onOk: () => { that.hidemodel(false) },
                onCancel: () => { that.hidemodel(false) }, footer: [
                    <ABB.Button
                        onClick={() => that.hidemodel(false)}
                        type="discreet-black"
                        sizeClass='small' theme="filled"
                        text="取消"
                    />,
                    <ABB.Button
                        disabled={disabled}
                        onClick={() => that.submitData()}
                        type="primary-blue"
                        sizeClass='small' theme="filled"
                        text="添加"
                    />,
                ]
            }
        }
        else {
            modelProps = {
                ...modelProps,
                onOk: () => { that.submitModel() },
                onCancel: () => { that.submitModel() }, footer: [
                    <ABB.Button
                        disabled={disabled}
                        onClick={() => that.submitModel()}
                        type="primary-blue"
                        sizeClass='small' theme="filled"
                        text="确定"
                    />,
                ]
            }
        }

        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                {
                    isAddSuccess === false ? (
                        <div>
                            <Checkbox

                                indeterminate={this.state.indeterminate}
                                onChange={this.onCheckAllChange}
                                checked={this.state.checkAll}
                            >
                                <span className='CheckAll'>全选</span>
                            </Checkbox>

                            <Checkbox.Group
                                value={this.state.inputval}
                                style={{ width: '100%' }}
                                onChange={that.onChange}
                            >
                                {
                                    deviceList.map((item, index) => {
                                        return (
                                            <Row className='add_device_list'>
                                                <Col span={1}>
                                                    <Checkbox value={index}></Checkbox>
                                                </Col>
                                                <Col span={7}>
                                                    <div className='add_device_text'>传动名称：{item.name}</div>
                                                </Col>
                                                <Col span={7}>
                                                    <div className='add_device_text'>传动序列号：{item.sn}</div>
                                                </Col>
                                                <Col span={4}>
                                                    <div className='add_device_text'>网关类型：{item.applyType}</div>
                                                </Col>
                                                <Col span={5}>
                                                    <div className='add_device_text'>位置：{item.address}</div>
                                                </Col>
                                            </Row>
                                        )
                                    })
                                }


                            </Checkbox.Group>
                        </div>
                    ) : (
                        <div>
                            {
                                addSuccessDeviceList.map((item, index) => {
                                    return (
                                        <Row className='add_device_list'>
                                            {
                                                item.result.code === 200 ? (
                                                    <>
                                                        <Col span={1} style={{ paddingTop: '2px' }}>
                                                            <ABB.Icon style={{ color: '#0CA919' }} name="abb/check-mark-circle-1" sizeClass='small' />
                                                        </Col>
                                                        <Col span={7}>
                                                            <div className='add_device_text'>传动名称：{item.result.data.name}</div>
                                                        </Col>
                                                        <Col span={7}>
                                                            <div className='add_device_text'>传动序列号：{item.result.data.serialNumber}</div>
                                                        </Col>
                                                        <Col span={4}>
                                                            <div className='add_device_text'>网关类型：{item.result.data.gatewayType}</div>
                                                        </Col>
                                                        <Col span={5}>
                                                            <div className='add_device_text'>位置：{item.result.data.address}</div>
                                                        </Col>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Col span={1} style={{ paddingTop: '2px' }}>
                                                            <ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='small' />
                                                        </Col>
                                                        <Col span={7}>
                                                            <div className='add_device_text'>传动名称：{item.name}</div>
                                                        </Col>
                                                        <Col span={7}>
                                                            <div className='add_device_text'>传动序列号：{item.sn}</div>
                                                        </Col>
                                                         <Col span={9}>
                                                            <div className='add_device_text'>失败原因：{item.result.errorMessage}</div>
                                                        </Col>
                                                        
                                                    </>
                                                )
                                            }
                                            {/* <Col span={1} style={{paddingTop: '2px'}}>
                                                {
                                                    item.result.code === 200 ?
                                                        <ABB.Icon style={{ color: '#0CA919' }} name="abb/check-mark-circle-1" sizeClass='small' />
                                                        :
                                                        <ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='small' />
                                                }

                                            </Col>
                                            <Col span={7}>
                                                <div className='add_device_text'>传动名称：{item.data.name}</div>
                                            </Col>
                                            <Col span={7}>
                                                <div className='add_device_text'>传动序列号：{item.data.serialNumber}</div>
                                            </Col>
                                            <Col span={4}>
                                                <div className='add_device_text'>网关类型：{item.data.applyType}</div>
                                            </Col>
                                            <Col span={5}>
                                                <div className='add_device_text'>位置：{item.data.address}</div>
                                            </Col> */}
                                        </Row>
                                    )
                                })
                            }
                        </div>
                    )
                }



            </Modal>
        );
    }
}
const AddDeviceListScreen = Form.create({ name: 'register' })(addDeviceList);

export default AddDeviceListScreen;
