import * as actionTypes from '../constants/index';
const breadCrumbState = localStorage.getItem('breadCrumb') ? JSON.parse(localStorage.getItem('breadCrumb')) : { show: false };
const tagsState = localStorage.getItem('tags') ? JSON.parse(localStorage.getItem('tags')) : { show: false };
const themeState = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : { type: 'dark' };
const _setlanguage = localStorage.getItem('language') ? JSON.parse(localStorage.getItem('language')) : { type: 'zh-CN' };
const _getIsSignalShowthree = 2;
const _getNewsmodelIsShow = {};
const _getRouterLinkIndex = 0;
const breadCrumb = (state = breadCrumbState, action) => {
	switch (action.type) {
		case actionTypes.SET_BREADCRUMB:
			return action.data;
		default:
			return state;
	}
};
const tags = (state = tagsState, action) => {
	switch (action.type) {
		case actionTypes.SET_TAGS:
			return action.data;
		default:
			return state;
	}
};
const theme = (state = themeState, action) => {
	switch (action.type) {
		case actionTypes.SET_THEME:
			return action.data;
		default:
			return state;
	}
};
const collapse = (state = { isCollapsed: false }, action) => {
	switch (action.type) {
		case actionTypes.SET_COLLAPSE:
			return action.data;
		default:
			return state;
	}
};

const isloading = (state = false, action) => {
	switch (action.type) {
		case actionTypes.LOADING:
			return action.data;
		default:
			return state;
	}
};

const setlanguage = (state = _setlanguage, action) => {
	switch (action.type) {
		case actionTypes.LOADING:
			return action.data;
		default:
			return state;
	}
};

const getIsSignalShowthree = (state = _getIsSignalShowthree, action) => {
	switch (action.type) {
		case actionTypes.SIGNAL_THREE:
			return action.data;
		default:
			return state;
	}
};

const getNewsmodelIsShow = (state = _getNewsmodelIsShow, action) => {
	switch (action.type) {
		case actionTypes.NEWS_MODELISSHOW:
			return action.data;
		default:
			return state;
	}
};

const getRouterLinkIndex = (state = _getRouterLinkIndex, action) => {
	switch (action.type) {
		case actionTypes.ROUTER_LINK:
			return action.data;
		default:
			return state;
	}
};
export { getRouterLinkIndex, getNewsmodelIsShow, breadCrumb, tags, theme, collapse,isloading,setlanguage,getIsSignalShowthree };
