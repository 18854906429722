import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import { signinSignupConfig, signinSignupParameter, signinSignupOption } from "@/lib/PhonePwdConfig";
import { WechatLoginConfig, WechatLoginParameter, WechatLoginOption } from "@/lib/WechatLoginConfig";
import {
    MyABBLoginConfig,
    MyABBLoginParameter,
    MyABBLoginOption
} from "@/lib/MYABBConfig";
import {
    PhonePwdLessConfig,
    PhonePwdLessParameter,
    PhonePwdLessOption
} from "@/lib/PhonePwdLessConfig";

// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
var authProvider = null;


export function setAuthProvider(provider){
    authProvider = provider
}
export function getAuthProvider() {
    console.log("authProvider",authProvider)
    let loginWith = localStorage.getItem('loginWith');
        console.log("loginWith", loginWith)
    // if (authProvider === null) {
        
        if (loginWith === "wechat") {
            //console.log("login from wechat");
            authProvider = new MsalAuthProvider(
                WechatLoginConfig,
                WechatLoginParameter,
                WechatLoginOption
            );
        }
        else if (loginWith === "PhonePwdLess") {
            //console.log("login from native");
            authProvider = new MsalAuthProvider(
                PhonePwdLessConfig,
                PhonePwdLessParameter,
                PhonePwdLessOption
            );
        }
        else if (loginWith === "MYABBConfig") {
            console.log("login from MYABBConfig");
            authProvider = new MsalAuthProvider(
                MyABBLoginConfig,
                MyABBLoginParameter,
                MyABBLoginOption
            );
        }
        else {
            console.log("login from native");
            authProvider = new MsalAuthProvider(
                signinSignupConfig, signinSignupParameter, signinSignupOption
            );
        }
    // }
    
    return authProvider;
}

export function globalSetParameter(para) {
    if (authProvider === null) {
        //console.log("initialize set parameter");
        authProvider = new MsalAuthProvider(
            signinSignupConfig,
            signinSignupParameter,
            signinSignupOption
        );
    }
    authProvider.setAuthenticationParameters(para);
}
