import React, { Component } from 'react';
import { Modal,Button,Input,Radio,Row, Col,message } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
const { Search } = Input;
class Movegroup extends Component {
	constructor(props) {
		super(props);
		this.state = {
            
            inputval: [],
            
		};
		
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    hidemodel=(data)=>{
        this.props.hidemodel(data)
        this.setState({
            inputval: ''
        })
    }
    submitmodel=()=>{
        let data = this.state.inputval;
        if(data!==''){
            this.props.submitmodel(data)
            this.setState({
                inputval: '',
            })
        }
        else{
            message.warning("请选择设备组！")
        }
    }
    
    onChange=(e)=>{
        this.setState({
            inputval: e.target.value,
        })
    }
	render() {
        const that = this;
        const {showmodelMove} = this.props;
        
        const modelProps = {
            title: showmodelMove.title,
            centered: showmodelMove.centered,
            width:showmodelMove.width,
            visible:showmodelMove.visible,
            maskClosable: false,
            onOk:() => {that.hidemodel(false)},
            onCancel:() => {that.hidemodel(false)},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,          
            ]
        }
		return (
			<Modal {...modelProps}
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                
                <Radio.Group
                value={this.state.inputval}
                style={{ width: '100%',minHeight:100,maxHeight:200,overflow:'auto' }} onChange={that.onChange}>
                    <Row>
                    
                                
                    {
                        showmodelMove.movegrouplist.length>0 ? (
                            showmodelMove.movegrouplist.map((item,index)=>{
                                return (
                                    <Col span={24} key={index}>
                                        <Radio
                                        
                                        value={item.id}>{item.name}</Radio>
                                    </Col>
                                )
                            })
                        ): <div className="nodatagroup">暂无可移动设备组</div>
                        
                    }
                    
                    </Row>
                </Radio.Group>
                
            </Modal>
		);
	}
}

export default Movegroup;
