import React, { Component } from 'react';
import { Input, Table, Tooltip, message, Popover } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Search } = Input;

class StatusDescription extends Component {
	state = {};

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	render() {
		const info = (
			<>
				<div>灰色表示：未知 红色表示：故障 绿色表示：连接 黄色表示：维护中</div>
			</>
		);
		return (
			<>
				<Popover placement="topLeft" content={info} title={'说明'}>
					<span>云服务运行状态</span>
					<ABB.Icon name="abb/information-circle-1" sizeClass="small" showTag={'green'} />
				</Popover>
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StatusDescription));
