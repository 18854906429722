import React, { Component } from 'react';
import { Modal,Button,Input,Icon } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Search } = Input;
class AuthorizationText extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputval: ''
		};
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    hidemodel=(data)=>{
        this.props.hidemodel(data)
    }
    submitmodel=(data)=>{
        this.props.submitmodel(data)
    }
    onChange=(e)=>{
        this.setState({
            inputval: e.target.value
        })
    }
	render() {
        const that = this;
        const {showmodel} = this.props;
        const modelProps = {
            title: showmodel.title,
            centered: showmodel.centered,
            width:showmodel.width,
            visible:showmodel.visible,
            maskClosable: false,
            onOk:() => {that.hidemodel(false)},
            onCancel:() => {that.hidemodel(false)},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(that.state.inputval)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,           
            ]
        }
		return (
            <Modal {...modelProps}
            style={{minHeight: 504,overflow:"auto"}}
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                {/* 客户有权选择是否授权ABB渠道伙伴配合ABB共同对客户进行服务。 */}
                <br/>
                1. ABB授权服务商为ABB官方认证的辅助服务提供方，客户通过对ABB服务商进行授权，可以在ABB所提供服务资源的基础上获得额外的服务资源保障。
                <br/>
                2. 经过客户授权的ABB服务商可以访问客户在ABB Portal服务范围内的传动和（或）Smart Sensor所采集的数据，对此数据进行数据分析并以此作为对客户服务内容的一部分；传动和Smart Sensor 所采集的数据包括传动和电机的运行数据（例如电压、电流、输出频率、电机转速）、状态数据（例如电机温度）、告警和故障信息。
                <br/>
                3. 由于传动和（或） Smart Sensor所采集数据可能存在测量误差，所采集数据存在无法准确反映设备状态的可能性，客户设备的实际运行状态与“数据分析”结果可能存在差异性; 经客户授权的ABB服务商对传动和（或） Smart Sensor所采集数据所做的“数据分析”仅用作对客户进行决策辅助指导和支持；ABB Portal 所服务客户的传动和（或）电机，仅能通过传动和（或）电机所在现场的客户方人员执行具体操作过程。
            </Modal>
		);
	}
}

export default AuthorizationText;
