//编辑数字化连接调试信息
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Form,
    Input,
    Modal,
    message,
    Col,
    Row,
    Spin,
    Select
} from 'antd';

import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { APISyncDebuginfo } from '@/api/device';
import './style.scss'
import { setDebugInformationRemindConfig } from '../../store/actionCreators';

class RemindModel extends React.Component {
    state = { disabled: false };

    hidemodel = (data) => {

        this.props.form.resetFields()

        console.log("DebuggingInformationModel  click: 显示提示信息")
        const config = { ...this.props.getDebugInformationRemindConfig }
        config.visible = false

        this.props.updateDebugInformationRemindConfig(config)
        this.props.hidemodel(this.props.showmodelboundsub)

    }

    submitmodel = (data) => {
        console.log("submitmodel=============", data)
        var param = {
            "deviceSn": data.deviceData.deviceSn, //源设备序列号
            "syncPart": data.type, //同步数据类型 1-NETA21信息 2-路由器信息
        }

        APISyncDebuginfo(param).then(res => {
            console.log("url============设置服务方式为一键呼叫===", res)
            let resdata = res.data;

            if (resdata !== undefined) {
                this.hidemodel()

            }
        }).catch(err => {
            console.log("err============", err)
            this.setState({
                loading: false
            })
            if (err.data.code === 400 || err.data.code === 404) {
                message.warning(err.data.errorMessage)
            }
            else {
                message.error('设备详情接口异常')
            }
        })
    }



    // 4G路由器信息 / NETA-21信息 / 变频器信息
    _renderSectionHeaderByTitle = (isMust, title) => {
        return <Row >
            <div className='section_item'>
                {isMust === true ? (<div className='section_icon'></div>) : null}
                <div >{title}</div>
            </div>
        </Row>
    }

    _renderCenterMessage = (data) => {


        if (data.type === 1) { //neta21
            return <div className='remind_center_box'>


                系统检测到您修改了以下传动的数字化连接硬件中也用到了<span className='text_blue'>序列号为{data.deviceData.gatewaySn}的NETA-21</span>，您的修改也将<span className='text_blue'>同步修改以下传动的NETA-21调试信息</span>，请确认。

            </div>
        } else if (data.type === 2) { //路由器
            return <div className='remind_center_box'>


                系统检测到您修改了以下传动的数字化连接硬件中也用到了<span className='text_blue'>序列号为{data.deviceData.routerSerialNo}的4G路由器</span>，您的修改也将<span className='text_blue'>同步修改以下传动的4G路由器信息</span>，请确认。

            </div>
        }



        // text_blue
    }

    render() {
        let that = this;

        const { showmodelboundsub } = that.props;

        // {
        //     "routerSerialNo": "routercode",
        //     "gatewaySn": "8260209",
        //     "devices": [
        //         {
        //             "matchType": 1,
        //             "matchList": []
        //         },
        //         {
        //             "matchType": 2,
        //             "matchList": []
        //         }
        //     ],
        //     "deviceSn": "2UBA958000854"
        // }

        console.log("remindshowmodelboundsub", showmodelboundsub)
        const modelProps = {
            title: "提示",
            centered: true,
            width: 550,
            maskClosable: false,
            visible: showmodelboundsub.visible,

            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelboundsub)}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="确认"
                />,
            ]
        }

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <div className='remind_sub_text'>传动序列号：{showmodelboundsub.deviceData.deviceSn}</div>
                    <div className='remind_sub_text'>NETA-21序列号：{showmodelboundsub.deviceData.gatewaySn}</div>

                    {this._renderCenterMessage(showmodelboundsub)}

                    <div className='scrollContent'>
                        {
                            showmodelboundsub.devices.map((item) => {
                                return (<div className='remind_item'>
                                    <ABB.Icon className="asset_icon" name="abb/drive" sizeClass='small' />
                                    {item.deviceName} ({item.deviceSn})</div>)
                            })
                        }
                    </div>
                </Modal>
            </>
        );
    }
}


const RemindModelForm = Form.create({ name: 'register' })(RemindModel);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateDebugInformationRemindConfig: data => {
        dispatch(setDebugInformationRemindConfig(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RemindModelForm));

