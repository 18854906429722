import $axios from '../axios/$axios';

export function EUequipment(data) {
   return $axios({
      url: '/api/v2/device/list' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function APIsingledeviceList(data) {
   return $axios({
      url: '/api/v2/device' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function batchAddDevice(id,data) {
   return $axios({
      url: '/api/v3/device/batch-add-to-eu/' + id,
      method: 'post',
      type: 'json',
      data: data
   })
}

export function hidecomplimentary() {
   return $axios({
      url: '/api/v2/userextention/hide-complimentary-subscription',
      method: 'post',
      type: 'json',
      data: ''
   })
}

export function uploadStoreFile(params) {
   return $axios({
      url: '/api/v3/excel-analysis/add-a-batch-of-device',
      method: 'post',
      type: 'form',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: params
   })
}

export function editInfo(assetid, isSubAsset) {
   return $axios({
      url: '/api/v3/device/edit-info?assetid=' + assetid + "&isSubAsset=" + isSubAsset,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function getApplyType(data) {
   return $axios({
      url: '/api/v3/device/apply-type' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function subscriptioncof(data) {
   return $axios({
      url: '/api/v1/device/subscriptioncof/' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function APImovegroup(data) {
   return $axios({
      url: '/api/v1/assetgroup/asset/move',
      method: 'put',
      type: 'json',
      data: data
   })
}

export function equipmentdetail(data) {
   return $axios({
      url: '/api/v3/device/detail' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function devicedetail(data) {
   return $axios({
      url: '/api/v3/device/profile' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function assetgroupsample(data) {
   return $axios({
      url: '/api/v2/assetgroup/list/sample/' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function eventlist(data) {
   return $axios({
      url: '/api/v1/event/by/device' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function eventlistfilter(data) {
   return $axios({
      url: '/api/v2/event/filter' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function createDevice(data) {
   return $axios({
      url: '/api/v2/assetgroup',
      method: 'post',
      type: 'json',
      data: data
   })
}

export function resolutionPowertrain(data) {
   return $axios({
      url: '/api/v1/device/powertrain' + data,
      method: 'delete',
      type: 'json',
      data: ''
   })
}

export function combinationPowertrain(data) {
   return $axios({
      url: '/api/v2/device/assetgroup/' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

//创建设备前校验订阅
export function checkCreateasset(data) {
   return $axios({
      url: '/api/v4/device/create/drive/verify',
      method: 'post',
      type: 'json',
      data: data
   })
}

//不使用订阅
export function unUseSubscriptionRequest(data) {
   return $axios({
      url: '/api/v4/device/create/drive/cancel',
      method: 'post',
      type: 'json',
      data: data
   })
}

//添加设备请求: 用户确认绑定订阅后保存设备信息 
export function  addDeviceRequest(data) {
   return $axios({
      url: '/api/v4/device/create/drive',
      method: 'post',
      type: 'json',
      data: data
   })
}


//创建设备
export function createasset(data) {
   return $axios({
      url: '/api/v2/device/asset/drive',
      method: 'post',
      type: 'json',
      data: data
   })
}

export function createPowertrain(data) {
   return $axios({
      url: '/api/v1/device/powertrain',
      method: 'post',
      type: 'json',
      data: data
   })
}

export function geteventDetail(data) {
   return $axios({
      url: '/api/v1/event' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function closeevent(data) {
   return $axios({
      url: '/api/v1/event/close',
      method: 'put',
      type: 'json',
      data: data
   })
}

export function getexportdevice(data) {
   return $axios({
      url: '/api/v2/device/asset/smartsenor/motor' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function getssmotor(data) {
   return $axios({
      url: '/api/v2/device/asset/motor/' + data,
      method: 'post',
      type: 'json',
      data: data
   })
}

export function exception(data) {
   return $axios({
      url: '/api/v2/device/asset/exception/' + data,
      method: 'get',
      type: 'json',
      data: data
   })
}

export function deleteasset(data) {
   return $axios({
      url: '/api/v1/device/asset' + data,
      method: 'delete',
      type: 'json',
      data: ''
   })
}

export function getmotor(data) {
   return $axios({
      url: '/api/v2/assetgroup/motor/list' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function setmotor(url, data) {
   return $axios({
      url: '/api/v2/assetgroup/motor/move/' + url,
      method: 'put',
      type: 'json',
      data: data
   })
}

export function editassetgroup(data) {
   return $axios({
      url: '/api/v2/assetgroup/edit',
      method: 'put',
      type: 'json',
      data: data
   })
}

export function editasset(data) {
   return $axios({
      url: '/api/v1/device/asset',
      method: 'put',
      type: 'json',
      data: data
   })
}

export function editassetNeta(data) {
   return $axios({
      url: '/api/v2/device/asset',
      method: 'put',
      type: 'json',
      data: data
   })
}

export function editdevice(data) {
   return $axios({
      url: '/api/v3/device/edit-info',
      method: 'put',
      type: 'json',
      data: data
   })
}
 

export function editdevicepowertrain(data) {
   return $axios({
      url: '/api/v3/device/powertrain/edit-info',
      method: 'put',
      type: 'json',
      data: data
   })
}

export function getOperatorLocation(sn) {
   return $axios({
      url: '/api/v4/device/' + sn + '/asset-operator-location',
      method: 'get',
      type: 'json',
      data: ''
   })
}


export function removeassetgroup(data) {
   return $axios({
      url: '/api/v2/assetgroup/remove' + data,
      method: 'delete',
      type: 'json',
      data: ''
   })
}

// 设备迁移组织
export function APIMoveOrgGroup(data) {
   return $axios({
      url: '/api/v4/device/device/move/org',
      method: 'post',
      type: 'json',
      data: data
   })
}

// 更新设备序列号
// /api/v1/admin/update/devicesn
export function APIUpdateDriveSN(data) {
   return $axios({
      url: '/api/v1/admin/update/devicesn',
      method: 'post',
      type: 'json',
      data: data
   })
}

//默认加载巡检数据接口

export function APIInspection(assetId,isSubAsset) {
   return $axios({
      url: '/api/v4/device-inspection?assetId=' + assetId + "&isSubAsset=" + isSubAsset,
      method: 'get',
      type: 'json',
      data: ''
   })
}

//创建巡检数据轮询任务接口
export function APICreateInspection(assetId,isSubAsset) {
   return $axios({
      url: '/api/v4/device-inspection',
      method: 'post',
      type: 'json',
      data: {
         "assetId": assetId,
         "isSubAsset": false
       },
       
   })
}


//巡检数据轮询接口
export function APIRefreshInspection(taskId) {
   return $axios({
      url: '/api/v4/device-inspection/task-inspection?taskId=' + taskId,
      method: 'get',
      type: 'json',
      data: '',
     
   })
}

 

export function APIGetContact(assetId,isSubAsset) {
   return $axios({
      url: '/api/v4/device/contacts?id=' + assetId + "&isSubAsset=" + isSubAsset,
      method: 'get',
      type: 'json',
      data: '',
      
   })
}

 

export function APISetContact(assetId,isSubAsset,data) {
   return $axios({
      url: '/api/v4/device/exceptservice/servicetype?id=' + assetId + "&isSubAsset=" + isSubAsset,
      method: 'post',
      type: 'json',
      data: data
   })
}

export function APISetOrgContact(orgId,data) {
   return $axios({
      url: "/api/v3/organization/" + orgId +"/contacts" ,
      method: 'post',
      type: 'json',
      data: data
   })
}


//一键呼叫

export function APICallPrompt(eventId) {
   return $axios({
      url: '/api/v4/device/exceptservice/manual-call-service?eventId=' + eventId,
      method: 'post',
      type: 'json',
      data: {}
   })
}

//根据路由器序列号查询路由器信息
export function APICheckRouterCode(routerSn) {
   return $axios({
      url: '/api/v4/device/debuginfo/router-info/' + routerSn,
      method: 'get',
      type: 'json',
      data: {}
   })
}

//更新传动调试信息
export function APIUpdateDebuginfo(devicesn,data) {
   return $axios({
      url: '/api/v4/device/debuginfo/' + devicesn,
      method: 'post',
      type: 'json',
      data: data
   })
}

//从调试信息中获取NETA21信息
export function APIGetNeta21Info(deviceSN) {
   return $axios({
      url: '/api/v4/device/debuginfo/' + deviceSN + '/neta21-info',
      method: 'get',
      type: 'json',
      data: ''
   })
}


//更新传动调试信息
export function APISyncDebuginfo(data) {
   return $axios({
      url: '/api/v4/device/debuginfo/sync',
      method: 'post',
      type: 'json',
      data: data
   })
}
