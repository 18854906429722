import * as actionTypes from './constants';


//一键呼叫弹框
const seCallPromptConfig = data => {
	return {
		type: actionTypes.SETTING_CALL_PROMPT,
		data
	};
};
 
const setSupportRecordConfig = data=>{
	return {
		type: actionTypes.SETTING_SUPPORT_RECORD,
		data
	};
}

//提醒用户调试信息改变配置
export { seCallPromptConfig,setSupportRecordConfig };