import React, { Component } from 'react';
import { Modal,Button,Input,Checkbox,Row, Col, message } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import NoData from '@/components/NoData'
const { Search } = Input;
class Authorization extends Component {
	constructor(props) {
		super(props);
		this.state = {
            checkoutList: [],
            inputval: [],
            indeterminate: true,
            checkAll: false,
		};
		
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    hidemodel=(data)=>{
        this.props.hidemodel(data)
        this.setState({
            inputval: [],
            indeterminate: true,
            checkoutList: [],
            checkAll: false,
        })
    }
    submitmodel=(data)=>{
        if(data.length>0){
            this.props.submitmodel(data)
            this.setState({
                inputval: [],
                indeterminate: true,
                checkoutList: [],
                checkAll: false,
            })
        }
        else{
            message.warning("还未选择")
        }
    }
    onCheckAllChange = e => {
        const {showmodelusergroup} = this.props;
        let {checked,checkoutList} = this.state;
        if(e.target.checked===true){
            showmodelusergroup.userGroups.unOwned.map((item,index)=>{
                this.setState({
                    checkoutList: checkoutList.push(item.id) 
                })
            })
        }
        else{
            this.setState({
                checkoutList: []
            })
        }
        this.setState({
            inputval: e.target.checked ? this.state.checkoutList : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
      };
    onChange=(e)=>{
        this.setState({
            inputval: e
        })
    }
	render() {
        const that = this;
        const {showmodelusergroup,disabled} = this.props;
        const modelProps = {
            title: showmodelusergroup.title,
            centered: showmodelusergroup.centered,
            width:showmodelusergroup.width,
            maskClosable: false,
            visible:showmodelusergroup.visible,
            onOk:() => {that.hidemodel(false)},
            onCancel:() => {that.hidemodel(false)},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(that.state.inputval)}
                    type="primary-blue"
                    disabled = {disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,          
            ]
        }
        
		return (
			<Modal {...modelProps}
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                {
                    showmodelusergroup.title === "授权客户到用户组" ? (
                        <div className='box_flex'>已授权客户</div>
                    ): (
                        <div className='box_flex'>已授权EU</div>
                    )
                }
                
                <div style={{ width: '100%',marginBottom:16 }} >
                    <Row>
                    {
                        showmodelusergroup.userGroups.owned.length>0 ? (
                            showmodelusergroup.userGroups.owned.map((item,index)=>{
                                return (
                                    <Col span={24} key={index}>
                                        <div className="ownIdlist">{item.name}</div>
                                    </Col>
                                )
                            })
                        ): ''
                    }
                    </Row>
                </div>
                {
                    showmodelusergroup.title === "授权客户到用户组" ? (
                        <div className='box_flex'>未授权客户</div>
                    ): (
                        <div className='box_flex'>未授权EU</div>
                    )
                }
                <Checkbox
                    indeterminate={this.state.indeterminate}
                    onChange={this.onCheckAllChange}
                    checked={this.state.checkAll}
                >
                    全选
                </Checkbox>
                <hr/>
                <Checkbox.Group 
                value={that.state.inputval}
                style={{ width: '100%',minHeight:100,maxHeight:200,overflow:'auto' }} onChange={that.onChange}>
                    <Row>
                    {
                        showmodelusergroup.userGroups.unOwned.length>0 ? (
                            showmodelusergroup.userGroups.unOwned.map((item,index)=>{
                                return (
                                    <Col span={24} key={index}>
                                        <Checkbox value={item.id}>{item.name}</Checkbox>
                                    </Col>
                                )
                            })
                        ): <NoData/>
                        
                    }
                    </Row>
                </Checkbox.Group>
                
            </Modal>
		);
	}
}

export default Authorization;
