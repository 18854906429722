import $axios from '../axios/$axios';

export function trendsCharts(data){
   return $axios({
    url: '/api/v3/trends',
    method: 'post',
    type: 'json',
    data: data
   })
}

export function trendsrawdata(data){
   return $axios({
      url: '/api/v3/trends',
      method: 'post',
      type: 'json',
      data: data
     })
}

export function applyHistoryData(data){
   return $axios({
      url: '/api/v3/device/migration/apply-more',
      method: 'post',
      type: 'json',
      data: data
     })
}
