import $axios from '../axios/$axios';

export function grantcustomerlist(data) {
   return $axios({
      url: '/api/v2/grantcustomer/customers'+data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function organizationslist(data) {
   return $axios({
      url: '/api/v2/grantcustomer/organizations'+data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function Addcustomers(data) {
   return $axios({
      url: '/api/v2/grantcustomer/customers',
      method: 'post',
      type: 'json',
      data: data
   })
}

export function postponegrant(data) {
   return $axios({
      url: '/api/v2/grantcustomer/customers/postponegrant',
      method: 'put',
      type: 'json',
      data: data
   })
}

export function cancelgrant(data) {
   return $axios({
      url: '/api/v2/grantcustomer/customers/cancelgrant',
      method: 'put',
      type: 'json',
      data: data
   })
}