import React, { Component } from 'react';
import { Input, Table, Select, message } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { APIUnconfiguredDeviceList, APICMDDeviceCheck, APICheckDriveSNNotExist } from '@/api/tools.js';
import { APIUpdateDriveSN } from '@/api/device'

import { APIQueryDebugingList } from '@/api/tools.js';
import { setDebugingList } from '../../store/actionCreators';
import DebuggingInformationModel from '../../../devicedetails/components/device/devicedetail/components/debuggingInformationModel';
import RemindModel from '../../../devicedetails/components/device/devicedetail/components/remindModel';
import { setDebugInformationConfig, setDebugInformationRemindConfig } from '../../../devicedetails/components/device/store/actionCreators';
const div_nowrap = {
	display: 'inline',
	margin: '0 30px 0 0'
};

const { Search } = Input;

class Neta21Debug extends Component {
	state = {
		showModel: {
			visible: false
		},
		pagination: {},
		loading: false,
		modal2Visible: false,
		selectedRowKeys: [],
		pageindex: 1,
		pageSize: 10,

		search: {
			type: 0,
			searchTitle: ''
		},
		columns: [
			{
				title: '设备名称',
				dataIndex: 'deviceName',
				key: '0',
				width: '10%'
			},
			{
				title: '传动序列号',
				dataIndex: 'deviceSerialNo',
				key: '1',
				width: '10%'
			},
			{
				title: '传动节点ID',
				dataIndex: 'deviceNodeId',
				key: '2',
				width: '10%'
			},
			{
				title: 'NETA-21序列号',
				dataIndex: 'netaSerialNo',
				key: '3',
				width: '15%'
			},

			{
				title: '路由器序列号',
				dataIndex: 'routerSerialNo',
				key: '4',
				width: '15%'
			},
			{
				title: '路由器MAC地址',
				dataIndex: 'routerMACAddress',
				key: '5',
				width: '10%'
			},
			{
				title: 'SIM卡ICCID',
				dataIndex: 'simiccid',
				key: '6',
				width: '10%'
			},
			{
				title: 'NETA-21 IP地址',
				dataIndex: 'netaIPAddress',
				key: '7',
				width: '10%'
			},
			{
				title: 'NETA-21登录账号',
				dataIndex: 'netaUserName',
				key: '8',
				width: '10%'
			},



			{
				title: '操作',
				dataIndex: 'operate',
				key: '9',
				width: '5%',
				render: (text, record, index) => {
					return (
						<>
							<div style={div_nowrap}>
								<span onClick={() => {

									this._updateDebuggingInformationConfig(record)

								}}>
									<ABB.Icon name="abb/edit" sizeClass='small' />
								</span>
							</div>
						</>
					);
				}
			}
		]
	};

	componentDidMount() {
		const {search} = this.state
		this._requestDebugingList(search.type,search.searchTitle)
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}

	_updateDebuggingInformationConfig = (item) => {
		let showDebuggingInformationConfig = { ...this.props.getDebugInformationConfig, }
		showDebuggingInformationConfig.visible = true
		showDebuggingInformationConfig.title = "编辑数字化连接调试信息"


		let ips = item.netaIPAddress.split(".")
		let defaultData = {}
		if (ips.length === 4) {
			defaultData = {
				netaIPAddress: item.netaIPAddress, //NETA IP地址
				netaUserName: item.netaUserName, //NETA UserName
				netaPassword: item.netaPassword, //NETA Password
				routerSerialNo: item.routerSerialNo, //路由器序列号
				routerPassword: item.routerPassword, //路由器密码
				routerMACAddress: item.routerMACAddress, //路由器MAC地址
				sIMICCID: item.simiccid, //SIM卡ICCID
				deviceVersion: item.deviceVersion, //设备版本号
				deviceNodeId: item.deviceNodeId,
				ip1: ips[0],
				ip2: ips[1],
				ip3: ips[2],
				ip4: ips[3]
			}
		} else {
			defaultData = {
				netaIPAddress: item.netaIPAddress, //NETA IP地址
				netaUserName: item.netaUserName, //NETA UserName
				netaPassword: item.netaPassword, //NETA Password
				routerSerialNo: item.routerSerialNo, //路由器序列号
				routerPassword: item.routerPassword, //路由器密码
				routerMACAddress: item.routerMACAddress, //路由器MAC地址
				sIMICCID: item.simiccid, //SIM卡ICCID
				deviceVersion: item.deviceVersion, //设备版本号
				deviceNodeId: item.deviceNodeId,
				ip1: "",
				ip2: "",
				ip3: "",
				ip4: ""
			}
		}
		showDebuggingInformationConfig.defaultData = defaultData

		showDebuggingInformationConfig = { ...showDebuggingInformationConfig, ...defaultData }

		if (showDebuggingInformationConfig.routerSerialNo.length > 0) {
			showDebuggingInformationConfig.checkRouterDisable = false
		} else {
			showDebuggingInformationConfig.checkRouterDisable = true
		}
		showDebuggingInformationConfig.serialNumber = item.deviceSerialNo //传动序列号
		showDebuggingInformationConfig.gatewaySn = item.netaSerialNo//NETA-21序列号 


		this.props.updateDebugInformationConfig(showDebuggingInformationConfig)
	}



	hidemodel = () => {

	}
	submitmodel = () => {

	}

	//接口数据
	_requestDebugingList = (type, searchTitle) => {

		this.setState({
			loading: true
		});
		APIQueryDebugingList(type, searchTitle)
			.then(res => {

				console.log("APIQueryDebugingList======================", res)

				if (res !== undefined) {
					let resdata = res.data;

					this.props.updateDebugingList(resdata.data)

				}
			})
			.catch(err => {
				console.log("APIQueryDebugingList=============err=========", err)
				if (err.data.status === 400 || err.data.status === 404) {
					message.warning(err.data.title);
				} else {
					message.error('列表接口异常');
				}
			});
	};

	//提示同步调试信息到设备
	_updateRemindConfig = (data, index) => {
		console.log('dataparam=========',data)

		if (data.devices.length < index + 1) {
			return
		}

		let typeData = data.devices[index]

		if (typeData.matchList.length < 1) {
			this._updateRemindConfig(data, index + 1)
		} else {

			const config = { ...this.props.getDebugInformationRemindConfig }

			console.log("_updateRemindConfig==============", data)

			config.visible = true
			config.index = index
			config.type = typeData.matchType
			config.deviceData = data
			config.devices = typeData.matchList

			this.props.updateDebugInformationRemindConfig(config)

		}

	}

	_selectTrend = (e) => {

		console.log("search--------_selectTrend",e)

		this.setState({
			search:{...this.state.search,...{type:e}}
		},()=>{
			const {search} = this.state
			this._requestDebugingList(search.type,search.searchTitle)
		})
	}

	_onserch = (e) => {
		 console.log("search--------_onserch",e)
		this.setState({
			search:{...this.state.search,...{searchTitle:e}}
		},()=>{
			const {search} = this.state
			this._requestDebugingList(search.type,search.searchTitle)
		})
	}

	render() {
		let { pageSize, pageindex } = this.state;
		let { getDebugingList } = this.props

		console.log("getDebugingList================", getDebugingList)

		return (
			<>
				<div className="table_header ABB_tab_marginTop ">
					<Select
						placeholder="请选择状态"
						suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
						style={{ width: 160, marginRight: '16px' }}
						defaultValue={this.state.search.type}
						onChange={(e) => this._selectTrend(e)}
					>

						<Select.Option value={0} key={2}>
							传动序列号
						</Select.Option>
						<Select.Option value={1} key={2}>
							NETA序列号
						</Select.Option>
						<Select.Option value={2} key={2}>
							路由器序列号
						</Select.Option>
						<Select.Option value={3} key={2}>
							SIMICCID
						</Select.Option>

					</Select>

					<Search placeholder="请输入" onSearch={this._onserch} style={{ width: 190 }} className="search_input" />
				</div>
				{
					getDebugingList.map((item, index) => {
						return <>
							<div className="EUorg_title">
								<span>{item.sectionName}</span>
							</div>
							<Table
								size="small"
								scroll={{ x: 740 }}
								columns={this.state.columns}
								dataSource={item.sectionData}
								ellipsis={true}
								pagination={{
									// onChange: page => this.onChange(page),
									pageSize: pageSize,
									// current: pageindex,
									// total: this.state.unconfiguredDeviceList.total
								}}
								rowKey={(record, index) => index}
							/>
						</>
					})

				}

				{/* <EditNeta21
					showModel={this.state.showModel}
					hidemodel={this.hidemodel}
					submitmodel={this.submitmodel}
				/> */}


				<DebuggingInformationModel
					showmodelboundsub={this.props.getDebugInformationConfig}
					hidemodel={() => {

					}}
					submitmodel={(data) => {
						const {search} = this.state
						//更新设备详情
						this._requestDebugingList(search.type,search.searchTitle)
						this._updateRemindConfig(data, 0)

					}}
				/>

				<RemindModel
					showmodelboundsub={this.props.getDebugInformationRemindConfig}
					hidemodel={(data) => {

						
						let param = {
							routerSerialNo: data.deviceData.routerSerialNo,
							gatewaySn: data.deviceData.gatewaySn,
							devices: data.deviceData.devices,
							deviceSn:data.deviceData.deviceSn
						}

						this._updateRemindConfig(param, data.index + 1)
					}}
					submitmodel={() => { }}
					showModelSubConfig={() => { }} />
			</>



		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	updateDebugingList: data => {
		dispatch(setDebugingList(data));
	},
	updateDebugInformationConfig: data => {

		dispatch(setDebugInformationConfig(data))
	},
	updateDebugInformationRemindConfig: data => {
		dispatch(setDebugInformationRemindConfig(data))
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Neta21Debug));
