//编辑数字化连接调试信息
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Form,
    Modal,
    message,
    Spin,
 
} from 'antd';
 
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import '../style.scss'
 

 
import {APISyncContact} from '../../../../../api/user'
import { setRemindUpdateContactConfig } from '../../../store/actionCreators';

class RemindContactModel extends React.Component {
    
    state = {
        disabled: false
    };

    hidemodel = () => {
        let config = {...this.props.getRemindUpdateContactConfig}
        config.visible = false
        console.log("config================",config)
        this.props.updateRemindUpdateContactConfig(config)
    }

    submitmodel = () => {
        
        this.setState({
            disabled: true
        })
        APISyncContact().then(res => {
            this.setState({
                disabled: false
            })
            let resdata = res.data;
            if (resdata !== undefined) {
                this.hidemodel()
            }
        }).catch(err => {
            this.setState({
                disabled: false
            })
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('接口异常')
            }
        })
    }

 
    render() {
        let that = this;
    
        const { showmodelboundsub } = that.props;
       
        const modelProps = {
            title: "提示",
            centered: true,
            width: showmodelboundsub.width,
            maskClosable: false,

            visible: showmodelboundsub.visible,

            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel()}
                    disabled={this.state.disabled}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="暂不更新"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="全部更新"
                />,
            ]
        }
       

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <div>组织联系人信息更新成功，您是否需要将组织联系人信息批量更新至设备联系人？</div>
                   
                </Modal>
            </>
        );
    }
}



const RemindContactModelForm = Form.create({ name: 'register' })(RemindContactModel);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
      
    updateRemindUpdateContactConfig: data => {
        dispatch(setRemindUpdateContactConfig(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RemindContactModelForm));

