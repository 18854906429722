import React, { Component } from 'react';
// import ABBsub from './components/subscription/abbsub';
// import ABBclient from './components/client/abbclient';
// import CreateRecord from './components/subscription/createrecord';
import CheckDevice from './checkdevice';
import CheckCMD from './checkcmd';
import CheckUser from './checkuser';
import CheckOrganization from './checkorgnization';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { Typography, Descriptions, Col, Row } from 'antd';
import StatusDescription from './statusDescription';
import ConfigDevice from './configdevice';
import CMDOnlyDevice from '../../../devicemanagement/components/cmd/CMDDevice';
import Blacklist from './blacklist';
import AddLog from './addLog';
import Gatewaymigration from './gatewaymigration';
import Neta21Debug from './neta21Debug';
import LocationManagement from './locationManagement';
import './style.scss';
import Authorization from './authorization';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const { Title } = Typography;
class ToolBoxCenter extends Component {
	state = {
		activeTab: 0
	};

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	callback(newIndex) {
		this.setState({ activeTab: newIndex });
	}
	isShow = () => {
		let { userInfo } = this.props;
		if (userInfo.organizationType === 'ABB' && userInfo.isOrgAdministrator === true) {
			return (
				<ABB.TabControl type="primary" activeTab={this.state.activeTab} className={'fullHeight mainTabControlPaddings tabHeaderAnimation'} onTabChange={(oldIndex, newIndex) => this.callback(newIndex)} padLeft={false}>
					<ABB.TabItem title="传动详细信息" key="1">
						<CheckDevice />
					</ABB.TabItem>
					<ABB.TabItem title="API数据" key="2">
						<CheckCMD />
					</ABB.TabItem>

					<ABB.TabItem title="用户账号检查" key="3">
						<CheckUser />
					</ABB.TabItem>
					<ABB.TabItem title="组织信息检查" key="4">
						<CheckOrganization />
					</ABB.TabItem>
					<ABB.TabItem title="待配置设备" key="5">
						<ConfigDevice />
					</ABB.TabItem>
					<ABB.TabItem title="未添加设备" key="6">
						<CMDOnlyDevice />
					</ABB.TabItem>
					<ABB.TabItem title="授权API访问" key="7">
						<Authorization />
					</ABB.TabItem>
					{/* <ABB.TabItem title="事件黑名单" key="8">
						<Blacklist />
					</ABB.TabItem> */}
					<ABB.TabItem title="用户添加记录" key="9">
						<AddLog />
					</ABB.TabItem>
					<ABB.TabItem title="网关迁移" key="10">
						<Gatewaymigration />
					</ABB.TabItem>
					<ABB.TabItem title="NETA-21调试信息" key="11">
						<Neta21Debug />
					</ABB.TabItem>

					<ABB.TabItem title="位置管理" key="12">
						<LocationManagement />
					</ABB.TabItem>
				</ABB.TabControl>
			)
		}
		else {
			return (
				<ABB.TabControl type="primary" activeTab={this.state.activeTab} className={'fullHeight mainTabControlPaddings tabHeaderAnimation'} onTabChange={(oldIndex, newIndex) => this.callback(newIndex)} padLeft={false}>
					<ABB.TabItem title="传动详细信息" key="1">
						<CheckDevice />
					</ABB.TabItem>
					<ABB.TabItem title="API数据" key="2">
						<CheckCMD />
					</ABB.TabItem>

					<ABB.TabItem title="用户账号检查" key="3">
						<CheckUser />
					</ABB.TabItem>
					<ABB.TabItem title="组织信息检查" key="4">
						<CheckOrganization />
					</ABB.TabItem>
					<ABB.TabItem title="待配置设备" key="5">
						<ConfigDevice />
					</ABB.TabItem>
					{/* <ABB.TabItem title="授权API访问" key="6">
						<Authorization/>
					</ABB.TabItem> */}
					{/* <ABB.TabItem title="事件黑名单" key="7">
						<Blacklist />
					</ABB.TabItem> */}
				</ABB.TabControl>
			)
		}
	}
	render() {
		return (
			<div style={{ margin: '8px 16px' }}>
				{/* <div className="" style={{ margin: 8 }}>
					<div className="EUorg_title" style={{ marginTop: 8 }}>
						<Title level={4}>云服务运行状态</Title>
					</div>
					<div className="box_flex" style={{ justifyContent: 'left' }}>
						站位
					</div>
					<div className="box_flex" style={{ justifyContent: 'left' }}>
						站位
					</div>
				</div> */}
				<Row  >
					<Col span={15}>
						<Descriptions title={<StatusDescription />} column={3} style={{ margin: '8px 0 0 8px' }}>
							<Descriptions.Item label="哥伦布服务运行状态" labelStyle={{ fontWeight: 'Bold' }} children={
								<ABB.Icon style={{ margin: '2px 0 16px 8px' }} name="abb/io-signals" sizeClass="meidum" color="green" />
							} />
							<Descriptions.Item label="CMD服务运行状态">
								<ABB.Icon style={{ margin: '2px 0 16px 8px' }} name="abb/io-signals" sizeClass="meidum" color="green" />
							</Descriptions.Item>
							<Descriptions.Item label="数据分析服务运行状态">
								<ABB.Icon style={{ margin: '2px 0 16px 8px' }} name="abb/io-signals" sizeClass="meidum" color="green" />
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>

				{
					this.isShow()
				}
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ToolBoxCenter));
// export default ToolBoxCenter;
