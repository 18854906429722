import React, { Component } from 'react';
import {
    Form,
    Modal,
    Input,
    message,
    Col,
    Row,
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { handover } from '@/api/user'

class TransferControlokForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: true,
            dataSource: []
        };
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }

    hidemodel = () => {
        this.props.hidemodel()
        this.setState({
            inputval: ''
        })
    }
    submitmodel = (data) => {
        handover({
            "handOverOrgId": data.orgId,
            "handOverOrgName": data.orgMessage,
            "handOverFromUserId": data.userId,
            "handOverFromUserName": data.userName,
            "handOverToUserPhone": Number(data.phone)
        }).then(res => {
            if (res.data.code === 200) {
                message.success("移交管理权成功").then(()=>{
                    window.location.reload();
                })
            }
            else{
                message.warning(res.data.errorMessage)
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('组织信息获取失败！')
            }

        })
        // this.props.form.validateFields((err, fieldsValue) => {
        //     if (!err) {
        //         console.log("----------",fieldsValue)
        //         // this.props.submitmodel(fieldsValue)
        //         // this.props.form.resetFields();
        //     }
        // });
    }
    onChange = (e) => {

    }
    //获取搜索用户框内容
    onChangeSerch = (e) => {
        this.setState({
            inputval: e
        })
    }
    render() {
        const that = this;
        const { showmodelTranferOk } = this.props;
        const { getFieldDecorator } = that.props.form;
        const modelProps = {
            title: "移交管理权",
            centered: true,
            width: 354,
            visible: showmodelTranferOk.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="我再想想"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelTranferOk)}
                    type="primary-blue"
                    style={{
                        background: '#FF000F',
                        border: '1px solid #FF000F'
                    }}
                    sizeClass='small' theme="filled"
                    text="确认移交"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div className='tranfer-title'>请确认您要将<span style={{ color: '#3366ff' }}>{showmodelTranferOk.orgMessage}</span>下所有设备的管理权移交给以下手机号</div>
                
                <div className='tranfer-phone'>{showmodelTranferOk.phone}</div>
                <div className='tranfer-text'>
                <ABB.Icon style={{ color: '#FF000F' }} className="closeButton" name="abb/warning-triangle" sizeClass='medium' />
                手机号输入错误将无法变更！
                </div>
            </Modal>
        );
    }
}
const TransferControlOk = Form.create({ name: 'register' })(TransferControlokForm);
export default TransferControlOk;
