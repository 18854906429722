import React, { Component } from 'react';
import { Input, Table, Tooltip, message, Descriptions, Tree, Select } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import {APICheckUserInfo, APICheckUserList} from '@/api/tools'

const icon_center = {
	margin: '10px 0 0 0',
	display: 'inline',
	verticalAlign: 'middle'
};
const div_nowrap = {
	margin: '0 10px 0px 20px',
	display: 'inline'
};

const { Search } = Input;
const { Option } = Select;
class CheckUser extends Component {
	state = {
		pagination: {},
		loading: false,
		modal2Visible: false,
		selectedRowKeys: [],
		pageindex: 1,
		pageSize: 10,
		location: '###',
		userInfo: {},
		userList: [],
		selectedUserId: undefined,
		treeData: [
			{
				title: 'EU',
				key: '0-0',
				icon: <ABB.Icon name="abb/hardware-devices" sizeClass="small" />,
				children: [
					{
						title: '设备组1',
						key: '0-0-0',
						icon: <ABB.Icon name="abb/hardware-devices" sizeClass="small" />
					},
					{
						title: '设备组2',
						key: '0-0-1',
						icon: <ABB.Icon name="abb/hardware-devices" sizeClass="small" />
					}
				]
			},
			{
				title: 'EU',
				key: '0-0',
				icon: <ABB.Icon name="abb/hardware-devices" sizeClass="small" />,
				children: [
					{
						title: '设备组1',
						key: '0-0-0',
						icon: <ABB.Icon name="abb/hardware-devices" sizeClass="small" />
					},
					{
						title: '设备组2',
						key: '0-0-1',
						icon: <ABB.Icon name="abb/hardware-devices" sizeClass="small" />
					}
				]
			}
		]
	};

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	componentDidMount() {
		this.fetchUserList();
	}

	// 搜索数据
	onserch = value => {
		this.fetch(value.replace(/\s*/g, ''));
	};
	//接口数据
	fetch = (value) => {
		let url = `?user_id=${value}`;
		let that = this;
		APICheckUserInfo(url)
			.then(res => {
				if (res !== undefined) {
					
					let resdata = res.data.results;
					this.setState({
						userInfo: {
							userGroupName: resdata.userInfo.userGroupName,
							userRole: resdata.userInfo.userRole,
							orgType: resdata.userInfo.orgType,
							orgName: resdata.userInfo.orgName,
							grantedOrgName: resdata.userInfo.grantedOrgName ? resdata.userInfo.grantedOrgName : '-',
							isGranted: resdata.userInfo.isGranted ? '已授权' : '未授权',
							orgAdministrator: resdata.userInfo.orgAdministrator ? resdata.userInfo.orgAdministrator : '-',
							orgCreatedDate: moment.parseZone(resdata.userInfo.orgCreatedDate).local().format('YYYY-MM-DD HH:mm:ss'),
							userCreatedDate: moment.parseZone(resdata.userInfo.userCreatedDate).local().format('YYYY-MM-DD HH:mm:ss')

						},
						
					});
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data.error.message);
				} else {
					message.error('查询用户详细信息接口异常');
				}
				that.setState({
					userInfo: {},
				})
				
			});
	};
	fetchUserList = () => {
		APICheckUserList()
			.then(res => {
				if (res !== undefined) {
					
					this.setState({
						userList: res.data.results
						
					});
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data.error.message);
				} else {
					message.error('查询用户列表接口异常');
				}
				
			});
	}
	onChange = e => {
		console.log(e);
		this.setState({
			selectedUserId: e
		})
		this.fetch(e);
		
	};
	onFocus = () => {

	}
	render() {

		return (
			<>
				<div className="user_box ABB_tab_marginTop">
					<Select 
							showSearch
							placeholder="输入用户手机号" 
							optionFilterProp="children"
							suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />} 
							style={{ width: 288, marginRight: '64px' }} 
							onChange={this.onChange}
							onFocus={this.onFocus}
							value={this.state.selectedUserId}
							filterOption={(input, option) =>
								option.props.children.indexOf(input) >= 0
							}
								
						>
							{this.state.userList.map((item, index) => (
								<Option value={item.user_id} key={index}>
									{item.user_phone}
								</Option>
							))}
					</Select>
				</div>
				<div className="EUorg_title">
					<span>用户信息</span>
				</div>
				{
					this.state.userInfo.orgName ? (
						<Descriptions bordered column={4}>
							<Descriptions.Item label="所属组织">{this.state.userInfo.orgName}</Descriptions.Item>
							<Descriptions.Item label="组织管理员">{this.state.userInfo.orgAdministrator}</Descriptions.Item>
							<Descriptions.Item label="组织类型">{this.state.userInfo.orgType}</Descriptions.Item>
							<Descriptions.Item label="组织创建时间">{this.state.userInfo.orgCreatedDate}</Descriptions.Item>
							<Descriptions.Item label="所属用户组">{this.state.userInfo.userGroupName}</Descriptions.Item>
							<Descriptions.Item label="角色">{this.state.userInfo.userRole}</Descriptions.Item>
							<Descriptions.Item label="授权经销商情况">{this.state.userInfo.isGranted}</Descriptions.Item>
							<Descriptions.Item label="授权经销商">{this.state.userInfo.grantedOrgName}</Descriptions.Item>
							<Descriptions.Item label="微信账号">未知</Descriptions.Item>
							<Descriptions.Item label="ABB账号">未知</Descriptions.Item>
							<Descriptions.Item label="用户创建时间">{this.state.userInfo.userCreatedDate}</Descriptions.Item>
							<Descriptions.Item label="用户上次登录时间">未知</Descriptions.Item>
						</Descriptions>
					):(
						<div className="renderEmpty renderEmpty_flex" style={{ position: "relative", height: '80vh' }}>
							<div className="renderEmpty_center">
								<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
								<p>暂无数据</p>
							</div>
						</div>
					)
				}
				

				{/* <div className="EUorg_title">
					<span>拥有权限的设备节点</span>
				</div>
				<Tree showIcon defaultExpandAll treeData={this.state.treeData} /> */}
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckUser));
