import React, { Component } from 'react';
import { Modal, Button, Input, message, Table, DatePicker } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import moment from "moment";
import { userCheckDevice, deviceDetail, checkAllgen, eventDetaildevice } from '@/redux/actions/device';
import { backupchangelist } from "@/api/backup";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NoData from '@/components/NoData'

const div_nowrap = {
    display: 'inline'
}
const { Search } = Input;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD HH:mm:ss';

class BackupChange extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startime: moment().subtract(29, 'day'),
            endtime: moment(new Date(), dateFormat),
            BackupList: [],
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'parameterId',
                    key: 0,
                    width: '20%'
                },
                {
                    title: '参数名称',
                    dataIndex: 'parameterName',
                    key: 1,
                    width: '30%'
                },
                {
                    title: '原始值',
                    dataIndex: 'oldValue',
                    key: 2,
                    width: '10%'
                },
                {
                    title: '变化值',
                    dataIndex: 'newValue',
                    key: 3,
                    width: '10%'
                },
                {
                    title: '网关时间',
                    dataIndex: 'deviceTime',
                    key: 4,
                    width: '30%',
                    render: (text, record) => (

                        <span>{moment.parseZone(record.deviceTime).local().format(dateFormat)}</span>

                    )
                },

            ],
            feature: '',
            daytime: 14
        };

    }

    componentDidMount() {
        let { startime, endtime } = this.state;
        let today = new Date();
        let _today = moment(today).format("YYYY-MM-DD")
        let _todayminute = moment(today).format("HH:mm:ss")
        let _startime = moment(startime._d).format("YYYY-MM-DD")
        let _endtime = moment(endtime._d).format("YYYY-MM-DD")
        if (_today === _endtime) {
            _endtime = _endtime + " " + _todayminute
        }
        else {
            _endtime = _endtime + " 23:59:59"
        }
        let currentUTCTimeString = moment(_endtime).format("YYYY-MM-DD HH:mm:ss");
        let dateUTCTimeString = moment(_startime).format("YYYY-MM-DD HH:mm:ss");
        let datetime = {
            startime: dateUTCTimeString,
            endtime: currentUTCTimeString
        }

        this.getbackuplist(datetime);
        this.daytime({
            startime: _startime,
            endtime: _endtime,
        })
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    getbackuplist = (datatime) => {
        //接口数据
        let { _deviceDetail, getAssetIsPowertrain } = this.props;
        let url;
        let isSubAsset = '';
        if (getAssetIsPowertrain === true) {
            isSubAsset = `&isSubAsset=true`;
        }
        else {
            isSubAsset = ``
        }
        url = `${_deviceDetail.assetId}?startDatetime=${datatime.startime}&endDatetime=${datatime.endtime}${isSubAsset}`
        backupchangelist(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                this.setState({
                    BackupList: resdata.reverse()
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("getbackuplist--backupchangelist--接口异常")
                message.error('接口异常')
            }
        })

    }


    disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(90, 'day');
    onChangeTime = (dates, dateStrings) => {
        let that = this;
        if (dates[0] === undefined || dates[1] === undefined) {
            that.setState({
                startime: '',
                endtime: ''
            }, () => {
                this.getbackuplist({
                    startime: '',
                    endtime: ''
                })
            })

        }
        else {
            let today = new Date();
            let _today = moment(today).format("YYYY-MM-DD")
            let _todayminute = moment(today).format("HH:mm:ss")
            let _startime = moment(dateStrings[0]).format("YYYY-MM-DD")
            let _endtime = moment(dateStrings[1]).format("YYYY-MM-DD")
            if (_today === _endtime) {
                _endtime = _endtime + " " + _todayminute
            }
            else {
                _endtime = _endtime + " 23:59:59"
            }
            let currentUTCTimeString = moment(_endtime).format("YYYY-MM-DD HH:mm:ss")
            let dateUTCTimeString = moment(_startime).format("YYYY-MM-DD HH:mm:ss");

            this.setState({
                startime: dateUTCTimeString,
                endtime: currentUTCTimeString,
            }, () => {
                this.getbackuplist({
                    startime: dateUTCTimeString,
                    endtime: currentUTCTimeString
                })
                this.daytime({
                    startime: dateUTCTimeString,
                    endtime: currentUTCTimeString,
                })
            })
        }
    }
    daytime = (time) => {
        let startime = Date.parse(time.startime);
        let endtime = Date.parse(time.endtime);
        let day = (endtime - startime) / 1000 / 60 / 60 / 24;
        if (day <= 1) {
            this.setState({
                daytime: 1
            })
        }
        else {
            this.setState({
                daytime: Math.ceil(day)
            })
        }
        // return day;
    }
    render() {
        const that = this;
        let { startime, endtime, daytime } = that.state;
        return (
            <>

                <div>
                    <div style={{ color: '#1f1f1f', fontSize: 14, marginTop: 12 }}>网关时间不是参数更改的实际时间，而是NETA-21检测到更改的时间。通常在UTC晚上12点。</div>
                    <div className="backup_top_box">
                        <div>

                            <span style={{ marginRight: 16 }}>显示范围</span>
                            <RangePicker
                                disabledDate={that.disabledDate}
                                className="model_input"
                                style={{ margin: '0 10px 0 0' }}

                                suffixIcon={
                                    <ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
                                }
                                separator='—'
                                defaultValue={[startime, endtime]}

                                format={dateFormat}
                                allowClear={false}
                                format="YYYY/MM/DD"
                                onChange={this.onChangeTime}
                            // onOk={this.onOk}
                            />
                            <span style={{ color: '#1f1f1f' }}>已选{daytime}天（最长90天）</span>
                        </div>


                        <div></div>
                    </div>
                    <div style={{ position: 'relative', minHeight: '60vh' }}>
                        {
                            that.state.BackupList.length > 0 ? (
                                <Table
                                    size='small'
                                    className='org_right'
                                    ellipsis={true}
                                    columns={that.state.columns}
                                    dataSource={that.state.BackupList}
                                    pagination={{
                                        pageSize: 10
                                    }}
                                    rowKey={(record, index) => record.parameterId + index}
                                />
                            ) : <NoData />
                        }
                    </div>


                </div>


            </>

        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BackupChange));
// export default BackupParameters;
