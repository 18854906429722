//添加位置
import React, { Component } from 'react';
import { Modal, Button, Input, message } from 'antd';
import { addplant, plantList } from '@/api/plant';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import Amap from '../../plant/components/plantaddressmap';
import { connect } from 'react-redux';
import { setplant, setaddress, setaddressChange } from '@/redux/actions/plant';
import { withRouter } from 'react-router-dom';
import { plantListdata } from '@/utils/devicesData';
import AddressMap from './addressMap'

const { Search } = Input;
let inputIsChange = false
class AddAddress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: false,
           
            addPlantdata: false,
            addressObj: {},
            
            latitude: '',
            longitude: '',
            key:new Date()
        };

    }

 

    //显示添加位置弹出框
    addPlant(addPlantdata) {
        const {assetLocation } = this.props;
      
        inputIsChange = false
        this.setState({
            addPlantdata,
            key:addPlantdata === true ? new Date() : this.state.key
          
        })
        let location = {
            address: assetLocation.address,
            addressDisabled: false,
            latitude: Number(assetLocation.latitude),
            longitude: Number(assetLocation.longitude),
        }
     
        this.props.setaddressChange(location)
        this.props.setaddress(location)
    }
    //确认添加位置按钮
    subaddplant() {

        if (inputIsChange === true) {
            message.warning('请从地图上或者搜索列表中选择位置')
            return
        }
        
        //确认按钮点击

        let address = this.props.getaddressChange

        this.props.onSubmit(address)
 
        this.addPlant(false)
    }

    render() {
        const _self = this;
        const { getAddplanttype,assetLocation } = _self.props;
 
        let planttype;
        // if (getAddplanttype === 1) {
        // 	planttype = "primary-blue"
        // }
        // else {
        // 	planttype = "ghost"
        // }
        return (
            <>
                <ABB.Button
                    onClick={() => this.addPlant(true)}
                    style={{ border: 'none' }}
                    type="ghost"
                    sizeClass='small' theme="filled" icon="abb/edit"
                />
 
                <Modal
                    title="修改位置"
                    centered
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                    width={600}
                    maskClosable={false}
                    visible={_self.state.addPlantdata}
                    onOk={() => _self.addPlant(false)}
                    onCancel={() => _self.addPlant(false)}
                    footer={[
                        <ABB.Button
                            onClick={() => this.addPlant(false)}
                            type="discreet-black"
                            sizeClass='small' theme="filled"
                            text="取消"
                        />,
                        <ABB.Button
                            onClick={() => _self.subaddplant()}
                            type="primary-blue"
                            disabled={_self.state.disabled}
                            sizeClass='small' theme="filled"
                            text="确定"
                        />,
                    ]}
                >
                     
                    <div className='model_list'>
                        
                        <AddressMap key={this.state.key}  editInfo={this.props.editInfo} inputChange = {(isChange)=>{

                                inputIsChange = isChange
                        }}/>
                    </div>
                </Modal>
            </>

        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setplant: data => {
        dispatch(setplant(data))
    },

    setaddress: data => {
        dispatch(setaddress(data))
    },
    setaddressChange: data => {
        dispatch(setaddressChange(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddAddress));

