import React from 'react';
import { Select, Modal, Timeline } from 'antd';
import * as ABB from 'abb-common-ux-react';

import 'abb-common-ux-react/styles.css';

class StatusModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	handleOk = () => {
		// this.props.checkDeviceStatus();
		this.props.changeCheckModalVisible();

		if (this.props.checkStatus && this.props.checkColumbusStatus) {
			this.props.updateDriveSN(this.props.oldDriveSN, this.props.activeDriveSN, () => {
				this.props.refreshModal();
			});
			// this.props.refreshModal();
		}
	};

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	render() {
		const { visible, changeCheckModalVisible, checkStatus, checkDescription, activeDriveSN, checkSNDescription, checkColumbusStatus, oldDriveSN } = this.props;
		const modelProps = {
			title: `设备配置`,
			visible: visible,
			centered: true,
			onOk: () => {
				this.handleOk();
			},
			onCancel: () => {
				changeCheckModalVisible();
			},
			footer: [<ABB.Button onClick={() => changeCheckModalVisible()} type="discreet-black" sizeClass="small" theme="filled" text="取消" />, <ABB.Button onClick={() => this.handleOk()} type="primary-blue" sizeClass="small" theme="filled" text="确定" />]
		};
		return (
			<>
				<Modal {...modelProps}
				closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }
				>
					<Timeline>
						<Timeline.Item color="green" dot={<ABB.Icon name="abb/check-mark-circle-2" sizeClass="small" />}>
							序列号规则正确（只能包含字母和数字）
						</Timeline.Item>
						{checkDescription}
						{checkSNDescription}
						{checkStatus && checkColumbusStatus ? (
							<Timeline.Item color="green" dot={<ABB.Icon name="abb/check-mark-circle-2" sizeClass="small" />}>
								<p>检查完毕，请点击确定按钮提交到Motion365后台系统</p>
								<p style={{ fontWeight: 'bold' }}>
									点击确定后，设备序列号从{oldDriveSN}变为{activeDriveSN}
								</p>
								{/* <ABB.Icon style={{ color: 'green', width: 20 }} name="abb/check-mark-circle-2" sizeClass="large" /> */}
							</Timeline.Item>
						) : (
							<Timeline.Item color="red" dot={<ABB.Icon name="abb/error-circle-2" sizeClass="small" />}>
								<p>请先检查设备序列号，然后再次点击检查按钮</p>
								{/* <ABB.Icon style={{ color: 'red', width: 20 }} name="abb/error-circle-2" sizeClass="large" /> */}
							</Timeline.Item>
						)}
					</Timeline>
				</Modal>
			</>
		);
	}
}

export default StatusModal;
