import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon, message, Table, Tooltip, Checkbox } from 'antd';
import * as ABB from 'abb-common-ux-react';
import moment from 'moment';
import SvgAbbTrend316 from '@/components/trend3_16';
import CallPromptModel from '../../callPromptModel';
import SupportRecordModel from '../../supportRecordModel';
import { APICallPrompt } from '../../../../../../api/device';
import {easemobim_parameter} from '../../../../../../api/event'

const icon_center = {
    margin: '0 10px 0 0',
    display: 'inline'
}
class EeventTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            feature: false,
            tableData: props.tableData,
            checkoutAllList: [
                {
                    id: 1,
                    value: "运行"
                },
                {
                    id: 2,
                    value: "待机"
                },
                {
                    id: 3,
                    value: "计划停机"
                },
                {
                    id: 4,
                    value: "非计划停机"
                },
                {
                    id: 0,
                    value: "--"
                }
            ],
            inputval: [], //设备运行状态筛选列表选中记录
            indeterminate: false,
            checkAll: false,
            columns: [
                {
                    title: '类型',
                    dataIndex: 'assetEventType',
                    key: 0,
                    width: 70,
                    render: assetEventType => {
                        if (assetEventType === 'Fault') {
                            return (
                                <ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
                            );
                        }
                        if (assetEventType === 'Warning') {
                            return (
                                <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
                            );
                        }
                        if (assetEventType === 'Notification') {
                            return (
                                <ABB.Icon style={{ 'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
                            );
                        }
                        if (assetEventType === 'Custom') {
                            return (
                                <div className='customevent'></div>
                            );
                        }
                    },
                },
              
                {
                    title: () => (
                        <div className="deviceTime-box">
                            <span style={{ marginRight: '8px' }}>设备端时间</span>

                            <Tooltip placement="bottom" title={
                                <>
                                    <div>设备时间与上报时间出现不一致？</div>
                                    <div>1. 设备端日期及时钟未被校准； </div>
                                    <div>2. 网络传输延迟</div>
                                </>

                            }>
                                <div style={icon_center}>
                                    <ABB.Icon style={{ 'color': '#1f1f1f' }} name="abb/help-circle-2" sizeClass='small' />
                                </div>
                            </Tooltip>
                        </div>
                    ),
                    dataIndex: 'eventOccurrentTime',
                    key: 1,
                    width: '20%',
                    ellipsis: true,
                    render: (text, record) => {
                        return (
                            <>
                                {
                                    record.occurrentTimeIsValidFormat === true ? (

                                        <div>

                                            {moment.parseZone(Number(record.eventOccurrentTime)).local().format("YYYY-MM-DD HH:mm:ss")}

                                        </div>
                                    ) : (
                                        <div>{record.eventOccurrentTime}</div>
                                    )
                                }
                            </>
                        )
                    }
                },
                {
                    title: '网关时间(UTC+8)',
                    dataIndex: 'eventDateTime',
                    key: 2,
                    width: '20%',
                    ellipsis: true,
                    render: (text, record) => (
                        <div>{moment.parseZone(record.eventDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div>
                    )
                },
                {
                    title: '事件描述',
                    dataIndex: 'content',
                    key: 3,
                    width: '40%',
                    ellipsis: true,
                },
                {
                    title: '事件代码',
                    dataIndex: 'eventCode',
                    key: 4,
                    width: 80,
                    ellipsis: true,
                },
                {
                    title: '详情',
                    dataIndex: 'eventCode',
                    key: 5,
                    width: 50,
                    render: (text, record) => (
                        <div style={{ display: 'flex' }}>
                            {
                                record.isHasEventLogger === true ? (
                                    <Tooltip placement="bottom" title='详情'>
                                        <div style={icon_center} onClick={() => this.props.eventlogger(record)}>
                                            <Icon style={{ 'color': '#0CA919' }} component={SvgAbbTrend316} />
                                        </div>
                                    </Tooltip>

                                ) : <Tooltip placement="bottom" title='详情'>
                                    <div style={icon_center} onClick={() => this.props.eventlogger(record)}>
                                        <ABB.Icon name="abb/information-circle-2" sizeClass='small' />
                                    </div>
                                </Tooltip>
                            }

                            {
                                this._renderCallStatusUI(record)

                            }
                        </div>
                    )
                },

            ],
            filterValues: [], //设备运行状态过滤条件,点击确定按钮时更新

            showCallPromptConfig: {
                visible: false,
                title: '',
                value: '',
                width: 700,
                centered: true,
                eventData: {},
                timeLength: 0
            },
            showSupportRecordConfig: {
                visible: false,
                title: '',
                value: '',
                width: 700,
                centered: true,
                eventData: {
                    calledRecords: []
                },

            },
            number: -1
        }
    }
    componentDidMount() {
        let { getsubscriptioncof } = this.props;
        let feature = false;
        let _columns = []
        getsubscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 28) {
                    feature = items.limitValue
                }
            })

        })
        if(feature === true){
            _columns = [
                {
                    title: '类型',
                    dataIndex: 'assetEventType',
                    key: 0,
                    width: 70,
                    render: assetEventType => {
                        if (assetEventType === 'Fault') {
                            return (
                                <ABB.Icon style={{cursor: "text", 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
                            );
                        }
                        if (assetEventType === 'Warning') {
                            return (
                                <ABB.Icon style={{ cursor: "text",'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
                            );
                        }
                        if (assetEventType === 'Notification') {
                            return (
                                <ABB.Icon style={{cursor: "text", 'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
                            );
                        }
                        if (assetEventType === 'Custom') {
                            return (
                                <div className='customevent'></div>
                            );
                        }
                    },
                },
                {
                    title: '设备状态',
                    dataIndex: 'deviceWorkStatus',
                    key: 'eventDateTime',
                    width: 150,
                    ellipsis: true,
                    ...this.getColumnSearchProps('deviceWorkStatusval'),

                    render: deviceWorkStatus => {
                        if (deviceWorkStatus === 1) {
                            return (
                                <div className="device_status_box">
                                    <ABB.Icon style={{cursor: "text", 'color': '#1f1f1f' }} name="abb/drive" sizeClass='small' />&nbsp;
                                    <div style={{ 'color': '#1f1f1f' }}>运行</div>
                                </div>
                            )
                        }
                        if (deviceWorkStatus === 2) {
                            return (
                                <div className="device_status_box">
                                    <ABB.Icon style={{cursor: "text", 'color': '#1f1f1f' }} name="abb/drive" sizeClass='small' />&nbsp;

                                    <div style={{ 'color': '#1f1f1f' }}>待机</div>
                                </div>
                            )
                        }
                        if (deviceWorkStatus === 3) {
                            return (
                                <div className="device_status_box">
                                    <ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drives-error" sizeClass='small' />&nbsp;
                                    <div style={{ 'color': '#1f1f1f' }}>计划停机</div>
                                </div>
                            )
                        }
                        if (deviceWorkStatus === 4) {
                            return (

                                <div className="device_status_box">
                                    <ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drives-error" sizeClass='small' />&nbsp;
                                    <div style={{ 'color': '#1f1f1f' }}>非计划停机</div>
                                </div>

                            )
                        }
                        return '--'

                    }
                },
                {
                    title: () => (
                        <div className="deviceTime-box">
                            <span style={{ marginRight: '8px' }}>设备端时间</span>

                            <Tooltip placement="bottom" title={
                                <>
                                    <div>设备时间与上报时间出现不一致？</div>
                                    <div>1. 设备端日期及时钟未被校准； </div>
                                    <div>2. 网络传输延迟</div>
                                </>

                            }>
                                <div style={icon_center}>
                                    <ABB.Icon style={{ 'color': '#1f1f1f' }} name="abb/help-circle-2" sizeClass='small' />
                                </div>
                            </Tooltip>
                        </div>
                    ),
                    dataIndex: 'eventOccurrentTime',
                    key: 1,
                    width: '20%',
                    ellipsis: true,
                    render: (text, record) => {
                        return (
                            <>
                                {
                                    record.occurrentTimeIsValidFormat === true ? (

                                        <div>

                                            {moment.parseZone(Number(record.eventOccurrentTime)).local().format("YYYY-MM-DD HH:mm:ss")}

                                        </div>
                                    ) : (
                                        <div>{record.eventOccurrentTime}</div>
                                    )
                                }
                            </>
                        )
                    }
                },
                {
                    title: '网关时间(UTC+8)',
                    dataIndex: 'eventDateTime',
                    key: 2,
                    width: '20%',
                    ellipsis: true,
                    render: (text, record) => (
                        <div>{moment.parseZone(record.eventDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div>
                    )
                },
                {
                    title: '事件描述',
                    dataIndex: 'content',
                    key: 3,
                    width: '36%',
                    ellipsis: true,
                },
                {
                    title: '事件代码',
                    dataIndex: 'eventCode',
                    key: 4,
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '详情',
                    dataIndex: 'eventCode',
                    key: 5,
                    width: 60,
                    render: (text, record) => (
                        <div style={{ display: 'flex' }}>
                            {
                                record.isHasEventLogger === true ? (
                                    <Tooltip placement="bottom" title='详情'>
                                        <div style={icon_center} onClick={() => this.props.eventlogger(record)}>
                                            <Icon style={{ 'color': '#0CA919' }} component={SvgAbbTrend316} />
                                        </div>
                                    </Tooltip>

                                ) : <Tooltip placement="bottom" title='详情'>
                                    <div style={icon_center} onClick={() => this.props.eventlogger(record)}>
                                        <ABB.Icon name="abb/information-circle-2" sizeClass='small' />
                                    </div>
                                </Tooltip>
                            }

                            {
                                this._renderCallStatusUI(record)

                            }
                        </div>
                    )
                },

            ]
        }
        else{
            _columns = [
                {
                    title: '类型',
                    dataIndex: 'assetEventType',
                    key: 0,
                    width: 70,
                    render: assetEventType => {
                        if (assetEventType === 'Fault') {
                            return (
                                <ABB.Icon style={{ cursor: "text",'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
                            );
                        }
                        if (assetEventType === 'Warning') {
                            return (
                                <ABB.Icon style={{ cursor: "text",'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
                            );
                        }
                        if (assetEventType === 'Notification') {
                            return (
                                <ABB.Icon style={{ cursor: "text",'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
                            );
                        }
                        if (assetEventType === 'Custom') {
                            return (
                                <div className='customevent'></div>
                            );
                        }
                    },
                },
                
                {
                    title: () => (
                        <div className="deviceTime-box">
                            <span style={{ marginRight: '8px' }}>设备端时间</span>

                            <Tooltip placement="bottom" title={
                                <>
                                    <div>设备时间与上报时间出现不一致？</div>
                                    <div>1. 设备端日期及时钟未被校准； </div>
                                    <div>2. 网络传输延迟</div>
                                </>

                            }>
                                <div style={icon_center}>
                                    <ABB.Icon style={{ 'color': '#1f1f1f' }} name="abb/help-circle-2" sizeClass='small' />
                                </div>
                            </Tooltip>
                        </div>
                    ),
                    dataIndex: 'eventOccurrentTime',
                    key: 1,
                    width: '25%',
                    ellipsis: true,
                    render: (text, record) => {
                        return (
                            <>
                                {
                                    record.occurrentTimeIsValidFormat === true ? (

                                        <div>

                                            {moment.parseZone(Number(record.eventOccurrentTime)).local().format("YYYY-MM-DD HH:mm:ss")}

                                        </div>
                                    ) : (
                                        <div>{record.eventOccurrentTime}</div>
                                    )
                                }
                            </>
                        )
                    }
                },
                {
 
                    title: '网关时间(UTC+8)',
                    dataIndex: 'eventDateTime',
                    key: 2,
                    width: '25%',
                    ellipsis: true,
                    render: (text, record) => (
                        <div>{moment.parseZone(record.eventDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div>
                    )
                },
                {
                    title: '事件描述',
                    dataIndex: 'content',
                    key: 3,
                    width: '26%',
                    ellipsis: true,
                },
                {
                    title: '事件代码',
                    dataIndex: 'eventCode',
                    key: 4,
                    width: 150,
                    ellipsis: true,
                },
                {
                    title: '详情',
                    dataIndex: 'eventCode',
                    key: 5,
                    width: 60,
                    render: (text, record) => (
                        <div style={{ display: 'flex' }}>
                            {
                                record.isHasEventLogger === true ? (
                                    <Tooltip placement="bottom" title='详情'>
                                        <div style={icon_center} onClick={() => this.props.eventlogger(record)}>
                                            <Icon style={{ 'color': '#0CA919' }} component={SvgAbbTrend316} />
                                        </div>
                                    </Tooltip>

                                ) : <Tooltip placement="bottom" title='详情'>
                                    <div style={icon_center} onClick={() => this.props.eventlogger(record)}>
                                        <ABB.Icon name="abb/information-circle-2" sizeClass='small' />
                                    </div>
                                </Tooltip>
                            }

                            {
                                this._renderCallStatusUI(record)

                            }
                        </div>
                    )
                },

            ]
        }
        this.setState({
            feature: feature,
            columns: _columns
        })

    }

    confirmClick = (selectedKeys, confirm, dataIndex, setSelectedKeys) => {
        const { isGroup, index, updateNumber, tableData } = this.props
        let inputval = this.state.inputval
        this.setState({
            filterValues: [...this.state.inputval]
        }, () => {
            // selectedKeys(this.state.inputval)
            setSelectedKeys(this.state.inputval)
            this.handleSearch(selectedKeys, confirm, dataIndex)
 
            let number = 0

            if (this.state.inputval.length === 0) {
                number = tableData.length
            } else {

                tableData.map((item, index) => {

                    if (inputval.indexOf(item.deviceWorkStatus) !== -1) {
                        number += 1
                    }

                })
            }


            //确定按钮点击  如果是分组状态,通知外部更新数量

            if (isGroup && this.state.number !== number) {
                this.setState({
                    number: number
                }, () => {
                    updateNumber(index, number)
                })

            }

        })
    }

    getColumnSearchProps = dataIndex => {

        return ({
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, record }) => (

                <div className='tablefiltersearch' style={{ padding: 8 }}>
                    
                    {/* <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button> */}


                    <Checkbox
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        全选
                    </Checkbox>
                    <Checkbox.Group
                        value={this.state.inputval}
                        style={{ width: '80%' }}
                        onChange={this.onChangeSearch}
                    >

                        {
                            this.state.checkoutAllList.map((item, index) => {
                                return (
                                    <div key={index} style={{ marginTop: '16px' }}>
                                        <Checkbox value={item.id} checked={true}>{item.value}</Checkbox>
                                    </div>
                                )
                            })
                        }


                    </Checkbox.Group>
                    <div>
                        <div className='button-filter'
                            onClick={() => { this.confirmClick(selectedKeys, confirm, dataIndex, setSelectedKeys) }}
                        >确定</div>
                    </div>

                </div>
            ),
            filterIcon: filtered => (
                // <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
                <div style={{ padding: '8px' }}>
                    <ABB.Icon style={{ 'color': '#1f1f1f' }} name="abb/filter" sizeClass='small' />
                </div>

            ),
            onFilter: (value, record) => {


                // if (this.state.checkAll === true) return true

                if (this.state.filterValues.length === 0 || this.state.filterValues.length === 5) return true

                if (this.state.filterValues.indexOf(record.deviceWorkStatus) !== -1) {
                    return true
                }
                return false

                // let index = arr.indexOf('nothing');

                // record.deviceWorkStatus




                // console.log("value--", this.state.filterValues, record)

                // return true
                // console.log("record",record[dataIndex].includes(value.toLowerCase()))
            },
            // record[dataIndex].includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: visible => {
                //点击筛选图标
                if (visible) {
                    // setTimeout(() => this.searchInput.select());
                }
            },
            render: text =>
                // this.state.searchedColumn === dataIndex ? (
                text
            // ) : (
            // 	text
            // ),
        })
    };

    //一键呼叫请求
    _showCallPrompt = (record) => {
        //一键呼叫网络请求
        var config = { ...this.state.showCallPromptConfig, visible: true, eventData: record, timeLength: 5 }
        this.setState({
            showCallPromptConfig: config
        }, () => {
            this.child2.udpateTimeLength()
        })
    }

    _callPromptRequest = (record) => {
        const that = this
        APICallPrompt(record.id).then((res) => {
            if (res !== undefined) {
                if((res.data?.data?.pushResultMessage||'').length==0){
                    that._showCallPrompt(record)
                   }else{ 
                    message.warning("操作失败，"+res.data.data.pushResultMessage)
                   }
            }
        }).catch(err => {
            if (err === undefined) {
                return
            }
            that.setState({
                loading: false
            })
            if (err.data.code === 400 || err.data.code === 404) {
                message.warning(err.data.errorMessage)
            }
            else {
                message.error('一键呼叫请求异常')
            }
        })
    }

    //支持记录
    _supportRecord = (record) => {
        this.setState({
            showSupportRecordConfig: { ...this.state.showSupportRecordConfig, visible: true, eventData: record }
        })
    }


    _renderCallStatusUI = (record) => {
        //临时关闭专家服务 全部事件
        // return null
        
        if (record.assetEventType !== 'Fault') return null
        if (record.calledStatus === 0) { return null }
        if (record.calledStatus === 1) {
            return <Tooltip placement="bottom" title='一键呼叫'>
                <div style={icon_center} onClick={() => this._callPromptRequest(record)}>
                    <ABB.Icon name="abb/call" sizeClass='small' />
                </div>

            </Tooltip>
        }
        if (record.calledStatus === 2) {
            return <Tooltip placement="bottom" title='支持记录'>
                <div style={icon_center} onClick={() => this._supportRecord(record)}>
                    <ABB.Icon name="abb/list" sizeClass='small' />
                </div>

            </Tooltip>
        }
        if (record.calledStatus === 3) {
			return <Tooltip placement="bottom" title='一键呼叫'>
				<div style={icon_center} onClick={() => this.callEasemob(record)}>
					<ABB.Icon name="abb/call" sizeClass='small' />
				</div>
			</Tooltip>
		}

    }
	renderEventName = (eventCode) => {
		if (eventCode === 1) {
			return '故障'
		}
		if (eventCode === 2) {
			return '告警'
		}
		if (eventCode === 3) {
			return '通知'
		}
		if (eventCode === 8) {
			return '自定义'
		}
		return "--"
	}


	//调用环信机器人
	callEasemob = (record) => {
        window.easemobim.bind({
			configId: "198e7ac1-4dfc-4e01-a043-037569957e4e",
			dialogWidth: "500px",
            hide:true
		})
		return

		this.setState({
			loading: true
		})
		easemobim_parameter(record.id).then(res => {

			this.setState({
				loading: false
			})
			 
			//临时代码 模拟设备状态 设备详情
			if (res.data.code === 200) {
				let resdata = res.data.data


				window.easemobim.bind({
					configId: "198e7ac1-4dfc-4e01-a043-037569957e4e",
					dialogWidth: "500px"
				})

				window.easemobim.sendExt({
					ext: {
						"imageName":`{${resdata.productImageName}}`,
						//custom代表自定义消息，无需修改
						"type": "custom",
						"msgtype": {
							"track": {
								"title": `{[${resdata.orgName}]}{${resdata.productName}}{${this.renderEventName(resdata.eventType)}}`,  // 公司名称 / 型号 / 事件类型
								"price": `故障代码：${resdata.eventCode || "--"}`,    // 故障代码、辅助代码
								"desc": `序列号：${resdata.deviceSn || "--"}；故障描述：${resdata.eventContent || "--"}`,  // 序列号 / 描述
								"img_url": `{${process.env.REACT_APP_BASE_URL}/${resdata.productImageName}}`,  // 产品图片地址
							 
							}
						}
					}
				});

			} else {
				message.destroy()
				message.error('事件信息获取失败')
			}
		}).catch((err) => {

			this.setState({
				loading: false
			})
			message.destroy()
			message.error('事件信息获取失败')
		})


		console.log('呼叫机器人+++++++++++++++++++++++++++')

	}

    //全选按钮点击
    onCheckAllChange = (e) => {
        let { checkoutAllList } = this.state;
        let checkoutList = []
        if (e.target.checked === true) {
            checkoutAllList.map((item, index) => {
                checkoutList.push(item.id)
            })
        }

        console.log("e.target.checked", e.target.checked)
        this.setState({
            inputval: checkoutList,
            // filterValues:[...checkoutList],
            indeterminate: false,
            checkAll: e.target.checked,
        }, () => {
            console.log("checkoutListcheckoutList", checkoutList)

            //如果是分组情况,更新外部数量
        });
    }

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();



        // console.log("selectedKeys", selectedKeys)
        // console.log("dataIndex", dataIndex)
        // // console.log("selectedKeys",selectedKeys)
        // this.setState({
        // 	searchText: selectedKeys[0],
        // 	searchedColumn: dataIndex,
        // });
    };

    //单选框选择按钮 
    onChangeSearch = (e) => {
        this.setState({
            inputval: e,
            checkAll: e.length === 5 ? true : false,
        })
    }
    render() {
        let that = this;
        let mytablecolumns = that.state.columns;
        return (
            <div>

                <Table
                    size='small'
                    scroll={{ x: 500 }}
                    className='org_right'
                    ellipsis={true}
                    columns={mytablecolumns}
                    dataSource={that.props.tableData}
                    // pagination={false}
                    pagination={{
                        pageSize: 10
                    }}
                    rowKey={(record, item) => record.id}
                />

                <CallPromptModel
                    onRef={(ref) => { this.child2 = ref }}
                    showmodelboundsub={this.state.showCallPromptConfig}
                    hidemodel={() => {
                        that.setState({ showCallPromptConfig: { ...that.state.showCallPromptConfig, visible: false } })

                        that.props.refreshData()
                    }}
                />

                <SupportRecordModel
                    showmodelboundsub={that.state.showSupportRecordConfig}
                    hidemodel={() => {

                        that.setState({
                            showSupportRecordConfig: { ...that.state.showSupportRecordConfig, visible: false }
                        })
                    }} />
            </div>

        )
        // return <div>asdf</div>
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EeventTable));
// export default EeventDetail;