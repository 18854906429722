import $axios from '../axios/$axios';

export function customAlarms(data) {
    return $axios({
        url: '/api/v3/notification/custom-limits/asset/' + data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function addCustomAlarm(param, data) {
    return $axios({
        url: '/api/v3/notification/custom-limits/asset/' + data,
        method: 'post',
        type: 'json',
        data: param
    })
}
export function deleteCustomAlarm(data) {
    return $axios({
        url: '/api/v3/notification/custom-limits/asset/'+data,
        method: 'delete',
        type: 'json',
        data: ''
    })
}

export function AbilitycustomAlarms(data) {
    return $axios({
        url: '/api/v3/notification/device-signal-custom-threshold-rule' + data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function addAbilityCustomAlarm(param) {
    return $axios({
        url: '/api/v3/notification/device-signal-custom-threshold-rule',
        method: 'post',
        type: 'json',
        data: param
    })
}

export function deleteAbilityCustomAlarm(data) {
    return $axios({
        url: '/api/v3/notification/device-signal-custom-threshold-rule/'+data,
        method: 'delete',
        type: 'json',
        data: ''
    })
}

export function AbilitySignals(data) {
    return $axios({
        url: '/api/v3/trends/usersignals' + data,
        method: 'get',
        type: 'json',
        data: ''
    })
}