import React from 'react';
import { Tabs, message } from 'antd';
import EUequipment from './components/device/EUequipment';
import EUsubscription from './components/subcription/EUsubscription';
import Plant from './components/plant/plant';
import { connect } from 'react-redux';
import Batchimport from './components/batchImport/batchImport';
import { setUserInfo } from '@/redux/actions/userInfo';
import { setAddplanttype } from '@/redux/actions/plant';
import { setBatchasset, setBatchImport } from '@/redux/actions/device';
import { withRouter } from 'react-router-dom';
import { getexportdevice } from '@/api/device';

import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
const { TabPane } = Tabs;

class Orgtable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0
		};
	}
	onTabClick(key) {
		// 判断用户类型
		let { userInfo } = this.props;
		this.setState(
			{
				activeIndex: key
			},
			() => {
				this.props.setBatchImport(key);
				if (key === 3) {
					// if(this.props.getSubid.type == 3 || this.props.getSubid.type == 100){
					// 	let url = `/${this.props.getSubid.orgId}`;
					// 	getexportdevice(url)
					// 		.then(res => {
					// 			this.props.setBatchasset(res.data);
					// 		})
					// 		.catch(err => {
					// 			if (err.status === 400 || err.status === 404) {
					// 				message.warning(err.data);
					// 			} else {
					// 				message.error('导入失败');
					// 			}
					// 		});
					// }
				}
				if (key === 2) {
					this.props.setAddplanttype(1);
				}
				if (key === 4) {
					if (userInfo.organizationType === 'ABB') {
					}
				}
			}
		);
	}
	istab = userInfo => {
		if (userInfo.organizationType === 'EU') {
			if (userInfo.isOrgAdministrator === true) {
				return (
					<ABB.TabControl type="primary" activeTab={this.state.activeIndex} className={'fullHeight mainTabControlPaddings tabHeaderAnimation'} onTabChange={(oldIndex, newIndex) => this.onTabClick(newIndex)} padLeft={false}>
						<ABB.TabItem title="设备列表">
							<EUequipment />
						</ABB.TabItem>
						<ABB.TabItem title="订阅列表">
							<EUsubscription key={"6789"}/>
						</ABB.TabItem>
					 
						<ABB.TabItem title="批量导入">
							<Batchimport />
						</ABB.TabItem>
					</ABB.TabControl>
				);
			} else {
				return (
					<ABB.TabControl type="primary" activeTab={this.state.activeIndex} className={'fullHeight mainTabControlPaddings tabHeaderAnimation'} onTabChange={(oldIndex, newIndex) => this.onTabClick(newIndex)} padLeft={false}>
						<ABB.TabItem title="设备列表">
							<EUequipment />
						</ABB.TabItem>
						<ABB.TabItem title="订阅列表">
							<EUsubscription key={"5678"}/>
						</ABB.TabItem>
						 
					</ABB.TabControl>
				);
			}
		} else if (userInfo.organizationType === 'CP') {
			return (
				<ABB.TabControl type="primary" activeTab={this.state.activeIndex} className={'fullHeight mainTabControlPaddings tabHeaderAnimation'} onTabChange={(oldIndex, newIndex) => this.onTabClick(newIndex)} padLeft={false}>
					<ABB.TabItem title="设备列表">
						<EUequipment />
					</ABB.TabItem>
					<ABB.TabItem title="订阅列表">
						<EUsubscription key={"4567"}/>
					</ABB.TabItem>
					 
					<ABB.TabItem title="批量导入">
						<Batchimport />
					</ABB.TabItem>
				</ABB.TabControl>
			);
		} else {
			return (
				<ABB.TabControl type="primary" activeTab={this.state.activeIndex} className={'fullHeight mainTabControlPaddings tabHeaderAnimation'} onTabChange={(oldIndex, newIndex) => this.onTabClick(newIndex)} padLeft={false}>
					<ABB.TabItem title="设备列表">
						<EUequipment />
					</ABB.TabItem>
					<ABB.TabItem title="订阅列表">
						<EUsubscription key={"2345"}/>
					</ABB.TabItem>
				 
					<ABB.TabItem title="批量导入">
						<Batchimport />
					</ABB.TabItem>
					
				</ABB.TabControl>
			);
		}
	};
	render() {
		let { userInfo } = this.props;
		return <div className="assetbox">{this.istab(userInfo)}</div>;
	}
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	setBatchasset: data => {
		dispatch(setBatchasset(data));
	},
	setAddplanttype: data => {
		dispatch(setAddplanttype(data));
	},

	setBatchImport: data => {
		dispatch(setBatchImport(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orgtable));
// export default Orgtable;
