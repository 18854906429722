import * as actionTypes from './constants';


const showServiceConfig = {
    visible: false,
    title: '',
    value: '',
    width: 700,
    centered: true,
    isRequestData: false,
    deviceData: {},
    defaultDeviceContacts: [
    ],
    deviceContacts: [
        {
            "title": "首选联系人", //标题
            "name": "", // 联系人名称
            "phone": "", // 联系人电话
            "priority": 0, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
            isMust: true
        },
        {
            "title": "备选联系人1", //标题
            "name": "", // 联系人名称
            "phone": "", // 联系人电话
            "priority": 1, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
            isMust: true
        },
        {
            "title": "备选联系人2", //标题
            "name": "", // 联系人名称
            "phone": "", // 联系人电话
            "priority": 2, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
            isMust: false
        },
        {
            "title": "备选联系人3", //标题
            "name": "", // 联系人名称
            "phone": "", // 联系人电话
            "priority": 3, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
            isMust: false
        },
    ],
    orgContacts:[],

    expertServiceType: 0
}

const showDebuggingInformationConfig = {
    visible: false,
    title: '',
    value: '',
    width: 700,
    centered: true,
    deviceData: {},
    subscriptionData: [],

    defaultData: {
        netaIPAddress: "", //NETA IP地址
        netaUserName: "", //NETA UserName
        netaPassword: "", //NETA Password
        routerSerialNo: "", //路由器序列号
        routerPassword: "", //路由器密码
        routerMACAddress: "", //路由器MAC地址
        sIMICCID: "", //SIM卡ICCID
        deviceVersion: "", //设备版本号
        deviceNodeId:"",
        isShowNeta21Remind:false,
        isShowRouterRemind:false,
        ip1: "",
        ip2: "",
        ip3: "",
        ip4: ""
    },


    checkRouterDisable: false,
    isShowNeta21Remind:false,
    isShowRouterRemind:false,
    netaIPAddress: "", //NETA IP地址
    netaUserName: "", //NETA UserName
    netaPassword: "", //NETA Password
    routerSerialNo: "", //路由器序列号
    routerPassword: "", //路由器密码
    routerMACAddress: "", //路由器MAC地址
    sIMICCID: "", //SIM卡ICCID
    deviceVersion: "", //设备版本号
    deviceNodeId:"",
     serialNumber:"",//传动序列号
     gatewaySn:"",//NETA-21序列号 
    ip1: "",
    ip2: "",
    ip3: "",
    ip4: ""
}

const showRemindConfig = {
    visible: false,
    title: '',
    value: '',
    width: 700,
    centered: true,

    type: 0,
    index: 0,
    deviceData: {
        routerSerialNo: "",
        gatewaySn: "",
        devices: []
    },
    devices: []

}


//设备巡检数据
const getExpertsConfig = (state = showServiceConfig, action) => {
    switch (action.type) {
        case actionTypes.SETTING_EXPERTS_SERVICE:
            return action.data;
        default:
            return state;
    }
}

//填写调试信息弹框配置
const getDebugInformationConfig = (state = showDebuggingInformationConfig, action) => {
    switch (action.type) {
        case actionTypes.SETTING_DEBUGING_INFORMATIONN:
            return action.data;
        default:
            return state;
    }
}

//提醒用户调试信息改变配置
const getDebugInformationRemindConfig = (state = showRemindConfig, action) => {
    switch (action.type) {
        case actionTypes.SETTING_DEBUGING_INFORMATIONN_REMIND:
            return action.data;
        default:
            return state;
    }
}



export {
    getExpertsConfig, getDebugInformationConfig, getDebugInformationRemindConfig

};