import React, { Component } from 'react';
import * as echarts from 'echarts';
import { deviceDetail, userCheckDevice, settrendscode, settrendstag } from '@/redux/actions/device';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setIsSignalShowthree } from '@/redux/actions/setting';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
class Echartstest extends Component {
	constructor(props) {
		super(props);
		this.state = {
			options: props.options,
			chartsUpdate: props.chartsUpdate,
			IsSignalShowthree: props.getIsSignalShowthree
		}
	}


	componentDidMount() {
		this.showBarChart();
		window.addEventListener('resize', this.handleSize);
	}
	componentWillUnmount() {
		// 一定要最后移除监听器，以防多个组件之间导致this的指向紊乱
		// window.removeEventListener('scroll', this.handleScroll)
		window.removeEventListener('resize', this.handleSize)
	}
	componentDidUpdate(nextProps, prevState) {
		let objnull = (JSON.stringify(this.props._deviceDetail) === "{}")
		if (objnull === false) {
			if (
				// prevState.options[0].min.length !== this.props.options[0].min.length && 
				prevState.chartsUpdate !== this.props.chartsUpdate
			) {
				this.setState({
					options: this.props.options,
					chartsUpdate: this.props.chartsUpdate
				}, () => {
					this.showBarChart()
				})
			}
			// if (prevState.IsSignalShowthree !== this.props.getIsSignalShowthree) {
			// 	this.setState({
			// 		IsSignalShowthree: this.props.getIsSignalShowthree
			// 	}, () => {
			// 		this.showBarChart()
			// 	})
			// }
		}
	}
	// 自适应浏览器的高度
	handleSize = () => {
		this.showBarChart();
	}
	showBarChart = () => {
		let myChart = [];
		this.state.options.map((item, index) => {
			let charts = echarts.init(
				document.getElementById('charts' + index), null, { renderer: 'svg' }
			)
			charts.resize();
			myChart.push(charts)
			charts.setOption(item.trendsCharts, true);
			charts.dispatchAction({
				type: "takeGlobalCursor",
				key: 'dataZoomSelect',
				dataZoomSelectActive: true
			});

		})
		echarts.connect(myChart)
	}


	restore = (data) => {
		this.setState({
			options: data
		}, () => {
			this.showBarChart();
		})

	}
	chartsdetail = (data) => {
		this.props.settrendscode([{
			name: data.config.label,
			code: data.code,
			id: 20,
			isSignals: 0
		}])
		this.props.settrendstag(data.code);
		this.props.userCheckDevice(7)
	}
	render() {
		return (
			<>
				{
					this.state.options.map((item, index) => {
						return (
							<div key={index} className={(this.props.IsSignalShowthree === 2 || this.props.IsSignalShowthree === 3 || this.props.IsSignalShowthree === 4) ? "signal_char" : "signal_char_deviceNone"}>
								<div className="signal_char_box">
									<div className="char_button_box">
										<div className="detailbutton" onClick={() => this.chartsdetail(item)}>
											<ABB.Icon style={{ color: "#1f1f1f" }} name="abb/information-circle-2" sizeClass='small' />
											<span className="detailtoolip">详情</span>
										</div>

										<span className="restorebutton" onClick={() => this.restore(this.props.options)}>
											{/* <Icon type="sync" /> */}
											<span className="restoretoolip">还原</span>
										</span>
									</div>
									<div style={{ height: '100%', width: '100%' }} id={"charts" + index}></div>
								</div>

							</div>

						)
					})
				}
			</>
		)
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setIsSignalShowthree: data => {
		dispatch(setIsSignalShowthree(data))
	},
	userCheckDevice: data => {
		dispatch(userCheckDevice(data))
	},
	deviceDetail: data => {

		dispatch(deviceDetail(data));
	},
	settrendscode: data => {

		dispatch(settrendscode(data));
	},
	settrendstag: data => {

		dispatch(settrendstag(data));
	},
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Echartstest));
// export default Echartstest;