import React from 'react';
import { Input, Select, Tree, message, Spin, Menu, Radio, Tooltip } from 'antd';
import { orgtree, assetGroupList, changenode, addnode } from '@/api/orgtree.js'
import { connect } from 'react-redux';
import { selectUsergroup, selectequipment } from '@/redux/actions/userlist';
import { setUserInfo } from '@/redux/actions/userInfo';
import { setplant } from '@/redux/actions/plant';
import { loading } from '@/redux/actions/setting';
import Loading from '@/components/loading';
import { setSubscription, setSubid, setSubselect } from '@/redux/actions/subscription'
import { setassetId, settreeValue, settreeCopy, deviceDetail, userCheckDevice, setselect, settree, setTopdevice, settreeStatus, settreeSelect, setRadiovalue } from '@/redux/actions/device';
import { withRouter } from 'react-router-dom';
import { myOrgtree, deviceList, singledeviceList, subscriptionlist, Devicesubscriptionlist } from '@/utils/devicesData'
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { setCBMtype } from '@/redux/actions/cbm'
import { data } from 'jquery';

const { SubMenu } = Menu;
const { Search } = Input;
const treeStatusarr = [];
class Orgtree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isshowloading: true,
            isloading: true,
            searchvalue: this.props.gettreeValue,
            radiovalue: this.props.getRadiovalue,
            radiovalueText: '搜索设备名称/序列号/位置',
            onDragdisplay: {
                display: 'none'
            },
            loading: false,
            treeOpenKeys: 0,
            settree_copy: [],
            value: 1,
            showLine: true,
            showIcon: false,
            parentid: '',//添加子节点parentId
            changeparentId: '',
            changenodename: '',
            addChildname: '',//添加子节点name
            gData: [],
            dataList: [],
            searchValue: '',
            expandedKeys: [],
            autoExpandParent: true,
            selectedKeys: [''],
            defaultOpenKeys: [''],
            treeStatusarr: [],
            openKeys: [''],
            value2: 'Apple',
            department_id: '',
            treeData: [],
            display: 'none',
            isselectId: '',
            isOnfocus: false,
            isdetailOrmanagement: 0,
            listheight: (document.body.clientHeight - 300) + "px",
            RouterLinkIndex: this.props.getRouterLinkIndex
        }
        this.onSelect = this.onSelect.bind(this)
    }

    componentDidMount() {
        if (this.props.gettree.length <= 0) {
            if (this.state.searchvalue === '') {
                this.fetch();
            }

            // this.onSearch(this.state.searchvalue)
            // this.setState({
            //     openKeys: this.props.gettreeStatus
            // })
        }
        else {

        }
        this.props.userCheckDevice(0)
        let isdetailOrmanagement = this.props.location.pathname;
        if (isdetailOrmanagement === '/devicedetails') {
            this.setState({
                isdetailOrmanagement: 10
            })
        }
        else if (isdetailOrmanagement === '/devicemanagement') {
            this.setState({
                isdetailOrmanagement: 30
            })
        }
        window.addEventListener('resize', this.handleSize);
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        window.removeEventListener('resize', this.handleSize);
        this.setState = () => {
            return;
        };
    }
    // 自适应浏览器的高度
    handleSize = () => {
        this.setState({
            listheight: (document.body.clientHeight - 300) + "px"
        })

    }
    //获取订阅列表
    getsublist = (id, type) => {
        let that = this;
        let { getRadiovalue, gettreeValue } = this.props;
        if (getRadiovalue === 0) {
            subscriptionlist('', id, type, getRadiovalue, function (data) {

                that.props.setSubscription(data)
            })
        }
        else {
            subscriptionlist(gettreeValue, id, type, getRadiovalue, function (data) {

                that.props.setSubscription(data)
            })
        }
    };
    FnDevicesubscriptionlist = (id, type) => {
        let that = this;

        let { getRadiovalue, gettreeValue } = this.props;
        if (getRadiovalue === 0) {
            Devicesubscriptionlist('', id, type, getRadiovalue, function (data) {

                that.props.setSubscription(data)
            })
        }
        else {
            Devicesubscriptionlist(gettreeValue, id, type, getRadiovalue, function (data) {
                that.props.setSubscription(data)
            })
        }

    };
    // 选择节点
    onSelect = (key) => {

        let _type = key.item.props.type;
        let groupid = key.item.props.dataref.id;
        const selectedKeys = key.key;
        let _selectedKeys = this.getCaption(selectedKeys, 0)
        this.props.setSubselect('0')
        this.props.userCheckDevice(0)
        this.props.setCBMtype("fan");
        this.onLoadData(key.item)
        if (_selectedKeys === '') {
            return false;
        }
        if (_type === 3) {
            this.props.setTopdevice(true)

            this.props.setSubid({
                type: _type,
                id: groupid,
                orgId: groupid
            })
            this.props.selectequipment([]);
            // this.deviceList(_selectedKeys, this.props.gettreeValue);
            // this.getsublist('/' + _selectedKeys, 0)
        }
        else if (_type === 100) {
            this.props.setTopdevice(false)
            this.props.setSubid({
                type: _type,
                id: groupid,
                orgId: key.item.props.parentid
            })
            this.FnDevicesubscriptionlist('/' + groupid, 0)
            this.FnsingledeviceList(key.item.props.parentid, groupid, this.props.gettreeValue);
        }

        else if (_type === 2) {
            this.props.setSubid({
                type: _type,
                id: _selectedKeys,
                orgId: key.item.props.parentid
            })
            // this.getsublist('/'+_selectedKeys,0)
            this.props.setTopdevice(false)
            this.props.selectequipment([]);
            this.props.setSubscription([])
        }
        this.setState({
            isselectId: '0',
            selectedKeys: selectedKeys
        })
        this.props.settreeSelect({
            isselectId: '0',
            selectedKeys: selectedKeys
        })
        this.props.deviceDetail({})
        this.props.setselect(0);

    };
    //点击获取设备列表接口数据
    deviceList = (org, value) => {
        let that = this;
        that.props.setassetId('')
        if (this.state.isloading === true) {
            this.setState({
                isloading: false,
                isshowloading: false
            })
            setTimeout(() => {
                this.setState({
                    isloading: true,
                    isshowloading: true
                })
            }, 1000)
            let { isdetailOrmanagement, radiovalue } = that.state;
            if (radiovalue === 0) {
                value = ''
            }
            that.props.loading(true);
            if (org !== undefined && org !== '') {
                deviceList(value, org, isdetailOrmanagement, radiovalue, function (data) {
                    that.props.selectequipment(data);
                    that.props.loading(false);
                })
            }
        }
    }

    //点击获取设备列表接口数据
    FnsingledeviceList = (org, agId, value) => {
        let that = this;
        that.props.setassetId('')
        if (this.state.isloading === true) {
            this.setState({
                isloading: false,
                isshowloading: false
            })
            setTimeout(() => {
                this.setState({
                    isloading: true,
                    isshowloading: true
                })
            }, 1000)
            let { isdetailOrmanagement, radiovalue } = that.state;
            if (radiovalue === 0) {
                value = ''
            }
            that.props.loading(true);
            singledeviceList(value, org, agId, isdetailOrmanagement, radiovalue, function (data) {
                that.props.selectequipment(data)
                that.props.loading(false);
            })
        }
    }
    onChange = (e) => {
        let { value } = e.target;
        this.props.settreeValue(value)
        if (value === "") {
            this.setState({
                searchvalue: ''
            })
            this.props.selectequipment([])
            this.props.setSubscription([])
            this.props.settree(this.props.gettreeCopy)
            this.props.settreeSelect({
                isselectId: '0',
                selectedKeys: ''
            })
            this.props.setSubid({
                type: '',
                id: '',
                orgId: '',
            })
        }

    }

    FntreeKey = (data) => {
        if (data.length > 0) {
            let item = data[0];
            if (item.children.length > 0) {
                return this.SearchKey(item, item.children[0]);
            } else {
                return {
                    key: item.key,
                    id: item.id
                };
            }
        }

    }

    SearchKey = (data, childData) => {
        if (childData.children.length > 0) {
            return this.SearchKey(childData, childData.children[0]);
        } else {

            return {
                key: data.key,
                id: childData.id
            };
        }
    }

    onSearch = e => {
        
        if(this.props.gettreeValue.trim().length >= 2){
            const value = e;
            const { gettreeCopy } = this.props;
    
            this.props.settreeValue(value);
            this.props.selectequipment([]);
            this.props.deviceDetail({});
            this.props.setSubscription([])
            this.props.userCheckDevice(0)
            const _gettree = JSON.parse(JSON.stringify(gettreeCopy));

            if (value !== '') {
                let _newgettree = this.searchData(_gettree, value);
                this.setState({
                    searchvalue: value
                })
                if (_newgettree.length === 0) {
                    message.warning("搜索结果为空")
                    this.props.settree([])
                    this.props.settreeSelect({
                        isselectId: '0',
                        selectedKeys: ''
                    })
                }
                else {
                    let defaultSelectKey = this.FntreeKey(_newgettree);
                    let openkey = this.openKeysdata(_newgettree)
                    this.props.settreeStatus(openkey);
                    this.props.settree(_newgettree);
    
                    this.props.settreeSelect({
                        isselectId: '0',
                        selectedKeys: defaultSelectKey.key + "-menu-item_0"
                    })
                    let arr11 = defaultSelectKey.key.split('/');
    
                    // let orgval = _openKeys.substring(strindex + 1, _openKeys.length);
                    // let stropenKeys = this.getCaption(_openKeys, 0)
                    this.props.setSubid({
                        type: 100,
                        id: defaultSelectKey.id,
                        orgId: arr11[0],
                    })
                    this.FnsingledeviceList(arr11[0], defaultSelectKey.id, this.props.gettreeValue);
                    this.FnDevicesubscriptionlist('/' + defaultSelectKey.id, 0)
                }
    
            } else {
                this.setState({
                    searchvalue: value
                })
                this.props.selectequipment([])
                this.props.settree(gettreeCopy)
                this.props.settreeSelect({
                    isselectId: '0',
                    selectedKeys: ''
                })
            }
        }
        

    };
    onfocus = e =>{
        // console.log("1111111111111111111111111")
        this.setState({
            isOnfocus: true
        })
    }
    onBlur = e =>{
        // console.log("2222")
        this.setState({
            isOnfocus: false
        })
    }
    openKeysdata = (data) => {
        data.map(item => {
            // if (item.children.length > 0 ) {
            if (item.key !== undefined) {
                treeStatusarr.push(item.key)
                if (item.children !== undefined && item.children.length > 0) {
                    this.openKeysdata(item.children)
                }
            }
            // else {
            //     // if (item.children !== undefined && item.children.length > 0) {
            //         this.props.settreeSelect({
            //             selectedKeys: treeStatusarr[2]+"-menu-item_0",
            //             isselectId: '0'
            //         })
            //     // }
            // }
        })
        return treeStatusarr;
    }
    searchData = (dataArray, value) => {
        let array = [];
        for (let i = 0; i < dataArray.length; i++) {
            let _data = dataArray[i]
            if (this.state.radiovalue === 0) {

                // if(_data.type != 3) {

                // }
                // if(_data.type!==3){
                // if(_data.type===3){
                //     return false;
                // }
                if (_data.name !== null && _data.name.toLowerCase().indexOf(value.toLowerCase().trim()) !== -1) {
                    if (_data.type !== 100) {
                        array.push(_data);
                    }

                } else {
                    if (_data.children.length > 0) {
                        let childrenNewData = this.searchData(_data.children, value);
                        if (childrenNewData.length > 0) {
                            _data.children = childrenNewData;
                            array.push(_data);
                        }
                    }
                }
                // }

            }
            else if (this.state.radiovalue === 1) {
                if (_data.keyWord !== null && _data.keyWord.toLowerCase().indexOf(value.toLowerCase().trim()) !== -1) {
                    array.push(_data);
                } else {
                    if (_data.children.length > 0) {
                        let childrenNewData = this.searchData(_data.children, value);
                        if (childrenNewData.length > 0) {
                            _data.children = childrenNewData;
                            array.push(_data);
                        }
                    }
                }
            }
            else if (this.state.radiovalue === 2) {

                if (_data.gatewayKeyWord !== null && _data.gatewayKeyWord !== undefined && _data.gatewayKeyWord.toLowerCase().indexOf(value.toLowerCase().trim()) !== -1) {
                    array.push(_data);
                } else {
                    if (_data.children.length > 0) {
                        let childrenNewData = this.searchData(_data.children, value);
                        if (childrenNewData.length > 0) {
                            _data.children = childrenNewData;
                            array.push(_data);
                        }
                    }
                }
            }
        }
        return array;
    }
    getCaption(obj, state) {
        var index = obj.lastIndexOf("\/");
        if (state === 0) {
            obj = obj.substring(0, index);
        } else {
            obj = obj.substring(index + 1, obj.length);
        }
        return obj;
    }

    treechildren = (data, id) => {
        data.map(item => {
            item.key = item.id + "/" + item.type + "/" + item.name;
            item.orgId = id;
            if (item.children.length > 0 && item.ag.length <= 0) {
                // debugger;
                if (item.keyWord === null) {
                    item.children.map(item1 => {
                        item1.key = item1.id + "/" + item1.type + "/" + item1.name;
                        item1.orgId = id;
                        this.treechildren([item1], item1.orgId)
                    })
                }
                else {
                    return false
                }

            }
            if (item.children.length <= 0 && item.ag.length > 0) {
                // debugger;
                if (item.keyWord === null) {
                    item.children.map(item1 => {
                        item1.key = item1.id + "/" + item1.type + "/" + item1.name;
                        item1.orgId = id;
                        this.treechildren([item1], item1.orgId)
                    })
                }
                else {
                    return false
                }

                item.children = [...item.ag];

            }
        })

        return data;
    }
    //组织结构接口数据
    fetch = () => {
        this.setState({
            loading: true
        })
        let that = this;
        myOrgtree('', data => {
            let dataid = data[0].id;
            setTimeout(() => {
                let treedata = that.treechildren([...data], dataid);
                that.setState({
                    defaultOpenKeys: data[0].key,
                    settree_copy: data
                }, () => {

                    that.props.settreeCopy(treedata)
                    that.props.settree(treedata)

                    that.onOpenChange(new Array(data[0].key));
                    that.setState({
                        loading: false
                    })
                })
            }, 400);


        });
    };
    FnSearch = (e) => {
        this.setState({
            addChildname: e.target.value
        })
    }
    SearchChangeNodeName = (e) => {
        this.setState({
            changenodename: e.target.value
        })
    }

    onLoadData = treeNode => {
        new Promise(resolve => {
            if (treeNode.props.dataref.type != '3') {
                // it's not an EU
                resolve();
                return;
            }
            let url = '' + treeNode.props.dataref.id;
            assetGroupList(url).then(data => {
                treeNode.props.dataref.children = data.data;
                treeNode.props.dataref.children.map(item => {

                    item.key = item.id + "/" + item.type;
                    item.orgId = treeNode.props.dataref.id;
                    // item.isLeaf = true;
                })

                this.props.settree([...this.props.gettree])
                resolve();
            });
        });
    }
    isselect = (data) => {
        this.props.settreeSelect({
            isselectId: data.key,
            selectedKeys: data.key
        })
        // if (this.state.isloading === true) {
        //     this.setState({
        //         isloading: false
        //     })
        //     setTimeout(() => {
        //         this.setState({
        //             isloading: true
        //         })
        //     }, 1000)
        //     this.props.settreeSelect({
        //         isselectId: data.key,
        //         selectedKeys: data.key
        //     })
        // }
        // else {
        //     message.warning("组织页面一直")
        // }
        // if (data.type === 3) {
        //     assetGroupList(data.id).then(res => {
        //         data.children = res.data;
        //         data.children.map(item => {

        //             item.key = item.id + "/" + item.type;
        //             item.orgId = data.id;
        //             // item.isLeaf = true;
        //         })

        //         this.props.settree([...this.props.gettree])
        //     })
        // }

    }
    isselectItem = (id, parentid, key) => {
        this.props.settreeSelect({
            isselectId: key,
            selectedKeys: ['']
        })
        this.props.setTopdevice(false)
        this.setState({
            isselectId: key
        })
        // this.FnsingledeviceList(parentid, id);
    }
    renderTreeNodes = (data, id) =>

        data.map(item => {

            if (item.children.length > 0 || item.hasChildren === true) {

                return (
                    <SubMenu
                        parentid={id}
                        type={item.type}
                        title={
                            <div
                                className={this.props.gettreeSelect.isselectId == item.key ? 'ant-menu-submenu-title_active ant-menu-submenu-title_center' : 'ant-menu-submenu-title_center'}
                            >
                                {/* <ABB.Icon style={{marginRight:8}} name="abb/briefcase" sizeClass='small' /> */}
                                {
                                    item.type === 3 ? (
                                        <ABB.Icon style={{ marginRight: 8 }} name="abb/factory" sizeClass='small' />
                                    ) : ''
                                }
                                {
                                    item.type === 2 ? (
                                        <ABB.Icon style={{ marginRight: 8 }} name="abb/briefcase" sizeClass='small' />
                                    ) : ''
                                }
                                <Tooltip placement="right" title={item.name}>
                                    <div className="menu_submenulist">{item.name}</div>
                                </Tooltip>

                            </div>
                        }
                        onTitleClick={() => this.isselect(item)}
                        key={item.key}
                        dataref={item}
                        disabled={item.isVisiable === true ? false : true}>

                        {this.renderTreeNodes(item.children, item.id)}
                    </SubMenu>
                );
            }
            else {
                return <Menu.Item
                    parentid={id}
                    type={item.type}

                    key={item.key}
                    dataref={item}
                    className={this.props.gettreeSelect.isselectId === item.key ? 'ant-menu-submenu-title_active' : ''}
                    // onClick={() => this.isselectItem(item.id, id, item.key)}
                    disabled={item.isVisiable === true ? false : true}>

                    {
                        item.type === 3 ? (
                            <ABB.Icon style={{ marginRight: 8 }} name="abb/factory" sizeClass='small' />
                        ) : ''
                    }
                    {
                        item.type === 2 ? (
                            <ABB.Icon style={{ marginRight: 8 }} name="abb/briefcase" sizeClass='small' />
                        ) : ''
                    }
                    {
                        item.type === 100 ? (
                            // <Icon style={{ marginRight:8}} component={SvgAbbGroup316} />
                            <ABB.Icon style={{ marginRight: 8 }} name="abb/hardware-devices" sizeClass='small' />
                        ) : ''
                    }
                    {/* {item.name} */}
                    <Tooltip placement="right" title={item.name}>
                        <span href="#">{item.name}</span>
                    </Tooltip>
                </Menu.Item>;
            }

        });

    onOpenChange = (openKeys) => {
        let newarropenKeys = [...new Set(openKeys)];

        this.props.settreeStatus(newarropenKeys)
        let { treeOpenKeys } = this.state;
        this.props.setSubselect('0')
        this.props.setTopdevice(true)
        if (newarropenKeys.length > treeOpenKeys) {

            const _openKeys = newarropenKeys.slice(-1).join('')
            var strindex = _openKeys.lastIndexOf("\/");
            let arr11 = _openKeys.split('/');

            // let orgval = _openKeys.substring(strindex + 1, _openKeys.length);
            // let stropenKeys = this.getCaption(_openKeys, 0)
            this.props.setSubid({
                type: arr11[1],
                id: arr11[0],
                orgId: arr11[0],
            })
            if (arr11[1] !== '1' && arr11[1] !== '2') {
                this.deviceList(arr11[0], this.props.gettreeValue);
                this.getsublist('/' + arr11[0], 0)
            }

            console.log("[打开tree获取stropenKeys]：", arr11[0])

        } else if (newarropenKeys.length < treeOpenKeys) {
            console.log('---关闭----')
            // this.props.selectequipment({})
            // this.props.setSubscription([])
        }

        this.setState({
            treeOpenKeys: newarropenKeys.length,
            // isselectId: openKeys.slice(-1),
        })
    }
    // componentDidUpdate(nextProps, prevState){
    //     console.log("prevState.RouterLinkIndex------",prevState.RouterLinkIndex)
    //     console.log("this.props.getRouterLinkIndex------",nextProps.getRouterLinkIndex)
    //     if (prevState.RouterLinkIndex !== this.props.getRouterLinkIndex) {
    //         this.setState({
    //             RouterLinkIndex: this.props.getRouterLinkIndex
    //         }, () => {
    //             console.log("11111111111111111111111---------------")
    //             // this.chartsData(this.props.getCBMdata, this.props.getCBMtype)
    //         })
    //     }
    // }
    onChangeRadio = (e) => {

        let val = e.target.value
        this.setState({
            radiovalue: val,
        })
        this.props.setRadiovalue(val)
        if (val === 0) {
            this.setState({
                searchvalue: '',
                radiovalueText: '组织名称'
            })
        }
        if(val === 1){
            this.setState({
                radiovalueText: '搜索设备名称/序列号/位置'
            })
        }
        if(val === 2){
            this.setState({
                radiovalueText: '搜索网关序列号'
            })
        }
    }
    render() {
        const { gettree, gettreeStatus, gettreeSelect, userInfo } = this.props;
        let { selectedKeys, defaultOpenKeys,isOnfocus, listheight, isshowloading,radiovalueText } = this.state;

        return (
            <div style={{ minWidth: 280 }} className="orgtree_box">


                <div className="orgtree_top">
                    <div className='org_name'>
                        组织结构
                    </div>
                    <div className="orgtreeRadio">
                        <Radio.Group value={this.state.radiovalue} onChange={this.onChangeRadio}>
                            <Radio value={1}>设备</Radio>

                            {
                                userInfo.organizationType !== 'EU' ? (
                                    <>
                                        <Radio value={0}>组织</Radio>
                                        <Radio value={2}>网关</Radio>
                                    </>
                                ) : ''
                            }


                        </Radio.Group>
                    </div>
                    <Search
                        style={{ margin: '8px 0px 2px 9px', width: 260, height: 40 }}
                        placeholder={radiovalueText}
                        value={this.props.gettreeValue}
                        onSearch={this.onSearch}
                        onChange={this.onChange}
                        onFocus={this.onfocus}
                        onBlur={this.onBlur}
                    />
                    {
                        ((this.props.gettreeValue.trim().length < 2 && this.props.gettreeValue.trim().length >= 0) && isOnfocus===true) ? (
                            <div
                                style={{ margin: '0px 0px 10px 9px', fontSize: '10px', color: "#ff0000" }}

                            >*请输入至少2个字符进行搜索</div>
                        ) : ''
                    }

                </div>
                <Spin spinning={this.state.loading} style={{ height: listheight }}>
                    <div className="orgtree_bottom org_tree_scroll" style={{ height: listheight }}>
                        {
                            gettree.length > 0 && gettree[0].name !== null ? (
                                <Menu
                                    // selectedKeys={gettreeSelect.selectedKeys}
                                    defaultOpenKeys={gettreeStatus}
                                    selectedKeys={gettreeSelect.selectedKeys}
                                    openKeys={gettreeStatus}
                                    overflowedIndicator={
                                        <ABB.Icon name="abb/down" sizeClass='small' />
                                    }
                                    inlineIndent={15}
                                    mode="inline"
                                    onSelect={this.onSelect}
                                    onOpenChange={this.onOpenChange}>
                                    {this.renderTreeNodes(gettree, 0)}
                                </Menu>

                            ) : ''
                        }
                    </div>
                </Spin>

                {isshowloading == true ? "" : <Loading />}
                {/* <Loading/> */}
            </div>
        );
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    loading: data => {
        dispatch(loading(data))
    },
    setRadiovalue: data => {
        dispatch(setRadiovalue(data));
    },
    selectUsergroup: data => {
        dispatch(selectUsergroup(data));
    },
    selectequipment: data => {
        dispatch(selectequipment(data))
    },
    setCBMtype: data => {
        dispatch(setCBMtype(data))
    },
    settreeStatus: data => {
        dispatch(settreeStatus(data))
    },
    settreeSelect: data => {
        dispatch(settreeSelect(data))
    },
    setUserInfo: data => {
        dispatch(setUserInfo(data))
    },
    setselect: data => {
        dispatch(setselect(data))
    },
    deviceDetail: data => {
        dispatch(deviceDetail(data))
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    setplant: data => {
        dispatch(setplant(data))
    },
    settree: data => {
        dispatch(settree(data))
    },
    setTopdevice: data => {
        dispatch(setTopdevice(data))
    },
    setSubscription: data => {
        dispatch(setSubscription(data));
    },
    setSubid: data => {
        dispatch(setSubid(data));
    },
    setSubselect: data => {
        dispatch(setSubselect(data))
    },
    settreeCopy: data => {
        dispatch(settreeCopy(data))
    },
    settreeValue: data => {
        dispatch(settreeValue(data))
    },
    setassetId: data => {
        dispatch(setassetId(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Orgtree));
// export default Orgtree;