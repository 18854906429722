import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { connect } from 'react-redux';
import { setCBMdata, setCBMtype } from '@/redux/actions/cbm'
import { withRouter } from 'react-router-dom';
import { userCheckDevice, deviceDetail } from '@/redux/actions/device';


class Dailylife extends Component {
    constructor(props) {
        super(props);
        this.state = {


            cbmdata: props.cbmdata,
            seriesData: {},
            CBMtype: this.props.getCBMtype
        };
    }
    componentDidMount() {
        // this.fnCBMdata();
        this.chartsData(this.state.cbmdata, this.state.CBMtype);

    }
    componentDidUpdate(nextProps, prevState) {
        if (prevState.CBMtype !== this.props.getCBMtype) {
            this.setState({
                CBMtype: this.props.getCBMtype
            }, () => {
                this.chartsData(this.state.cbmdata, this.props.getCBMtype)
            })
        }
    }

    chartsData = (data, type) => {

        if (data !== "") {
            let resdata = data.data;
            resdata.map(item => {
                if (item.unitType === type) {
                    this.optiondata(item);
                }

            })
        }

    }
    optiondata = (item) => {
        let KPIs = item.kpIs;
        let arr = [];
        let tsArray = [];

        KPIs.map((item, index) => {
            tsArray.push(item.date);
            arr.push(item.kpi);
        })
        // console
        let newarr = arr.map((key, index) => [tsArray[index], 0.2, key,this.state.CBMtype])
        this.setState({
            seriesData: {
                title: {
                    text: '日常寿命影响',
                    subtext: ''
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        
                        var htmlStr = '';
                        if(params[0].value[3] === 'fan'){
                            // htmlStr = `<div>${params[0].value[0]}</div>`;
                            if (params[0].value[2] === 0) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>预测性分析不支持此设备，<br/>数据丢失或设备未连接</div><div style="color: #1f1f1f">
                                影响：</div><div>预测性维护计算缺少当天数据</div><div style="color: #1f1f1f">
                                建议：</div><div>检查网络连接</div>`;
                            }
                            if (params[0].value[2] === 0.01) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>适当的运行环境温度</div><div style="color: #1f1f1f">
                                影响：</div><div>正常老化</div><div style="color: #1f1f1f">
                                建议：</div><div>遵循正常维护计划</div>`;
                            }
                            if (params[0].value[2] === 0.5) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>运行环境温度升高</div><div style="color: #1f1f1f">
                                影响：</div><div>对轴承润滑产生额外应力</div><div style="color: #1f1f1f">
                                建议：</div><div>降低运行环境温度</div>`;
                            }
                            if (params[0].value[2] === 1) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>运行环境温度很高</div><div style="color: #1f1f1f">
                                影响：</div><div>加速轴承润滑蒸发和转子磨损</div><div style="color: #1f1f1f">
                                建议：</div><div>降低运行环境温度</div>`;
                            }
                        }
                        if(params[0].value[3] === 'igbt'){
                            if (params[0].value[2] === 0) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>预测性分析不支持此设备，<br/>数据丢失或设备未连接</div><div style="color: #1f1f1f">
                                影响：</div><div>预测性维护计算缺少当天数据</div><div style="color: #1f1f1f">
                                建议：</div><div>检查网络连接</div>`;
                            }
                            if (params[0].value[2] === 0.01) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>在设计范围内运行良好</div><div style="color: #1f1f1f">
                                影响：</div><div>正常老化</div><div style="color: #1f1f1f">
                                建议：</div><div>遵循正常维护计划</div>`;
                            }
                            if (params[0].value[2] === 0.5) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>负荷循环升高了IGBT工作应力</div><div style="color: #1f1f1f">
                                影响：</div><div>热循环加速元器件疲劳</div><div style="color: #1f1f1f">
                                建议：</div><div>减少负荷循环</div>`;
                            }
                            if (params[0].value[2] === 1) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>负荷循环对IGBT产生了很高的应力</div><div style="color: #1f1f1f">
                                影响：</div><div>频繁而严重的热循环和物理形变</div><div style="color: #1f1f1f">
                                建议：</div><div>减少负荷循环</div>`;
                            }
                        }
                        if(params[0].value[3] === 'capacitor'){
                            // htmlStr = `<div>${params[0].value[0]}</div>`;
                            if (params[0].value[2] === 0) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>预测性分析不支持此设备，<br/>数据丢失或设备未连接</div><div style="color: #1f1f1f">
                                影响：</div><div>预测性维护计算缺少当天数据</div><div style="color: #1f1f1f">
                                建议：</div><div>检查网络连接</div>`;
                            }
                            if (params[0].value[2] === 0.01) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>在设计范围内运行良好</div><div style="color: #1f1f1f">
                                影响：</div><div>正常老化</div><div style="color: #1f1f1f">
                                建议：</div><div>遵循正常维护计划</div>`;
                            }
                            if (params[0].value[2] === 0.5) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>直流母线电压及电容温度升高</div><div style="color: #1f1f1f">
                                影响：</div><div>元器件参数漂移到设计要求范围以外</div><div style="color: #1f1f1f">
                                建议：</div><div>改善模块散热（降低运行环境温度）</div>`;
                            }
                            if (params[0].value[2] === 1) {
                                htmlStr = `<div>${params[0].value[0]}</div><div style="color: #1f1f1f">
                                原因：</div><div>直流母线电压较高和/或很高的电容温度</div><div style="color: #1f1f1f">
                                影响：</div><div>加速电解质退化</div><div style="color: #1f1f1f">
                                建议：</div><div>改善模块散热（降低运行环境温度）<br/>检查直流母线电压水平</div>`;
                            }
                        }

                        
                        return htmlStr;
                    },
                    axisPointer: {
                        // show: false           // 坐标轴指示器，坐标轴触发有效
                        type: 'none'        // 默认为直线，可选为：'line' | 'shadow'

                    }
                },
                grid: {
                    left: '8px',
                    right: '0px',
                    top: '120px',
                    bottom: '0px',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        // show: false,
                        formatter: function (value) {
                            var date = new Date(value);
                            var year = date.getDate();
                            return year;
                        },
                        color: '#686868',
                        interval: 0,
                        // rotate: -40,
                    },
                    splitLine: {
                        show: false
                    },

                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fff'
                        }
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        show: false,
                        formatter: "{value}",
                        color: '#686868',
                        interval: 0,

                    },
                    splitLine: {
                        show: false
                    },
                },
                series: [
                    {
                        name: 'Direct',
                        type: 'bar',

                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    var index_color = params.value[2];
                                    if (index_color === 0) {
                                        return '#bababa';
                                    }
                                    else if (index_color === 0.01) {
                                        return '#477a43';
                                    }
                                    else if (index_color === 0.5) {
                                        return '#ffd800';
                                    }
                                    else if (index_color === 1) {
                                        return '#f13040';
                                    }
                                }
                            }

                        },

                        data: newarr
                    }
                ]
            }
        })


    }
    render() {
        return (

            <div className="charts_flex">


                <div className="charts_flex_left_dailylife">

                    <ReactEcharts
                        option={this.state.seriesData}
                        style={{ height: '100%', width: '100%' }}
                        opts={{ renderer: 'svg' }}
                        className='react_for_echarts'
                    />
                </div>
                <div className="charts_flex_right charts_flex_right_daily">

                    <div className="list_icon list_icon4">高</div>
                    <div className="list_text">
                        当前运行环境及使用情况对预期寿命有较大影响
                    </div>
                    <div className="list_icon list_icon3">中等</div>
                    <div className="list_text">
                        当前运行环境及使用情况对预期寿命有轻微影响
                    </div>
                    <div className="list_icon list_icon2">低</div>
                    <div className="list_text">
                        当前运行环境及使用情况对预期寿命无影响
                    </div>
                    <div className="list_icon list_icon1">未知</div>
                    <div className="list_text">
                        预测性维护不可用
                    </div>

                </div>
            </div>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setCBMdata: data => {
        dispatch(setCBMdata(data))
    },
    setCBMtype: data => {
        dispatch(setCBMtype(data))
    },
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Dailylife));
// export default Dailylife;