import React from 'react';
import { Select, Modal } from 'antd';
import * as ABB from 'abb-common-ux-react';

import 'abb-common-ux-react/styles.css';
const { Option } = Select;

class AvailableOrgListModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedOrgId: null
		};
	}

	handleChange = value => {

		this.setState({
			selectedOrgId: value
		});
	};

	handleOk = () => {
		this.props.changeOrglistVisible();
		if (this.state.selectedOrgId) {
			this.props.updateUserOrgMapping(this.state.selectedOrgId);
			// // 手动强制刷新页面
			// window.location.reload();
		}
	};

	render() {
		const { visible, changeOrglistVisible, orgList, userOrg, disabled } = this.props;
		const modelProps = {
			title: '可选组织列表',
			visible: visible,
			centered: true,
			onOk: () => {
				this.handleOk();
			},
			onCancel: () => {
				changeOrglistVisible();
			},
			footer: [<ABB.Button onClick={() => changeOrglistVisible()} type="discreet-black" sizeClass="small" theme="filled" text="取消" />, 
			<ABB.Button onClick={() => this.handleOk()} disabled={disabled} type="primary-blue" sizeClass="small" theme="filled" text="确定" />]
		};
		return (
			<>
				<Modal {...modelProps}>
					<Select defaultValue={userOrg} suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />} style={{ width: "100%", marginRight: '64px' }} onChange={this.handleChange}>
						{orgList.map((item, index) => (
							<Option value={item.id} key={index}>
								{item.name}
							</Option>
						))}
					</Select>
				</Modal>
			</>
		);
	}
}

export default AvailableOrgListModal;
