import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import IEVersion from "./IEChecker.js";

let signinSignupConfig = {
    auth: {
        //   authority: "https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd",
        //   clientId: "e29fc4a8-5e23-42b9-866c-9b727dc27342",
        //   redirectUri: "https://dev.digital.motion.abb.com.cn",

        postLogoutRedirectUri: window.location.origin,

        authority: process.env.REACT_APP_B2C_PHONE_LOGIN_URL,
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectUri: window.location.origin,
        validateAuthority: false,

        // After being redirected to the "redirectUri" page, should user
        // be redirected back to the Url where their login originated from?
        navigateToLoginRequestUrl: false
    },
    // Enable logging of MSAL events for easier troubleshooting.
    // This should be disabled in production builds.
    system: {
        logger: new Logger(
            (logLevel, message, containsPii) => {
                console.log("[MSAL]", message);
            },
            {
                level: LogLevel.Verbose,
                piiLoggingEnabled: false
            }
        )
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

let isIE = IEVersion()
//console.log(isIE,"---判断是否是IE----")
if(isIE!==-1){
    //console.log("IE浏览器")
    signinSignupConfig.cache.cacheLocation = "localStorage"
    signinSignupConfig.cache.storeAuthStateInCookie = true
}
else{
    //console.log("非IE浏览器")
    signinSignupConfig.cache.cacheLocation = "sessionStorage"
    signinSignupConfig.cache.storeAuthStateInCookie = false
}

const signinSignupParameter = {
    scopes: [process.env.REACT_APP_B2C_SCOPES],
    authority: process.env.REACT_APP_B2C_PHONE_LOGIN_URL,
};
const signinSignupOption = {
    loginType: LoginType.Redirect,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    tokenRefreshUri: window.location.origin + '/swagger/token.html'
};

export {
    signinSignupConfig,
    signinSignupParameter,
    signinSignupOption
}

