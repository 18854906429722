import React, { Component } from 'react';
import { Input, Table, Tooltip, message, Spin } from 'antd';
import { ABBsubscription } from '@/api/subscription';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import WrappedRegistrationForm from './components/createsub';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Search } = Input;
const Tooltip_print = <span>导出PDF</span>;
class ABBsub extends Component {
	state = {
		subscriptionlist: [],
		pagination: {},
		loading: false,
		modal2Visible: false,
		selectedRowKeys: [],
		subscriptionlist_copy: [],
		pageindex: 1,
		pageSize: 10,
		location: '###',
		exportpdfUrl: 'https://e5bb286fc1a9444b81f98fe3d9d64e98.apig.cn-north-4.huaweicloudapis.com/Create_docx?',
		columns: [
			{
				title: '订阅序列号',
				dataIndex: 'subscriptionCode',
				key: '0',
				width: '24%'
			},
			{
				title: '订阅等级',
				dataIndex: 'subscriptionLevel',
				key: '1',
				width: '5%'
			},
			{
				title: '订阅类型',
				dataIndex: 'subscriptionType',
				key: '11',
				width: '5%'
			},
			{
				title: '适用设备',
				dataIndex: 'deviceType',
				key: '2',
				width: '5%'
			},
			{
				title: '订阅码有效期',
				dataIndex: 'validDateTime',
				key: '3',
				width: '12%'
			},
			{
				title: '订阅服务期',
				dataIndex: 'validPeriodTime',
				key: '12',
				width: '6%'
			},
			{
				title: '使用状态',
				dataIndex: 'usedStatus',
				key: '4',
				width: '5%'
			},
			{
				title: '生成时间',
				dataIndex: 'generatedDatetime',
				key: '8',
				width: '12%'
			},
			{
				title: '生成人',
				dataIndex: 'generatedUser',
				key: '9',
				width: '9%'
			},
			{
				title: '绑定客户',
				dataIndex: 'customerName',
				key: '6',
				width: '15%'
			},
			{
				title: '绑定设备',
				dataIndex: 'deviceName',
				key: '7',
				width: '10%'
			},
			{
				title: '操作',
				dataIndex: '',
				key: '10',
				width: '4%',
				render: (text, record) => (
					<Tooltip placement="bottom" title={Tooltip_print}>
						<a href={this.state.location} onClick={() => this.exportPdf(record)}>
							<ABB.Icon name="abb/print" sizeClass="small" />
						</a>
					</Tooltip>
				)
			}
		]
	};

	UNSAFE_componentWillMount() {
		this.fetch(1, '');
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	//导出订阅pdf
	exportPdf = data => {
		let that = this;
		let { exportpdfUrl } = this.state;
		that.setState({
			location: `${exportpdfUrl}subscriptionCode=${data.subscriptionCode}&subscriptionLevel=${data.subscriptionLevel}&validDateTime=${data.validDateTime}&deviceType=${data.deviceType}&validPeriodTime=${data.validPeriodTime}&subscriptionType=${data.subscriptionType}`
		});
	};
	initdata = () => {
		this.fetch(1, '');
	};
	// 搜索数据
	onserch = value => {
		if (value !== '') {
			this.fetch(1, value.toUpperCase().replace(/\s*/g, ''));
		} else {
			this.fetch(1, '');
		}
	};
	//接口数据
	fetch = (pageindex, value) => {
		this.setState({
			loading: true
		});
		let url = `?pagesize=${this.state.pageSize}&pageindex=${pageindex}&keywords=${value}`;
		ABBsubscription(url)
			.then(res => {
				if (res !== undefined) {
					let resdata = res.data;
					resdata.rawData.forEach((item, index) => {
						if (item.deviceType === 'Drive') {
							item.deviceType = '传动';
						}
						if (item.deviceType === 'Motor') {
							item.deviceType = '电机';
						}
						if (item.deviceType === 'Powetrain') {
							item.deviceType = '传动链';
						}
						if (item.customerName === '' || item.customerName === null) {
							item.customerName = '--';
						}
						if (item.deviceName === '' || item.deviceName === null) {
							item.deviceName = '--';
						}
						item.generatedDatetime = moment.parseZone(item.generatedDatetime).local().format('YYYY-MM-DD HH:mm:ss');
						item.validDateTime = moment.parseZone(item.validDateTime).local().format('YYYY-MM-DD HH:mm:ss');
					});
					this.setState({
						subscriptionlist_copy: resdata,
						subscriptionlist: resdata,
						loading: false
					});
				}
			})
			.catch(err => {
				this.setState({
					loading: false
				});
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data);
				} else {
					message.error('订阅列表接口异常');
				}
			});
	};
	onChange = e => {
		this.setState({
			pageindex: e
		});
		this.fetch(e, '');
	};
	render() {
		let { pageSize, pageindex } = this.state;
		let { userInfo } = this.props;
		return (
			<>
				<div className="user_box ABB_tab_marginTop">
					{userInfo.isOrgSuperAdmin === true ? <WrappedRegistrationForm initdata={this.initdata} /> : <div></div>}

					<Search placeholder="请输入订阅码/设备序列号" onSearch={this.onserch} style={{ width: 190 }} className="search_input" />
				</div>
				<Spin spinning={this.state.loading} style={{ height: '90vh' }}>
					<Table
						size="small"
						scroll={{ x: 1140 }}
						columns={this.state.columns}
						dataSource={this.state.subscriptionlist.rawData}
						pagination={{
							onChange: page => this.onChange(page),
							pageSize: pageSize,
							current: pageindex,
							total: this.state.subscriptionlist.totalCount
						}}
						rowKey={(record, index) => index}
					/>
				</Spin>
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ABBsub));
