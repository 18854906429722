import React, { Component } from 'react';
import { Modal, Button, Input, message, Form, Select } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { addeventfilter,changeeventfilter } from '@/api/eventfilter.js';

const { Search, TextArea } = Input;
class AddFilterForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: false,
            disabledbutton: false,
            isZn_CH: false
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel()
        this.props.form.resetFields();
    }
    AddFilterFn = (user, data) => {
        this.setState({
            disabledbutton: true
        })
        let allDrive = false;
        if (data.driveType === "All Drive") {
            allDrive = true;
        }
        else {
            allDrive = false;
        }
        let url = {
            driveType: data.driveType,
            allDrive: allDrive,
            keyWords: data.keyWords,
            status: data.status,
            description: data.description,
            createdUser: user.createdUser,
            userId: user.userId
        };

        addeventfilter(url).then(res => {
            if (res !== undefined) {
                message.success("添加黑名单规则成功")
                this.setState({
                    disabledbutton: false
                })
                this.props.submitmodel();
                this.hidemodel()
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data);
            } else {
                console.log("AddFilterFn -- addeventfilter --接口异常")
                message.error('接口异常');
            }
        });
    }
    ChangeFilterFn = (user, data) => {
        this.setState({
            disabledbutton: true
        })
        let allDrive = false;
        if (data.driveType === "All Drive") {
            allDrive = true;
        }
        else {
            allDrive = false;
        }
        let url = {
            id: user.id,
            driveType: user.detail.driveType,
            allDrive: allDrive,
            keyWords: data.keyWords,
            status: data.status,
            description: data.description,
            createdUser: user.createdUser,
            userId: user.userId
        };

        changeeventfilter(url).then(res => {
            if (res !== undefined) {
                message.success("编辑黑名单规则成功")
                this.setState({
                    disabledbutton: false
                })
                this.props.submitmodel();
                this.hidemodel()
                
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data);
            } else {
                console.log("ChangeFilterFn -- changeeventfilter --接口异常")
                message.error('接口异常');
            }
        });
    }
    submitmodel = (data) => {
        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                if(data.type === 2){
                    this.ChangeFilterFn(data, fieldsValue);
                }
                else{
                    this.AddFilterFn(data, fieldsValue);
                }
                
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };
    render() {
        const that = this;
        const { showmodel } = this.props;
        const { getFieldDecorator } = that.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showmodel.title,
            centered: true,
            width: 354,
            maskClosable: false,
            visible: showmodel.visible,
            onOk: () => { that.hidemodel() },
            onCancel: () => { that.hidemodel() },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel()}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodel)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    disabled={that.state.disabledbutton}
                    text="确定"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>

                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>
                    {
                        showmodel.type !== 2 ? (
                            <Form.Item label="产品类型">
                                {getFieldDecorator('driveType', {
                                    initialValue: showmodel.detail !== '' ? showmodel.detail.driveType : undefined,
                                    rules: [
                                        {
                                            required: true,
                                            message: '产品类型不能为空',
                                        }
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        placeholder="请选择产品类型"
                                        optionFilterProp="children"
                                        suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
                                        style={{ width: '100%', marginRight: '64px' }}
                                    >
                                        {
                                            showmodel.alldrivetypelist.length > 0 ? (
                                                showmodel.alldrivetypelist.map((item, index) => {
                                                    return (
                                                        <Select.Option value={item} key={index}>
                                                            {item}
                                                        </Select.Option>
                                                    )
                                                })
                                            ) : ''

                                        }
                                    </Select>
                                )}
                            </Form.Item>

                        ) : ''
                    }

                    <Form.Item label="状态">
                        {getFieldDecorator('status', {
                            initialValue: showmodel.detail !== '' ? showmodel.detail.status : 1,
                            rules: [

                                {
                                    required: true,
                                    message: '状态不能为空',
                                }
                            ],
                        })(
                            <Select
                                showSearch
                                placeholder="请选择状态"
                                optionFilterProp="children"
                                suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
                                style={{ width: '100%', marginRight: '64px' }}

                            >
                                {/* <Select.Option value={2} key={2}>
                                    全部
                                </Select.Option> */}
                                <Select.Option value={1} key={1}>
                                    启用
                                </Select.Option>
                                <Select.Option value={0} key={0}>
                                    禁用
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item label="描述">
                        {getFieldDecorator('description', {
                            initialValue: showmodel.detail !== '' ? showmodel.detail.description : undefined,
                            rules: [
                                {
                                    required: true,
                                    message: '描述不能为空',
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            <Input placeholder="请输入描述" className="model_input" />,
                        )}
                    </Form.Item>
                    <Form.Item label="过滤关键字">
                        {getFieldDecorator('keyWords', {
                            initialValue: showmodel.detail !== '' ? showmodel.detail.keyWords : undefined,
                            rules: [
                                {
                                    required: true,
                                    message: '过滤关键字不能为空',
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            <TextArea
                                className="model_input"
                                style={{ marginTop: 5 }}
                                rows={4}
                                placeholder='请输入过滤关键字'
                            />
                        )}
                    </Form.Item>
                </Form>

            </Modal>
        );
    }
}
const AddFilter = Form.create({ name: 'register' })(AddFilterForm);

export default AddFilter;
