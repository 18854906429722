import React from 'react';
import {

    Modal,
    message,
    Row,
    Col,
} from 'antd';
import { boundsubscription } from '@/api/subscription';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class BoundsubConfigForm extends React.Component {
    state = {
        inputval: '',
        valiededCode: '',
        subdata: {},
        disabled: false
    };

    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.setState({
            inputval: '',
            subdata: {}
        })
    }
    
    submitmodel = (isActivatedNow,subdata,deviceData) => {
        // this.resboundSub(data)
        console.log('提交')

        this.setState({
            disabled: true
        })
        let params = {
            "assetId": deviceData.assetId,
            "subscriptionCode": subdata.subscriptionCode,
            "isSubAsset": deviceData.isSubAsset,
            "isActivatedNow":isActivatedNow   //当前订阅为体验版或者不小于新增订阅,可以直接请求绑定接口,此时传false,否则需要提示用户是否马上生效
        }

   
        boundsubscription(params).then(res => {
            message.success("绑定订阅成功")
            this.props.submitmodel()
            this.setState({
                disabled: false,
                inputval: '',
                subdata: {}
            })
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data.errorMessage)
            }
            else {
                message.warning("绑定订阅失败")
            }
            this.setState({
                disabled: false
            })
        })

    }

    render() {
        let that = this;
        const { visible,subdata,deviceData} = that.props.showmodelboundsubConfig;
 
      
        const modelProps = {
            title: '订阅绑定通知',
            centered: true,
            width: 504,
            maskClosable: false,
            visible: visible,
           
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.submitmodel(false,subdata,deviceData)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    disabled={this.state.disabled}
                    text="绑定为待激活订阅"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(true,subdata,deviceData)}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="立即生效"
                />,

            ]
        }

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium'  />
                    }
                >
                    <div className='subconfig_box'>
                        <div>{deviceData.deviceData.assetName}（序列号：{deviceData.deviceData.assetSrNo}）</div>
                        <div className='sub_list'>
                            <div className='using'>  使用中  </div>
                            <div className="subscribeLevel"> 订阅等级：{deviceData.deviceData.subscriptionLevel} </div>
                            <div> 订阅结束时间：{deviceData.deviceData.expiredDateTime}</div>
                            {/* <div> 订阅结束时间：{item.expiredDateTime === null ? '--' : moment.parseZone(item.expiredDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div> */}
                        </div>

                        <div className='sub_list'>
                            <div  className='binding'> 绑定中 </div>
                            <div className="subscribeLevel"> 订阅等级： {subdata.subscriptionLevel} </div>
                            <div> 订阅码有效期：{subdata.validDateTime}</div>
                            {/* <div> 订阅结束时间：{item.expiredDateTime === null ? '--' : moment.parseZone(item.expiredDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div> */}
                        </div>
                        <div className="sub_bottom_content">
                        <ABB.Icon className="sub_bottom_icon" name="abb/warning-triangle" sizeClass='small' color='#F03040'/>
                        <div  className='sub_bottom_title'>待绑定的设备订阅等级<span style={{color:"#3366ff"}}>高于</span>使用中的订阅等级，需要立即生效替换使用中订阅，以此来享受<span style={{color:"#3366ff"}}>高等级</span>的设备服务吗？</div>
                        </div>
                        
                    </div>
                </Modal>
            </>

        );
    }
}

export default BoundsubConfigForm;