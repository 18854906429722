import $axios from '../axios/$axios';

export function devicereportlist(data) {
   return $axios({
      url: '/api/v1/devicereport/list' + data,
      method: 'get',
      type: 'json',
      data: data
   })
}

export function devicereportdownload(data) {
   return $axios({
      url: '/api/v1/devicereport/dowload' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function seiralnumberlist(data) {
   return $axios({
      url: '/api/v1/devicereport/seiralnumber/list' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function templatelist(data) {
   return $axios({
      url: '/api/v1/devicereport/template' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function devicereportcreate(data,isSubAsset) {
   return $axios({
      url: '/api/v1/devicereport/create'+isSubAsset,
      method: 'post',
      type: 'json',
      data: data
   })
}