import $axios from '../axios/$axios';

export function backuplist(data) {
   return $axios({
      url: '/api/v3/device/parameter/backup/list'+data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function backupdownload(data) {
    return $axios({
       url: '/api/v3/device/parameter/backup/download'+data,
       method: 'get',
       type: 'json',
    //    responseType : 'blob',
       data: ''
    })
 }
 export function backupchangelist(data) {
   return $axios({
      url: '/api/v1/device/parameter/changes/'+data,
      method: 'get',
      type: 'json',
      data: ''
   })
}