import React, { Component } from 'react';
import { Modal,Button,Input,Radio,Row, Col,message, Cascader, Select } from 'antd';
import 'abb-common-ux-react/styles.css';
import {orgtree} from '@/api/orgtree'
import {APIOrgPlants} from '@/api/tools'
import * as ABB from 'abb-common-ux-react';

const { Search } = Input;
const { Option } = Select;

class moveOrgGroup extends Component {
	constructor(props) {
		super(props);
		this.state = {
            
            inputval: [],
            assetOptions: [],
            assetCascaderValue: [],
 
		};
		
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    // 获取组织、asset树
    componentDidMount() {
        this.getOrgAssetGroupTreeList();
    }
    componentDidUpdate(prevProps) {
        if (this.props.showmodelMove.visible && !this.state.assetOptions) {
            message.info('正在加载设备组')
            this.getOrgAssetGroupTreeList();
        }
    }
    //获取设备组列表接口
	getOrgAssetGroupTreeList = () => {
		// let url = `/${this.props.userGroupId}`;
        let url = ``;
		orgtree(url).then(res => {
			if (res !== undefined) {
                const assetOptions = JSON.parse(JSON.stringify(res.data).replace(/"name"/g,'"label"').
                replace(/"id"/g,'"value"').replace(/"children":\[\],/g, "").replace(/"ag"/g,'"children"'));
                
                this.setState({
                    assetOptions: [assetOptions]
                })
				// this.setState({
				// 	assetGroups: res.data,
                    
				// })
                // res.data.owned.forEach((item,index) => {
                //     this.setState({
                //         inputval: [...this.state.inputval, item.id],
                //         ownedAssetGroups: [...this.state.ownedAssetGroups, item],
                //     })
                // })
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.error(err.data)
			}
			else {
				message.error('获取组织列表失败')
			}

		})
	}
 
    hidemodel=(data)=>{
        this.props.hidemodel(data)
        this.setState({
            inputval: '',
            assetCascaderValue: []

        })
    }
    submitmodel=()=>{
        if(  this.state.assetCascaderValue !== undefined){
            
            const assetGroupId = this.state.assetCascaderValue[this.state.assetCascaderValue.length - 1];
            const data = {
                targetAssetGroupId: assetGroupId,
             
            }
            this.props.submitmodel(data)
            this.setState({
                inputval: '',
             
                assetCascaderValue: []
            })
        } 
        
        else{
            message.warning("请选择设备组")
        }
        
    }
    
    onChange = (value, selectedOptions)=> {
        this.setState({
            assetCascaderValue: value,
       
        })
    }
 
    filter = (inputValue, path)=>{
        return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
    displayRender = (label) => {
        return label[label.length - 1];
    }
	render() {
        const that = this;
        const {showmodelMove} = this.props;
        
        const modelProps = {
            title: showmodelMove.title,
            centered: showmodelMove.centered,
            width:showmodelMove.width,
            maskClosable: false,
            visible:showmodelMove.visible,
            onOk:() => {that.hidemodel(false)},
            onCancel:() => {that.hidemodel(false)},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,          
            ]
        }
        
		return (
			<Modal {...modelProps}
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                <div>
                    <span>选择设备组：</span>
                    <Cascader
                        size="middle"
                        options={this.state.assetOptions}
                        suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
                        onChange={this.onChange}
                        // expandIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
                        placeholder="输入设备组/组织名称"
                        showSearch={{filter: this.filter, matchInputWidth: false}}
                        displayRender={this.displayRender}
                        value={this.state.assetCascaderValue} 
                        // style={{borderRadius: 0,border:'1px solid #'}}
                        className="ant-select-selection"
                    />
                </div>
 
            </Modal>
		);
	}
}

export default moveOrgGroup;
