
import React, { Component } from 'react';
import { Input, Table, message, Tooltip } from 'antd';
import { customersList, APIchangeCP,OrganizationRegInfo,EditCustomerName } from '@/api/client';
import { assetGroupList } from '@/api/orgtree.js'
import moment from 'moment';
import UserModel from './components/adduserGroup';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { myOrgtree } from '@/utils/devicesData'
import { connect } from 'react-redux';
import { ABBorCPcreateEU } from '@/api/user.js';
import { settreeCopy, settree, settreeValue, settreeSelect, settreeStatus } from '@/redux/actions/device';
import { withRouter } from 'react-router-dom';
import CreateEUForm from './components/createEU'

const { Search } = Input;

const Tooltip_change = <span>编辑</span>;
class CPmanager extends Component {
    state = {
        customersid: '',
        customersName: '',
        modeltype: '',
        clientlist: [],
        clientlist_copy: [],
        pagination: {},
        showmodel: {},
        selectedRowKeys: [],
        showmodelEU: {},
        columns: [
            {
                title: '编号',
                dataIndex: 'index',
                key: 'index',
                width: 50,
            },
            {
                title: '客户名称',
                dataIndex: 'name',
                key: '0',

            },

            {
                title: '认证客户名称',
                dataIndex: 'aliasName',
                key: '1',

            },
            {
                title: '地址信息',
                dataIndex: 'address',
                key: '2',

            },
            {
                title: '管理员',
                dataIndex: 'adminContact',
                key: '3',

            },
            {
                title: '联系电话',
                dataIndex: 'contactPhone',
                key: '4',

            },
            {
                title: '创建时间',
                dataIndex: 'createdOn',
                key: '5',

            },
            {
                title: '操作',
                dataIndex: '',
                key: '6',
                width: '5%',
                render: (text, record) => (
                    <>
                        {
                            this.props.userInfo.organizationSubType !== "Float" ? (
                                <Tooltip placement="bottom" title={Tooltip_change}>
                                    <span onClick={() => this.onchangeName(record)}>
                                        <ABB.Icon name="abb/edit" sizeClass='small' />
                                    </span>
                                </Tooltip>
                            ) : ''
                        }
                    </>



                )
            }
        ]
    };

    UNSAFE_componentWillMount() {
        this.fetch();
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    treechildren = (data, id) => {
        data.map(item => {
            item.key = item.id + "/" + item.type;
            item.orgId = id;
            if (item.children.length > 0 && item.ag.length <= 0) {
                // debugger;
                if (item.keyWord === null) {
                    item.children.map(item1 => {
                        item1.key = item1.id + "/" + item1.type;
                        item1.orgId = id;
                        this.treechildren([item1], item1.orgId)
                    })
                }
                else {
                    return false
                }

            }
            if (item.children.length <= 0 && item.ag.length > 0) {
                // debugger;
                if (item.keyWord === null) {
                    item.children.map(item1 => {
                        item1.key = item1.id + "/" + item1.type;
                        item1.orgId = id;
                        this.treechildren([item1], item1.orgId)
                    })
                }
                else {
                    return false
                }

                item.children = [...item.ag];

            }
        })

        return data;
    }
    //组织结构接口数据
    Fnorgtree = () => {
        let that = this;
        myOrgtree('', data => {
            let dataid = data[0].id
            let treedata = that.treechildren([...data], dataid);
            that.props.settreeCopy(treedata)
            that.props.settree(treedata)
            that.props.settreeStatus(new Array(data[0].key))
        });
    };
    //搜索客户
    onSearch = (val) => {
        let _self = this
        let clientlist = _self.state.clientlist_copy;//原始数据
        let deviceArr = []
        const value = val;//获取输入值
        if (val !== '') {
            let _value = val.toLowerCase().trim()
            //检查原始数据中是否有该内容
            for (let i = 0; i < clientlist.length; i++) {
                let device = clientlist[i]
                if (
                    (device.name !== null && device.name.toLowerCase().indexOf(_value) !== -1) ||
                    (device.aliasName !== null && device.aliasName.toLowerCase().indexOf(_value) !== -1) ||
                    (device.contactPhone !== null && device.contactPhone.toLowerCase().indexOf(_value) !== -1)
                ) {
                    let _deviceTag = deviceArr.indexOf(device);
                    if (_deviceTag === -1) {
                        deviceArr.push(device)
                    }
                }
            }
            _self.setState({
                clientlist: deviceArr
            })
        } else {
            _self.fetch()
        }
    }
    onChange = (e) => {
        if (e.target.value === '') {
            this.fetch()
        }
    }
    onchangeName = (data) => {
        OrganizationRegInfo(data.id).then(res => {
            const resdata = res.data.payload
            this.setState({
                customersid: data.id,
                customers: resdata.creditCode && { label: resdata.aliasName, key: resdata.creditCode } || undefined
            }, () => {
                this.showmodel(true, '编辑备注名称', 1)
            })
        })
    }
    fetch = () => {
        let url = ""
        customersList(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                resdata.forEach((item, index) => {
                    var utcDate = item.createdOn;
                    item.index = index + 1;
                    if (item.address === '' || item.address === null) {
                        item.address = '--'
                    }
                    if (item.aliasName === '' || item.aliasName === null) {
                        item.aliasName = '--'
                    }
                    if (item.createdOn === '' || item.createdOn === null) {
                        item.createdOn = '--'
                    }
                    else {
                        item.createdOn = moment.parseZone(item.createdOn).local().format('YYYY-MM-DD HH:mm:ss');
                    }
                    if (item.adminContact === '' || item.adminContact === null) {
                        item.adminContact = '--'
                    }
                    if (item.contactPhone === '' || item.contactPhone === null) {
                        item.contactPhone = '--'
                    }
                    if (item.children.length <= 0) {
                        delete item.children
                    }
                    else {
                        item.children.forEach((items, index) => {
                            if (items.children.length <= 0) {
                                delete items.children
                            }
                            if (items.address === '' || items.address === null) {
                                items.address = '--'
                            }
                            if (items.aliasName === '' || items.aliasName === null) {
                                items.aliasName = '--'
                            }
                            if (items.createdOn === '' || items.createdOn === null) {
                                items.createdOn = '--'
                            }
                            else {
                                items.createdOn = moment.parseZone(items.createdOn).local().format('YYYY-MM-DD HH:mm:ss');
                            }
                            if (items.adminContact === '' || items.adminContact === null) {
                                items.adminContact = '--'
                            }
                            if (items.contactPhone === '' || items.contactPhone === null) {
                                items.contactPhone = '--'
                            }
                        })
                    }
                });
                this.setState({
                    clientlist_copy: resdata,
                    clientlist: resdata
                })
            }
        }).catch(err => {
            if (err.status === 400) {
                message.warning(err.data)
            }
            else if (err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('客户列表接口异常')
            }
        })
    };
    APIchangeCP = (val,func) => {
        if (this.state.showmodel.value && val.company.label === this.state.showmodel.value.label) {
            this.setState({
                showmodel: {
                    visible: false,
                    title: '',
                    value: '',
                    width: 354,
                    centered: true,
                }
            })
            return false;
        }
        EditCustomerName({
            organizationId: this.state.customersid,
            organizationName: val.company.label,
            creditCode: val.company.key.indexOf('null-') === -1 ? val.company.key : '',
        }).then(res => {
            message.success("编辑成功")
            func()
            this.setState({
                showmodel: {
                    visible: false,
                    title: '',
                    value: '',
                    width: 354,
                    lable: '',
                    centered: true,
                }
            })
            this.fetch()
            this.Fnorgtree()
            this.props.settreeSelect({
                isselectId: 0,
                selectedKeys: ['']
            })
            this.props.settreeValue('')
        }).catch(err => {
            if (err.status === 400) {
                message.warning(err.data)
            }
            else if (err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("APIchangeCP接口异常")
                message.error('接口异常')
            }
        })


    }
    APIcreateEU = (val,func) => {
    
        console.log("--------3333")
        let url = {
            "name": val.company.label,
            "creditCode": val.company.key.indexOf('null-') === -1 ? val.company.key : '',
            "industry": val.industry ,
            "province": "",
            "city": "",
            "district": "",
            "address": val.companyaddress.trim(),
            // "contact": val.name.trim(),
            "phone": val.phone.trim(),
            "description": ""
        }
        ABBorCPcreateEU(url).then(res => {
            message.success("终端用户添加成功")
            func()

            this.setState({
                showmodelEU: {
                    visible: false,
                    title: '',
                    value: '',
                    width: 354,
                    centered: true,
                }
            })
            this.fetch()
        }).catch(err => {
            if (err.status === 400) {
                message.warning(err.data)
            }
            else if (err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('添加客户接口异常')
            }

        })
    }
    //添加CP
    showmodelEU = (showmodel, title, type) => {
        this.setState({
            modeltype: type,
            showmodelEU: {
                visible: showmodel,
                title: title,
                value: '',
                width: 354,
                centered: true,
            }
        })
    }
    hidemodel = (data) => {
        this.setState({

            showmodel: {
                visible: data,
                title: '',
                value: '',
                lable: '',
                width: 354,
                centered: true,
            },
            showmodelEU: {
                visible: data,
                title: '',
                value: '',
                width: 354,
                centered: true,
            },
        })
    }
    submitmodel = (val,func) => {
        let modeltype = this.state.modeltype;
 
        if (modeltype === 1) {
            this.APIchangeCP(val,func)
        }
        if (modeltype === 2) {
            this.APIcreateEU(val,func)
        }
    }

    //添加CP
    showmodel = (showmodel, title, type) => {
        this.setState({
            modeltype: type,
            showmodel: {
                visible: showmodel,
                title: title,
                value: this.state.customers,
                width: 354,
                lable: '认证客户名称仅授权服务商可见',
                centered: true,
            }
        })
    }
    customExpandIcon = (props) => {

        if (props.record.children !== undefined) {
            if (props.expanded) {
                return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
                    props.onExpand(props.record, e);
                }}><ABB.Icon name="abb/down" sizeClass='small' /></a>
            } else {
                return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
                    props.onExpand(props.record, e);
                }}><ABB.Icon name="abb/right" sizeClass='small' /></a>
            }
        } else {
            return <span style={{ marginRight: 8 }}></span>
        }
    }
    render() {
        let that = this;
        let { userInfo } = that.props;
        return (
            <div className="shadow-box" style={{ padding: 16 }}>
                <div className="user_box">
                    {
                        userInfo.isOrgAdministrator === true && userInfo.organizationSubType !== "Float" ? (
                            <ABB.Button
                                style={{ marginRight: 32 }}
                                onClick={() => this.showmodelEU(true, '添加终端用户', 2)}
                                type="primary-blue"
                                sizeClass='small' theme="filled"
                                text="添加客户"
                                icon="abb/plus-in-circle"
                            />
                        ) : <div></div>
                    }

                    <Search
                        placeholder="请输入客户名称/手机号"
                        onSearch={value => that.onSearch(value)}
                        onChange={this.onChange}
                        style={{ width: 190 }}
                    />
                </div>
                <Table
                    size='small'
                    scroll={{ x: 1000 }}
                    className='org_right'
                    ellipsis={true}
                    expandIcon={(props) => that.customExpandIcon(props)}
                    columns={that.state.columns}
                    dataSource={that.state.clientlist}
                    pagination={false}
                    rowKey={(record, index) => record.id}
                />
                <UserModel
                    showmodel={that.state.showmodel}
                    hidemodel={that.hidemodel}
                    submitmodel={that.submitmodel}
                />
                <CreateEUForm
                    showmodelEU={that.state.showmodelEU}
                    hidemodel={that.hidemodel}
                    submitmodel={that.submitmodel}
                />
            </div>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    settree: data => {
        dispatch(settree(data))
    },
    settreeStatus: data => {
        dispatch(settreeStatus(data))
    },
    settreeCopy: data => {
        dispatch(settreeCopy(data))
    },
    settreeValue: data => {
        dispatch(settreeValue(data))
    },
    settreeSelect: data => {
        dispatch(settreeSelect(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CPmanager));

