import $axios from '../axios/$axios';

export function eventcount(data){
   return $axios({
    url: '/api/v1/event/count/unhandled'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function EUeventcount(data){
    return $axios({
     url: '/api/notification/count'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
 }

 export function chartConfig(data){
    return $axios({
     url: '/api/v3/trends/chart-config'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
 }

 export function parametersData(data){
    return $axios({
     url: '/api/v3/device/event/'+data+'/parameters',
     method: 'get',
     type: 'json',
     data: ''
    })
 }
export function eventlist(data){
    return $axios({
        url: '/api/v1/event/list'+data,
        method: 'get',
        type: 'json',
        data: ''
    })
}
export function MessageType(data){
    return $axios({
        url: '/api/v3/notification/type/list',
        method: 'get',
        type: 'json',
        data: ''
    })
}
export function eventlistGroup(data,isSubAsset){
    return $axios({
        url: '/api/v3/device/asset/'+data+'/events/all'+isSubAsset,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function eventlistfilter(data){
    return $axios({
        url: '/api/v3/device/asset/'+data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function eventDetailData(data){
    return $axios({
        url: '/api/v3/device/event/'+data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function easemobim_parameter(eventid){
    return $axios({
        url: '/api/v3/device/event/'+ eventid + '/easemobim_parameter',
        method: 'get',
        type: 'json',
        data: ''
    })
}


export function EUeventlist(data){
    return $axios({
        url: '/api/notification'+data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function notificationdevice(data){
    return $axios({
        url: '/api/notification/device/'+data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function EUeventreadStatus(data){
    return $axios({
        url: '/api/notification'+data,
        method: 'put',
        type: 'json',
        data: ''
    })
}

export function notificationbulk(data){
    return $axios({
        url: '/api/notification/bulk',
        method: 'put',
        type: 'json',
        data: data
    })
}

export function EUeventdetail(data){
    return $axios({
        url: '/api/notification'+data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function pushlist(){
    return $axios({
        url: '/api/notificationprofile',
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function closemessage(data){
    return $axios({
        url: '/api/notificationprofile'+data,
        method: 'delete',
        type: 'json',
        data: ''
    })
}

export function openmessage(data){
    return $axios({
        url: '/api/notificationprofile'+data,
        method: 'post',
        type: 'json',
        data: ''
    })
}

export function loggerfile(data){
    return $axios({
        url: '/api/v2/event/logger/file/'+data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function loggerfiledownload(data){
    return $axios({
        url: '/api/v2/event/logger/file/download/'+data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function eventlogger(data){
    return $axios({
        url: '/api/v2/event/logger/'+data,
        method: 'get',
        type: 'json',
        data: ''
    })
}