import React, { Component } from 'react';
import moment from 'moment';
import { Input } from 'antd';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { setNewsmodelIsShow } from '@/redux/actions/setting';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import NoData from '@/components/NoData'
import EUevent from './components/EUevent'
import CPABBevent from './components/CP_ABBevent'
import './style.scss';
import EquipmentDetail from '../devicedetails/components/device/devicedetail/index';

const { Search } = Input;
class Notification extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: 0
		};
	}
	UNSAFE_componentWillMount() {

		let _getNewsmodelIsShow = this.props.getNewsmodelIsShow;
		let objnull = (JSON.stringify(_getNewsmodelIsShow) === "{}")
		if (objnull === false) {
			this.setState({ activeTab: 1 })

		}
	}
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}

	callback(newIndex) {
		this.setState({ activeTab: newIndex })
	}
	selectUsercheckDeviceDetail = (data) => {
		if (data === 0) {
			return (
				<ABB.TabControl
					type="primary"
					activeTab={this.state.activeTab}
					className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
					onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
					padLeft={false}
				>
					<ABB.TabItem title="消息中心">
						<EUevent />
					</ABB.TabItem>
					<ABB.TabItem title="全部事件">
						<div className="shadow-box">
							<CPABBevent />
						</div>
					</ABB.TabItem>
				</ABB.TabControl>
			)
			// return 
		}
		else {
			return <EquipmentDetail />
		}
	}
	render() {
		let that = this;
		let { pageIndex, eventlist, size } = this.state;
		return (
			<>
				{/* {
					that.props.userInfo.organizationType === "EU" ? (
						<div className='shadow-radius'>
							<div className='org_right notification_box' style={{overflowY:'scroll'}}>

								{
									this.selectUsercheckDeviceDetail(this.props.UsercheckDeviceDetail)
									// this.props.UsercheckDeviceDetail ===0 ? <Orgtable/> : <EquipmentDetail/>
								}
							</div>
						</div>

					) : (
						<div className="shadow-radius">
							<div className="shadow-box">
								<CPABBevent />
							</div>
						</div>
					)
				} */}
				<div className='shadow-radius'>
							<div className='org_right notification_box' style={{overflowY:'scroll'}}>

								{
									this.selectUsercheckDeviceDetail(this.props.UsercheckDeviceDetail)
									// this.props.UsercheckDeviceDetail ===0 ? <Orgtable/> : <EquipmentDetail/>
								}
							</div>
						</div>
			</>

		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Notification));