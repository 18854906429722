import React, { Component } from 'react';
import ABBsub from './components/subscription/abbsub';
import ABBclient from './components/client/abbclient';
import CreateRecord from './components/subscription/record';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
class ABBmanager extends Component {
	state = {
		activeTab: 0
	};

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	callback(newIndex) {
		this.setState({ activeTab: newIndex });
	}
	render() {
		return (
			<>
				<ABB.TabControl type="primary" activeTab={this.state.activeTab} className={'fullHeight mainTabControlPaddings tabHeaderAnimation'} onTabChange={(oldIndex, newIndex) => this.callback(newIndex)} padLeft={false}>
					<ABB.TabItem title="客户列表">
						<ABBclient />
					</ABB.TabItem>
					<ABB.TabItem title="生成订阅">
						<ABBsub />
					</ABB.TabItem>
					<ABB.TabItem title="批量生成记录">
						<CreateRecord />
					</ABB.TabItem>
				</ABB.TabControl>
			</>
		);
	}
}

export default ABBmanager;
