//编辑数字化连接调试信息
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Modal,
    Row,
} from 'antd';

import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import './style.scss'
 
class CallPromptModel extends React.Component {
    state = {
        disabled: false,
        timeLength: 5
    };

    componentDidMount() {
        this.props.onRef(this)
    }

    udpateTimeLength = () => {
        this._startTimeInterval()
    }

    _startTimeInterval = () => {
        this.setState({
            timeLength: 5
        })
        this.timer = setInterval(() => {
            var updateTimelength = this.state.timeLength

            if (updateTimelength > 0) {
                this.setState({
                    timeLength: updateTimelength - 1
                })
            } else {
                this.hidemodel()
            }
 
        }, 1000)

    }

    hidemodel = (data) => {
        clearInterval(this.timer)
        this.props.hidemodel()
    }


    // 4G路由器信息 / NETA-21信息 / 变频器信息
    _renderSectionHeaderByTitle = (isMust, title) => {
        return <Row >
            <div className='section_item'>
                {isMust === true ? (<div className='section_icon'></div>) : null}
                <div >{title}</div>
            </div>
        </Row>
    }

    _renderEventType = (assetEventType) => {
        if (assetEventType === 'Fault') {
            return (
                <>
                    <ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
                    故障
                </>
            );
        }
        if (assetEventType === 'Warning') {
            return (
                <>
                    <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
                    告警
                </>
            );
        }
        if (assetEventType === 'Notification') {
            return (
                <>
                    <ABB.Icon style={{ 'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
                    通知
                </>
            );
        }
        if (assetEventType === 'Custom') {
            return (
                <>
                    <div className='customevent'></div>
                    自定义
                </>
            );
        }
    }

    render() {
        let that = this;

        const { showmodelboundsub } = that.props
        const eventData = showmodelboundsub.eventData

        const modelProps = {
            title: "呼叫成功",
            centered: true,
            width: 550,
            maskClosable: false,
            visible: showmodelboundsub.visible,

            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <div className='close_remind'>窗口将在<span className='time'>{this.state.timeLength}s</span>后关闭</div>
            ]
        }

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <div className='remin_title'>ABB专家即将联系您，协助处理以下设备事件。请您保持手机畅通。</div>

                    <div className='event_detail'>事件详情</div>

                    <div className='message_wrapper'>
                        <div className='left'>事件类型:</div><div className='right'>{
                            this._renderEventType(eventData.assetEventType)
                        }</div>
                    </div>
                    <div className='message_wrapper'>
                        <div className='left'>设备端时间:</div>
                        <div className='right'>{
                            eventData.occurrentTimeIsValidFormat === true ?
                                moment.parseZone(Number(eventData.eventOccurrentTime)).local().format("YYYY-MM-DD HH:mm:ss")
                                : eventData.eventOccurrentTime

                        }</div>
                    </div>
                    <div className='message_wrapper'>
                        <div className='left'>上传时间:</div><div className='right'><div>{moment.parseZone(eventData.eventDateTime).local().format("YYYY-MM-DD HH:mm:ss")}（UTC+8）</div></div>
                    </div>
                    <div className='message_wrapper'>
                        <div className='left'>事件描述:</div><div className='right'>{eventData.content || '-'}</div>
                    </div>
                    <div className='message_wrapper'>
                        <div className='left'>事件代码:</div><div className='right'>{eventData.eventCode || '-'}</div>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
   
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CallPromptModel));

