import React, { Component } from 'react'
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { deviceDetail, eventDetaildevice, settrendstag } from '@/redux/actions/device';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EchartLine from "./components/charts"
import { message, Select, } from 'antd';
import { eventlogger } from "@/api/event"
import './style.scss';
import moment from 'moment';

class Chartsdetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parameterList: [],
            eventnameList: [],
            eventnameListselect: 0,
            tagList: [],
            tagColor: ["#7ED6C6", "#968D0A", "#CC50CC", "#524C00", "#CC0815"],
            daytime: 1,
            timestamp: 0,
            trendstime: 1,
            trendstimeselect: 1,
            eventlist: [],
            loggerId: 0,
            eventloggerchart: [],
            eventloggerchartdata: []
        }
    }
    componentDidMount() {
        this.geteventloggerdata()
    }

    geteventloggerdata = () => {

        let { _deviceDetail, getAssetIsPowertrain, getEventDetail } = this.props;

        let isSubAsset = ``;
        let url;

        if (getAssetIsPowertrain === true) {
			isSubAsset = `?isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}

        url = `${_deviceDetail.assetId}/${getEventDetail.eventId}${isSubAsset}`;

        eventlogger(url).then(res => {

            if (res !== undefined) {
                let resdata = res.data;
                let eventselectlist = [];

                resdata.map((item, index) => {
                    eventselectlist.push({
                        id: item.loggerId,
                        name: item.loggerName
                    })
                    if (index === 0) {
                        this.setState({
                            eventnameList: [
                                {
                                    id: item.loggerId,
                                    name: item.loggerName
                                }
                            ],
                            eventnameListselect: item.loggerId,
                            loggerId: item.loggerId,
                            eventloggerchart: item.loggerData,
                        })
                        if(item.loggerData!==null){
                            this.parameterList(item.loggerData);
                            item.loggerData.map((datasitem, index) => {
                                if (index === 0) {
    
                                    this.setState({
                                        tagList: [{
                                            id: datasitem.name,
                                            name: datasitem.name,
                                            isShow: false
                                        }]
                                    }, () => {
                                        this.eventloggerchartdatafn(this.state.tagList)
                                    })
    
                                }
    
                            })
                        }
                        
                    }
                })
                this.setState({
                    eventnameList: eventselectlist,
                    eventlist: resdata,
                })
                // 
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
             
                console.log("geteventloggerdata -- eventlogger --接口异常")

                message.error('接口异常')
            }
            this.setState({
                lodding: false
            })
        })
    }
    parameterList = (data) => {

        let parameterList = []
        data.map((item, index) => {
            if (index !== 0) {
                parameterList.push({
                    name: item.name,
                    id: item.name,
                    isShow: true
                });
            }
            else{
                parameterList.push({
                    name: item.name,
                    id: item.name,
                    isShow: false
                });
            }

        })
        this.setState({
            parameterList: parameterList
        })
    }
    tag_close = (id) => {
        let tagList = this.state.tagList;
        let parameterList = this.state.parameterList;
        parameterList.map((item, index) => {
            if (item.id === id) {
                item.isShow = true
                // this.setState({
                //     parameterList: [...parameterList, item]
                // })
            }
        })

        tagList.splice(tagList.findIndex(item => item.id === id), 1);
        this.setState({
            tagList: [...tagList],
            parameterList: parameterList
        }, () => {
            this.eventloggerchartdatafn(this.state.tagList)
        })
    }
    eventloggerchartdatafn = (data) => {
        let eventloggerchartdata = []
        data.map(tagListitem => {
            this.state.eventloggerchart.map(chartsitem => {

                if (chartsitem.name === tagListitem.name) {
                    let avg = [];
                    let timestamp = [];
                    let value = [];
                    chartsitem.datas.map(dataitem => {
                        avg.push(dataitem.avg);
                        value.push(dataitem.value);
                        timestamp.push(dataitem.timestamp);

                    })
                    chartsitem.avg = avg;
                    chartsitem.timestamp = timestamp;
                    chartsitem.value = value;
                    eventloggerchartdata.push(chartsitem)
                }
            })

        })
        this.setState({
            eventloggerchartdata: eventloggerchartdata
        })
        this.props.settrendstag(this.state.tagList)
    }
    onChange = (e) => {
        let parameterList = this.state.parameterList;
        let tagList = this.state.tagList;
        parameterList.map((item, index) => {
            if (item.id === e) {
                item.isShow = false
                // parameterList.splice(parameterList.findIndex(item => item.id === e), 1);
                this.setState({
                    tagList: [...tagList, item],
                    parameterList: parameterList
                }, () => {
                    this.eventloggerchartdatafn(this.state.tagList)
                })
            }
        })

    }
    onChangeName = (e) => {
        this.state.eventlist.map(item => {
            if (item.loggerId === e) {
                let loggerDataarr = [];
                let tagList = []
                item.loggerData.map((loggerDataitem,index) => {
                    if(index!==0){
                        loggerDataarr.push({
                            id: loggerDataitem.name,
                            name: loggerDataitem.name,
                            isShow: true
                        })
                    }
                    else{
                        tagList = [{
                            id: loggerDataitem.name,
                            name: loggerDataitem.name,
                            isShow: false
                        }]
                    }
                })
                this.setState({
                    eventnameListselect: e,
                    parameterList: loggerDataarr,
                    loggerId: item.loggerId,
                    eventloggerchart: item.loggerData,
                    tagList: tagList
                }, () => {
                    this.props.settrendstag(this.state.tagList)
                    this.eventloggerchartdatafn(this.state.tagList)
                })
            }
        })
    }
    render() {

        return (

            <div>

                <div style={{ padding: '16px 0 0 0' }}>
                    <div className="select_box">
                        <div className="model_list" style={{ marginRight: 32 }}>
                            <div className='model_title'>选择事件记录</div>
                            <Select
                            dropdownMatchSelectWidth={false}
                                placeholder="选择事件记录"
                                value={this.state.eventnameListselect}
                                suffixIcon={
                                    <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                }
                                style={{ width: 300 }}
                                onChange={this.onChangeName}
                            >

                                {this.state.eventnameList.map((item, index) =>
                                    <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                )}
                            </Select>
                        </div>
                        <div className="model_list">
                            <div className='model_title'>选择参数（{this.state.tagList.length}/5）</div>
                            <Select placeholder="添加一个信号"
                                value="添加一个信号"
                                // defaultValue = {this.props.gettrendscode}
                                disabled={this.state.tagList.length > 4 ? true : false}
                                suffixIcon={
                                    <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                }
                                style={{ width: 300 }}
                                onChange={this.onChange}
                            >
                                {
                                    this.state.parameterList.length > 0 ? (
                                        this.state.parameterList.map((item, index) => {
                                            return item.isShow ? (
                                                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                                            ) : <Select.Option key={item.id} style={{display:'none'}}></Select.Option>
                                        })
                                    ) : ''

                                }
                            </Select>
                        </div>
                    </div>

                    {
                        this.state.tagList.length > 0 ? (
                            <>
                                <div className="select_box">
                                    <div className="mydevice_name">
                                        {this.props._deviceDetail.assetName}：
                                </div>
                                    <div className="tag_box">
                                        {
                                            this.state.tagList.map((item, index) => {
                                                return (
                                                    <div className="tag_list" key={index} style={{ borderBottomColor: this.state.tagColor[index] }}>
                                                        <div className="tag_name">{item.name}</div>
                                                        <div className="tag_close" onClick={() => this.tag_close(item.id)}>
                                                            <ABB.Icon style={{ color: '#1F1F1F' }} name="abb/close" sizeClass='small' />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <EchartLine
                                    tagList={this.state.tagList}
                                    eventloggerchartdata={this.state.eventloggerchartdata}
                                    tagColor={this.state.tagColor}
                                />
                            </>

                        ) : <div className="selectparam">请选择信号</div>
                    }


                </div>
            </div>
        )
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    },

    settrendstag: data => {

        dispatch(settrendstag(data));
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Chartsdetail));