import React, { Component } from 'react'
import { plantmap } from '@/api/plant';
import { Map, Markers, InfoWindow } from 'react-amap';
import { Spin, message } from 'antd';
const mapKey = process.env.REACT_APP_AMAP_KEY

const styleA = {
    background: "rgba(240,28,64,0.4)",
    borderRadius: "50%",
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '30px',
    height: '30px',
    color: '#000',
    textAlign: 'center',
    lineHeight: '40px',
    position: 'relative'

};
const styleB = {
    fontsize: '11px',
    paddingTop: '5px',
    color: '#F00',
};

const styleD = {
    width: '20px',
    height: '20px',
    background: "rgba(240,28,64,0.6)",
    borderRadius: "50%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
}
const styleE = {
    width: '10px',
    height: '10px',
    background: "rgba(240,28,64,1)",
    borderRadius: "50%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    margin: 'auto'
}
class Address extends Component {
    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            offset: [0, 0],
            positions: [110, 36],
            context: [],
            assetCount: [],
            markList: [],
            loading: false,
            noLocationAssetCount: 0 //未获取到位置的设备数量
        };
        this.markersEvents = {
            click: (MapsOption, marker) => {
                const extData = marker.getExtData();
                this.setState({
                    visible: !this.state.visible,
                    positions: extData.position,
                    context: extData.context,
                    assetCount: extData.assetCount,
                    address: extData.address
                });
            },
        };
    }
    Fnplantmap() {
        this.setState({
            loading: true
        })
        let url = ``;
        plantmap(url).then(res => {


            if (res !== undefined) {
                let resdata = res.data.data;
                let mark = [];
                let list = resdata.list;

                if (list.length > 0) {
                    for (let i = 0; i < list.length; i++) {

                        mark.push({
                            position: {
                                longitude: Number(list[i].longitude),
                                latitude: Number(list[i].latitude),
                            },

                            assetCount: list[i].assetCount,
                            address: list[i].address
                        })
                    }

                } else {
                    mark = []
                }

                console.log('mark=================', mark)
                this.setState({
                    markList: mark,
                    loading: false,
                    noLocationAssetCount:resdata.noLocationAssetCount
                });

            }
        }).catch(err => {
            this.setState({
                loading: false
            });
            if (err.status === 400 || err.status === 404) {
                // message.warning(err.data)
            }
            else {
                // message.error("")
            }
        })
    }
    componentDidMount() {
        this.Fnplantmap();
    }
    renderLayout() {
        return (<div style={styleA}>
            <div style={styleD}>
                <div style={styleE}></div>
            </div>
        </div>);
    }
    render() {

        const {noLocationAssetCount} = this.state

        return (
            <Spin spinning={this.state.loading} style={{ height: '260px' }}>
                <div className='maphover'>
                    {
                        noLocationAssetCount > 0 && 
                        <div className='maptitle'>共有{noLocationAssetCount}台设备未获取到位置信息，请及时设置</div>
                         
                    }


                    {
                        <Map amapkey={mapKey}
                            zoom={0}

                            center={this.state.positions}
                            plugins={['ToolBar']}>

                            <InfoWindow
                                position={this.state.positions}
                                visible={this.state.visible}
                                offset={[0, -20]}
                                isCustom={false}
                            >
                                <h3 style={styleB}>{this.state.context}</h3>
                                {this.state.address != null ? <p>{this.state.address}</p> : <div></div>}
                                <p>设备数量：{this.state.assetCount}</p>
                            </InfoWindow>
                            <Markers
                                markers={this.state.markList}
                                render={this.renderLayout}
                                events={this.markersEvents}
                            >
                            </Markers>
                        </Map>

                    }

                </div>
            </Spin>

        )
    }
}

export default Address
