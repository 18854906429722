import React, { Component } from 'react';
import { devicecbmunitall } from '@/api/cbm';
import { withRouter } from 'react-router-dom';
import { userCheckDevice, deviceDetail } from '@/redux/actions/device';
import { message } from 'antd';
import { connect } from 'react-redux';
// import NoData from '@/components/NoData'
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class Lifestatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devicecbmunitlist: [],
            subscriptioncof: props.getsubscriptioncof,
            assetId: this.props._deviceDetail.assetId,
            cbmlifestatus: false,
        }
    }

    componentDidMount() {
        let { subscriptioncof } = this.state;
        subscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 22) {
                    this.setState({
                        cbmlifestatus: items.limitValue
                    })
                    if (items.limitValue === true) {
                        this.fnCBMdata();
                    }
                }
            })
        })

    }
    // componentDidUpdate(nextProps, prevState) {
    // 	if (prevState.assetId !== this.props._deviceDetail.assetId) {

    // 		this.setState({
    // 			assetId: this.props._deviceDetail.assetId
    // 		}, () => {
    // 			this.fnCBMdata()
    // 		})

    // 	}
    // }
    fnCBMdata = () => {

        let { _deviceDetail, getAssetIsPowertrain } = this.props;

        let isSubAsset = ``;
        let url;
        if (getAssetIsPowertrain === true) {
            isSubAsset = `?isSubAsset=true`;
        }
        else {
            isSubAsset = ``
        }
        url = `${_deviceDetail.assetId}${isSubAsset}`;

        devicecbmunitall(url).then(res => {

            if (res !== undefined) {
                let resdata = res.data;
                let devicecbmunitlist = [];
                for (let i = 0; i < resdata.length; i++) {
                    resdata.map(item => {

                        if (item.deviceUnitType === 1 && i === 0) {
                            devicecbmunitlist.push(item)
                        }
                        if (item.deviceUnitType === 3 && i === 1) {
                            devicecbmunitlist.push(item)
                        }
                        if (item.deviceUnitType === 2 && i === 2) {
                            devicecbmunitlist.push(item)
                        }
                    })
                }

                this.setState({
                    devicecbmunitlist: devicecbmunitlist
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                // message.warning(err.data)
            }
            else {
                console.log("fnCBMdata--devicecbmunitall--接口异常")
                message.destroy()
                message.error('接口异常')
            }
        })
    }


    //根据参数生成预测性维护item

    _renderPredictiveMaintenanceItem = (iconClassName, title, subTitle) => {
        return (
            <div className='lifestatus_list'>
                <div className='lifestatus_icon'>
                    <div className={`asset_type_icon ${iconClassName}`}></div>
                </div>
                <div className='lifestatus_right'>
                    <div className="life_title">{title}</div>
                    <div className="life_subtitle">{subTitle}</div>
                </div>
            </div>
        )
    }

    renderList = (data) => {
        var itemTitle = ""
        switch (data.deviceUnitType) {
            case 1:
                itemTitle = "冷却"
                break
            case 2:
                itemTitle = "变流单元"
                break
            case 3:
                itemTitle = "直流母线电容"
                break
            default:
                itemTitle = ""
        }
        if (data.deviceUnitState === 0) {
            return this._renderPredictiveMaintenanceItem("asset_type_icon_gray", itemTitle, "当前预测性维护不可用")
        }
        if (data.deviceUnitState === 1) {
            return this._renderPredictiveMaintenanceItem("asset_type_icon_green", itemTitle, "无需计划维护")
        }
        if (data.deviceUnitState === 2) {
            return this._renderPredictiveMaintenanceItem("asset_type_icon_yellow", itemTitle, "开始计划维护")
        }
        if (data.deviceUnitState === 3) {
            return this._renderPredictiveMaintenanceItem("asset_type_icon_red", itemTitle, "执行维护")
        }
    }


    render() {
        let that = this;
        return (


            <div className="lifestatusbox">
                {
                    that.state.cbmlifestatus === true ? (
                        that.state.devicecbmunitlist.length > 0 ? (

                            that.state.devicecbmunitlist.map((item, index) => {
                                return (
                                    <>
                                        {
                                            that.renderList(item)
                                        }
                                    </>
                                )

                            })
                        ) : <div className="renderEmpty renderEmpty_flex">
                            <div className="renderEmpty_center">
                                <ABB.Icon style={{ margin: '20px 0 8px 8px' }} name="abb/event" sizeClass='large' />
                                <p>暂无数据</p>
                            </div>
                        </div>
                    ) : ''
                }

            </div>

        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Lifestatus));
// export default Lifestatus;