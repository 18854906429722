
import React, { Component } from 'react';
import ABBmanager from './abbmanager';
import CPmanager from './components/client/cpmanager';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setIndustryList,setShowIndustryList } from '../Orgmanagement/store/actionCreators';
import { APIQueryIndestryList } from '../../api/user';
import IndustriesModel from '../Orgmanagement/components/org/components/industriesModel';
class Clientmanager extends Component {
	constructor(props){
		super(props)
		this.state = {

		}
		
	}
	componentDidMount(){
		this._queryIndustry()
	}
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	_queryIndustry = () => {
		 
		APIQueryIndestryList().then(res => {
			if (res !== undefined) {
				let resData = res.data
				this.props.updateIndestryList(resData.data)

				this.props.updateShowIndestryList([...resData.data, ...[{
					firstIndustry: "其它",
					secondIndustry: "其它"
				}]])
			}

		}).catch(err => {

			if (err && err.message) {
				console.log(err.message);
			}

			// message.error('行业信息获取失败')
		})
	}

	render() {
		return (
			<div className='shadow-radius'>
				<div className='org_right'>
					{
						this.props.userInfo.organizationType === 'ABB' ? <ABBmanager/> : <CPmanager/>
					}
				    
				</div>
 
			 
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
 
	updateIndestryList: data => {
		 
        dispatch(setIndustryList(data))
    },
    updateShowIndestryList: data => {
        dispatch(setShowIndustryList(data))
    },
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Clientmanager));
