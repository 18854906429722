import * as actionTypes from '../constants/index';
const setEventcount = (data) => {
    return {
        type: actionTypes.SET_EVENTCOUNT,
        data
    }
};

const setEventDetail = (data) => {
    return {
        type: actionTypes.EVENTDETAIL,
        data
    }
};

const setEventData = (data) => {
    return {
        type: actionTypes.EVENTDATA,
        data
    }
};

const setEventDetailStatus = (data) => {
    return {
        type: actionTypes.EVENTDETAIL_STATUS,
        data
    }
};
export {setEventData,setEventcount,setEventDetail,setEventDetailStatus};