import Dashboard from '../views/dashboard/Index';
import EUequipment from '../views/devicedetails/Index';
import Clientmanager from '../views/clientmanagement/Index';
import EUdevice from '../views/devicemanagement/Index';
import Orgmanagement from '../views/Orgmanagement/Index';
import PersonalCenter from '../views/Orgmanagement/components/user/personalCenter';
import ToolBoxCenter from '../views/Orgmanagement/components/tool/toolBoxCenter';
import Setting from '../views/Setting';

import Error404 from '../views/error/Error404';
import Error500 from '../views/error/Error500';
import Notification from '../views/Notification/Notification';

export const routes = [
	{ path: '/dashboard', component: Dashboard },
	{ path: '/devicedetails', component: EUequipment },
	{ path: '/clientmanagement', component: Clientmanager },
	{ path: '/Setting', component: Setting },
	{ path: '/devicemanagement', component: EUdevice },
	{ path: '/Orgmanagement', component: Orgmanagement },
	{ path: '/personalCenter', component: PersonalCenter },
	{ path: '/Notification', component: Notification },
	{ path: '/error/404', component: Error404 },
	{ path: '/error/500', component: Error500 },
	{ path: '/toolbox', component: ToolBoxCenter }
];
