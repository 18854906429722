
import React, { Component } from 'react';
import Orgtree from '../orgtree/orgtree';
import Orgtable from './orgtable';
import '@/assets/css/tree';
import EquipmentDetail from '../devicedetails/components/device/devicedetail/index';
import { connect } from 'react-redux';
import { userCheckDevice } from '@/redux/actions/device'
import { withRouter } from 'react-router-dom';

const org_box = {
	width: '100%',
	display: 'flex',
	flexFlow: 'row',
	marginTop: '8px',
	marginBottom: '8px'
}

class Clientmanager extends Component {
	constructor(props){
		super(props);
		this.state = {
			org_leftwidth: '',
			org_right: {
				width: '77%',
				minHeight: '85vh',
				flex:1,
				overflowY: 'scroll',
				height: (document.body.clientHeight-124)+"px"
			},
			org_left: {
				position: 'relative',
				
				borderRight: '1px solid #BABABA',
				zIndex: '100',
				width: "280px",
				overflow: 'auto',
				height: (document.body.clientHeight-124)+"px",
			},
			isdevice: 0,
		}
		// this.onMouseDownbtn = this.onMouseDownbtn.bind(this)
	}
	
	UNSAFE_componentWillMount() {
		window.addEventListener('resize', this.handleSize);
		
	}
	
	// 自适应浏览器的高度
	handleSize = () => {


		this.setState({
			contentHeight:(document.body.clientHeight-124)+"px",
			
			org_left: {
				position: 'relative',
				borderRight: '1px solid #BABABA',
				width: "280px",
				overflowY: 'scroll',
				height: (document.body.clientHeight-110)+"px",
				marginRight: '8px'
			},
			org_right: {
				width: '77%',
				flex:1,
				minHeight: '85vh',
				overflowY: 'scroll',
				height: (document.body.clientHeight-124)+"px"
			}
		})
		
	}
	componentWillUnmount() {
		// 移除监听事件
		window.removeEventListener('resize', this.handleSize);
	}
	// 自适应浏览器的高度
	handleSize = () => {
		this.setState({
			
			contentHeight:(document.body.clientHeight-124)+"px",
			
			org_left: {
				position: 'relative',
				borderRight: '1px solid #BABABA',
				width: "280px",
				overflowY: 'scroll',
				height: (document.body.clientHeight-124)+"px",
				marginRight: '8px'
			},
			org_right: {
				width: '77%',
				flex:1,
				overflowY: 'scroll',
				height: (document.body.clientHeight-124)+"px"
			}
		})
		
	}
	selectUsercheckDeviceDetail = (data) => {
		if(data === 0){
			return <Orgtable/>
		}
		else{
			return <EquipmentDetail/>
		}
	}
	render() {
		const { org_left } = this.state;
		return (
			<div style={org_box}>
				<div style={this.state.org_left} className='org_tree_scroll'>
					<Orgtree/>
				</div>
				<div style={this.state.org_right} className='org_right'>
					{
						this.selectUsercheckDeviceDetail(this.props.UsercheckDeviceDetail)
						// this.props.UsercheckDeviceDetail ===0 ? <Orgtable/> : <EquipmentDetail/>
					}
				</div>
				
			</div>
		);
	}
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	userCheckDevice: data =>{
		dispatch(userCheckDevice(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Clientmanager));
// export default Clientmanager;
