import * as actionTypes from '../constants/index';

const setplant = data => {
	return {
		type: actionTypes.SET_PLANT,
		data
	};
};
const setaddress = data => {
	return {
		type: actionTypes.SET_ADDRESS,
		data
	};
};
const setAddplanttype = data => {
	return {
		type: actionTypes.SET_ADDPLANTTYPE,
		data
	};
};
const setaddressChange = data => {
	return {
		type: actionTypes.SET_ADDRESSCHANGE,
		data
	};
};

const setPlantisShow = data => {
	return {
		type: actionTypes.SET_PLANTISSHOW,
		data
	};
};

const setAddDeviceProcess = data =>{
	return {
		type: actionTypes.SET_ADDDEVICEPROCESS,
		data
	};
}


export { setplant,setaddress,setAddplanttype,setaddressChange,setPlantisShow,setAddDeviceProcess };