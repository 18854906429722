import React, { Component } from 'react'
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { userCheckDevice, deviceDetail, checkAllgen, eventDetaildevice, settrendscode, settrendstag, setChartsTime } from '@/redux/actions/device';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EchartLine from "./components/charts"
import { message, Select, DatePicker } from 'antd';
import './style.scss';
import moment from 'moment';
import { timeConfig } from "@/utils/index";
import ApplyHistoryDataFrom from '../ApplyHistoryDataFrom';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD HH:mm:ss';

class Chartsdetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parameterList: [],
            tagList: this.props.gettrendscode,
            startime: moment(new Date(new Date(moment.parseZone(this.props.getChartsTime.startime).local().format("YYYY-MM-DD")).toLocaleDateString()), dateFormat),
            endtime: moment(new Date(moment.parseZone(this.props.getChartsTime.endtime).local().format("YYYY-MM-DD")), dateFormat),
            fromtime: this.props.getChartsTime.startime,
            totime: this.props.getChartsTime.endtime,
            tagColor: ["#7ED6C6", "#968D0A", "#CC50CC", "#524C00", "#CC0815"],
            daytime: 1,
            timestamp: 0,
            trendstime: 1,
            trendstimeselect: 1,
            showmodelboundsubConfig: {
                visible: false,
                endTime: '-'
            },

        }
    }
    componentDidMount() {
        this.parameterList();
        let { getsubscriptioncof } = this.props;
        let { fromtime, totime } = this.state;
        // let today = new Date();
        // let _today = moment(today).format("YYYY-MM-DD")
        // let _todayminute = moment(today).format("HH:mm:ss")
        let _startime = moment.parseZone(fromtime).local().format("YYYY-MM-DD")
        let _endtime = moment.parseZone(totime).local().format("YYYY-MM-DD")
        // if (_today === _endtime) {
        //     _endtime = _endtime + " " + _todayminute
        // }
        // else {
        //     _endtime = _endtime + " 23:59:59"
        // }
        // let currentUTCTimeString = moment(_endtime).utc().format("YYYY-MM-DD HH:mm:ss")
        // let dateUTCTimeString = moment(_startime).utc().format("YYYY-MM-DD HH:mm:ss");
        let time = timeConfig({
            startime: fromtime,
            endtime: totime,
        })
        this.setState({
            // fromtime: time.startime,
            // totime: time.endtime
        }, () => {
            this.daytime({
                startime: fromtime,
                endtime: totime,
            })
        })
        getsubscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 2) {
                    this.setState({
                        trendstime: items.limitValue
                    })
                }
                if (items.featureCode === 1) {
                    this.setState({
                        trendstimeselect: items.limitValue - 1
                    })
                }
            })

        })
    }
    daytime = (time) => {
        let startime = Date.parse(time.startime);
        let endtime = Date.parse(time.endtime);
        let day = (endtime - startime) / 1000 / 60 / 60 / 24;
        if (day <= 1) {
            this.setState({
                daytime: 1
            })
        }
        else {
            this.setState({
                daytime: Math.ceil(day)
            })
        }
        // return day;
    }
    parameterList = () => {
        let trendsCharts = this.props.getTrendsData;
        let parameterList = [];

        if (trendsCharts.length > 0) {


            trendsCharts[0].map((item, index) => {
                let isShow = true;
                if (this.props.gettrendscode[0].code === item.code) {
                    isShow = false;
                }

                parameterList.push({
                    name: item.config.label,
                    code: item.code,
                    id: index,
                    isSignals: 0,
                    isShow: isShow
                });
            })


            this.setState({
                parameterList: [parameterList, this.props.getTrendsData[1]]
            }, () => {
            })
        }
    }

    backuper = () => {
        let { getassettype, getRouterLinkIndex } = this.props;
        if (getassettype === "PowerTrain") {
            if (getRouterLinkIndex === 2) {
                this.props.userCheckDevice(11)
            } else {
                this.props.userCheckDevice(3)
            }

        }
        else if (getassettype === "noPowerTrain") {
            this.props.userCheckDevice(11)
        }
        else {
            this.props.userCheckDevice(0);
        }
        this.props.eventDetaildevice('0')
        this.props.checkAllgen('0')
    }
    tag_close = (code) => {
        let tagList = this.state.tagList;
        let parameterList = this.state.parameterList;


        parameterList[0].map((item, index) => {
            if (item.code === code) {
                item.isShow = true
                // parameterList[0].splice(parameterList[0].findIndex(item => item.code === e), 1);
                // this.setState({
                //     // tagList: [...tagList, item],
                //     parameterList: parameterList
                // }, () => {
                //     this.props.settrendstag(e);
                //     this.props.settrendscode(this.state.tagList)
                // })
            }
        })

        parameterList[1].map((item, index) => {
            if (item.code === code) {
                item.isShow = true
                // parameterList[1].splice(parameterList[1].findIndex(item => item.code === e), 1);
                // this.setState({
                //     // tagList: [...tagList, item],
                //     parameterList: parameterList
                // }, () => {
                //     // this.props.settrendstag(code);
                //     // this.props.settrendscode(this.state.tagList)
                // })
            }
        })



        // tagList.map((item, index) => {
        //     if (item.isSignals === 0) {
        //         if (item.code === code) {
        //             // parameterList[0].push(item)
        //             this.setState({
        //                 parameterList: parameterList
        //             })
        //         }
        //     }
        //     if (item.isSignals === 1) {
        //         if (item.code === code) {
        //             parameterList[1].push(item)
        //             this.setState({
        //                 parameterList: parameterList
        //             })
        //         }
        //     }
        // })

        tagList.splice(tagList.findIndex(item => item.code === code), 1);
        this.setState({
            tagList: [...tagList],
            parameterList: parameterList
        }, () => {
            this.props.settrendstag(code + 20);
            this.props.settrendscode(this.state.tagList)
        })
    }
    onChange = (e, data) => {
        let parameterList = this.state.parameterList;
        let tagList = this.state.tagList;

        if (data.props.issignals === 0) {
            parameterList[0].map((item, index) => {
                if (item.code === e) {
                    item.isShow = false
                    // parameterList[0].splice(parameterList[0].findIndex(item => item.code === e), 1);
                    this.setState({
                        tagList: [...tagList, item],
                        parameterList: parameterList
                    }, () => {
                        this.props.settrendstag(e);
                        this.props.settrendscode(this.state.tagList)
                    })
                }
            })
        }
        else {
            parameterList[1].map((item, index) => {
                if (item.code === e) {
                    item.isShow = false
                    // parameterList[1].splice(parameterList[1].findIndex(item => item.code === e), 1);
                    this.setState({
                        tagList: [...tagList, item],
                        parameterList: parameterList
                    }, () => {
                        this.props.settrendstag(e);
                        this.props.settrendscode(this.state.tagList)
                    })
                }
            })
        }
        // parameterList.map((item, index) => {
        //     if (item.code === e) {
        //         parameterList.splice(parameterList.findIndex(item => item.code === e), 1);
        //         this.setState({
        //             tagList: [...tagList, item],
        //             parameterList: parameterList
        //         }, () => {
        //             this.props.settrendstag(e);
        //             this.props.settrendscode(this.state.tagList)

        //         })
        //     }
        // })

    }
    onChangetime = (dates, dateStrings) => {
        if (dateStrings[0] === '' || dateStrings[1] === '') {
            let currentUTCTimeString = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss")
            let dateUTCTimeString = moment(new Date(new Date().toLocaleDateString())).utc().format("YYYY-MM-DD HH:mm:ss");
            this.setState({
                startime: dateUTCTimeString,
                endtime: currentUTCTimeString,
                fromtime: dateUTCTimeString,
                totime: currentUTCTimeString
            }, () => {
                this.daytime({
                    startime: dateUTCTimeString,
                    endtime: currentUTCTimeString,
                })
            })

        }
        else {
            let starttimetmp = Date.parse(dateStrings[0]);
            let endtimetmp = Date.parse(dateStrings[1]);
            var days = (endtimetmp - starttimetmp) / (1 * 24 * 60 * 60 * 1000);

            let myday = Math.round(days);

            if (myday > this.state.trendstimeselect) {
                console.log("时间不能超过30天")

                message.warning(`时间不能超过${this.state.trendstimeselect + 1}天！`);
                this.setState({
                    startime: this.state.startime,
                    endtime: this.state.endtime,
                    fromtime: this.props.getChartsTime.startime,
                    totime: this.props.getChartsTime.endtime,
                })
                return false;
            }

            const { _deviceDetail, getAssetIsPowertrain } = this.props;

            let endTime = _deviceDetail.migrationStartDate

            //如果migrationStartDate有值且选择时间早于 migrationStartDate 提示用户申请查看更多数据
            if (endTime !== null && starttimetmp < Date.parse(endTime)) {

                this.setState({
                    startime: this.state.startime,
                    endtime: this.state.endtime,
                    fromtime: this.props.getChartsTime.startime,
                    totime: this.props.getChartsTime.endtime,

                    showmodelboundsubConfig: {
                        visible: true,
                        endTime: endTime,
                        deviceDetail: _deviceDetail,
                        isSubAsset: getAssetIsPowertrain
                    },
                })
                return false
            }



            let time = timeConfig({
                startime: dateStrings[0],
                endtime: dateStrings[1],
            })
            // this.props.setChartsTime({
            //     startime: time.startime,
            //     endtime: time.endtime,
            // })
            this.setState({
                startime: time.startime,
                endtime: time.endtime,
                fromtime: time.startime,
                totime: time.endtime,
                timestamp: Date.parse(time.endtime) + Date.parse(time.startime)
            }, () => {
                this.daytime({
                    startime: time.startime,
                    endtime: time.endtime,
                })
            })
        }

    }

    //隐藏model
    hidemodel = () => {
        this.setState({
            showmodelboundsubConfig: {
                visible: false,
                endTime: '-',
                deviceDetail: {},
                isSubAsset: false
            },
        })
    }

    render() {
        let { tagList, parameterList, daytime, tagColor, timestamp, fromtime, totime, trendstime } = this.state;
        const disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(trendstime, 'day');
        let startime = moment.parseZone(this.state.startime).local().format(dateFormat);
        let endtime = moment.parseZone(this.state.endtime).local().format(dateFormat);

        let times = [moment(startime, dateFormat), moment(endtime, dateFormat)]
        return (

            <div className='deviceDetail_status' style={{ minHeight: "87vh" }}>
                <div className="EUorg_title_back">

                    <div className='backup_button' onClick={() => this.backuper()}>
                        <ABB.Icon name="abb/left" sizeClass='medium' />信号时序
                    </div>

                </div>
                <div>
                    <div className="select_box">

                        <div className="model_list"
                            style={{ margin: "0 8px 32px 0" }}
                        >
                            <div className='model_title'>显示范围</div>
                            <RangePicker
                                // defaultValue={[this.state.startime, this.state.endtime]}
                                value={times}
                                format={dateFormat}
                                suffixIcon={
                                    <ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
                                }
                                disabledDate={disabledDate}
                                style={{ width: 300 }}
                                allowClear={false}
                                separator='—'
                                className="model_input"
                                format="YYYY/MM/DD"
                                onChange={this.onChangetime}
                            // onOk={this.onOk}
                            />
                        </div>
                        <div className="model_list" style={{ margin: "0 32px 32px 0" }}>
                            <div className='model_title' style={{ color: "#fff" }}>.</div>
                            <div className='model_title' style={{ color: '#1f1f1f', fontWeight: 'bold' }}>已选{daytime}天 （最长30天）</div>
                        </div>
                        <div className="model_list">
                            <div className='model_title'>选择参数（{tagList.length}/5）</div>
                            <Select placeholder="添加一个信号"
                                value="添加一个信号"
                                // defaultValue = {this.props.gettrendscode}
                                disabled={tagList.length > 4 ? true : false}
                                suffixIcon={
                                    <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                }
                                style={{ width: 300 }}
                                onChange={(e, option) => this.onChange(e, option)}
                            >
                                {
                                    parameterList.map((item, index) =>
                                        index === 0 ? (
                                            item.map((childrenitem, childIndex) => {
                                                return childrenitem.isShow ? (
                                                    <Select.Option
                                                        issignals={childrenitem.isSignals}
                                                        key={childrenitem.code}
                                                        value={childrenitem.code}>
                                                        {childrenitem.name}
                                                    </Select.Option>
                                                ) : <Select.Option style={{ display: 'none' }} key={childrenitem.code}></Select.Option>;

                                            })
                                        ) : (
                                            item.map((childrenitem, index) => {
                                                return childrenitem.isShow ? (
                                                    <Select.Option
                                                        issignals={childrenitem.isSignals}
                                                        style={index === 0 ? { borderTop: '1px solid #bababa' } : ''}
                                                        key={childrenitem.code}
                                                        value={childrenitem.code}>
                                                        {childrenitem.code} {childrenitem.name}
                                                    </Select.Option>
                                                ) : <Select.Option style={{ display: 'none' }} key={childrenitem.code}></Select.Option>;
                                            })
                                        )
                                    )
                                }
                            </Select>
                        </div>
                        {/* </div>
                    <div className="select_box" style={{ marginTop: 16 }}> */}

                    </div>
                    {
                        tagList.length > 0 ? (
                            <>
                                <div className="select_box">
                                    <div className="mydevice_name">
                                        {this.props._deviceDetail.assetName}：
                                    </div>
                                    <div className="tag_box">
                                        {
                                            tagList.map((item, index) => {
                                                return (
                                                    <div className="tag_list" key={index} style={{ borderBottomColor: tagColor[index] }}>
                                                        {
                                                            item.isSignals === 0 ? (
                                                                <div className="tag_name">{item.name}</div>
                                                            ) : (
                                                                <div className="tag_name">{item.code} {item.name}</div>
                                                            )
                                                        }

                                                        <div className="tag_close" onClick={() => this.tag_close(item.code)}>
                                                            <ABB.Icon style={{ color: '#1F1F1F' }} name="abb/close" sizeClass='small' />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <EchartLine
                                    tagList={tagList}
                                    trendstime={{
                                        startime: fromtime,
                                        endtime: totime,
                                    }}
                                    timestamp={timestamp}
                                    tagColor={tagColor}
                                />
                            </>

                        ) : <div className="selectparam">选择参数</div>
                    }


                </div>

                <ApplyHistoryDataFrom
                    showmodelboundsubConfig={this.state.showmodelboundsubConfig}
                    hidemodel={this.hidemodel}
                    submitmodel={this.submitmodel} />

            </div>
        )
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    },
    settrendscode: data => {

        dispatch(settrendscode(data));
    },
    settrendstag: data => {

        dispatch(settrendstag(data));
    },
    setChartsTime: data => {
        dispatch(setChartsTime(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Chartsdetail));