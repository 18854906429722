import $axios from '../axios/$axios';

export function secretkey(data) {
   return $axios({
      url: '/api/v2/grantcustomer/secretkey'+data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function applicationkey(data) {
   return $axios({
      url: '/api/v2/grantcustomer/applicationkey'+data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function createsecretkey(data) {
   return $axios({
      url: '/api/v2/grantcustomer/secretkey',
      method: 'post',
      type: 'json',
      data: data
   })
}