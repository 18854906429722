import React, { Component } from 'react';
import { Modal, Button, Input, message, Form, Select } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { organizationKeywordSearch, OrganizationRegInfo } from "@/api/client";

const { Option } = Select;
let timeout;
let currentValue;

function fetchOrgSearch(value, callback) {
    currentValue = value;
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    function fake() {
        if (currentValue.length < 4 || currentValue.length > 100) {
            callback([]);
            return;
        }
        organizationKeywordSearch(currentValue)
            .then(body => {
                const data = body.data.payload.map(org => ({
                    label: org.companyName,
                    key: creditCodeNull(org.creditCode),
                }));
                callback(data);
            });
    }

    timeout = setTimeout(fake, 300);
}

function creditCodeNull(value) {
    if (!value) {
        return `null-${Math.random()}`
    }
    return value
}
class UserModelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: false,
            isZn_CH: false,
            searchOrgData: [],
            searchOrgValue: undefined,
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.props.submitmodel(fieldsValue, () => {
                    this.props.form.resetFields();
                })

            }
        });
    }

    handleOrgSearch = value => {
        if (value) {
            fetchOrgSearch(value, data => this.setState({ searchOrgData: data }));
        } else {
            this.setState({ searchOrgData: [] });
        }
    };

    handleOrgChange = (value, options) => {
        this.setState({ searchOrgValue: value });
    };

    groupChange = (e) => {
        this.setState({
            groupId: e,
            deviceGroupName: e
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };

    render() {
        const that = this;
        const { showmodel, disabled } = this.props;
        const { getFieldDecorator, setFieldsValue } = that.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showmodel.title,
            centered: showmodel.centered,
            width: showmodel.width,
            visible: showmodel.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(that.state.inputval)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    disabled={disabled}
                    text="确定"
                />,

            ]
        }
        let inputTilte = ''
        if (showmodel.title === "编辑组织名称") {
            inputTilte = "客户名称"
        }
        else {
            inputTilte = "认证客户名称"
        }
        const searchOrgoptions = this.state.searchOrgData.map(d => <Option  key={d.key}>{d.label}</Option>);

        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>
                    <Form.Item label={inputTilte}>
                        {getFieldDecorator('company', {
                            initialValue: showmodel.value,
                            rules: [

                                {
                                    required: true,
                                    message: '请输入天眼查注册过的公司名称',
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                labelInValue
                                placeholder="请输入公司名称，最少4个字符"
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={this.handleOrgSearch}
                                onChange={this.handleOrgChange}
                                notFoundContent={null}
                            >
                                {searchOrgoptions}
                            </Select>
                        )}
                    </Form.Item>


                </Form>

                {/* <div className="model_list">
                    <div className='model_title'>{showmodel.lable}</div>
                    <Input
                    className="model_input"
                    onCompositionStart={that.onChange}
                    onCompositionEnd={that.onChange}
                    value={that.state.inputval}
                    placeholder={showmodel.value}
                    onChange={(value) => {that.onChange(value)}}
                    />
                </div> */}

            </Modal>
        );
    }
}
const UserModel = Form.create({ name: 'register' })(UserModelForm);

export default UserModel;
