import React from 'react';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
const NoData = props => 

<div className="renderEmpty renderEmpty_flex">
<div className="renderEmpty_center">
    <ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
    <p>暂无数据</p>
</div>
</div>;

export default NoData;
