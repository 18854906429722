import React, { Component } from 'react'
import { message, Table } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { deviceDetail } from '@/redux/actions/device';
import { loggerfile, loggerfiledownload } from "@/api/event";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NoData from '@/components/NoData'

const div_nowrap = {
    display: 'inline'
}
class Downloadeventlogger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventloggerlist: [],
            columns: [

                {
                    title: '参数列表',
                    dataIndex: 'timestamp',
                    key: 1,
                    render: (text, record) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ABB.Icon name="abb/folder" style={{ marginRight: 16, color: "#868686" }} sizeClass='medium' />
                            <span>{record.loggerFile}</span>
                        </div>
                    )
                },
                {
                    title: '操作',
                    dataIndex: 'name',
                    key: 2,
                    width: 70,
                    render: (text, record) => (
                        <div style={div_nowrap}
                        onClick={() => this.Fndownload(record.loggerId)}
                        >
                            <ABB.Icon name="abb/download" sizeClass='small' />
                        </div>
                    )
                },

            ],
            feature: ''
        };

    }
    Fndownload = (id) => {
        const hide = message.loading('正在下载', 0);
        let { _deviceDetail, getAssetIsPowertrain,getEventDetail } = this.props;
        let isSubAsset = ``;
        let url;
        if (getAssetIsPowertrain === true) {
			isSubAsset = `?isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}
        url = `${_deviceDetail.assetId}/${id}${isSubAsset}`;
        loggerfiledownload(url).then(res => {
            let resdata = res.data;
            let blob = resdata.stream;
            let url = blob;
            const a = document.createElement('a');
            a.style.display = 'none';
            a.download = resdata.filename;
            a.href = url;
            a.click();
            document.body.removeChild(a);
            hide();
        }).catch(err => {
            hide();
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                // message.error('下载失败')
            }
        })
    }

    componentDidMount() {
        this.geteventloggerlist();
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    geteventloggerlist = () => {
        //接口数据

        let { _deviceDetail, getAssetIsPowertrain,getEventDetail } = this.props;

        let isSubAsset = ``;
        let url;
        if (getAssetIsPowertrain === true) {
			isSubAsset = `?isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}
        url = `${_deviceDetail.assetId}/${getEventDetail.eventId}${isSubAsset}`
        loggerfile(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                this.setState({
                    eventloggerlist: resdata
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("geteventloggerlist -- loggerfile --接口异常")
                message.error('接口异常')
            }
        })

    }
    render() {
        let that = this;
        return (
            <div style={{ position: 'relative', minHeight: '54vh',padding: '12px 0' }}>
                {
                    this.state.eventloggerlist.length > 0 ?(
                        <Table
                            showHeader={false}
                            size='small'

                            // scroll={{ y: 200 }}
                            className='org_right'
                            ellipsis={true}
                            columns={that.state.columns}
                            dataSource={that.state.eventloggerlist}
                            // pagination={false}
                            rowKey={(record, item) => record.id}
                        />
                    ) : <NoData />
            }

            </div>
        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Downloadeventlogger));
// export default Downloadeventlogger;