import React, { Component } from 'react';
// import $axios from '../../axios/$axios';
import {devicedetail} from '@/api/device.js'
import { Tooltip,message,Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deviceDetail, checkAllgen, eventDetaildevice, userCheckDevice } from '@/redux/actions/device';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const generalnformation = {
    // border: '1px solid #d8d8d8',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'left',
    // alignItems: 'center',
    padding: '16px 0 0 0',
    fontSize: '14px',
    color: '#1F1F1F',
    // marginBottom: '20px'
}
const EUorg_title = {
    lineHeight: '18px',
    fontSize: '16px',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    flexFlow: 'row',
    color: '#1F1F1F',
    cursor: 'pointer'
}
const checkdetail = {
    color: "rgb(24, 144, 255)",
    lineHeight: '30px',
}
const icon_center = {
    margin: '0px 10px 0 10px',
    display: 'inline'
}
const gen_li = {
    width: '33.3333%',
    color: '#1F1F1F',
    lineHeight: '18px',
    padding: '0px 10px 0 0',
    marginBottom: '8px'
}
const gen_lidetail = {
    width: '66.66%',
    color: '#1F1F1F',
    lineHeight: '18px',
    padding: '0px 10px 0 0',
    marginBottom: '8px'
}
class DeviceMessage extends Component {
    state = {
        deviceDetails: '',
		loading: false,
        UsercheckDeviceDetail: this.props.UsercheckDeviceDetail,
    };

    checkall = () => {
        let { getassettype,getRouterLinkIndex } = this.props;
        if (getassettype === "PowerTrain") {
            if(getRouterLinkIndex === 2){
                this.props.userCheckDevice(11)
            }else{
                this.props.userCheckDevice(3)
            }
            
        }
        else if (getassettype === "noPowerTrain") {
            this.props.userCheckDevice(11)
        }
        else {
            this.props.userCheckDevice(0);
        }
        this.props.eventDetaildevice('0')
        this.props.checkAllgen('0')
    }
    assetPropertiesData = (assetPropertyName, assetPropertyValue) => {
        if (assetPropertyName === 'Motor Standard') {
            return (
                <div>
                    电机标准: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Motor Load') {
            return (
                <div>
                    电机负载: {assetPropertyValue}
                </div>
            )
        }
        // if(assetPropertyName === 'Motor Duty'){
        //     return (
        //         <div>
        //             电机负载: {assetPropertyValue}
        //         </div> 
        //     )
        // }
        if (assetPropertyName === 'Speed') {
            return (
                <div>
                    转速: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Speed Control') {
            return (
                <div>
                    速度控制: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Voltage') {
            return (
                <div>
                    电压: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Current') {
            return (
                <div>
                    电流: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Power') {
            return (
                <div>
                    功率: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Line Frequency') {
            return (
                <div>
                    工频: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Power Factor') {
            return (
                <div>
                    功率因数: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'DE Bearing') {
            return (
                <div>
                    驱动端轴承: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'DE Manufacturer') {
            return (
                <div>
                    驱动端生产商: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'NDE Bearing') {
            return (
                <div>
                    非驱动端轴承: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'NDE Manufacturer') {
            return (
                <div>
                    非驱动端生产商: {assetPropertyValue}
                </div>
            )
        }

        if (assetPropertyName === 'Shaft Height') {
            return (
                <div>
                    轴高: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Greasing Event') {
            return (
                <div>
                    注油事件: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Relubrication Interval') {
            return (
                <div>
                    额定润滑间隔: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Reference Temperature') {
            return (
                <div>
                    参考温度: {assetPropertyValue}
                </div>
            )
        }
        if (assetPropertyName === 'Motor Type') {
            return (
                <div>
                    电机类型: {assetPropertyValue}
                </div>
            )
        }
        else {
            return (
                <div>
                    {assetPropertyName}: {assetPropertyValue}
                </div>
            )
        }
    }
    componentDidMount(){
        this.detailrequest()
    }
    componentDidUpdate(nextProps, prevState) {
		if (prevState.UsercheckDeviceDetail !== this.props.UsercheckDeviceDetail) {
			this.setState({
				UsercheckDeviceDetail: this.props.UsercheckDeviceDetail,
			}, () => {
				this.detailrequest()
			})
		}
	}
    detailrequest = () => {
		let { getAssetIsPowertrain,getassetId } = this.props;
		let isSubAsset = '';
		if (getAssetIsPowertrain === true) {
			isSubAsset = `&isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}
        this.setState({
            loading: true
        })
		let url = `?id=${getassetId}${isSubAsset}`;
		devicedetail(url).then(res => {
			let resdata = res.data;
			console.log("devicedetaildevicedetail---",res.data)
            this.setState({
                deviceDetails: resdata.data,
                loading: false
            })
		}).catch(err => {
			this.setState({
				loading: false
			})
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('设备详情接口异常')
			}
		})
	}
    render() {
        const { _deviceDetail, checkAll } = this.props;
        let {deviceDetails} = this.state;
        // let objnull = (JSON.stringify(_deviceDetail) === "{}")
        let that = this;
        return (
            <div className='deviceDetail_status' style={{ minHeight: '88vh' }}>
                <Spin spinning={this.state.loading} style={{ minHeight: '50vh' }}>
                <div className="EUorg_title_back">
					<div className='backup_button' onClick={this.checkall}>
						<ABB.Icon name="abb/left" sizeClass='medium' />
						<span>综合说明</span>

					</div>
				</div>
                {
                    deviceDetails !== '' ? (
                        <div>
                            {
                                _deviceDetail.assetType === 3 ? (
                                    <div>
                                        <div className='device_title device_detail_genera_title'>
                                            电机详情
                                        </div>
                                        <div style={generalnformation}>
                                            <div style={gen_li}>设备id：{_deviceDetail.assetId || '-'}</div>
                                            <div style={gen_li}>设备名称：{_deviceDetail.assetName || '-'}</div>
                                            {
                                                _deviceDetail.assetType === 2 ? (
                                                    <div style={gen_li}>设备类型：传动</div>
                                                ) : (
                                                        <div style={gen_li}>设备类型：电机</div>
                                                    )
                                            }
                                            {
                                                deviceDetails !== null ? (
                                                    <>
                                                        <div style={gen_li}>厂名： {deviceDetails.details.serialnumber || '-'}</div>
                                                        <div style={gen_li}>组织名称：{deviceDetails.details.serialnumber || '-'}</div>
                                                        <div style={gen_li}>序列号：{deviceDetails.details.serialNumber || '-'}</div>
                                                        <div style={gen_li}>描述：{deviceDetails.details.description || '-'}</div>
                                                        <div style={gen_li}>责任人：{deviceDetails.details.serialnumber || '-'}</div>

                                                    </>
                                                ) : ''
                                            }

                                        </div>
                                        <div className='device_title device_detail_genera_title'>
                                            细节详情
                                        </div>

                                        {
                                            (deviceDetails.details.assetProperties !== undefined && deviceDetails.details.assetProperties !== null) ? (
                                                <div style={generalnformation}>
                                                    {
                                                        deviceDetails.details.assetProperties.map(function (item, index) {
                                                            return (
                                                                <div style={gen_li}>
                                                                    {
                                                                        that.assetPropertiesData(item.assetPropertyName, item.assetPropertyValue)
                                                                    }
                                                                </div>

                                                            )
                                                        })
                                                    }
                                                    <div style={gen_li}>
                                                        传感器ID：{deviceDetails.details.sensorIdentifier || '-'}
                                                    </div>
                                                </div>

                                            ) : <div style={{ textAlign: "center", marginTop: 12, color: "#1f1f1f" }}>暂无数据</div>

                                        }

                                    </div>
                                ) : (
                                        <>
                                            {
                                               deviceDetails !== null ? (
                                                    <div>
                                                        <div className='device_title device_detail_genera_title'>
                                                            传动信息
                                                    </div>
                                                        <div style={generalnformation}>
                                                            <div style={gen_li}>序列号：{deviceDetails.details.serialNumber || "-"}</div>
                                                            <div style={gen_lidetail}>型号代码： {deviceDetails.details.typeCode || "-"}</div>
                                                            <div style={gen_li}>传动固件版本：{deviceDetails.details.driveFirmwareVersion || "-"}</div>
                                                            <div style={gen_lidetail}>传动名称：{_deviceDetail.assetName || "-"}</div>
                                                            <div style={gen_li}>LoadingPackage版本：{deviceDetails.details.driveLoadingPackage||"-"}</div>
                                                        </div>

                                                        <div className='device_title device_detail_genera_title'>
                                                            网关信息
                                                    </div>
                                                        <div style={generalnformation}>
                                                            <div style={gen_li}>网关型号：{deviceDetails.details.gatewayType || "-"}</div>
                                                            <div style={gen_li}>网关序列号：{deviceDetails.details.gatewaySerialNumber || "-"}</div>
                                                            <div style={gen_li}>最后接收数据时间：{deviceDetails.details.latestDataReceived ==='' || deviceDetails.details.latestDataReceived === undefined || deviceDetails.details.latestDataReceived === null ? '-' : moment.parseZone(deviceDetails.details.latestDataReceived).local().format('YYYY-MM-DD HH:mm:ss')}</div>
                                                            {
                                                                deviceDetails.details.gatewayType === "IoTPanel" ? (
                                                                    <>
                                                                        <div style={gen_li}>SIM ICCID：{deviceDetails.details.simIccid || "-"}</div>
                                                                        <div style={gen_li}>运营商：{deviceDetails.details.networkOperator || "-"}</div>
                                                                        <div style={gen_li}>现场信号强度：{deviceDetails.details.signalStrength || "-"}</div>
                                                                    </>
                                                                ) : ''
                                                            }

                                                            <div style={gen_li}>当前固件版本：{deviceDetails.details.gatewayVersion || "-"}</div>
                                                            <div style={gen_lidetail}>上次固件升级时间：
                                                            {
                                                                    deviceDetails.details.firmwareUpdates.length > 0 ? (
                                                                        <Tooltip placement="bottom" title={
                                                                            <>
                                                                                <div className="logtitle">最近三次升级记录</div>
                                                                                {
                                                                                    deviceDetails.details.firmwareUpdates.map((item, index) => {
                                                                                        return (
                                                                                            <div className="lodlist" key={index}>
                                                                                                <div>版本：{item.version} </div>
                                                                                                <div>{moment.parseZone(Number(item.updateTime)).local().format('YYYY-MM-DD')} </div>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        }>
                                                                            <div style={icon_center}>
                                                                                <ABB.Icon style={{ 'color': '#1f1f1f' }} name="abb/help-circle-2" sizeClass='small' />
                                                                            </div>
                                                                        </Tooltip>
                                                                    ) : ''
                                                                }

                                                                {deviceDetails.details.lastUpdateGatewayFirmwareTime ==='' || deviceDetails.details.lastUpdateGatewayFirmwareTime === undefined || deviceDetails.details.lastUpdateGatewayFirmwareTime === null ? '-' : moment.parseZone(Number( deviceDetails.details.lastUpdateGatewayFirmwareTime)).local().format('YYYY-MM-DD')}
                                                                
                                                                </div>

                                                        </div>
                                                    </div>
                                                ) : ''
                                            }

                                        </>
                                    )
                            }
                            <div className='device_title device_detail_genera_title'>
                                订阅信息
                            </div>
                            <div style={generalnformation}>
                                <div style={gen_li}>
                                    订阅等级：{deviceDetails.subscription.subscriptionLevel || "-"}
                                </div>
                                <div style={gen_li} >
                                    订阅服务期至：{moment.parseZone(deviceDetails.subscription.expiredDateTime).local().format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                                <div style={gen_li}>
                                    订阅类型：{deviceDetails.subscription.subscriptionType || "-"}
                                </div>
                            </div>
                        </div>
                    ) : ""
                }
                </Spin>
            </div>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {

        dispatch(deviceDetail(data));
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeviceMessage));
// export default Generalnformation;

