import * as actionTypes from '../constants/index';
const _getCBMdata = ''

const _getCBMtype = 'fan'
const getCBMdata = (state = _getCBMdata, action) => {
	switch (action.type) {
		case actionTypes.CBM_DATA:
			return action.data;
		default:
			return state;
	}
};

const getCBMtype = (state = _getCBMtype, action) => {
	switch (action.type) {
		case actionTypes.CBM_TYPE:
			return action.data;
		default:
			return state;
	}
};



export {getCBMdata,getCBMtype};