import React, { Component } from 'react';
import { Input, Table, Tooltip, message } from 'antd';
import {APICheckDeviceInfo} from '@/api/tools'
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';


const { Search } = Input;

class CheckDevice extends Component {
	state = {
		pagination: {},
		loading: false,
		modal2Visible: false,
		selectedRowKeys: [],
		pageindex: 1,
		pageSize: 10,
		location: '###',
		driveInfo: [],
		gatewayInfo: [],
		subscriptionInfo: [],
		device_columns: [
			{
				title: '设备名称',
				dataIndex: 'deviceName',
				key: '0',
				width: '20%'
			},
			{
				title: '序列号',
				dataIndex: 'deviceSN',
				key: '1',
				width: '20%'
			},
			{
				title: '类型',
				dataIndex: 'deviceType',
				key: '2',
				width: '10%'
			},
			{
				title: '固件版本',
				dataIndex: 'deviceFWVersion',
				key: '3',
				width: '10%'
			},
			{
				title: '所属客户',
				dataIndex: 'orgEU',
				key: '4',
				width: '20%'
			},

			{
				title: '所属CP',
				dataIndex: 'orgCP',
				key: '5',
				width: '20%'
			},
			{
				title: '所在地址',
				dataIndex: 'address',
				key: '6',
				width: '40%'
			},
			{
				title: '添加设备时间',
				dataIndex: 'addedDate',
				key: '7',
				width: '25%'
			}
		],
		// gatewayType, gatewaySN, simIccID, signalStrength, latestReceivedDate
		gateway_columns: [
			{
				title: '关联网关类型',
				dataIndex: 'gatewayType',
				key: '0',
				width: '10%'
			},
			{
				title: '网关序列号',
				dataIndex: 'gatewaySN',
				key: '1',
				width: '10%'
			},
			{
				title: 'ICCID',
				dataIndex: 'simIccID',
				key: '2',
				width: '10%'
			},
			{
				title: '信号强度',
				dataIndex: 'signalStrength',
				key: '3',
				width: '10%'
			},
			{
				title: '最后数据上传时间',
				dataIndex: 'latestReceivedDate',
				key: '4',
				width: '10%'
			}
		],
		// No， 订阅类型 订阅等级，订阅时长，订阅起始日期，订阅结束日期，订阅码，订阅状态
		// No, subscriptionType, subscriptionLevel, subscriptionPeriodTime, subscriptionEndDate, subscriptionStartDate, subscriptionCode, SubscriptionStatus
		subscription_columns: [
			{
				title: '订阅类型',
				dataIndex: 'subscriptionType',
				key: '1',
				width: '20%'
			},
			{
				title: '订阅等级',
				dataIndex: 'subscriptionLevel',
				key: '2',
				width: '20%'
			},
			{
				title: '订阅时长',
				dataIndex: 'validPeriodTime',
				key: '3',
				width: '10%'
			},
			{
				title: '订阅结束日期',
				dataIndex: 'expiredTime',
				key: '4',
				width: '20%'
			},

			{
				title: '订阅起始日期',
				dataIndex: 'activatedStartTime',
				key: '5',
				width: '20%'
			},
			{
				title: '订阅码',
				dataIndex: 'subscriptionCode',
				key: '6',
				width: '30%'
			},
			{
				title: '订阅状态',
				dataIndex: 'subscriptionStatus',
				key: '7',
				width: '10%'
			}
		]
		// 关联网关类型，网关序列号， ICCID 信号强度，最后数据上传时间， 固件版本
	};

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	
	initdata = () => {
		this.fetch(1, '');
	};
	// 搜索数据
	onserch = value => {
		this.fetch(value.replace(/\s*/g, ''));
	};
	//接口数据
	fetch = (value) => {
		let url = `?drive_sn=${value}`;
		let that = this;
		APICheckDeviceInfo(url)
			.then(res => {
				if (res !== undefined) {
					
					let resdata = res.data.results;
					resdata.subscription_info.forEach((item, index) => {
						item.expiredTime = item.expiredTime ? moment.parseZone(item.expiredTime).local().format('YYYY-MM-DD HH:mm:ss') : '未知';
						item.validPeriodTime = item.validPeriodTime.toString() + '个月';
						item.activatedStartTime = item.activatedStartTime ? moment.parseZone(item.activatedStartTime).local().format('YYYY-MM-DD HH:mm:ss') : '未知';

					});
					this.setState({
						driveInfo: [{
							deviceName: resdata.drive_info.deviceName,
							deviceSN: resdata.drive_info.deviceSN,
							deviceType: resdata.drive_info.deviceType,
							deviceFWVersion: resdata.drive_info.deviceFWVersion,
							orgEU: resdata.drive_info.organizationName,
							orgCP: resdata.drive_info.cpOrganizationName,
							address: resdata.drive_info.address ? resdata.drive_info.address : '未知',
							addedDate: moment.parseZone(resdata.drive_info.addedDate).local().format('YYYY-MM-DD HH:mm:ss')

						}],
						// gatewayType, gatewaySN, simIccID, signalStrength, latestReceivedDate
						// res.data.results.driveProductCode ? res.data.results.driveProductCode : '-'}
						gatewayInfo:[{
							gatewayType: resdata.gateway_info.gatewayType,
							gatewaySN: resdata.gateway_info.gatewaySN,
							simIccID: resdata.gateway_info.simIccid ? resdata.gateway_info.simIccid: '未知',
							signalStrength: resdata.gateway_info.signalStrength ? resdata.gateway_info.signalStrength : '未知',
							latestReceivedDate: resdata.gateway_info.latestDataReceived ? moment.parseZone(resdata.gateway_info.latestDataReceived).local().format('YYYY-MM-DD HH:mm:ss') : '未知'
					
						}],
						subscriptionInfo: resdata.subscription_info

					});
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data.error.message);
				} else {
					message.error('查询传动详细信息接口异常');
				}
				that.setState({
					subscriptionInfo: [],
					gatewayInfo: [],
					driveInfo: []
				})
				
			});
	};
	onChange = e => {
		this.setState({
			pageindex: e
		});
		this.fetch(e, '');
	};
	render() {

		return (
			<>
				<div className="user_box ABB_tab_marginTop">
					<Search placeholder="输入序列号" onSearch={this.onserch} style={{ width: 190 }} className="search_input" />
				</div>
				<div className="EUorg_title">
					<span>传动信息</span>
				</div>
				<Table
					size="small"
					scroll={{ x: 1140 }}
					columns={this.state.device_columns}
					dataSource={this.state.driveInfo}
					pagination={false}
					rowKey={(record, index) => index}
				/>
				<div className="EUorg_title">
					<span>网关信息</span>
				</div>
				<Table
					size="small"
					scroll={{ x: 1140 }}
					columns={this.state.gateway_columns}
					dataSource={this.state.gatewayInfo}
					pagination={false}
					// pagination={{
					// 	onChange: page => this.onChange(page),
					// 	pageSize: pageSize,
					// 	current: pageindex,
					// 	total: this.state.subscriptionlist.totalCount
					// }}
					rowKey={(record, index) => index}
				/>
				<div className="EUorg_title">
					<span>订阅信息</span>
				</div>
				<Table
					size="small"
					scroll={{ x: 1140 }}
					columns={this.state.subscription_columns}
					pagination={false}
					dataSource={this.state.subscriptionInfo}
					// pagination={{
					// 	onChange: page => this.onChange(page),
					// 	pageSize: pageSize,
					// 	current: pageindex,
					// 	total: this.state.subscriptionlist.totalCount
					// }}
					rowKey={(record, index) => index}
				/>
			</>
		);
	}
}

export default CheckDevice;
