import React from 'react';
import {
    Form,
    Input,
    Select,
    Modal,
    message,
    Tooltip
} from 'antd';
import { getuserInfo, createEU, APIQueryIndestryList, APICreateOrg } from '@/api/user.js';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import guild from '@/utils/guild1'
import { organizationKeywordSearch } from "@/api/client";

import { setIndustryList, setShowIndustriesConfig, setShowIndustryList } from '../../store/actionCreators';
import IndustriesModel from './components/industriesModel';


const { Option } = Select;
let timeout;
let currentValue;

function fetchOrgSearch(value, callback) {
    currentValue = value;
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    function fake() {
        if (currentValue.length < 4 || currentValue.length > 100) {
            callback([]);
            return;
        }
        organizationKeywordSearch(currentValue)
            .then(body => {
                if (!body.data || !body.data.payload) {
                    callback([])
                }
                const data = body.data.payload.map(org => ({
                    label: org.companyName,
                    key: creditCodeNull(org.creditCode),
                }));
                callback(data);
            });
    }

    timeout = setTimeout(fake, 300);
}

function creditCodeNull(value) {
    if (!value) {
        return `null-${Math.random()}`
    }
    return value
}


class RegistrationForm extends React.Component {
    state = {
        confirmDirty: false,
        createOrgdata: false,
        autoCompleteResult: [],
        userName: '',
        userPhone: '',
        disabled: false,
        createDisabled: false,
        selectChildrendata: [],
        searchOrgData: [],
        searchOrgValue: undefined,
    };
    componentDidMount() {

        this._queryIndustry()
    }
    _queryIndustry = () => {
        APIQueryIndestryList().then(res => {
            if (res !== undefined) {
                let resData = res.data
                this.props.updateIndestryList(resData.data)

                this.props.updateShowIndestryList([...resData.data, ...[{
                    firstIndustry: "其它",
                    secondIndustry: "其它"
                }]])
            }

        }).catch(err => {

            if (err && err.message) {
                console.log(err.message);
            }

            // message.error('行业信息获取失败')
        })
    }
    UNSAFE_componentWillMount() {
        this.fetch()

    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };
    //获取个人信息
    fetch = () => {
        let url = `/${this.props.userInfo.userId}`;
        getuserInfo(url).then(res => {
            if (res !== undefined) {
                this.setState({
                    userName: res.data.name,
                    userPhone: res.data.phone
                })
            }

        }).catch(err => {
            message.error('获取失败！')
        })
    }
    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    handleWebsiteChange = value => {
        let autoCompleteResult;
        if (!value) {
            autoCompleteResult = [];
        } else {
            autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
        }
        this.setState({ autoCompleteResult });
    };
    FncreateOrg(createOrgdata) {
        if (this.state.disabled === false) {
            this.setState({ createOrgdata: createOrgdata });
            this.props.form.resetFields();
        }
        else {
            message.warning("当前用户是管理员，不需要创建组织！")
        }
    }
    rescreateOrg = () => {


        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {

            if (!err) {

                let Industries = fieldsValue.industry.split('-')

                if (Industries.length !== 2) {

                    message.error('行业信息格式有误')
                    return
                }

                let url = {
                    "organizationType": 3,
                    "name": fieldsValue.organization.label,
                    "creditCode": fieldsValue.organization.key.indexOf('null-') === -1 ? fieldsValue.organization.key : '',
                    // "industry": fieldsValue.industry,
                    "firstIndustry": Industries[0],//一级行业
                    "secondIndustry": Industries[1],//二级行业
                    // "province": '北京市',
                    // "city": '北京',
                    // "district": '通州区',
                    "address": fieldsValue.company.trim(),
                    "contact": this.state.userName || '',
                    "phone": this.state.userPhone || '',

                };
                this.setState({
                    createDisabled: true
                })

                APICreateOrg(url).then(res => {
                    if (res !== undefined) {
                        message.success('用户组织创建成功')

                        that.setState({
                            createOrgdata: false,
                            createDisabled: false
                        })
                        that.updateOrg(1)
                        that.props.form.resetFields();
                    }
                }).catch(err => {
                    that.setState({
                        createDisabled: false
                    })
                    if (err.code === 400) {
                        message.warning(err.data)
                    }
                    else if (err.code === 404) {
                        message.warning(err.data)
                    }
                    else {
                        message.error('用户组织创建失败！')
                    }

                })
            }
        });

    }
    updateOrg = (val) => {
        this.props.updateOrg(val)
    }
    handleSearch = (e) => {
        const industries = [...this.props.getIndustryList]

        console.log("输入内容=============", industries, e)

        let data = [];
        if (industries.length != 0 && e) {

            let str = `\S*${e}\S*`;
            let reg = new RegExp(str, 'i');//不区分大小写

            industries.map(item => {
                if (reg.test(`${item.firstIndustry}-${item.secondIndustry}`)) {
                    data.push(item);
                }
            })
        }
        data.push({
            firstIndustry: "其它",
            secondIndustry: "其它"
        })
        if (data.length > 0) {
            this.props.updateShowIndestryList(data)

        }
        console.log("输入内容============data=", data)

    }
    onChangeselect = (e) => {

        // return data;
        return

        setTimeout(() => {
            let getFieldsValue = this.props.form.getFieldsValue();
            this.props.form.setFieldsValue({
                industry1: undefined

            })
            guild.map(item => {

                if (item.name === getFieldsValue.industry) {
                    this.setState({
                        selectChildrendata: item.children,
                    })
                }

            })
        }, 0);
    }
    handleOrgSearch = value => {
        if (value) {
            fetchOrgSearch(value, data => this.setState({ searchOrgData: data }));
        } else {
            this.setState({ searchOrgData: [] });
        }
    };

    handleOrgChange = value => {
        this.setState({ searchOrgValue: value });
    };


    //  返回标题信息
    _renderHeaderByTitle = (isMust, title) => {
        return <div className='section_item'>
            {isMust === true ? (<div className='section_icon'></div>) : null}
            <div >{title}</div>
        </div>

    }

    _gotoIndustry = () => {

        console.log("进入全部行业")
        let config = { ...this.props.getShowIndustriesConfig }

        config.visible = true


        console.log("conffig ===============", config);

        this.props.updateShowIndustriesConfig(config)

    }
    render() {
        let that = this;
        const { getFieldDecorator } = that.props.form;
        const { autoCompleteResult, disabled, selectChildrendata, createDisabled } = that.state;
        const industries = [...this.props.getShowIndustryList]



        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        const searchOrgoptions = this.state.searchOrgData.map(d => <Option key={d.key}>{d.label}</Option>);

        return (
            <>
                <div className="join_box">
                    <div className="join_center">
                        <div className="join_botton" onClick={() => that.FncreateOrg(true)}>
                            <ABB.Button
                                disabled={disabled}
                                type="primary-blue"
                                sizeClass='small' theme="filled"
                                text="创建组织"
                                icon="abb/plus-in-circle"
                            />

                        </div>

                        <div className="joinorg">如果您要加入现有组织,请等待组织管理员邀请您加入.</div>
                    </div>

                </div>

                <Modal
                    title="创建组织"
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                    centered
                    width={504}
                    maskClosable={false}
                    visible={that.state.createOrgdata}
                    onOk={() => that.FncreateOrg(false)}
                    onCancel={() => that.FncreateOrg(false)}
                    footer={[
                        <ABB.Button
                            onClick={() => that.FncreateOrg(false)}
                            type="discreet-black"
                            sizeClass='small' theme="filled"
                            disabled={createDisabled}
                            text="取消"
                        />,
                        <ABB.Button
                            onClick={() => that.rescreateOrg()}
                            type="primary-blue"
                            sizeClass='small' theme="filled"
                            disabled={createDisabled}
                            text="确定"
                        />,

                    ]}

                >
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <div className="from_list_sub" >
                            {
                                this._renderHeaderByTitle(false, '公司名称')
                            }

                            <Form.Item  >
                                {getFieldDecorator('organization', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入天眼查注册过的公司名称',
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        labelInValue
                                        placeholder="请输入公司名称，最少4个字符"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={this.handleOrgSearch}
                                        onChange={this.handleOrgChange}
                                        notFoundContent={null}
                                    >
                                        {searchOrgoptions}
                                    </Select>
                                )}
                            </Form.Item>
                        </div>
                        <div className="from_list_sub" >
                            {
                                this._renderHeaderByTitle(false, '公司详细地址')
                            }

                            <Form.Item  >
                                {getFieldDecorator('company', {
                                    rules: [{ required: true, message: '请输入公司地址' }],
                                })(
                                    <Input className="model_input"
                                        placeholder="请输入公司地址"
                                        style={{ width: 440, marginRight: "64px" }} />
                                )}
                            </Form.Item>


                        </div>
                        <div className="from_list_sub" >
                            <div className='title_name_box'>
                                {
                                    this._renderHeaderByTitle(false, '所属行业')
                                }

                                <Tooltip placement="bottom" title={
                                    <span>全部行业</span>
                                }>
                                    <div className="orgToolTip" onClick={this._gotoIndustry}>
                                        <ABB.Icon name="abb/information-circle-2" sizeClass='small' />
                                    </div>
                                </Tooltip>
                            </div>

                            <Form.Item  >
                                {getFieldDecorator('industry', {
                                    rules: [
                                        { required: true, message: '请选择所属行业' },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        placeholder="请选择所属行业"
                                        suffixIcon={
                                            <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                        }
                                        onChange={this.onChangeselect}
                                        style={{ width: 440, marginRight: "64px" }}

                                        defaultActiveFirstOption={false}
                                        showArrow={true}
                                        filterOption={false}
                                        onSearch={this.handleSearch}
                                        notFoundContent={null}
                                    >
                                        {
                                            industries.map((item, index) =>
                                                <Option value={item.firstIndustry + '-' + item.secondIndustry}>{item.firstIndustry}-{item.secondIndustry}</Option>
                                            )}
                                    </Select>,
                                )}
                            </Form.Item>
                        </div>
                        <div className="model_list_sub" >
                            <div className='from_list_sub'>
                                {
                                    this._renderHeaderByTitle(false, '管理员')
                                }

                                <Form.Item  >
                                    {getFieldDecorator('admin', {
                                        rules: [{ required: false, message: '管理员', whitespace: true }],
                                    })(<Input className="model_input"
                                        style={{ width: 188, marginRight: "64px" }} disabled={true}
                                        placeholder={that.state.userName} />)}
                                </Form.Item>
                            </div>
                            <div className='from_list_sub'>
                                {
                                    this._renderHeaderByTitle(false, '联系方式')
                                }
                                <Form.Item  >
                                    {getFieldDecorator('phone', {
                                        rules: [{ required: false, message: '请输入手机号' }],
                                    })(<Input

                                        className="model_input"
                                        style={{ width: 188, marginRight: "64px" }}
                                        disabled={true} placeholder={that.state.userPhone} />)}
                                </Form.Item>
                            </div>

                        </div>
                        {/* <Form.Item label="公司地址">
                            {getFieldDecorator('city', {
                                initialValue: ['zhejiang', 'hangzhou', 'xihu'],
                                rules: [
                                    { type: 'array', required: true, message: 'Please select your habitual residence!' },
                                ],
                            })(<Cascader options={citytree} />)}
                        </Form.Item> */}



                    </Form>
                </Modal>

                <IndustriesModel />

            </>

        );
    }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrationForm);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({

    setUserInfo: data => {
        dispatch(setUserInfo(data))
    },
    updateIndestryList: data => {
        dispatch(setIndustryList(data))
    },
    updateShowIndestryList: data => {
        dispatch(setShowIndustryList(data))
    },
    updateShowIndustriesConfig: data => {
        dispatch(setShowIndustriesConfig(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WrappedRegistrationForm));
