import React, { Component } from 'react';
import { Modal, Button, Input, Form, Tooltip, Select } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import guild from '@/utils/guild1'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { organizationKeywordSearch } from "@/api/client";

import './style.scss'
import { setShowIndustriesConfig, setShowIndustryList } from '../../../../Orgmanagement/store/actionCreators';
import EuAllIndustriesModel from '../../../../Orgmanagement/components/org/components/euallIndustriesModel';

const { Option } = Select;
let timeout;
let currentValue;

function fetchOrgSearch(value, callback) {
    currentValue = value;
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }

    function fake() {
        if (currentValue.length < 4 || currentValue.length > 100) {
            callback([]);
            return;
        }
        organizationKeywordSearch(currentValue)
            .then(body => {
                if (!body.data || !body.data.payload) {
                    callback([])
                }
                const data = body.data.payload.map(org => ({
                    label: org.companyName,
                    key: creditCodeNull(org.creditCode),
                }));
                console.log('search data:',data)
                callback(data);
            });
    }

    timeout = setTimeout(fake, 300);
}

function creditCodeNull(value) {
    if (!value) {
        return `null-${Math.random()}`
    }
    return value
}

class CreateEU extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            industry1: '',
            selectChildrendata: [],
            searchOrgData: [],
            searchOrgValue: undefined,
        };

    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    handleWebsiteChange = value => {
        let autoCompleteResult;
        if (!value) {
            autoCompleteResult = [];
        } else {
            autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
        }
        this.setState({ autoCompleteResult });
    };
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.props.submitmodel(fieldsValue,()=>{
                    console.log("回调清空数据")
                    this.props.form.resetFields();

                })
                
            }
        });
    }
    onChange = (e) => {
        this.setState({
            inputval: e.target.value
        })
    }
    onChangeselect = (e) => {
        setTimeout(() => {
            let getFieldsValue = this.props.form.getFieldsValue();
            this.props.form.setFieldsValue({
                industry1: undefined

            })
            guild.map(item => {

                if (item.name === getFieldsValue.industry) {
                    this.setState({
                        selectChildrendata: item.children,
                    })
                }

            })
        }, 0);
    }
    handleSearch = (e) => {

       
        const industries = [...this.props.getIndustryList]
      
        let data = [];

        if (e === ''){

            data = [...industries]
 
        } else {
            if (industries.length != 0 && e) {

                let str = `\S*${e}\S*`;
                let reg = new RegExp(str, 'i');//不区分大小写
    
                industries.map(item => {
                    if (reg.test(`${item.firstIndustry}-${item.secondIndustry}`)) {
                        data.push(item);
                    }
                })
            }
          
        }
       

        console.log("输入内容=============", industries, e)

        data.push({
            firstIndustry: "其它",
            secondIndustry: "其它"
        })
  
        if (data.length > 0) {
            this.props.updateShowIndestryList(data)

        }
        console.log("输入内容============data=", data)

    }

    handleOrgSearch = value => {
        if (value) {
            fetchOrgSearch(value, data => this.setState({ searchOrgData: data }));
        } else {
            this.setState({ searchOrgData: [] });
        }
    };

    handleOrgChange = value => {
        this.setState({ searchOrgValue: value });
    };

    //  返回标题信息
    _renderHeaderByTitle = (isMust, title) => {
        return <div className='section_item'>
            {isMust === true ? (<div className='section_icon'></div>) : null}
            <div >{title}</div>
        </div>

    }

    //显示全部行业
    _gotoIndustry = () => {
        this.child1.onshow()
    }
    render() {
        const that = this;
        const { showmodelEU, disabled } = this.props;
        const { getFieldDecorator } = that.props.form;
        const { selectChildrendata, industry1 } = that.state;
        const industries = [...this.props.getShowIndustryList]


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showmodelEU.title,
            centered: showmodelEU.centered,
            width: showmodelEU.width,
            visible: showmodelEU.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(that.state.inputval)}
                    type="primary-blue"
                    disabled={disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        const searchOrgoptions = this.state.searchOrgData.map(d => <Option  key={d.key}>{d.label}</Option>);

        return (
            <>
             
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>

                    <div>
                        {
                            this._renderHeaderByTitle(false, '公司名称')
                        }
                        <Form.Item  >
                            {getFieldDecorator('company', {
                                    rules: [

                                        {
                                            required: true,
                                            message: '请输入天眼查注册过的公司名称',
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        labelInValue
                                        placeholder="请输入公司名称，最少4个字符"
                                        defaultActiveFirstOption={false}
                                        showArrow={false}
                                        filterOption={false}
                                        onSearch={this.handleOrgSearch}
                                        onChange={this.handleOrgChange}
                                        notFoundContent={null}
                                    >
                                        {searchOrgoptions}
                                    </Select>
                            )}
                        </Form.Item>
                    </div>

                    <div>
                        {
                            this._renderHeaderByTitle(false, '公司详细地址')
                        }
                        <Form.Item  >
                            {getFieldDecorator('companyaddress', {
                                rules: [
                                    { required: true, message: '请输入公司地址' },
                                    {
                                        max: 200,
                                        message: '公司地址长度不能大于200',
                                    },
                                    {
                                        whitespace: true,
                                        message: '禁止输入空格',
                                    }
                                ],
                            })(
                                <Input className="model_input"
                                    placeholder="请输入公司地址"
                                />
                            )}
                        </Form.Item>

                    </div>
                    <div>

                        <div className='title_name_box'>
                            {
                                this._renderHeaderByTitle(false, '所属行业')
                            }

                            <Tooltip placement="bottom" title={
                                <span>全部行业</span>
                            }>
                                <div className="orgToolTip" onClick={this._gotoIndustry}>
                                    <ABB.Icon name="abb/information-circle-2" sizeClass='small' />
                                </div>
                            </Tooltip>
                        </div>
                        <Form.Item >
                            {getFieldDecorator('industry', {
                                rules: [
                                    { required: true, message: '请选择所属行业' },
                                ],
                            })(
                                <Select
                                    showSearch
                                    placeholder="请选择所属行业"

                                    onChange={() => this.onChangeselect()}

                                    suffixIcon={
                                        <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                    }
                                    defaultActiveFirstOption={false}
                                    filterOption={false}
                                        onSearch={this.handleSearch}
                                        notFoundContent={null}
                                >
                                    {
                                        industries.map((item, index) =>
                                            <Option value={item.firstIndustry + '-' + item.secondIndustry}>{item.firstIndustry}-{item.secondIndustry}</Option>
                                        )}

                                </Select>,
                            )}
                        </Form.Item>
                    </div>



                    {/* <Form.Item label="联系人">
                        {getFieldDecorator('name', {
                            rules: [
                                
                                {
                                    required: true,
                                    message: '联系人不能为空',
                                },
                            ],
                        })(
                            <Input placeholder="请输入联系人" className="model_input"/>,
                        )}
                    </Form.Item> */}
                    <div>
                        {
                            this._renderHeaderByTitle(false, '用户手机号')
                        }

                        <Form.Item>
                            {getFieldDecorator('phone', {
                                rules: [
                                    {
                                        required: true,
                                        message: '用户手机号不能为空',

                                    }, {

                                        pattern: new RegExp(/^\s*1\s*[3456789]\s*(\d\s*){9}\s*$/, "g"),
                                        message: '手机号格式错误',
                                    }, {
                                        whitespace: true,
                                        message: '禁止输入空格',
                                    }

                                ],
                            })(
                                <Input placeholder="请输入联系方式" className="model_input" />,
                            )}
                        </Form.Item>
                    </div>

                </Form>
            </Modal>
            {/* 行业信息 */}
            <EuAllIndustriesModel   onRef={(ref) =>{this.child1 = ref}}/>
            </>
        );
    }
}
const CreateEUForm = Form.create({ name: 'register' })(CreateEU);


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({

    // updateIndestryList: data => {

    //     dispatch(setIndustryList(data))
    // },
    updateShowIndestryList: data => {
         
        dispatch(setShowIndustryList(data))
    },

    updateShowIndustriesConfig: data => {

        dispatch(setShowIndustriesConfig(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CreateEUForm));
