import React, { Component } from 'react';
import { Modal, Button, Input, message, Form, Select } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { cancelgrant } from '@/api/authorization.js';

const { Search, TextArea } = Input;
class CancelAuthorization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledbutton: false,

        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel()
        this.props.form.resetFields();
    }
    fncancelgrant = (data,val) => {
        
        if(data.name === val.customersname.trim()){
            this.setState({
                disabledbutton: true
            })
            let url = {
                "id": data.id,
            }
    
            cancelgrant(url).then(res => {
                if (res !== undefined) {
                    message.success("取消授权成功！")
                    this.setState({
                        disabledbutton: false
                    })
                    this.props.submitmodel();
                    this.hidemodel()
                }
            }).catch(err => {
                this.setState({
                    disabledbutton: false
                })
                if (err.status === 400 || err.status === 404) {
                    message.warning(err.data);
                } else {
                    console.log("fncancelgrant -- cancelgrant --接口异常")
                    message.error('接口异常');
                }
            });
        }
        else{
            message.warning("客户名称错误！")
        }
    }

    submitmodel = (data) => {

        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.fncancelgrant(data,fieldsValue)
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };

    render() {
        const that = this;
        const { showmodel } = this.props;
        const { getFieldDecorator } = that.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: '取消授权',
            centered: true,
            width: 504,
            maskClosable: false,
            visible: showmodel.visible,
            onOk: () => { that.hidemodel() },
            onCancel: () => { that.hidemodel() },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel()}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodel)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    disabled={that.state.disabledbutton}
                    text="确定"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div style={{color: '#1f1f1f',marginBottom: 8}}>取消授权后<span style={{color: '#36f'}}>{showmodel.name}</span>将不再拥有访问API的权限，且客户正在使用的API密钥信息将不可恢复。请在输入框中输入完整的客户名称以确认。</div>
                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>
                    <Form.Item label="">
                        {getFieldDecorator('customersname', {
                            rules: [
                                {
                                    required: true,
                                    message: '客户名称不能为空',
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            <Input placeholder="请输入上文中蓝色文字内容" className="model_input" />,
                        )}
                    </Form.Item>

                </Form>

            </Modal>
        );
    }
}
const AddFilter = Form.create({ name: 'register' })(CancelAuthorization);

export default AddFilter;
