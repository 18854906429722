import React, { Component } from 'react';
import {
    Form,
    Modal,
    Input,
    message,
    Col,
    Row,
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class TransferControlForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            dataSource: []
        };
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }

    hidemodel = () => {
        this.props.hidemodel()
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log("----------",fieldsValue)
                this.props.showmodelTranferOkFn(fieldsValue)
                this.props.form.resetFields();
            }
        });
    }
    render() {
        const that = this;
        const { showmodelTranfer } = this.props;
        const { getFieldDecorator } = that.props.form;
        const modelProps = {
            title: "移交管理权",
            centered: true,
            width: 354,
            visible: showmodelTranfer.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div className='tranfer-title'><span style={{ color: '#3366ff' }}>{showmodelTranfer.orgMessage}</span>由您在{showmodelTranfer.createdTime}创建。移交管理权后，您将无法查看此公司下的所有设备。</div>
                

                <Form >
                    <Form.Item label="被移交人手机号">
                        {getFieldDecorator('phone', {
                            // initialValue: showassetGroupmodel.value,
                            rules: [

                                {
                                    required: true,
                                    message: '手机号不能为空',
                                },
                                {
                                    pattern: new RegExp(/^\s*1\s*[3456789]\s*(\d\s*){9}\s*$/, "g"),
                                    message: '手机号格式错误',
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            <Input placeholder="请输入手机号" className="model_input" />,
                        )}
                    </Form.Item>

                </Form>

            </Modal>
        );
    }
}
const TransferControl = Form.create({ name: 'register' })(TransferControlForm);
export default TransferControl;
