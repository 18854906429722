import React from 'react';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';

const AuthRouter = ({ component: Component, ...rest }) => {
	// const isLogged = localStorage.getItem('isLogin') === '1' ? true : false;
	return <Route {...rest}  exact={true} render={props => (<Component {...props} />)} />;
};

export default withRouter(AuthRouter);
