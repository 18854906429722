

const getJson = {
    "version": "1.9.18",
    "usefull_links": {
      //ABB备件防伪
      "abb_parts-anti_fake": "https://abbdrive.yangben.io/#!/material/1660b5712d5c4f45a63d9d30f8fcc066/assets/606440b0193e172792442365",
      //ABB视频指导
      "abb_video_guid": "https://abbdrive.yangben.io/#!/material/fd39eb0d5ffb435f8557fc6fe5545799/assets/61cdaa02dd51db24ae6f2a02",
      //快速开始指南
      "application_start_guid": "https://sycfxrsxgy.jiandaoyun.com/sharedoc/436gfzrrtVTQpvR5RAxiYN",
      //用户手册
      "user_guid_ABB": "https://sycfxrsxgy.jiandaoyun.com/sharedoc/1mBKwYEHC2S8MitRfgX3EU",
      "user_guid_CP": "https://sycfxrsxgy.jiandaoyun.com/sharedoc/1rOT3xlRvbpZhl0Ni8AJgx",
      "user_guid_EU": "https://sycfxrsxgy.jiandaoyun.com/sharedoc/1rOT3xlRvbpZhl0Ni8AJgx",
      //可接受政策
      "acceptable_user_policy": "https://digital.motion.abb.com.cn/acceptable-user-policy.pdf",
      //用户协议传动
      "user_license_drive": "https://digital.motion.abb.com.cn/user_license_drive.pdf",
      //用户协议电机
      "user_license_motor": "https://search.abb.com/library/Download.aspx?DocumentID=9AKK107045A3315&LanguageCode=en&DocumentPartId=&Action=Launch"
    }
  }
export default getJson