import styled from "styled-components";

export const EmptyData = styled.div`
	font-size: 12px;
	font-family: ABBvoiceCNSG-Regular;
	line-height: 15px;
	color: #1F1F1F;
    paddingTop: 20, 
    minHeight: 100

`