import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { last7Days } from '@/api/chart.js'
import moment from 'moment';
import { Spin, message } from 'antd';

class EchartsLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seriesData: {},
            loading: false
        };
        // this.changeData  = this.changeData.bind(this);
        this.fetch = this.fetch.bind(this);
    }

    // changeData(){
    //     this.setState({
    //         seriesData: {}
    //     });
    // }

    fetch = () => {
        this.setState({
            loading: true
        })
        let url = '?utcOffsetMinutes=' + moment(new Date()).utcOffset();
        last7Days(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                let resDate = [], resFault = [], resWarning = []
                for (let i = 0; i < resdata.length; i++) {
                    resDate.push(resdata[i].dateStr);
                    switch (resdata[i].data.length) {
                        case 2:
                            for (let j = 0; j < resdata[i].data.length; j++) {
                                if (resdata[i].data[j].deviceEventType == 'Warning') {
                                    resWarning.push(resdata[i].data[j].eventCount);
                                }
                                else {
                                    resFault.push(resdata[i].data[j].eventCount)
                                }
                            };
                            break;
                        case 1:
                            if (resdata[i].data[0].deviceEventType == 'Warning') {
                                resWarning.push(resdata[i].data[0].eventCount);
                                resFault.push(0)
                            }
                            else {
                                resFault.push(resdata[i].data[0].eventCount);
                                resWarning.push(0)
                            };
                            break;
                        case 0:
                            resWarning.push(0);
                            resFault.push(0);
                            break;
                    }
                }
                let option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                            type: 'line'        // 默认为直线，可选为：'line' | 'shadow'
                        }
                    },
                    grid: {
                        left: '40px',
                        right: '90px',
                        top: '30px',
                        bottom: '50px',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: resDate.reverse(),
                        axisLine: {
                            lineStyle: {
                                color: '#BABABA'
                            }
                        },
                        axisLabel: {
                            fontFamily: 'ABBvoiceCNSG-Regular',
                            fontSize: 16,
                            color: '#686868'
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    yAxis: {
                        type: 'value',
                        splitNumber: 4,
                        axisLabel: {
                            fontFamily: 'ABBvoiceCNSG-Regular',
                            fontSize: 16,
                            color: '#686868'
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#BABABA'
                            }
                        }
                    },
                    legend: {
                        selectedMode: false,
                        top: 20,
                        right: 0,
                        orient: 'vertical',
                        textStyle: {
                            fontFamily: 'ABBvoiceCNSG-Regular',
                            fontSize: 16,
                        },
                        icon: 'circle',
                        data: ['告警', '故障']
                    },
                    series: [
                        {
                            name: '告警',
                            type: 'line',
                            symbol: 'none',
                            color: '#FFD800',
                            data: resWarning.reverse(),
                        },
                        {
                            name: '故障',
                            type: 'line',
                            symbol: 'none',
                            color: '#F03040',
                            data: resFault.reverse(),
                        }
                    ]
                }
                this.setState({
                    seriesData: option,
                    loading: false
                })
            }


        })
    }
    componentDidMount() {
        this.fetch();
    }
    render() {
        return (
            <Spin spinning={this.state.loading} style={{ height: '260px' }}>
                <div style={{ height: '100%', width: '100%' }}>
                    <ReactEcharts
                        option={this.state.seriesData}
                        style={{ height: '320px', width: '100%' }}
                        opts={{ renderer: 'svg' }}
                        className='react_for_echarts'
                    />
                </div>
            </Spin>

        );
    }
}

export default EchartsLine;