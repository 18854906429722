import React, { Component } from 'react';
import { Modal, Button, Input, message, Form, Select } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { postponegrant } from '@/api/authorization.js';

const { Search, TextArea } = Input;
class PostponeAuthorization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledbutton: false,

        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel()
        this.props.form.resetFields();
    }
    fupostponegrant = (data,val) => {
        this.setState({
            disabledbutton: true
        })
        let url = {
            "id": data.id,
            "postponeMonth": val.APIdeadline
        }

        postponegrant(url).then(res => {
            if (res !== undefined) {
                message.success("延期授权成功！")
                this.setState({
                    disabledbutton: false
                })
                this.props.submitmodel();
                this.hidemodel()
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data);
            } else {
                console.log("fupostponegrant -- postponegrant --接口异常")
                message.error('接口异常');
            }
        });
    }

    submitmodel = (data) => {

        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.fupostponegrant(data,fieldsValue)
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };

    render() {
        const that = this;
        const { showmodel } = this.props;
        const { getFieldDecorator } = that.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: '延期授权',
            centered: true,
            width: 354,
            maskClosable: false,
            visible: showmodel.visible,
            onOk: () => { that.hidemodel() },
            onCancel: () => { that.hidemodel() },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel()}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodel)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    disabled={that.state.disabledbutton}
                    text="确定"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                {/* <div>取消授权后<span>dfsda</span>将不再拥有访问API的权限，且客户正在使用的API密钥信息将不可恢复。请在输入框中输入完整的客户名称以确认。</div> */}
                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>



                    <Form.Item label="将API授权延长">
                        {getFieldDecorator('APIdeadline', {
                            initialValue: 1,
                            rules: [

                                {
                                    required: true,
                                    message: 'API授权期限不能为空',
                                }
                            ],
                        })(
                            <Select
                                showSearch
                                placeholder="请选择API授权期限"
                                optionFilterProp="children"
                                suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
                                style={{ width: '100%', marginRight: '64px' }}

                            >
                                {/* <Select.Option value={2} key={2}>
                                    全部
                                </Select.Option> */}
                                <Select.Option value={1} key={1}>
                                    1个月
                                </Select.Option>
                                <Select.Option value={2} key={2}>
                                    2个月
                                </Select.Option>
                                
                                <Select.Option value={12} key={12}>
                                    1年
                                </Select.Option>
                                <Select.Option value={24} key={24}>
                                    2年
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>

                </Form>

            </Modal>
        );
    }
}
const AddFilter = Form.create({ name: 'register' })(PostponeAuthorization);

export default AddFilter;
