import $axios from '../axios/$axios';

export function plantList(data){
   return $axios({
    url: '/api/v2/plant'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function addplant(data){
    return $axios({
     url: '/api/v2/plant',
     method: 'post',
     type: 'json',
     data: data
    })
 }

 export function deleteplant(data){
    return $axios({
     url: '/api/v2/plant'+data,
     method: 'delete',
     type: 'json',
     data: ''
    })
 }

 export function changeplant(data){
    return $axios({
     url: '/api/v2/plant',
     method: 'put',
     type: 'json',
     data: data
    })
 }

 export function plantmap(data){
   return $axios({
    url: '/api/v4/chart/map',
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function orgplant(data){
   return $axios({
    url: '/api/v1/plant'+ data,
    method: 'get',
    type: 'json',
    data: ''
   })
}