import React from "react";
import '@/assets/css/login';
import { Form } from 'antd';
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { getAuthProvider,setAuthProvider } from "@/lib/AuthProvider";
import { signinSignupConfig, signinSignupParameter, signinSignupOption } from "@/lib/PhonePwdConfig"
import { PhonePwdLessConfig,
	PhonePwdLessParameter,
	PhonePwdLessOption } from "@/lib/PhonePwdLessConfig"
import { MyABBLoginConfig } from "@/lib/MYABBConfig"
// import {WechatLoginParameter} from "@/lib/WechatLoginConfig"
import { WechatLoginConfig,
	WechatLoginParameter,
	WechatLoginOption } from "@/lib/WechatLoginConfig"
import BottomFooter from './layout/BottomFooter';
import loginpageIcon from "../assets/img/loginpage_icon.png"
import GetJson from "@/utils/link.js"

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {

		}
	};

	nativeLogin = () => {
		let authProvider1 = new MsalAuthProvider(
			signinSignupConfig, signinSignupParameter, signinSignupOption
		);
		setAuthProvider(authProvider1)

		localStorage.setItem('loginWith', 'native');
		// setTimeout(() => {
			authProvider1.clearCache();
			//authProvider.logout();
			authProvider1.setAuthenticationParameters(signinSignupParameter);

			// invoke a login method, it will actully jump to password reset page
			authProvider1.login();
		// }, 1500)

	}
	
	wechatLogin = () => {
		let authProvider2 = new MsalAuthProvider(
			WechatLoginConfig,
			WechatLoginParameter,
			WechatLoginOption
		);
		setAuthProvider(authProvider2)
		localStorage.setItem('loginWith', 'wechat');
		// change the authority url to wechat login
		// setTimeout(() => {
			authProvider2.clearCache();
			//authProvider.logout();
			authProvider2.setAuthenticationParameters(WechatLoginParameter);

			// invoke a login method, it will actully jump to password reset page
			authProvider2.login();
		// }, 1500)

	}
	PhonePwdLess = () => {
		let authProvider3 = new MsalAuthProvider(
			PhonePwdLessConfig,
                PhonePwdLessParameter,
                PhonePwdLessOption
		);
		setAuthProvider(authProvider3)
		localStorage.setItem('loginWith', 'PhonePwdLess');
		// setTimeout(() => {
			authProvider3.clearCache();
			//authProvider.logout();
			authProvider3.setAuthenticationParameters(PhonePwdLessConfig);

			// invoke a login method, it will actully jump to password reset page
			authProvider3.login();
		// }, 1500)

	}
	MYABBConfig = () => {
		localStorage.setItem('loginWith', 'MYABBConfig');
		let authProvider4 = getAuthProvider();

		// setTimeout(() => {
			authProvider4.clearCache();
			//authProvider.logout();
			authProvider4.setAuthenticationParameters(MyABBLoginConfig);
			console.log("authProvider-----------", authProvider4)
			// invoke a login method, it will actully jump to password reset page
			authProvider4.login();
		// }, 1500)

	}
	render() {
		// if (this.props.token != null) {
		// 	// already got token, jump to dashboard
		// 	this.props.history.push('/dashboard');
		// }
		let _self = this;
		return (
			<div className="container">

				<div className="content1">
					<div className="title1">ABB Ability™ 数字化传动链状态监测系统</div>
					<div className="content_text">
						<div>每一天我们都致力于驱动世界，</div>
						<div>今天，我们让驱动变得更便捷与智能。</div>
						<div>ABB运动控制，您的数字化合作伙伴。</div>
					</div>
					<div className="content_icon">
						{/* <ABB.Icon style={{ 'color': '#1f1f1f' }} name="abb/motor" sizeClass='large' /> */}
						<img src={loginpageIcon}></img>
					</div>
					<Form className="login-form">
						<Form.Item>
							{/* <div className="button1" onClick={() => {_self.nativeLogin()}}>
								账号登陆
							</div> */}
							<div className="button1" onClick={() => { _self.nativeLogin() }}>
								手机密码登录
							</div>
							<div className="button2" onClick={() => { _self.PhonePwdLess() }}>
								短信验证码登录
							</div>
							
							<a className="guide" href={GetJson.usefull_links.application_start_guid} target="_blank">快速开始指南</a>

							<div className="otherLogin-box">
								<div>其他登录方式</div>
								<div className="otherLogin_button_box">
									<div className="otherLogin_button" onClick={() => { _self.MYABBConfig() }}></div>
									<div className="otherLogin_button otherLogin_button_weixin" onClick={() => { _self.wechatLogin() }}></div>
								</div>
							</div>
							<div className="scancode">
								<div>
									扫码下载 Motion 365
								</div>

								<img style={{ height: 90, width: 90 }} src="https://fe-static.obs.cn-north-4.myhuaweicloud.com/qrcode.png"></img></div>
							<div className="broadcast">
								<img style={{ height: 20, width: 20 }} alt="" src="https://fe-static.obs.myhuaweicloud.com/security.png"></img>
								<div>
									<a className="advertising" href={GetJson.usefull_links["abb_parts-anti_fake"]} target="_blank">ABB 备件防伪</a>
								</div>
								<div>
									<a className="advertising" href={GetJson.usefull_links.abb_video_guid} target="_blank">视频指导</a>

								</div>
							</div>

						</Form.Item>
					</Form>

				</div>
				<div className="bj_opacity"></div>
				<BottomFooter />
			</div>

		);
	};
}

export default Login;
