import React, { Component } from 'react';
import { Table, Icon, Modal, Button, Input, Select, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { setSubscription, setSubid, setSubselect } from '@/redux/actions/subscription'
import { withRouter } from 'react-router-dom';
import { subscriptionlist, Devicesubscriptionlist } from '@/utils/devicesData'
import { APIMoveSubscription } from '@/api/subscription'
import { MovesubForm } from './components/moveSubscriptionModel'
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import BoundsubConfigForm from './components/boundsubConfig'
const { Search } = Input;
const { Option } = Select;
const icon_center = {
	display: 'flex'
}

const Tooltip_moveSubscription = "迁移订阅"

const div_nowrap = {
	margin: '0 10px',
}
class EUsubscription extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tabledata: [],
			pagination: {},
			loading: false,
			selectedRowKeys: [],
			selectval: '0',
			showmodelmovesub: {},
			subscriptionDetail: {},
			subscriptionId: null,
			subscriptionListType: 0,
			showmodelboundsubConfig: {
				visible: false,
				deviceData: {
					assetName: '',
					assetSrNo: '',
					isUsingSubscriptions: {}
				}
			},
			columns: [
				{
					title: '编号',
					dataIndex: 'index',
					key: 0,
					width: 50,
				},
				{
					title: '设备名称',
					dataIndex: 'deviceName',
					key: 1,
					width: 150,
				},
				{
					title: '设备序列号',
					dataIndex: 'deviceSrNo',
					key: 2,
					width: 100,
				},
				{
					title: '型号',
					dataIndex: 'deviceDriveType',
					key: 3,
					width: 90
				},
				{
					title: '设备类型',
					dataIndex: 'assetType',
					key: 4,
					width: 90,
				},
				{
					title: '订阅等级',
					dataIndex: 'subscriptionLevel',
					key: 5,
					width: 90,
				},
				{
					title: '订阅类型',
					dataIndex: 'subscriptionType',
					key: 6,
					width: 90,
				},
				{
					title: '订阅有效期',
					dataIndex: 'validPeriodTime',
					key: 7,
					width: 90,
				},
				{
					title: '订阅状态',
					dataIndex: 'subscriptionStatus',
					key: 8,
					width: 90,
				},
				{
					title: '订阅开始时间',
					dataIndex: 'activatedDateTime',
					key: 9,
					width: 170,
				},
				{
					title: '订阅结束时间',
					dataIndex: 'expiredDateTime',
					key: 10,
					width: 170,
				},
				{
					title: '操作',
					dataIndex: '',
					key: 11,
					width: 100,
					render: (text, record) => (
						<div style={icon_center}>

							{
								(this.props.userInfo.organizationType === "ABB" && record.subscriptionStatus !== "已过期" && record.assetType !== "电机") ? (
									<div style={div_nowrap}>
										<Tooltip placement="bottom" title={Tooltip_moveSubscription} >
											<div style={{marginTop:'2px'}} onClick={() => this.moveSubscription(record)}>
												<ABB.Icon name="abb/cut" sizeClass='small' />
											</div>
										</Tooltip>
									</div>
								) : ''
							}
							{
								record.isImmediateActivation && (
									<div style={div_nowrap}>
										<Tooltip placement="bottom" title='激活订阅' >
											<div className='abind_icon'  onClick={() => this.activateSubscription(record)}></div>
										</Tooltip>
									</div>
								)
							}


						</div>
					),
				}
			]
		};
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	FnDevicesubscriptionlist = (id, type) => {
		// let that = this;
		// Devicesubscriptionlist(this.props.gettreeValue,id,type,function(data){
		// 	that.props.setSubscription(data)
		// })

		let that = this;

		let { getRadiovalue, gettreeValue } = this.props;
		if (getRadiovalue === 0) {
			Devicesubscriptionlist('', id, type, getRadiovalue, function (data) {

				that.props.setSubscription(data)
			})
		}
		else {
			Devicesubscriptionlist(gettreeValue, id, type, getRadiovalue, function (data) {

				that.props.setSubscription(data)
			})
		}
	};
	fetch = (id, type) => {

		let that = this;
		let { getRadiovalue, gettreeValue } = this.props;
		if (getRadiovalue === 0) {
			subscriptionlist('', id, type, getRadiovalue, function (data) {
				that.setState({
					tabledata: data
				})
				that.props.setSubscription(data)
			})
		}
		else {
			subscriptionlist(gettreeValue, id, type, getRadiovalue, function (data) {
				that.setState({
					tabledata: data
				})
				that.props.setSubscription(data)
			})
		}
	};
	onChange = (e) => {
		this.setState({
			subscriptionListType: e
		})
		const { getSubid } = this.props;
		this.props.setSubselect(e)
		if (getSubid.type === 100) {
			this.FnDevicesubscriptionlist('/' + getSubid.id, e)
		}
		else if (getSubid.type === 1) {

		}
		else {
			this.fetch('/' + getSubid.id, e)
		}

	}
	moveSubscription = (record) => {
		// record.id: 45804
		this.setState({

			showmodelmovesub: {
				visible: true,
				title: "订阅迁移",
				value: '',
				width: 504,
				centered: true,
				subscriptionId: record.id
			},
			subscriptionDetail: {
				"activatedDateTime": record.activatedDateTime,
				"deviceType": "传动",
				"subscriptionType": record.subscriptionType,
				"subscriptionLevel": record.subscriptionLevel,
				"expiredDateTime": record.expiredDateTime,
				"orginDeviceSN": record.deviceSrNo

			},
			subscriptionId: record.id,
		})
	}
	activateSubscription = (record) => {
		console.log("record",record)
		let isUsingSubscriptions = {}
		record.group.subscriptions.map((item)=>{
			if(item.isUsing === true && item.deviceId === record.deviceId){
				 
				isUsingSubscriptions = item
			}
		})
		console.log("isUsingSubscriptions",isUsingSubscriptions)
		this.setState({
			showmodelboundsubConfig: {
				visible: true,
				deviceData: {...record,isUsingSubscriptions:isUsingSubscriptions}
			},
		})
	}
	operateSubscription = (data) => {
		// {"targetDeviceSN": xx, "subscriptionId": xxx}
		APIMoveSubscription(data)
			.then(res => {
				message.success('订阅迁移成功');
				this.setState({
					showmodelmovesub: {
						visible: false,
						title: '',
						value: '',
						width: 504,
						centered: true,
					},
					subscriptionDetail: {
						"activatedDateTime": "",
						"deviceType": "",
						"subscriptionType": "",
						"subscriptionLevel": "",
						"expiredDateTime": "",
						"orginDeviceSN": ""

					},
					subscriptionId: null
				});
				this.onChange(this.state.subscriptionListType);
				// TODO 增加刷新页面
				// const { getSubid } = this.props;
				// this.FnDevicesubscriptionlist('/'+ getSubid.id, this.state.subscriptionListType);

			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data);
				} else if (err.status === 403) {
					message.error('没有权限');
				}
				else {
					message.error('订阅迁移接口异常');
				}
			});
	}
	//隐藏弹出框
	hidemodel = (data) => {
		this.setState({

			showmodelmovesub: {
				visible: false,
				title: '',
				value: '',
				width: 354,
				centered: true,
			},
			subscriptionDetail: {
				"activatedDateTime": "",
				"deviceType": "",
				"subscriptionType": "",
				"subscriptionLevel": "",
				"expiredDateTime": "",
				"orginDeviceSN": ""

			},
			subscriptionId: null,
			showmodelboundsubConfig: {
				visible: false,
				deviceData: {
					isUsingSubscriptions: {}
				},

			},

		})
	}
	//提交数据
	submitmodel = (targetSN) => {
		// const data = {
		// 	"targetDeviceSN": targetSN, 
		// 	"subscriptionId": this.state.subscriptionId
		// }
		// this.operateSubscription(data);
		this.setState({
			showmodelmovesub: {
				visible: false,
				title: '',
				value: '',
				width: 504,
				centered: true,
			},
			subscriptionDetail: {
				"activatedDateTime": "",
				"deviceType": "",
				"subscriptionType": "",
				"subscriptionLevel": "",
				"expiredDateTime": "",
				"orginDeviceSN": ""

			},
			subscriptionId: null,
			showmodelboundsubConfig: {
				visible: false,
				deviceData: {
					isUsingSubscriptions: {}
				}
			},
		});
		this.onChange(this.state.subscriptionListType);
	}
	render() {
		let mytablecolumns = this.state.columns;
		let { getSubselect } = this.props;
		let _self = this;

		return (
			<>
				<div className="ABB_tab_marginTop">
					<Select
						suffixIcon={
							<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
						}
						defaultValue="全部" value={getSubselect} style={{ width: 188 }} onChange={_self.onChange}>
						<Option value="0">全部</Option>
						<Option value="1">已激活</Option>
						<Option value="2">已过期</Option>
					</Select>
					{
						_self.props.getSubscription.length > 0 ? (
							_self.props.getSubscription.map((item, index) => {
								
								return (
									<>
										<div className='EUorg_title'>
											<span>{item.assetGroupName}</span>
										</div>
										<Table
											size='small'
											// scroll={{ x: 740}}
											className='org_right'
											ellipsis={true}
											columns={mytablecolumns}
											dataSource={item.subscriptions}
											pagination={{
												pageSize: 10
											}}
											rowKey={(record, item) => record.id}
										/>
									</>
								)
							})
						) : <div className="renderEmpty" >
							<div className="renderEmpty_center">
								<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
								<p>暂无数据</p>
							</div>
						</div>

					}

				</div>
				{/* 绑定订阅 */}
				<MovesubForm
					showmodelmovesub={_self.state.showmodelmovesub}
					subscriptionDetail={_self.state.subscriptionDetail}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel} />

				<BoundsubConfigForm
					showmodelboundsubConfig={_self.state.showmodelboundsubConfig}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel}
				// showModelSubConfig={()=>_self.showModelSubConfig()}
				/>

			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setSubscription: data => {
		dispatch(setSubscription(data));
	},
	setSubid: data => {
		dispatch(setSubid(data));
	},
	setSubselect: data => {
		dispatch(setSubselect(data));
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EUsubscription));

// export default EUsubscription;

