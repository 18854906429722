
import React, { Component } from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo'
import { setOrginformation } from '@/redux/actions/userlist'
import BackupParameters from './BackupParameters';
import BackupChange from './BackupChange';
import { userCheckDevice, deviceDetail, checkAllgen, eventDetaildevice } from '@/redux/actions/device';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
class BackUp extends Component {
    constructor(props) {
        super(props);
        this.state = {

            activeTab: 0,
            feature: false
        }
    }

    componentDidMount() {
        let { getsubscriptioncof } = this.props;
        let feature = false
        getsubscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 14) {
                    feature = items.limitValue
                }
            })

        })
        this.setState({
            feature: feature
        })
    }
    callback(newIndex) {

        this.setState({ activeTab: newIndex })

    }
    backuper = () => {
        let { getassettype,getRouterLinkIndex } = this.props;
        if (getassettype === "PowerTrain") {
            if(getRouterLinkIndex === 2){
                this.props.userCheckDevice(11)
            }else{
                this.props.userCheckDevice(3)
            }
            
        }
        else if (getassettype === "noPowerTrain") {
            this.props.userCheckDevice(11)
        }
        else {
            this.props.userCheckDevice(0);
        }
        this.props.eventDetaildevice('0')
        this.props.checkAllgen('0')
    }
    istab = (data) => {
        if(data === false){
            return (
                <ABB.TabControl
                    type="primary"
                    activeTab={this.state.activeTab}
                    className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
                    onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
                    padLeft={false}
                >
                   
                    <ABB.TabItem title="参数备份">
                        <BackupParameters/>
                    </ABB.TabItem>
                </ABB.TabControl>
            )
        }
        else{
            return (
                <ABB.TabControl
                    type="primary"
                    activeTab={this.state.activeTab}
                    className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
                    onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
                    padLeft={false}
                >
                    <ABB.TabItem title="参数变化">
                        <BackupChange/>
                    </ABB.TabItem>
                    <ABB.TabItem title="参数备份">
                        <BackupParameters/>
                    </ABB.TabItem>
                </ABB.TabControl>
            )
        }
    }
    render() {
        return (
            <div style={{ minHeight: "86vh", marginRight: 12 }}>
                <div className="EUorg_title_back" style={{ background: '#fff', padding: 8 }} >
                    <div className='backup_button' onClick={() => this.backuper()}>
                    <ABB.Icon name="abb/left" sizeClass='medium' />传动参数</div>
                    </div>
                    
                {/* <ABB.TabControl
                    type="primary"
                    activeTab={this.state.activeTab}
                    className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
                    onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
                    padLeft={false}
                >
                    <ABB.TabItem title="参数变化">
                        <BackupChange/>
                    </ABB.TabItem>
                    <ABB.TabItem title="参数备份">
                        <BackupParameters/>
                    </ABB.TabItem>
                </ABB.TabControl> */}
                {
                    this.istab(this.state.feature)
                }
            </div>


        );
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BackUp));
