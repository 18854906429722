import React, { Component } from 'react';
import {
    Form,
    Modal,
    Input,
    message,
    Col,
    Row,
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class MigrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            dataSource: []
        };
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }

    hidemodel = () => {
        this.props.hidemodel()
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log("----------", fieldsValue)
                this.props.showmodelMigrationFn(fieldsValue)
                this.props.form.resetFields();
            }
        });
    }
    validateNoChinese = (rule, value, callback) => {
        let reg = /[^\w\/]/ig;
        let regEmpty = /^\s*$/g;
        if (value && reg.test(value)) {
            callback('只能输入字母和数字');
        } else if (value && regEmpty.test(value)) {
            callback('组织代码不能为空');
        } else {
            callback();
        }
    }
    render() {
        const that = this;
        const { showmodelMigration } = this.props;
        const { getFieldDecorator } = that.props.form;
        const modelProps = {
            title: "迁移组织",
            centered: true,
            width: 354,
            visible: showmodelMigration.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>

                <Form >
                    <Form.Item label="组织代码">
                        {getFieldDecorator('code', {
                            // initialValue: showassetGroupmodel.value,
                            rules: [

                                {
                                    required: true,
                                    message: '组织代码不能为空',
                                },
                                {
                                    max: 6,
                                    message: '长度不能大于6',
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                },
                                { validator: this.validateNoChinese }
                            ],
                        })(
                            <Input placeholder="请输入目标迁移组织的组织代码" className="model_input" />,
                        )}
                    </Form.Item>

                </Form>

            </Modal>
        );
    }
}
const Migration = Form.create({ name: 'register' })(MigrationForm);
export default Migration;
