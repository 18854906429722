import React, { Component } from 'react';
import { Modal, Table, Input, message, Form, Col, Row, Select, InputNumber } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import RemoweModel from '@/components/remove/removeModel'
import { customAlarms, addCustomAlarm, deleteCustomAlarm } from '@/api/alarms.js'
const { Search } = Input;
const { Option } = Select;

class CustomAlarmForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alarms: [],
            columns: [
                {
                    title: '信号名称',
                    dataIndex: 'name',
                    key: 1,
                    width: '30%',
                },
                {
                    title: '告警条件',
                    dataIndex: 'name',
                    key: 2,
                    width: '70%',
                },
            ],
            dataSource: [
                // {
                //     id: 1,
                //     name: '111'
                // },
                // {
                //     id: 2,
                //     name: '222'
                // }
            ],
            showmodelRemove: {},
            trends: [],
            disabled: false,
            isZn_CH: false,
            deviceId: '',
            isSubAsset: false,
            itemData: {},
            removeDisabled: false
        };
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    deleteAlarm = (data) => {
        this.setState({
            itemData: data,
            showmodelRemove: {
                visible: true,
                title: '刪除告警规则',
                value: '删除规则后将不会继续提示满足当前告警规则的消息，确定删除当前告警规则吗？',
                width: 504,
                centered: true,
            }
        })
    }
    _modalConfirmEvent = () => {
        this.setState({
            removeDisabled: true
        })
        let url = `${this.state.deviceId}/limit/${this.state.itemData.id}?isSubAsset=${this.state.isSubAsset}`
        deleteCustomAlarm(url).then((res) => {
            if (res.data.code === 200) {
                message.success("删除成功")
                this._getAlarms(this.state.deviceId, this.state.isSubAsset);
            } else {
                message.warn("删除失败")
            }

        }).catch(err => {

        });
    }
    _getAlarms = (id, isSubAsset) => {
        let url = `${id}?isSubAsset=${isSubAsset}`
        customAlarms(url).then((res) => {
            // console.log("customAlarms===============", res.data)
            var responseValue = res.data;
            if (responseValue.code === 200) {
                this.setState({
                    alarms: responseValue.data,
                    deviceId: id,
                    isSubAsset: isSubAsset,
                    disabled: false,
                    removeDisabled: false,
                    showmodelRemove: {
                        visible: false,
                        title: '',
                        value: '',
                        width: 504,
                        centered: true,
                    }
                })
            } else {

            }

        }).catch(err => {
            // console.log("customAlarms==========err=====", err)
        });

    }
    _addCustomSignal = (data, trends) => {
        // console.log("确认删除按钮点击=================")
        var that = this;
        let label = trends.filter(item => {
            if (item.code === data.param) {
                return item
            }
        })
        that.setState({
            disabled: true
        })
        // console.log(trends, "label--------", label[0].label)
        var param = [{
            "signalCode": data.param,//this.currentSignal.id,  //  信号名称
            "signalName": label[0].label,
            "limitName": data.name,  //告警规则名称
            "value": data.limit,   //限值
            "limitType": data.type   //比较类型  
        }]
        let url = `${this.state.deviceId}?isSubAsset=${this.state.isSubAsset}`
        addCustomAlarm(param, url).then((res) => {
            if (res.data.code === 200) {
                that._getAlarms(this.state.deviceId, this.state.isSubAsset);
                message.success("添加成功")
                this.props.form.resetFields();
            } else {
                message.warning('添加告警规则失败')
            }

        }).catch(err => {
 
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("_addCustomSignal1 -- addCustomAlarm --接口异常")
                message.error('接口异常')
            }
        });
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                // console.log("12222222222", fieldsValue)
                this._addCustomSignal(fieldsValue, data.trends)
            }
            else {
                console.log("err", data)
                if (data.trends.length <= 0) {
                    message.warning("信号时序参数为空，无法设置自定义告警规则")
                }
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
            }
        });
    };
    validateNoChinese = (rule, value, callback) => {
        let reg = /[^\-?\d.]/g;
        let regEmpty = /^\s*$/g;
        if (value && reg.test(value)) {
            callback('只能输入数值');
        } else if (value && regEmpty.test(value)) {
            callback('不能为空');
        } else {
            callback();
        }
    }
    render() {
        const that = this;
        const { showModel } = this.props;
        const { getFieldDecorator } = that.props.form;
        let { columns, dataSource, alarms, removeDisabled,disabled } = that.state;

        const modelProps = {
            title: '自定义告警',
            maskClosable: false,
            centered: true,
            width: 756,
            visible: showModel.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                // <ABB.Button
                //     onClick={() => that.hidemodel(false)}
                //     type="discreet-black"
                //     sizeClass='small' theme="filled"
                //     text="取消"
                // />,
                // <ABB.Button
                //     disabled={disabled}
                //     onClick={() => that.hidemodel(false)}
                //     type="primary-blue"
                //     sizeClass='small' theme="filled"
                //     text="应用"
                // />,
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>

                <div>
                    <div className='costom_title'>默认告警规则</div>
                    <Table
                        size='small'
                        className='org_right'
                        columns={columns}
                        dataSource={dataSource}
                        pagination={false}
                        rowKey={(record, item) => record.id}
                    />
                </div>
                <div style={{ marginTop: '8px' }}>
                    <div className='costom_title'>添加告警规则</div>
                    <div className='costom_box'>
                        <Row className='costom_body'>
                            <Col span={5}>
                                信号名称
                            </Col>
                            <Col span={5}>
                                名称
                            </Col>
                            <Col span={5}>
                                比较类型
                            </Col>
                            <Col span={5}>
                                限值
                            </Col>
                            <Col span={4}>

                            </Col>
                        </Row>
                        <Row className='costom_list'>
                            <Col span={5}>
                                <Form.Item >
                                    {getFieldDecorator('param', {
                                        rules: [
                                            { required: true, message: '请选择参数' },
                                        ],
                                    })(

                                        <Select placeholder="选择参数"
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }
                                            // onChange={that.eventOnChange}
                                            style={{ width: 116 }}>
                                            {
                                                showModel.trends.map((item, index) =>
                                                    <Option key={item.code} value={item.code}>{item.label}</Option>
                                                )
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>
                            <Col span={5}>
                                <Form.Item >
                                    {getFieldDecorator('name', {
                                        rules: [
                                            { required: true, message: '请输入名称' },
                                            {
                                                max: 20,
                                                message: '名称长度不能大于20',
                                            },
                                            {
                                                whitespace: true,
                                                message: '禁止输入空格',
                                            }
                                        ],
                                    })(
                                        <Input style={{ width: 116 }} placeholder="名称" className="model_input" />,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item >
                                    {getFieldDecorator('type', {
                                        rules: [
                                            { required: true, message: '请选择比较类型' },
                                        ],
                                    })(
                                        <Select placeholder="比较类型"
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }
                                            // onChange={that.eventOnChange}

                                            style={{ width: 116 }}>
                                            <Option key="1" value={1}>大于</Option>
                                            <Option key="2" value={2}>小于</Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item >
                                    {getFieldDecorator('limit', {
                                        rules: [
                                            { required: true, message: '请输入限值' },
                                            {
                                                max: 20,
                                                message: '长度不能大于20',
                                            },
                                            {
                                                whitespace: true,
                                                message: '禁止输入空格',
                                            },
                                            {validator: this.validateNoChinese}
                                        ],
                                    })(
                                        <Input style={{ width: 116, borderRadius: '0' }} placeholder="限值" className="model_input" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                {
                                    showModel.trends.length > 0 ? (
                                        <ABB.Button
                                            style={{
                                                marginTop: '4px',
                                                background: '#fff',
                                                border: '1px solid #36f',
                                                color: '#36f'
                                            }}
                                            disabled={disabled}
                                            onClick={() => that.submitmodel(showModel)}
                                            sizeClass='small' theme="filled"
                                            text="添加"
                                        />
                                    ) : (
                                        <ABB.Button
                                            style={{
                                                marginTop: '4px',
                                                background: '#999',
                                                border: '1px solid #999',
                                                color: '#fff'
                                            }}
                                            onClick={() => that.submitmodel(showModel)}
                                            sizeClass='small' theme="filled"
                                            text="添加"
                                        />
                                    )
                                }

                            </Col>
                        </Row>
                        {
                            alarms.length > 0 ? (
                                <div className='alarms-box'>
                                    {
                                        alarms.map((item, index) => {
                                            return (
                                                <Row className='costom_list' key={index}>
                                                    <Col span={5}>
                                                        {item.signalName}
                                                    </Col>
                                                    <Col span={5}>
                                                        {item.limitName}
                                                    </Col>
                                                    <Col span={5}>
                                                        {
                                                            item.limitType === 1 ? '大于' : '小于'
                                                        }
                                                    </Col>
                                                    <Col span={8}>
                                                        {item.value}
                                                    </Col>
                                                    <Col span={1}>
                                                        <div onClick={() => this.deleteAlarm(item)}>
                                                            <ABB.Icon name="abb/trash" sizeClass='small' />
                                                        </div>

                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </div>

                            ) : ''
                        }

                    </div>
                </div>
                <RemoweModel
                    showmodelRemove={that.state.showmodelRemove}
                    hidemodel={() => {
                        this.setState({
                            showmodelRemove: {
                                visible: false,
                                title: '',
                                value: '',
                                width: 504,
                                centered: true,
                            }
                        })
                    }}
                    disabled={removeDisabled}
                    submitmodel={that._modalConfirmEvent}
                />
            </Modal>
        );
    }
}
const CustomAlarm = Form.create({ name: 'register' })(CustomAlarmForm);

export default CustomAlarm;
