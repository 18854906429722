import React from 'react';
import { Form, Input, Select, Modal, message, DatePicker } from 'antd';
import { ABBcreatesubscription, assettype, sublevel } from '@/api/subscription';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Option } = Select;

class RegistrationForm extends React.Component {
	state = {
		confirmDirty: false,
		createOrgdata: false,
		autoCompleteResult: [],
		endTime: '',
		level: [],
		guild: [],
		disabled: false,
		viability: [
			{
				id: 3,
				name: '3个月'
			},
			{
				id: 6,
				name: '6个月'
			},
			{
				id: 12,
				name: '1年'
			},
			{
				id: 24,
				name: '2年'
			},
			{
				id: 36,
				name: '3年'
			}
		]
	};
	UNSAFE_componentWillMount() {
		this.sublevel();
		this.assettype();
		
	}

	//获取订阅等级
	assettype = () => {
		let url = '';
		assettype(url).then(res => {
			if (res !== undefined) {
				this.setState({
					guild: res.data
				});
			}
		});
	};
	//获取适用设备
	sublevel = () => {
		let url = '';
		sublevel(url).then(res => {
			if (res !== undefined) {
				this.setState({
					level: res.data
				});
			}
		});
	};
	handleSubmit = e => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				console.log('Received values of form: ', values);
			}
		});
	};

	handleConfirmBlur = e => {
		const { value } = e.target;
		this.setState({ confirmDirty: this.state.confirmDirty || !!value });
	};

	compareToFirstPassword = (rule, value, callback) => {
		const { form } = this.props;
		if (value && value !== form.getFieldValue('password')) {
			callback('Two passwords that you enter is inconsistent!');
		} else {
			callback();
		}
	};

	validateToNextPassword = (rule, value, callback) => {
		const { form } = this.props;
		if (value && this.state.confirmDirty) {
			form.validateFields(['confirm'], { force: true });
		}
		callback();
	};

	handleWebsiteChange = value => {
		let autoCompleteResult;
		if (!value) {
			autoCompleteResult = [];
		} else {
			autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
		}
		this.setState({ autoCompleteResult });
	};
	FncreateOrg(createOrgdata) {
		this.setState({ createOrgdata: createOrgdata });
		this.props.form.resetFields();
	}
	//提交订阅
	rescreateOrg = () => {
		let that = this;
		that.setState({
			disabled: true
		})
		this.props.form.validateFields((err, fieldsValue) => {
			if (!err) {
				let subtime = moment(that.state.endTime).utc().format('YYYY-MM-DD HH:mm:ss');
				let url = {
					deviceTypeId: fieldsValue.industry,
					subscriptionLevelId: fieldsValue.name,
					validPeriodTime: fieldsValue.admin,
					count: fieldsValue.Number,
					validDateTime: subtime,
					subscriptionType: fieldsValue.subtype,
					comment: fieldsValue.comment
				};
				ABBcreatesubscription(url)
					.then(res => {
						if (res !== undefined) {
							message.success('生成订阅成功');

							that.setState({
								createOrgdata: false,
								disabled: false
							});
							that.props.initdata();
							that.props.form.resetFields();
						}
					})
					.catch(err => {
						that.setState({
							disabled: false
						});
						if (err.status === 400 || err.status === 404) {
							message.warning(err.data);
							
						} else {
							message.error('订阅生成失败');
						}
					});
			}
			else{
				that.setState({
					disabled: false
				})
			}
		});
	};

	onChangeTime = e => {
		if (e === null) {
		} else {
			let datetime = moment(e._d).format('YYYY-MM-DD');
			let _datetime = datetime + ' 23:59:59';
			this.setState({
				endTime: _datetime
			});
		}
	};

	disabledDate = current => {
		// Can not select days before today and today
		return current && current <= moment().endOf('day');
	};
	validator = (rule, value, callback) => {
		const { getFieldValue } = this.props.form;
		if (getFieldValue('Number') > 800) {
			callback('生成份数不能大于800份！');
		}

		// Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
		callback();
	};
	render() {
		let that = this;
		const { getFieldDecorator } = that.props.form;
		const { disabled } = that.state;

		const formItemLayout = {
			labelCol: {
				xs: { span: 24 },
				sm: { span: 24 }
			},
			wrapperCol: {
				xs: { span: 24 },
				sm: { span: 24 }
			}
		};

		return (
			<>
				<ABB.Button onClick={() => that.FncreateOrg(true)} type="primary-blue" sizeClass="small" theme="filled" text="生成订阅" icon="abb/plus-in-circle" />
				<Modal
					closeIcon={<ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass="medium" />}
					title="生成订阅"
					centered
					width={504}
					maskClosable= {false}
					visible={that.state.createOrgdata}
					onOk={() => that.FncreateOrg(false)}
					onCancel={() => that.FncreateOrg(false)}
					footer={[
					<ABB.Button 
					onClick={() => that.FncreateOrg(false)} 
					type="discreet-black" 
					sizeClass="small" 
					theme="filled" 
					text="取消" />, 
					<ABB.Button 
					onClick={() => that.rescreateOrg()} 
					type="primary-blue" 
					sizeClass="small" 
					theme="filled" 
					disabled={disabled}
					text="确定" />]}
				>
					<Form onSubmit={this.handleSubmit}>
						<div className="model_list_sub">
							<Form.Item label="订阅类型">
								{getFieldDecorator('subtype', {
									rules: [
										{
											required: true,
											message: '请选择订阅类型'
										}
									]
								})(
									<Select placeholder="订阅类型" suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />} style={{ width: 188, marginRight: '64px' }}>
										<Option value={2}>正式</Option>
										<Option value={1}>试用</Option>
									</Select>
								)}
							</Form.Item>
							<Form.Item label="订阅等级">
								{getFieldDecorator('name', {
									rules: [
										{
											required: true,
											message: '请选择订阅等级'
										}
									]
								})(
									<Select placeholder="订阅等级" suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />} style={{ width: 188, marginRight: '64px' }}>
										{that.state.level.map((item, index) => (
											<Option value={item.id}>{item.level}</Option>
										))}
									</Select>
								)}
							</Form.Item>
						</div>
						<div className="model_list_sub">
							<Form.Item label="订阅码有效期">
								{getFieldDecorator('company', {
									rules: [{ required: true, message: '请选择订阅码有效期' }]
								})(
									<DatePicker
										style={{ minWidth: 188, marginRight: '64px' }}
										// showTime
										// disabled={true}
										disabledDate={this.disabledDate}
										placeholder="请选择订阅码有效期"
										className="model_input"
										format="YYYY/MM/DD"
										allowClear={false}
										suffixIcon={<ABB.Icon style={{ color: '#000', width: 10 }} name="abb/calendar" sizeClass="small" />}
										onChange={that.onChangeTime}
										// onOk={that.onOk}
									/>
								)}
							</Form.Item>
							<Form.Item label="适用设备">
								{getFieldDecorator('industry', {
									rules: [{ required: true, message: '请选择适用设备' }]
								})(
									<Select placeholder="适用设备" suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />} style={{ width: 188 }}>
										{that.state.guild.map((item, index) => (
											<Option value={item.id}>{item.name}</Option>
										))}
										{/* <Option value='1'>1</Option> */}
									</Select>
								)}
							</Form.Item>
						</div>
						<div className="model_list_sub">
							<Form.Item label="订阅服务期限">
								{getFieldDecorator('admin', {
									rules: [{ required: true, message: '请选择订阅服务期限' }]
								})(
									<Select placeholder="订阅服务期限" suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />} style={{ minWidth: 188 }}>
										{that.state.viability.map((item, index) => (
											<Option value={item.id}>{item.name}</Option>
										))}
									</Select>
								)}
							</Form.Item>
							<Form.Item label="生成份数">
								{getFieldDecorator('Number', {
									rules: [
										{ required: true, message: '请输入生成份数' },
										{
											min: 0,
											pattern: new RegExp(/^[1-9]\d*$/, 'g'),
											message: '生成份数不能小于0'
										},
										{
											validator: this.validator
										}
									]
								})(<Input min={0} max={800} placeholder="请输入生成份数" style={{ width: 188 }} className="model_input" type="number" />)}
							</Form.Item>
						</div>
						<div className="model_list_sub" style={{ marginBottom: -30 }}>
							<Form.Item label="备注">
								{getFieldDecorator('comment', {
									rules: [{ required: false, message: '请输入备注' }]
								})(<Input placeholder="请输入备注" style={{ width: 440 }} className="model_input" type="text" />)}
							</Form.Item>
						</div>
					</Form>
				</Modal>
			</>
		);
	}
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrationForm);

export default WrappedRegistrationForm;
