import axios from 'axios';
import { message } from 'antd';
import { getAuthProvider } from '@/lib/AuthProvider';
import { isvaliduser } from '@/api/user.js';
const authProvider = getAuthProvider();

axios.defaults.headers.post['Content-Type'] = 'application/json'; //配置请求头
axios.defaults.headers.get['Content-Type'] = 'application/json'; //配置请求头
axios.defaults.headers.delete['Content-Type'] = 'application/json'; //配置请求头
axios.defaults.headers.put['Content-Type'] = 'application/json'; //配置请求头

const $axios = axios.create({
	// baseURL: BASEURL,
	baseURL: process.env.REACT_APP_BASE_URL,
	timeout: 30000,
	retry: 4,
	retryDelay: 1000
});

//请求拦截
$axios.interceptors.request.use(
	async function (config) {

		var token = await authProvider.getIdToken();
		let userInfo = localStorage.getItem('userInfo');
		console.log("token",token)
		// 在发送请求之前做些什么
		// 通过reudx的store拿到拿到全局状态树的token ，添加到请求报文，后台会根据该报文返回status
		// 此处应根据具体业务写token
		// const token = localStorage.getItem('token');
		// if(config.url === "/api/v3/excel-analysis/add-a-batch-of-device"){
		// 	config.headers["Content-Type"]= "multipart/form-data";
		// }
		// else{
		// 	config.headers["Content-Type"]= "application/json";
		// }
		config.headers["Content-Type"] = "application/json";
		// config.headers["content-type"]= "multipart/form-data";
		config.headers["pragma"] = "no-cache";
		config.headers['Authorization'] = "Bearer " + token.idToken.rawIdToken;
		if (userInfo === null) {

		}
		else {
			userInfo = JSON.parse(userInfo);
			config.headers['organization'] = userInfo.orgId;
			config.headers['phone'] = userInfo.phone;
		}
		return config;
	},
	function (error) {
		// 对请求错误做些什么
		message.error(error);
		return Promise.reject(error);
	}
);

// 添加响应拦截器
$axios.interceptors.response.use(
	//请求响应成功后
	function (response) {
		// 对响应数据做点什么
		// console.log("response------", response.headers)
		let _OrgStateTimestamp = localStorage.getItem("OrgStateTimestamp");
		if(response.headers.orgstate === "1" && _OrgStateTimestamp !== response.headers.orgstatetimestamp){
			// message.warning("组织发生变化，正在更新组织...")
			
				let hide = message.loading({
					content: "您当前的组织已发生切换，需重新加载数据...",
					top: 200
				});
				localStorage.setItem("OrgStateTimestamp",response.headers.orgstatetimestamp)
				
				setTimeout(() => {
					hide()
					// console.log("您当前的组织已发生切换，需重新加载数据-------------")
					window.location.reload();
				}, 3000)
			

		}
		
		if (response.data.success === false) {
			message.error(response);
		}
		return response;
	},
	//请求失败后
	function (error) {
		console.log("error------", error.response)
	 
		
		if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {

			// message.error('请求超时');
			var config = error.config;
			config.__retryCount = config.__retryCount || 0;
			if (config.__retryCount >= config.retry) {
				// Reject with the error
				// window.location.reload();
				return Promise.reject(error);
			}

			// Increase the retry count
			config.__retryCount += 1;
			var backoff = new Promise(function (resolve) {
				setTimeout(function () {
					resolve();
				}, config.retryDelay || 1);
			});

			return backoff.then(function () {
				return axios(config);
			});
		}

		else if (error.response.status === 401) {
			message.warning('TOKEN失效，重新登录！')
			authProvider.clearCache();
			// geturl();
		}
		else if (error.response.status === 205) {
			
			// return false;
		}
		// else if(error.response.status === 404){
		// 	message.warning(error.response.data)
		// }
		else {
			return Promise.reject(error.response);
		}
	}
);

export default $axios;
