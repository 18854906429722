import React, { Component } from 'react';
import { Input, Checkbox, Select, DatePicker, Table, message, Spin } from 'antd';
import { EUeventreadStatus, EUeventlist, eventcount, EUeventcount, notificationbulk, notificationdevice,MessageType } from '@/api/event.js';
import { geteventDetail, closeevent } from '@/api/device.js';
import { setAssetlabel, userCheckDevice, setAssetIdCopy, setAssetIsPowertrain, setAssetStatus, setTrendsData, setassettype, deviceDetail, deviceTime, setselect, settree, setassetId, setevent, eventDetaildevice, checkAllgen } from '@/redux/actions/device';
import moment from 'moment';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { setNewsmodelIsShow } from '@/redux/actions/setting';
import { setEventcount, setEventDetailStatus } from '@/redux/actions/event';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import IndexInputView from '@/components/page';
import NoData from '@/components/NoData'
import EventModel from './eventModel';
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;

const dateFormat = 'YYYY/MM/DD HH:mm:ss';

class EUevent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			size: 14,
			pageIndex: 1,
			readStatus: null,
			startime: "",
			endtime: "",
			eventdata: {},
			fromtime: moment().subtract(6, 'day'),
			totime: moment(new Date(), dateFormat),
			notificationType: '0',
			eventlist: {
				data: [],
				totalCount: 1
			},
			pagination: {},
			loading: false,
			modal2Visible: false,
			selectedRowKeys: [],
			showmodel: {},
			ids: [],
			selectList: [],
			columns: [
				{
					title: '类型',
					dataIndex: 'readStatus',
					key: '0',
					width: '10%',
					render: (text, record) => {
						if (record.readStatus === true) {
							return (
								<div>
									<span style={{ color: '#42ca1d' }}
									>已读</span>
								</div>
							);
						}
						if (record.readStatus === false) {
							return (
								<div>
									<span style={{ color: '#f71732' }}
									>未读</span>
								</div>
							);
						}
					},
				},
				{
					title: '类别',
					dataIndex: 'typeName',
					key: '1',
					width: '15%',
					ellipsis: true,
					// render: (text, record) => {
					// 	return (
					// 		<span style={{ cursor: 'pointer' }} onClick={() => this.showmodel(true, '消息详情', record)}>
					// 			{record.showtype}
					// 		</span>
					// 	)

					// }
				},
				{
					title: '消息',
					dataIndex: 'message',
					key: '2',
					width: '55%',
					ellipsis: true,
					render: (text, record) => {
						return (
							<span style={{ cursor: 'pointer' }} onClick={() => this.showmodel(true, '消息详情', record)}>
								{record.message}
							</span>
						)

					}
				},
				{
					title: '时间',
					dataIndex: 'timestamp',
					key: '3',
					width: '12%',
					ellipsis: true,
					render: (text, record) => {
						return (
							<span style={{ cursor: 'pointer' }} onClick={() => this.showmodel(true, '消息详情', record)}>
								{moment.parseZone(record.timestamp).local().format('YYYY-MM-DD HH:mm:ss')}
							</span>
						)

					}
				},
				{
					title: '操作',
					dataIndex: 'timestamp',
					key: '4',
					width: '8%',
					ellipsis: true,
					render: (text, record) => {
						if (record.type == 101 || record.type == 100) {
							return (
								<span onClick={() => this.checkDevice(record)} style={{ color: '#36f', fontSize: 11, cursor: 'pointer' }}>设备详情</span>
							);
						}

					}
				}
			]
		};
	}

	readStatus = () => {
		if (this.state.selectedRowKeys.length > 0) {
			let url = {
				"idList": this.state.selectedRowKeys,
				"isAllRead": false
			}
			notificationbulk(url).then(res => {
				message.success("批量已读成功！")
				this.setState({
					selectedRowKeys: []
				})
				this.props.setNewsmodelIsShow({})
				this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
				this.Fneventcount()
			}).catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data)
				}
				else {
					message.error('消息改为已读失败')
				}

			})
		}


	}
	readStatusAll = () => {
		let url = {
			"idList": [],
			"isAllRead": true
		}
		notificationbulk(url).then(res => {
			message.success("全部已读成功！")
			this.setState({
				selectedRowKeys: []
			})
			this.props.setNewsmodelIsShow({})
			this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
			this.Fneventcount();
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('消息改为已读失败')
			}

		})
	}
	componentDidMount() {
		let _startime = moment(this.state.fromtime).format("YYYY-MM-DD") + " 00:00:00"
		let _endtime = moment(new Date()).format("YYYY-MM-DD") + " 23:59:59"
		let currentUTCTimeString = moment(_endtime).utc().format("YYYY-MM-DD HH:mm:ss")
		let dateUTCTimeString = moment(_startime).utc().format("YYYY-MM-DD HH:mm:ss");
		// let selectList = []
	
		this.setState({
			startime: dateUTCTimeString,
			endtime: currentUTCTimeString,
			// selectList: selectList
		}, () => {
			this.getMessageType();
			this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
		})
		// this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
	}
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	ondeviceDetail = () => {
		this.checkDevice(this.state.eventdata)
	}
	getMessageType = () =>{
		
		MessageType('').then(res => {
			let resdata = res.data;
			if(resdata.code === 200){
				this.setState({
					selectList: resdata.data
				})
			}
			
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('获取失败')
			}

		})
	}
	checkDevice(data) {
		let url = `${data.id}`
		notificationdevice(url).then(res => {
			let resdata = res.data;
			this.props.eventDetaildevice('0')
			this.props.checkAllgen('0');
			this.props.setEventDetailStatus({
				startime: '',
				endtime: '',
				page: 1,
				EventType: 0,
				eventName: '',
				forComment: true,
				forEventRecord: false,
				group: false,
				defaultActiveKey: []
			})
			if (resdata.assetType === 1) {
				this.props.setAssetIsPowertrain(true)
				this.props.setassetId('')
				this.props.userCheckDevice(11)
				this.FndeviceDetail(resdata.deviceId, resdata.assetType, resdata.subscriptionStatus)
			}
			else {
				this.props.setAssetIsPowertrain(false)
				this.props.setassetId('')
				this.props.userCheckDevice(11)
				this.FndeviceDetail(resdata.assetId, resdata.assetType, resdata.subscriptionStatus)
			}
			this.props.setAssetlabel(["消息中心", "设备详情"])
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('获取失败')
			}

		})


	}
	//根据id获取详情
	FndeviceDetail(id, devicetype, status) {
		let that = this;
		that.props.setassetId(id);
		that.props.setAssetIdCopy(id);
		if (devicetype === 1) {
			that.props.setassettype('PowerTrain');
			that.props.setAssetIsPowertrain(true)
		}
		else {
			that.props.setassettype('noPowerTrain');
			that.props.setAssetIsPowertrain(false)
		}
		that.props.setAssetStatus(status)
		if (that.props.getassetId !== id) {
			that.props.deviceDetail({})
			that.props.setTrendsData([]);
			that.props.setevent({})
		}
		// that.props.userCheckDevice(1)

	}
	//关闭事件接口
	closeevent = (val) => {
		if (this.state.assetEventid !== '') {

			let url = {
				id: this.state.assetEventid,
				closeReason: val
			}
			closeevent(url).then(res => {
				if (res !== undefined) {
					message.success('操作成功')
					this.setState({
						showmodel: {
							visible: false,
							title: '',
							width: 504,
							value: '',
							centered: true,
							eventstatus: 0,
						},
					})
					this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
				}
			}).catch(err => {
				if (err.status === 400) {
					message.warning(err.data)
				}
				else {
					message.error('关闭事件失败')
				}

			})
		}

	}
	showmodel = (showmodel, title, data) => {
		let eventDateTime = moment.parseZone(data.timestamp).local().format('YYYY-MM-DD HH:mm:ss')
		let eventCloseTime = moment.parseZone(data.timestamp).local().format('YYYY-MM-DD HH:mm:ss')
		let eventstatus = 0;
		if (data.type === 100 || data.type === 101) {
			eventstatus = 1
		}
		this.setState({
			// eventdetail: `${eventDateTime} ${resdata.content}`,
			eventdata: data,
			assetEventid: data.id,
			showmodel: {
				eventstatus: eventstatus,
				visible: showmodel,
				title: title,
				value: `${eventDateTime} ${data.message}`,
				width: 504,
				centered: true,
				closeReason: data.closeReason,
				closeUser: `${data.closeUserOrganization || ''}, ${data.closeUser || ''}, ${eventCloseTime}`,
				assetEventStatus: data.assetEventStatus
			}
		}, () => {
			this.EUeventreadStatus()
		})
	}
	// 组件传值
	hidemodel = (data) => {
		this.setState({
			showmodel: {
				visible: data,
				title: '',
				width: 504,
				value: '',
				centered: true,
				eventstatus: 0,
			},

		})
		this.props.setNewsmodelIsShow({})
		this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
	}
	submitmodel = (val) => {
		if (val !== '') {
			// this.closeevent(val)
			this.props.setNewsmodelIsShow({})
			this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
		}
		else {
			message.destroy()
			message.warning('解决方案不能为空！')
		}
	}
	//更改未读为已读接口
	EUeventreadStatus = () => {
		let url = `/${this.state.assetEventid}`
		EUeventreadStatus(url).then(res => {
			if (res !== undefined) {
				this.Fneventcount()
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				// message.warning(err.data)
			}
			else {
				message.error('消息改为已读失败')
			}

		})
	}
	//获取事件列表
	resEUeventlist = (pageIndex, readStatus) => {
		this.setState({
			loading: true
		})
		this.inputview.pageIndexFn(pageIndex)
		let url = `?pageSize=${this.state.size}&pageIndex=${pageIndex}&notificationType=${this.state.notificationType}&${readStatus}&startTime=${this.state.startime}&endTime=${this.state.endtime}`
		EUeventlist(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				// let selectedRowKeys = [...this.state.selectedRowKeys]
				resdata.data.map((item, index) => {

					if (item.type === 1) {
						item.showtype = '订阅消息'
					}
					if (item.type === 100) {
						item.showtype = '设备故障消息'
					}
					if (item.type === 200) {
						item.showtype = '用户管理消息'
					}
					if (item.type === 101) {
						item.showtype = '设备告警消息'
					}
					// if (item.readStatus === true) {
					// 	selectedRowKeys.push(item.id);
					// }
				})
				this.setState({
					// selectedRowKeys: selectedRowKeys,
					eventlist: resdata,
					loading: false,

				}, () => {
					if (this.state.eventlist.data.length < this.state.size) {
						this.inputview.setIsLast(true)
					}
					else {
						this.inputview.setIsLast(false)
					}
				})

			}
		}).catch(err => {
			this.setState({
				loading: false
			})
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('消息列表接口获取错误')
			}

		})
	}
	//获取消息未读数量
	Fneventcount = () => {
		let url = ``
		if (this.props.userInfo.organizationType === 'EU') {
			EUeventcount(url).then(res => {
				if (res !== undefined) {
					this.props.setEventcount(res.data.count)
				}
			})
		}
		else {
			eventcount(url).then(res => {
				if (res !== undefined) {
					this.props.setEventcount(res.data.count)
				}
			})
		}

	}
	onChange = (e) => {
		this.setState({
			pageIndex: e,
			selectedRowKeys: []
		}, () => {
			this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
		})

	}

	onChangeTime = (dates, dateStrings) => {
		let that = this;
		if (dates[0] === undefined || dates[1] === undefined) {
			that.setState({
				startime: '',
				endtime: ''
			}, () => {
				this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
			})

		}
		else {
			let _startime = moment(dateStrings[0]).format("YYYY-MM-DD") + " 00:00:00"
			let _endtime = moment(dateStrings[1]).format("YYYY-MM-DD") + " 23:59:59"
			let currentUTCTimeString = moment(_endtime).utc().format("YYYY-MM-DD HH:mm:ss")
			let dateUTCTimeString = moment(_startime).utc().format("YYYY-MM-DD HH:mm:ss");
			this.setState({
				startime: dateUTCTimeString,
				endtime: currentUTCTimeString,
				pageIndex: 1
			}, () => {
				this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
			})
		}
	}
	//状态筛选
	eventOnChange = (e) => {
		if (e === "0") {
			this.setState({
				readStatus: null
			}, () => {
				this.resEUeventlist(1, this.state.readStatus)
			})
		}
		if (e === "1") {
			this.setState({
				readStatus: `readStatus=true`
			}, () => {
				this.resEUeventlist(1, this.state.readStatus)
			})
		}
		if (e === "2") {
			this.setState({
				readStatus: `readStatus=false`
			}, () => {
				this.resEUeventlist(1, this.state.readStatus)
			})
		}
		this.setState({
			pageIndex: 1
		})
	}
	//类别筛选
	typeOnChange = (e) => {
		this.setState({
			notificationType: e,
			pageIndex: 1
		}, () => {
			this.resEUeventlist(1, this.state.readStatus)
		})
	}
	disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(30, 'day');
	onSelectChange = selectedRowKeys => {
		this.setState({ selectedRowKeys });
	};
	render() {
		let that = this;
		let { pageIndex, eventlist, size, fromtime, totime, selectedRowKeys, selectList } = this.state;
		const rowSelection = {
			selectedRowKeys,
			columnWidth: 25,
			onChange: this.onSelectChange,
			getCheckboxProps: record => ({
				disabled: record.readStatus === true,    // 配置无法勾选的列
			}),
		};
		return (
			<div className="shadow-box">
				<EventModel
					showmodel={that.state.showmodel}
					hidemodel={that.hidemodel}
					submitmodel={that.submitmodel}
					ondeviceDetail={this.ondeviceDetail}
				/>

				<div className="user_box">
					<Select defaultValue="通知类别"
						suffixIcon={
							<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
						}
						style={{ width: 188 }}
						onChange={this.typeOnChange}
					>
						{
							selectList.length > 0 ? (
								selectList.map((item, index) => (
									<Option value={item.type} key={index}>{item.name}</Option>
								))
							) : ''
						}

					</Select>
					<RangePicker
						defaultValue={[fromtime, totime]}
						// disabledDate={that.disabledDate}
						className="model_input"
						style={{ margin: '0 10px 0 0' }}

						suffixIcon={
							<ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
						}
						separator='—'
						allowClear={false}
						format="YYYY/MM/DD"
						onChange={this.onChangeTime}
						onOk={this.onOk}
					/>
					<Select defaultValue="通知状态"
						suffixIcon={
							<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
						}
						style={{ width: 188 }}
						onChange={this.eventOnChange}
					>
						<Option value="0">全部</Option>
						<Option value="1">已读</Option>
						<Option value="2">未读</Option>
					</Select>
					{/* <Search
						placeholder="搜索"
						onSearch={value => console.log(value)}
						style={{ width: 200 }}
						/>  */}
				</div>
				<Spin spinning={this.state.loading} style={{ height: '90vh' }}>
					<div style={{ "width": '100%', position: 'relative', minHeight: 260 }} className='org_right'>
						<>
							<Table
								size="small"
								y='true'
								className="notification_table_box"
								rowSelection={rowSelection}
								columns={this.state.columns}
								dataSource={eventlist.data}
								pagination={false}
								rowKey={(record, index) => record.id}

							/>
							<div className="readstatus">
								<span className="read1" style={{ color: selectedRowKeys.length > 0 ? '#75ba70' : '#999' }} onClick={() => this.readStatus()}>标为已读</span>
								<span onClick={() => this.readStatusAll()} className="read2">全部已读</span>
								<IndexInputView ref={(onref) => {
									this.inputview = onref;
								}} confirmInputValue={(pageIndex) => {
									this.resEUeventlist(pageIndex, this.state.readStatus)
									// this.inputview.setIsLast(true)
								}} />
							</div>

						</>

					</div>
				</Spin>
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	setNewsmodelIsShow: data => {
		dispatch(setNewsmodelIsShow(data))
	},
	setEventcount: data => {
		dispatch(setEventcount(data))
	},
	setAssetStatus: data => {
		dispatch(setAssetStatus(data));
	},
	setassetId: data => {
		dispatch(setassetId(data))
	},
	setAssetIdCopy: data => {
		dispatch(setAssetIdCopy(data));
	},
	setassettype: data => {
		dispatch(setassettype(data))
	},
	setAssetIsPowertrain: data => {
		dispatch(setAssetIsPowertrain(data));
	},
	deviceDetail: data => {
		dispatch(deviceDetail(data));
	},
	setTrendsData: data => {
		dispatch(setTrendsData(data));
	},
	setevent: data => {
		dispatch(setevent(data))
	},
	userCheckDevice: data => {
		dispatch(userCheckDevice(data))
	},
	eventDetaildevice: data => {
		dispatch(eventDetaildevice(data))
	},
	setEventDetailStatus: data => {
		dispatch(setEventDetailStatus(data))
	},
	checkAllgen: data => {
		dispatch(checkAllgen(data))
	},
	setAssetlabel: data => {
		dispatch(setAssetlabel(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EUevent));