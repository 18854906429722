import React from 'react';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
const NoPermission = props =>
    <div className="renderEmpty renderEmpty_flex">
        <div className="nopermission_center">
            <div className="nopermission_icon">

            </div>
            {/* <ABB.Icon style={{ margin: '' }} name="abb/event" sizeClass='large' /> */}
            <p className="nopermission_title">{props.data}</p>
        </div>
    </div>;

export default NoPermission;
