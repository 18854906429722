import $axios from '../axios/$axios';

export function APIsubscriptionlist(data){
   return $axios({
    url: '/api/v2/subscription/organization'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function APIdevicesubscriptionlist(data){
   return $axios({
    url: '/api/v2/subscription/assetgroup'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}


export function subscriptionInformation(data) {
   return $axios({
       url: '/api/v3/subscription/device/' + data,
       method: 'get',
       type: 'json',
       data: ""
   })
}

export function ABBsubscription(data){
   return $axios({
    url: '/api/v2/subscription'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function assettype(data){
   return $axios({
    url: '/api/v1/device/asset/type'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function sublevel(data){
   return $axios({
    url: '/api/v1/subscription/level'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function ABBcreatesubscription(data){
   return $axios({
    url: '/api/v2/subscription/create',
    method: 'post',
    type: 'json',
    data: data
   })
}

export function getsubscriptionCode(data){
   return $axios({
    url: '/api/v3/subscription'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

//老接口 根据订阅code获取订阅信息
export function getsubscriptionWithCode(code){
   return $axios({
    url: '/api/v1/subscription/'+code,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function boundsubscription(data){
   return $axios({
    url: '/api/v3/subscription/activate',
    method: 'post',
    type: 'json',
    data: data
   })
}

export function APIMoveSubscription(data){
   return $axios({
      url: '/api/v1/admin/subscription/move',
      method: 'post',
      type: 'json',
      data: data
     })
}

export function activateSubscription(data){
   return $axios({
      url: '/api/v3/subscription/activate-unused',
      method: 'post',
      type: 'json',
      data: data
     })
}