import React, { Component } from 'react';
import { Modal, Button, Input, message,Form } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import copy from "copy-to-clipboard";  
// import './CopyBoard.css'; 

const { Search } = Input;
class CreateSecretkeyclass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            isZn_CH: false
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = () => {
        
        this.props.hidemodel()
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.props.submitmodel(0)
                
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };
    copySk = (sk) => {
        message.success("复制成功！")
        copy(sk);
    }
    render() {
        const that = this;
        const { showmodel,disabled } = this.props;
        const { getFieldDecorator } = that.props.form;

        const modelProps = {
            title: '生成Secret Key',
            centered: true,
            width: 604,
            maskClosable: false,
            visible: showmodel.visible,
            onOk: () => { that.hidemodel() },
            onCancel: () => { that.hidemodel() },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel()}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    disabled={disabled}
                    text="确定"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>

                <div className="sk_box">
                    <div>
                    Secret Key：{showmodel.sk}
                    </div>
                    <div className="copysk" onClick={()=>this.copySk(showmodel.sk)}>
                        复制
                    </div>
                </div>
                <div className="sk_tip">
                关闭此弹窗后Secret Key将不再可见，只能重新生成，在点击确定前请务必确认已将Secret key保存。
                </div>
            </Modal>
        );
    }
}
const CreateSecretkey = Form.create({ name: 'register' })(CreateSecretkeyclass);

export default CreateSecretkey;
