import React, { Component } from 'react';
import { message } from 'antd';
import { setEventDetail } from '@/redux/actions/event';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import EventModel from '@/components/eventcomponent/eventModel'
import { geteventDetail, closeevent } from '@/api/device.js'
import { eventDetailData, chartConfig, easemobim_parameter } from '@/api/event.js'
import moment from 'moment';
import '../style.scss'
import { APICallPrompt } from '../../../../../../api/device';
import CallPromptModel from '../../callPromptModel';
import SupportRecordModel from '../../supportRecordModel';


class EeventDetail extends Component {
	constructor(props) {
		super(props);
		// console.log("jurisdictionConfig11111111111",props.jurisdictionConfig)
		this.state = {
			feature: false,
			showmodel: {},
			assetEventid: '',
			disabled: false,
			showCallPromptConfig: {
				visible: false,
				title: '',
				value: '',
				width: 700,
				centered: true,
				eventData: {},
				timeLength: 0
			},
			showSupportRecordConfig: {
				visible: false,
				title: '',
				value: '',
				width: 700,
				centered: true,
				eventData: {
					calledRecords: []
				},

			}
		}
	}

	//关闭事件接口
	closeevent = (val) => {
		if (this.state.assetEventid !== '') {

			let url = {
				id: this.state.assetEventid,
				closeReason: val
			}
			this.setState({
				disabled: true
			})
			closeevent(url).then(res => {
				if (res !== undefined) {
					message.success('操作成功')
					this.setState({
						disabled: false,
						showmodel: {
							visible: false,
							title: '',
							width: 504,
							value: '',
							centered: true,
						},
					})
					this._geteventDetail(this.state.assetEventid, false)

				}
			}).catch(err => {
				if (err.status === 400) {
					message.warning(err.data)
				}
				else {
					message.error('关闭事件失败')
				}
				this.setState({
					disabled: false
				})
			})
		}

	}
	// 组件传值
	hidemodel = (data) => {
		this.setState({
			showmodel: {
				visible: data,
				title: '',
				width: 504,
				value: '',
				centered: true,
			},

		})
	}
	submitmodel = (val) => {
		if (val !== '') {
			this.closeevent(val)
		}
		else {
			message.warning('处理方案不能为空！')
		}
	}
	showmodel = (id) => {
		this._geteventDetail(id, true)
		// this.setState({
		// 	modeltype: type,
		// })
	}
	_geteventDetail = (id, showmodel) => {
		//获取设备详情
		let url = `${id}?userId=${this.props.userInfo.userId}`;
		eventDetailData(url).then(res => {
			if (res.data.code === 200) {
				let resdata = res.data.data;
				// let eventDateTime = moment(resdata.eventDateTime).utc().format("YYYY-MM-DD HH:mm:ss")
				let eventDateTime = moment.parseZone(resdata.eventTime).local().format('YYYY-MM-DD HH:mm:ss')
				let eventCloseTime = moment.parseZone(resdata.closeAt).local().format('YYYY-MM-DD HH:mm:ss')
				this.setState({
					// eventdetail: `${eventDateTime} ${resdata.content}`,
					assetEventid: `${resdata.eventId}`,
					showmodel: {
						visible: showmodel,
						title: '事件详情',
						value: `${eventDateTime} ${resdata.message}`,
						width: 504,
						closeReason: resdata.closeReason,
						closeUser: `${resdata.closeUserOrganization}, ${resdata.closeUser}, ${eventCloseTime}`,
						centered: true,
						assetEventStatus: `${resdata.eventStatus}`
					}
				})
				// this.props.setEventDetail(resdata)

				//临时代码,模拟设备状态
				// resdata.deviceWorkStatus = 0
				this.getTrendConfig(resdata)
			}
		})
	}
	getTrendConfig = (data) => {
		// console.log("this.props.getEventDetail----",data)
		let url = "?eventId=" + data.eventId
		chartConfig(url).then(res => {
			// console.log("chartConfigchartConfig---",res.data.data)
			this.props.setEventDetail({ ...data, trendconfig: res.data.data });

		})
	}
	isassetEventType = (type) => {
		if (type === 'Fault') {
			return (
				<div className="eventtype">
					<ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
					故障
				</div>
			)
		}
		if (type === 'Warning') {
			return (
				<div className="eventtype">
					<ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
					告警
				</div>
			)
		}
		if (type === 'Notification') {
			return (
				<div className="eventtype">
					<ABB.Icon style={{ 'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
					通知
				</div>
			)
		}
		if (type === 'Custom') {
			return (
				<div className="eventtype">
					<div className='customevent'></div>
					自定义
				</div>
			)
		}
	}

	_renderDeviceStatus = (deviceWorkStatus) => {

		if (deviceWorkStatus === 1) {
			return (
				<div className="device_status">
					<ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drive" sizeClass='medium' />&nbsp;
					<div style={{ 'color': '#1f1f1f' }}>运行</div>
				</div>
			)
		}
		if (deviceWorkStatus === 2) {
			return (
				<div className="device_status">
					<ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drive" sizeClass='medium' />&nbsp;
					<div style={{ 'color': '#1f1f1f' }}>待机</div>
				</div>
			)
		}
		if (deviceWorkStatus === 3) {
			return (
				<div className="device_status">
					<ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drives-error" sizeClass='medium' />&nbsp;
					<div style={{ 'color': '#1f1f1f' }}>计划停机</div>
				</div>
			)
		}
		if (deviceWorkStatus === 4) {
			return (
				<div className="device_status">
					<ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drives-error" sizeClass='medium' />&nbsp;
					<div style={{ 'color': '#1f1f1f' }}>非计划停机</div>
				</div>
			)
		}
		return <div>--</div>

	}
	_callPromptRequest = (record) => {
		const that = this
		APICallPrompt(record.eventId).then((res) => {
			if (res !== undefined) {
				if((res.data?.data?.pushResultMessage||'').length==0){
					that._showCallPrompt(record)
				   }else{ 
					message.warning("操作失败，"+res.data.data.pushResultMessage)
				   }
			}
		}).catch(err => {
			that.setState({
				loading: false
			})
			if (err.data.code === 400 || err.data.code === 404) {
				message.warning(err.data.errorMessage)
			}
			else {
				message.error('一键呼叫请求异常')
			}
		})
	}
	//一键呼叫请求
	_showCallPrompt = (record) => {
		//一键呼叫网络请求
		var eventData = {
			...record, ...{
				assetEventType: record.eventType,
				content: record.message,
				eventDateTime: record.eventTime
			}
		}

		this.setState({
			showCallPromptConfig: { ...this.state.showCallPromptConfig, visible: true, eventData: eventData, timeLength: 5 }
		}, () => {
			this.child1.udpateTimeLength()
		})

	}
	//支持记录
	_supportRecord = (record) => {

		var eventData = {
			...record, ...{
				assetEventType: record.eventType,
				content: record.message,
				eventDateTime: record.eventTime
			}
		}

		var config = { ...this.state.showSupportRecordConfig }
		config.visible = true;
		config.eventData = eventData
		this.setState({
			showSupportRecordConfig: config
		})

	}
	renderEventName = (eventCode) => {
		if (eventCode === 1) {
			return '故障'
		}
		if (eventCode === 2) {
			return '告警'
		}
		if (eventCode === 3) {
			return '通知'
		}
		if (eventCode === 8) {
			return '自定义'
		}
		return "--"
	}
	//调用环信机器人
	callEasemob = (record) => {
		window.easemobim.bind({
			configId: "198e7ac1-4dfc-4e01-a043-037569957e4e",
			dialogWidth: "500px",
			hide:true
		})
		return

		this.setState({
			loading: true
		})
		easemobim_parameter(record.eventId).then(res => {

			this.setState({
				loading: false
			})

			//临时代码 模拟设备状态 设备详情
			if (res.data.code === 200) {
				let resdata = res.data.data


				window.easemobim.bind({
					configId: "198e7ac1-4dfc-4e01-a043-037569957e4e",
					dialogWidth: "500px"
				})
				console.log('res======================', res)

				window.easemobim.sendExt({
					ext: {
						"imageName": `{${resdata.productImageName}}`,
						//custom代表自定义消息，无需修改
						"type": "custom",
						"msgtype": {
							"track": {
								"title": `{[${resdata.orgName}]}{${resdata.productName}}{${this.renderEventName(resdata.eventType)}}`,  // 公司名称 / 型号 / 事件类型
								"price": `故障代码：${resdata.eventCode || "--"}`,    // 故障代码、辅助代码
								"desc": `序列号：${resdata.deviceSn || "--"}；故障描述：${resdata.eventContent || "--"}`,  // 序列号 / 描述
								"img_url": `{${process.env.REACT_APP_BASE_URL}/${resdata.productImageName}}`,  // 产品图片地址

							}
						}
					}
				});

			} else {

				console.log('res======================11111111111', res)
				message.destroy()
				message.error('事件信息获取失败')
			}
		}).catch((err) => {
			console.log('res======================22222222222222', err)
			this.setState({
				loading: false
			})
			message.destroy()
			message.error('事件信息获取失败')
		})


	}

	_renderCallStatusUI = (eventDetail) => {
		//临时关闭专家服务 设备详情

	 
 
 
		if (eventDetail.calledStatus === 1) {
			return (
				<div className='icon_center' >
					<span style={{ cursor: 'pointer' }} onClick={() => this._callPromptRequest(eventDetail)}>
						<ABB.Icon name="abb/call" sizeClass='small' /> <text style={{ cursor: 'pointer' }}>一键呼叫</text>
					</span>
				</div>
			)
		}

		if (eventDetail.calledStatus === 2) {
			return (
				<div className='icon_center'>
					<span style={{ cursor: 'pointer' }} onClick={() => this._supportRecord(eventDetail)}>
						<ABB.Icon name="abb/list" sizeClass='small' /> <text style={{ cursor: 'pointer' }}>支持记录</text>
					</span>
				</div>
			)
		}

		if (eventDetail.calledStatus === 3) {
			return (
				<div className='icon_center' >
					<span style={{ cursor: 'pointer' }} onClick={() => this.callEasemob(eventDetail)}>
						<ABB.Icon name="abb/call" sizeClass='small' /> <text style={{ cursor: 'pointer' }}>一键呼叫</text>
					</span>
				</div>
			)
		}

	}

	//专家服务
	renderExportService = () => {
		//临时关闭专家服务
	 
		const { getEventDetail } = this.props;

		if (getEventDetail.eventType !== 'Fault') return null
		if (getEventDetail.calledStatus === 0 || getEventDetail.calledStatus === undefined) return null

		// 临时关闭专家服务 事件详情
		return <div className="eventdetaillist">
			<div>设备服务：</div>
			{
				this._renderCallStatusUI(getEventDetail)
			}
		</div>
	}
	isshowlink=()=>{
		const { feature } = this.state;
		let { getEventDetail } = this.props;
		console.log("_deviceDetail_deviceDetail---",feature)
		if(feature === true){
			return (
				<div className="eventdetaillist">
						<div>设备状态：</div>
						{
							this._renderDeviceStatus(getEventDetail.deviceWorkStatus)
						}
					</div>
			)
		}
	}
	componentDidMount() {
        let { getsubscriptioncof } = this.props;
        let feature = false
        getsubscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 28) {
                    feature = items.limitValue
                }
            })

        })
        this.setState({
            feature: feature
        })
    }

	render() {
		const { disabled } = this.state;
		const { getEventDetail } = this.props;
		return (
			<div className="eventdetailbox">
				<div className="eventdetaillist">
					<div>事件类型：</div>
					{
						this.isassetEventType(getEventDetail.eventType)
					}

				</div>
				{
					this.isshowlink()
				}
				


				<div className="eventdetaillist">
					<div>设备端时间：</div>
					<div>{
						getEventDetail.occurrentTimeIsValidFormat === true ? (

							<div>

								{moment.parseZone(Number(getEventDetail.eventOccurrentTime)).local().format("YYYY-MM-DD HH:mm:ss")}

							</div>
						) : (
							<div>{getEventDetail.eventOccurrentTime || '--'}</div>
						)
					}</div>
				</div>
				<div className="eventdetaillist">
 
					<div>网关时间：</div>
					<div>{getEventDetail.eventTime !== undefined ? moment.parseZone(getEventDetail.eventTime).local().format("YYYY-MM-DD HH:mm:ss") + "（UTC+8）" : '-'}</div>

				</div>

				{
					this.renderExportService()
				}



				<div className="eventdetaillist">
					<div>事件描述：</div>
					<div>{getEventDetail.message || '--'}</div>
				</div>

				<div className="eventdetaillist">
					<div>事件代码：</div>
					<div>{getEventDetail.eventCode || '--'}</div>
				</div>
				<div className="eventdetaillist">
					<div>事件状态：</div>
					{

						getEventDetail.eventStatus === undefined ? <div>--</div> :

							<>
								{
									getEventDetail.eventStatus === "Open" ? (
										<div style={{ color: '#3366FF' }}>
											<span style={{ cursor: 'pointer' }} onClick={() => {
												this.showmodel(
													getEventDetail.eventId
												)
											}}>未处理</span>
										</div>
									) : <div style={{ color: '#0ca919' }} >
										<span style={{ cursor: 'pointer' }} onClick={() => {
											this.showmodel(
												getEventDetail.eventId
											)
										}}>已处理</span>
									</div>
								}
							</>


					}
				</div>
				<div className="eventdetaillist">
					<div>解决方案：</div>
					<div>{getEventDetail.solution || '--'}</div>
				</div>
				<EventModel
					showmodel={this.state.showmodel}
					hidemodel={this.hidemodel}
					disabled={disabled}
					submitmodel={this.submitmodel} />

				<CallPromptModel
					onRef={(ref) => { this.child1 = ref }}
					showmodelboundsub={this.state.showCallPromptConfig}
					hidemodel={() => {
						this.setState({ showCallPromptConfig: { ...this.state.showCallPromptConfig, visible: false } })

						this._geteventDetail(getEventDetail.eventId, false)
					}}
				/>

				<SupportRecordModel
					showmodelboundsub={this.state.showSupportRecordConfig}
					hidemodel={() => {
						this.setState({
							showSupportRecordConfig: { ...this.state.showSupportRecordConfig, visible: false }
						})
					}} />
			</div>
		)
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setEventDetail: data => {
		dispatch(setEventDetail(data));
	},

});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EeventDetail));
// export default EeventDetail;