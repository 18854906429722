import React, { Component } from 'react';
import { Table, Icon, Modal, Button, Input, Select, Menu, Dropdown, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { setplant, setaddress, setaddressChange, setPlantisShow } from '@/redux/actions/plant';
import { withRouter } from 'react-router-dom';
import { plantList, deleteplant, changeplant } from '@/api/plant';
import { setUserInfo } from '@/redux/actions/userInfo';
import Amap from './components/plantaddressmap';
import { plantListdata } from '@/utils/devicesData';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import Addplant from './components/addplant'
import Changeplant from './components/changeplant'
import NoData from '@/components/NoData'

const { Search } = Input;
const { Option } = Select;
const icon_center = {
	margin: '0 10px 0 0',
	display: 'inline'
}
const Tooltip_plantchange = <span>编辑</span>;
const Tooltip_plantremove = <span>删除</span>;
class Plant extends Component {

	constructor(props) {
		super(props);
		this.state = {
			orgId: this.props.getSubid.orgId,
			addressObj: {},
			pagination: {},
			loading: false,
			addPlantdata: false,
			addPlantval: '',
			latitude: '',
			longitude: '',
			address: '',
			districts: '',
			plantName: '',
			deletePlantdata: false,
			changePlantdata: false,
			changePlantval: '',
			plantId: '',
			disabled: false,
			columns: [
				{
					title: '位置名称',
					dataIndex: 'name',
					key: '0',
					width: 180
				},
				{
					title: '经纬度',
					dataIndex: 'addresslocation',
					key: '1',
					width: 180
				},
				// {
				// 	title: '省（市）',
				// 	dataIndex: 'provinces',
				// 	key: '2',
				// 	width: 80
				// },
				// {
				// 	title: '市（区）',
				// 	dataIndex: 'cities',
				// 	key: '3',
				// 	width: 80
				// },
				{
					title: '地址',
					dataIndex: 'address',
					key: '4',
					width: 200,
					render: (text, record) => (
						<span>{record.provinces}{record.cities}{record.districts}{record.address}</span>
					)
				},
				{
					title: '创建人',
					dataIndex: 'createdByUserName',
					key: '5',
					width: 100
				},
				{
					title: '创建时间',
					dataIndex: 'createdOn',
					key: '6',
					width: 160
				},
				{
					title: '操作',
					dataIndex: '7',
					key: '7',
					width: 80,
					render: (text, record) => (

						<>
							{
								this.props.userInfo.organizationSubType !== "Float" ? (
									<div>
										<Tooltip placement="bottom" title={Tooltip_plantchange}>
											<div style={icon_center} onClick={() => this.FnchangePlant(true, record)}>
												<ABB.Icon name="abb/edit" sizeClass='small' />
											</div>
										</Tooltip>
										<Tooltip placement="bottom" title={Tooltip_plantremove}>
											<div style={icon_center} onClick={() => { this.deletePlant(true, record.id) }}>
												<ABB.Icon name="abb/trash" sizeClass='small' />
											</div>
										</Tooltip>
									</div>
								) : ''
							}



						</>
					),
				}
			]
		};
	}
	componentDidMount() {
		this.plantList()

	}
	componentDidUpdate(nextProps, prevState) {

		if (prevState.orgId !== this.props.getSubid.orgId) {
			this.setState({
				orgId: this.props.getSubid.orgId
			}, () => {
				this.plantList()
			})
		}
	}
	//修改位置
	FnchangePlant(changePlantdata, data) {
		if (data !== undefined) {
			this.setState({
				changePlantdata: changePlantdata,
			})
			this.props.setPlantisShow(1)
			this.props.setaddressChange({
				plantName: data.name,
				plantId: data.id,
				address: data.address,
				addressDisabled: true,
				latitude: data.latitude,
				longitude: data.longitude,
			})
		}
		else {
			this.setState({
				changePlantdata
			})
		}
	}

	//删除按钮弹出框
	deletePlant(deletePlantdata, id) {
		this.setState({
			deletePlantdata,
			plantId: id
		})
	}
	//删除数据接口
	resdeleteplant() {
		this.setState({
			disabled: true
		})
		let that = this;
		if (that.state.plantId !== '') {
			let url = `/${that.props.getSubid.orgId}/${that.state.plantId}`;
			deleteplant(url).then(res => {
				if (res !== undefined) {

					message.success('位置删除成功！')
					that.setState({
						deletePlantdata: false,

					})
					setTimeout(() => {
						that.setState({
							disabled: false,
						})
					}, 1000)
					that.plantList()
				}

			}).catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data)
				}
				that.setState({
					disabled: false,
				})
			})
		}

	}
	//点击获取plant接口数据刷新数据
	plantList = () => {
		let that = this;
		if (that.props.getSubid.type == 3 || that.props.getSubid.type == 100) {
			plantListdata(that.props.getSubid.orgId, function (data) {
				that.props.setplant(data)
			})
		}
	}



	render() {
		let mytablecolumns = this.state.columns;
		let _self = this;
		let { getplant, getPlantisShow, getSubid, userInfo } = _self.props;

		return (
			<div style={{ position: "relative", height: '80vh' }}>
				{
					(getSubid.type == 3 || getSubid.type == 100 && userInfo.organizationSubType !== "Float") ? (
						<>
							<div className="ABB_tab_marginTop">
								<Addplant />
							</div>
							<div style={{ position: "relative", height: '70vh' }}>
							{
								getplant.length > 0 ? (
									<Table

										size='small'
										// scroll={{ x: 740 }}
										className='org_right ABB_tab_marginTop'
										ellipsis={true}
										columns={mytablecolumns}
										dataSource={getplant}
										pagination={false}
										rowKey={(record, item) => item}
									/>

								) : <NoData />
							}
							</div>
							
						</>
					) : <NoData />
				}

				{/* 修改位置 */}
				{
					getPlantisShow === 1 ? <Changeplant plantName={this.state.plantName} /> : ''
				}


				{/* 删除位置 */}
				<Modal
					title="提示"
					centered
					closeIcon={
						<ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
					}
					width={504}
					visible={this.state.deletePlantdata}
					onOk={() => this.deletePlant(false)}
					onCancel={() => this.deletePlant(false)}
					footer={[

						<ABB.Button
							onClick={() => this.deletePlant(false)}
							type="discreet-black"
							sizeClass='small' theme="filled"
							text="取消"
						/>,
						<ABB.Button
							onClick={() => _self.resdeleteplant()}
							type="primary-blue"
							disabled={_self.state.disabled}
							sizeClass='small' theme="filled"
							text="确定"
						/>,

					]}
				>
					<div className="remove_model">确定删除当前位置吗？</div>
				</Modal>


			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setplant: data => {
		dispatch(setplant(data))
	},
	setUserInfo: data => {
		dispatch(setUserInfo(data))
	},
	setaddress: data => {
		dispatch(setaddress(data))
	},
	setaddressChange: data => {
		dispatch(setaddressChange(data))
	},
	setPlantisShow: data => {
		dispatch(setPlantisShow(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Plant));

// export default EUsubscription;

