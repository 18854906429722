//编辑数字化连接调试信息
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Form,
    Input,
    Modal,
    message,
    Row,
    Spin,

} from 'antd';

import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { setExpertsConfig } from '../../store/actionCreators'
import './style.scss'
import { APISetContact } from '../../../../../../api/device';

class ServiceModel extends React.Component {
    state = {
        inputval: '',
        valiededCode: '',
        subdata: {},
        disabled: false,
        title: "abc"
    };

    hidemodel = (data) => {
        this.props.form.resetFields();
        var config = { ...this.props.getExpertsConfig }
        config.visible = false
        //专家服务方式弹框
        this.props.updateExpertsConfig(config)
    }

    //校验联系人信息是否合法  暂时还废弃
    _checkContactPerson = (contact) => {
        console.log("contact===============", contact)


        if (contact.isMust) {
            //必传
            if (contact.name === '') {

                return {
                    isError: true,
                    errorMessage: `${contact.title}姓名不能为空`,

                }
            }

        } else {

        }

        return {
            isError: false,
            errorMessage: "",

        }

    }


    _commitServiceType = (data) => {
        this.setState({
            disabled: true
        })
        let { getAssetIsPowertrain, getassetId } = this.props;
        let isSubAsset = 'false';
        if (getAssetIsPowertrain === true) {
            isSubAsset = `true`;
        }

        APISetContact(getassetId, isSubAsset, data).then(res => {
            console.log("url============设置服务方式为一键呼叫===", res)
            let resdata = res.data;

            if (resdata !== undefined) {

                this.hidemodel()
                this.props.submitmodel()
            }
            this.setState({
                disabled: false
            })
        }).catch(err => {
            this.setState({
                loading: false,
                disabled: false
            })
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('设备详情接口异常')
            }
        })
    }

    submitmodel = () => {
        const config = { ...this.props.getExpertsConfig }
        //一键呼叫
        // if (config.expertServiceType === 1) {

        //     //一键呼叫,只改服务方式,没有变化时,直接退出
        // if (config.expertServiceType === this.props._deviceDetail.expertServiceType) {
        //     this.hidemodel()
        //     return
        // }
        //     this._commitServiceType({ "serviceType": 1 })
        //     return
        // }

        //主动触发 需要设置手机号
        // if (config.expertServiceType === 2) {

        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                let deviceContacts = config.deviceContacts
                let commitContacts = []
                for (let index = 0; index < deviceContacts.length; index++) {
                    const element = deviceContacts[index];
                    if (element.phone === '' || element.name === '') {
                        let contact = {
                            "name": "",
                            "phone": "",
                            "priority": element.priority
                        }
                        commitContacts.push(contact)
                    } else {
                        let contact = {
                            "name": element.name,
                            "phone": element.phone,
                            "priority": element.priority
                        }
                        commitContacts.push(contact)
                    }
                }
                let data = {
                    "serviceType": config.expertServiceType === 0 ? 1 : config.expertServiceType,
                    contacts: commitContacts
                }

                this._commitServiceType(data)
            } else {
                console.log("表单验证错误")
            }
        });
        // }
    }

    //  联系人标题
    _renderSectionHeaderByTitle = (isMust, title) => {

        return <Row >
            <div className='contact_section_item'>
                {isMust === true ? (<div className='section_icon'></div>) : null}
                <div >{title}</div>
            </div>
        </Row>

    }

    // 填充联系人
    _fillingContact = () => {

        var config = { ...this.props.getExpertsConfig }
        const { deviceContacts, orgContacts } = config

        for (let i = 0; i < orgContacts.length; i++) {
            if (i < 4) {
                var orgContact = orgContacts[i];
                var deviceContact = deviceContacts[i]
                deviceContact.name = orgContact.name
                deviceContact.phone = orgContact.phone
            }
        }
        this.props.form.setFieldsValue({
            ['name' + 0]: deviceContacts[0].name,
            phone0: deviceContacts[0].phone,

            name1: deviceContacts[1].name,
            phone1: deviceContacts[1].phone,

            name2: deviceContacts[2].name,
            phone2: deviceContacts[2].phone,

            name3: deviceContacts[3].name,
            phone3: deviceContacts[3].phone,
        })
        this.props.updateExpertsConfig({ ...config })

        // this.props.updateExpertsConfig()
    }

    //切换服务方式
    _selectedServiceType = (serviceType) => {
        var config = { ...this.props.getExpertsConfig }
        const type = config.expertServiceType
        config.expertServiceType = type === 2 ? 1 : 2
        this.props.updateExpertsConfig(config)
    }

    //服务方式选择图标
    _renderSelectIcon = (isSelected) => {
        return <div className={isSelected === true ? 'external_selected' : "external_default"}></div>
    }

    _renderServiceTypeTitle = () => {
        const serviceType = this.props._deviceDetail.expertServiceType
        if (serviceType === 1) {
            return "一键呼叫"
        }
        if (serviceType === 2) {
            return "设备看护"
        }
    }

    onChangeInput = (e, item, type) => {
        //type 1:姓名  2:电话

        var config = { ...this.props.getExpertsConfig }
        config.defaultDeviceContacts = [...config.defaultDeviceContacts]

        var contacts = [...config.deviceContacts]
        var contact = contacts[item.priority]

        if (type === 1) {
            contact.name = e.target.value
        }
        if (type === 2) {
            contact.phone = e.target.value
        }

        config.deviceContacts = contacts

        this.props.updateExpertsConfig(config)
    }

    render() {
        let that = this;

        const { _deviceDetail } = that.props;
        const { getFieldDecorator } = that.props.form;

        let config = that.props.getExpertsConfig

        const modelProps = {
            title: "设备服务方式",
            centered: true,
            width: 756,
            maskClosable: false,

            visible: config.visible,

            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="提交"
                />,
            ]
        }


        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >

                    <div className='introduce_box'>
                        <div className='introduce_item_selected' style={{ opacity: '0.5' }}>
                            {
                                this._renderSelectIcon(true)
                            }

                            <div className='introduce_message_box'>
                                <div className='introduce_title'>一键呼叫</div>
                                <div className='introduce_text'>一键获取ABB技术支持，协助您处理设备故障</div>
                            </div>


                        </div>
                        <div className={config.expertServiceType === 2 ? 'introduce_item_selected right' : 'introduce_item right'} onClick={() => {
                            this._selectedServiceType(2)
                        }}>
                            {
                                this._renderSelectIcon(config.expertServiceType === 2 ? true : false)
                            }

                            <div className='introduce_message_box'>
                                <div className='introduce_title'>设备看护</div>
                                <div className='introduce_text'>
                                    ABB定期巡检设备故障信息，并与您取得联系
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* {
                        config.expertServiceType !== 0 ? */}
                    <div className='service_record' >
                        <div>当前服务方式：{this._renderServiceTypeTitle()}</div>
                        <div>当前服务方式设置记录：用户{_deviceDetail.expertServiceTypeUpdateUser !== null ? _deviceDetail.expertServiceTypeUpdateUser : "--"}于{moment(_deviceDetail.expertServiceTypeUpdateTime).format("YYYY-MM-DD HH:mm:ss")}设置</div>
                    </div>
                    {/* : null */}
                    {/* } */}

                    <>

                        <div className='section_title'>设置设备联系人</div>
                        <div className='contactPersonBox'>
                            <div className='left'>
                                <div className='instructions'>当前设备需要ABB专家提供支持时，ABB专家将按照以下顺序联系并提供支持。</div>

                                <Form >
                                    {
                                        config.deviceContacts.map((item, index) => {

                                            return (<>
                                                <div className='input_content' >
                                                    {/* <div className='item_title'>{item.title}</div> */}
                                                    {
                                                        this._renderSectionHeaderByTitle(item.isMust, item.title)
                                                    }
                                                    {
                                                        item.isMust ? <div className='input_box'>
                                                            <div className='input_left'>

                                                                <Form.Item >

                                                                    {getFieldDecorator(`name${index}`, {
                                                                        initialValue: item.name,
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: '联系人不能为空',
                                                                            },

                                                                            {
                                                                                whitespace: true,
                                                                                message: '禁止输入空格',
                                                                            }
                                                                        ],
                                                                    })(
                                                                        <Input className="model_input" disabled={false} placeholder='请输入' onChange={(e) => {
                                                                            this.onChangeInput(e, item, 1)
                                                                        }} />
                                                                    )}
                                                                </Form.Item>

                                                            </div>
                                                            <div className='input_right'>
                                                                <Form.Item >
                                                                    {getFieldDecorator(`phone${index}`, {
                                                                        initialValue: item.phone,
                                                                        rules: [

                                                                            {
                                                                                required: true,
                                                                                message: '联系方式不能为空',
                                                                            },

                                                                            {
                                                                                pattern: new RegExp(/^\s*1\s*[3456789]\s*(\d\s*){9}\s*$/, "g"),
                                                                                message: '手机号格式错误',
                                                                            },
                                                                            {
                                                                                whitespace: true,
                                                                                message: '禁止输入空格',
                                                                            }
                                                                        ],
                                                                    })(
                                                                        <Input className="model_input" disabled={false} placeholder='请输入电话号码' onChange={(e) => {
                                                                            this.onChangeInput(e, item, 2)
                                                                        }} />
                                                                    )}
                                                                </Form.Item>


                                                            </div>
                                                        </div> :

                                                            <div className='input_box'>
                                                                <div className='input_left'>

                                                                    <Form.Item >

                                                                        {getFieldDecorator(`name${index}`, {
                                                                            initialValue: item.name,
                                                                            rules: [
                                                                                {
                                                                                    whitespace: true,
                                                                                    message: '禁止输入空格',
                                                                                }
                                                                            ],
                                                                        })(
                                                                            <Input className="model_input" disabled={false} placeholder='请输入' onChange={(e) => {
                                                                                this.onChangeInput(e, item, 1)
                                                                            }} />
                                                                        )}
                                                                    </Form.Item>

                                                                </div>
                                                                <div className='input_right'>
                                                                    <Form.Item >
                                                                        {getFieldDecorator(`phone${index}`, {
                                                                            initialValue: item.phone,
                                                                            rules: [

                                                                                {
                                                                                    pattern: new RegExp(/^\s*1\s*[3456789]\s*(\d\s*){9}\s*$/, "g"),
                                                                                    message: '手机号格式错误',
                                                                                },
                                                                                {
                                                                                    whitespace: true,
                                                                                    message: '禁止输入空格',
                                                                                }
                                                                            ],
                                                                        })(
                                                                            <Input className="model_input" disabled={false} placeholder='请输入电话号码' onChange={(e) => {
                                                                                this.onChangeInput(e, item, 2)
                                                                            }} />
                                                                        )}
                                                                    </Form.Item>


                                                                </div>
                                                            </div>
                                                    }

                                                </div>

                                            </>)
                                        })
                                    }
                                </Form>

                            </div>
                            <div className='center'>
                                <div className='line'></div>
                                <div className='center_button_box'>
                                    <div className='center_button' onClick={this._fillingContact}>
                                        <div className='center_button_icon'> </div>
                                        <div>一键填充至设备联系人</div>
                                    </div>
                                </div>

                            </div>
                            <div className='right'>
                                <div className='instructions'>当前组织管理员为组织设置的联系人</div>
                                <div>
                                    {
                                        config.orgContacts.map((item1, index) => {
                                            if (index === 0) {
                                                return (<div className='input_content' key={'right' + item1.priority}>
                                                    <div  >{item1.title}</div>
                                                    <div className='name_phone'>{item1.name || "-"}  {item1.phone || "-"}</div>
                                                </div>)

                                            } else {
                                                return (<div className='input_content1' key={'right' + item1.priority}>
                                                    <div  >{item1.title}</div>
                                                    <div className='name_phone'> {item1.name || "-"}  {item1.phone || "-"}</div>
                                                </div>)

                                            }
                                        })
                                    }

                                </div>
                            </div>

                        </div>
                    </>

                </Modal>
            </>
        );
    }
}



const RemindModelForm = Form.create({ name: 'register' })(ServiceModel);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    //专家服务方式弹框
    updateExpertsConfig: data => {
        dispatch(setExpertsConfig(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RemindModelForm));

