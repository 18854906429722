import React, { Component } from 'react'
import AnnualAging from "../charts/annualaging";
import Annuallifemaintenance from "../charts/Annuallifemaintenance";
import AnnuallifemaintenanceNoline from "../charts/Annuallifemaintenance_noline";
import Dailylife from "../charts/dailylife";
import { connect } from 'react-redux';
import { setCBMdata, setCBMtype } from '@/redux/actions/cbm'
import { devicecbm } from '@/api/cbm';
import { withRouter } from 'react-router-dom';
import { userCheckDevice, deviceDetail } from '@/redux/actions/device';
import { message } from 'antd';
import NoData from '@/components/NoData'


class Cooling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assetId: this.props._deviceDetail.assetId,

        }
    }
    componentDidMount() {
        this.fnCBMdata();
    }

    fnCBMdata = () => {

        let { _deviceDetail, getAssetIsPowertrain } = this.props;

        let isSubAsset = ``;
        let url;
        if (getAssetIsPowertrain === true ) {
			isSubAsset = `?isSubAsset=true`;    
		}
		else {
			isSubAsset = ``
		}
        url = `${_deviceDetail.assetId}${isSubAsset}`;

        devicecbm(url).then(res => {

            if (res !== undefined) {
                let resdata = res.data;
                this.props.setCBMdata(resdata);
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                // message.warning(err.data)
                this.props.setCBMdata("");
                this.props.setCBMtype("fan");
            }
            else {
                console.log("fnCBMdata--devicecbm--接口异常")
                message.error('接口异常')
            }
        })
    }
    // componentDidUpdate(nextProps, prevState) {
    //     if (prevState.assetId !== this.props._deviceDetail.assetId) {
    //         this.setState({
    //             assettype: this.props._deviceDetail.assetId,
    //         }, () => {
    //             this.fnCBMdata();
    //         })
    //     }
    // }
    render() {
        return (
            <div style={{ position: 'relative', minHeight: '60vh' }}>
                {
                    this.props.getCBMdata !== '' ? (
                        <>
                            <div className="charts_box">

                                <Annuallifemaintenance cbmdata={this.props.getCBMdata} />

                            </div>

                            <div className="charts_box">
                                <AnnualAging cbmdata={this.props.getCBMdata} />
                                <AnnuallifemaintenanceNoline cbmdata={this.props.getCBMdata} />
                            </div>
                            <div className="charts_box">
                                <Dailylife cbmdata={this.props.getCBMdata} />
                            </div>
                        </>
                    ) : <NoData />
                }
            </div>

        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setCBMdata: data => {
        dispatch(setCBMdata(data))
    },
    setCBMtype: data => {
        dispatch(setCBMtype(data))
    },
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Cooling));
// export default Cooling;