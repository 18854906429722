import React, { Component } from 'react'
import Cooling from "./components/Cooling";
import moment from "moment";
import { connect } from 'react-redux';
import { setCBMdata, setCBMtype } from '@/redux/actions/cbm'
import { withRouter } from 'react-router-dom';
import { userCheckDevice, deviceDetail } from '@/redux/actions/device';
import { message } from 'antd';
import LifeSetting from "./components/lifesetting";
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { devicecbm, devicecbmunit,updatedevicecbmunit } from '@/api/cbm';

const dateFormat = 'YYYY/MM/DD';

class Predictive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            seriesData: {},
            isUpdateform: false,
            assetId: this.props._deviceDetail.assetId,
            CBMtype: this.props.getCBMtype,
            // cbmdata: props.cbmdata,
            lifedata: {
                visible: false,
                cbmunitdata: {
                    startDate: '',
                    year: '',
                    month: ''
                }
            },
            cbmunitdata: {}
        };
    }
    callback(newIndex) {
        this.setState({ 
            activeTab: newIndex
        });
        if (newIndex === 0) {
            this.props.setCBMtype("fan");
            this.devicecbmunitData("fan");
            this.setState({ 
                CBMtype: "fan"
            });
        }
        if (newIndex === 2) {
            this.props.setCBMtype("igbt")
            this.devicecbmunitData("igbt");
            this.setState({ 
                CBMtype: "igbt"
            });
        }
        if (newIndex === 1) {
            this.props.setCBMtype("capacitor")
            this.devicecbmunitData("capacitor");
            this.setState({ 
                CBMtype: "capacitor"
            });
        }

    }
    gotolifesetting = () => {
        // let { _deviceDetail,getSubid,getassettype } = this.props;
        this.setState({
            lifedata: {
                visible: true,
                cbmunitdata: this.state.cbmunitdata
            }
        })
    }
    hidemodel = (data) => {

        this.setState({
            lifedata: {
                visible: data,
                cbmunitdata: {
                    startDate: '',
                    year: '',
                    month: ''
                }
            }
        })

    }
    submitmodel = (data) => {
        let { _deviceDetail, getAssetIsPowertrain, getCBMtype } = this.props;
        let type = 1;
        if (getCBMtype === "fan") {
            type = 1;
        }
        if (getCBMtype === "igbt") {
            type = 2;
        }
        if (getCBMtype === "capacitor") {
            type = 3;
        }
        let isSubAsset = ``;
        let url;
        if (getAssetIsPowertrain === true) {
			isSubAsset = `&isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}
        url = `${_deviceDetail.assetId}?unitType=${type}&startDatetime=${data}${isSubAsset}`;

        updatedevicecbmunit(url).then(res => {

            if (res !== undefined) {
                let resdata = res.data;
                message.success("寿命维护设置成功！");
                this.setState({
                    lifedata: {
                        visible: false,
                        cbmunitdata: {
                            startDate: '',
                            year: '',
                            month: ''
                        }
                    },
                    isUpdateform: true
                })
                this.devicecbmunitData(this.state.CBMtype);
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data);
            }
            else {
                console.log("submitmodel--updatedevicecbmunit--接口异常")

                message.error('接口异常')
            }
            this.setState({
                isUpdateform: false
            })
        })


    }
    devicecbmunitData = (type) => {

        let { _deviceDetail, getAssetIsPowertrain } = this.props;
        let _type = 1;
        if (type === "fan") {
            _type = 1;
        }
        if (type === "igbt") {
            _type = 2;
        }
        if (type === "capacitor") {
            _type = 3;
        }
        let isSubAsset = ``;
        let url;
        if (getAssetIsPowertrain === true) {
			isSubAsset = `&isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}
        url = `${_deviceDetail.assetId}?unitType=${_type}${isSubAsset}`;

        devicecbmunit(url).then(res => {

            if (res !== undefined) {
                let resdata = res.data;
                let year = new Date(resdata.startDate).getFullYear();
                let month = new Date(resdata.startDate).getMonth();
                let data = {
                    startDate: moment(resdata.startDate).local().format(dateFormat),
                    year: year,
                    month: month + 1
                }
                this.setState({
                    cbmunitdata: data
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                // message.warning(err.data)
            }
            else {
                message.error('接口异常')
            }
        })
    }
    componentDidMount() {
        this.props.setCBMdata('')
        this.devicecbmunitData(this.state.CBMtype);
    }
   
    
    render() {
        return (
            <div className="lifebox">
                <div className="lifestatus_title">
                    预测性维护
                    <span>（此页面数据于每日上午9:00更新）</span>
                </div>
                
                <ABB.TabControl
                    type="primary"
                    activeTab={this.state.activeTab}
                    className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
                    onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
                    padLeft={false}
                >
                    <ABB.TabItem title="冷却">
                        <Cooling />
                    </ABB.TabItem>
                    <ABB.TabItem title="直流母线电容">
                        <Cooling />
                    </ABB.TabItem>
                    <ABB.TabItem title="变流单元">
                        <Cooling />
                    </ABB.TabItem>
                </ABB.TabControl>
                <div className="charts_button">
                    <ABB.Button
                        onClick={() => this.gotolifesetting()}
                        type="ghost"
                        disabled={this.props.getCBMdata == "" ? true : false}
                        sizeClass='small' theme="filled"
                        text="寿命维护设置"
                        icon="abb/settings"
                    />
                </div>
                <LifeSetting 
                submitmodel={this.submitmodel} 
                hidemodel={this.hidemodel} 
                lifedata={this.state.lifedata}
                isUpdateform = {this.state.isUpdateform}
                />
            </div>
        )
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setCBMdata: data => {
        dispatch(setCBMdata(data))
    },
    setCBMtype: data => {
        dispatch(setCBMtype(data))
    },
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Predictive));

// export default Predictive;