import React from 'react';
import {

    Modal,
    message,
    Row,
    Col,
} from 'antd';
import { activateSubscription } from '@/api/subscription';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class BoundsubConfigForm extends React.Component {
    state = {
        inputval: '',
        valiededCode: '',
        subdata: {},
        disabled: false
    };

    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.setState({
            inputval: ''
        })
    }

    submitmodel = (deviceData) => {
        // this.resboundSub(data)
        console.log('提交')

        this.setState({
            disabled: true
        })
        let params = {
            // `?assetId=${deviceData.deviceId}&subscriptionId=${deviceData.id}&isSubAsset=${true}`
            "assetId": deviceData.deviceId,
            "subscriptionId": deviceData.id,
            "isSubAsset": true
        }

        activateSubscription(params).then(res => {
            message.success("激活订阅成功")
            this.props.submitmodel()
            this.setState({
                disabled: false,
                inputval: ''
            })
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data.errorMessage)
            }
            else {
                message.warning("激活订阅失败")
            }
            this.setState({
                disabled: false
            })
        })

    }

    render() {
        let that = this;
        const { visible, deviceData } = that.props.showmodelboundsubConfig;

        console.log("deviceData", deviceData)
        const modelProps = {
            title: '激活订阅',
            centered: true,
            width: 504,
            maskClosable: false,
            visible: visible,

            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(deviceData)}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="立即激活"
                />,

            ]
        }

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <div className='subconfig_box'>
                        <div>{deviceData.deviceName}（序列号：{deviceData.deviceSrNo}）</div>
                        <div className='sub_list'>
                            <div className='using' style={{ width: '50px' }}>  使用中  </div>
                            <div className="subscribeLevel"> 订阅等级：{deviceData.isUsingSubscriptions.subscriptionLevel} </div>
                            <div> 订阅结束时间：{deviceData.isUsingSubscriptions.expiredDateTime}</div>
                            {/* <div> 订阅结束时间：{item.expiredDateTime === null ? '--' : moment.parseZone(item.expiredDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div> */}
                        </div>

                        <div className='sub_list'>
                            <div className='binding' style={{ width: '50px' }}> 当前 </div>
                            <div className="subscribeLevel"> 订阅等级： {deviceData.subscriptionLevel} </div>
                            <div> 订阅结束时间：{deviceData.expiredDateTime}</div>
                            {/* <div> 订阅结束时间：{item.expiredDateTime === null ? '--' : moment.parseZone(item.expiredDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div> */}
                        </div>
                        <div className="sub_bottom_content">
                            <ABB.Icon className="sub_bottom_icon" name="abb/warning-triangle" sizeClass='small' color='#F03040' />
                            <div className='sub_bottom_title'>当前<span style={{ color: "#3366ff" }}>{deviceData.subscriptionLevel}</span>订阅将会覆盖使用中的<span style={{ color: "#3366ff" }}>{deviceData.isUsingSubscriptions.subscriptionLevel}</span>订阅，确定激活{deviceData.subscriptionLevel}订阅吗？</div>
                        </div>

                    </div>
                </Modal>
            </>

        );
    }
}

export default BoundsubConfigForm;