import React, { Component } from 'react';
import { Modal, Button, Input, message, Table, DatePicker, Select } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import moment from "moment";
import { userCheckDevice, deviceDetail, checkAllgen, eventDetaildevice } from '@/redux/actions/device';
import { setUserInfo } from '@/redux/actions/userInfo';
import { devicereportlist, devicereportdownload } from "@/api/report";
import CreateReport from "./components/createreport";
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import NoData from '@/components/NoData'

const Option = Select;
const div_nowrap = {
    display: 'inline'
}
const { Search } = Input;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD HH:mm:ss';

class ReportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startime: moment().subtract(29, 'day'),
            endtime: moment(new Date(), dateFormat),
            reportlist: [],
            reportdata: {
                visible: false,
            },
            reporttype: [],
            reportlisttype: '',
            reporttime: 1,
            reporttimecopy: 1,
            columns: [

                {
                    title: '编号',
                    dataIndex: 'id',
                    key: 1,
                    render: (text, record, index) => (
                        <div>
                            {index + 1}
                        </div>
                    )
                },
                {
                    title: '报告名称',
                    dataIndex: 'name',
                    key: 2,
                },
                {
                    title: '生成时间',
                    dataIndex: 'createdOn',
                    key: 3,
                    render: (text, record) => (
                        <div>
                            {moment.parseZone(record.createdOn).local().format(dateFormat)}
                        </div>
                    )
                },
                {
                    title: '创建人',
                    dataIndex: 'user',
                    key: 6,
                },
                {
                    title: '报告类型',
                    dataIndex: 'type',
                    key: 4,
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 5,
                    width: 100,
                    render: (text, record) => (
                        <div style={div_nowrap}>
                            {
                                this.statustype(record)
                            }

                        </div >
                    )
                },

            ]
        };

    }
    statustype = (data) => {
        if (data.status === 1) {
            return (
                <div onClick={() => this.Fndownload(data.id)}>
                    <ABB.Icon name="abb/download" sizeClass='small' />
                </div>
            )

        }
        if (data.status === 0) {
            return (
                <span style={{ color: "#36f" }}>正在生成</span>
            )

        }
        if (data.status === -1) {
            return (
                <span style={{ color: "#ff0000" }}>生成失败</span>
            )

        }
    }

    Fndownload = async (id) => {
        const hide = message.loading('正在下载', 0);
        let { _deviceDetail, getAssetIsPowertrain } = this.props;

        let isSubAsset = ``;
        let url;
        if (getAssetIsPowertrain === true) {
            isSubAsset = `?isSubAsset=true`;
        }
        else {
            isSubAsset = ``
        }
        url = `/${_deviceDetail.assetId}/${id}${isSubAsset}`;
        devicereportdownload(url).then(res => {
            let resdata = res.data;
            let blob = resdata.stream;

            this.download(blob, resdata.filename);
            hide();
            message.success('下载成功');
        }).catch(err => {
            hide();
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                // message.error('下载失败')
            }
        })
    }

    // base64转Blob
    dataURLtoBlob = (dataurl) => {
        let aaa = "data:text/plain;base64," + dataurl
        var arr = aaa.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    download = (blobUrl, filename) => {
        let blob = this.dataURLtoBlob(blobUrl);
        let url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.download = filename;
        a.href = url;
        a.click();
        document.body.removeChild(a);
    }
    componentDidMount() {
        let { startime, endtime } = this.state;
        let today = new Date();
        let { getsubscriptioncof } = this.props;
        let _today = moment(today).format("YYYY-MM-DD")
        let _todayminute = moment(today).format("HH:mm:ss")
        let _startime = moment(startime._d).format("YYYY-MM-DD")
        let _endtime = moment(endtime._d).format("YYYY-MM-DD")
        if (_today === _endtime) {
            _endtime = _endtime + " " + _todayminute
        }
        else {
            _endtime = _endtime + " 23:59:59"
        }
        let currentUTCTimeString = moment(_endtime).format("YYYY-MM-DD HH:mm:ss");
        let dateUTCTimeString = moment(_startime).format("YYYY-MM-DD HH:mm:ss");
        let datetime = {
            startime: dateUTCTimeString,
            endtime: currentUTCTimeString
        }
        this.getreportlist(datetime);
        getsubscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 20) {
                    this.setState({
                        reporttime: items.limitValue,
                        reporttimecopy: items.limitValue,
                    })

                }
                if (items.featureCode === 18) {
                    let stringResult = items.limitValue.split(',');
                    let arr = [
                        {
                            name: "全部",
                            id: ''
                        }
                    ];
                    stringResult.map(itemarr => {
                        if (itemarr === "1") {
                            let obj1 = {
                                name: "传动报告",
                                id: 1
                            }
                            arr.push(obj1)
                        }
                        if (itemarr === "2") {
                            let obj2 = {
                                name: "整体运行报告",
                                id: 2
                            }
                            arr.push(obj2)
                        }
                        if (itemarr === "3") {
                            let obj2 = {
                                name: "专家报告",
                                id: 3
                            }
                            arr.push(obj2)
                        }
                    })
                    this.setState({
                        reporttype: arr
                    })
                }
            })

        })
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    getreportlist = (datatime) => {
        //接口数据
        let { _deviceDetail, getAssetIsPowertrain } = this.props;

        let isSubAsset = ``;
        let url;
        if (getAssetIsPowertrain === true) {
            isSubAsset = `&isSubAsset=true`;
        }
        else {
            isSubAsset = ``
        }
        url = `?id=${_deviceDetail.assetId}&startTime=${datatime.startime}&endTime=${datatime.endtime}&type=${this.state.reportlisttype}${isSubAsset}`
        devicereportlist(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                this.setState({
                    reportlist: resdata.reverse()
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("getreportlist -- devicereportlist --接口异常")
                message.error('接口异常')
            }
        })

    }

    backuper = () => {
        let { getassettype, getRouterLinkIndex } = this.props;
        if (getassettype === "PowerTrain") {
            if (getRouterLinkIndex === 2) {
                this.props.userCheckDevice(11)
            } else {
                this.props.userCheckDevice(3)
            }

        }
        else if (getassettype === "noPowerTrain") {
            this.props.userCheckDevice(11)
        }
        else {
            this.props.userCheckDevice(0);
        }
        this.props.eventDetaildevice('0')
        this.props.checkAllgen('0')
    }
    disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(90, 'day');
    onChangeTime = (dates, dateStrings) => {
        let that = this;
        if (dates[0] === undefined || dates[1] === undefined) {
            that.setState({
                startime: '',
                endtime: ''
            }, () => {
                this.getreportlist({
                    startime: '',
                    endtime: ''
                })
            })

        }
        else {
            let _startime = moment(dateStrings[0]).format(dateFormat)
            let _endtime = moment(dateStrings[1]).format(dateFormat)
            let currentUTCTimeString = moment(_endtime).utc().format(dateFormat)
            let dateUTCTimeString = moment(_startime).utc().format(dateFormat);
            this.setState({
                startime: dateUTCTimeString,
                endtime: currentUTCTimeString,
            }, () => {
                this.getreportlist({
                    startime: dateUTCTimeString,
                    endtime: currentUTCTimeString
                })
            })
        }
    }
    reportlisttype = (e) => {
        let { startime, endtime } = this.state;
        let datetime = {
            startime: moment(startime._d).utc().format(dateFormat),
            endtime: moment(endtime._d).utc().format(dateFormat)
        }
        this.setState({
            reportlisttype: e
        }, () => {
            this.getreportlist(datetime)
        })
    }
    gotoreport = () => {
        let { _deviceDetail, getSubid, getassettype } = this.props;
        this.setState({
            reportdata: {
                visible: true,
                assetId: _deviceDetail.assetId,
                orgId: getSubid.orgId,
                isSubAsset: getassettype
            }
        })
    }
    // 组件传值
    hidemodel = (data) => {
        let { startime, endtime } = this.state;
        this.setState({
            reportdata: {
                visible: data
            }
        })
        let datetime = {
            startime: moment(startime._d).utc().format(dateFormat),
            endtime: moment(endtime._d).utc().format(dateFormat)
        }
        setTimeout(() => {
            this.getreportlist(datetime)
        }, 1000)

    }
    changereporttime = (data) => {
        if (data === '') {
            this.setState({
                reporttime: this.state.reporttimecopy
            })
        }
        else {
            this.setState({
                reporttime: data
            })
        }

    }
    render() {
        const that = this;
        let { startime, endtime } = that.state;
        return (
            <div className='deviceDetail_status' style={{ minHeight: "86vh" }}>
                <div className="EUorg_title_back" onClick={() => this.backuper()}>
                    <div className='backup_button'>
                        <ABB.Icon name="abb/left" sizeClass='medium' />报告列表
                        <span style={{ fontSize: 12, color: '#f13040' }}>（生成后的报告将在此列表中保留7天，请及时下载存档。）</span>
                    </div>

                </div>
                <div className="backup_top_box">
                    {/* <div>

                        
                        <RangePicker
                            disabledDate={that.disabledDate}
                            className="model_input"
                            style={{ margin: '0 10px 0 0' }}

                            suffixIcon={
                                <ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
                            }
                            separator='—'
                            defaultValue={[startime, endtime]}

                            // format={dateFormat}
                            allowClear={false}
                            format="YYYY/MM/DD"
                            onChange={this.onChangeTime}
                        // onOk={this.onOk}
                        />
                    </div> */}

                    <Select defaultValue="报告类型"
                        suffixIcon={
                            <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                        }
                        style={{ width: 188 }}
                        onChange={this.reportlisttype}
                    >
                        {
                            this.state.reporttype.map((item, index) => {
                                return (
                                    <Select.Option value={item.id} key={index}>{item.name}</Select.Option>
                                )
                            })
                        }
                        {/* <Select.Option value="">全部</Select.Option>
                        <Select.Option value="1">传动报告</Select.Option>
                        <Select.Option value="2">整体运行报告</Select.Option>
                        <Select.Option value="3">专家报告</Select.Option> */}
                    </Select>
                    <ABB.Button
                        // className="ABB_tab_marginTop"

                        onClick={() => this.gotoreport()}
                        type="primary-blue"
                        sizeClass='small' theme="filled"
                        text="生成报告"
                        icon="abb/plus-in-circle"
                    />
                </div>



                <div style={{ position: 'relative', minHeight: '80vh' }}>
                    {
                        that.state.reportlist.length > 0 ? (
                            <Table

                                size='small'

                                className='org_right'
                                ellipsis={true}
                                columns={that.state.columns}
                                dataSource={that.state.reportlist}
                                pagination={{
                                    pageSize: 13
                                }}
                                rowKey={(record, item) => record.id}
                            />
                        ) : <NoData />
                    }
                </div>
                <CreateReport
                    reporttype={this.state.reporttype}
                    reporttime={this.state.reporttime}
                    hidemodel={this.hidemodel}
                    reportdata={this.state.reportdata}
                    changereporttime={this.changereporttime}
                />
            </div>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    setUserInfo: data => {
        dispatch(setUserInfo(data))
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ReportList));
// export default BackupParameters;
