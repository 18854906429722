import React, { Component } from 'react';
import { Modal,message } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { eventfilterDelete } from '@/api/eventfilter.js';

class RemoweModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disabled: false
		};
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    hidemodeldelete=()=>{
        this.props.hidemodel()
    }
    submitmodel=(id)=>{
        if(this.state.disabled !== true){
            this.setState({
                disabled: true
            })
            let url = `${id}`;
            eventfilterDelete(url).then(res => {
                // if (res !== undefined) {
                    message.success("刪除黑名单规则成功")
                    
                    this.props.submitmodel();
                    this.hidemodeldelete()
                    setTimeout(()=>{
                        this.setState({
                            disabled: false
                        })
                    },1500)
                    
                // }
            }).catch(err => {
                if (err.status === 400 || err.status === 404) {
                    message.warning(err.data);
                    
                } else {
                    console.log("submitmodel -- eventfilterDelete --接口异常")
                    message.error('接口异常');
                }
                this.setState({
                    disabled: false
                })
            });
        }
        
    }
    
	render() {
        const that = this;
        const {showmodelRemove} = this.props;
        const modelProps = {
            title: '删除',
            centered: 'true',
            width: 504,
            maskClosable: false,
            visible: showmodelRemove.visible,
            onOk:() => {that.hidemodeldelete()},
            onCancel:() => {that.hidemodeldelete()},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodeldelete()}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelRemove.blackId)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    disabled={that.state.disabled}
                    text="确定"
                />,        
            ]
        }
		return (
			<Modal 
            {...modelProps} 
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                <div className="remove_model">确定删除当前的过滤规则吗？</div>
            </Modal>
		);
	}
}

export default RemoweModel;
