import { combineReducers } from 'redux';
import { userInfo, getOrgInfo } from './userInfo';
import tagList from './tagList';
import { Usergroup, equipmentdata, getserchUser, getOrginformation, getcustomersList } from './userlist';
import { breadCrumb, tags, theme, collapse, isloading, setlanguage, getIsSignalShowthree, getNewsmodelIsShow, getRouterLinkIndex } from './setting';
import {
    getAssetlabel,
    getBatchImport,
    getAssetIsPowertrain,
    getassettype,
    getassetId,
    getFilterDevice,
    _deviceDetail,
    checkAll,
    eventDetail,
    deviceTrendtime,
    UsercheckDeviceDetail,
    getselect,
    gettree,
    getTopdevice,
    getevent,
    getBatchasset,
    gettreeStatus,
    gettreeSelect,
    gettreeCopy,
    gettreeValue,
    gettrendscode,
    gettrendstag,
    getChartsTime,
    getsubscriptioncof,
    getRadiovalue,
    getResTrendsCode,
    getTrendsData,
    getAssetStatus,
    getAssetIdCopy,
    getAssetDetail,
    getTrendEventcode,
    getTrendEventTag,
    getActiveTab,
    getInspection,

} from './device';
import { getplant, getaddress, getAddplanttype, getaddressChange, getPlantisShow, getAddDeviceProcess } from './plant';
import { getSubscription, getSubid, getSubselect } from './subscription';
import { geteventcount, getEventDetail, getEventDetailStatus, getEventData } from './event';
import { getCBMdata, getCBMtype } from './cbm';
import { getCMDdata } from './cmd';

import { getExpertsConfig, getDebugInformationConfig, getDebugInformationRemindConfig } from '../../views/devicedetails/components/device/store/reducer';
import {
    getContactConfig, getRemindUpdateContactConfig, getDebugingList, getLogoutOrganizationConfig,
    getIndustryList, getShowIndustryList, getShowIndustriesConfig, getEditOrgConfig, getToolEditOrgConfig,
    getToolAssetLocations
} from '../../views/Orgmanagement/store/reducer';


export default combineReducers({

    getAddDeviceProcess,
    getEventData,
    getActiveTab,
    getTrendEventcode,
    getTrendEventTag,
    getAssetDetail,
    getAssetlabel,
    getBatchImport,
    getCMDdata,
    getAssetIdCopy,
    getAssetIsPowertrain,
    getAssetStatus,
    getTrendsData,
    getResTrendsCode,
    getEventDetailStatus,
    getEventDetail,
    getRadiovalue,
    getsubscriptioncof,
    getChartsTime,
    gettrendstag,
    getCBMdata,
    getCBMtype,
    getRouterLinkIndex,
    geteventcount,
    getNewsmodelIsShow,
    getPlantisShow,
    gettreeValue,
    gettreeCopy,
    getaddressChange,
    getcustomersList,
    getAddplanttype,
    gettreeSelect,
    gettreeStatus,
    getIsSignalShowthree,
    gettrendscode,
    getOrginformation,
    getSubselect,
    getBatchasset,
    getassettype,
    getSubid,
    getSubscription,
    getevent,
    getassetId,
    getserchUser,
    getaddress,
    getFilterDevice,
    getTopdevice,
    gettree,
    getplant,
    setlanguage,
    getselect,
    UsercheckDeviceDetail,
    isloading,
    deviceTrendtime,
    eventDetail,
    checkAll,
    _deviceDetail,
    equipmentdata,
    userInfo,
    Usergroup,
    tagList,
    breadCrumb,
    tags,
    theme,
    collapse,
    getInspection,
    getExpertsConfig,
    getContactConfig,
    getDebugInformationConfig,
    getDebugInformationRemindConfig,
    getOrgInfo,
    getRemindUpdateContactConfig,
    getDebugingList,
    getLogoutOrganizationConfig,
    getIndustryList,
    getShowIndustryList,
    getShowIndustriesConfig,
    getEditOrgConfig,
    getToolEditOrgConfig,
    getToolAssetLocations
});
