import React, { Component } from 'react';
import { message } from 'antd';
import { isvaliduser } from '@/api/user.js'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setUserInfo } from '@/redux/actions/userInfo';
import CPindex from './CPindex'
import EUindex from './EUindex'
import ABBindex from './ABBindex'

const CPhomepage_title = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	lineHeight: '40px',
	padding: '0 20px',
	color: '#686868'
}
const CPhomepage_num = {
	lineHeight: '190px',
	fontSize: '50px',
	color: '#1F1F1F',
	textAlign: 'center'
}

class Index extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEUorCP: '',
			
		};
	}
	componentDidMount(){
		this.getUserInfo()
	}
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
    getUserInfo=()=>{
		let that = this
			let userData = that.props.userInfo;
			let newarr = []
			if (userData) {
				newarr.push(userData)
				that.setState({
					isEUorCP: newarr[0].organizationType
				})
			}
	}
	
	FnisEUorCP = () => {
		let self = this;
		if (this.state.isEUorCP === 'EU') {
			return (
				<EUindex/>
			)
		}
		else if (this.state.isEUorCP === 'CP') {
			return (
				<CPindex/>
			)
		}
		else if(this.state.isEUorCP === 'ABB'){
			return (
				<ABBindex/>
			)
		} else {
			setTimeout(() => {
				self.getUserInfo();
			}, 500);
		}
	}
	render() {

		return (
			<div >
				{
						this.FnisEUorCP()
				}
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Index));
// export default Index;
