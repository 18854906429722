import React, { Component } from 'react'
import { message, Table } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { deviceDetail } from '@/redux/actions/device';
import { parametersData } from "@/api/event";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NoData from '@/components/NoData'

const div_nowrap = {
    display: 'inline'
}
class Diagnosticparameters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventloggerlist: [],
            columns: [

                {
                    title: '序号',
                    dataIndex: 'parameterId',
                    key: 1,
                    
                },
                {
                    title: '名称',
                    dataIndex: 'parameterName',
                    key: 2,
                    
                },
                {
                    title: '值',
                    dataIndex: 'value',
                    key: 3,
                    render: (text, record) => (
                        <div>
                            {record.value + record.unit}
                        </div>
                    )
                },


            ],
            feature: ''
        };
    }
    componentDidMount() {
        this.getparametersData();
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    getparametersData = () => {
        //接口数据
        let { getEventDetail } = this.props;
        let url = `${getEventDetail.eventId}`;
        parametersData(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data.data;
                this.setState({
                    eventloggerlist: resdata
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("getparametersData -- parametersData --接口异常")
                message.error('接口异常')
            }
        })

    }
    render() {
        let that = this;
        return (
            <div style={{ position: 'relative', minHeight: '54vh',padding: '12px 0' }}>
                {
                    this.state.eventloggerlist.length > 0 ?(
                        <Table
                            size='small'
                            // scroll={{ y: 200 }}
                            className='org_right'
                            ellipsis={true}
                            columns={that.state.columns}
                            dataSource={that.state.eventloggerlist}
                            pagination={{
                                pageSize: 10
                            }}
                            rowKey={(record, item) => record.parameterId}
                        />
                    ) : <NoData />
            }

            </div>
        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Diagnosticparameters));
// export default Downloadeventlogger;