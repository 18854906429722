import React, { Component } from 'react';
import { Table, message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { APICmdOnlyDevicesListV2, APIAbilityOnlyDevicesListV2 } from '@/api/tools.js';

import { setCMDdata } from '@/redux/actions/cmd';

import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class CMDOnlyDevice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabledata: [],
			pagination: {},
			loading: false,
			selectedRowKeys: [],
			pageSize: 10,
			pageindex: 1,
			abilityPageSize: 10,
			abilityPageIndex: 1,
			selectval: '0',
			cmdDeviceList: [],
			AbilityDeviceList: [],
			columns: [
				{
					title: '编号',
					dataIndex: 'index',
					key: 0,
					width: 80
				},

				{
					title: '设备序列号',
					dataIndex: 'deviceSrNo',
					key: 2,
					width: 100
				}
			],
			abilityColumns: [
				{
					title: '编号',
					dataIndex: 'index',
					key: 0,
					width: 80
				},

				{
					title: '设备序列号',
					dataIndex: 'deviceSrNo',
					key: 2,
					width: 100
				}
			]
		};
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	componentWillMount() {
		this.initdata();
	}

	initdata = () => {
		this.fetch(1, '');
		this.FetchAbilityDevice(1, '');
	};
	fetch = pageindex => {
		let url = `?pagesize=${this.state.pageSize}&pageindex=${pageindex}`;

		APICmdOnlyDevicesListV2(url)
			.then(res => {
				if (res !== undefined) {
					let resdata = [];
					res.data.devices.forEach((item, index) => {
						resdata.push({
							deviceSrNo: item,
							index: index + 1
						});
					});
					this.setState({
						cmdDeviceList: resdata,
						totalDevice: res.data.count
					});
					// return resdata;
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					// message.warning(err.data)
				} else {
					message.error('接口获取失败');
				}
			});
	};

	FetchAbilityDevice = pageindex => {
		let url = `?pagesize=${this.state.abilityPageSize}&pageindex=${pageindex}`;

		APIAbilityOnlyDevicesListV2(url)
			.then(res => {
				if (res !== undefined) {
					let resdata = [];
					res.data.devices.forEach((item, index) => {
						resdata.push({
							deviceSrNo: item,
							index: index + 1
						});
					});
					this.setState({
						AbilityDeviceList: resdata,
						totalAbilityDevice: res.data.count
					});
					// return resdata;
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					// message.warning(err.data)
				} else {
					message.destroy()
					message.error('接口获取失败');
				}
			});
	};

	onChange = e => {
		this.setState({
			pageindex: e
		});
		this.fetch(e, '');
	};
	onChangeAbility = e => {
		console.log(e);
		this.setState({
			abilityPageIndex: e
		});
		this.FetchAbilityDevice(e, '');
	};
	render() {
		let mytablecolumns = this.state.columns;
		let abilityColumns = this.state.abilityColumns;
		let { pageSize, pageindex,abilityPageSize, abilityPageIndex } = this.state;
		// console.log(this.state.cmdDeviceList);
		return (
			<>
				<div className="ABB_tab_marginTop">
					{/* <>
						<div className="EUorg_title">
							<span>CMD设备</span>
						</div>
						<Table size="small" scroll={{ x: 740 }} className="org_right" ellipsis={true} columns={mytablecolumns} dataSource={_self.props.getCMDdata} pagination={false} rowKey={(record, item) => record.id} />
					</> */}

					{this.state.cmdDeviceList.length > 0 ? (
						<>
							<div className="EUorg_title">
								<span>CMD设备</span>
							</div>
							<Table
								size="small"
								// scroll={{ x: 740 }}
								className="org_right"
								ellipsis={true}
								columns={mytablecolumns}
								dataSource={this.state.cmdDeviceList}
								pagination={{
									onChange: page => this.onChange(page),
									pageSize: pageSize,
									current: pageindex,
									total: this.state.totalDevice
								}}
								rowKey={(record, item) => record.index}
							/>
						</>
					) : (
						<div className="renderEmpty">
							<div className="renderEmpty_center">
								<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass="large" />
								<p>暂无数据</p>
							</div>
						</div>
					)}
				</div>
				<div className="ABB_tab_marginTop">
					{/* <>
						<div className="EUorg_title">
							<span>CMD设备</span>
						</div>
						<Table size="small" scroll={{ x: 740 }} className="org_right" ellipsis={true} columns={mytablecolumns} dataSource={_self.props.getCMDdata} pagination={false} rowKey={(record, item) => record.id} />
					</> */}

					{this.state.AbilityDeviceList.length > 0 ? (
						<>
							<div className="EUorg_title">
								<span>ABB Ability Platform设备</span>
							</div>
							<Table
								size="small"
								// scroll={{ x: 740 }}
								className="org_right"
								ellipsis={true}
								columns={abilityColumns}
								dataSource={this.state.AbilityDeviceList}
								pagination={{
									onChange: page => this.onChangeAbility(page),
									pageSize: abilityPageSize,
									current: abilityPageIndex,
									total: this.state.totalAbilityDevice
								}}
								rowKey={(record, item) => record.index}
							/>
						</>
					) : (
						<div className="renderEmpty">
							<div className="renderEmpty_center">
								<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass="large" />
								<p>暂无数据</p>
							</div>
						</div>
					)}
				</div>
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setCMDdata: data => {
		dispatch(setCMDdata(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CMDOnlyDevice));

// export default EUsubscription;
