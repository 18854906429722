import React, { Component } from 'react';
import { Input, Table, Tooltip, message } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { APISubscriptionGroup, APISubscriptionDownload } from '@/api/tools';

const { Search } = Input;
const Tooltip_print = <span>导出订阅Excel</span>;
class CreateRecord extends Component {
	state = {
		subscriptionLevel: [undefined, '高级版', '标准版', '基础版', '入门版'],
		subscriptionType: [undefined, '试用版', '正式版'],
		subscriptionlist: [],
		pagination: {},
		loading: false,
		modal2Visible: false,
		selectedRowKeys: [],
		subscriptionlist_copy: [],
		pageindex: 1,
		pageSize: 10,
		location: '###',
		exportExcelUrl: process.env.REACT_APP_ADMIN_TOOLS_BASE_URL + '/toolApi/v1/subscription/download?',
		columns: [
			{
				title: '数量',
				dataIndex: 'subscriptionCount',
				key: '0',
				width: '5%'
			},
			{
				title: '订阅类型',
				dataIndex: 'subscriptionType',
				key: '1',
				width: '5%'
			},
			{
				title: '订阅等级',
				dataIndex: 'subscriptionLevel',
				key: '2',
				width: '5%'
			},
			{
				title: '适用设备',
				dataIndex: 'deviceType',
				key: '3',
				width: '5%'
			},
			{
				title: '订阅码有效期',
				dataIndex: 'validDateTime',
				key: '4',
				width: '12%'
			},
			{
				title: '订阅服务期',
				dataIndex: 'validPeriodTime',
				key: '5',
				width: '6%'
			},

			{
				title: '生成时间',
				dataIndex: 'generatedDatetime',
				key: '6',
				width: '12%'
			},
			{
				title: '生成人',
				dataIndex: 'generatedUser',
				key: '7',
				width: '9%'
			},
			{
				title: '备注',
				dataIndex: 'comment',
				key: '8',
				width: '40%'
			},
			{
				title: '操作',
				dataIndex: '',
				key: '9',
				width: '4%',
				render: (text, record, index) => {
					return (
						<Tooltip placement="bottom" title={Tooltip_print}>
							<a href={this.state.location} onClick={() => this.Fndownload(index)}>
								<ABB.Icon name="abb/download" sizeClass="small" />
							</a>
						</Tooltip>
					);
				}
			}
		]
	};

	UNSAFE_componentWillMount() {
		this.fetch(1, '');
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}

	exportExcel = (data, index) => {
		let { exportExcelUrl } = this.state;
		let { subscriptionlist_copy } = this.state;

		let record = subscriptionlist_copy.rawData[index];
		this.setState({
			location: `${exportExcelUrl}generatedDatetime=${record.generatedDatetime}&
			validDateTime=${record.validDateTime}&
			validPeriodTime=${record.validPeriodTime}&generatedUser=${record.generatedUser}&
			subscriptionLevel=${record.subscriptionLevel}&subscriptionCount=${record.subscriptionCount}&
			id=${record.id}`
		});
	};
	Fndownload = async id => {
		const hide = message.loading('正在下载', 0);
		let { subscriptionlist_copy } = this.state;

		let record = subscriptionlist_copy.rawData[id];
		let url;

		url = `?generatedDatetime=${record.generatedDatetime}&
		validDateTime=${record.validDateTime}&
		validPeriodTime=${record.validPeriodTime}&generatedUser=${record.generatedUser}&
		subscriptionLevel=${record.subscriptionLevel}&subscriptionCount=${record.subscriptionCount}&
		id=${record.id}`;
		APISubscriptionDownload(url)
			.then(res => {
				let resdata = res.data;
				let obsUrl = resdata.url;

				this.download(obsUrl, resdata.filename);
				hide();
				message.success('下载成功');
			})
			.catch(err => {
				hide();
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data.error);
				} else {
					// message.error('下载失败')
				}
			});
	};

	// base64转Blob
	dataURLtoBlob = dataurl => {
		let aaa = 'data:text/plain;base64,' + dataurl;
		var arr = aaa.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new Blob([u8arr], { type: mime });
	};

	download = (blobUrl, filename) => {
		// let blob = this.dataURLtoBlob(blobUrl);
		// let url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.download = filename;
		a.href = blobUrl;
		a.click();
		document.body.removeChild(a);
	};
	initdata = () => {
		this.fetch(1, '');
	};
	// 搜索数据
	onserch = value => {
		if (value !== '') {
			this.fetch(1, value.replace(/\s*/g, ''));
		} else {
			this.fetch(1, '');
		}
	};
	//接口数据
	fetch = (pageindex, value) => {
		let url = `?pagesize=${this.state.pageSize}&pageindex=${pageindex}&keywords=${value}`;
		APISubscriptionGroup(url)
			.then(res => {
				if (res !== undefined) {
					this.setState({
						subscriptionlist_copy: JSON.parse(JSON.stringify(res.data))
					});

					let resdata = res.data;
					resdata.rawData.forEach((item, index) => {
						item.generatedDatetime = moment.parseZone(item.generatedDatetime).local().format('YYYY-MM-DD HH:mm:ss');
						item.validDateTime = moment.parseZone(item.validDateTime).local().format('YYYY-MM-DD HH:mm:ss');
						item.validPeriodTime = item.validPeriodTime.toString() + '个月';
						item.deviceType = item.deviceType === 2 ? '传动' : '电机';
						item.subscriptionLevel = this.state.subscriptionLevel[item.subscriptionLevel];
						item.subscriptionType = this.state.subscriptionType[item.subscriptionType];
					});
					this.setState({
						subscriptionlist: resdata
					});
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data);
				} else {
					message.error('订阅列表接口异常, ' + err.status + err.message);
				}
			});
	};
	onChange = e => {
		this.setState({
			pageindex: e
		});
		this.fetch(e, '');
	};
	render() {
		let { pageSize, pageindex } = this.state;

		return (
			<>
				<div className="user_box ABB_tab_marginTop">
					<Search placeholder="请输入生成人" onSearch={this.onserch} style={{ width: 190 }} className="search_input" />
				</div>
				<Table
					size="small"
					scroll={{ x: 1140 }}
					columns={this.state.columns}
					dataSource={this.state.subscriptionlist.rawData}
					pagination={{
						onChange: page => this.onChange(page),
						pageSize: pageSize,
						current: pageindex,
						total: this.state.subscriptionlist.totalCount
					}}
					rowKey={(record, index) => index}
				/>
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateRecord));
