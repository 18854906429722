import React, { Component } from 'react';
import Orgtree from '../orgtree/orgtree';
import EquuipmentList from './components/device/devicegroup/equuipmentList';
import EquipmentDetail from './components/device/devicedetail/index';
import { Input,Tooltip } from 'antd';
import { deviceDetail,setselect } from '@/redux/actions/device';
import { setIsSignalShowthree } from '@/redux/actions/setting';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {setFilterDevice} from '@/redux/actions/device';
import '@/assets/css/tree';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import './style.scss';

const { Search } = Input;
const org_box = {
	width: '100%',
	display: 'flex',
	flexFlow: 'row',
	marginTop: '8px',
	marginBottom: '8px'
}

const tree_btn = {
	fontSize: '16px',
	zIndex: '10',
	position: 'absolute',
	top: '10px',
	color: '#1f1f1f',
	right: '4px',
	// lineHeight: '38px',
	// padding: '0 0 0 8px'
}
class Tableuser extends Component {
	constructor(props){
		super(props);
		this.state = {
			orgiShow: 0,
			deviceisShow: 0,
			contentHeight: '',
			org_leftwidth: '',
			org_right: {
				width: '77%',
				flex:1,
				overflowY: 'scroll',
				height: (document.body.clientHeight-124)+"px"
			},
			org_center:{
				display:'block',
				width: '259px',
				height: (document.body.clientHeight-124)+"px",
				background: '#fff',
				// border: '1px solid rgba(219,219,219,1)',
				borderLeft: 'none',
				padding: '10px 0px 10px 0px',
				overflow: 'hidden',
				// overflowY: 'scroll',
				position: 'relative',
				marginRight: '8px'
			},
			org_left: {
				position: 'relative',
				borderRight: '1px solid #BABABA',
				width: "280px",
				overflowY: 'scroll',
				height: (document.body.clientHeight-124)+"px",
				marginRight: '8px'
			},
			org_btn: {
				width: '48px',
				height: (document.body.clientHeight-124)+"px",
				display: 'none',
				background: '#EBEBEB',
				fontSize: '26px', 
				cursor: 'pointer',
			},
			
		}
	}
	
	UNSAFE_componentWillMount() {
        window.addEventListener('resize', this.handleSize);
	}
	// 自适应浏览器的高度
	handleSize = () => {
		this.setState({
			listData:[],
			contentHeight:(document.body.clientHeight-124)+"px",
			org_center:{
				position: 'relative',
				width: '259px',
				height: (document.body.clientHeight-124)+"px",
				background: '#fff',
				// border: '1px solid rgba(219,219,219,1)',
				borderLeft: 'none',
				padding: '10px 0px 10px 0px',
				overflow: 'hidden',
				marginRight: '8px'
				// overflowY: 'scroll'
			},
			org_left: {
				position: 'relative',
				borderRight: '1px solid #BABABA',
				width: "280px",
				overflowY: 'scroll',
				height: (document.body.clientHeight-124)+"px",
				marginRight: '8px'
			},
			org_right: {
				width: '77%',
				flex:1,
				overflowY: 'scroll',
				height: (document.body.clientHeight-124)+"px"
			}
		})
		// this.setState({
		// 	deskHeight:document.body.clientHeight,
		// },()=>{
		// });
	}
	componentWillUnmount() {
		// 移除监听事件
		window.removeEventListener('resize', this.handleSize);
	}
	showdeviceOrorg =()=>{
		
		this.setState({
			org_center:{
				display:'block',
				position: 'relative',
				height: (document.body.clientHeight-124)+"px",
				width: '259px',
				background: '#fff',
				borderLeft: 'none',
				padding: '10px 0',
				overflow: 'hidden',
				marginRight: '8px'
			},
			org_left:{
				position: 'relative',
				width: "280px",
				height: (document.body.clientHeight-124)+"px",
				overflowY: 'scroll',
				borderRight: '1px solid #d8d8d8',
				marginRight: '8px'
			},
			org_btn: {
				display:'none'
			}
		},()=>{
			this.isLeftview(0,0)
		})
	}
	close_equ(){
		const { orgiShow,deviceisShow } = this.state;
		this.setState({
			org_center:{
				display:'none',
			},
			org_btn: {
				width: '48px',
				height: (document.body.clientHeight-124)+"px",
				display: 'block',
				background: '#EBEBEB',
				fontSize: '28px', 
				cursor: 'pointer',
				borderRight: '1px solid rgb(216, 216, 216)'
			},
		})
		
		if(orgiShow === 1 && deviceisShow === 0){
			this.isLeftview(1,1)
		}
		
		if(orgiShow === 0 && deviceisShow === 0){
			this.isLeftview(0,1)
		}
		
	}
	orgTreeShow(){
		const { orgiShow,deviceisShow } = this.state;
		this.setState({
			org_left:{
				position: 'relative',
				width: "280px",
				height: (document.body.clientHeight-124)+"px",
				overflowY: 'scroll',
				borderRight: '1px solid #d8d8d8',
				marginRight: '8px'
			},
			// org_btn: {
			// 	display: 'none',
			// },
		})
		if(orgiShow === 1 && deviceisShow === 1){
			this.isLeftview(0,1)
		}
		
		if(orgiShow === 1 && deviceisShow === 0){
			this.isLeftview(0,0)
			this.setState({
				org_btn: {
					display: 'none',
				},
			})
		}
		
	}
	close_tree(e){
		e.stopPropagation();
		const { orgiShow,deviceisShow } = this.state;
		this.setState({
			org_left:{
				display:'none',
			},
			org_btn: {
				width: '48px',
				height: (document.body.clientHeight-124)+"px",
				display: 'block',
				background: '#EBEBEB',
				fontSize: '28px', 
				cursor: 'pointer',
				borderRight: '1px solid rgb(216, 216, 216)'
			},
		})
		if(orgiShow === 0 && deviceisShow === 1){
			this.isLeftview(1,1)
		}
		
		if(orgiShow === 0 && deviceisShow === 0){
			this.isLeftview(1,0)
		}
	}
	isLeftview=(orgtype,devicetype)=>{
        if(orgtype === 1 && devicetype === 1){
			this.setState({
				deviceisShow: 1,
			    orgiShow: 1
			})
			this.props.setIsSignalShowthree(1)
		}
		if(orgtype === 1 && devicetype === 0){
			this.setState({
				deviceisShow: 0,
			    orgiShow: 1
			})
			this.props.setIsSignalShowthree(2)
		}
		if(orgtype === 0 && devicetype === 1){
			this.setState({
				deviceisShow: 1,
			    orgiShow: 0
			})
			this.props.setIsSignalShowthree(3)
		}
		if(orgtype === 0 && devicetype === 0){
			this.setState({
				deviceisShow: 0,
			    orgiShow: 0
			})
			this.props.setIsSignalShowthree(4)
		}
	}
	showdevice=()=>{
		const { orgiShow,deviceisShow } = this.state;
        this.setState({
			org_center:{
				display:'block',
				position: 'relative',
				height: (document.body.clientHeight-124)+"px",
				width: '259px',
				background: '#fff',
				borderLeft: 'none',
				padding: '10px 0',
				overflow: 'hidden',
				marginRight: '8px'
			}
		})
		if(orgiShow === 1 && deviceisShow === 1){
			this.isLeftview(1,0)
		}
		
		if(orgiShow === 0 && deviceisShow === 1){
			this.isLeftview(0,0)
			this.setState({
				org_btn: {
					display: 'none',
				},
			})
		}
	}
	//设备搜索
	deviceSerchTitle = (e) =>{
		let _self = this;
		const {equipmentdata}=_self.props;
		const _equipmentdata = JSON.parse(JSON.stringify(equipmentdata));//原始数据
		let deviceArr = []
		const value = e;//获取输入值
		_self.props.setselect(1); 
		if(value !== ''){
			let _value = value.toLowerCase().trim()
			//检查原始数据中是否有该内容
			for(let j=0; j < _equipmentdata.length; j++){
				let equipmentdataDevices = _equipmentdata[j];
				let devicelist = []
				for(let i=0;i<equipmentdataDevices.assets.length;i++){
					let device = equipmentdataDevices.assets[i]
					//匹配输入值和json数据里面的值是否相等
					if(
						(device.assetName!==null && device.assetName.toLowerCase().indexOf(_value) !== -1) || 
						(device.assetSrNo!==null && device.assetSrNo.toLowerCase().indexOf(_value)!==-1) || 
						(device.backendIdentifier!==null && device.backendIdentifier.toLowerCase().indexOf(_value)!==-1) || 
						(device.plantName!==null && device.plantName.toLowerCase().indexOf(_value) !==-1)
					){
						let _deviceTag = devicelist.indexOf(device);
						if(_deviceTag === -1){
							//符合要求，push进新数组
							devicelist.push(device) 
						} 
					} 
				}
				//符合要求push进要展示的数组中
				if (devicelist.length > 0) {
					equipmentdataDevices.assets = devicelist
					deviceArr.push(equipmentdataDevices)
				}
			}
			//在redux里面存入搜索后的数据并展示
			_self.props.setFilterDevice(deviceArr)
		}
		else{
			_self.props.setselect(0); 
		}
	}
	onChange = (e) =>{
		let {value} = e.target;
		if(value===''){
			this.props.setselect(0); 
		}
	}
	render() {
		const { org_left,org_center,orgiShow,deviceisShow } = this.state;
		return (
			<div style={org_box}>
				
				<div style={this.state.org_btn}>
					<div style={{margin:"-8px 0 0 15px"}} onClick={this.showdeviceOrorg}>
					<ABB.Icon name="abb/right" sizeClass='small' />
					</div>
				    
					{
						orgiShow === 1 ? (
							<Tooltip placement="right" title={
								<span>组织结构</span>
							}>
								<div className="assetBtnShow" onClick={() => this.orgTreeShow()}>
									<ABB.Icon name="abb/sld-1" sizeClass='small' />
								</div>
							</Tooltip>
						) : ''
					}
					
					{
						deviceisShow === 1 ? (
							<Tooltip placement="right" title={
								<span>设备列表</span>
							}>
								<div className="assetBtnShow" onClick={() => this.showdevice()}>
									<ABB.Icon name="abb/powertrain" sizeClass='small' />
								</div>
							</Tooltip>
						) : ''
					}
					
					
				</div>
				
				<div className='org_tree_scroll' style={org_left} 
				onClick={() => this.showdevice()}>
					<div onClick={(event) => this.close_tree(event)}>
						<ABB.Icon style={tree_btn} name="abb/left" sizeClass='small' />
					</div>
					<Orgtree/>
				</div>
				<div style={org_center} className='org_right'>
					<div className='device_list_position'>
					    
						<div className='asset_name'>
							设备列表
							<div onClick={() => this.close_equ()}>
								<ABB.Icon name="abb/left" sizeClass='small' />
							</div>
						</div>
						{/* <Search style={{ margin: '0 0 8px 9px', width: 240,height:40 }} 
						onSearch={this.deviceSerchTitle} 
						onChange={this.onChange}
						placeholder="设备名称/序列号/位置"/> */}
					</div>
					
				  
					<EquuipmentList/>
				</div>
				<div style={this.state.org_right} className='org_right'>
					<EquipmentDetail/>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	
	deviceDetail: data => {
		dispatch(deviceDetail(data));
	},
	setselect: data =>{
		dispatch(setselect(data))
	},
	setFilterDevice: data =>{
		dispatch(setFilterDevice(data))
	},
	setIsSignalShowthree: data =>{
		dispatch(setIsSignalShowthree(data))
	}
	
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Tableuser));

// export default Tableuser;

