import React, { Component } from 'react';
import * as echarts from 'echarts';
import { deviceDetail, userCheckDevice, settrendscode, settrendstag } from '@/redux/actions/device';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setIsSignalShowthree } from '@/redux/actions/setting';
import './style.scss';
import { trendsrawdata } from "@/api/trends"
import moment from 'moment';
import { Spin, Icon, message } from 'antd';

const dateFormat = 'YYYY/MM/DD HH:mm:ss';
class EchartLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trendscode: this.props.getTrendEventcode,
            options: {},
            trendList: [],
            trendstag: this.props.getTrendEventTag,
            isZoom: true,
            startime: moment.parseZone(this.props.trendstime.startime).local().format(dateFormat),
            endtime: moment.parseZone(this.props.trendstime.endtime).local().format(dateFormat),
            tagColor: this.props.tagColor,
            IsSignalShowthree: this.props.getIsSignalShowthree,
            timestamp: this.props.timestamp,
            rowdatachartsflag: 0,
            nodata: true,
            lodding: false,
            isAmplification:false //图表放大后 恢复缩放 方可点击
        }
    }


    componentDidMount() {
        if (this.state.trendscode.length > 0) {

            this.gettrendsrawdata(this.state.trendscode, {
                startime: moment(this.state.startime).utc().format(dateFormat),
                endtime: moment(this.state.endtime).utc().format(dateFormat),
            })
            window.addEventListener('resize', this.handleSize);
        }
    }
    componentWillUnmount() {
        // 一定要最后移除监听器，以防多个组件之间导致this的指向紊乱
        window.removeEventListener('resize', this.handleSize)
    }
    componentDidUpdate(nextProps, prevState) {

        if (prevState.trendstag !== this.props.getTrendEventTag) {
            let isZoom = false;
            if (Date.parse(this.props.trendstime.endtime) - Date.parse(this.props.trendstime.startime) <= 3600000) {
                isZoom = true
            }
            else {
                isZoom = false
            }
            this.setState({
                isZoom: isZoom,
                trendstag: this.props.getTrendEventTag,
                startime: moment.parseZone(this.props.trendstime.startime).local().format(dateFormat),
                endtime: moment.parseZone(this.props.trendstime.endtime).local().format(dateFormat),
                rowdatachartsflag: 0
            }, () => {
                this.gettrendsrawdata(this.props.getTrendEventcode, this.props.trendstime)
            })
        }
        if (prevState.timestamp !== this.props.timestamp) {
            let isZoom = false;
            if (Date.parse(this.props.trendstime.endtime) - Date.parse(this.props.trendstime.startime) <= 3600000) {
                isZoom = true
            }
            else {
                isZoom = false
            }
            this.setState({
                timestamp: this.props.timestamp,
                isZoom: isZoom,
                startime: moment.parseZone(this.props.trendstime.startime).local().format(dateFormat),
                endtime: moment.parseZone(this.props.trendstime.endtime).local().format(dateFormat),
            }, () => {
                this.gettrendsrawdata(this.props.getTrendEventcode, this.props.trendstime)
            })
        }
        if (prevState.IsSignalShowthree !== this.props.getIsSignalShowthree) {
            this.setState({
                IsSignalShowthree: this.props.getIsSignalShowthree
            }, () => {
                this.showBarChart()
            })
        }
    }
    trendtwodimensionalarr = (a, b, c, d) => {

        // function threeArray(a,b,c){
        var numleng = Math.min(a.length, b.length, c.length, d.length);
        var nweArry = [];
        var mArry = [];
        for (var i = 0; i < numleng; i++) {
            mArry.push(a[i]);
            mArry.push(b[i]);
            mArry.push(c[i]);
            mArry.push(d[i]);

            nweArry.push(mArry);

            mArry = [];
        }
        return nweArry;
        // }
    }
    gettrendsrawdata = (gettrendscode, time) => {
        this.setState({
            lodding: true
        })
        let { _deviceDetail, getAssetIsPowertrain, getEventDetail } = this.props;
        let isZoom = this.state.isZoom;
        let isSubAsset = ``;
        let url;
        let trends = [];
        let signals = []
        if (gettrendscode !== undefined) {
            for (let i = 0; i < gettrendscode.length; i++) {
                if (gettrendscode[i].isSignals === 0) {
                    trends.push(gettrendscode[i].code);
                }
                else {
                    signals.push(gettrendscode[i].code);
                }

            }
        }

        if (getAssetIsPowertrain === true) {
            isSubAsset = `&isSubAsset=true`;
        }
        else {
            isSubAsset = ``
        }
        // url = `?assetId=${_deviceDetail.assetId}${code}&from=${time.startime}&to=${time.endtime}&isZoom=${isZoom}${isSubAsset}`;
        url = {
            "assetId": _deviceDetail.assetId,
            "trends": trends,
            "signals": signals,
            "from": time.startime,
            "to": time.endtime,
            "isSubAsset": getAssetIsPowertrain,
            "isZoom": isZoom
        }
        // console.log("url------------", url)
        // return false;
        let eventTime = moment.parseZone(getEventDetail.eventTime).local().format('MM/DD HH:mm:ss')
        // console.log("eventTime-------", eventTime)
        trendsrawdata(url).then(res => {

            if (res.data.code === 200) {
                let resdata = res.data.data;

                let yAxisdataarr = [];
                let seriesdataarr = [];
                let xAxisdataarr = [];

                let dataArray = resdata.trend;
                let maxArray = []
                if (dataArray.length > 0) {
                    maxArray = dataArray[0].timestamp;
                }
                for (let i = 1; i < dataArray.length; i++) {
                    let tempArray = dataArray[i].timestamp;
                    if (tempArray.length > maxArray.length) {
                        maxArray = tempArray;
                    }
                }
                let mytimeStamp = [];
                let mytimeStamp1 = [];
                // let localTime = moment.parseZone(getEventDetail.eventTime).local()
                // maxArray.push(Date.parse(localTime) / 1000)
                // maxArray.sort()
                // console.log("maxArray-------", maxArray)
                // if (Date.parse(time.endtime) - Date.parse(time.startime) < 3600000) {
                for (let z = 0; z < maxArray.length; z++) {
                    // mytimeStamp.push(moment.parseZone(parseInt(maxArray[z] * 1000)).local().format('MM/DD HH:mm:ss'))
                    mytimeStamp.push(maxArray[z] * 1000)
                }
                for (let z = 0; z < maxArray.length; z++) {
                    mytimeStamp1.push(moment.parseZone(parseInt(maxArray[z] * 1000)).local().format('MM/DD HH:mm:ss'))
                    // mytimeStamp.push(maxArray[z] * 1000)
                }
                let options = {
                    title: {
                        text: '',
                        left: 15,
                        align: 'right',
                        textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                            fontSize: 14,
                            color: '#1F1F1F'
                        },
                    },
                    grid: {
                        top: '60px',
                        left: '40px',
                        right: '50px',
                        bottom: '10px',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        backgroundColor: 'rgba(255,255,255,0.8)',//通过设置rgba调节背景颜色与透明度
                        color: 'black',
                        borderWidth: '1',
                        borderColor: 'gray',
                        textStyle: {
                            color: 'black',
                        },
                        // axisPointer: {
                        //     type: "none"
                        // },
                        formatter: function (params) {
                            let htmlStr = '';
                            // console.log("params[0]---",params[0].axisValueLabel)
                            htmlStr += '<div><span style="color:#1f1f1f;">' + params[0].axisValueLabel + '</span><br/> ';

                            // 缓存用于记录
                            const cache = params;

                            for (let i = 0; i < cache.length; i++) {
                                if (cache[i].seriesName !== "time") {
                                    htmlStr += '<div style="display: flex; justify-content: space-between; fontFamily: ABBvoiceCNSG-Regular"><span style="color:#1f1f1f;"><span style="width: 8px;height: 8px;display:inline-block;border-radius: 50%;background:' + cache[i].color + '"></span>&nbsp;' + cache[i].seriesName + ':&nbsp;</span>' +
                                        '<span style="color:#1f1f1f;">' + cache[i].value[3] + '/' + cache[i].value[1] + '/' + cache[i].value[2] + '</span></div>';

                                }

                            }
                            htmlStr += '</div>';
                            return htmlStr;
                        },
                    },
                    toolbox: {
                        show: true,
                        feature: {
                            dataZoom: {
                                // show: false,
                                yAxisIndex: "none",
                                icon: {
                                    zoom: "path://M14.0343806,8.04813286 L11.0412567,8.04813286 L11.0412567,11.0412567 L8.04813286,11.0412567 L8.04813286,14.0343806 L11.0412567,14.0343806 L11.0412567,17.0275045 L14.0343806,17.0275045 L14.0343806,14.0343806 L17.0275045,14.0343806 L17.0275045,11.0412567 L14.0343806,11.0412567 L14.0343806,8.04813286 L14.0343806,8.04813286 Z M29,26.0068761 L20.5593907,17.5662668 C23.1336593,13.4686605 22.2196876,8.09123718 18.4361858,5.07422697 C14.652684,2.05721677 9.20672895,2.36315733 5.78494314,5.78494314 C2.36315733,9.20672895 2.05721677,14.652684 5.07422697,18.4361858 C8.09123718,22.2196876 13.4686605,23.1336593 17.5662668,20.5593907 L26.0068761,29 L29,26.0068761 Z M5.05500898,12.5378187 C5.05500898,8.40517699 8.40517699,5.05500898 12.5378187,5.05500898 C16.6704604,5.05500898 20.0206284,8.40517699 20.0206284,12.5378187 C20.0206284,16.6704604 16.6704604,20.0206284 12.5378187,20.0206284 C8.40517699,20.0206284 5.05500898,16.6704604 5.05500898,12.5378187 L5.05500898,12.5378187 Z",
                                    back: "path://M25,19 C25,15.21 22.06,12 18.85,12 L11,12 L15,15.8 L13,18 L5,10.5 L13,3 L15,5.2 L11,9 L18.85,9 C23.69,9 28,13.58 28,19 C28,24.42 23.69,29 18.85,29 L7,29 L7,26 L18.85,26 C22.06,26 25,22.79 25,19 Z"
                                },
                                iconStyle: {
                                    color: "#1F1F1F",
                                    borderWidth: 0,
                                    borderColor: "#1F1F1F",
                                    opacity: 0
                                },
                                emphasis: {
                                    iconStyle: {
                                        color: "#3366ff",
                                        borderColor: "#3366ff"
                                    }
                                },
                                title: {
                                    zoom: "区域缩放",
                                    back: "区域缩放还原"
                                }
                            },

                            restore: {
                                show: false,
                                title: "还原",
                                emphasis: {
                                    color: '#3366ff'
                                },
                                iconStyle: {
                                    opacity: 0
                                }
                            },
                        },
                        top: 0,
                        right: '50px',

                    },
                    //x轴数据
                    xAxis: [],
                    yAxis: [],
                    series: []
                }

                if (resdata.trend.length < 1) { //如果没有线,显示暂无数据
                    this.setState({
                        nodata: true
                    })
                } else {
                    resdata.trend.map((item, index) => {
                        if (item.avg.length <= 0) {

                        }
                        else {

                            // let itemarr = [item.min, item.max, item.avg];


                            let values = []
                            let isShowSymbol = false;
                            let itemarr = []
                            if (Date.parse(time.endtime) - Date.parse(time.startime) <= 3600000) {
                                if (item.value.length === 1) {
                                    isShowSymbol = true
                                }
                                values = item.value;
                            }
                            else {
                                if (item.avg.length === 1) {
                                    isShowSymbol = true
                                }
                                values = item.avg;
                            }

                            if (isShowSymbol === true) {
                                itemarr = this.trendtwodimensionalarr(mytimeStamp1, values, item.min, item.max)
                            }
                            else {
                                itemarr = this.trendtwodimensionalarr(mytimeStamp, values, item.min, item.max)

                            }

                            let newvalues = []
                            for (let i = 0; i < values.length; i++) {
                                if (values[i] !== '-') {
                                    newvalues.push(values[i]);
                                }
                            }

                            let maxNumber = Math.max(...newvalues);
                            let minNumber = Math.min(...newvalues);

                            let intervalNumber = 0;

                            if (maxNumber >= 1 || maxNumber === 0 || maxNumber < -1) {

                                maxNumber = Math.ceil(maxNumber);
                                maxNumber = Math.ceil(maxNumber / 10) * 10;

                                // intervalNumber = Math.ceil(Math.max(...values) / 10);

                            } else if (maxNumber > 0 && maxNumber < 1) {



                                let temp = maxNumber;
                                let number = 0;
                                for (let i = 0; temp < 1; i++) {
                                    number = i;

                                    temp = temp * 10;

                                }

                                maxNumber = Math.ceil(temp);


                                // intervalNumber =  (temp + 1) * 100  / 10 /(Math.pow(10,number + 3) );

                                maxNumber = maxNumber / (Math.pow(10, number + 1));


                            } else if (maxNumber < 0 && maxNumber > -1) {

                            }

                            if (minNumber >= 1 || minNumber === 0 || minNumber < -1) {

                                minNumber = Math.floor(minNumber);
                                minNumber = Math.floor(minNumber / 5) * 5;

                                // intervalNumber = Math.ceil(Math.max(...values) / 10);

                            } else if (minNumber > 0 && minNumber < 1) {
                                let temp = minNumber;
                                let number = 0;
                                for (let i = 0; temp < 1; i++) {
                                    number = i;

                                    temp = temp * 10;

                                }

                                minNumber = Math.floor(temp);


                                // intervalNumber =  (temp + 1) * 100  / 10 /(Math.pow(10,number + 3) );

                                minNumber = maxNumber / (Math.pow(10, number + 1));


                            } else if (minNumber < 0 && minNumber > -1) {

                            }

                            if (maxNumber > 1 && minNumber > 1) {

                                intervalNumber = (maxNumber - minNumber) / 10
                            } else {
                                intervalNumber = intervalNumber = (maxNumber - minNumber) / 10;
                            }
                            if ((maxNumber < 1 && maxNumber > -1) && minNumber < -1) {
                                if (maxNumber >= 0) {
                                    maxNumber = 0;
                                } else {
                                    maxNumber = 1;
                                }
                            }

                            if ((minNumber < 1 && minNumber > -1) && maxNumber > 1) {
                                if (minNumber >= 0) {
                                    minNumber = 0;
                                } else {
                                    minNumber = -1;
                                }
                            }

                            if ((maxNumber === 0 && minNumber === 0) || minNumber === maxNumber) {
                                maxNumber = 1;
                                minNumber = -1;
                                intervalNumber = (maxNumber - minNumber) / 10;
                            }
                            let xAxisdata = {
                                type: isShowSymbol ? 'category' : 'time',
                                boundaryGap: false,
                                axisLine: {
                                    lineStyle: {
                                        color: '#BABABA'
                                    }
                                },
                                axisLabel: {
                                    rotate: 45,
                                    textStyle: {
                                        color: '#686868',
                                        fontSize: '11'
                                    },
                                    showMinLabel: true,
                                    formatter: function (value, index) {
                                        if (isShowSymbol === false) {
                                            if (Date.parse(time.endtime) - Date.parse(time.startime) < 3600000) {
                                                return moment(value).format('MM/DD HH:mm:ss')

                                            }
                                            return moment(value).format('MM/DD HH:mm')
                                        }
                                        else {
                                            return value

                                        }

                                    }
                                },
                                position: 'bottom'
                            }
                            let yAxisdata = {
                                type: 'value',
                                axisLine: {       //y轴
                                    show: false,
                                    fontFamily: 'ABBvoiceCNSG-Regular',
                                    lineStyle: {
                                        color: '#BABABA'
                                    }
                                },
                                min: minNumber,
                                max: maxNumber,
                                interval: intervalNumber,
                                //  改变x轴字体颜色和大小  
                                axisLabel: {
                                    // formatter: '{value} ' + item.config.unit,
                                    formatter: function (value) {
                                        if (value.toString().length > 6) {
                                            return value.toFixed(4) + item.config.unit;
                                        }
                                        else {
                                            return value + item.config.unit;
                                        }
                                    },
                                    textStyle: {
                                        color: '#686868',
                                        fontSize: '12'
                                    },
                                    interval: 0,
                                },
                                // lineHeight: 56,
                                // length:5,
                                axisTick: {       //y轴刻度线
                                    show: false
                                },
                                // data:["0.001","0.002","0.003","0.004","0.005","0.006"]
                            }
                            let tempcode = item.code;
                            
                            let color = ''
                            let _trendscode = this.props.getTrendEventcode
                            for (let i = 0; i < _trendscode.length; i++) {
                                if(_trendscode[i].code.toLowerCase() === tempcode.toLowerCase()){
                                    color = this.state.tagColor[i]
                                    break;
                                }
                            }
                            let seriesdata = {
                                name: "" + item.config.label + "(" + item.config.unit + ")",
                                type: 'line',
                                itemStyle: {

                                    normal: {
                                        color: color,
                                        lineStyle: {
                                            color: color,
                                            width: 1// 0.1的线条是非常细的了
                                        }
                                    }
                                },
                                // symbol: "none",
                                showSymbol: isShowSymbol,
                                // symbolSize: 2,
                                // symbol: 'circle',
                                connectNulls: true,
                                data: itemarr,
                                yAxisIndex: index,
                                xAxisIndex: index,
                                markLine: {
                                    lineStyle: {
                                        width: 2,
                                        color: '#1f1f1f',
                                        type: 'solid'
                                    },
                                    label: {
                                        position: 'top',
                                        padding: [0, 0, 5, 0],
                                        // left: 8,
                                        normal: {
                                            formatter: eventTime // 这儿设置安全基线

                                        }
                                    },

                                    silent: true, // 鼠标悬停事件, true悬停不会出现实线
                                    symbol: 'none', // 去掉箭头
                                    data: [{
                                        xAxis: getEventDetail.eventTime
                                    },]
                                },
                            }
                            // if (maxNumber !== 0) {
                            if (index === 1) {
                                options.grid.right = "50px";
                                yAxisdata.offset = 0;
                            }
                            if (index === 2) {
                                options.grid.right = "50px";
                                yAxisdata.offset = 75;
                            }
                            if (index === 3) {
                                options.grid.right = "50px";
                                yAxisdata.offset = 150;
                            }
                            if (index === 4) {
                                options.grid.right = "50px";
                                yAxisdata.offset = 220;
                            }

                            yAxisdataarr.push(yAxisdata);
                            seriesdataarr.push(seriesdata)
                            xAxisdataarr.push(xAxisdata)

                            // item.trendcharts = options;
                        }
                    })

                    options.yAxis = yAxisdataarr;
                    options.xAxis = xAxisdataarr;
                    // console.log("options----", options)
                    if (seriesdataarr.length > 0) {
                        options.series = seriesdataarr;
                        this.setState({

                            options: options,
                            trendList: resdata,
                            nodata: false,
                        }, () => {

                            this.showBarChart()
                        })
                    } else {  //显示暂无数据
                        this.setState({
                            nodata: true
                        })
                    }
                }
                this.setState({
                    lodding: false
                })
            }
        }).catch(err => {
            // message.warning("errerrerrerrerr-----------")
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('接口异常，请稍后再试！')
            }
            this.setState({
                lodding: false,
                nodata: true
            })

        })
    }
    showBarChart = () => {
        let that = this;
        let charts = echarts.init(
            document.getElementById('charts'), null, { renderer: 'svg' }
        )
        charts.clear();
        charts.setOption({}, true);
        // debugger;
        charts.setOption(that.state.options, true);
        // debugger;
        charts.dispatchAction({
            type: "takeGlobalCursor",
            key: 'dataZoomSelect',
            dataZoomSelectActive: true
        });
        charts.on("dataZoom", params => {
            let time = {};

            let dataArray = this.state.trendList.trend;
            let maxArray = []
            if (dataArray.length > 0) {
                maxArray = dataArray[0].timestamp;
            }
            for (let i = 1; i < dataArray.length; i++) {
                let tempArray = dataArray[i].timestamp;
                if (tempArray.length > maxArray.length) {
                    maxArray = tempArray;
                }
            }
            time.startime = moment(params.batch[0].startValue).utc().format(dateFormat);
            time.endtime = moment(params.batch[0].endValue).utc().format(dateFormat);

            this.setState({
                startime: moment.parseZone(time.startime).local().format(dateFormat),
                endtime: moment.parseZone(time.endtime).local().format(dateFormat),
                isAmplification:true
            })
            if (Date.parse(time.endtime) - Date.parse(time.startime) < 3600000) {
                if (this.state.rowdatachartsflag === 0) {
                    this.setState({
                        isZoom: true
                    }, () => {
                        this.gettrendsrawdata(this.props.getTrendEventcode, time)
                    })
                }
                this.setState({
                    rowdatachartsflag: 1
                })
                let that = this;
                setTimeout(() => {
                    that.setState({
                        rowdatachartsflag: 0
                    })
                }, 600);

            }
            else {
                if (this.state.rowdatachartsflag === 0) {
                    this.setState({
                        isZoom: false
                    }, () => {
                        this.gettrendsrawdata(this.props.getTrendEventcode, time)
                    })
                }
                this.setState({
                    rowdatachartsflag: 1
                })
                let that = this;
                setTimeout(() => {
                    that.setState({
                        rowdatachartsflag: 0
                    })
                }, 600);
            }

        });

        charts.resize();

    }

    // 自适应浏览器的高度
    handleSize = () => {
        this.showBarChart();
    }
    restore = () => {
        if (!this.state.isAmplification) {
            return
        }

        let isZoom = false;
        if (Date.parse(this.props.trendstime.endtime) - Date.parse(this.props.trendstime.startime) <= 3600000) {
            isZoom = true
        }
        else {
            isZoom = false
        }
        this.setState({
            startime: moment.parseZone(this.props.trendstime.startime).local().format(dateFormat),
            endtime: moment.parseZone(this.props.trendstime.endtime).local().format(dateFormat),
            isZoom: isZoom,
            rowdatachartsflag: 0,
            isAmplification:false
            // nodata: false
        }, () => {
            this.gettrendsrawdata(this.props.getTrendEventcode, this.props.trendstime);
        })
    }

    render() {
        let { startime, endtime } = this.state;
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />
        return (
            <>
                <div className="chartstime">{startime} —— {endtime}</div>
                <div className="chartstime1">所选时间段小于60分钟时可显示原始数据</div>
                <div className="chartsdetail">
               
                    <span className= {this.state.isAmplification === true ? "restorebuttonDetail" : "restorebuttonDetail_Unenable"} onClick={() => this.restore()}>
                        恢复缩放
                    </span>
                    <div style={{ height: '100%', width: '100%' }} id="charts"></div>

                    {
                        this.state.nodata === false ? (
                            <div style={{ display: "none" }} ></div>
                        ) : <div className="nodata">暂无数据</div>
                    }
                    {
                        this.state.lodding ? (
                            <div className="chartsLodding">
                                <Spin indicator={antIcon} />

                            </div>
                        ) : <div style={{ display: "none" }} ></div>
                    }
                </div>


            </>

        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setIsSignalShowthree: data => {
        dispatch(setIsSignalShowthree(data))
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    deviceDetail: data => {

        dispatch(deviceDetail(data));
    },
    settrendscode: data => {

        dispatch(settrendscode(data));
    },
    settrendstag: data => {

        dispatch(settrendstag(data));
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EchartLine));