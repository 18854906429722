import * as actionTypes from '../constants/index';

const setUserInfo = (data) => {
    return {
        type: actionTypes.SET_USERINFO,
        data
    }
};
const setOrgInfo = (data) => {
    return {
        type: actionTypes.MAIN_ORGINFO,
        data
    }
};


export {setUserInfo,setOrgInfo};
