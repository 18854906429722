
import React, { Component } from 'react';
import { message } from 'antd';
import '@/assets/css/tree';
import EUuser from './components/user/EUuser';
import APIauth from './components/user/APIauth'
import OrgInformation from './components/org/OrgInformation'
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo'
import { setOrginformation } from '@/redux/actions/userlist'
import { withRouter } from 'react-router-dom';
import { orgInfo } from '@/api/orgtree.js';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import './components/user/style.scss'
import { getBindSSOrganization,isvaliduser } from '@/api/user'

class Orgmanagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ssName: '',
            activeTab: 0
        }
    }


    callback(newIndex) {

        this.setState({ activeTab: newIndex })
        if (newIndex === 1) {
            this.fetch()
            this.child.getOrgData();
            if(this.props.userInfo.organizationType === "EU"){
                this.getBindStatus();
            }
        }
        if(newIndex === 2){
            this.child1.getOrgData();
        }
    }
    fetch = () => {
        let url = `/` + this.props.userInfo.orgId;
        orgInfo(url).then(res => {
            if (res !== undefined) {
                let data = res.data;
                let addr = data.province + "， " + data.city + "， " + data.district + "， " + data.address;

               
                this.props.setOrginformation(data)
              }
        }).catch(err => {
            if (err.status === 400) {
                message.warning(err.data)
            }
            else if (err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('组织信息获取失败！')
            }

        })
    }
    onRef = (ref) => {
        this.child = ref
    }
    onRef1 = (ref) => {
        this.child1 = ref
    }
    getBindStatus = () => {
        getBindSSOrganization(this.props.userInfo.orgId).then(res => {
            this.setState({ ssName: res.data.ssOrgName });
        }).catch(err => {
            if (err.status === 400) {
                this.setState({ ssName: null });
            }
            else if (err.status === 404) {
                this.setState({ ssName: null });
            }
        })
    }
    componentDidMount(){
        this.userInfo()
    }
    userInfo() {
		let url = ``;
		isvaliduser(url)
			.then(res => {
				if (res !== undefined) {
					localStorage.setItem('userInfo', JSON.stringify(res.data));
					
					let userInfo = res.data;
					
					if (userInfo) {

						this.props.setUserInfo(userInfo);
						
					} else {
						this.props.history.push('/login');
					}
				}
			})
			.catch(err => {
				// message.warning('获取用户信息失败！无法正常访问。');
				// setTimeout(() => {
				// 	this.props.history.push('/login');
				// }, 1500);
			});
	}
    isType = () => {
        let {userInfo} = this.props;
        if (userInfo.isGrandDevCenter === true && userInfo.isOrgAdministrator ===true) {
            return (
                <ABB.TabControl
                    type="primary"
                    activeTab={this.state.activeTab}
                    className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
                    onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
                    padLeft={false}
                >
                    <ABB.TabItem title="用户组">
                        <EUuser />
                    </ABB.TabItem>
                    <ABB.TabItem title="组织信息">
                        <OrgInformation ssName={this.state.ssName} onRef={this.onRef} />
                    </ABB.TabItem>
                    <ABB.TabItem title="API访问权限">
                        <APIauth onRef={this.onRef1}/>
                    </ABB.TabItem>

                </ABB.TabControl>
            )
        }
        else {
            return (
                <ABB.TabControl
                    type="primary"
                    activeTab={this.state.activeTab}
                    className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
                    onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
                    padLeft={false}
                >
                    <ABB.TabItem title="用户组">
                        <EUuser />
                    </ABB.TabItem>
                    <ABB.TabItem title="组织信息">
                        <OrgInformation ssName={this.state.ssName} onRef={this.onRef}/>
                    </ABB.TabItem>
                </ABB.TabControl>
            )
        }
    }
    render() {
        return (
            <div className='shadow-radius'>
                <div className='org_right'>
                    {
                        this.isType()
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setUserInfo: data => {
        dispatch(setUserInfo(data))
    },
    setOrginformation: data => {
        dispatch(setOrginformation(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Orgmanagement));
