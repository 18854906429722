import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import './style.scss'
import { List, message, Spin } from 'antd'
import * as ABB from 'abb-common-ux-react';
import { APIInspection } from "@/api/device"
import { setInspection } from '@/redux/actions/device';
import moment from 'moment';
import { APICreateInspection, APIRefreshInspection } from '../../../../../../../api/device';
import { _deviceDetail } from '../../../../../../../redux/reducers/device';

let api = null

class DataInspection extends Component {
	constructor(props) {
		super(props)
		this.state = {
			requestTime: 0,
			assetId: this.props._deviceDetail.assetId,
			_defaultInspection: {
				dataLifespan: '',
				inspections: [],
				timestamp: "",
				disabled: true,
				isLoading: false,
				isRequestFail: false,
				isRequestSuccess: false,
				errorMessage: "",
				disabled: false,
				requestTime: 0
			}
		};
	}

	componentDidMount() {




		//默认加载巡检数据
		var deviceDetail = this.props._deviceDetail
		console.log("DataInspection---------", deviceDetail)

		//版本号大于6.0.5请求数据
		if (deviceDetail.gatewaySupportInspection === true) {
			this._requestData()
		}
	}
	componentWillUnmount() {

		// this.setState({
		// 	requestTime: 0
		// })

		this.stopTask = 1;

	}
	// componentDidUpdate(nextProps, prevState) {
	// 	// console.log("prevState.assetId",prevState.assetId)
	// 	// 	console.log("this.props._deviceDetail.assetId",nextProps._deviceDetail.assetId)
	//     if (prevState.assetId !== nextProps._deviceDetail.assetId) {

	//         this.setState({
	//             assetId: this.props._deviceDetail.assetId,
	// 			requestTime: 0
	//         })

	//     }
	// }

	_requestData = () => {
		let that = this
		let { getAssetIsPowertrain, getassetId } = this.props;
		let getInspection = this.state._defaultInspection
		let isSubAsset = getAssetIsPowertrain === true ? `true` : 'false';

		APIInspection(getassetId, isSubAsset).then(res => {


			if (res !== undefined) {
				let resdata = res.data;
				if (resdata.code === 200) {

					let requestData = resdata.data
					let refreshData = { ...requestData }
					let inspectionsTemp = refreshData.inspections

					if (inspectionsTemp.length % 2 === 1) {
						inspectionsTemp.push({
							"parameterId": "",
							"parameterName": "",
							"value": "",
							"unit": ""
						})
					}
					//每一行显示两条数据,数据由一维数组转为二维数组
					let newInspectionsList = []
					for (let i = 0; i < inspectionsTemp.length / 2; i++) {
						const t = inspectionsTemp.slice(i * 2, (i + 1) * 2)
						newInspectionsList.push(t)
					}

					refreshData.inspections = newInspectionsList;
					let inspection = { ...getInspection, ...refreshData }

					let disabled = this._reloadDataEnable(inspection)
					inspection.disabled = disabled
					this.setState({
						_defaultInspection: inspection
					})

				}
			}
		}).catch(err => {
			if (err.data.status === 400 || err.data.status === 404) {
				message.warning(err.data.title)
			} else {
				message.error('操作失败')
			}
		})
	}

	//获取 读取实时数据 按钮状态  true:可以交互  false:不可以交互
	_reloadDataEnable = (getInspection) => {


		const { _deviceDetail } = this.props
		console.log("_deviceDetail-----------_reloadDataEnable", _deviceDetail)
		if (getInspection.isLoading) {
			return false
		}
		if (_deviceDetail.connectedStatus !== 1) {
			return false
		}
		if (_deviceDetail.gatewaySupportInspection === false) {
			return false
		}
		return true
	}

	//创建轮询数据任务
	_createInspectionTask = () => {
		let that = this
		let requestTime = 1


		if (that.state.requestStop === true) {
			return
		}
		var inspection = { ...that.state._defaultInspection }

		inspection.isLoading = true


		let disabled = that._reloadDataEnable(inspection)
		inspection.disabled = disabled
		inspection.requestTime = requestTime

		this.setState({
			_defaultInspection: inspection
		})


		let { getAssetIsPowertrain, getassetId } = that.props;
		let getInspection = that.state._defaultInspection
		let isSubAsset = getAssetIsPowertrain === true ? `true` : 'false';


		that.stopTask = 0

		APICreateInspection(getassetId, isSubAsset).then(res => {
			console.log("终止轮询------stopTask----", that.stopTask)
			if (that.stopTask !== 0) {
				console.log("终止轮询----------")
				return
			}


			console.log("APICreateInspection=====================", res)


			let resdata = res.data;
			if (resdata.code === 200) {
				that._refreshInspection(resdata.data, requestTime)
			} else {
				var inspection = { ...that.state._defaultInspection }
				inspection.isLoading = false

				let disabled = that._reloadDataEnable(inspection)
				inspection.disabled = disabled
				that.setState({
					_defaultInspection: inspection
				})
			}
		}).catch(err => {

			var inspection = { ...that.state._defaultInspection }
			inspection.isLoading = false
			inspection.isRequestFail = true

			if (err.data.code === 400 || err.data.code === 404) {
				inspection.errorMessage = err.data.errorMessage
			} else {
				inspection.errorMessage = "读取失败"
			}
			let disabled = that._reloadDataEnable(inspection)
			inspection.disabled = disabled
			that.setState({
				_defaultInspection: inspection
			})
		})
	}

	//轮询数据加载
	_refreshInspection = (taskId, requestTime) => {
		let that = this

		APIRefreshInspection(taskId).then(res => {

			let resdata = res.data;
			if (resdata.code === 200) {
				let requestData = resdata.data

				//如果数据为空
				if (requestData.loadDataSuccess === true) {
					//渲染数据

					let refreshData = { ...requestData }
					let inspectionsTemp = refreshData.inspections

					if (inspectionsTemp.length % 2 === 1) {
						inspectionsTemp.push({
							"parameterId": "",
							"parameterName": "",
							"value": "",
							"unit": ""
						})
					}
					//每一行显示两条数据,数据由一维数组转为二维数组
					let newInspectionsList = []
					for (let i = 0; i < inspectionsTemp.length / 2; i++) {
						const t = inspectionsTemp.slice(i * 2, (i + 1) * 2)
						newInspectionsList.push(t)
					}

					refreshData.inspections = newInspectionsList;

					let inspection = { ...that.state._defaultInspection, ...refreshData }
					inspection.isLoading = false
					inspection.isRequestSuccess = true
					let disabled = that._reloadDataEnable(inspection)
					inspection.disabled = disabled

					that.setState({
						_defaultInspection: inspection
					})

				} else {
					//下一次轮询
					setTimeout(() => {
						that._refreshInspection(taskId, requestTime)
					}, 5000)
				}

			} else {

				var inspection = { ...that.state._defaultInspection }
				inspection.isLoading = false
				inspection.isRequestFail = true
				inspection.errorMessage = "读取失败"
				let disabled = that._reloadDataEnable(inspection)
				inspection.disabled = disabled

				that.setState({
					_defaultInspection: inspection
				})
			}

		}).catch(err => {

			if (err.data.code === 400) {
				var inspection = { ...that.state._defaultInspection }

				inspection.isLoading = false
				inspection.isRequestFail = true
				inspection.errorMessage = err.data.errorMessage
				let disabled = that._reloadDataEnable(inspection)
				inspection.disabled = disabled
				that.setState({
					_defaultInspection: inspection
				})

			} else {

				var inspection = { ...that.state._defaultInspection }
				inspection.isLoading = false
				inspection.isRequestFail = true
				inspection.errorMessage = "读取失败"
				let disabled = that._reloadDataEnable(inspection)
				inspection.disabled = disabled

				that.setState({
					_defaultInspection: inspection
				})

			}
		})

	}


	// 提示信息
	/**
	 * 设备离线:当前设备离线无法执行此操作，请在设备在线后操作。
	 *  getewaySupportInspection: false : 代表网关版本低于6.05提示""当前设备的固件版本低于6.05无法执行此操作，请将固件版本升级至6.05以上后操作。"
	 *  getewaySupportInspection: true: 代表巡检功能可用 
	 * loadDataSuccess : true  表示有数据，提示巡检时间
	 *  loadDataSuccess : false 表示无数据，提示仅显示生命周期内的数据
	 * */
	_remindMessage = () => {
		const { _deviceDetail } = this.props
		let getInspection = this.state._defaultInspection

		console.log("_deviceDetail-----------_remindMessage", _deviceDetail)
		if (getInspection.isLoading === true) {
			//正在加载数据
			return <div className='contentCenter'>
				<div className='loading_icon' style={{cursor: 'text'}}></div>
				<div style={{ color: '#3366FF' }}>数据巡检中，请等待设备返回数据...</div>
			</div>
		}

		if (getInspection.isRequestFail === true) {
			return <div className='contentCenter'>
				<ABB.Icon style={{ color: '#ff0000', marginRight: '5px' ,cursor: 'text'}} name="abb/error-circle-2" sizeClass="small" />
				<div style={{ color: '#ff0000' }}>数据读取失败，失败原因：{getInspection.errorMessage}</div>
			</div>
		}

		if (getInspection.isRequestSuccess === true) {
			return <div className='contentCenter'>
				<ABB.Icon style={{ color: '#0CA919', marginRight: '5px',cursor: 'text'}} name="abb/check-mark-circle-1" sizeClass="small" />
				<div style={{ color: '#0CA919' }}>数据读取成功，读取时间{moment(getInspection.timestamp).local().format("YYYY-MM-DD HH:mm:ss")} </div>
			</div>
		}

		if (_deviceDetail.gatewaySupportInspection === false) {
			return <div className='contentCenter'>
				<ABB.Icon style={{ color: '#1f1f1f', marginRight: '5px', cursor: 'text'}} name="abb/information-circle-2" sizeClass="small" />
				<div>当前设备的固件版本低于6.05无法执行此操作，请将固件版本升级至6.05以上后操作。</div>
			</div>
		}

		if (_deviceDetail.connectedStatus !== 1) {
			return <div className='contentCenter'>
				<ABB.Icon style={{ color: '#1f1f1f', marginRight: '5px', cursor: 'text'}} name="abb/information-circle-2" sizeClass="small" />
				<div > 当前设备离线无法执行此操作，请在设备在线后操作。</div>
			</div>
		}

		if (getInspection.loadDataSuccess === false) {
			//没有加载到数据 提示仅显示生命周期内的数据
			return <div className='contentCenter'>
				<ABB.Icon style={{ color: '#1f1f1f', marginRight: '5px', cursor: 'text'}} name="abb/comment" sizeClass="small" />
				<div>仅显示<span>{getInspection.dataLifespan}</span>内巡检结果</div>
			</div>
		}

		return <div className='contentCenter'>
			<ABB.Icon style={{ color: '#1f1f1f', marginRight: '5px', cursor: 'text'}} name="abb/comment" sizeClass="small" />
			<div>巡检时间：{getInspection.timestamp !== '' ? moment(getInspection.timestamp).local().format("YYYY-MM-DD HH:mm:ss") : ''} </div>
		</div>
	}

	_renderItem = (item) => {

		const left = item[0]
		const right = item[1]
		return <div className='item'>
			<div className='left'>
				<span className='titleclass'>{left.parameterId} {left.parameterName}</span>
				<span>{left.value} {left.unit}</span>
			</div>
			<div className='right'>
				<span className='titleclass'>{right.parameterId} {right.parameterName}</span>
				<span>{right.value} {right.unit}</span>
			</div>
		</div>
	}

	render() {

		let getInspection = this.state._defaultInspection;

		return <div className='DataInspectionWrapper'>
			<div className='header'>
				<div className="lifestatus_title">实时数据巡检 <span>（此功能可帮助您远程获取传动关键巡检参数的实时数据）</span></div>
				<ABB.Button
					onClick={this._createInspectionTask}
					disabled={!getInspection.disabled}
					type="primary-blue"
					sizeClass='small' theme="filled"
					text="读取实时数据"
				/>

			</div>

			{this._remindMessage()}

			<div className='listContent'>
				<List dataSource={getInspection.inspections} renderItem={this._renderItem}></List>
			</div>
		</div>

	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setInspection: data => {
		dispatch(setInspection(data));
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(DataInspection));