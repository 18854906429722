import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deviceDetail } from '@/redux/actions/device';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import './style.scss'

import BoundsubForm from '../../../../../devicemanagement/components/device/components/boundsub';
import { subscriptionInformation } from '../../../../../../api/subscription';
import { message } from 'antd';
import BoundsubConfigForm from '../../../../../devicemanagement/components/device/components/boundsubConfig';
import moment from 'moment';
import { setExpertsConfig } from '../../store/actionCreators';

class ConnectStatus extends Component {

    constructor(props) {
        super(props);



        this.state = {
            isSubAsset: '',
            listassetId: '',
            assetId: '',
            orgId: '',
            modeltype: '',
            disabled: false,

            showmodelboundsub: {
                deviceData: {},
                subscriptionData: []
            },
            showmodelboundsubConfig: {
                deviceData: {
                    deviceData: {
                        subscriptionLevel: "",
                        expiredDateTime: ""
                    }
                },
                subdata: {}
            },

        };
    }

    _renderRemindService = () => {
        // //临时关闭专家服务
        // return null
       
        const { _deviceDetail } = this.props

        console.log('_deviceDetail====================',_deviceDetail)

        // assetType 1:传动链  2:传动  3:电机
        if (_deviceDetail.assetType === 3) return null //电机设备不显示专家服务
 
        if (_deviceDetail.gatewayType === 1 && _deviceDetail.hasDebugInfo === false) {
            return null
        }
        // "expertServiceStatus": 1 //新增字段,  专家服务状态，0-正在享受服务，1-合同不存在，2-订阅等级无法使用服务，3-服务联系信息未配置
        // "expertServiceType": 1 //新增字段,  专家服务方式，0-暂未设置,1-一键呼叫,2-主动触发

        // if (_deviceDetail.expertServiceStatus === 1) {
        //     //合同不存在
        //     return <div className='device-name-bottom-text'>（此设备暂未购买专家服务。请联系ABB销售人员咨询。）</div>
        // }

        // if (_deviceDetail.expertServiceStatus === 2) {
        //     //订阅等级无法使用服务

        //     return <div className='device-name-bottom-text'> （此设备已购买专家服务，需绑定标准版订阅后方可启用）<span className='serviceButton' onClick={this.boundsub}>绑定订阅</span></div>
        // }

        // if (_deviceDetail.expertServiceStatus === 3) {
        //     //服务联系信息未配置

        //     return <div className='device-name-bottom-text'> （此设备具有专家服务权益，<span className='serviceButton' onClick={this.serviceButtonClick} >设置服务方式</span>）</div>
        // }


        // if (_deviceDetail.expertServiceStatus === 0) {
            //正在享受服务

            if (_deviceDetail.expertServiceType === 0) {
                //暂未设置服务方式
                return <div className='device-name-bottom-text'> （此设备服务方式为：一键呼叫）<span className='serviceButton' onClick={this.serviceButtonClick}>了解和设置服务方式</span>                          </div>
            } else if (_deviceDetail.expertServiceType === 1) {
                //一键呼叫

                return <div className='device-name-bottom-text'>（专家服务权益已生效。此设备服务方式为：一键呼叫）<span className='serviceButton' onClick={this.serviceButtonClick} >了解和设置服务方式</span></div>
            } else if (_deviceDetail.expertServiceType === 2) {

                //主动触发
                return <div className='device-name-bottom-text'> （专家服务权益已生效。此设备服务方式为：设备看护）<span onClick={this.serviceButtonClick} className='serviceButton'>了解和设置服务方式</span></div>
            }
        // }

        // console.log("_renderRemindService-------deviceDetail------", _deviceDetail)

        return <div className='device-name-bottom-text'>（此设备暂不支持专家服务，专家服务是xxxxx）</div>
    }

    //设置服务方式点击
    serviceButtonClick = () => {
        console.log("设置服务方式点击")

        // this.props.showServiceType()
        var config = { ...this.props.getExpertsConfig }
        config.visible = true;

        let deviceContacts = [
            {
                "title": "首选联系人", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 0, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: true
            },
            {
                "title": "备选联系人1", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 1, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: true
            },
            {
                "title": "备选联系人2", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 2, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: false
            },
            {
                "title": "备选联系人3", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 3, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: false
            },
        ]


        // var deviceContacts = [
        //     {
        //         "title": "首选联系人", //标题
        //         "name": "", // 联系人名称
        //         "phone": "", // 联系人电话
        //         "priority": 0, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
        //         isMust: true
        //     },
        //     {
        //         "title": "备选联系人1", //标题
        //         "name": "", // 联系人名称
        //         "phone": "", // 联系人电话
        //         "priority": 1, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
        //         isMust: true
        //     },
        //     {
        //         "title": "备选联系人2", //标题
        //         "name": "", // 联系人名称
        //         "phone": "", // 联系人电话
        //         "priority": 2, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
        //         isMust: false
        //     },
        //     {
        //         "title": "备选联系人3", //标题
        //         "name": "", // 联系人名称
        //         "phone": "", // 联系人电话
        //         "priority": 3, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
        //         isMust: false
        //     },
        // ]
        var defaultDeviceContacts = [...config.defaultDeviceContacts]

        for (let index = 0; index < defaultDeviceContacts.length; index++) {
            const element = defaultDeviceContacts[index];
            deviceContacts[index] = { ...deviceContacts[index], ...element }
        }


        config.deviceContacts = deviceContacts

        config.expertServiceType = this.props._deviceDetail.expertServiceType

        console.log("serviceButtonClick=============", config)

        this.props.updateExpertsConfig(config)

    }
    //显示是否立即生效
    // subdata 验证订阅序列号返回的订阅数据
    // deviceData  当前设备信息
    showModelSubConfig = (subdata, deviceData) => {
        console.log("showModelSubConfig------------------", subdata, "devicedata====", deviceData)
        var newDeviceData = { ...deviceData }

        newDeviceData.deviceData = {
            ...newDeviceData.deviceData, ...{
                assetSrNo: newDeviceData.deviceData.serialNumber,
                subscriptionLevel: newDeviceData.deviceData.subscription.subscriptionLevel,
                expiredDateTime: moment.parseZone(newDeviceData.deviceData.subscription.expiredDateTime).local().format('YYYY-MM-DD HH:mm:ss')
            }
        }

        this.setState({
            showmodelboundsubConfig: {
                visible: true,
                deviceData: newDeviceData,
                subdata: subdata
            }
        })
    }

    //绑定订阅
    boundsub = () => {
        console.log("当前订阅信息-------------", this.props._deviceDetail)
        let { _deviceDetail, getAssetIsPowertrain } = this.props;
        let isSubAsset = `false`;

        if (getAssetIsPowertrain === true) {
            isSubAsset = `true`;
        }

        let url = _deviceDetail.assetId + "?isSubAsset=" + isSubAsset
        console.log("当前订阅信息-------------", this.props._deviceDetail)
        _deviceDetail.assetSrNo = _deviceDetail.serialNumber
        subscriptionInformation(url).then(res => {

            this.setState({
                showmodelboundsub: {
                    visible: true,
                    title: "绑定订阅",
                    value: '',
                    width: 504,
                    centered: true,
                    assetId: _deviceDetail.assetId,
                    isSubAsset: isSubAsset,
                    deviceData: _deviceDetail,
                    subscriptionData: res.data.data
                }
            })
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.warning("失败")
            }
        })
    }

    hidemodel = () => {
        this.setState({
            showmodelboundsub: { ...this.state.showmodelboundsub, visible: false },
            showmodelboundsubConfig: { ...this.state.showmodelboundsubConfig, visible: false }
        })
    }


    //提醒补充调试信息
    _renderDebuggingInformation = () => {
        const { _deviceDetail } = this.props
        // deviceData.gateway.gatewayType === 1 ? 'NETA-21' : "IoT-Panel"
        if (_deviceDetail.gatewayType === 1 && _deviceDetail.hasDebugInfo === false) {
            return <div className='title_bottom'>

                (此设备尚未补充数字化连接调试信息)<span className='serviceButton' onClick={() => {
                    this.props.showDebuggingInformation()
                }}>点击补充调试信息</span>
            </div>

        }

    }

    _renderDeviceStatus() {

        const { _deviceDetail, jurisdictionConfig } = this.props;
        if (jurisdictionConfig.isShowWorkStatus === false) {
            return null
        }

        console.log('_renderDeviceStatus--------', jurisdictionConfig)


        if (_deviceDetail.deviceWorkStatus === 1) {
            return (
                <div className="asset_type">
                    <div>设备状态：</div>
                    <ABB.Icon style={{cursor: "text", 'color': '#0CA919' }} name="abb/drive" sizeClass='small' />&nbsp;

                    <div style={{ 'color': '#0CA919' }}>运行</div>
                </div>
            )
        }
        if (_deviceDetail.deviceWorkStatus === 2) {
            return (
                <div className="asset_type">
                    <div>设备状态：</div>
                    <ABB.Icon style={{cursor: "text", 'color': '#0CA919' }} name="abb/drive" sizeClass='small' />&nbsp;

                    <div style={{ 'color': '#0CA919' }}>待机</div>
                </div>
            )
        }
        if (_deviceDetail.deviceWorkStatus === 3) {
            return (
                <div className="asset_type">
                    <div>设备状态：</div>
                    <ABB.Icon style={{ cursor: "text",'color': '#BABABA' }} name="abb/drives-error" sizeClass='small' />&nbsp;
                    <div style={{ 'color': '#BABABA' }}>计划停机</div>
                </div>
            )
        }
        if (_deviceDetail.deviceWorkStatus === 4) {
            return (
                <div className="asset_type">
                    <div>设备状态：</div>
                    <ABB.Icon style={{ cursor: "text",'color': '#F03040' }} name="abb/drives-error" sizeClass='small' />&nbsp;
                    <div style={{ 'color': '#F03040' }}>非计划停机</div>
                </div>
            )
        }
        return (
            <div className="asset_type">
                <div>设备状态：</div>
                <div>--</div>
            </div>
        )
    }

    render() {
        let _self = this;
        const { _deviceDetail } = _self.props;



        let objnull = (JSON.stringify(_deviceDetail) === "{}")

        return (
            <div className="deviceDetail_status">
                {
                    objnull === false ? (
                        <div>

                            <div className="devicedetail_title">
                                {
                                    this._renderDeviceStatus()
                                }


                                <div className="asset_type">
                                    <div>连接状态：</div>
                                    <div>
                                        {
                                            _deviceDetail.connectedStatus === 1 ? (
                                                <div className='online'></div>
                                            ) : <div className='offline'></div>
                                        }

                                    </div>
                                </div>
                                {
                                    _deviceDetail.assetType === 2 ? (
                                        <>
                                            <div className="asset_type">
                                                <div>序列号：</div>
                                                <div>{_deviceDetail.serialNumber}</div>
                                            </div>
                                            <div className="asset_type">
                                                <div>型号：</div>
                                                <div>{_deviceDetail.driveType}</div>
                                            </div>
                                        </>
                                    ) : ''
                                }


                            </div>
                            <div>{
                                this._renderRemindService()
                            }</div>
                            {
                                this._renderDebuggingInformation()
                            }
                        </div>
                    ) : ''
                }



                <BoundsubForm //绑定订阅弹框
                    showmodelboundsub={_self.state.showmodelboundsub}
                    hidemodel={_self.hidemodel}
                    submitmodel={() => {
                        //绑定订阅,刷新设备详情 
                        this.props.refreshData()
                    }}
                    showModelSubConfig={_self.showModelSubConfig} />

                <BoundsubConfigForm
                    showmodelboundsubConfig={_self.state.showmodelboundsubConfig}
                    hidemodel={_self.hidemodel}
                    submitmodel={() => {
                        this.hidemodel()
                        //绑定订阅,刷新设备详情 
                        this.props.refreshData()


                    }} />
            </div>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    //专家服务方式弹框
    updateExpertsConfig: data => {
        dispatch(setExpertsConfig(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ConnectStatus));