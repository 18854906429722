import $axios from '../axios/$axios';

export function orgtree(data){
   return $axios({
    url: '/api/v2/organization/tree'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function assetGroupList(data){
    return $axios({
     url: '/api/v1/assetgroup/list/'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
 }

 export function assetGroup(data){
    return $axios({
     url: '/api/v1/assetgroup/'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
 }

export function changenode(data){
    return $axios({
     url: '/api/v1/assetgroup/' + data.id,
     method: 'put',
     type: 'json',
     data: data
    })
}

export function addnode(data){
    return $axios({
     url: '/api/v1/assetgroup',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function orgInfo(data) {
    return $axios({
        url: '/api/v1/organization' + data,
        method: 'get',
        type: 'json',
        data: ''
    })
}


export function APIUpdateContacts(orgid,contacts) {
    return $axios({
        url: '/api/v3/organization/' + orgid + '/contacts',
        method: 'post',
        type: 'json',
        data: contacts
    })
}
