import React from 'react';
import {
    Form,
    Select,
    Modal,
    message,
    Col,
    Row,
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { blockdevice, Adddeviceblock } from "@/api/pushsetting";

const { Option } = Select;

class AddDeviceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            devicelist: [],
            templatetype: 1,
            selectdevice: []
        };
    }

    // componentDidMount() {
    //     this.getdevicelist()
    // }
    hidemodel = () => {
        this.props.hidemodel(false)
        this.setState({
            selectdevice: []
        })
        this.props.form.resetFields();
    }
    onChangefrom = (e, orgId) => {
        this.props.form.resetFields();
    }
    // getdevicelist = (orgId) => {

    //     let url = ``;
    //     blockdevice(url).then(res => {
    //         if (res !== undefined) {
    //             let resdata = res.data;
    //             this.setState({
    //                 devicelist: resdata
    //             })
    //         }
    //     }).catch(err => {
    //         if (err.status === 400 || err.status === 404) {
    //             message.warning(err.data)
    //         }
    //         else {
    //             message.error('接口异常')
    //         }
    //     })

    // }

    createselectdevice = () => {
        let url = this.state.selectdevice;
        if (this.state.selectdevice.length > 0) {
            Adddeviceblock(url).then(res => {
                message.success("操作成功！")
                this.props.hidemodel(true);
                this.props.form.resetFields();
            }).catch(err => {
                if (err.status === 400 || err.status === 404) {
                    message.warning(err.data)
                    this.props.hidemodel(false);
                }
                else {
                    console.log("createselectdevice -- Adddeviceblock --接口异常")
                    message.error('接口异常')
                }
            })
        }
        else {
            this.props.hidemodel(false);
        }
    }
    submitmodel = (id, type) => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.createselectdevice();
                this.setState({
                    selectdevice: []
                })
                // this.getdevicelist();
            }
        });
    }
    onChange = (e, id) => {
        setTimeout(() => {
            let selectIds = []
            id.map(item => {
                selectIds.push(item.props.myid);
            })
            this.setState({
                selectdevice: selectIds
            })
        }, 0)
    }
    render() {
        let that = this;
        let { showdata } = that.props;
        const { getFieldDecorator } = that.props.form;
        return (
            <>
                <Modal
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                    title="添加不推送设备"
                    centered
                    width={506}
                    height={400}
                    maskClosable={false}
                    visible={showdata.visible}
                    onOk={() => that.hidemodel()}
                    onCancel={() => that.hidemodel()}
                    footer={[
                        <ABB.Button
                            onClick={() => that.hidemodel()}
                            type="discreet-black"
                            sizeClass='small' theme="filled"
                            text="取消"
                        />,
                        <ABB.Button
                            onClick={() => that.submitmodel()}
                            type="primary-blue"
                            disabled={this.state.selectdevice.length > 0 ? false : true}
                            sizeClass='small' theme="filled"
                            text="确定"
                        />,
                    ]}

                >

                    <Form >
                        <Row gutter={24}>


                            <Col span={24}>
                                <Form.Item label="选择设备">
                                    {getFieldDecorator('model', {
                                        rules: [
                                            { required: false, message: '请选择选择设备' },
                                        ],
                                    })(
                                        <Select
                                            placeholder="请选择选择设备"
                                            mode="multiple"
                                            showArrow={true}
                                            showSearch={true}
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }
                                            onChange={(e, option) => this.onChange(e,option)}
                                        >
                                            {
                                                showdata.devicelist.length > 0 ? (
                                                    showdata.devicelist.map((item, index) => {
                                                        return (
                                                            <Option key={item.deviceId} myid={item.deviceId} value={item.deviceName+'（'+item.deviceSN+'）'}>{item.deviceName}（{item.deviceSN}）</Option>
                                                        )
                                                    })
                                                ) : ''
                                                
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                        </Row>

                    </Form>
                </Modal>
            </>

        );
    }
}

const AddDevice = Form.create({ name: 'register' })(AddDeviceForm);

export default AddDevice;