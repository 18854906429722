import React, { Component } from 'react';
import { Modal, Button, Input, message, Form, Select } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { Addcustomers } from '@/api/authorization.js';

const { Search, TextArea } = Input;
class AddAuthorization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledbutton: false,
            isZn_CH: false,
            selectorgid: []
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel()
        this.props.form.resetFields();
    }
    fuAddcustomers = (data) => {
        this.setState({
            disabledbutton: true
        })
        let url = {
            "abbOrganizationIds": this.state.selectorgid,
            "authorizationType": data.APItype,
            "authrizationPeriodMonth": data.APIdeadline
        }

        Addcustomers(url).then(res => {
            if (res !== undefined) {
                message.success("添加授权客户成功！")
                this.setState({
                    disabledbutton: false
                })
                this.props.submitmodel();
                this.hidemodel()
            }
        }).catch(err => {
            this.setState({
                disabledbutton: false
            })
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data);
            } else {
                console.log("fuAddcustomers -- Addcustomers --接口异常")
                message.error('接口异常');
            }
        });
    }

    submitmodel = (data) => {

        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.fuAddcustomers(fieldsValue)
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };
    onChange = (e, id) => {
        setTimeout(() => {
            let selectIds = []
            id.map(item => {
                selectIds.push(item.props.myid);
            })
            this.setState({
                selectorgid: selectIds
            })
        }, 0)
    }
    render() {
        const that = this;
        const { showmodel } = this.props;
        const { getFieldDecorator } = that.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showmodel.title,
            centered: true,
            width: 506,
            maskClosable: false,
            visible: showmodel.visible,
            onOk: () => { that.hidemodel() },
            onCancel: () => { that.hidemodel() },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel()}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodel)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    disabled={that.state.disabledbutton}
                    text="确定"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>

                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>

                    <Form.Item label="选择客户">
                        {getFieldDecorator('driveType', {
                            // initialValue: showmodel.detail !== '' ? showmodel.detail.driveType : undefined,
                            rules: [
                                {
                                    required: true,
                                    message: '客户不能为空',
                                }
                            ],
                        })(
                            <Select
                                onChange={(e, option) => this.onChange(e, option)}
                                mode="multiple"
                                showArrow={true}
                                showSearch={false}
                                placeholder="请选择客户/手机号"
                                suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
                                style={{ width: '100%', marginRight: '64px' }}
                            >
                                {
                                    showmodel.organizationslist.length > 0 ? (
                                        showmodel.organizationslist.map((item, index) => {
                                            return (
                                                <Select.Option myid={item.id} value={item.name+item.orgContactUser} key={index}>
                                                    {item.name}（{item.orgContactUser}）
                                                </Select.Option>
                                            )
                                        })
                                    ) : ''

                                }
                            </Select>
                        )}
                    </Form.Item>



                    <Form.Item label="API授权类型">
                        {getFieldDecorator('APItype', {
                            initialValue: 1,
                            rules: [

                                {
                                    required: true,
                                    message: 'API授权类型不能为空',
                                }
                            ],
                        })(
                            <Select
                                showSearch
                                placeholder="请选择API授权类型"
                                optionFilterProp="children"
                                suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
                                style={{ width: '100%', marginRight: '64px' }}

                            >
                                {/* <Select.Option value={2} key={2}>
                                    全部
                                </Select.Option> */}
                                <Select.Option value={1} key={1}>
                                    正式
                                </Select.Option>
                                <Select.Option value={2} key={2}>
                                    试用
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>

                    <Form.Item label="API授权期限">
                        {getFieldDecorator('APIdeadline', {
                            initialValue: 1,
                            rules: [

                                {
                                    required: true,
                                    message: 'API授权期限不能为空',
                                }
                            ],
                        })(
                            <Select
                                showSearch
                                placeholder="请选择API授权期限"
                                optionFilterProp="children"
                                suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
                                style={{ width: '100%', marginRight: '64px' }}

                            >
                                {/* <Select.Option value={2} key={2}>
                                    全部
                                </Select.Option> */}
                                <Select.Option value={1} key={1}>
                                    1个月
                                </Select.Option>
                                <Select.Option value={2} key={2}>
                                    2个月
                                </Select.Option>

                                <Select.Option value={12} key={12}>
                                    1年
                                </Select.Option>
                                <Select.Option value={24} key={24}>
                                    2年
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>

                </Form>

            </Modal>
        );
    }
}
const AddFilter = Form.create({ name: 'register' })(AddAuthorization);

export default AddFilter;
