const guild = [
    {
        id: 1,
        name: '船舶',
        children: [
            {
                id: 1,
                name: '船舶制造企业'
            }
        ]
    },
    {
        id: 2,
        name: '电力',
        children: [
            {
                id: 2,
                name: '秸秆和污泥发电'
            },
            {
                id: 3,
                name: '生物质发电'
            },
            {
                id: 4,
                name: '核电、氢能及发电'
            },
            {
                id: 5,
                name: '企业自备电厂'
            },
            {
                id: 6,
                name: '储能'
            },
            {
                id: 7,
                name: '分布式能源'
            },
            {
                id: 8,
                name: '光热发电'
            },
            {
                id: 9,
                name: '垃圾发电'
            },
            {
                id: 10,
                name: '水力发电'
            },
            {
                id: 11,
                name: '火电厂、热电厂'
            },
        ]
    },
    {
        id: 3,
        name: '纺织',
        children: [
            {
                id: 12,
                name: '纺织品加工'
            },
            {
                id: 13,
                name: '碳纤维生产'
            },
            {
                id: 14,
                name: '化学纤维生产及纺制'
            },
            {
                id: 15,
                name: '纺织'
            },
        ]
    },
    {
        id: 4,
        name: '风电',
        children: [
            {
                id: 16,
                name: '光伏发电，光热发电，光化学发电'
            },
        
            {
                id: 17,
                name: '一般小风电'
            },
            {
                id: 18,
                name: '海洋风力发电'
            }, {
                id: 19,
                name: '陆地风力发电'
            },
        ]
    },
    {
        id: 5,
        name: '供热及电子厂房',
        children: [
            {
                id: 20,
                name: '电子厂房设备制造企业'
            },
            {
                id: 21,
                name: '3C设备制造企业'
            },
            {
                id: 22,
                name: '供热企业'
            },
        ]
    },
    {
        id: 6,
        name: '化工',
        children: [
            {
                id: 23,
                name: '煤化工'
            },
            {
                id: 24,
                name: '黏胶短纤维'
            },
            {
                id: 25,
                name: '精细化工'
            },
            {
                id: 26,
                name: '石化原料企业'
            },
            {
                id: 27,
                name: '化肥'
            },
            {
                id: 28,
                name: '石化厂'
            },
            {
                id: 29,
                name: '炼油厂'
            },
        ]
    },
    {
        id: 7,
        name: '机器人和电子电工',
        children: [
            {
                id: 30,
                name: '电工'
            },
            {
                id: 31,
                name: '电子'
            },
            {
                id: 32,
                name: '游乐设备生产'
            },
            {
                id: 33,
                name: '机器人生产和制造'
            },
        ]
    },
    {
        id: 8,
        name: '建筑',
        children: [
            {
                id: 34,
                name: '建筑机械'
            },
            {
                id: 35,
                name: '建筑材料'
            },
        ]
    },
    {
        id: 9,
        name: '交通及制冷',
        children: [
            {
                id: 36,
                name: '轨道交通的掘进设备制造企业'
            },
            {
                id: 37,
                name: '铁路'
            },
            {
                id: 38,
                name: '地铁'
            },
            {
                id: 39,
                name: '机场'
            },
        ]
    },
    {
        id: 10,
        name: '矿山',
        children: [
            {
                id: 40,
                name: '洗煤厂'
            },
            {
                id: 41,
                name: '其它有色金属矿'
            },
            {
                id: 42,
                name: '铜矿'
            },
            {
                id: 43,
                name: '铁矿'
            },
            {
                id: 44,
                name: '煤矿'
            },
        ]
    },
    {
        id: 11,
        name: '楼宇空调',
        children: [
            {
                id: 45,
                name: '数据中心'
            },
            {
                id: 46,
                name: '学校和医院'
            },
            {
                id: 47,
                name: '楼宇空调'
            },
        ]
    },
    {
        id: 12,
        name: '石油天然气',
        children: [
            {
                id: 48,
                name: '石油天然气海洋工程'
            },
            {
                id: 49,
                name: '国家管网公司及地方能源公司'
            },
            {
                id: 50,
                name: '国内能源公司海外区块'
            },
            {
                id: 51,
                name: '国内各大油田'
            },
            {
                id: 52,
                name: '国内外油气设备制造系统集成商'
            },
        ]
    },
    {
        id: 13,
        name: '食品饮料',
        children: [
            {
                id: 53,
                name: '烟草'
            },
            {
                id: 54,
                name: '酒及饮料'
            },
            {
                id: 55,
                name: '面粉加工'
            },
            {
                id: 56,
                name: '乳制品加工'
            },
        
            {
                id: 57,
                name: '调味品及添加剂'
            },
            {
                id: 58,
                name: '食品制造'
            },
            {
                id: 59,
                name: '肉及农副产品加工'
            },
            {
                id: 60,
                name: '糖加工'
            },
            {
                id: 61,
                name: '饲料加工'
            },
            {
                id: 62,
                name: '粮油加工'
            },
        ]
    },
    {
        id: 14,
        name: '试验台',
        children: [
            {
                id: 63,
                name: '汽车试验台'
            },
            {
                id: 64,
                name: '航空、航天风洞试验台'
            },
            {
                id: 65,
                name: '发动机试验台'
            },
            {
                id: 66,
                name: '泵，压缩机类试验台'
            },
            {
                id: 67,
                name: '变速箱试验台'
            },
            {
                id: 68,
                name: '风力发电试验台'
            },
            {
                id: 69,
                name: '电机试验台'
            },
        ]
    },
    {
        id: 15,
        name: '水泥',
        children: [
            {
                id: 70,
                name: '水泥生产企业'
            },
        ]
    },
    {
        id: 16,
        name: '水行业',
        children: [
            {
                id: 71,
                name: '喷泉'
            },
            {
                id: 72,
                name: '输水，引水泵站'
            },
            {
                id: 73,
                name: '海水淡化公司'
            },
            {
                id: 74,
                name: '生活和工业污水处理厂'
            },
            {
                id: 75,
                name: '水务集团公司'
            },{
                id: 76,
                name: '自来水公司'
            },
        
            {
                id: 77,
                name: '灌溉系统设计生产企业'
            },
            {
                id: 78,
                name: '高功率泵'
            },
            {
                id: 79,
                name: '通用型小功率泵'
            },
        ]
    },
    {
        id: 17,
        name: '提升',
        children: [
            {
                id: 80,
                name: '岸电设备等相关应用'
            },
            {
                id: 81,
                name: '造船厂起重设备等相关应用'
            },
            {
                id: 82,
                name: '工业起重设备等相关应用'
            },
            {
                id: 83,
                name: '港口码头的起重设备等相关应用'
            },
        ]
    },
    {
        id: 18,
        name: '物流仓储',
        children: [
            {
                id: 84,
                name: '制冷及冷藏'
            },
            {
                id: 85,
                name: '通用中小型空气压缩机制造企业'
            },
            {
                id: 86,
                name: '仓储设备'
            },
            {
                id: 87,
                name: '典型物流企业及物流设备厂商'
            },
        ]
    },
    {
        id: 19,
        name: '橡胶塑料',
        children: [
            {
                id: 88,
                name: '塑料工业'
            },
        
            {
                id: 89,
                name: '橡胶工业'
            },
        ]
    },
    {
        id: 20,
        name: '冶金',
        children: [
            {
                id: 90,
                name: '有色金属冶炼及加工设备制造企业'
            },
            {
                id: 91,
                name: '有色金属加工企业'
            },
            {
                id: 92,
                name: '有色金属冶炼企业'
            },
            {
                id: 93,
                name: '氧化铝生产企业'
            },
            {
                id: 94,
                name: '各类金属深加工企业'
            },
            {
                id: 95,
                name: '冶金线材深加工企业'
            },
            {
                id: 96,
                name: '冶金板材深加工企业'
            },
            {
                id: 97,
                name: '工业炉生产企业'
            },
            {
                id: 98,
                name: '钢铁生产企业'
            },
        ]
    },
    {
        id: 21,
        name: '印刷包装',
        children: [
            {
                id: 99,
                name: '印后设备'
            },
            {
                id: 100,
                name: '印刷设备'
            },
            {
                id: 101,
                name: '印前设备'
            },
            {
                id: 102,
                name: '包装机械'
            },
            {
                id: 103,
                name: '包装容器及成型机械'
            },
            {
                id: 104,
                name: '包装材料及成型机械'
            },
        ]
    },
    {
        id: 22,
        name: '制浆造纸',
        children: [
            {
                id: 105,
                name: '石墨烯'
            },
            {
                id: 106,
                name: '造纸的后加工机械厂和集成商'
            },
            {
                id: 107,
                name: '造纸厂和纸业有限公司'
            },
            {
                id: 108,
                name: '林木加工'
            },
        ]
    },
    {
        id: 23,
        name: '制药',
        children: [
            {
                id: 109,
                name: '制药设备及生产线'
            },
        ]
    },
]

export default guild;