import * as actionTypes from './constants';


const setExpertsConfig = data => {
	return {
		type: actionTypes.SETTING_EXPERTS_SERVICE,
		data
	};
};
 
const setDebugInformationConfig = data => {
	return {
		type: actionTypes.SETTING_DEBUGING_INFORMATIONN,
		data
	};
};
 
const setDebugInformationRemindConfig = data => {
	return {
		type: actionTypes.SETTING_DEBUGING_INFORMATIONN_REMIND,
		data
	};
};
 
//提醒用户调试信息改变配置
export { setExpertsConfig,setDebugInformationConfig,setDebugInformationRemindConfig};