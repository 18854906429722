import $axios from '../axios/$axios';

export function usergrouplist(data){
   return $axios({
    url: '/api/v1/usergroup'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function userdelete(data){
    return $axios({
     url: '/api/v1/usergroup/user',
     method: 'delete',
     type: 'json',
     data: data
    })
}

export function userserch(data){
    return $axios({
     url: '/api/v1/user/search' + data,
     method: 'get',
     type: 'json',
     data: ''
    })
}

export function userinvite(data){
    return $axios({
     url: '/api/v1/usergroup/user',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function handover(data){
    return $axios({
     url: '/api/v3/organization/handover/to-user',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function cancelPermission(data){
    return $axios({
     url: '/api/applet/v3/permission/cancel-cp-permission',
     method: 'put',
     type: 'json',
     data: data
    })
}

export function getOrgInfoFromOrgCode(orgcode) {
    return $axios({
        url: '/api/v3/organization?code=' + orgcode,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function migrationOrganization(data) {
    return $axios({
        url: '/api/v3/organization/migration',
        method: 'put',
        type: 'json',
        data:data
    })
}

export function organizationLeave(data){
    return $axios({
     url: '/api/v1/organization/leave',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function isvaliduser(data){
    return $axios({
     url: '/api/account/isvaliduser'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
}

export function createEU(data){
    return $axios({
     url: '/api/v1/organization/eu',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function ABBorCPcreateEU(data){
    return $axios({
     url: '/api/v2/organization/eu',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function bindSSOrganization(data){
    return $axios({
     url: '/api/v1/organization/bindsmartsensor',
     method: 'post',
     type: 'json',
     data: null
    })
}

export function getBindSSOrganization(data){
    return $axios({
     url: '/api/v2/organization/bindsmartsensor/'+data,
     method: 'get',
     type: 'json',
     data: null
    })
}

export function createUsergroup(data){
    return $axios({
     url: '/api/v1/usergroup',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function changeUsergroup(data){
    return $axios({
     url: '/api/v1/usergroup',
     method: 'put',
     type: 'json',
     data: data
    })
}

export function deleteUsergroup(data){
    return $axios({
     url: '/api/v1/usergroup'+data,
     method: 'delete',
     type: 'json',
     data: ''
    })
}

export function getrole(data){
    return $axios({
     url: '/api/v1/role'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
}

export function getcpid(data){
    return $axios({
     url: '/api/v1/grant/cp'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
}

export function autoorizitionCp(data){
    return $axios({
     url: '/api/v1/grant',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function getassetgroup(data){
    return $axios({
     url: '/api/v1/assetgroup/usergroup'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
}
export function addinclude(data){
    return $axios({
     url: '/api/v1/assetgroup/usergroup/add',
     method: 'put',
     type: 'json',
     data: data
    })
}

export function removeinclude(data){
    return $axios({
     url: '/api/v1/assetgroup/usergroup/remove',
     method: 'delete',
     type: 'json',
     data: data
    })
}

export function getusergroup(data){
    return $axios({
     url: '/api/v1/grant/cp/grantlist'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
}

export function ABBgetgrantlist(data){
    return $axios({
     url: '/api/v1/grant/abb/grantlist'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
}

export function CPgrantEU(data){
    return $axios({
     url: '/api/v1/grant/cp/customerservicemapping',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function ABBgrantCP(data){
    return $axios({
     url: '/api/v1/grant/abb/partnerservicemapping',
     method: 'post',
     type: 'json',
     data: data
    })
}

export function getuserInfo(data){
    return $axios({
     url: '/api/v1/user'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
}

export function changeuserInfo(data){
    return $axios({
     url: '/api/v1/user',
     method: 'put',
     type: 'json',
     data: data
    })
}


export function banner(){
    return $axios({
     url: '/api/enviroment/banner',
     method: 'get',
     type: 'json',
     data: ''
    })
}

export function removeEUinclude(data){
    return $axios({
     url: '/api/v1/grant/cp/customerservicemapping',
     method: 'delete',
     type: 'json',
     data: data
    })
}

export function removeCPinclude(data){
    return $axios({
     url: '/api/v1/grant/abb/partnerservicemapping',
     method: 'delete',
     type: 'json',
     data: data
    })
}

export function orgData(params){
    return $axios({
        url: '/api/v3/organization/' + params.orgId + "/detail?userId=" + params.userId,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function APISyncContact(){
    return $axios({
        url: '/api/v4/device/contacts/sync-from-org' ,
        method: 'post',
        type: 'json',
        data: {}
    })
}

 
export function APIQueryIndestryList(){
    return $axios({
        url: '/api/v3/industry' ,
        method: 'get',
        type: 'json',
        data: {}
    })
}

//新用户创建组织  abb用户创建渠道,添加组织
export function APICreateOrg(params){
    return $axios({
        url: '/api/v3/organization' ,
        method: 'post',
        type: 'json',
        data: params
    })
}

// 修改组织信息
export function APIUpdateOrg(orgId,params){
    return $axios({
        url: '/api/v3/organization/' + orgId,
        method: 'put',
        type: 'json',
        data: params
    })
}
 