import $axios from '../axios/$axios';

export function notificationprofile(data) {
    return $axios({
        url: '/api/v2/notificationprofile' + data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function notificationprofileblock(data) {
    return $axios({
        url: '/api/v2/notificationprofile/block' + data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function updateCustomLimit(param) {
    return $axios({
        url: '/api/v3/notification/custom-limits/switch',
        method: 'post',
        type: 'json',
        data: param
    })
}

export function notificationprofileenable(data) {
    return $axios({
        url: '/api/v2/notificationprofile/' + data,
        method: 'put',
        type: 'json',
        data: ''
    })
}

export function nodisturb(data) {
    return $axios({
        url: '/api/v2/notificationprofile/nodisturb' + data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function blockdevice(data) {
    return $axios({
        url: '/api/v2/notificationprofile/block/device' + data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function changenodisturb(data) {
    return $axios({
        url: '/api/v2/notificationprofile/nodisturb',
        method: 'put',
        type: 'json',
        data: data
    })
}

export function Adddeviceblock(data) {
    return $axios({
        url: '/api/v2/notificationprofile/block',
        method: 'post',
        type: 'json',
        data: data
    })
}

export function changedeviceblock(data) {
    return $axios({
        url: '/api/v2/notificationprofile/block/'+data,
        method: 'put',
        type: 'json',
        data: ''
    })
}

export function changedevicefault(data) {
    return $axios({
        url: '/api/v2/notificationprofile/block/'+data,
        method: 'put',
        type: 'json',
        data: ''
    })
}

export function deletedevice(data) {
    return $axios({
        url: '/api/v2/notificationprofile/block/'+data,
        method: 'delete',
        type: 'json',
        data: ''
    })
}