import React, { Component } from 'react';
import {
	Modal, message, Form,
	Upload
} from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { CreateMigration } from '@/api/tools.js';
import MigrationModel from './components/migration'


const { Dragger } = Upload;


class Gatewaymigration extends Component {
	state = {
		showmodel: {
			visible: false,
			number: 0
		}
	};

	componentDidMount() {
		// this.fetch(1);
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}

	beforeUpload = (file: any) => {
        console.log("file----------", file)
        const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isJpgOrPng) {
            message.error('请上传 .XLSX 类型的文件!');
        }
        const isLt2M = file.size / 1024 < 2000;
        if (!isLt2M) {
            message.error('文件不能大于200k!');
        }
        return isJpgOrPng && isLt2M;
    }

    customRequest = async (options) => {
        
        const formData = new FormData();

        formData.append('File', options.file);
        let hide = message.loading('正在上传...', 0);
        CreateMigration(formData).then(res => {
			// console.log("CreateMigration---",res.data)
			let resdata = res.data;
            if(resdata.code === 200){
                hide()
				if(resdata.data.failed > 0){
					this.setState({
						showmodel: {
							visible: true,
							number: -1
						}
					})
				}
                else{
					this.setState({
						showmodel: {
							visible: true,
							number: resdata.data.created + resdata.data.updated + resdata.data.migrated
						}
					})
				}
            }
            else{
                hide()
				
                message.warning("上传失败，请检查文件内容")
            }
        }).catch(err=>{
            message.error('上传失败，请检查文件内容')
            hide()
        })

    }
	hidemodel = ()=>{
		this.setState({
			showmodel: {
				visible: false,
				number: 0
			}
		})
	}
	submitmodel = () => {

	}
	render() {
		let {showmodel} = this.state;
		const props = {
			name: 'file',
			multiple: true,
			showUploadList: false,
			accept: '.csv',
			// beforeUpload: this.beforeUpload,
			customRequest: this.customRequest

		};
		return (
			<div className='gateway-box'>

			
				<div className='gatewaymin'>
					<Dragger {...props} className='deviceBorder'>
						<div className='deviceUpLoad'>
							
							<ABB.Button

								// onClick={() => that.submitmodel()}
								type="primary-blue"
								sizeClass='small' theme="filled"
								text="导入网关序列号"
							/>
						</div>
					</Dragger>

					<div style={{ textAlign: 'center', fontSize: '14px', color: '#1f1f1f' }}>
						请先<a href="https://digital.motion.abb.com.cn/网关迁移.csv">下载模板csv文件</a>，填充需要迁移的网关序列号后，再在此处上传。
					</div>
					<div style={{ marginTop: '20px',fontSize: '14px', color: '#1f1f1f' }}>
					详细迁移状态请按照以下方法查看：请以下的链接触发生成新的设备报表。
					</div>
					<div style={{ fontSize: '14px', color: '#686868' }}>
					步骤1：通过此链接<a target="_blank" href="https://ce4174222f2b4ec0b61ec5b89b196037.apig.cn-north-4.huaweicloudapis.com/asset_weekly_report_new">https://ce4174222f2b4ec0b61ec5b89b196037.apig.cn-north-4.huaweicloudapis.com/asset_weekly_report_new</a>触发生成一份设备报表；
		
					</div>
					<div style={{ fontSize: '14px', color: '#686868' }}>
					步骤2：等待10分钟后，通过此链接下载最新报表中查看状态。<a href="https://weekly-report.obs.cn-north-4.myhuaweicloud.com/All_Asset_Table_latest.xlsx">https://weekly-report.obs.cn-north-4.myhuaweicloud.com/All_Asset_Table_latest.xlsx</a>
		
					</div>
				</div>
				<MigrationModel
					showmodel={showmodel}
					hidemodel={this.hidemodel}
					submitmodel={this.submitmodel}
				/>
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Gatewaymigration));
