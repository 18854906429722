import React, { Component } from 'react'
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import {
    userCheckDevice, deviceDetail, checkAllgen, eventDetaildevice, setTrendEventcode,
    setTrendEventTag, setChartsTime
} from '@/redux/actions/device';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EchartLine from "./components/charts"
import { message, Select, DatePicker } from 'antd';
import './style.scss';
import moment from 'moment';
import { timeConfig } from "@/utils/index";

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD HH:mm:ss';

class TrendChartsdetail extends Component {
    constructor(props) {
        super(props);
        let _totime = moment(this.props.getEventDetail.eventTime + 20 * 60 * 1000).utc().format('YYYY-MM-DDTHH:mm:ss')
        let _endtime = moment(new Date(moment.parseZone(this.props.getEventDetail.eventTime + 20 * 60 * 1000).local().format("YYYY-MM-DD")), dateFormat)

        // let aaa = moment.parseZone(_totime).local().format("YYYY-MM-DD HH:mm:ss")
        // if(moment(aaa).unix() * 1000 > Date.parse(new Date())){
        //     _endtime =  moment(new Date(moment(new Date()).format("YYYY-MM-DD")), dateFormat)
        //     _totime = moment(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss')

        // }

        this.state = {
            parameterList: [],
            tagList: [],
            startime: moment(new Date(new Date(moment.parseZone(this.props.getEventDetail.eventTime - 40 * 60 * 1000).local().format("YYYY-MM-DD")).toLocaleDateString()), dateFormat),
            endtime: _endtime,
            fromtime: moment(this.props.getEventDetail.eventTime - 40 * 60 * 1000).utc().format('YYYY-MM-DDTHH:mm:ss'),
            totime: _totime,
            enentTime: this.props.getEventDetail.eventTime,
            tagColor: ["#7ED6C6", "#968D0A", "#CC50CC", "#524C00", "#CC0815"],
            daytime: 1,
            timestamp: 0,
            trendstime: 1,
            trendstimeselect: 1
        }
    }
    componentDidMount() {
        this.parameterList();
        // console.log("343434343434343434")
        let { getsubscriptioncof, getEventDetail } = this.props;
        let { fromtime, totime, startime, endtime } = this.state;
        // let _totime = moment(getEventDetail.eventTime + 20 * 60 * 1000).utc().format('YYYY-MM-DDTHH:mm:ss')
        // let _endtime = moment(new Date(moment.parseZone(getEventDetail.eventTime + 20 * 60 * 1000).local().format("YYYY-MM-DD")), dateFormat)
        // let _fromtime = moment(getEventDetail.eventTime - 40 * 60 * 1000).utc().format('YYYY-MM-DDTHH:mm:ss')
        // let _startime = moment(new Date(new Date(moment.parseZone(getEventDetail.eventTime - 40 * 60 * 1000).local().format("YYYY-MM-DD")).toLocaleDateString()), dateFormat)
        // let aaa = moment.parseZone(_totime).local().format("YYYY-MM-DD HH:mm:ss")
        // if (moment(aaa).unix() * 1000 > Date.parse(new Date())) {
        //     _endtime = moment(new Date(moment(new Date()).format("YYYY-MM-DD")), dateFormat)
        //     _totime = moment(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss')

        // }
        this.setState({
            // totime: _totime,
            // endtime: _endtime,
            // startime: _startime,
            // fromtime: _fromtime
        }, () => {
            // console.log("endtimeendtimeendtime", _startime)
            this.daytime({
                startime: fromtime,
                endtime: totime,
            })
        })
        getsubscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 2) {
                    this.setState({
                        trendstime: items.limitValue
                    })
                }
                if (items.featureCode === 1) {
                    this.setState({
                        trendstimeselect: items.limitValue - 1
                    })
                }
            })

        })
    }
    componentDidUpdate(nextProps, prevState) {
        if (prevState.enentTime !== this.props.getEventDetail.eventTime) {
            let { getEventDetail } = this.props;
            let { fromtime, totime, startime, endtime } = this.state;
            let _totime = moment(getEventDetail.eventTime + 20 * 60 * 1000).utc().format('YYYY-MM-DDTHH:mm:ss')
            let _endtime = moment(new Date(moment.parseZone(getEventDetail.eventTime + 20 * 60 * 1000).local().format("YYYY-MM-DD")), dateFormat)
            let _fromtime = moment(getEventDetail.eventTime - 40 * 60 * 1000).utc().format('YYYY-MM-DDTHH:mm:ss')
            let _startime = moment(new Date(new Date(moment.parseZone(getEventDetail.eventTime - 40 * 60 * 1000).local().format("YYYY-MM-DD")).toLocaleDateString()), dateFormat)
            let aaa = moment.parseZone(_totime).local().format("YYYY-MM-DD HH:mm:ss")
            if (moment(aaa).unix() * 1000 > Date.parse(new Date())) {
                _endtime = moment(new Date(moment(new Date()).format("YYYY-MM-DD")), dateFormat)
                _totime = moment(new Date()).utc().format('YYYY-MM-DDTHH:mm:ss')

            }
            this.setState({
                enentTime: getEventDetail.eventTime,
                totime: _totime,
                endtime: _endtime,
                startime: _startime,
                fromtime: _fromtime
            }, () => {
                console.log("endtimeendtimeendtime", _startime)
                this.daytime({
                    startime: fromtime,
                    endtime: totime,
                })
            })

        }
    }
    daytime = (time) => {
        let startime = Date.parse(time.startime);
        let endtime = Date.parse(time.endtime);
        let day = (endtime - startime) / 1000 / 60 / 60 / 24;
        if (day <= 1) {
            this.setState({
                daytime: 1
            })
        }
        else {
            this.setState({
                daytime: Math.ceil(day)
            })
        }
        // return day;
    }
    parameterList = () => {
        let trendsCharts = this.props.getEventDetail.trendconfig;
        let parameterList = [];
        let parameterListsignal = [];
        // console.log("trendsCharts-----",trendsCharts)

        if (trendsCharts.trends.length > 0 || trendsCharts.signals.length > 0) {


            trendsCharts.trends.map((item, index) => {
                let isShow = true;
                // if (this.props.gettrendscode[0].code === item.code) {
                //     isShow = false;
                // }

                parameterList.push({
                    name: item.label,
                    code: item.code,
                    id: index,
                    isSignals: 0,
                    isShow: isShow
                });
            })
            trendsCharts.signals.map((item, index) => {
                let isShow = true;
                // if (this.props.gettrendscode[0].code === item.code) {
                //     isShow = false;
                // }

                parameterListsignal.push({
                    name: item.label,
                    code: item.parameterId,
                    id: index,
                    isSignals: 1,
                    isShow: isShow
                });
            })


            this.setState({
                parameterList: [parameterList, parameterListsignal]
            })
        }
    }

    tag_close = (code) => {
        let tagList = this.state.tagList;
        let parameterList = this.state.parameterList;


        parameterList[0].map((item, index) => {
            if (item.code === code) {
                item.isShow = true
            }
        })

        parameterList[1].map((item, index) => {
            if (item.code === code) {
                item.isShow = true
            }
        })




        tagList.splice(tagList.findIndex(item => item.code === code), 1);
        this.setState({
            tagList: [...tagList],
            parameterList: parameterList
        }, () => {
            this.props.setTrendEventTag(code + 20);
            this.props.setTrendEventcode(this.state.tagList)
        })
    }
    onChange = (e, data) => {
        let parameterList = this.state.parameterList;
        let tagList = this.state.tagList;

        if (data.props.issignals === 0) {
            parameterList[0].map((item, index) => {
                if (item.code === e) {
                    item.isShow = false
                    this.setState({
                        tagList: [...tagList, item],
                        parameterList: parameterList
                    }, () => {
                        this.props.setTrendEventTag(e);
                        this.props.setTrendEventcode(this.state.tagList)
                    })
                }
            })
        }
        else {
            parameterList[1].map((item, index) => {
                if (item.code === e) {
                    item.isShow = false
                    this.setState({
                        tagList: [...tagList, item],
                        parameterList: parameterList
                    }, () => {
                        this.props.setTrendEventTag(e);
                        this.props.setTrendEventcode(this.state.tagList)
                    })
                }
            })
        }


    }
    onChangetime = (dates, dateStrings) => {
        if (dateStrings[0] === '' || dateStrings[1] === '') {
            let currentUTCTimeString = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss")
            let dateUTCTimeString = moment(new Date(new Date().toLocaleDateString())).utc().format("YYYY-MM-DD HH:mm:ss");
            this.setState({
                startime: dateUTCTimeString,
                endtime: currentUTCTimeString,
                fromtime: dateUTCTimeString,
                totime: currentUTCTimeString
            }, () => {
                this.daytime({
                    startime: dateUTCTimeString,
                    endtime: currentUTCTimeString,
                })
            })

        }
        else {
            let starttimetmp = Date.parse(dateStrings[0]);
            let endtimetmp = Date.parse(dateStrings[1]);
            var days = (endtimetmp - starttimetmp) / (1 * 24 * 60 * 60 * 1000);

            let myday = Math.round(days);

            if (myday > this.state.trendstimeselect) {

                message.warning(`时间不能超过${this.state.trendstimeselect + 1}天！`);
                this.setState({
                    startime: this.state.startime,
                    endtime: this.state.endtime,
                    fromtime: this.props.getChartsTime.startime,
                    totime: this.props.getChartsTime.endtime,
                })
                return false;
            }

            let time = timeConfig({
                startime: dateStrings[0],
                endtime: dateStrings[1],
            })
            // this.props.setChartsTime({
            //     startime: time.startime,
            //     endtime: time.endtime,
            // })
            this.setState({
                startime: time.startime,
                endtime: time.endtime,
                fromtime: time.startime,
                totime: time.endtime,
                timestamp: Date.parse(time.endtime) + Date.parse(time.startime)
            }, () => {
                this.daytime({
                    startime: time.startime,
                    endtime: time.endtime,
                })
            })
        }

    }
    render() {
        let { tagList, parameterList, daytime, tagColor, timestamp, fromtime, totime, trendstime } = this.state;
        const disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(trendstime, 'day');
        let startime = moment.parseZone(this.state.startime).local().format(dateFormat);
        let endtime = moment.parseZone(this.state.endtime).local().format(dateFormat);

        let times = [moment(startime, dateFormat), moment(endtime, dateFormat)]
        return (

            <div>

                <div style={{ padding: '16px 0 0 0' }}>
                    <div className="select_box">

                        <div className="model_list"
                            style={{ margin: "0 8px 32px 0" }}
                        >
                            <div className='model_title'>显示范围</div>
                            <RangePicker
                                // defaultValue={[this.state.startime, this.state.endtime]}
                                value={times}
                                format={dateFormat}
                                suffixIcon={
                                    <ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
                                }
                                disabledDate={disabledDate}
                                style={{ width: 300 }}
                                allowClear={false}
                                separator='—'
                                className="model_input"
                                format="YYYY/MM/DD"
                                onChange={this.onChangetime}
                            // onOk={this.onOk}
                            />
                        </div>
                        <div className="model_list" style={{ margin: "0 32px 32px 0" }}>
                            <div className='model_title' style={{ color: "#fff" }}>.</div>
                            <div className='model_title' style={{ color: '#1f1f1f', fontWeight: 'bold' }}>已选{daytime}天 （最长30天）</div>
                        </div>
                        <div className="model_list">
                            <div className='model_title'>选择参数（{tagList.length}/5）</div>
                            <Select placeholder="添加一个信号"
                                value="添加一个信号"
                                // defaultValue = {this.props.gettrendscode}
                                disabled={tagList.length > 4 ? true : false}
                                suffixIcon={
                                    <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                }
                                style={{ width: 300 }}
                                onChange={(e, option) => this.onChange(e, option)}
                            >
                                {
                                    parameterList.map((item, index) =>
                                        index === 0 ? (
                                            item.map((childrenitem, childIndex) => {
                                                return childrenitem.isShow ? (
                                                    <Select.Option
                                                        issignals={childrenitem.isSignals}
                                                        key={childrenitem.code}
                                                        value={childrenitem.code}>
                                                        {childrenitem.name}
                                                    </Select.Option>
                                                ) : <Select.Option style={{ display: 'none' }} key={childrenitem.code}></Select.Option>;

                                            })
                                        ) : (
                                            item.map((childrenitem, index) => {
                                                return childrenitem.isShow ? (
                                                    <Select.Option
                                                        issignals={childrenitem.isSignals}
                                                        style={index === 0 ? { borderTop: '1px solid #bababa' } : ''}
                                                        key={childrenitem.code}
                                                        value={childrenitem.code}>
                                                        {childrenitem.code} {childrenitem.name}
                                                    </Select.Option>
                                                ) : <Select.Option style={{ display: 'none' }} key={childrenitem.code}></Select.Option>;
                                            })
                                        )
                                    )
                                }
                            </Select>
                        </div>
                        {/* </div>
                    <div className="select_box" style={{ marginTop: 16 }}> */}

                    </div>
                    {
                        tagList.length > 0 ? (
                            <>
                                <div className="select_box">
                                    <div className="mydevice_name">
                                        {this.props._deviceDetail.assetName}：
                                    </div>
                                    <div className="tag_box">
                                        {
                                            tagList.map((item, index) => {
                                                return (
                                                    <div className="tag_list" key={index} style={{ borderBottomColor: tagColor[index] }}>
                                                        {
                                                            item.isSignals === 0 ? (
                                                                <div className="tag_name">{item.name}</div>
                                                            ) : (
                                                                <div className="tag_name">{item.code} {item.name}</div>
                                                            )
                                                        }

                                                        <div className="tag_close" onClick={() => this.tag_close(item.code)}>
                                                            <ABB.Icon style={{ color: '#1F1F1F' }} name="abb/close" sizeClass='small' />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <EchartLine
                                    tagList={tagList}
                                    trendstime={{
                                        startime: fromtime,
                                        endtime: totime,
                                    }}
                                    timestamp={timestamp}
                                    tagColor={tagColor}
                                />
                            </>

                        ) : <div className="selectparam">选择参数</div>
                    }


                </div>
            </div>
        )
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    },
    setTrendEventTag: data => {

        dispatch(setTrendEventTag(data));
    },
    setTrendEventcode: data => {

        dispatch(setTrendEventcode(data));
    },
    setChartsTime: data => {
        dispatch(setChartsTime(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TrendChartsdetail));