import * as actionTypes from '../constants/index';
const setBreadCrumb = data => {
	return {
		type: actionTypes.SET_BREADCRUMB,
		data
	};
};
const setTags = data => {
	return {
		type: actionTypes.SET_TAGS,
		data
	};
};
const setTheme = data => {
	return {
		type: actionTypes.SET_THEME,
		data
	};
};
const setCollapse = data => {
	return {
		type: actionTypes.SET_COLLAPSE,
		data
	};
};
const loading = data => {
	return {
		type: actionTypes.LOADING,
		data
	};
};

const language = data => {
	return {
		type: actionTypes.LANGUAGE,
		data
	};
};

const setIsSignalShowthree = data => {
	return {
		type: actionTypes.SIGNAL_THREE,
		data
	};
};

const setNewsmodelIsShow = data => {
	return {
		type: actionTypes.NEWS_MODELISSHOW,
		data
	};
};

const setRouterLinkIndex = data => {
	return {
		type: actionTypes.ROUTER_LINK,
		data
	};
};
export { setRouterLinkIndex,setNewsmodelIsShow, setBreadCrumb, setTags, setTheme, setCollapse,loading,language,setIsSignalShowthree };
