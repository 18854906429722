import React, { Component } from 'react';
import { Input, Spin, Select, DatePicker, Table, message } from 'antd';
import { eventlist, EUeventlist } from '@/api/event.js';
import { geteventDetail, closeevent } from '@/api/device.js';
import moment from 'moment';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { setNewsmodelIsShow } from '@/redux/actions/setting';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import NoData from '@/components/NoData';
import IndexInputView from '@/components/page';
import EventModel from '@/components/eventcomponent/eventModel';
import EUevent from './EUevent'
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Search } = Input;
const dateFormat = 'YYYY/MM/DD HH:mm:ss';


class CPABBevent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			size: 14,
			pageIndex: 1,
			disabled: false,
			eventlist: {
				data: [],
				totalCount: 1
			},
			startime: '',
			endtime: '',
			fromtime: moment(new Date(), dateFormat),
			totime: moment(new Date(), dateFormat),
			eventStatus: '0',
			eventTypes: '0',
			pagination: {},
			loading: false,
			modal2Visible: false,
			selectedRowKeys: [],
			showmodel: {},
			columns: [
				{
					title: '类型',
					dataIndex: 'assetEventType',
					key: 'assetEventType',
					width: 50,
					align: 'center',
					render: assetEventType => {
						if (assetEventType === 'Fault') {
							return (
								<ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Warning') {
							return (
								<ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Notification') {
							return (
								<ABB.Icon style={{ 'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Custom') {
							return (
								<div className='customevent'></div>
							);
						}
					},
				},

				{
					title: '客户名称',
					dataIndex: 'customerName',
					key: 'customerName',
					width: '13%',
					ellipsis: true
				},
				{
					title: '设备名称',
					dataIndex: 'deviceName',
					key: 'deviceName',
					width: '15%',
					ellipsis: true,
				},
				{
					title: '设备端时间',
					dataIndex: 'deviceTime',
					key: 'deviceTime',
					width: '12%',
					ellipsis: true,
					render: assetEventType => {
						return (
							moment.parseZone(assetEventType).local().format('YYYY-MM-DD HH:mm:ss')
						);
					}
				},
				{
					title: '网关时间',
					dataIndex: 'eventDateTime',
					key: 'eventDateTime',
					width: '12%',
					ellipsis: true,
					render: assetEventType => {
						return (
							moment.parseZone(assetEventType).local().format('YYYY-MM-DD HH:mm:ss')
						);
					}
				},
				{
					title: '事件描述',
					dataIndex: 'content',
					key: 'content',
					width: '38%',
					ellipsis: true,
				},
				{
					title: '事件代码',
					dataIndex: 'eventCode',
					key: 'eventCode',
					width: '10%',
					ellipsis: true,
				},
				{
					title: '处理状态',
					dataIndex: 'assetEventStatus',
					key: 7,
					width: 100,
					render: (text, record) => {
						if (record.assetEventStatus === 'Open') {
							return (
								<div style={{ color: '#3366FF', cursor: 'pointer' }}
									onClick={() => {
										this.showmodel(
											true, '事件详情', record
										)
									}}>未处理</div>
							);
						}
						if (record.assetEventStatus === 'Close') {
							return (
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										this.showmodel(
											true, '事件详情', record
										)
									}}>已处理</div>
							);
						}
					},
				}
			]
		};
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	UNSAFE_componentWillMount() {
		let _startime = moment(new Date()).format("YYYY-MM-DD") + " 00:00:00"
		let _endtime = moment(new Date()).format("YYYY-MM-DD") + " 23:59:59"
		let currentUTCTimeString = moment(_endtime).utc().format("YYYY-MM-DD HH:mm:ss")
		let dateUTCTimeString = moment(_startime).utc().format("YYYY-MM-DD HH:mm:ss");
		this.setState({
			startime: dateUTCTimeString,
			endtime: currentUTCTimeString,
		}, () => {
			this.fetch(this.state.pageIndex)
		})


		// this.fetch(this.state.pageIndex);	
		let _getNewsmodelIsShow = this.props.getNewsmodelIsShow;
		let objnull = (JSON.stringify(_getNewsmodelIsShow) === "{}")
		if (objnull === false) {
			this.showmodel(
				true, '事件详情', _getNewsmodelIsShow
			)
		}
	}
	//关闭事件接口
	closeevent = (val) => {
		if (this.state.assetEventid !== '') {

			let url = {
				id: this.state.assetEventid,
				closeReason: val
			}
			this.setState({
				disabled: true
			})
			closeevent(url).then(res => {
				if (res !== undefined) {
					message.success('操作成功')
					this.setState({
						disabled: false,
						showmodel: {
							visible: false,
							title: '',
							width: 504,
							value: '',
							centered: true,
							eventstatus: 0
						},
					})
					this.fetch(this.state.pageIndex)
				}
			}).catch(err => {
				if (err.status === 400) {
					message.warning(err.data)
				}
				else {
					message.error('关闭事件失败')
				}
				this.setState({
					disabled: false
				})
			})
		}

	}
	showmodel = (showmodel, title, data) => {
		let eventDateTime = moment.parseZone(data.eventDateTime).local().format('YYYY-MM-DD HH:mm:ss')
		let eventCloseTime = moment.parseZone(data.closeAt).local().format('YYYY-MM-DD HH:mm:ss')

		this.setState({
			// eventdetail: `${eventDateTime} ${resdata.content}`,
			assetEventid: data.id,
			showmodel: {
				eventstatus: 0,
				visible: showmodel,
				title: title,
				value: `${eventDateTime} ${data.content}`,
				width: 504,
				centered: true,
				closeReason: data.closeReason,
				closeUser: `${data.closeUserOrganization}, ${data.closeUser}, ${eventCloseTime}`,
				assetEventStatus: data.assetEventStatus
			}
		})
	}
	// 组件传值
	hidemodel = (data) => {
		this.setState({
			showmodel: {
				visible: data,
				title: '',
				width: 504,
				value: '',
				centered: true,
				eventstatus: 0
			},

		})
		this.props.setNewsmodelIsShow({})
	}
	submitmodel = (val) => {
		if (val !== '') {
			this.closeevent(val)
			this.props.setNewsmodelIsShow({})
		}
		else {
			message.destroy()
			message.warning('解决方案不能为空！')
		}
	}
	fetch = (pageIndex) => {
		this.setState({
			loading: true
		})
		this.inputview.pageIndexFn(pageIndex)
		let url = `?pageSize=${this.state.size}&pageIndex=${pageIndex}&eventStatus=${this.state.eventStatus}&eventTypes=${this.state.eventTypes}&startTime=${this.state.startime}&endTime=${this.state.endtime}`
		eventlist(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				this.setState({
					eventlist: resdata,
					loading: false
				}, () => {
					if (this.state.eventlist.data.length < this.state.size) {
						this.inputview.setIsLast(true)
					}
					else {
						this.inputview.setIsLast(false)
					}
				})
				// if (resdata.data.length < this.state.size) {
				// 	this.inputview.setIsLast(true)
				// }
				// else {
				// 	this.inputview.setIsLast(false)
				// }
			}
		}).catch(err => {
			this.setState({
				loading: false
			})
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('消息列表接口获取错误')
			}

		})
	};
	onChange = (e) => {
		this.setState({
			pageIndex: e
		}, () => {
			this.fetch(e)
		})

	}
	onChangeTime = (dates, dateStrings) => {
		let that = this;
		if (dates[0] === undefined || dates[1] === undefined) {
			that.setState({
				startime: '',
				endtime: ''
			}, () => {
				this.resEUeventlist(this.state.pageIndex, this.state.readStatus)
			})

		}
		else {
			let _startime = moment(dateStrings[0]).format("YYYY-MM-DD") + " 00:00:00"
			let _endtime = moment(dateStrings[1]).format("YYYY-MM-DD") + " 23:59:59"
			let currentUTCTimeString = moment(_endtime).utc().format("YYYY-MM-DD HH:mm:ss")
			let dateUTCTimeString = moment(_startime).utc().format("YYYY-MM-DD HH:mm:ss");
			this.setState({
				startime: dateUTCTimeString,
				endtime: currentUTCTimeString,
				pageIndex: 1
			}, () => {
				this.fetch(this.state.pageIndex)
			})
		}
	}
	FneventTypes = (e) => {
		this.setState({
			eventTypes: e,
			pageIndex: 1
		}, () => {
			this.fetch(this.state.pageIndex)
		})
	}
	//类别筛选
	typeOnChange = (e) => {
		this.setState({
			eventStatus: e,
			pageIndex: 1
		}, () => {
			this.fetch(this.state.pageIndex)
		})
	}
	disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(30, 'day');

	render() {
		let that = this;
		let { pageIndex, eventlist, size, fromtime, totime, disabled } = this.state;
		return (
			<>
				<EventModel
					showmodel={that.state.showmodel}
					hidemodel={that.hidemodel}
					disabled={disabled}
					submitmodel={that.submitmodel} />
				<div className="user_box">
					{
						that.props.userInfo.organizationType === "EU" ? (
							''
						) : <span>消息中心</span>
					}
					<Select defaultValue="事件类型"
						suffixIcon={
							<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
						}
						style={{ width: 188 }}
						onChange={this.FneventTypes}
					>
						<Option value="0">全部</Option>
						<Option value="1">故障</Option>
						<Option value="2">告警</Option>
						<Option value="3">通知</Option>
						<Option value="8">自定义</Option>
					</Select>
					<RangePicker
						defaultValue={[fromtime, totime]}
						disabledDate={that.disabledDate}
						className="model_input"
						style={{ margin: '0 10px 0 0' }}
						// disabled={this.disabledDate}
						suffixIcon={
							<ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
						}
						separator='—'
						allowClear={false}
						format="YYYY/MM/DD"
						onChange={this.onChangeTime}
						onOk={this.onOk}
					/>
					<Select defaultValue="事件状态"
						suffixIcon={
							<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
						}
						style={{ width: 188 }}
						onChange={this.typeOnChange}
					>
						<Option value="0">全部</Option>
						<Option value="1">已处理</Option>
						<Option value="2">未处理</Option>

					</Select>
				</div>
				<Spin spinning={this.state.loading} style={{ height: '90vh' }}>
					<div style={{ "width": '100%', position: 'relative', minHeight: 260 }} className='org_right'>
						<>
							<Table
								size="small"
								y='true'
								style={{ cursor: 'pointer' }}
								columns={this.state.columns}
								dataSource={eventlist.data}
								pagination={false}
								onRowClick={(record) =>
									this.showmodel(true, '事件详情', record)
								}
								rowKey={(record, index) => index}
							/>
							<IndexInputView ref={(onref) => {
								this.inputview = onref;
							}} confirmInputValue={(pageIndex) => {
								this.fetch(pageIndex)
								// this.inputview.setIsLast(true)
							}} />
						</>
					</div>
				</Spin>
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	setNewsmodelIsShow: data => {
		dispatch(setNewsmodelIsShow(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(CPABBevent));