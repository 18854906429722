import * as actionTypes from '../constants/index';
const _geteventcount = '0'
const geteventcount = (state = _geteventcount, action) => {
	switch (action.type) {
		case actionTypes.SET_EVENTCOUNT:

			return action.data;
		default:
			return state;
	}
};

const getEventDetail = (state = {}, action) => {
	switch (action.type) {
		case actionTypes.EVENTDETAIL:

			return action.data;
		default:
			return state;
	}
};

const getEventData = (state = [], action) => {
	switch (action.type) {
		case actionTypes.EVENTDATA:

			return action.data;
		default:
			return state;
	}
};

const getEventDetailStatus = (state = {
	startime: '',
	endtime: '',
	EventType: 0,
	page: 1,
	eventName: '',
	forComment: true,
	forEventRecord: false,
	defaultActiveKey: []
}, action) => {
	switch (action.type) {
		case actionTypes.EVENTDETAIL_STATUS:

			return action.data;
		default:
			return state;
	}
};
export { getEventData, geteventcount, getEventDetail, getEventDetailStatus };
