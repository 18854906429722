import * as actionTypes from '../constants/index';
const setCBMdata = data => {
	return {
		type: actionTypes.CBM_DATA,
		data
	};
};
const setCBMtype = data => {
	return {
		type: actionTypes.CBM_TYPE,
		data
	};
};

export { setCBMdata,setCBMtype };