import React, { Component } from 'react';
import { Select, DatePicker, message, Spin } from 'antd';
import { trendsCharts } from '@/api/trends.js'
import { connect } from 'react-redux';
import { deviceDetail, deviceTime, setChartsTime, setTrendsData } from '@/redux/actions/device';
import { loading } from '@/redux/actions/setting';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
// import { FntrendsCharts } from '@/utils/devicesData';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import Echartstest from "./components/LineChartsignal";
import { timeConfig } from "@/utils/index";
import { getChartsTime } from '../../../../redux/reducers/device';
import ApplyHistoryDataFrom from './components/ApplyHistoryDataFrom';

const { RangePicker } = DatePicker;

const { Option } = Select;
const dateFormat = 'YYYY/MM/DD HH:mm:ss';
const linechartsoption = {
	// backgroundColor : '#042b53',
	// 标题
	title: {
		text: '图表',
		left: 15,
		align: 'right',
		textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}

			fontSize: 14,
			color: '#1F1F1F'
		},
	},
	grid: {
		top: '40px',
		left: '3%',
		right: '4%',
		bottom: '10px',
		containLabel: true
	},
	tooltip: {
		trigger: 'axis',
		backgroundColor: 'rgba(255,255,255,0.8)',//通过设置rgba调节背景颜色与透明度
		color: 'black',
		borderWidth: '1',
		borderColor: 'gray',
		textStyle: {
			color: 'black',
		}

	},

	// dataZoom: [
	//     { type: "inside" }, 
	//     // { type: 'slider' }
	// ],
	toolbox: {
		show: true,
		feature: {
			dataZoom: {
				// show: false,
				yAxisIndex: "none",
				icon: {
					zoom: "path://M14.0343806,8.04813286 L11.0412567,8.04813286 L11.0412567,11.0412567 L8.04813286,11.0412567 L8.04813286,14.0343806 L11.0412567,14.0343806 L11.0412567,17.0275045 L14.0343806,17.0275045 L14.0343806,14.0343806 L17.0275045,14.0343806 L17.0275045,11.0412567 L14.0343806,11.0412567 L14.0343806,8.04813286 L14.0343806,8.04813286 Z M29,26.0068761 L20.5593907,17.5662668 C23.1336593,13.4686605 22.2196876,8.09123718 18.4361858,5.07422697 C14.652684,2.05721677 9.20672895,2.36315733 5.78494314,5.78494314 C2.36315733,9.20672895 2.05721677,14.652684 5.07422697,18.4361858 C8.09123718,22.2196876 13.4686605,23.1336593 17.5662668,20.5593907 L26.0068761,29 L29,26.0068761 Z M5.05500898,12.5378187 C5.05500898,8.40517699 8.40517699,5.05500898 12.5378187,5.05500898 C16.6704604,5.05500898 20.0206284,8.40517699 20.0206284,12.5378187 C20.0206284,16.6704604 16.6704604,20.0206284 12.5378187,20.0206284 C8.40517699,20.0206284 5.05500898,16.6704604 5.05500898,12.5378187 L5.05500898,12.5378187 Z",
					back: "path://M25,19 C25,15.21 22.06,12 18.85,12 L11,12 L15,15.8 L13,18 L5,10.5 L13,3 L15,5.2 L11,9 L18.85,9 C23.69,9 28,13.58 28,19 C28,24.42 23.69,29 18.85,29 L7,29 L7,26 L18.85,26 C22.06,26 25,22.79 25,19 Z"
				},
				iconStyle: {
					color: "#1F1F1F",
					borderWidth: 0,
					borderColor: "#1F1F1F",
					opacity: 0
				},
				emphasis: {
					iconStyle: {
						color: "#fff",
						borderColor: "#fff",
						opacity: 0
					}
				},
				title: {
					zoom: "区域缩放",
					back: "区域缩放还原"
				}
			},

			restore: {
				show: false,
				title: "还原",
				emphasis: {
					color: '#3366ff'
				},
				iconStyle: {
					opacity: 0
				}
			},

			//其他功能性按钮查看官网进行增加，包括（显示数据，下载图片，改为柱状图等）
		},
		top: 0,
		right: '30px',
		// tooltip: {
		//     show: true,
		//     formatter: function (param) {
		//         return '<div style="height:20">' + param.title + '</div>'; // 自定义的 DOM 结构
		//     },
		//     backgroundColor: '#ffffff',
		// }
	},
	//x轴数据
	xAxis: {
		type: 'category',
		boundaryGap: false,
		axisLine: {
			lineStyle: {
				color: '#BABABA'
			}
		},
		//  改变x轴字体颜色和大小  
		axisLabel: {
			textStyle: {
				color: '#686868',
				fontSize: '11'
			},
		},
		axisTick: {       //y轴刻度线
			show: false
		},
		data: ['00:30', '01:00', '01:30']
	},
	yAxis: {
		type: 'value',
		axisLine: {       //y轴
			show: false,
			lineStyle: {
				color: '#BABABA'
			}
		},
		//  改变x轴字体颜色和大小  
		axisLabel: {
			textStyle: {
				color: '#686868',
				fontSize: '11'
			},
		},
		axisTick: {       //y轴刻度线
			show: false
		},
	},
	series: [
		{
			name: '最大',
			type: 'line',
			// symbol: 'none',
			// stack: '总量',
			symbol: 'emptyCircle',
			symbolSize: 3,
			itemStyle: {

				normal: {
					color: '#D98F45',
					lineStyle: {
						color: '#D98F45',
						width: 0.7// 0.1的线条是非常细的了
					}
				}
			},
			connectNulls: false,
			data: ['0', '0', '0'],
			// markLine: {
			//     silent: true,
			//     lineStyle: {
			//       normal: {
			//         color: '#333'                  // 这儿设置安全基线颜色
			//       }
			//     },
			//     data: [{
			//         yAxis: 123     //这儿定义基准线的数值为多少
			//     }],
			//     label: {
			//       normal: {
			//         formatter: '安全\n基线'           // 这儿设置安全基线
			//       }
			//     }

			// }
		},
		{
			name: '最小',
			type: 'line',
			// symbol: 'none',
			// stack: '总量',
			symbol: 'emptyCircle',
			symbolSize: 3,
			itemStyle: {

				normal: {
					color: '#5ABFAE',
					lineStyle: {
						color: '#5ABFAE',
						width: 0.7// 0.1的线条是非常细的了
					}
				}
			},
			connectNulls: false,
			// areaStyle:{
			//     color: 'rgba(137,128,25,0.5)'
			// },
			data: ['0', '0', '0']
		},
		{
			name: '平均',
			type: 'line',
			// symbol: 'none',
			// stack: '总量',
			connectNulls: false,
			symbol: 'emptyCircle',
			symbolSize: 3,
			itemStyle: {

				normal: {
					color: '#E64997',
					lineStyle: {
						color: '#E64997',
						width: 0.7// 0.1的线条是非常细的了
					}
				}
			},
			// areaStyle:{
			//     color: 'rgba(137,128,25,0.5)'
			// },
			data: ['0', '0', '0']
		},

	]
}
class Sinal extends Component {

	constructor(props) {
		super(props);
		this.state = {
			daytime: 1,
			TrendsData: [],
			assetId: this.props._deviceDetail.assetId,
			startime: moment(this.props.getChartsTime.startime, dateFormat),
			endtime: moment(this.props.getChartsTime.endtime, dateFormat),
			trendstime: 1,
			trendstimeselect: 1,
			loading: false,
			chartsUpdate: 0,
			showmodelboundsubConfig: {
				visible: false,
				endTime: '-'
			},

		};
	}
	componentDidMount() {
		// let { startime, endtime } = this.state;
		let { getsubscriptioncof, getChartsTime } = this.props;

		// if (getChartsTime.startime === "" || getChartsTime.endtime === "") {
		// 	let time = timeConfig({
		// 		startime: startime._d,
		// 		endtime: endtime._d,
		// 	})
		// 	this.props.setChartsTime({
		// 		startime: time.startime,
		// 		endtime: time.endtime,
		// 	})
		// 	this.getDetailvalue(time);
		// 	this.daytime({
		// 		startime: time.startime,
		// 		endtime: time.endtime,
		// 	})
		// }
		// else {

		// 	this.setState({
		// 		startime: moment(getChartsTime.startime, dateFormat),
		// 		endtime: moment(getChartsTime.endtime, dateFormat),
		// 	}, () => {

		// 		this.getDetailvalue({
		// 			startime: getChartsTime.startime,
		// 			endtime: getChartsTime.endtime,
		// 		})
		// 		this.getDetailvalue(getChartsTime);
		// 	})
		// 	this.daytime({
		// 		startime: getChartsTime.startime,
		// 		endtime: getChartsTime.endtime,
		// 	})

		// }

		this.getDetailvalue({
			startime: getChartsTime.startime,
			endtime: getChartsTime.endtime,
		})
		this.getDetailvalue(getChartsTime);
		this.daytime({
			startime: getChartsTime.startime,
			endtime: getChartsTime.endtime,
		})

		getsubscriptioncof.map(item => {
			item.config.map(items => {
				if (items.featureCode === 2) {
					this.setState({
						trendstime: items.limitValue
					})
				}
				if (items.featureCode === 1) {
					this.setState({
						trendstimeselect: items.limitValue - 1
					})
				}
			})

		})

	}

	onChange = (dates, dateStrings) => {
		if (dateStrings[0] === '' || dateStrings[1] === '') {
			let currentUTCTimeString = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss")
			let dateUTCTimeString = moment(new Date(new Date().toLocaleDateString())).utc().format("YYYY-MM-DD HH:mm:ss");
			this.setState({
				startime: dateUTCTimeString,
				endtime: currentUTCTimeString,
			}, () => {
				this.daytime({
					startime: dateUTCTimeString,
					endtime: currentUTCTimeString,
				})
				this.getDetailvalue();
				this.props.setChartsTime({
					startime: dateUTCTimeString,
					endtime: currentUTCTimeString,
				})
			})

		}
		else {
			let starttimetmp = Date.parse(dateStrings[0]);
			let endtimetmp = Date.parse(dateStrings[1]);
			var days = (endtimetmp - starttimetmp) / (1 * 24 * 60 * 60 * 1000);
			let myday = Math.round(days);

			const {  _deviceDetail,getAssetIsPowertrain } = this.props;

			if (myday > this.state.trendstimeselect) {
 
				message.warning(`时间不能超过${this.state.trendstimeselect + 1}天！`)
				this.setState({
					startime: this.state.startime,
					endtime: this.state.endtime,
				})
				return false;
			} 

			let endTime = _deviceDetail.migrationStartDate

			//如果migrationStartDate有值且选择时间早于 migrationStartDate 提示用户申请查看更多数据
			if (endTime !== null && starttimetmp < Date.parse(endTime)) {

				this.setState({
					startime: this.state.startime,
					endtime: this.state.endtime,
					showmodelboundsubConfig: {
						visible: true,
						endTime: endTime,
						deviceDetail:_deviceDetail,
						isSubAsset:getAssetIsPowertrain
					},
				})
				return false
			}

			let time = timeConfig({
				startime: dateStrings[0],
				endtime: dateStrings[1],
			})
			this.setState({
				startime: time.startime,
				endtime: time.endtime,
			}, () => {
				this.daytime({
					startime: time.startime,
					endtime: time.endtime,
				})
				this.props.setChartsTime({
					startime: time.startime,
					endtime: time.endtime,
				})
				// this.props.loading('block')
				this.getDetailvalue({
					startime: time.startime,
					endtime: time.endtime,
				})
			})
		}
	}



	getDetailvalue(datatime) {
		// let { getAssetStatus } = this.props;
		// if (getAssetStatus !== "已过期") {


		this.setState({
			loading: true
		})
		const { getResTrendsCode, _deviceDetail, getAssetIsPowertrain } = this.props;
		let that = this;
		let trendsCode = '';

		let isSubAsset = ``
		if (getAssetIsPowertrain === true) {
			isSubAsset = `&isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}
		let startime = datatime.startime;
		let endtime = datatime.endtime;
		let trends = getResTrendsCode;
		if (startime === undefined || endtime === undefined) {
			message.warning('时间选择问题，请刷新页面！')
			// _self.props.loading('none')
		}
		else {
			// let url = `?AssetId=${_deviceDetail.assetId}${trends}&From=${startime}&To=${endtime}${isSubAsset}&isZoom=false${isSubAsset}`;
			let url = {
				"assetId": _deviceDetail.assetId,
				"trends": trends,
				"signals": [],
				"from": startime,
				"to": endtime,
				"isSubAsset": getAssetIsPowertrain,
				"isZoom": false
			}
			console.log("url----------", url)
			// return false
			trendsCharts(url).then(res => {
				let resdata = res.data;
				if (resdata !== undefined) {
					if (resdata.data.trend !== null) {
						for (let i = 0; i < resdata.data.trend.length; i++) {
							var dataTrend = resdata.data.trend[i];
							dataTrend.isSignals = 0;
							dataTrend.trendsCharts = JSON.parse(JSON.stringify(linechartsoption));

							let mytimeStamp = []
							for (let z = 0; z < dataTrend.timestamp.length; z++) {
								mytimeStamp.push(moment.parseZone(parseInt(dataTrend.timestamp[z] * 1000)).local().format('MM/DD HH:mm'))
							}

							dataTrend.trendsCharts.series[0].data = dataTrend.max;
							dataTrend.trendsCharts.series[1].data = dataTrend.min;
							dataTrend.trendsCharts.series[2].data = dataTrend.avg;
							dataTrend.trendsCharts.xAxis.data = mytimeStamp;

							if (dataTrend.config.unit === '' || dataTrend.config.unit === null || dataTrend.config.unit === undefined) {
								dataTrend.trendsCharts.title.text = dataTrend.config.label;
							}
							else {
								dataTrend.trendsCharts.title.text = dataTrend.config.label + '（' + dataTrend.config.unit + '）';
							}
							// dataTrend.trendsCharts.title.text = dataTrend.config.label + ' (' + dataTrend.config.unit + ')';
						}

						// data.trendsignal = resdata.data.trend;
						let signals = [];
						if (_deviceDetail.signals !== undefined) {
							_deviceDetail.signals.map((item, index) => {
								signals.push({
									code: item.parameterId,
									name: item.name,
									config: {
										label: item.name
									},
									isShow: true,
									id: index,
									isSignals: 1,
									isShow: true

								})
							})
						}

						// if(userInfo.organizationType === "ABB" && userInfo.isOrgAdministrator === true){
						// 	this.props.setTrendsData([resdata.data.trend,signals]);
						// }
						// else{
						// 	this.props.setTrendsData([resdata.data.trend,[]]);
						// }
						this.props.setTrendsData([resdata.data.trend, signals]);

						this.setState({
							loading: false,
							chartsUpdate: this.state.chartsUpdate + 1,
							TrendsData: resdata.data.trend
						})
					}

				}
			}).catch(err => {
				console.log("获取信号时序失败", err)
				this.setState({
					loading: false
				})
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data)
				}
				else {
					message.error('获取信号时序失败')
				}
			})
		}
		// }
	}
	daytime = (time) => {
		let startime = Date.parse(time.startime);
		let endtime = Date.parse(time.endtime);
		let day = (endtime - startime) / 1000 / 60 / 60 / 24;
		if (day <= 1) {
			this.setState({
				daytime: 1
			})
		}
		else {
			this.setState({
				daytime: Math.ceil(day)
			})
		}
		// return day;
	}

	//隐藏model
	hidemodel = () => {
		this.setState({
			showmodelboundsubConfig: {
				visible: false,
				endTime: '-',
				deviceDetail:{},
				isSubAsset:false
			},
		})
	}

	render() {

		let _self = this;

		const { _deviceDetail, getTrendsData, getIsSignalShowthree } = this.props;
		let { TrendsData, trendstime, chartsUpdate } = this.state;
		const disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(this.state.trendstime, 'day') || (current && _deviceDetail.createdOn && current < moment(_deviceDetail.createdOn).add(-1, 'd').endOf('day'));

		let startime = moment.parseZone(this.state.startime).local().format(dateFormat);
		let endtime = moment.parseZone(this.state.endtime).local().format(dateFormat);

		let times = [moment(startime, dateFormat), moment(endtime, dateFormat)]

		return (
			<div className='deviceDetail_status_bottom'>
				<div className='asset_title' style={{ 'marginTop': '0', height: 30 }}>
					<div>
						<span style={{ 'marginRight': '42px', fontSize: '16px' }}>信号时序</span>
						{
							trendstime !== 1 ? (
								<>
									<RangePicker
										defaultValue={[this.state.startime, this.state.endtime]}
										value={times}
										format={dateFormat}
										suffixIcon={
											<ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
										}
										disabledDate={disabledDate}
										// showTime
										allowClear={false}
										separator='—'
										className="model_input"
										format="YYYY/MM/DD"
										onChange={this.onChange}
									// onOk={this.onOk}
									/>
									<span style={{ 'marginLeft': '16px', fontSize: '14px', color: '#1f1f1f', fontWeight: 'bold' }}>已选{this.state.daytime}天 （最长30天）</span>

									{/* <span style={{ 'marginLeft': '16px', fontSize: '14px' }}>最长30天</span> */}

								</>
							) : ''
						}

					</div>

				</div>
				<Spin spinning={this.state.loading} style={{ height: '260px' }}>
					<div className="generalnformation">
						{
							(TrendsData.length > 0) ? (
								<Echartstest IsSignalShowthree={getIsSignalShowthree} options={TrendsData} chartsUpdate={chartsUpdate} />

							) : <div className="renderEmpty renderEmpty_flex">
								<div className="renderEmpty_center">
									<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
									<p>暂无数据</p>
								</div>
							</div>
						}


					</div>
				</Spin>

				<ApplyHistoryDataFrom
					showmodelboundsubConfig={_self.state.showmodelboundsubConfig}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel} />
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setTrendsData: data => {

		dispatch(setTrendsData(data));
	},
	deviceDetail: data => {

		dispatch(deviceDetail(data));
	},
	deviceTime: data => {
		dispatch(deviceTime(data))
	},
	loading: data => {
		dispatch(loading(data))
	},
	setChartsTime: data => {
		dispatch(setChartsTime(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Sinal));
// export default Sinal;

