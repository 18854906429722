import React, { Component, PureComponent } from 'react';
import { message, Menu, Dropdown, Button, Alert, Icon, Modal } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setUserInfo } from '@/redux/actions/userInfo';
import { setEventcount } from '@/redux/actions/event';
import { emptyTag, addTag } from '@/redux/actions/tagList';
import { selectequipment } from '@/redux/actions/userlist';
import { deviceDetail, userCheckDevice, checkAllgen, setassetId,settreeCopy,settree,settreeStatus,settreeValue } from '@/redux/actions/device';
import { setCollapse, setBreadCrumb, setTags, setTheme, setRouterLinkIndex } from '@/redux/actions/setting';
import { routes } from '@/router/routes';
import intl from 'react-intl-universal';
import { isvaliduser, banner,orgData } from '@/api/user.js';
import { eventcount, EUeventcount } from '@/api/event.js';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import Tags from './Tags';
import BasicDrawer from '../dashboard/components/BasicDrawer';
import { AuthProvider } from '../../lib/AuthProvider';
import { getAuthProvider } from '@/lib/AuthProvider';
import { APIUserOrgMapping, APIUpdateUserOrgMapping, getOrgListByUserid } from '@/api/tools.js';
import AvailableOrgListModal from './headerComponents/OrgList';
import { duration } from 'moment';
import MigrationModel from '@/components/migration/index';
import userInfo from './../../redux/reducers/userInfo';
import { myOrgtree } from '@/utils/devicesData'
import GetJson from "@/utils/link.js"
import { setOrgInfo } from '../../redux/actions/userInfo';
const authProvider = getAuthProvider();

class TopHeader extends Component {
	state = {
		messagetime: process.env.REACT_APP_MESSAGETIME || 300000,
		visible: false,
		userData: 'CP',
		disabled: false,
		routerLinkIndex: 0,
		language: 'English',
		settingIcon: 'hide',
		eventcount: '',
		banner: {},
		org_list: [],
		orgListVisible: false,
		canTransOrganization: false
	};
	componentDidMount() {
		// this.props.match()
		this.userInfo();

	}
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	onRef = (ref) => {
		this.child = ref
	}
	userInfo() {
		let url = ``;
		isvaliduser(url)
			.then(res => {
				if (res !== undefined) {
					localStorage.setItem('userInfo', JSON.stringify(res.data));
					// let userInfo = JSON.parse(localStorage.getItem('userInfo'));
					let userInfo = res.data;
					let _language = localStorage.getItem('lang');
					let menukey = localStorage.getItem('menukey');
					if (userInfo) {

						this.props.setUserInfo(userInfo);
						this.setState({
							routerLinkIndex: Number(menukey),
							userData: 'EU'
							// userData: userInfo.organizationType
						});
						if (_language === 'en-US') {
							this.setState({
								language: '中文'
							});
						} else if (_language === 'zh-CN') {
							this.setState({
								language: 'English'
							});
						}
						this.isbackrouter();
						// this.Fneventcount();
						this.messagetooltip();
						this.getOrgData();
						this.child.showModelFn(userInfo);

					} else {
						this.props.history.push('/login');
					}
				}
			})
			.catch(err => {
				message.warning('获取用户信息失败！无法正常访问。');
				setTimeout(() => {
					this.props.history.push('/login');
				}, 1500);
			});
	}
	isbackrouter = () => {
		let _routerLinkIndex = this.props.location.pathname;
		if (_routerLinkIndex === '/dashboard') {
			this.props.setRouterLinkIndex(0);
			this.setState({
				routerLinkIndex: 0
			});
		}
		if (_routerLinkIndex === '/devicedetails') {
			this.props.setRouterLinkIndex(1);
			this.setState({
				routerLinkIndex: 1
			});
		}
		if (_routerLinkIndex === '/devicemanagement') {
			this.props.setRouterLinkIndex(2);
			this.setState({
				routerLinkIndex: 2
			});
		}
		if (_routerLinkIndex === '/Orgmanagement') {
			this.props.setRouterLinkIndex(3);
			this.setState({
				routerLinkIndex: 3
			});
		}
		if (_routerLinkIndex === '/clientmanagement') {
			this.props.setRouterLinkIndex(5);
			this.setState({
				routerLinkIndex: 5
			});
		}
		if (_routerLinkIndex === '/clientmanagement') {
			this.props.setRouterLinkIndex(5);
			this.setState({
				routerLinkIndex: 5
			});
		}
		if (_routerLinkIndex === '/Notification') {
			this.props.setRouterLinkIndex(7);
			this.setState({
				routerLinkIndex: 7
			});
		}
		if (_routerLinkIndex === '/personalCenter') {
			this.props.setRouterLinkIndex(8);
			this.setState({
				routerLinkIndex: 8
			});
		}
		if (_routerLinkIndex === '/toolbox') {
			this.props.setRouterLinkIndex(9);
			this.setState({
				routerLinkIndex: 9
			});
		}
		if (_routerLinkIndex === '/Setting') {
			this.props.setRouterLinkIndex(10);
			this.setState({
				routerLinkIndex: 10
			});
		}
	};

	handClickTag(path, parent) {
		for (let i = 0; i < routes.length; i++) {
			if (path === routes[i].path) {
				let obj = { path, title: '消息', component: routes[i].component };
				this.props.addTag(parent ? Object.assign({}, obj, { parent: parent.title }) : obj);
			}
		}
	}
	toggle = () => {
		this.props.setCollapse({ isCollapsed: !this.props.collapse.isCollapsed });
	};
	setting = () => {
		let _routerLinkIndex = this.props.location.pathname;
		if (_routerLinkIndex === '/dashboard') {
			this.setState({ visible: true });
		}
	};
	onClose = () => {
		this.setState({ visible: false });
	};
	onChangeTags = checked => {
		this.props.setTags({ show: checked });
		localStorage.setItem('tags', JSON.stringify({ show: checked }));
		this.onClose();
	};
	onChangeBreadCrumb = checked => {
		this.props.setBreadCrumb({ show: checked });
		localStorage.setItem('breadCrumb', JSON.stringify({ show: checked }));
		this.onClose();
	};
	onChangeTheme = checked => {
		this.props.setTheme({ type: checked ? 'dark' : 'light' });
		localStorage.setItem('theme', JSON.stringify({ type: checked ? 'dark' : 'light' }));
		this.onClose();
	};
	treechildren = (data, id) => {
        data.map(item => {
            item.key = item.id + "/" + item.type;
            item.orgId = id;
            if (item.children.length > 0 && item.ag.length <= 0) {
                // debugger;
                if (item.keyWord === null) {
                    item.children.map(item1 => {
                        item1.key = item1.id + "/" + item1.type;
                        item1.orgId = id;
                        this.treechildren([item1], item1.orgId)
                    })
                }
                else {
                    return false
                }

            }
            if (item.children.length <= 0 && item.ag.length > 0) {
                // debugger;
                if (item.keyWord === null) {
                    item.children.map(item1 => {
                        item1.key = item1.id + "/" + item1.type;
                        item1.orgId = id;
                        this.treechildren([item1], item1.orgId)
                    })
                }
                else {
                    return false
                }

                item.children = [...item.ag];

            }
        })

        return data;
    }
	//组织结构接口数据
    Fnorgtree = () => {
        let that = this;
        myOrgtree('', data => {
            let dataid = data[0].id
            let treedata = that.treechildren([...data], dataid);
            that.props.settreeCopy(treedata)
            that.props.settree(treedata)
            that.props.settreeStatus(new Array(data[0].key))
        });
    };
	Fnselect = key => {
		localStorage.setItem('menukey', JSON.stringify(key));
		// this.Fnorgtree()
		// this.props.settreeValue('')
		if (key === 8 && this.props.getRouterLinkIndex === 0) {
			this.setState({
				settingIcon: 'show'
			});
			return
		}
		
		this.props.setRouterLinkIndex(key);


		console.log("key=================",key)

		if (key === 0) {
			this.props.history.push('./dashboard');
			this.props.userCheckDevice(0)
			this.props.checkAllgen(0)
			this.props.setassetId('');
			this.props.deviceDetail({})
			return
		}

		if (this.state.settingIcon === 'show') {
			this.setState({
				settingIcon: 'hide'
			});
		}
		if (key === 1) {
			this.props.history.push('./devicedetails');
			this.props.userCheckDevice(0)
			this.props.checkAllgen(0)
			this.props.setassetId('');
			this.props.deviceDetail({})
		}
		if (key === 2) {
			this.props.history.push('./devicemanagement');
			this.props.userCheckDevice(0)
			this.props.checkAllgen(0)
			this.props.setassetId('');
			this.props.deviceDetail({})
		}
		if (key === 3) {
			this.props.history.push('./Orgmanagement');
		}
		if (key === 4 || key === 5) {
			this.props.history.push('./clientmanagement');
		}

		if (key === 6) {
			let _routerLinkIndex = this.props.location.pathname;
			if (_routerLinkIndex === '/dashboard') {
				this.setState({ visible: true });
			}
		}
		if (key === 7) {
			this.handClickTag('/Notification');
			this.props.history.push('/Notification');
			this.props.userCheckDevice(0)
			this.props.checkAllgen(0)
			this.props.setassetId('');
			this.props.deviceDetail({})
		}
		if (key === 10) {
			this.handClickTag('/Setting');
			this.props.history.push('/Setting');
		}
	};
	//判断登陆用户是CP还是EU
	isvaliduser() {
		console.log('this.props.userInfo================',this.props.userInfo)
		let userInfo = this.props.userInfo.organizationType;
		let { getRouterLinkIndex } = this.props;
		// 
		if (userInfo === 'EU') {
			return (
				<div className="header_nav">
					<div onClick={() => this.Fnselect(0)} className={getRouterLinkIndex === 0 ? 'header_nav_list_active' : 'header_nav_list'}>
						{intl.get('dashboard')}
					</div>
					<div onClick={() => this.Fnselect(1)} className={getRouterLinkIndex === 1 ? 'header_nav_list_active' : 'header_nav_list'}>
						设备详情
					</div>
					<div onClick={() => this.Fnselect(2)} className={getRouterLinkIndex === 2 ? 'header_nav_list_active' : 'header_nav_list'}>
						设备管理
					</div>
					<div onClick={() => this.Fnselect(3)} className={getRouterLinkIndex === 3 ? 'header_nav_list_active' : 'header_nav_list'}>
						组织管理
					</div>
				</div>
			);
		} else if (userInfo === 'CP') {
			return (
				<div className="header_nav">
					<div onClick={() => this.Fnselect(0)} className={getRouterLinkIndex === 0 ? 'header_nav_list_active' : 'header_nav_list'}>
						{intl.get('dashboard')}
					</div>
					<div onClick={() => this.Fnselect(1)} className={getRouterLinkIndex === 1 ? 'header_nav_list_active' : 'header_nav_list'}>
						设备详情
					</div>
					<div onClick={() => this.Fnselect(2)} className={getRouterLinkIndex === 2 ? 'header_nav_list_active' : 'header_nav_list'}>
						设备管理
					</div>
					<div onClick={() => this.Fnselect(5)} className={getRouterLinkIndex === 5 ? 'header_nav_list_active' : 'header_nav_list'}>
						客户管理
					</div>
					<div onClick={() => this.Fnselect(3)} className={getRouterLinkIndex === 3 ? 'header_nav_list_active' : 'header_nav_list'}>
						组织管理
					</div>
				</div>
			);
		} else if (userInfo === 'ABB') {
			return (
				<div className="header_nav">
					<div onClick={() => this.Fnselect(0)} className={getRouterLinkIndex === 0 ? 'header_nav_list_active' : 'header_nav_list'}>
						{intl.get('dashboard')}
					</div>
					<div onClick={() => this.Fnselect(1)} className={getRouterLinkIndex === 1 ? 'header_nav_list_active' : 'header_nav_list'}>
						设备详情
					</div>
					<div onClick={() => this.Fnselect(2)} className={getRouterLinkIndex === 2 ? 'header_nav_list_active' : 'header_nav_list'}>
						设备管理
					</div>
					<div onClick={() => this.Fnselect(5)} className={getRouterLinkIndex === 5 ? 'header_nav_list_active' : 'header_nav_list'}>
						客户管理
					</div>
					<div onClick={() => this.Fnselect(3)} className={getRouterLinkIndex === 3 ? 'header_nav_list_active' : 'header_nav_list'}>
						组织管理
					</div>
				</div>
			);
		}
	}
	FnhandleClick = lang => {
		let _language = localStorage.getItem('lang');
		if (_language === 'en-US') {
			localStorage.setItem('lang', 'zh-CN');
		} else {
			localStorage.setItem('lang', 'en-US');
		}
		window.location.reload();
	};
	personalCenter = () => {
		this.setState({
			settingIcon: 'hide'
		});
		this.props.history.push('/personalCenter');
	};
	SettingCenter = () => {
		this.setState({
			settingIcon: 'hide'
		});
		this.props.history.push('/Setting');
	};
	toolBoxCenter = () => {
		this.setState({
			settingIcon: 'hide'
		});
		this.props.history.push('/toolbox');
	};
	tjfn() {

		var LoginEvent = window.JAnalyticsInterface.Event.LoginEvent;
		var lEvent = new LoginEvent('wx', true);
		lEvent.addKeyValue('key1byq', 'value1').addKeyValue('key2', 'value2');
		window.JAnalyticsInterface.onEvent(lEvent);
	}
	zcfn() {
		var RegisterEvent = window.JAnalyticsInterface.Event.RegisterEvent;
		var rEvent = new RegisterEvent('sina', true);
		rEvent.addKeyValue('注册', 'value1').addKeyValue('key2', 'value2');
		window.JAnalyticsInterface.onEvent(rEvent);
	}

	// 获取用户所拥有的组织
	getUserOrgs = () => {
		let userId = this.props.userInfo.userId;
		getOrgListByUserid(userId)
			.then(res => {
				console.log("resgetOrgListByUserid--------", res.data)
				// const userOrg = res.data.results.org_list.filter(value => {
				// 	return value.active === 1;
				// });
				this.setState({
					org_list: res.data.data,
					// userOrg: userOrg[0]
				});

			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					// message.warning(err.data)
				} else {
				}
			});
	};
	changeOrglistVisible = () => {
		this.setState({
			orgListVisible: !this.state.orgListVisible
		});
	};
	optionOrgListOnChange = org_id => { };
	updateUserOrgMapping = org_id => {
		const userId = this.props.userInfo.userId;
		const data = {
			new_organization_id: org_id
		};
		this.setState({
			disabled: true
		})
		APIUpdateUserOrgMapping(userId, data)
			.then(res => {
				this.setState({
					disabled: false
				})
				message.success(res.data, 3).then(() => {
					window.location.reload();
				});
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data.error.message);
				} else {
					message.error('接口获取失败');
				}
				this.setState({
					disabled: false
				})
			});
	};
	//获取消息未读数量
	Fneventcount = () => {
		let url = ``;
		let that = this;
		if (this.props.userInfo.organizationType === 'EU') {
			EUeventcount(url).then(res => {
				if (res !== undefined) {
					that.props.setEventcount(res.data.count);
				}
			});
		} else {
			// this.resmessagedata();
			// setInterval(function () {
			// 	that.resmessagedata();
			// }, that.state.messagetime);
		}
	};
	resmessagedata = () => {
		let url = ``;
		eventcount(url).then(res => {
			if (res !== undefined) {
				this.props.setEventcount(res.data.count);
			}
		});
	};
	messagetooltip = () => {
		let url = ``;
		banner(url).then(res => {
			if (res !== undefined) {
				this.setState({
					banner: res.data
				});
			}
		});
	};
	getOrgData = () => {
        console.log("this.props.userInfo", this.props.userInfo.orgId)
        let url = {
            orgId: this.props.userInfo.orgId,
            userId: this.props.userInfo.userId
        }
        orgData(url).then(res => {

			console.log("orgData--------------------",res)
 
            if (res !== undefined) {
                let data = res.data.data;
                this.setState({
					canTransOrganization: data.canTransOrganization,
				})
				let requestContacts = data.contacts
 
				var deviceContacts = [
					{
						"title": "首选联系人", //标题
						"name": "", // 联系人名称
						"phone": "", // 联系人电话
						"priority": 0, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
						isMust: true
					},
					{
						"title": "备选联系人1", //标题
						"name": "", // 联系人名称
						"phone": "", // 联系人电话
						"priority": 1, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
						isMust: true
					},
					{
						"title": "备选联系人2", //标题
						"name": "", // 联系人名称
						"phone": "", // 联系人电话
						"priority": 2, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
						isMust: false
					},
					{
						"title": "备选联系人3", //标题
						"name": "", // 联系人名称
						"phone": "", // 联系人电话
						"priority": 3, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
						isMust: false
					},
				]
	 
				for (let index = 0; index < requestContacts.length; index++) {
					const element = requestContacts[index];
					requestContacts[index] = { ...deviceContacts[index], ...element }
				}
				data.contacts = [...requestContacts]

				console.log("orginnfo========",data)
		 
				this.props.updateOrgInfo(data)
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            } else {
                message.error('组织信息获取失败！')
            }

        })
    }
	showOrgList = () => {
		// this.state.isShowOrgList = true;
		let userId = this.props.userInfo.userId;
		getOrgListByUserid(userId)
			.then(res => {
				console.log("resgetOrgListByUserid--------", res.data)
				// const userOrg = res.data.results.org_list.filter(value => {
				// 	return value.active === 1;
				// });
				this.setState({
					org_list: res.data.data,
					orgListVisible: true
				});

			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					// message.warning(err.data)
				} else {
				}
			});
	};
	render() {
		const {organizationType,isOrgAdministrator} = this.props.userInfo
		var userManualLink = '';
		switch (organizationType) {
			case 'ABB':
				{
					userManualLink = GetJson.usefull_links.user_guid_ABB;
				}
				break;
			case 'CP':
				{
					userManualLink = GetJson.usefull_links.user_guid_CP;
				}
				break;
			case 'EU':
				{
					userManualLink = GetJson.usefull_links.user_guid_EU;
				}
				break;
		}
		const DropdownList = (
			<Menu className="drop-list" style={{ width: 168 }}>
				<Menu.Item key="0" style={{ color: '#1F1F1F' }} onClick={() => this.personalCenter()}>
					<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/edit" sizeClass="small" />
					{intl.get('user')}
				</Menu.Item>
				{organizationType === 'ABB' && isOrgAdministrator === true ? (
					<Menu.Item key="1" style={{ color: '#1F1F1F' }} onClick={() => this.toolBoxCenter()}>
						<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/wrench" sizeClass="small" />
						工具箱
					</Menu.Item>
				) : (
					<></>
				)}

				{organizationType === 'EU' ? (
					<Menu.Item key="8" style={{ color: '#1F1F1F' }} onClick={() => this.SettingCenter()}>
						<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/controls-and-settings" sizeClass="small" />
						消息设置
					</Menu.Item>
				) : (
					<></>
				)}


				<Menu.Item key="2">
					<a style={{ color: '#1F1F1F' }} target="_blank" href={userManualLink}>
						<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/document" sizeClass="small" />
						用户手册
					</a>
				</Menu.Item>
				<Menu.Item key="3">
					<a style={{ color: '#1F1F1F' }} target="_blank" href={GetJson.usefull_links.acceptable_user_policy}>
						<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/document-pdf" sizeClass="small" />
						可接受使用政策
					</a>
				</Menu.Item>
				<Menu.Item key="4">
					<a style={{ color: '#1F1F1F' }} target="_blank" href={GetJson.usefull_links.user_license_drive}>
						<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/document-pdf" sizeClass="small" />
						用户许可协议_传动
					</a>
				</Menu.Item>
				<Menu.Item key="5">
					<a style={{ color: '#1F1F1F' }} target="_blank" href={GetJson.usefull_links.user_license_motor}>
						<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/document-pdf" sizeClass="small" />
						用户许可协议_电机
					</a>
				</Menu.Item>
				{this.state.canTransOrganization === true ? (
					<Menu.Item key="6" style={{ color: '#1F1F1F' }} onClick={() => this.showOrgList()}>
						<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/network" sizeClass="small" />
						切换组织
					</Menu.Item>
				) : (
					/* <>
						<Menu.Item key="user" style={{ color: '#1F1F1F' }} onClick={() => this.showOrgList()}>
							<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/network" sizeClass="small" />
							切换组织
						</Menu.Item>
						{this.state.orgListVisible && <OrgList visible={this.state.orgListVisible} changeOrglistVisible={() => this.changeOrglistVisible()} />}
					</> */
					<></>
				)}
				<Menu.Item key="7" style={{ color: '#1F1F1F' }} onClick={()=>{

					 authProvider.clearCache();
					authProvider.logout();

					
					//authProvider.logout();
					// authProvider4.setAuthenticationParameters(MyABBLoginConfig);
				 
				}}>
					<ABB.Icon style={{ margin: '0 16px 0 0px' }} name="abb/log-out" sizeClass="small" className="logout" />
					{intl.get('logout')}
				</Menu.Item>
			</Menu>
		);
		const { tags, getRouterLinkIndex } = this.props;

		return (
			<>
				<div>
					<Alert
						style={{ display: this.state.banner.isActivate === true ? 'block' : 'none' }}
						message={
							<div style={{ padding: '0px 8px 0 0' }}>
								<Icon type="exclamation-circle" theme="filled" style={{ color: '#36f', marginRight: 8 }} />
								{/* {this.state.banner.banner} */}
								<span dangerouslySetInnerHTML={{ __html: this.state.banner.banner }}></span>
							</div>
						}
						type="info"
						closable
					/>
				</div>

				<div className="top-header org_right">
					<div className="header-logo">
						<img src="https://abbtrigger86c5.blob.core.chinacloudapi.cn/b2cpagelayout/zh-hans/logo.png" />
					</div>
					<div className="header-title">Smart Powertrain</div>
					<div className="top-header-inner">
						{this.isvaliduser()}
						{/* <Button onClick={()=>this.tjfn()}>测试登录事件</Button>
					<Button onClick={()=>this.zcfn()}>测试注册事件</Button> */}
						<div className="header-right">
							{/* <Button onClick={()=>this.FnhandleClick()}>{this.state.language}</Button> */}
							{getRouterLinkIndex === 0 || getRouterLinkIndex === 6 || this.state.settingIcon === 'show' ? (
								<div className={getRouterLinkIndex === 6 ? 'setting_active' : 'setting'} onClick={() => this.Fnselect(6)}>
									<ABB.Icon name="abb/settings" sizeClass="medium" />
								</div>
							) : ''
							}

							<div className={getRouterLinkIndex === 7 ? 'setting_active' : 'setting'}
								onClick={() => this.Fnselect(7)}>
								<ABB.Icon name="abb/alarm-bell" sizeClass='medium' />
								{/* <span className="newcount">{this.props.geteventcount}</span> */}
							</div>


							{/* <div className={getRouterLinkIndex === 7 ? 'setting_active' : 'setting'} onClick={() => this.Fnselect(7)}>
								<ABB.Icon name="abb/alarm-bell" sizeClass="medium" />
								<span className="newcount">{this.props.geteventcount}</span>
							</div> */}

							<div className="userinfo_box">
								<Dropdown trigger={['click']} overlay={DropdownList}>
									<div onClick={() => this.Fnselect(8)} className={getRouterLinkIndex === 8 || getRouterLinkIndex === 10 ? 'userinfo_box_active' : 'userinfo_boxin'} style={{ marginBottom: 0 }}>
										<div style={{ paddingRight: 8 }}>
											<ABB.Icon name="abb/user-in-circle" sizeClass="medium" />
										</div>

										<div className="userName">{this.props.userInfo.userName}</div>
										<div style={{ paddingTop: 3 }}>
											<ABB.Icon name="abb/down" sizeClass="small" />
										</div>
									</div>
								</Dropdown>
							</div>
						</div>
					</div>
					{tags.show ? <Tags /> : null}
					<BasicDrawer title="添加部件" closable onClose={this.onClose} visible={this.state.visible} onChangeTags={this.onChangeTags} onChangeBreadCrumb={this.onChangeBreadCrumb} onChangeTheme={this.onChangeTheme} {...this.props} />
				</div>
				<div>
					{
						this.state.orgListVisible &&
						<AvailableOrgListModal
							disabled={this.state.disabled}
							visible={this.state.orgListVisible}
							changeOrglistVisible={this.changeOrglistVisible}
							orgList={this.state.org_list}
							updateUserOrgMapping={this.updateUserOrgMapping}
							userOrg={this.props.userInfo.orgId} />
					}
				</div>
				<MigrationModel userInfo={this.props.userInfo} onRef={this.onRef} />
			</>
		);
	}
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setCollapse: data => {
		dispatch(setCollapse(data));
	},
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	emptyTag: () => {
		dispatch(emptyTag());
	},
	addTag: data => {
		dispatch(addTag(data));
	},
	setBreadCrumb: data => {
		dispatch(setBreadCrumb(data));
	},
	setTags: data => {
		dispatch(setTags(data));
	},
	settreeValue: data => {
		dispatch(settreeValue(data));
	},
	setTheme: data => {
		dispatch(setTheme(data));
	},
	deviceDetail: data => {
		dispatch(deviceDetail(data));
	},
	selectequipment: data => {
		dispatch(selectequipment(data));
	},
	setEventcount: data => {
		dispatch(setEventcount(data));
	},
	setRouterLinkIndex: data => {
		dispatch(setRouterLinkIndex(data));
	},
	userCheckDevice: data => {
		dispatch(userCheckDevice(data))
	},
	checkAllgen: data => {
		dispatch(checkAllgen(data))
	},
	setassetId: data => {
		dispatch(setassetId(data))
	},
	settree: data => {
        dispatch(settree(data))
    },
    settreeStatus: data => {
        dispatch(settreeStatus(data))
    },
    settreeCopy: data => {
        dispatch(settreeCopy(data))
    },
	updateOrgInfo: data => {
        dispatch(setOrgInfo(data))
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopHeader));
