import React, { Component } from 'react';
import { Input, Table, Tooltip, message, Descriptions, Tree, Divider, Row, Col, Select, Menu, Dropdown } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { APICheckOrgInfo, APICheckOrgList } from '@/api/tools'
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import EditOrg from './components/editOrg'
import { setToolEditOrgConfig } from '../../store/actionCreators';

const icon_center = {
	margin: '10px 0 0 0',
	display: 'inline',
	verticalAlign: 'middle'
};
const div_nowrap = {
	margin: '0 10px 0px 20px',
	display: 'inline'
};

const { Search } = Input;
const { Option } = Select;
class CheckOrganization extends Component {
	state = {
		pagination: {},
		loading: false,
		modal2Visible: false,
		selectedRowKeys: [],
		pageindex: 1,
		pageSize: 10,
		location: '###',
		orgInfo: {
			lastModifyRecord:undefined
		},
		orgList: [],
		selectedOrgId: undefined,
		treeData: [
			{
				title: '设备组',
				key: '0-0',
				icon: <ABB.Icon name="abb/hardware-devices" sizeClass="small" />,
				children: [
					{
						title: '设备组1',
						key: '0-0-0',
						icon: <ABB.Icon name="abb/hardware-devices" sizeClass="small" />
					},
					{
						title: '设备组2',
						key: '0-0-1',
						icon: <ABB.Icon name="abb/hardware-devices" sizeClass="small" />
					}
				]
			}
		],
		showEditOrg: false
	};


	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	componentDidMount() {
		this.fetchOrgList();
	}
	// 搜索数据
	onserch = value => {

		console.log('000----------------000000')
		this.fetch(value.replace(/\s*/g, ''));
	};
	//接口数据
	fetch = (value) => {

		console.log("fetch value=============", value)


		let that = this;
		APICheckOrgInfo(value)
			.then(res => {
				if (res !== undefined) {
					let resdata = res.data.data;

					console.log("resdata==================", resdata)
					this.setState({
						orgInfo: {
							orgName: resdata.orgName,
							orgType: resdata.orgType,
							SSAdministratorEmail: resdata.SSAdministratorEmail ? resdata.SSAdministratorEmail : '-',
							SSOrgName: resdata.SSOrgName ? resdata.SSOrgName : '-',
							grantedOrgName: resdata.grantedOrgName ? resdata.grantedOrgName : '-',
							isBoundSS: resdata.isBoundSS ? '已绑定' : '未绑定',
							isGranted: resdata.isGranted ? '已授权' : '未授权',
							orgAdministrator: resdata.orgAdministrator ? resdata.orgAdministrator : '-',
							orgCreatedDate:  resdata.orgCreatedDate  ? resdata.orgCreatedDate : '-',
							orgId: value,
							lastModifyRecord: resdata.lastModifyRecord,
							orgIndustry:resdata.orgIndustry  ? resdata.orgIndustry : '-'

						},
						showEditOrg: true
					});
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data.error.message);
				} else {
					message.error('查询组织详细信息接口异常');
				}
				that.setState({
					orgInfo: {}
				})

			});
	};
	// onChange = e => {
	// 	this.setState({
	// 		pageindex: e
	// 	});
	// 	this.fetch(e, '');
	// };
	fetchOrgList = () => {
		APICheckOrgList()
			.then(res => {
				if (res !== undefined) {

					console.log("获取组织列表----------------", res)
					this.setState({
						orgList: res.data.data

					});
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data.error.message);
				} else {
					message.error('查询组织列表接口异常');
				}

			});
	}
	onChange = e => {
		this.setState({
			selectedOrgId: e
		})
		this.fetch(e);


	};
	// onFocus = () => {
	// 	this.fetchOrgList();
	// }
	onFocus = () => {

	}

	_editOrg = () => {
		
	 
		const config = { ...this.props.getToolEditOrgConfig }
		config.visible = true
		config.orgInfo = this.state.orgInfo

		console.log("编辑组织信息 =============", config)

		this.props.updateToolEditOrgConfig(config)
	}

	render() {
		return (
			<>
				<div className="user_box ABB_tab_marginTop">
					<Select
						showSearch
						placeholder="输入组织名称"
						optionFilterProp="children"
						suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
						style={{ width: 288, marginRight: '64px' }}
						onChange={this.onChange}
						onFocus={this.onFocus}
						value={this.state.selectedOrgId}
						filterOption={(input, option) =>
							option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}

					>
						{this.state.orgList.map((item, index) => (
							<Option value={item.org_id} key={index}>
								{item.org_name}
							</Option>
						))}
					</Select>
				</div>
				<div className="EUorg_title">
					<span>组织信息</span>
					{

						this.state.showEditOrg === true ? <Dropdown
							trigger={['click']}
							overlay={

								<Menu style={{ width: 150 }}>
									<Menu.Item onClick={() => this._editOrg()}>
										<ABB.Icon name="abb/edit" sizeClass='small' /> &nbsp; 编辑组织信息
									</Menu.Item>

								</Menu>}

						>
							<div
								onClick={() => {
									console.log("11111111111")
								}}>
								<ABB.Icon name="abb/menu" sizeClass='small'
									style={{ 'color': '#1F1F1F' }}
								/>
							</div>

						</Dropdown> : null
					}


				</div>
				{this.state.orgInfo.orgName ? (
					<Descriptions bordered column={4}>
						<Descriptions.Item label="组织名称">{this.state.orgInfo.orgName}</Descriptions.Item>
						<Descriptions.Item label="所属行业">{this.state.orgInfo.orgIndustry}</Descriptions.Item>
						<Descriptions.Item label="组织管理员">{this.state.orgInfo.orgAdministrator}</Descriptions.Item>
						<Descriptions.Item label="组织类型">{this.state.orgInfo.orgType}</Descriptions.Item>
						<Descriptions.Item label="组织创建时间">{this.state.orgInfo.orgCreatedDate}</Descriptions.Item>
						<Descriptions.Item label="授权经销商情况" span={1}>
							{this.state.orgInfo.isGranted}
						</Descriptions.Item>
						<Descriptions.Item label="授权经销商" span={3}>
							{this.state.orgInfo.grantedOrgName}
						</Descriptions.Item>
						<Descriptions.Item label="SmartSensor绑定的情况">{this.state.orgInfo.isBoundSS}</Descriptions.Item>
						<Descriptions.Item label="SmartSensor组织名称">{this.state.orgInfo.SSOrgName}</Descriptions.Item>
						<Descriptions.Item label="SmartSensor邮箱管理员">{this.state.orgInfo.SSAdministratorEmail}</Descriptions.Item>
					</Descriptions>

				) : (
					<div className="renderEmpty renderEmpty_flex" style={{ position: "relative", height: '80vh' }}>
						<div className="renderEmpty_center">
							<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
							<p>暂无数据</p>
						</div>
					</div>
				)}
				{/* <>
						<Row>
							<Col span={4}>
								<div className="EUorg_title">
									<span>设备组信息</span>
								</div>
								<Tree showIcon defaultExpandAll treeData={this.state.treeData} />
							</Col>
							<Col span={4} push={1}>
								<div className="EUorg_title">
									<span>用户组及权限</span>
								</div>
								<Tree showIcon defaultExpandAll treeData={this.state.treeData} />
							</Col>
							<Col span={8}></Col>
						</Row>
					</> */}

				<EditOrg orgInfo={this.state.orgInfo} submitmodel={() => {
					console.log("EditOrg-----------更新组织信息")
					this.fetch(this.props.getToolEditOrgConfig.orgInfo.orgId)
				}} />



			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	updateToolEditOrgConfig: data => {
		dispatch(setToolEditOrgConfig(data));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckOrganization));
