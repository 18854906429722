import React, { Component } from 'react';
import { Modal, Button, message, Icon } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class AddressModel extends Component {
    // constructor(props) {
    //     super(props);

    // }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = () => {
        this.props.hidemodel()
    }

    render() {
        const that = this;
        const { showModel } = this.props;

        const modelProps = {
            title: "提示",
            maskClosable: false,
            centered: true,
            width: '504px',
            visible: showModel.visible,
            onOk: () => { that.hidemodel() },
            onCancel: () => { that.hidemodel() },
            footer: [

                <ABB.Button
                    onClick={() => that.hidemodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确认"
                />,
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                {
                    showModel.type === 0 && (
                        <div>
                            <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
                            <span style={{ marginLeft: '8px' }}>{showModel.message}</span>
                        </div>
                    )
                }

                {
                    showModel.type === 1 && (
                        <div>
                            <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#ffc107" />
                            <span style={{ marginLeft: '8px' }}>{showModel.message}</span>
                        </div>
                    )
                }

                {
                    showModel.type === 2 && (
                        <div>
                            <Icon type="close-circle" theme="twoTone" twoToneColor="#ff0000" />
                            <span style={{ marginLeft: '8px' }}>{showModel.message}</span>
                        </div>
                    )
                }
            </Modal>
        );
    }
}

export default AddressModel;
