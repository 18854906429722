import React, { Component } from 'react';
// import $axios from '../../axios/$axios';
import {equipmentdetail} from '@/api/device.js'
import { Icon,message } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { eventDetaildevice,checkAllgen,deviceDetail, deviceTime, setselect,setFilterDevice} from '@/redux/actions/device';
import { setAssetlabel,setAssetStatus,setAssetIsPowertrain,setassetId,setResTrendsCode, setassettype, deviceTime, deviceDetail,checkAllgen,eventDetaildevice,userCheckDevice } from '@/redux/actions/device';
import intl from 'react-intl-universal';
import { FndeviceDetail,FntrendsCharts } from '@/utils/devicesData'
import { loading } from '@/redux/actions/setting';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import SvgAbbTrend332 from '@/components/trend3_32';

const generalnformation = {
    // border: '1px solid #d8d8d8',
    display: 'flex',
    width: '800px',
    // justifyContent: 'space-between',
    flexWrap: 'nowrap',
    // padding: '10px',
    marginBottom: '10px',
    overflow: 'hidden'
}
const EUorg_title = {
	lineHeight: '40px',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexFlow: 'row',
    color: '#1F1F1F'
}
const gen_li = {
    width: "200px",
    display: "flex",
    flexWrap: "nowrap",
    lineHeight: "30px",
    fontSize: "16px",
    color: "#3d3d3d",
    position: "relative",
    paddingRight: "16px"
}
const EUorg_title_back ={
    lineHeight: '30px',
    fontSize: '18px',
    
    color: '#1F1F1F'
}
const checkdetail = {
    color: "#3366FF",
    lineHeight: '30px',
    fontSize: '14px',
    cursor: 'pointer',
    margin: '0 6px 0 0'
}

const device_date = {
    // marginBottom: '10px',
    fontSize: '14px',
    color: '#525252'
}
class DeviceAlltype extends Component {
	state = {
        deviceDetails: {},
	};

    //根据id获取详情
	FndeviceDetail(id,type,status){
        this.props.setassetId(id)
        this.props.userCheckDevice(3)
		this.props.setAssetStatus(status)
		let that = this;
		let datatime = {
			startime: that.props.deviceTrendtime.startime,
			endtime: that.props.deviceTrendtime.endtime
        }
        let isSubDevice = ``
        this.props.setassettype(type)
		this.props.setAssetIsPowertrain(true)
        this.props.setAssetlabel(["传动链","设备详情"])
	}
    //类型icon
    typeIcon=(data,assetType,deviceType,subscriptioncof)=>{
        let _assetType = '';
        let drive_status = `conditionindex.desc.`;
        if(assetType===2){
            _assetType = 'drive'
            drive_status = `conditionindex.desc.${_assetType}.`;
        }
        else if(assetType===3){
            _assetType = 'motor'
            drive_status = `conditionindex.desc.${_assetType}.`;
        }
        else if(assetType===1){
            
            if(deviceType===3){
                _assetType = 'motor'
                drive_status = `conditionindex.desc.${_assetType}.`;
            }
            else if(deviceType===2){
                _assetType = 'drive'
                drive_status = `conditionindex.desc.${_assetType}.`;
            }
        }
        
        let conditionindexName = `conditionindex.name.`;


        let subscriptioncofarr = [];
        subscriptioncof.map(item=>{
            item.config.map(items=>{
                if (items.featureCode === 21) {
                    let stringResult = items.limitValue.split(',');
                    subscriptioncofarr = stringResult;
                    
                }
            })
            
        })
        return (
            <>
            
            {
                subscriptioncofarr.map(item=>{
                    if(item === "1"){
        
                    }
                    if(item === "2"){
                        if (data.type === 'availability') {
                            if (data.status === 'good') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#0CA919' }} name="abb/warning-circle-2" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}availability`)}</span>
                                            <div>{intl.get(`${drive_status}availability.good`)}</div>
                
                                        </div>
                                    </>
                                )
                            }
                            else if (data.status === 'tolerable') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-circle-2" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}availability`)}</span>
                                            <div>{intl.get(`${drive_status}availability.tolerable`)}</div>
                
                                        </div>
                                    </>
                                )
                            }
                            else if (data.status === 'poor') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#F03040' }} name="abb/warning-circle-2" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}availability`)}</span>
                                            <div>{intl.get(`${drive_status}availability.poor`)}</div>
                
                                        </div>
                                    </>
                
                                )
                            }
                            else if (data.status === 'unknown') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#BABABA' }} name="abb/warning-circle-2" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}availability`)}</span>
                                            <div>{intl.get(`${drive_status}availability.unknown`)}</div>
                                        </div>
                                    </>
                                )
                            }
                        }
                    }
                    if(item === "3"){
                        if (data.type === 'environment') {
                            if (data.status === 'good') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#0CA919' }} name="abb/temperature" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}environment`)}</span>
                                            <div>{intl.get(`${drive_status}environment.good`)}</div>
                
                                        </div>
                                    </>
                                )
                            }
                            else if (data.status === 'tolerable') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/temperature" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}environment`)}</span>
                                            <div>{intl.get(`${drive_status}environment.tolerable`)}</div>
                
                                        </div>
                                    </>
                                )
                            }
                            else if (data.status === 'poor') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#F03040' }} name="abb/temperature" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}environment`)}</span>
                                            <div>{intl.get(`${drive_status}environment.poor`)}</div>
                
                                        </div>
                                    </>
                
                                )
                            }
                            else if (data.status === 'unknown') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#BABABA' }} name="abb/temperature" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}environment`)}</span>
                                            <div>{intl.get(`${drive_status}environment.unknown`)}</div>
                                        </div>
                                    </>
                                )
                            }
                        }
                    }
                    if(item === "4"){
                        if (data.type === 'reliability') {
                            if (data.status === 'good') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#0CA919' }} name="abb/pie-chart" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}reliability`)}</span>
                                            <div>{intl.get(`${drive_status}reliability.good`)}</div>
                
                                        </div>
                                    </>
                                )
                            }
                            else if (data.status === 'tolerable') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/pie-chart" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}reliability`)}</span>
                                            <div>{intl.get(`${drive_status}reliability.tolerable`)}</div>
                
                                        </div>
                                    </>
                                )
                            }
                            else if (data.status === 'poor') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#F03040' }} name="abb/pie-chart" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}reliability`)}</span>
                                            <div>{intl.get(`${drive_status}reliability.poor`)}</div>
                
                                        </div>
                                    </>
                
                                )
                            }
                            else if (data.status === 'unknown') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <ABB.Icon style={{ 'color': '#BABABA' }} name="abb/pie-chart" sizeClass='large' />
                                        </div>
                                        <div className='device_gen_title'>
                                            <span>{intl.get(`${conditionindexName}reliability`)}</span>
                                            <div>{intl.get(`${drive_status}reliability.unknown`)}</div>
                                        </div>
                                    </>
                                )
                            }
                        }
                    }
                    if(item === "5"){
                        if (data.type === 'stress') {
                            if (data.status === 'good') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <Icon style={{ 'color': '#0CA919' }} component={SvgAbbTrend332} />
                
                                        </div>
                                        <div className='device_gen_title'>
                                                {
                                                    deviceType === 2 ? (
                                                        <span>{intl.get(conditionindexName + 'stress')}</span>
                                                    ) : (
                                                        <span>负荷</span>
                                                    )
                                                }
                                            <div>{intl.get(`${drive_status}stress.good`)}</div>
                
                                        </div>
                                    </>
                                )
                            }
                            else if (data.status === 'tolerable') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <Icon style={{ 'color': '#FFD800' }} component={SvgAbbTrend332} />
                                        </div>
                                        <div className='device_gen_title'>
                                        {
                                                    deviceType === 2 ? (
                                                        <span>{intl.get(conditionindexName + 'stress')}</span>
                                                    ) : (
                                                        <span>负荷</span>
                                                    )
                                                }
                                            <div>{intl.get(`${drive_status}stress.tolerable`)}</div>
                
                                        </div>
                                    </>
                                )
                            }
                            else if (data.status === 'poor') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <Icon style={{ 'color': '#F03040' }} component={SvgAbbTrend332} />
                                        </div>
                                        <div className='device_gen_title'>
                                        {
                                                    deviceType === 2 ? (
                                                        <span>{intl.get(conditionindexName + 'stress')}</span>
                                                    ) : (
                                                        <span>负荷</span>
                                                    )
                                                }
                                            <div>{intl.get(`${drive_status}stress.poor`)}</div>
                
                                        </div>
                                    </>
                
                                )
                            }
                            else if (data.status === 'unknown') {
                                return (
                                    <>
                                        <div className='devie_gen_icon'>
                                            <Icon style={{ 'color': '#BABABA' }} component={SvgAbbTrend332} />
                                        </div>
                                        <div className='device_gen_title'>
                                        {
                                                    deviceType === 2 ? (
                                                        <span>{intl.get(conditionindexName + 'stress')}</span>
                                                    ) : (
                                                        <span>负荷</span>
                                                    )
                                                }
                                            <div>{intl.get(`${drive_status}stress.unknown`)}</div>
                                        </div>
                                    </>
                                )
                            }
                        }
                    }
                })
            }
            </>
        )



        if(data.type==='stress'){
            if(data.status==='good'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <Icon style={{ 'color': '#0CA919' }} component={SvgAbbTrend332} />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(conditionindexName+'stress')}</span>
                            <div>{intl.get(`${drive_status}stress.good`)}</div>
                            
                        </div>
                    </>
                )
            }
            else if(data.status==='tolerable'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <Icon style={{ 'color': '#FFD800' }} component={SvgAbbTrend332} />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(conditionindexName+'stress')}</span>
                            <div>{intl.get(`${drive_status}stress.tolerable`)}</div>
                            
                        </div>
                    </>
                )
            }
            else if(data.status==='poor'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <Icon style={{ 'color': '#F03040' }} component={SvgAbbTrend332} />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(conditionindexName+'stress')}</span>
                            <div>{intl.get(`${drive_status}stress.poor`)}</div>
                            
                        </div>
                    </>
                    
                )
            }
            else if(data.status==='unknown'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <Icon style={{ 'color': '#BABABA' }} component={SvgAbbTrend332} />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(conditionindexName+'stress')}</span>
                            <div>{intl.get(`${drive_status}stress.unknown`)}</div>
                        </div>
                    </>
                )
            }
        }
        else if(data.type==='reliability'){
            if(data.status==='good'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#0CA919' }} name="abb/pie-chart" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}reliability`)}</span>
                            <div>{intl.get(`${drive_status}reliability.good`)}</div>
                            
                        </div>
                    </>
                )
            }
            else if(data.status==='tolerable'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/pie-chart" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}reliability`)}</span>
                            <div>{intl.get(`${drive_status}reliability.tolerable`)}</div>
                            
                        </div>
                    </>
                )
            }
            else if(data.status==='poor'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#F03040' }} name="abb/pie-chart" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}reliability`)}</span>
                            <div>{intl.get(`${drive_status}reliability.poor`)}</div>
                            
                        </div>
                    </>
                    
                )
            }
            else if(data.status==='unknown'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#BABABA' }} name="abb/pie-chart" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}reliability`)}</span>
                            <div>{intl.get(`${drive_status}reliability.unknown`)}</div>
                        </div>
                    </>
                )
            }
        }
        else if(data.type==='environment'){
            if(data.status==='good'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#0CA919' }} name="abb/temperature" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}environment`)}</span>
                            <div>{intl.get(`${drive_status}environment.good`)}</div>
                            
                        </div>
                    </>
                )
            }
            else if(data.status==='tolerable'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/temperature" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}environment`)}</span>
                            <div>{intl.get(`${drive_status}environment.tolerable`)}</div>
                            
                        </div>
                    </>
                )
            }
            else if(data.status==='poor'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#F03040' }} name="abb/temperature" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}environment`)}</span>
                            <div>{intl.get(`${drive_status}environment.poor`)}</div>
                            
                        </div>
                    </>
                    
                )
            }
            else if(data.status==='unknown'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#BABABA' }} name="abb/temperature" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}environment`)}</span>
                            <div>{intl.get(`${drive_status}environment.unknown`)}</div>
                        </div>
                    </>
                )
            }
        }
        else if(data.type==='availability'){
            if(data.status==='good'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#0CA919' }} name="abb/warning-circle-2" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}availability`)}</span>
                            <div>{intl.get(`${drive_status}availability.good`)}</div>
                            
                        </div>
                    </>
                )
            }
            else if(data.status==='tolerable'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-circle-2" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}availability`)}</span>
                            <div>{intl.get(`${drive_status}availability.tolerable`)}</div>
                            
                        </div>
                    </>
                )
            }
            else if(data.status==='poor'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#F03040' }} name="abb/warning-circle-2" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}availability`)}</span>
                            <div>{intl.get(`${drive_status}availability.poor`)}</div>
                            
                        </div>
                    </>
                    
                )
            }
            else if(data.status==='unknown'){
                return (
                    <>
                        <div className='devie_gen_icon'>
                            <ABB.Icon style={{ 'color': '#BABABA' }} name="abb/warning-circle-2" sizeClass='large' />
                        </div>
                        <div className='device_gen_title'>
                            <span>{intl.get(`${conditionindexName}availability`)}</span>
                            <div>{intl.get(`${drive_status}availability.unknown`)}</div>
                        </div>
                    </>
                )
            }
        }
    }
    
    //总体状态
    overall=(data)=>{
        if(data===2){
            return (
                <>
                    <div className="asset_type_icon asset_type_icon3"></div>
                    <div>未知</div>
                </>
            )
        }
        else if(data===3){
            return (
                <>
                    <div className="asset_type_icon asset_type_icon1"></div>
                    <div>良好</div>
                </>
            )
        }
        else if(data===4){
            return (
                <>
                    <div className="asset_type_icon asset_type_icon4"></div>
                    <div>一般</div>
                </>
            )
        }
        else if(data===5){
            return (
                <>
                    <div className="asset_type_icon asset_type_icon2"></div>
                    <div>较差</div>
                </>
            )
        }
    }
    backupper=()=>{
        this.props.userCheckDevice(0)
        
    }
    //设备列表设备类型icon(16)渲染
    assetIcon16 = (data) => {
        if (data.assetType === 3 || data.deviceType === 3) {
            return (
                <ABB.Icon className="asset_icon" style={{margin:'0 8px 0 0'}} name="abb/motor" sizeClass='small' />
            )
        }
        else if (data.assetType === 2 || data.deviceType === 2) {
            return (
                <ABB.Icon className="asset_icon" style={{margin:'0 8px 0 0'}} name="abb/drive" sizeClass='small' />
            )
        }
        else if (data.assetType === 1) {
            return (
                <ABB.Icon className="asset_icon" style={{margin:'0 8px 0 0'}} name="abb/powertrain" sizeClass='small' />
            )
        }
    }
    
	render() {
        let _self = this;
        const {_deviceDetail,UsercheckDeviceDetail,getsubscriptioncof} = _self.props;
        let objnull = (JSON.stringify(_deviceDetail) === "{}")
		return (
            <>
            <div className='deviceDetail_status_top'>
                <div className='assetName'>
                    <ABB.Icon className="asset_icon" style={{margin:'0 8px 0 0'}} name="abb/powertrain" sizeClass='medium' />
                    {_deviceDetail.assetName}
                </div>
            </div>
			<div className="deviceDetail_status">
                {
                    UsercheckDeviceDetail === 1 ? (
                        <div style={EUorg_title_back} onClick={this.backupper}>
                        <ABB.Icon name="abb/left" sizeClass='medium'/>返回</div>
                    ): ''
                }
                {
                   objnull===false ? (
                        <div>
                            {
                                _deviceDetail.devices.map(function(item,index){
                                    return (
                                        <>
                                        <div className='asset_title' style= {{margin: '0 0 0px 0'}}>
                                            <div className="box_flex" style={{fontSize: 20,marginBottom: 8,height:25}}>
                                                {_self.assetIcon16(item)}
                                                {item.deviceName}
                                            </div>
                                            <div style={checkdetail} onClick={()=>_self.FndeviceDetail(item.deviceId,'PowerTrain',item.subscription.subscriptionStatus)}>
                                                全部
                                               
                                            </div>
                                        </div>
                                        <div className="asset_type">
                                        {
                                              item.conditions.map(function(items,index){
                                                return (
                                                    <>
                                                    {
                                                        items.type==='overall'? (
                                                            <div className="asset_type">
                                                                <div>总体状态:</div>
                                                                
                                                                {
                                                                    _self.overall(items.statusId)    
                                                                }                                                      
                                                            </div>
                                                        ): ''
                                                    }
                                                    
                                                    </>
                                                )
                                            })
                                          }
                                        </div>
                                          
                                          <div style={generalnformation}>
                                          {
                                              item.conditions.map(function(items,index){
                                                return (  
                                                    <>
                                                        {
                                                            items.type!=='overall'?(
                                                                <div style={gen_li}>
                                                                    {
                                                                        _self.typeIcon(items,_deviceDetail.assetType,item.deviceType,getsubscriptioncof)
                                                                    }
                                                                </div>
                                                            ): ''
                                                        }
                                                    </>
                                                )
                                            })
                                          }
                                          </div>
                                        </>
                                    )
                                    
                                })
                            }
                                              
                           
                            
                        </div>
                        
                    ): "数据为空!"
                }  
                {/* <div style={device_date}>可用性每五分钟测量一次,所有其他条件每60分钟测量一次</div>               */}
			</div>
            </>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setAssetlabel: data => {
		dispatch(setAssetlabel(data));
	},
    setAssetStatus:  data => {
		dispatch(setAssetStatus(data));
    },
    setAssetIsPowertrain: data => {
		dispatch(setAssetIsPowertrain(data));
    },
    setassetId: data => {
		dispatch(setassetId(data));
    },
    setResTrendsCode: data => {
    
		dispatch(setResTrendsCode(data));
    },
	deviceDetail: data => {
    
		dispatch(deviceDetail(data));
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    },
    userCheckDevice: data =>{
		dispatch(userCheckDevice(data))
    },
    deviceTime: data => {
		dispatch(deviceTime(data))
    },
    loading: data => {
		dispatch(loading(data))
    },
    setassettype: data =>{
		dispatch(setassettype(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(DeviceAlltype));