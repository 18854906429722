import * as React from 'react';

function SvgAbbTrend316(props) {
    return (
        <svg viewBox="0 0 16 16" width="16px" height="16px" >
            <defs>
                <style>{'.abb_trend-3_16_svg__cls-2{fill:currentColor}'}</style>
            </defs>
            <path fill="none" d="M0 0h16v16H0z" id="abb_trend-3_16_svg__Box" />
            <g id="abb_trend-3_16_svg__Drive">
                <path className="abb_trend-3_16_svg__cls-2" d="M10.33 10.73L6.67 7.06 3.53 10.2 2.47 9.14l4.2-4.2 3.66 3.67 4.14-4.14 1.06 1.06-5.2 5.2z" />
                <circle className="abb_trend-3_16_svg__cls-2" cx={6.5} cy={5.5} r={1.5} />
                <circle className="abb_trend-3_16_svg__cls-2" cx={10.5} cy={9.5} r={1.5} />
                <path className="abb_trend-3_16_svg__cls-2" d="M1 2h1v12H1z" />
                <path className="abb_trend-3_16_svg__cls-2" d="M1 13h14v1H1z" />
            </g>
        </svg>
    );
}

export default SvgAbbTrend316;