import React, { Component } from 'react';
import { Icon,Spin } from 'antd';
const loadings = {
    width: "100%",
    height: "100%",
    background: "rgba(205, 215, 224, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 999999,
}
const loadings_center = {
    width: "30px",
    height: "30px",
    // background: "#000",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
	// z-index: 100009;
}
class Loading extends Component {
    render() {
        return (
            <div style={loadings}>
                 <div style = {loadings_center}>
                 <Spin spinning={true} style={{ height: 100 }}></Spin>
                    {/* <Icon style = {{'fontSize':'28px','cursor': 'pointer'}} type="loading" /> */}
                 </div>
            </div>
        )
    }
}

export default Loading;