import * as actionTypes from '../constants/index';

const selectUsergroup = data => {
	return {
		type: actionTypes.SELECT_USERGROUP,
		data
	};
};

const selectequipment = data => {
	return {
		type: actionTypes.SELECT_EQUIPMENT,
		data
	};
};

const setserchUser = data => {
	return {
		type: actionTypes.SET_SERCHUSER,
		data
	};
};

const setOrginformation = data => {
	return {
		type: actionTypes.SET_ORGINFORMATION,
		data
	};
};
const setcustomersList = data => {
	return {
		type: actionTypes.SET_CUSTOMERS,
		data
	};
};

export { selectUsergroup,selectequipment,setserchUser,setOrginformation,setcustomersList };
