import React, { Component } from 'react';
import { Modal, Button, Input, message, Form } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Search } = Input;
class AddassetGroupForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: false,
            isZn_CH: false
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.props.form.resetFields();
    }
    submitmodel = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.props.submitmodel(fieldsValue)
                setTimeout(() => {
                    this.props.form.resetFields();
                }, 1000)
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
            }
        });
    };
    render() {
        const that = this;
        const { showassetGroupmodel,disabled } = this.props;
        const { getFieldDecorator } = that.props.form;


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showassetGroupmodel.title,
            maskClosable: false,
            centered: showassetGroupmodel.centered,
            width: showassetGroupmodel.width,
            visible: showassetGroupmodel.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    disabled={disabled}
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>


                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>
                    <Form.Item label="设备组名称">
                        {getFieldDecorator('deviceGroupName', {
                            initialValue: showassetGroupmodel.value,
                            rules: [

                                {
                                    required: true,
                                    message: '设备组名称不能为空',
                                },
                                {
                                    max: 20,
                                    message: '设备组名称长度不能大于20',
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            <Input placeholder="请输入设备组名称" className="model_input" />,
                        )}
                    </Form.Item>


                </Form>
                
            </Modal>
        );
    }
}
const AddassetGroup = Form.create({ name: 'register' })(AddassetGroupForm);

export default AddassetGroup;
