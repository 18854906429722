import React from 'react';
import { Drawer, Switch, Checkbox,Button } from 'antd';
const styles = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	paddingBottom: '8px'
};
const EUhomeweight = {
	width:'254px',
	height: '160px',
	border: '1px solid #ccc',
	// background: 'pink',
	margin: '20px 0',
	
}
const weight_title = {
	width: "100%",
	height: '30px',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '0 10px'
}
const weight_right = {
	margin: '0 10px',
	height: '116px',
	background: '#ccc',
	lineHeight: '116px',
	textAlign: 'center'
}
function onChange(checkedValues) {
	console.log('checked = ', checkedValues.target.checked);
}
const BasicDrawer = props => {
	return (
		<>
			<Drawer width={302} title={props.title} placement="right" closable={props.closable} onClose={props.onClose} visible={props.visible}>
				
				<div style={EUhomeweight}>
					<div style={weight_title}>
						<span>复合</span>
						<Checkbox disabled={true} onChange={onChange}/>
					</div>
					<div style={weight_right}>
					    comming soon
						<img src=""></img>
					</div>
				</div>
				<div style={EUhomeweight}>
					<div style={weight_title}>
						<span>地图</span>
						<Checkbox disabled={true}/>
					</div>
					<div style={weight_right}>
					comming soon
						<img src=""></img>
					</div>
				</div>
				<div style={EUhomeweight}>
					<div style={weight_title}>
						<span>设备卡片</span>
						<Checkbox disabled={true}/>
					</div>
					<div style={weight_right}>
					comming soon
						<img src=""></img>
					</div>
				</div>
				<Button type="danger" disabled={true}>添加</Button>
			</Drawer>
		</>
	);
};
export default BasicDrawer;
