import $axios from '../axios/$axios';

export function eventfilter(data){
   return $axios({
    url: '/api/v2/eventfilter'+data,
    method: 'get',
    type: 'json',
    data: ''
   })
}

export function alldrivetype(data){
    return $axios({
     url: '/api/v2/eventfilter/alldrivetype'+data,
     method: 'get',
     type: 'json',
     data: ''
    })
 }

 export function addeventfilter(data){
    return $axios({
     url: '/api/v2/eventfilter',
     method: 'post',
     type: 'json',
     data: data
    })
 }

 export function changeeventfilter(data){
    return $axios({
     url: '/api/v2/eventfilter',
     method: 'put',
     type: 'json',
     data: data
    })
 }

 export function eventfilterDelete(data){
    return $axios({
     url: '/api/v2/eventfilter/'+data,
     method: 'delete',
     type: 'json',
     data: ''
    })
 }