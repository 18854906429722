//编辑数字化连接调试信息
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Form,
    Input,
    Modal,
    message,
    Col,
    Row,
    Spin,

} from 'antd';
import { getsubscriptionCode } from '@/api/subscription';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { boundsubscription } from '@/api/subscription';
import './style.scss'
import { setDebugInformationConfig } from '../../store/actionCreators';
import { APICheckRouterCode, APIUpdateDebuginfo } from '../../../../../../api/device';
import { data } from 'jquery';


const ipRules =  [
    {
        required: true,
        message: 'IP不能为空',
    },

    {
        whitespace: true,
        message: '禁止输入空格',
    },
    {
        pattern: new RegExp(/^\b(1?[0-9]{1,2}|2[0-4][0-9]|25[0-5])\b/, "g"),
        message: 'ip格式错误',
    }
]

class DebuggingInformationModel extends React.Component {
    state = {

        disabled: false
    };

    hidemodel = (data) => {
        this.props.hidemodel()
        this.props.form.resetFields();
        var config = { ...this.props.getDebugInformationConfig }
        config.visible = false
        this.props.updateDebugInformationConfig(config)
    }

    submitmodel = () => {


        //显示提示信息 
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const { getDebugInformationConfig } = this.props;
                console.log("fieldsValue==============", fieldsValue)

                var params = {
                    "netaIPAddress": `${fieldsValue.ip1}.${fieldsValue.ip2}.${fieldsValue.ip3}.${fieldsValue.ip4}`, //NETA IP地址
                    "netaUserName": fieldsValue.netaUserName, //NETA UserName
                    "netaPassword": fieldsValue.netaPassword, //NETA Password
                    "routerSerialNo": fieldsValue.routerSerialNo, //路由器序列号
                    "routerPassword": fieldsValue.routerPassword, //路由器密码
                    "routerMACAddress": fieldsValue.routerMACAddress, //路由器MAC地址
                    "sIMICCID": fieldsValue.sIMICCID, //SIM卡ICCID
                    "deviceVersion": fieldsValue.deviceVersion, //设备版本号
                    "deviceNodeId": fieldsValue.deviceNodeId
                }

                console.log("params================", params)

                this.setState({
                    disabled: true
                })

                APIUpdateDebuginfo(this.props.getDebugInformationConfig.serialNumber, params).then(res => {
                     
                    let resdata = res.data;
                    console.log("resdata--------------",resdata)
                    
                    let data = {
                        routerSerialNo: fieldsValue.routerSerialNo,
                        gatewaySn: getDebugInformationConfig.gatewaySn,
                        devices: resdata.data,
                        deviceSn: this.props.getDebugInformationConfig.serialNumber
                    }
                    if (resdata !== undefined) {
                        this.hidemodel()
                        this.props.submitmodel(data)
                    }
                    this.setState({
                        disabled: false
                    })
                }).catch(err => {
                    this.setState({
                        loading: false,
                        disabled: false
                    })
                    if (err.data.code === 400 || err.data.code === 404) {
                        message.warning(err.data.errorMessage)
                    }
                    else {
                        message.error('设备详情接口异常')
                    }
                })

            }
        });

    }

    onSearch = () => {
        console.log("订阅序列号验证")
        // let val = this.state.inputval.toUpperCase().replace(/\s*/g, "");
        // if (val === '') {
        //     message.warning("请输入订阅序列号")
        // }
        // else {
        //     this.getsubscriptionCode(val.trim())
        // }



    }
    getsubscriptionCode = (e) => {

    }

    onChange = (e) => {
        // if (e.target.value === '') {
        //     this.setState({
        //         inputval: e.target.value,
        //         subdata: {}
        //     })
        //     return false;
        // }
        // this.setState({
        //     inputval: e.target.value
        // })
    }

    // 4G路由器信息 / NETA-21信息 / 变频器信息
    _renderSectionHeaderByTitle = (isMust, title) => {
        return <Row >
            <div className='section_item'>
                {isMust === true ? (<div className='section_icon'></div>) : null}
                <div >{title}</div>
            </div>
        </Row>
    }
    _renderInputHeaderByTitle = (isMust, title) => {
        return <Row >
            <div className='row_header_title'>
                {isMust === true ? (<div className='section_icon'></div>) : null}
                <div >{title}</div>
            </div>
        </Row>
    }

    //neta21 信息输入框 input onchange调用
    _inputOnChange = (e,key,options)=>{
        let title = e.target.value
        let showDebuggingInformationConfig = { ...this.props.getDebugInformationConfig ,...options}
        showDebuggingInformationConfig[key] = title
        console.log("showDebuggingInformationConfig.netaPassword=======",showDebuggingInformationConfig[key])
        this.props.updateDebugInformationConfig(showDebuggingInformationConfig)
    }

    //更新路由器序列号提示文案是否显示
    _updateisShowRouterRemind = ()=>{
        let config = { ...this.props.getDebugInformationConfig }
     
        config.isShowRouterRemind = false
     
        this.props.updateDebugInformationConfig(config)
    }

    //验证路由器序列号
    _checkRouter = () => {


        console.log("_checkRouter---------code", this.props.getDebugInformationConfig)

        // this.props.getDebugInformationConfig

        APICheckRouterCode(this.props.getDebugInformationConfig.routerSerialNo).then(res => {
            console.log("APICheckRouterCode===============sucess", res)
            let resdata = res.data;

            if (resdata !== undefined && resdata.data !== null) {

                let config = { ...this.props.getDebugInformationConfig }
                console.log("config============", config)

                config.routerPassword = resdata.data.routerPassword //路由器密码
                config.routerMACAddress = resdata.data.routerMACAddress  //路由器MAC地址
                config.sIMICCID = resdata.data.simiccid  //SIM卡ICCID
                config.isShowRouterRemind = true
                console.log("config============", config)
                this.props.form.resetFields();
                this.props.updateDebugInformationConfig(config)
                // this.hidemodel()
                // this.props.submitmodel()
            } else {
                let config = { ...this.props.getDebugInformationConfig }
                console.log("config============", config)

                config.routerPassword = "" //路由器密码
                config.routerMACAddress = ""  //路由器MAC地址
                config.sIMICCID = "" //SIM卡ICCID
                config.isShowRouterRemind = false
                console.log("config============", config)
                this.props.form.resetFields();
                this.props.updateDebugInformationConfig(config)
            }
        }).catch(err => {
            console.log("APICheckRouterCode=============err", err)
            this.setState({
                loading: false
            })
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('设备详情接口异常')
            }
        })

    }

    render() {
        let that = this;


        const { showmodelboundsub, getDebugInformationConfig } = that.props;

        const { getFieldDecorator } = that.props.form;


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        const modelProps = {
            title: getDebugInformationConfig.title,
            centered: true,
            width: 680,
            maskClosable: false,
            visible: showmodelboundsub.visible,

            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                    disabled={this.state.disabled}
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelboundsub)}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="提交"
                />,
            ]
        }

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <div className='sub_box'>
                        <div className='sub_text'>传动序列号：{getDebugInformationConfig.serialNumber} </div>
                        <div className='sub_text'>NETA-21序列号：{getDebugInformationConfig.gatewaySn}</div>
                    </div>


                    <div className='scrollContent'>

                        <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px', paddingRight: '10px' }}>
                            <div className='model_section'>
                                {
                                    this._renderSectionHeaderByTitle(false, "4G路由器信息")
                                }
                                <Row gutter={24}>
                                    <Col style={{ position: 'relative' }}>
                                        {
                                            this._renderInputHeaderByTitle(true, "路由器序列号")
                                        }

                                        <div className='line_wrapper' >
                                            <Form.Item label="">
                                                {getFieldDecorator('routerSerialNo', {
                                                    initialValue: getDebugInformationConfig.routerSerialNo,
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '路由器序列号不能为空',
                                                        },
                                                        {
                                                            whitespace: true,
                                                            message: '禁止输入空格',
                                                        }
                                                    ],
                                                })(
                                                    <Input className="router_model_input" value={getDebugInformationConfig.routerSerialNo} disabled={false} onChange={(e) => {
                                                        console.log("e================", e)
                                                        let title = e.target.value

                                                        let showDebuggingInformationConfig = { ...this.props.getDebugInformationConfig }

                                                        showDebuggingInformationConfig.routerSerialNo = title
                                                        if (title.length > 0) {
                                                            showDebuggingInformationConfig.checkRouterDisable = false
                                                        } else {
                                                            showDebuggingInformationConfig.checkRouterDisable = true
                                                        }
                                                        showDebuggingInformationConfig.isShowRouterRemind = false
                                                        this.props.updateDebugInformationConfig(showDebuggingInformationConfig)
                                                        // this._updateisShowRouterRemind()

                                                    }} placeholder='请输入路由器序列号' />
                                                )}
                                            </Form.Item>
                                            <div className='check_button'>
                                                <ABB.Button
                                                    onClick={() => that._checkRouter()}
                                                    type="primary-blue"
                                                    disabled={getDebugInformationConfig.checkRouterDisable}
                                                    sizeClass='small' theme="filled"
                                                    text="验证"
                                                />
                                            </div>

                                        </div>
                                       {
                                         getDebugInformationConfig.isShowRouterRemind === true  ?
                                          <div className='positionTitle'>
                                          <ABB.Icon style={{ cursor: "auto" }} name="abb/information-circle-2" sizeClass='small' />
                                          <span>系统已找到{getDebugInformationConfig.routerSerialNo}的信息记录已完成填写，请核对</span>
                                      </div>:null
                                       }
                                    </Col>

                                </Row>


                                <Row gutter={24}>
                                    <Col span={12} >
                                        {
                                            this._renderInputHeaderByTitle(true, "路由器密码")
                                        }

                                        <Form.Item label="">
                                            {getFieldDecorator('routerPassword', {
                                                initialValue: getDebugInformationConfig.routerPassword,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '路由器密码不能为空',
                                                    },
                                                    {
                                                        max: 30,
                                                        message: '字符最大长度为30'
                                                    },
                                                    {
                                                        whitespace: true,
                                                        message: '禁止输入空格',
                                                    }
                                                ],
                                            })(
                                                <Input placeholder="请输入路由器密码" className="model_input" onChange={()=>{
                                                    this._updateisShowRouterRemind()
                                                }}/>,
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        {
                                            this._renderInputHeaderByTitle(false, "路由器MAC地址")
                                        }

                                        <Form.Item label="">
                                            {getFieldDecorator('routerMACAddress', {
                                                initialValue: getDebugInformationConfig.routerMACAddress,
                                                rules: [
                                                    {
                                                        whitespace: true,
                                                        message: '禁止输入空格',
                                                    }
                                                ],
                                            })(
                                                <Input placeholder="请输入路由器MAC地址" className="model_input" onChange={()=>{
                                                    this._updateisShowRouterRemind()
                                                }}/>,
                                            )}
                                        </Form.Item>
                                    </Col>

                                </Row>

                                <Row gutter={24}>
                                    <Col>
                                        {
                                            this._renderInputHeaderByTitle(true, "SIM卡ICCID")
                                        }

                                        <Form.Item label="">
                                            {getFieldDecorator('sIMICCID', {
                                                initialValue: getDebugInformationConfig.sIMICCID,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: 'SIM卡ICCID不能为空',
                                                    },
                                                    {
                                                        max: 30,
                                                        message: '字符最大长度为30'
                                                    },
                                                    {
                                                        whitespace: true,
                                                        message: '禁止输入空格',
                                                    }
                                                ],
                                            })(
                                                <Input placeholder="请输入SIM卡ICCID" className="model_input" onChange={()=>
                                                {
                                                    this._updateisShowRouterRemind()
                                                }}/>,
                                            )}
                                        </Form.Item>


                                    </Col>


                                </Row>

                            </div>

                            <div className='model_section'>

                                {
                                    this._renderSectionHeaderByTitle(false, "NETA-21信息")
                                }

                                <Row gutter={24}>

                                    <Col >
                                        {
                                            this._renderInputHeaderByTitle(true, "NETA-21 IP地址")
                                        }


                                        <div className='line_wrapper'>
                                            <div className='ip_input_content'>
                                                <Form.Item label="">
                                                    {getFieldDecorator('ip1', {
                                                        initialValue: getDebugInformationConfig.ip1,
                                                        rules:  ipRules,
                                                    })(
                                                        <Input className="ip_model_input" disabled={false} placeholder='请输入' onChange={(e) => {
                                                            this._inputOnChange(e,"ip1",{ isShowNeta21Remind:false })

                                                        }} />
                                                    )}
                                                </Form.Item>
                                                <div className='ip_separation'> . </div>
                                                <Form.Item label="">
                                                    {getFieldDecorator('ip2', {
                                                        initialValue: getDebugInformationConfig.ip2,
                                                        rules: ipRules,
                                                    })(
                                                        <Input className="ip_model_input" disabled={false} placeholder='请输入' onChange={(e) => {
                                                            this._inputOnChange(e,"ip2",{ isShowNeta21Remind:false })

                                                        }} />
                                                    )}
                                                </Form.Item>
                                                <div className='ip_separation'> . </div>
                                                <Form.Item label="">
                                                    {getFieldDecorator('ip3', {
                                                        initialValue: getDebugInformationConfig.ip3,
                                                        rules:  ipRules
                                                    })(
                                                        <Input className="ip_model_input" disabled={false} placeholder='请输入' onChange={(e) => {
                                                            this._inputOnChange(e,"ip3",{ isShowNeta21Remind:false })

                                                        }} />
                                                    )}
                                                </Form.Item>
                                                <div className='ip_separation'> . </div>
                                                <Form.Item label="">
                                                    {getFieldDecorator('ip4', {
                                                        initialValue: getDebugInformationConfig.ip4,
                                                        rules:  ipRules
                                                    })(
                                                        <Input className="ip_model_input" disabled={false} placeholder='请输入' onChange={(e) => {
                                                            this._inputOnChange(e,"ip4",{ isShowNeta21Remind:false })}} />
                                                    )}
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <Row gutter={24} style={{position:'relative'}}>
                                    <Col span={12} >
                                        {
                                            this._renderInputHeaderByTitle(true, "NETA-21页面登录账号")
                                        }

                                        <Form.Item label="">
                                            {getFieldDecorator('netaUserName', {
                                                initialValue: getDebugInformationConfig.netaUserName,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '登录账号不能为空',
                                                    },
                                                    {
                                                        max: 30,
                                                        message: '字符最大长度为30'
                                                    },
                                                    {
                                                        whitespace: true,
                                                        message: '禁止输入空格',
                                                    }
                                                ],


                                            })(
                                                <Input placeholder="请输入NETA-21页面登录账号" className="model_input" onChange={(e) => {
                                                    this._inputOnChange(e,"netaUserName",{  isShowNeta21Remind:false })   }} />,
                                            )}
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        {
                                            this._renderInputHeaderByTitle(true, "NETA-21页面登录密码")
                                        }

                                        <Form.Item label="">
                                            {getFieldDecorator('netaPassword', {
                                                initialValue: getDebugInformationConfig.netaPassword,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '登录密码不能为空',
                                                    },
                                                    {
                                                        max: 30,
                                                        message: '字符最大长度为30'
                                                    }, 
                                                    {
                                                        whitespace: true,
                                                        message: '禁止输入空格',
                                                    }
                                                ],
                                            })(
                                                < Input.Password placeholder="请输入NETA-21页面登录密码" className="model_input"   visibilityToggle = {true} onChange={(e) => {
                                                    this._inputOnChange(e,"netaPassword",{  isShowNeta21Remind:false, }) }} />,
                                            )}
                                        </Form.Item>

                                    </Col>
                                    {
                                         <div className='positionTitle'>
                                        <ABB.Icon  style={{ cursor: "auto" }} name="abb/information-circle-2" sizeClass='small' />
                                        <span>系统已为您自动加载{getDebugInformationConfig.gatewaySn}的调试信息，请核对是否需要修改</span>
                                    </div>  
                                    }
                                   
                                </Row>
                            </div >
                            <div >
                                {
                                    this._renderSectionHeaderByTitle(false, "传动信息")
                                }
                                <Row gutter={24}>
                                    <Col span={12} >
                                        {
                                            this._renderInputHeaderByTitle(true, "传动节点ID")
                                        }

                                        <Form.Item label="">
                                            {getFieldDecorator('deviceNodeId', {
                                                initialValue: getDebugInformationConfig.deviceNodeId,
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: '传动节点ID不能为空',
                                                    },

                                                    {
                                                        whitespace: true,
                                                        message: '禁止输入空格',
                                                    }
                                                ],


                                            })(
                                                <Input placeholder="请输入传动节点ID" className="model_input" onChange={(e) => {
                                                    console.log("e================", e)
                                                    let title = e.target.value

                                                    let showDebuggingInformationConfig = { ...this.props.getDebugInformationConfig }

                                                    showDebuggingInformationConfig.deviceNodeId = title

                                                    this.props.updateDebugInformationConfig(showDebuggingInformationConfig)

                                                }} />,
                                            )}
                                        </Form.Item>
                                    </Col>


                                    <Col span={12} >
                                        {
                                            this._renderInputHeaderByTitle(false, "传动软件版本")
                                        }

                                        <Form.Item label="">
                                            {getFieldDecorator('deviceVersion', {
                                                initialValue: getDebugInformationConfig.deviceVersion,
                                                rules: [
                                                    {
                                                        max: 30,
                                                        message: '字符最大长度为30'
                                                    },
                                                    {
                                                        whitespace: true,
                                                        message: '禁止输入空格',
                                                    }
                                                ],
                                            })(
                                                <Input placeholder="请输入传动软件版本" className="model_input" onChange={(e) => {
                                                    console.log("e================", e)
                                                    let title = e.target.value

                                                    let showDebuggingInformationConfig = { ...this.props.getDebugInformationConfig }

                                                    showDebuggingInformationConfig.deviceVersion = title

                                                    this.props.updateDebugInformationConfig(showDebuggingInformationConfig)

                                                }} />,
                                            )}
                                        </Form.Item>


                                    </Col>

                                </Row>

                            </div>

                        </Form >
                    </div >
                </Modal >
            </>
        );
    }
}



const DebuggingInformationForm = Form.create({ name: 'register' })(DebuggingInformationModel);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateDebugInformationConfig: data => {
        dispatch(setDebugInformationConfig(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DebuggingInformationForm));

