import React, { Component } from 'react';
import { Modal } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class MigrationModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showmodel: {
                visible: false
            }
		};
	}
	componentDidMount() {
        this.props.onRef(this)
    }
    showModelFn = (data)=>{
        console.log("data--------------byq",data)
        if(data.notifyAboutCpInfo){
            this.setState({
                showmodel: {
                    visible: true
                }
            })
        }
    }
    hidemodel=(data)=>{
        this.setState({
            showmodel: {
                visible: false
            }
        })
    }
    submitmodel=(data)=>{
        this.setState({
            showmodel: {
                visible: false
            }
        })
    }
	render() {
        const that = this;
        let {showmodel} = that.state;
        const {userInfo} = that.props;
        // console.log("userInfo--userInfo",userInfo)
        const modelProps = {
            title: '管理权移交提醒',
            centered: true,
            width:504,
            maskClosable: false,
            visible:showmodel.visible,
            onOk:() => {that.hidemodel(false)},
            onCancel:() => {that.hidemodel(false)},
            footer:[
                
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,        
            ]
        }
		return (
			<Modal {...modelProps} 
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                <div className="remove_model"><span style={{color: '#36f'}}>{userInfo.orgName}</span>的管理权已被移交给您。该组织已授权<span style={{color: '#36f'}}>{userInfo.cpOrgName}</span>给提供服务。您可以切换组织后进行管理。</div>
                
            </Modal>
		);
	}
}

export default MigrationModel;
