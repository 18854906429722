import React, { Component } from 'react';
import { Modal, Button, Input, message,Form } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Search } = Input;
class UserModelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: false,
            isZn_CH: false
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.props.submitmodel(fieldsValue)
                setTimeout(() => {
                    this.props.form.resetFields();
                }, 1000)
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };
    render() {
        const that = this;
        const { showmodel,disabled } = this.props;
        const { getFieldDecorator } = that.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showmodel.title,
            centered: showmodel.centered,
            width: showmodel.width,
            maskClosable: false,
            visible: showmodel.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(that.state.inputval)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    disabled={disabled}
                    text="确定"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>

                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>
                    <Form.Item label="用户组名称">
                        {getFieldDecorator('userGroupName', {
                            initialValue: showmodel.value,
                            rules: [

                                {
                                    required: true,
                                    message: '用户组名称不能为空',
                                },
                                {
                                    max: 20,
                                    message: '用户组名称长度不能大于20',
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            <Input placeholder="请输入用户组名称" className="model_input" />,
                        )}
                    </Form.Item>


                </Form>
                {/* <div className="model_list">
                    <div className='model_title'>{showmodel.lable}</div>
                    <Input
                    className="model_input"
                    onCompositionStart={that.onChange}
                    onCompositionEnd={that.onChange}
                    value={that.state.inputval}
                    placeholder={showmodel.value}
                    onChange={(value) => {that.onChange(value)}}
                    />
                </div> */}

            </Modal>
        );
    }
}
const UserModel = Form.create({ name: 'register' })(UserModelForm);

export default UserModel;
