import * as actionTypes from '../constants/index';
let _userdata = []

const _selectequipment = []
const _getserchUser = []
const _getOrginformation = {
	contacts:[]
}
const _getcustomersList = []
const Usergroup = (state = _userdata, action) => {
	switch (action.type) {
		case actionTypes.SELECT_USERGROUP:
			return action.data;
		default:
			return state;
	}
};
const getcustomersList = (state = _getcustomersList, action) => {
	switch (action.type) {
		case actionTypes.SET_CUSTOMERS:
			return action.data;
		default:
			return state;
	}
};
const equipmentdata = (state = _selectequipment, action) => {
	switch (action.type) {
		case actionTypes.SELECT_EQUIPMENT:
			return action.data;
		default:
			return state;
	}
};

const getserchUser = (state = _getserchUser, action) => {
	switch (action.type) {
		case actionTypes.SET_SERCHUSER:
			return action.data;
		default:
			return state;
	}
};

const getOrginformation = (state = _getOrginformation, action) => {
	switch (action.type) {
		case actionTypes.SET_ORGINFORMATION:
			return action.data;
		default:
			return state;
	}
};


export {Usergroup,equipmentdata,getserchUser,getOrginformation,getcustomersList};