import React, { Component } from 'react';
import { Modal, Button, Input, Select, message, Icon } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { createPowertrain } from '@/api/device';

class Powertrainmodel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PowertrainmotorsId: '',
            PowertraindrivesId: '',
            assetName: '',
            disabled: false
        };
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.setState({
            PowertrainmotorsId: '',
            PowertraindrivesId: '',
            assetName: '',

        })
    }
    submitmodel = (PowertrainmotorsId, PowertraindrivesId) => {
        if (this.state.assetName === '') {
            message.warning('名称不能为空')
            return false;
        }

        else if (PowertraindrivesId === "") {
            message.warning('请选择传动设备')
            return false
        }
        else if (PowertrainmotorsId === "") {
            message.warning('请选择电机设备')
            return false
        }
        else {
            this.rescreatePowertrain(PowertrainmotorsId, PowertraindrivesId);
        }

    }
    //组合powertrain接口
    rescreatePowertrain(PowertrainmotorsId, PowertraindrivesId) {
            this.setState({
                disabled: true
            })
            let PowertrainIds = [PowertrainmotorsId, PowertraindrivesId]
            let url = {
                "assetName": this.state.assetName,
                "ids": PowertrainIds
            };
            createPowertrain(url).then(res => {
                if (res !== undefined) {
                    message.success("组合powertrain成功")
                    this.props.submitmodel();
                    this.setState({
                        PowertrainmotorsId: '',
                        PowertraindrivesId: '',
                        assetName: '',
                        disabled: false
                    })
                }
            }).catch(err => {
                message.error("组合powertrain失败")
                this.setState({
                    disabled: false
                })
            })
        

    }
    //电机id
    motorsId = (e) => {
        this.setState({
            PowertrainmotorsId: e
        })
    }
    //传动id
    drivesId = (e) => {
        this.setState({
            PowertraindrivesId: e
        })
    }
    //get assetname
    powertrainName = (e) => {
        this.setState({
            assetName: e.target.value
        })
    }
    render() {
        const that = this;
        const { showPowertrainmodel } = this.props;
        const modelProps = {
            title: showPowertrainmodel.title,
            centered: showPowertrainmodel.centered,
            width: showPowertrainmodel.width,
            maskClosable: false,
            visible: showPowertrainmodel.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(
                        that.state.PowertrainmotorsId,
                        that.state.PowertraindrivesId,
                    )}
                    disabled={this.state.disabled}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div className="model_list">
                    <div className="model_title">组合传动链名称</div>
                    <Input
                        value={that.state.assetName}
                        className="model_input"
                        placeholder="组合传动链名称"
                        onChange={that.powertrainName}
                        style={{ width: '100%' }}
                    />
                </div>
                <div className="model_list">
                    <div className="model_title">电机</div>
                    <Select placeholder="电机"
                        suffixIcon={
                            <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                        }
                        value={that.state.PowertrainmotorsId}
                        style={{ 'width': '100%' }}
                        onChange={that.motorsId}
                    >
                        {
                            showPowertrainmodel.powertraindata.motors.map((item, index) => {
                                return (
                                    <Select.Option value={item.id}>{item.assetName}</Select.Option>
                                )
                            })
                        }

                    </Select>
                </div>

                <div className="model_list">
                    <div className="model_title">传动</div>
                    <Select
                        suffixIcon={
                            <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                        }
                        placeholder="传动"
                        value={that.state.PowertraindrivesId}
                        style={{ 'width': '100%' }}
                        onChange={this.drivesId}
                    >
                        {
                            showPowertrainmodel.powertraindata.drives.map((item, index) => {
                                return (
                                    <Select.Option value={item.id}>{item.assetName}</Select.Option>
                                )
                            })
                        }

                    </Select>
                </div>


            </Modal>
        );
    }
}

export default Powertrainmodel;
