import React, { Component } from 'react';
import { Input, Table, message, InputNumber } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { getDeviceLog } from '@/api/tools.js';
import IndexInputView from '@/components/page';

const div_nowrap = {
	display: 'inline',
	margin: '0 30px 0 0'
};
const icon_center = {
	margin: '0 10px 0 0',
	display: 'inline'
}
const { Search } = Input;

class AddLog extends Component {
	state = {
		pagination: {},
		pageIndex: 1,
		pageSize: 12,
		loglist: [],
		searchValue: '',
		columns: [
			{
				title: '编号',
				dataIndex: 'addedDate',
				key: '1',
				width: '5%',
				render: (text, record, index) => (
					<span>{index + 1}</span>
				)
			},
			{
				title: '用户名称',
				dataIndex: 'userName',
				key: '2',
				width: '15%'
			},
			{
				title: '添加时间',
				dataIndex: 'createTime',
				key: '3',
				width: '15%',
				render: (text, record, index) => (
					<span>{moment.parseZone(record.createTime).local().format("YYYY/MM/DD HH:mm:ss")}</span>
				)
			},

			{
				title: '组织名称',
				dataIndex: 'targetOrgName',
				key: '4',
				render: (text, record, index) => {
					if (record.targetOrgName === null) {
						return (
							<span>null</span>
						)
					}
					else {
						return (
							<span>{record.targetOrgName}</span>
						)
					}
				}
			},
			{
				title: '组织ID',
				dataIndex: 'targetOrgId',
				key: '5',
				width: '5%',
				render: (text, record, index) => {
					if (record.targetOrgId === null) {
						return (
							<span>null</span>
						)
					}
					else {
						return (
							<span>{record.targetOrgId}</span>
						)
					}
				}
			},

			{
				title: '设备序列号',
				dataIndex: 'serialNumber',
				key: '6',
				width: '20%'
			},
			{
				title: '事件状态',
				dataIndex: 'addResult',
				key: '7',
				width: '10%',
				render: (text, record, index) => {
					if (record.addResult) {
						return (
							<span style={{ color: '#0CA919' }}>添加成功</span>
						)
					}
					else {
						return (
							<span style={{ color: '#F03040' }}>添加失败</span>
						)
					}
				}
			},
			{
				title: '失败原因',
				dataIndex: 'log',
				key: '8',
				width: '15%',
			}
		]
	};

	componentDidMount() {
		this.fetch(1);
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}


	//接口数据
	fetch = (pageIndex) => {
		let { pageSize, searchValue } = this.state;
		let url = `index=${pageIndex}&size=${pageSize}&userPhone=${searchValue}`;

		getDeviceLog(url).then(res => {
			let resdata = res.data;
			if (resdata.code === 200) {
				this.setState({
					loglist: resdata.data,
					pageIndex: pageIndex
				},()=>{
					if(this.state.loglist.length< pageSize){
						this.inputview.setIsLast(true)
					}
					else{
						this.inputview.setIsLast(false)
					}
				})
				
			}
			else {
				message.warning(resdata.errorMessage)
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data);
			} else {
				console.log("fetch -- getDeviceLog --接口异常")
				message.error('接口异常');
			}
		});
	};
	
	render() {
		let { pageIndex } = this.state;

		return (
			<>
				<div className="user_box ABB_tab_marginTop" style={{ fontSize: 14 }}>

					<div style={{ display: 'flex' }}>
						<div>
							<Search
								// onChange={this.compareKeyword}
								onSearch={(e) => {
									console.log("eeeeee", e)
									this.setState({
										searchValue: e
									}, () => {
										this.fetch(pageIndex);
									})

								}
								} placeholder="请输入用户手机号" style={{ width: 190 }} className="search_input" />
						</div>

					</div>


				</div>

				<Table
					size="small"
					scroll={{ x: 740 }}
					columns={this.state.columns}
					dataSource={this.state.loglist}
					ellipsis={true}

					pagination={false}
					rowKey={(record, index) => index}
				/>
				<IndexInputView ref={(onref) => {
					this.inputview = onref;
				}}   confirmInputValue={(pageIndex) => {
					this.fetch(pageIndex)
					// this.inputview.setIsLast(true)
				}} />
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddLog));
