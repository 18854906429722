import $axios from '../axios/$axios';

export function conditionOverall(data) {
   return $axios({
      url: '/api/v1/chart/conditiontype/overall',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function conditionAvailability(data) {
   return $axios({
      url: '/api/v1/chart/conditiontype/availability',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function conditionEnvironment(data) {
   return $axios({
      url: '/api/v1/chart/conditiontype/environment',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function conditionReliability(data) {
   return $axios({
      url: '/api/v1/chart/conditiontype/reliability',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function conditionStress(data) {
   return $axios({
      url: '/api/v1/chart/conditiontype/stress',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function last7Days(data) {
   return $axios({
      url: '/api/v2/chart/event/last7days' + data,
      method: 'get',
      type: 'json',
      data: null
   })
}

export function last7DaysId(data) {
   return $axios({
      url: '/api/v2/chart/event/last7days/' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function topFiveDevice(data) {
   return $axios({
      url: '/api/v1/chart/device/topfive',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function topFiveEvent(data) {
   return $axios({
      url: '/api/v1/chart/eventcode/topfive',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function topFiveEventId(data) {
   return $axios({
      url: '/api/v1/chart/eventcode/topfive/' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function mapData(data) {
   return $axios({
      url: '/api/v1/chart/map',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function eventTop(data) {
   return $axios({
      url: '/api/v2/event/top?size=' + data,
      method: 'get',
      type: 'text',
      data: ''
   })
}

export function eventlatest(data) {
   return $axios({
      url: '/api/v1/chart/cp/event/latest' + data,
      method: 'get',
      type: 'text',
      data: ''
   })
}

export function assetByType(data) {
   return $axios({
      url: '/api/v1/chart/assetcountbytype',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function assetBySubscription(data) {
   return $axios({
      url: '/api/v1/chart/assetcountbysubscriptionstatus',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function assetsandsubscriptions(data) {
   return $axios({
      url: '/api/v2/chart/statistics/assetsandsubscriptions',
      method: 'get',
      type: 'json',
      data: data
   })
}

export function customerList(data) {
   return $axios({
      url: '/api/v1/chart/customerlist' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}
export function customerNew10(data) {
   return $axios({
      url: '/api/v3/organization/getneworg' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function customerCount(data) {
   return $axios({
      url: '/api/v1/chart/customercount',
      method: 'get',
      type: 'json',
      data: data
   })
}