import React, { Component } from 'react';
import * as echarts from 'echarts';
import { deviceDetail, userCheckDevice, settrendscode, settrendstag } from '@/redux/actions/device';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setIsSignalShowthree } from '@/redux/actions/setting';
import './style.scss';
import moment from 'moment';
import { Icon } from 'antd';
// import { eventlogger } from "@/api/event"

const dateFormat = 'YYYY/MM/DD HH:mm:ss.SSS';
class EchartLine extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {},
            trendList: [],
            trendstag: this.props.gettrendstag,
            ActiveTab: this.props.getActiveTab,
            isZoom: false,
            startime: '',
            endtime: '',
            tagColor: this.props.tagColor,
            IsSignalShowthree: this.props.getIsSignalShowthree,
            rowdatachartsflag: 0,
            nodata: true,
            lodding: false,
            isAmplification:false //图表放大后 恢复缩放 方可点击
        }
    }


    componentDidMount() {
        window.addEventListener('resize', this.handleSize);
        // this.geteventloggerdata(this.props.eventloggerchartdata);
    }

    componentWillUnmount() {
        // 一定要最后移除监听器，以防多个组件之间导致this的指向紊乱
        window.removeEventListener('resize', this.handleSize)
    }
    componentDidUpdate(nextProps, prevState) {
        if (prevState.trendstag !== this.props.gettrendstag) {
            this.setState({
                trendstag: this.props.gettrendstag,
            }, () => {
                this.geteventloggerdata(this.props.eventloggerchartdata);
                
            })
        }
        if (prevState.IsSignalShowthree !== this.props.getIsSignalShowthree) {
            this.setState({
                IsSignalShowthree: this.props.getIsSignalShowthree
            }, () => {
                this.showBarChart()
            })
        }
        if (prevState.ActiveTab !== this.props.getActiveTab) {
            this.setState({
                ActiveTab: this.props.getActiveTab
            }, () => {
                this.showBarChart()
            })
        }
    }
    geteventloggerdata = (resdata) => {

        let options = {
            title: {
                text: '',
                left: 15,
                align: 'right',
                textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                    fontSize: 14,
                    color: '#1F1F1F'
                },
            },
            grid: {
                top: '40px',
                left: '20px',
                right: '50px',
                bottom: '10px',
                containLabel: true
            },
            tooltip: {
                trigger: 'axis',
                backgroundColor: 'rgba(255,255,255,0.8)',//通过设置rgba调节背景颜色与透明度
                color: 'black',
                borderWidth: '1',
                borderColor: 'gray',
                textStyle: {
                    color: 'black',
                },
                // formatter: function (params) {
                // }
            },
            toolbox: {
                show: true,
                feature: {
                    dataZoom: {
                        // show: false,
                        yAxisIndex: "none",
                        icon: {
                            zoom: "path://M14.0343806,8.04813286 L11.0412567,8.04813286 L11.0412567,11.0412567 L8.04813286,11.0412567 L8.04813286,14.0343806 L11.0412567,14.0343806 L11.0412567,17.0275045 L14.0343806,17.0275045 L14.0343806,14.0343806 L17.0275045,14.0343806 L17.0275045,11.0412567 L14.0343806,11.0412567 L14.0343806,8.04813286 L14.0343806,8.04813286 Z M29,26.0068761 L20.5593907,17.5662668 C23.1336593,13.4686605 22.2196876,8.09123718 18.4361858,5.07422697 C14.652684,2.05721677 9.20672895,2.36315733 5.78494314,5.78494314 C2.36315733,9.20672895 2.05721677,14.652684 5.07422697,18.4361858 C8.09123718,22.2196876 13.4686605,23.1336593 17.5662668,20.5593907 L26.0068761,29 L29,26.0068761 Z M5.05500898,12.5378187 C5.05500898,8.40517699 8.40517699,5.05500898 12.5378187,5.05500898 C16.6704604,5.05500898 20.0206284,8.40517699 20.0206284,12.5378187 C20.0206284,16.6704604 16.6704604,20.0206284 12.5378187,20.0206284 C8.40517699,20.0206284 5.05500898,16.6704604 5.05500898,12.5378187 L5.05500898,12.5378187 Z",
                            back: "path://M25,19 C25,15.21 22.06,12 18.85,12 L11,12 L15,15.8 L13,18 L5,10.5 L13,3 L15,5.2 L11,9 L18.85,9 C23.69,9 28,13.58 28,19 C28,24.42 23.69,29 18.85,29 L7,29 L7,26 L18.85,26 C22.06,26 25,22.79 25,19 Z"
                        },
                        iconStyle: {
                            color: "#1F1F1F",
                            borderWidth: 0,
                            borderColor: "#1F1F1F",
                            opacity: 0
                        },
                        emphasis: {
                            iconStyle: {
                                color: "#3366ff",
                                borderColor: "#3366ff"
                            }
                        },
                        title: {
                            zoom: "区域缩放",
                            back: "区域缩放还原"
                        }
                    },

                    restore: {
                        show: false,
                        title: "还原",
                        emphasis: {
                            color: '#3366ff'
                        },
                        iconStyle: {
                            opacity: 0
                        }
                    },
                },
                top: 0,
                right: '50px',

            },
            //x轴数据
            xAxis: {
                type: 'category',
                boundaryGap: false,
                axisLine: {
                    lineStyle: {
                        color: '#BABABA'
                    }
                },
                //  改变x轴字体颜色和大小  
                axisLabel: {
                    textStyle: {
                        color: '#686868',
                        fontSize: '11'
                    },
                },
                axisTick: {       //y轴刻度线
                    show: false
                },
                data: []
            },
            yAxis: [],
            series: []
        }
        let yAxisdataarr = [];
        let seriesdataarr = [];

        let dataArray = resdata;
        let maxArray = []
        if (dataArray.length > 0) {
            maxArray = dataArray[0].timestamp;
        }
        for (let i = 1; i < dataArray.length; i++) {
            let tempArray = dataArray[i].timestamp;
            if (tempArray.length > maxArray.length) {
                maxArray = tempArray;
            }
        }
        this.setState({
            startime: moment.parseZone(maxArray[0]).local().format(dateFormat),
            endtime: moment.parseZone(maxArray[maxArray.length - 1]).local().format(dateFormat)
        })
        let mytimeStamp = [];
        for (let z = 0; z < maxArray.length; z++) {
            mytimeStamp.push(moment.parseZone(parseInt(maxArray[z])).local().format('MM/DD HH:mm:ss.SSS'))
        }
        //
        if (resdata.length < 1) { //如果没有线,显示暂无数据
            this.setState({
                nodata: true
            })
        } else {
            resdata.map((item, index) => {
                if (item.avg.length <= 0) {

                }
                else {
                    let values = item.value;
                    let maxNumber = Math.max(...values);
                    let minNumber = Math.min(...values);

                    let intervalNumber = 0;

                    if (maxNumber >= 1 || maxNumber === 0 || maxNumber < -1) {

                        maxNumber = Math.ceil(maxNumber);
                        maxNumber = Math.ceil(maxNumber / 10) * 10;

                        // intervalNumber = Math.ceil(Math.max(...values) / 10);

                    } else if (maxNumber > 0 && maxNumber < 1) {



                        let temp = maxNumber;
                        let number = 0;
                        for (let i = 0; temp < 1; i++) {
                            number = i;

                            temp = temp * 10;

                        }

                        maxNumber = Math.ceil(temp);


                        // intervalNumber =  (temp + 1) * 100  / 10 /(Math.pow(10,number + 3) );

                        maxNumber = maxNumber / (Math.pow(10, number + 1));


                    } else if (maxNumber < 0 && maxNumber > -1) {

                    }

                    if (minNumber >= 1 || minNumber === 0 || minNumber < -1) {

                        minNumber = Math.floor(minNumber);
                        minNumber = Math.floor(minNumber / 5) * 5;

                        // intervalNumber = Math.ceil(Math.max(...values) / 10);

                    } else if (minNumber > 0 && minNumber < 1) {
                        let temp = minNumber;
                        let number = 0;
                        for (let i = 0; temp < 1; i++) {
                            number = i;

                            temp = temp * 10;

                        }

                        minNumber = Math.floor(temp);


                        // intervalNumber =  (temp + 1) * 100  / 10 /(Math.pow(10,number + 3) );

                        minNumber = maxNumber / (Math.pow(10, number + 1));


                    } else if (minNumber < 0 && minNumber > -1) {

                    }

                    if (maxNumber > 1 && minNumber > 1) {

                        intervalNumber = (maxNumber - minNumber) / 10
                    } else {
                        intervalNumber = intervalNumber = (maxNumber - minNumber) / 10;
                    }

                    if ((maxNumber < 1 && maxNumber > -1) && minNumber < -1) {
                        if (maxNumber >= 0) {
                            maxNumber = 0;
                        } else {
                            maxNumber = 1;
                        }


                    }

                    if ((minNumber < 1 && minNumber > -1) && maxNumber > 1) {
                        if (minNumber >= 0) {
                            minNumber = 0;
                        } else {
                            minNumber = -1;
                        }

                    }

                    if ((maxNumber === 0 && minNumber === 0) || minNumber === maxNumber) {
                        maxNumber = 1;
                        minNumber = -1;
                        intervalNumber = (maxNumber - minNumber) / 10;
                    }
                    let yAxisdata = {
                        type: 'value',
                        axisLine: {       //y轴
                            show: false,
                            fontFamily: 'ABBvoiceCNSG-Regular',
                            lineStyle: {
                                color: '#BABABA'
                            }
                        },
                        min: minNumber,
                        // // max: Math.ceil(maxNumber / 5) * 5,
                        max: maxNumber,
                        // max: maxNumber,
                        // // splitNumber: 5,
                        interval: intervalNumber,

                        // interval:    (Math.ceil(  Math.max(...values)) - Math.min(...values)) / 5,
                        //  改变x轴字体颜色和大小  
                        axisLabel: {
                            // formatter: '{value} ' + item.config.unit,
                            formatter: function (value) {
                                if (value.toString().length > 6) {
                                    return value.toFixed(5);
                                }
                                else {
                                    return value;
                                }
                            },
                            textStyle: {
                                color: '#686868',
                                fontSize: '12'
                            },
                            interval: 0,
                        },
                        // lineHeight: 56,
                        // length:5,
                        axisTick: {       //y轴刻度线
                            show: false
                        },
                        // data:["0.001","0.002","0.003","0.004","0.005","0.006"]
                    }
                    let seriesdata = {
                        name: item.name,
                        type: 'line',
                        // symbol: 'none',
                        // mydata: item.config.unit,
                        itemStyle: {

                            normal: {
                                color: this.state.tagColor[index],
                                lineStyle: {
                                    color: this.state.tagColor[index],
                                    width: 1// 0.1的线条是非常细的了
                                }
                            }
                        },
                        // markLine: {
                        //     lineStyle: {
                        //         width: 2,
                        //         color: '#F7B500',
                        //         type: 'solid'
                        //     },
                        //     label: {
                        //         position: 'top',
                        //         padding: [0, 0, 5, 0],
                        //         // left: 8,
                        //         normal: {
                        //             formatter: '12/05 13:01:23.018' // 这儿设置安全基线
            
                        //         }
                        //     },
            
                        //     silent: true, // 鼠标悬停事件, true悬停不会出现实线
                        //     symbol: 'none', // 去掉箭头
                        //     data: [{
                        //         xAxis: '12/05 13:01:23.018'
                        //     },]
                        // },
                        data: values,
                        yAxisIndex: index
                    }
                    // if (maxNumber !== 0) {
                    if (index === 1) {
                        options.grid.right = "50px";
                        yAxisdata.offset = 0;
                    }
                    if (index === 2) {
                        options.grid.right = "50px";
                        yAxisdata.offset = 75;
                    }
                    if (index === 3) {
                        options.grid.right = "100px";
                        yAxisdata.offset = 150;
                    }
                    if (index === 4) {
                        options.grid.right = "150px";
                        yAxisdata.offset = 220;
                    }

                    yAxisdataarr.push(yAxisdata);
                    seriesdataarr.push(seriesdata)
                    options.xAxis.data = mytimeStamp;

                    // item.trendcharts = options;
                }
            })

            options.yAxis = yAxisdataarr;

            if (seriesdataarr.length > 0) {
                options.series = seriesdataarr;
                this.setState({
                    options: options,
                    trendList: resdata,
                    nodata: false,
                }, () => {

                    this.showBarChart()
                })
            } else {  //显示暂无数据
                this.setState({
                    nodata: true
                })
            }
        }

    }
    showBarChart = () => {
        let that = this;
        let charts = echarts.init(
            document.getElementById('charts1'), null, { renderer: 'svg' }
        )
        // charts.clear();
        // debugger;
        charts.setOption(that.state.options, true);
        // debugger;
        charts.dispatchAction({
            type: "takeGlobalCursor",
            key: 'dataZoomSelect',
            dataZoomSelectActive: true
        });
        charts.on("dataZoom", params => {
            let time = {};

            let dataArray = this.props.eventloggerchartdata;
            let maxArray = []
            if (dataArray.length > 0) {
                maxArray = dataArray[0].timestamp;
            }
            for (let i = 1; i < dataArray.length; i++) {
                let tempArray = dataArray[i].timestamp;
                if (tempArray.length > maxArray.length) {
                    maxArray = tempArray;
                }
            }

            maxArray.map((item, index) => {
                if (index === params.batch[0].startValue) {
                    time.startime = moment.parseZone(item).local().format(dateFormat)
                }
                if (index === params.batch[0].endValue) {
                    time.endtime = moment.parseZone(item).local().format(dateFormat)
                }
            })

            this.setState({
                startime: time.startime,
                endtime: time.endtime,
                isAmplification:true
            })
        });
        charts.resize();
    }

    // 自适应浏览器的高度
    handleSize = () => {
        this.showBarChart();
    }
    restore = () => {
        if (!this.state.isAmplification) {
            return
        }
        this.setState({
            rowdatachartsflag: 0,
            isAmplification:false
            // nodata: false
        }, () => {
            this.geteventloggerdata(this.props.eventloggerchartdata);
        })
    }

    render() {
        let { startime, endtime } = this.state;
        const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />
        return (
            <>
                <div className="chartstime">{startime} —— {endtime}</div>

                <div className="chartsdetail">

                    <span className= {this.state.isAmplification === true ? "restorebuttonDetail" : "restorebuttonDetail_Unenable"} onClick={() => this.restore()}>
                        恢复缩放
                    </span>
                    <div style={{ height: '100%', width: '100%' }} id="charts1"></div>

                    {/* {
                        this.state.nodata === false ? (
                            <div style={{ display: "none" }} ></div>
                        ) : <div className="nodata">暂无数据</div>
                    }
                    {
                        this.state.lodding ? (
                            <div className="chartsLodding">
                                <Spin indicator={antIcon} />

                            </div>
                        ) : <div style={{ display: "none" }} ></div>
                    } */}
                </div>


            </>

        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setIsSignalShowthree: data => {
        dispatch(setIsSignalShowthree(data))
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    deviceDetail: data => {

        dispatch(deviceDetail(data));
    },
    settrendscode: data => {

        dispatch(settrendscode(data));
    },
    settrendstag: data => {

        dispatch(settrendstag(data));
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EchartLine));