import React from 'react';
import {Router,Route, Switch } from 'react-router-dom';
import Layout from '@/views/layout/Index';
import AuthRouter from '@/views/auth/AuthRouter';
import { createBrowserHistory } from 'history';
const browserHistory = createBrowserHistory()

const MyRouter = () => {
	return (
		<Router history={ browserHistory }>
			<Switch>
				<AuthRouter path="/" component={Layout} />
			</Switch>
		</Router>
	);
};

export default MyRouter;

// import React from 'react';
// import { HashRouter, Route, Switch } from 'react-router-dom';
// import Layout from '@/views/layout/Index';
// import Login from '@/views/Login';
// import AuthRouter from '@/views/auth/AuthRouter';
// const Router = () => {
// 	return (
// 		<HashRouter>
// 			<Switch>
// 				{/* <Route component={Login} exact path="/login" /> */}
// 				<AuthRouter path="/" component={Layout} />
// 			</Switch>
// 		</HashRouter>
// 	);
// };

// export default Router;
