import React, { Component } from 'react';
import { Icon, Menu } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setassetId, setAssetIsPowertrain, deviceDetail, checkAllgen, eventDetaildevice, userCheckDevice, setsubscriptioncof } from '@/redux/actions/device';
import intl from 'react-intl-universal';
import { loading } from '@/redux/actions/setting';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import SvgAbbTrend332 from '@/components/trend3_32';

const generalnformation = {
    // border: '1px solid #d8d8d8',
    display: 'flex',
    width: '800px',
    // justifyContent: 'space-between',
    flexWrap: 'nowrap',
    // padding: '10px',
    marginBottom: '10px',
    overflow: 'hidden'
}


const checkdetail = {
    color: "#3366FF",
    lineHeight: '30px',
    fontSize: '14px',
    cursor: 'pointer',
    margin: '0 6px 0 0'
}


const gen_li = {
    width: "200px",
    display: "flex",
    flexWrap: "nowrap",
    lineHeight: "30px",
    fontSize: "16px",
    color: "#3d3d3d",
    position: "relative",
    paddingRight: "16px"
}
class DeviceAlltype extends Component {
    state = {
        assetId: this.props._deviceDetail.assetId,
        deviceDetails: {},
        subscriptioncof: this.props.getsubscriptioncof,
        exceptiondata: '',
    };

    checkall = () => {
        this.props.checkAllgen('0');
        this.props.eventDetaildevice('1');
        this.props.userCheckDevice(10)
    }
    conditionIcon = (data, assetType, subscriptioncof) => {


        let that = this;
        return (
            <div style={generalnformation}>
                {
                    data.conditions.map(function (item, index) {

                        return (
                            <>
                                {
                                    item.type !== 'overall' ? (
                                        <div style={gen_li}>
                                            {
                                                that.typeIcon(item, assetType, subscriptioncof)
                                            }
                                        </div>
                                    ) : ''
                                }

                            </>
                        )

                    })
                }
            </div>
        )


    }
    //类型icon
    typeIcon = (data, assetType, subscriptioncof) => {
        let _assetType = ''
        if (assetType === 2) {
            _assetType = 'drive'
        }
        else if (assetType === 3) {
            _assetType = 'motor'
        }
        let drive_status = `conditionindex.desc.${_assetType}.`;
        let conditionindexName = `conditionindex.name.`;
        let subscriptioncofarr = [];
        subscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 21) {
                    let stringResult = items.limitValue.split(',');
                    subscriptioncofarr = stringResult;
                }
            })

        })
        return (
            <>
                {
                    subscriptioncofarr.map(item => {
                        if (item === "1") {

                        }
                        if (item === "2") {
                            if (data.type === 'availability') {
                                if (data.status === 'good') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#0CA919' }} name="abb/warning-circle-2" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}availability`)}</span>
                                                <div>{intl.get(`${drive_status}availability.good`)}</div>

                                            </div>
                                        </>
                                    )
                                }
                                else if (data.status === 'tolerable') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-circle-2" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}availability`)}</span>
                                                <div>{intl.get(`${drive_status}availability.tolerable`)}</div>

                                            </div>
                                        </>
                                    )
                                }
                                else if (data.status === 'poor') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#F03040' }} name="abb/warning-circle-2" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}availability`)}</span>
                                                <div>{intl.get(`${drive_status}availability.poor`)}</div>

                                            </div>
                                        </>

                                    )
                                }
                                else if (data.status === 'unknown') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#BABABA' }} name="abb/warning-circle-2" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}availability`)}</span>
                                                <div>{intl.get(`${drive_status}availability.unknown`)}</div>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        }
                        if (item === "3") {
                            if (data.type === 'environment') {
                                if (data.status === 'good') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#0CA919' }} name="abb/temperature" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}environment`)}</span>
                                                <div>{intl.get(`${drive_status}environment.good`)}</div>

                                            </div>
                                        </>
                                    )
                                }
                                else if (data.status === 'tolerable') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/temperature" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}environment`)}</span>
                                                <div>{intl.get(`${drive_status}environment.tolerable`)}</div>

                                            </div>
                                        </>
                                    )
                                }
                                else if (data.status === 'poor') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#F03040' }} name="abb/temperature" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}environment`)}</span>
                                                <div>{intl.get(`${drive_status}environment.poor`)}</div>

                                            </div>
                                        </>

                                    )
                                }
                                else if (data.status === 'unknown') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#BABABA' }} name="abb/temperature" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}environment`)}</span>
                                                <div>{intl.get(`${drive_status}environment.unknown`)}</div>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        }
                        if (item === "4") {
                            if (data.type === 'reliability') {
                                if (data.status === 'good') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#0CA919' }} name="abb/pie-chart" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}reliability`)}</span>
                                                <div>{intl.get(`${drive_status}reliability.good`)}</div>

                                            </div>
                                        </>
                                    )
                                }
                                else if (data.status === 'tolerable') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/pie-chart" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}reliability`)}</span>
                                                <div>{intl.get(`${drive_status}reliability.tolerable`)}</div>

                                            </div>
                                        </>
                                    )
                                }
                                else if (data.status === 'poor') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#F03040' }} name="abb/pie-chart" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}reliability`)}</span>
                                                <div>{intl.get(`${drive_status}reliability.poor`)}</div>

                                            </div>
                                        </>

                                    )
                                }
                                else if (data.status === 'unknown') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <ABB.Icon style={{ 'color': '#BABABA' }} name="abb/pie-chart" sizeClass='large' />
                                            </div>
                                            <div className='device_gen_title'>
                                                <span>{intl.get(`${conditionindexName}reliability`)}</span>
                                                <div>{intl.get(`${drive_status}reliability.unknown`)}</div>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        }
                        if (item === "5") {
                            if (data.type === 'stress') {
                                if (data.status === 'good') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <Icon style={{ 'color': '#0CA919' }} component={SvgAbbTrend332} />

                                            </div>
                                            <div className='device_gen_title'>
                                                {
                                                    assetType === 2 ? (
                                                        <span>{intl.get(conditionindexName + 'stress')}</span>
                                                    ) : (
                                                        <span>负荷</span>
                                                    )
                                                }

                                                <div>{intl.get(`${drive_status}stress.good`)}</div>

                                            </div>
                                        </>
                                    )
                                }
                                else if (data.status === 'tolerable') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <Icon style={{ 'color': '#FFD800' }} component={SvgAbbTrend332} />
                                            </div>
                                            <div className='device_gen_title'>
                                                {
                                                    assetType === 2 ? (
                                                        <span>{intl.get(conditionindexName + 'stress')}</span>
                                                    ) : (
                                                        <span>负荷</span>
                                                    )
                                                }
                                                <div>{intl.get(`${drive_status}stress.tolerable`)}</div>

                                            </div>
                                        </>
                                    )
                                }
                                else if (data.status === 'poor') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <Icon style={{ 'color': '#F03040' }} component={SvgAbbTrend332} />
                                            </div>
                                            <div className='device_gen_title'>
                                                {
                                                    assetType === 2 ? (
                                                        <span>{intl.get(conditionindexName + 'stress')}</span>
                                                    ) : (
                                                        <span>负荷</span>
                                                    )
                                                }
                                                <div>{intl.get(`${drive_status}stress.poor`)}</div>

                                            </div>
                                        </>

                                    )
                                }
                                else if (data.status === 'unknown') {
                                    return (
                                        <>
                                            <div className='devie_gen_icon'>
                                                <Icon style={{ 'color': '#BABABA' }} component={SvgAbbTrend332} />
                                            </div>
                                            <div className='device_gen_title'>
                                                {
                                                    assetType === 2 ? (
                                                        <span>{intl.get(conditionindexName + 'stress')}</span>
                                                    ) : (
                                                        <span>负荷</span>
                                                    )
                                                }
                                                <div>{intl.get(`${drive_status}stress.unknown`)}</div>
                                            </div>
                                        </>
                                    )
                                }
                            }
                        }
                    })
                }
            </>
        )

    }

    //总体状态
    overall = (data) => {
        if (data === 2) {
            return (
                <>
                    <div className="asset_type_icon asset_type_icon3"></div>
                    <div>未知</div>
                </>
            )
        }
        else if (data === 3) {
            return (
                <>
                    <div className="asset_type_icon asset_type_icon1"></div>
                    <div>良好</div>
                </>
            )
        }
        else if (data === 4) {
            return (
                <>
                    <div className="asset_type_icon asset_type_icon4"></div>
                    <div>一般</div>
                </>
            )
        }
        else if (data === 5) {
            return (
                <>
                    <div className="asset_type_icon asset_type_icon2"></div>
                    <div>较差</div>
                </>
            )
        }
    }

    //设备列表设备类型icon(24)渲染
    assetIcon24 = (data) => {
        if (data.assetType === 3) {
            return (
                <ABB.Icon className="asset_icon" style={{ margin: '0 8px 0 0' }} name="abb/motor" sizeClass='medium' />
            )
        }
        else if (data.assetType === 2) {
            return (
                <ABB.Icon className="asset_icon" style={{ margin: '0 8px 0 0' }} name="abb/drive" sizeClass='medium' />
            )
        }
        else if (data.assetType === 1) {
            return (
                <ABB.Icon className="asset_icon" style={{ margin: '0 8px 0 0' }} name="abb/powertrain" sizeClass='medium' />
            )
        }
    }
    render() {
        let _self = this;
        const { _deviceDetail, getsubscriptioncof, getAssetStatus } = _self.props;
        let objnull = (JSON.stringify(_deviceDetail) === "{}")

        return (
            <>
                <div className="deviceDetail_status">

                    {
                        objnull === false ? (
                            <>
                                <div className="asset_type">

                                    {
                                        _deviceDetail.conditions.map(function (item, index) {
                                            return (
                                                <>

                                                    {
                                                        item.type === 'overall' ? (
                                                            <div className="devicedetail_title">
                                                                <div className="asset_type">设备健康状态</div>
                                                                <div className="asset_type">

                                                                    <div style={{ fontSize: '14px', marginLeft: '20px' }}>总体状态：</div>
                                                                    {
                                                                        _self.overall(item.statusId)
                                                                    }

                                                                </div>

                                                                {/* {
                                                                    _deviceDetail.assetType === 2 ? (
                                                                        <>
                                                                            <div className="asset_type">
                                                                                <div>序列号：</div>
                                                                                <div>{_deviceDetail.serialNumber}</div>
                                                                            </div>
                                                                            <div className="asset_type">
                                                                                <div>型号：</div>
                                                                                <div>{_deviceDetail.driveType}</div>
                                                                            </div>
                                                                        </>
                                                                    ) : ''
                                                                } */}


                                                            </div>

                                                        ) : ''
                                                    }

                                                </>
                                            )
                                        })
                                    }
                                </div>
                                {
                                    _deviceDetail.conditions.length > 0 ? (
                                        <>
                                            {
                                                _self.conditionIcon(_deviceDetail, _deviceDetail.assetType, getsubscriptioncof)

                                            }
                                        </>
                                    ) : <div style={{ textAlign: 'center' }}>暂无数据</div>
                                }
                            </>

                        ) : (
                            <div>
                                {
                                    getAssetStatus !== "已过期" ? (
                                        <div className="asset_type">
                                            <div></div>
                                            <div style={checkdetail} onClick={() => _self.checkall()}>全部</div>
                                        </div>
                                    ) : ''
                                }

                                <div className="renderEmpty" >
                                    <div className="renderEmpty_center">
                                        <ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
                                        <p>暂无数据</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

            </>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setassetId: data => {
        dispatch(setassetId(data));
    },
    setAssetIsPowertrain: data => {
        dispatch(setAssetIsPowertrain(data));
    },
    setsubscriptioncof: data => {
        dispatch(setsubscriptioncof(data));
    },
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    loading: data => {
        dispatch(loading(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeviceAlltype));