import React, { Component } from 'react';
import { List, message, Button, Checkbox, Col, Row, Dropdown, Menu } from 'antd';
import { orgInfo } from '@/api/orgtree.js';
import { autoorizitionCp, getOrgInfoFromOrgCode, orgData } from '@/api/user'
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo'
import { setOrginformation } from '@/redux/actions/userlist'
import { withRouter } from 'react-router-dom';
import WrappedRegistrationForm from './createEU.js'
import AuthorizationText from './components/authorizationText'
import AuthorizationModel from './components/authorizationModel'
import TransferControl from './components/transfer/transferControl'
import TransferControlOk from './components/transfer/transferControlOk'
import Migration from './components/migration/migration'
import MigrationFormOk from './components/migration/migrationOk'
import CancelAuth from './components/authorization/cancelAuth'
import { loading } from '@/redux/actions/setting';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { createSliderWithTooltip } from 'rc-slider';
import Logoutgroup from './components/logoutGroup'
import './style.scss';
import userInfo from './../../../../redux/reducers/userInfo';
import { getContactConfig } from '../../store/reducer.js';
import moment from 'moment';
import ContactModel from './components/contactModel.js';
import { setContactConfig, setEditOrgConfig, setLogoutOrganizationConfig } from '../../store/actionCreators.js';
import { info } from 'sass';
import { data } from 'jquery';
import { setOrgInfo } from '../../../../redux/actions/userInfo.js';
import RemindContactModel from './components/remindContactModel.js';
import { config } from 'react-transition-group';
import { setRemindUpdateContactConfig } from '../../store/actionCreators'
import EditOrgInfo from './components/editOrgInfo.js';



class OrgInformation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showmodel: {},
            showmodelcode: {},
            showmodelTranfer: {},
            showmodelTranferOk: {},
            showmodelMigration: {},
            showmodelMigrationOk: {},
            showmodelCancelAuth: {},
            createOrgdata: false,
            data: [],
            orgdata: {},
            isgrantedCP: false,
            ssName: null,
            orgMessage: ''
        }
    };

    // getBindStatus = () => {
    //     getBindSSOrganization(this.props.userInfo.orgId).then(res => {
    //         this.setState({ ssName: res.data.ssOrgName });
    //     }).catch(err => {
    //         if (err.status === 400) {
    //             this.setState({ ssName: null });
    //         }
    //         else if (err.status === 404) {
    //             this.setState({ ssName: null });
    //         }
    //     })
    // }

    componentDidMount() {
        // if(this.props.userInfo.organizationType === "EU"){
        // this.getOrgData();
        // }
        this.props.onRef(this)
    }

    getOrgData = () => {
        console.log("get this.props.userInfo", this.props.userInfo.orgId)
        let url = {
            orgId: this.props.userInfo.orgId,
            userId: this.props.userInfo.userId
        }
        // let url = {
        //     orgId: 39,
        //     userId: 42
        // }
        orgData(url).then(res => {
            if (res !== undefined) {
                let data = res.data.data;
                console.log('+++++++++++++++++++++++')
                this.setState({
                    orgMessage: data
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('组织信息获取失败！')
            }

        })
    }
    fetch = () => {
        console.log("orginfo-----------")
        let url = `/` + this.props.userInfo.orgId;
        orgInfo(url).then(res => {
            console.log("fetch  orginfo-----------", res)
            if (res !== undefined) {
                let data = res.data;
                let addr = data.province + "， " + data.city + "， " + data.district + "， " + data.address;

                console.log("组织信息======================", data)
                this.props.setOrginformation(data)
            }
        }).catch(err => {
            console.log("fetch  orginfo----err-------", err)
            if (err.status === 400) {
                message.warning(err.data)
            }
            else if (err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('组织信息获取失败！')
            }

        })
    }
    //授权cp接口
    autoorizitionCp = (id) => {
        let that = this;
        let url = {
            "cpOrgId": id,
            "grantContent": ""
        }
        autoorizitionCp(url).then(res => {
            message.success("用户组织授权服务商成功")
            that.fetch();
            that.getOrgData();
            setTimeout(() => {
                that.setState({
                    showmodelcode: {
                        visible: false,
                        title: '',
                        value: '',
                        width: 354,
                        centered: true,
                    }
                })
            }, 1000)

        }).catch(err => {
            if (err.status === 400) {
                message.warning(err.data)
            }
            else if (err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.warning("授权失败，接口错误")
            }
        })
    }
    // 组件传值
    hidemodel = (data) => {
        this.setState({
            showmodel: {
                visible: data,
                title: '',
                width: 504,
                value: '',
                centered: true,
            },
            showmodelcode: {
                visible: data,
                title: '',
                value: '',
                width: 354,
                centered: true,
            },
            showmodelTranfer: {
                visible: false
            },
            showmodelTranferOk: {
                visible: false
            },
            showmodelMigration: {
                visible: false
            },
            showmodelMigrationOk: {
                visible: false
            },
            showmodelCancelAuth: {
                visible: false
            }
        })
    }
    submitmodel = (val) => {
        this.setState({
            showmodel: {
                visible: false,
                title: '',
                value: '',
                width: 504,
                centered: true,
            }
        })
        this.ToauthorizeCode(true, '选择服务商')
    }
    submitmodelcode = (val) => {
        if (val !== '') {
            this.autoorizitionCp(val)
        }
        else {
            message.warning('服务商名称不能为空')
        }
    }

    //刷新组织信息
    refreshOrgData = () => {

        let url = {
            orgId: this.props.userInfo.orgId,
            userId: this.props.userInfo.userId
        }
        orgData(url).then(res => {

            if (res !== undefined) {
                let data = res.data.data;

                let requestContacts = data.contacts

                var deviceContacts = [
                    {
                        "title": "首选联系人", //标题
                        "name": "", // 联系人名称
                        "phone": "", // 联系人电话
                        "priority": 0, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                        isMust: true
                    },
                    {
                        "title": "备选联系人1", //标题
                        "name": "", // 联系人名称
                        "phone": "", // 联系人电话
                        "priority": 1, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                        isMust: true
                    },
                    {
                        "title": "备选联系人2", //标题
                        "name": "", // 联系人名称
                        "phone": "", // 联系人电话
                        "priority": 2, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                        isMust: false
                    },
                    {
                        "title": "备选联系人3", //标题
                        "name": "", // 联系人名称
                        "phone": "", // 联系人电话
                        "priority": 3, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                        isMust: false
                    },
                ]

                for (let index = 0; index < requestContacts.length; index++) {
                    const element = requestContacts[index];
                    requestContacts[index] = { ...deviceContacts[index], ...element }
                }
                data.contacts = [...requestContacts]

                this.props.updateOrgInfo(data)
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            } else {
                message.error('组织信息获取失败！1111')
            }

        })
    }
    //授权文本model
    Toauthorize = (showmodel, title) => {
        this.setState({
            showmodel: {
                visible: showmodel,
                title: title,
                value: '',
                width: 504,
                centered: true,
            }
        })
    }
    //授权code model
    ToauthorizeCode = (showmodel, title) => {
        this.setState({
            showmodelcode: {
                visible: showmodel,
                title: title,
                value: '',
                width: 354,
                centered: true,
            }
        })
    }
    updateOrg = (val) => {
        // this.props.loading("block")
        setTimeout(() => {
            window.location.reload()
        }, 2000)

    }
    tranferButton = () => {
        this.setState({
            showmodelTranfer: {
                visible: true,
                orgMessage: this.state.orgMessage.name,
                createdTime: moment.parseZone(this.state.orgMessage.createdTime).local().format('YYYY-MM-DD')
            }
        })
    }
    _editOrgInformation = () => {

        let config = { ...this.props.getEditOrgConfig }

        config.visible = true

        console.log("config============", config)

        this.props.updateEditOrgInfoConfig(config)

    }
    showmodelTranferOkFn = (data) => {
        let { userInfo } = this.props;
        this.setState({
            showmodelTranferOk: {
                visible: true,
                orgMessage: this.state.orgMessage.name,
                phone: data.phone,
                orgId: userInfo.orgId,
                userId: userInfo.userId,
                userName: userInfo.userName,

            },
            showmodelTranfer: {
                visible: false
            },
        })
    }
    showmodelMigrationFn = (data) => {
        let code = data.code.toUpperCase()
        getOrgInfoFromOrgCode(code).then(res => {

            console.log("getOrgInfoFromOrgCode===============", res)
            if (res.data.code === 200) {
                this.setState({
                    showmodelMigrationOk: {
                        visible: true,
                        code: code,
                        orgMessage: this.state.orgMessage.name,
                        orgName: res.data.data.name,
                        id: res.data.data.id,
                        orgId: this.props.userInfo.orgId
                    },
                    showmodelMigration: {
                        visible: false
                    }
                })
            }
            else {
                message.warning(res.data.errorMessage)
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('组织信息获取失败！')
            }

        })

    }
    migrationbutton = () => {
        this.setState({
            showmodelMigration: {
                visible: true
            }
        })
    }
    submitmodelCancel = () => {
        this.setState({
            showmodelCancelAuth: {
                visible: true,
                euId: this.state.orgMessage.id,
                cpId: this.state.orgMessage.cpId,
            }
        })
    }
    showmodelCancelAuthFn = () => {
        let that = this;
        setTimeout(() => {
            that.setState({
                showmodelCancelAuth: {
                    visible: false
                }
            })
            that.fetch()
        }, 1000)

    }

    _showContactModel = () => {
        var contactConfig = { ...this.props.getContactConfig }
        var deviceContacts = [
            {
                "title": "首选联系人", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 0, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: true
            },
            {
                "title": "备选联系人1", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 1, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: true
            },
            {
                "title": "备选联系人2", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 2, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: false
            },
            {
                "title": "备选联系人3", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 3, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: false
            },
        ]
        contactConfig.visible = true

        const { contacts, contactsUpdateTime, contactsUpdateUser } = this.props.getOrgInfo

        contacts.map((item, index) => {
            if (index < 4) {
                var contact = deviceContacts[index]
                contact.name = item.name
                contact.phone = item.phone
            }
        })
        contactConfig.deviceContacts = deviceContacts
        contactConfig.contactsUpdateTime = contactsUpdateTime
        contactConfig.contactsUpdateUser = contactsUpdateUser
        this.props.updateContactsConfig(contactConfig)
    }
    _showRemindContactModel = () => {
        let config = { ...this.props.getRemindUpdateContactConfig }
        config.visible = true
        this.props.updateRemindUpdateContactConfig(config)
    }

    //组织联系人信息
    _renderOrgContactsUI = () => {
        const { userInfo, getOrgInfo, ssName } = this.props;
        const { contacts } = getOrgInfo



        console.log("userrinfo===============", userInfo)

        var menu = null

        if (userInfo.isOrgAdministrator === true) {
            menu = (<Menu style={{ width: 167 }}>
                <Menu.Item onClick={() => this._showContactModel()}>
                    <ABB.Icon style={{ margin: '0 0px 0 0px' }} name="abb/edit" sizeClass="small" />编辑联系人</Menu.Item>
            </Menu>)
        }


        return <>
            <div className='information_title_wrapper'>
                <div className="org_information">组织联系人</div>

                {

                    menu !== null ? <Dropdown
                        overlay={menu}
                        placement="bottomRight"
                        trigger={['click']}
                    >
                        <div
                            onClick={() => { console.log("点击了") }}>
                            <ABB.Icon name="abb/menu" sizeClass='small'
                                style={{ 'color': '#1F1F1F' }}
                            />
                        </div>

                    </Dropdown> : null

                }

            </div>

            <div className='line'></div>

            <Row gutter={24}  >

                {
                    contacts.map((item, index) => {

                        return (
                            <Col span={6}>
                                <div className='org_information_list'>
                                    <span className="org_information_list_lable">{item.title}</span>：
                                    <span>{item.name}   {item.phone}</span>
                                </div>
                            </Col>
                        )
                    })

                }


            </Row>


        </>

    }

    //一维数组转二维数组
    _oneDimensionalArrayToTwoDimensionalArray = (arr, num) => {
        let newArr = []
        const total = Math.ceil(arr.length / num)
        console.log(total);
        for (let i = 0; i < total; i++) {
            let a = arr.slice(i * num, (i + 1) * num)
            newArr.push(a)
        }
        return newArr
    }



    _renderOrgInformation = (informationList) => {

        const newList = this._oneDimensionalArrayToTwoDimensionalArray(informationList, 4)

        return <>
            {
                newList.map((list) => {
                    return <Row gutter={24}>
                        {
                            list.map((item, list) => {
                                return <Col span={6}>
                                    <div className='org_information_list'>
                                        <span className="org_information_list_lable">{item.title}</span>：
                                        <span>{item.message}</span>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                })
            }
        </>

    }



    _logoutOrg = () => {
        const config = { ...this.props.getLogoutOrganizationConfig }
        config.visible = true

        this.props.updateLogoutOrganizationConfig(config)
    }

    //服务商信息
    _renderServiceProviderInformationUI = (data, _orgdata) => {

        if (data.organizationType === "EU" && data.organizationSubType === "Actual") {
            var itemList = []

            if (_orgdata.isGranted === false) {
                itemList = [
                    {
                        title: "名 称",
                        message: "尚未授权服务商"
                    },
                    {
                        title: "联系人",
                        message: "无"
                    },
                    {
                        title: "联系方式",
                        message: "无"
                    },
                ]

                let menu = null
                let menuList = []

                if (data.isOrgAdministrator === true) {
                    menuList.push(<Menu style={{ width: 167 }}>
                        <Menu.Item onClick={() => this.Toauthorize(true, '授权提示')}>
                            <ABB.Icon style={{ margin: '0 5px 0 0px' }} name="abb/briefcase" sizeClass="small" />授权服务商</Menu.Item>

                    </Menu>)
                }

                if (menuList.length > 0) {

                    menu = <Menu>
                        {
                            menuList.map((item, index) => {
                                return item
                            })
                        }
                    </Menu>
                }


                return <>
                    <div className='information_title_wrapper'>
                        <div className="org_information">服务商信息</div>

                        {

                            menu !== null ? <Dropdown
                                overlay={menu}
                                placement="bottomRight"
                                trigger={['click']}
                            >
                                <div
                                    onClick={() => { }}>
                                    <ABB.Icon name="abb/menu" sizeClass='small'
                                        style={{ 'color': '#1F1F1F' }}
                                    />
                                </div>

                            </Dropdown> : null

                        }



                    </div>
                    <div className='line'></div>
                    {
                        this._renderOrgInformation(itemList)
                    }

                    {
                        data.isOrgSuperAdmin === true ? (
                            <Logoutgroup />
                        ) : ''
                    }


                    <AuthorizationText
                        showmodel={this.state.showmodel}
                        hidemodel={this.hidemodel}
                        submitmodel={this.submitmodel}
                    />
                    <AuthorizationModel
                        showmodelcode={this.state.showmodelcode}
                        hidemodel={this.hidemodel}
                        submitmodelcode={this.submitmodelcode} />
                </>


            } else {
                itemList = [
                    {
                        title: "名 称",
                        message: _orgdata.serviceProviderName
                    },
                    {
                        title: "联 系 人",
                        message: _orgdata.serviceProviderContactName
                    },
                    {
                        title: "联系方式",
                        message: _orgdata.serviceProviderContactPhone
                    },
                ]

                var menu = null

                // if (this.state.orgMessage !== '' &&  this.state.orgMessage.canCancelCpPermission === true) {
                //     menu = (<Menu style={{ width: 167 }}>
                //         <Menu.Item onClick={() => this.tranferButton()}>取消授权</Menu.Item>

                //     </Menu>)
                // }


                return <>
                    <div className='information_title_wrapper'>
                        <div className="org_information">服务商信息</div>

                        {

                            menu !== null ? <Dropdown
                                overlay={menu}
                                placement="bottomRight"
                                trigger={['click']}
                            >
                                <div
                                    onClick={() => { console.log("点击了") }}>
                                    <ABB.Icon name="abb/menu" sizeClass='small'
                                        style={{ 'color': '#1F1F1F' }}
                                    />
                                </div>

                            </Dropdown> : null

                        }



                    </div>
                    <div className='line'></div>
                    {
                        this._renderOrgInformation(itemList)
                    }
                    <div className='org_information_list'>
                        <Checkbox.Group defaultValue={['1']}>
                            <Checkbox value='1' disabled={true}></Checkbox>
                        </Checkbox.Group>
                        已接受服务协议
                    </div>

                </>

            }
        }
    }


    //公司信息
    _renderOrgInformationUI = (data, _orgdata) => {

        const { userInfo } = this.props;
 

        if (data.organizationType === "EU" && data.organizationSubType === "Actual") {
            let list = []
            if (_orgdata.isGranted === false) {
                list = [
                    {
                        title: "名 称",
                        message: _orgdata.name
                    },
                    {
                        title: "行 业",
                        message: _orgdata.industry
                    },
                    {
                        title: "联系人",
                        message: _orgdata.contact
                    },
                    {
                        title: "电 话",
                        message: _orgdata.phone
                    },
                    {
                        title: "SmartSensor 组织",
                        message: _orgdata.ssName
                    },
                    {
                        title: "组织编码",
                        message: this.state.orgMessage.code
                    }
                ]
            } else {
                list = [
                    {
                        title: "名 称",
                        message: _orgdata.name
                    },
                    {
                        title: "行 业",
                        message: _orgdata.industry
                    },
                    {
                        title: "地 址",
                        message: _orgdata.address
                    },
                    {
                        title: "联系人",
                        message: _orgdata.contact
                    },
                    {
                        title: "电 话",
                        message: _orgdata.phone
                    },
                    {
                        title: "SmartSensor 组织",
                        message: _orgdata.ssName
                    },
                    {
                        title: "组织编码",
                        message: this.state.orgMessage.code
                    }
                ]
            }

            var menu = null
            let menuList = []


            if (this.state.orgMessage !== '') {
                if (userInfo.isOrgAdministrator === true) {
                    menuList.push(<Menu.Item onClick={() => this._editOrgInformation()}>
                        <ABB.Icon style={{ margin: '0 5px 0 0px' }} name="abb/edit" sizeClass="small" /> 编辑组织信息</Menu.Item>)
                }
                if (this.state.orgMessage.canHandover === true) {
                    menuList.push(<Menu.Item onClick={() => this.tranferButton()}>
                        <ABB.Icon style={{ margin: '0 5px 0 0px' }} name="abb/user" sizeClass="small" /> 移交管理权</Menu.Item>)
                }
                if (this.state.orgMessage.canMigration === true) {
                    menuList.push(<Menu.Item onClick={() => this.migrationbutton()}>
                        <ABB.Icon style={{ margin: '0 5px 0 0px' }} name="abb/rearrange" sizeClass="small" />迁移组织</Menu.Item>)
                }
                if (data.isOrgSuperAdmin === true) {
                    menuList.push(<Menu.Item onClick={() => this._logoutOrg()}>
                            <ABB.Icon style={{ margin: '0 5px 0 0px' }} name="abb/log-out" sizeClass="small" />退出组织</Menu.Item>)
                }
            }
            if (menuList.length > 0) {

                menu = <Menu>
                    {
                        menuList.map((item, index) => {
                            return item
                        })
                    }
                </Menu>
            }

            return <>
                <div className='information_title_wrapper'>
                    <div className="org_information">公司信息</div>

                    {
                        this.state.orgMessage !== '' && menu !== null ? (
                            <Dropdown
                                overlay={menu}
                                placement="bottomRight"
                                trigger={['click']}
                            >
                                <div
                                    onClick={() => { console.log("点击了") }}>
                                    <ABB.Icon name="abb/menu" sizeClass='small'
                                        style={{ 'color': '#1F1F1F' }}
                                    />
                                </div>

                            </Dropdown>
                        ) : ''
                    }



                </div>
                <div className='line'></div>

                {
                    this._renderOrgInformation(list)
                }

            </>

        } else {
            var list = [
                {
                    title: "名称",
                    message: _orgdata.name
                },
                {
                    title: "行业",
                    message: _orgdata.industry
                },
                {
                    title: "地址",
                    message: _orgdata.address
                }
            ]
            if (data.organizationType === "CP") {
                list.push(
                    {
                        title: "组织编码",
                        message: this.state.orgMessage.code
                    }
                )
            }

            var menu = null
            let menuList = []

            if (this.state.orgMessage !== '') {

                if (userInfo.isOrgAdministrator === true) {
                    menuList.push(<Menu.Item onClick={() => this._editOrgInformation()}>
                        <ABB.Icon style={{ margin: '0 5px 0 0px' }} name="abb/edit" sizeClass="small" /> 编辑组织信息</Menu.Item>)
                }

                if (this.state.orgMessage.canHandover === true) {
                    menuList.push(
                        <Menu.Item onClick={() => this.tranferButton()}>
                            <ABB.Icon style={{ margin: '0 5px 0 0px' }} name="abb/user" sizeClass="small" /> 移交管理权</Menu.Item>
                    )
                }

                if (this.state.orgMessage.canMigration === true) {
                    menuList.push(<Menu.Item onClick={() => this.migrationbutton()}>
                        <ABB.Icon style={{ margin: '0 5px 0 0px' }} name="abb/rearrange" sizeClass="small" />迁移组织</Menu.Item>)
                }

                // if (this.state.orgMessage.canCancelCpPermission === true) {
                //     menuList.push(<Menu.Item onClick={() => this.submitmodelCancel()}>取消授权</Menu.Item>)
                // }

            }
            if (menuList.length > 0) {

                menu = <Menu>
                    {
                        menuList.map((item, index) => {
                            return item
                        })
                    }
                </Menu>
            }
 
            return <>
                <div className='information_title_wrapper'>
                    <div className="org_information">公司信息</div>

                    {
                        this.state.orgMessage !== '' && menu !== null ? (
                            <Dropdown
                                overlay={menu}
                                placement="bottomRight"
                                trigger={['click']}
                            >
                                <div
                                    onClick={() => { console.log("点击了") }}>
                                    <ABB.Icon name="abb/menu" sizeClass='small'
                                        style={{ 'color': '#1F1F1F' }}
                                    />
                                </div>

                            </Dropdown>
                        ) : null
                    }

                </div>
                <div className='line'></div>

                {
                    this._renderOrgInformation(list)
                }

            </>
        }

    }

    //判断是否授权，判断是EU还是CP
    FnorganizationType = (data, _orgdata) => {
        let that = this;

        if (data.organizationType === "EU" && data.organizationSubType === "Float") {
            return (
                <WrappedRegistrationForm updateOrg={that.updateOrg} />
            )
        }

        return (
            <>
                {
                    this._renderOrgInformationUI(data, _orgdata)
                }
                {
                    //组织联系人
                    this._renderOrgContactsUI()
                }
                {
                    //服务商信息
                    this._renderServiceProviderInformationUI(data, _orgdata)
                }
            </>
        )
    }


    render() {
        let that = this;
        const { userInfo, getOrginformation, ssName } = that.props;
        getOrginformation.ssName = ssName;
        // let _orgdata = that.state.orgdata;
        return (
            <div className="ABB_tab_marginTop">
                {
                    that.FnorganizationType(userInfo, getOrginformation)
                }
                <TransferControl
                    hidemodel={this.hidemodel}
                    showmodelTranferOkFn={this.showmodelTranferOkFn}
                    showmodelTranfer={this.state.showmodelTranfer}
                />
                <TransferControlOk
                    hidemodel={this.hidemodel}
                    showmodelTranferOk={this.state.showmodelTranferOk}
                />
                <Migration
                    hidemodel={this.hidemodel}
                    showmodelMigration={this.state.showmodelMigration}
                    showmodelMigrationFn={this.showmodelMigrationFn}
                />
                <MigrationFormOk
                    showmodelMigrationOk={this.state.showmodelMigrationOk}
                    hidemodel={this.hidemodel}
                />
                <CancelAuth
                    showmodelCancelAuth={this.state.showmodelCancelAuth}
                    hidemodel={this.hidemodel}
                    showmodelCancelAuthFn={this.showmodelCancelAuthFn}
                />
                <ContactModel
                    showmodelboundsub={this.props.getContactConfig}
                    hidemodel={() => { }}
                    submitmodel={() => {
                        this.refreshOrgData()
                        //弹框提示,是否更新至新设备
                        this._showRemindContactModel()
                    }} />
                <RemindContactModel showmodelboundsub={this.props.getRemindUpdateContactConfig}
                    hidemodel={() => { }}
                    submitmodel={() => { }}
                />
                <EditOrgInfo showmodelboundsub={this.props.getRemindUpdateContactConfig}
                    hidemodel={() => { }}
                    submitmodel={() => {
                        this.fetch()
                    }} />
            </div>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    loading: data => {
        dispatch(loading(data))
    },
    setUserInfo: data => {
        dispatch(setUserInfo(data))
    },
    setOrginformation: data => {
        dispatch(setOrginformation(data))
    },
    updateContactsConfig: data => {
        dispatch(setContactConfig(data))
    },
    updateOrgInfo: data => {
        dispatch(setOrgInfo(data))
    },
    updateRemindUpdateContactConfig: data => {
        dispatch(setRemindUpdateContactConfig(data))
    },
    updateLogoutOrganizationConfig: data => {

        dispatch(setLogoutOrganizationConfig(data))
    },
    updateEditOrgInfoConfig: data => {

        dispatch(setEditOrgConfig(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OrgInformation));
