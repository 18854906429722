import React, { Component } from 'react';
import { Modal, Button, Input, Checkbox, Row, Col, message, Radio } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
const { Search } = Input;
class Authorization extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkoutList: [],
            inputval: [],
            indeterminate: true,
            checkAll: false,
            displayType: 0
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    componentDidMount() {
        this.setState({
            displayType: this.props.showmodelAuthorization.assetGroups.displayDeviceGroupType ?? 0
        })
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.setState({
            inputval: [],
            indeterminate: true,
            checkoutList: [],
            checkAll: false,
        })
    }
    submitmodel = (data, displayType) => {
        if (data.length > 0 || displayType != this.props.showmodelAuthorization.assetGroups.displayDeviceGroupType) {
            this.props.submitmodel(data, displayType)
            this.setState({
                inputval: [],
                indeterminate: true,
                checkoutList: [],
                checkAll: false,
                displayType: 0,
            })
        }
        else {
            message.warning("还未选择设备组")
        }
    }
    onCheckAllChange = e => {
        const { showmodelAuthorization } = this.props;
        let { checked, checkoutList } = this.state;

        if (e.target.checked === true) {
            showmodelAuthorization.assetGroups.unOwned.map((item, index) => {
                this.setState({
                    checkoutList: checkoutList.push(item.id)
                })
            })
        }
        else {
            this.setState({
                checkoutList: []
            })
        }
        this.setState({
            inputval: e.target.checked ? this.state.checkoutList : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };
    onChange = (e) => {
        this.setState({
            inputval: e,
        })
    };
    onDisplayTypeChange = (e) => {
        this.setState({
            displayType: e.target.value,
        })
    }
    render() {
        const that = this;
        const { showmodelAuthorization, disabled, displayDeviceGroupType } = this.props;
        const modelProps = {
            title: showmodelAuthorization.title,
            centered: showmodelAuthorization.centered,
            width: showmodelAuthorization.width,
            maskClosable: false,
            visible: showmodelAuthorization.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(that.state.inputval, that.state.displayType)}
                    type="primary-blue"
                    disabled={disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div className='box_flex'>已授权设备组</div>
                <div style={{ width: '100%', marginBottom: 16 }}>
                    <Row>
                        {
                            showmodelAuthorization.assetGroups.owned.length > 0 ? (
                                showmodelAuthorization.assetGroups.owned.map((item, index) => {
                                    return (
                                        <Col span={24} key={index}>
                                            <div className="ownIdlist">{item.groupName}</div>
                                        </Col>
                                    )
                                })
                            ) : <div className="nodatagroup">暂无已授权设备组</div>
                        }
                    </Row>
                </div>
                <div className='box_flex'>未授权设备组</div>
                <Checkbox
                    indeterminate={this.state.indeterminate}
                    onChange={this.onCheckAllChange}
                    checked={this.state.checkAll}
                >
                    全选
                </Checkbox>
                <hr />
                <Checkbox.Group
                    value={this.state.inputval}
                    style={{ width: '100%', minHeight: 80, maxHeight: 200, overflow: 'auto' }} onChange={that.onChange}>
                    <Row>
                        {
                            showmodelAuthorization.assetGroups.unOwned.length > 0 ? (
                                showmodelAuthorization.assetGroups.unOwned.map((item, index) => {
                                    return (
                                        <Col span={24} key={index}>
                                            <Checkbox

                                                value={item.id}>{item.groupName}</Checkbox>
                                        </Col>
                                    )
                                })
                            ) : <div className="nodatagroup">暂无未授权设备组</div>

                        }
                    </Row>
                </Checkbox.Group>
                <div className='box_flex'>设备组查看权限</div>
                <Radio.Group onChange={this.onDisplayTypeChange} value={this.state.displayType}>
                    <Radio value={0} key="d_0">
                        显示所有设备组（包括无查看权限设备组）
                    </Radio>
                    <Radio value={1} key="d_1">
                        仅显示有权限设备组
                    </Radio>
                </Radio.Group>

            </Modal>
        );
    }
}

export default Authorization;
