import React, { Component } from 'react';
import { assettype } from '@/api/subscription';
import { getApplyType, checkCreateasset } from '@/api/device.js';
import unittype from '@/utils/unittype'

import { connect } from 'react-redux';
import { setplant } from '@/redux/actions/plant';
import { withRouter } from 'react-router-dom';
import {
    Form,
    Input,
    Select,
    Modal,
    message,
    Col,
    Row,
    Radio, Spin
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import Addplant from '../../plant/components/addplant'
import AddDeviceProcess from './addDeviceProcess';

class DeviceModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devicevalue: 2,
            isShowApplyType: false,
            addSN: 2,
            disabled: false,
            inputval: '',
            devicedetail: false,
            confirmDirty: false,
            serialnumber: false,
            assettypedata: '',
            createOrgdata: false,

            trendplaceholder: '请输入传动节点ID',
            guild: [
            ],
            plantlist: [],
            applyType: [],
            loading: false
        };

    }
    UNSAFE_componentWillMount() {
        this.assettype()
        this.getTypeList()
    }
    getTypeList = () => {
        getApplyType('').then(res => {
            if (res !== undefined) {
                this.setState({
                    applyType: res.data.data
                })
            }
        })
    }
    //获取适用设备
    assettype = () => {
        let url = ''
        assettype(url).then(res => {
            if (res !== undefined) {
                this.setState({
                    guild: res.data
                })
            }
        })
    }


    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {

        if (this.state.loading === true) {
            return
        }

        this.props.hidemodel(data);
        this.props.form.resetFields();
        this.setState({
            inputmessage: false,
            devicevalue: 2,
            addSN: 2,
            serialnumber: false,
            isShowApplyType: false
        })
    }

    submitmodel = () => {

        this.setState({
            disabled: true,
            loading: true
        })

        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                that.checkCreateassetfn(fieldsValue, this.state.devicevalue, this.state.serialnumber);

            } else {
                message.warning("信息不完整")
                this.setState({
                    disabled: false,
                    loading: false
                })
            }
        });

    }

    //点击下一步,请求接口,是否有订阅
    checkCreateassetfn(val, type, inputmessage) {
        // this.setState({
        //     disabled: false,
        //     loading: false
        // })

        // let deviceData = {
        //     "createId": "d274a29e-6a47-4c04-adda-ccd95fe5",
        //     "serialNumber": "312420", // 设备序列号
        //     "userInputSN": "3124200",
        //     "backendIdentifier": "",
        //     "assetName": "000",
        //     "deviceSource": 1,
        //     "objectId": null,
        //     "applicationName": "电机",
        //     "assetGroupId": null,
        //     "isHasSub": true,  // 是否拥有订阅额度
        //     "isNoSerialNumber": false,
        //     "createSource": 2,
        //     "isApplyTasteSub": false,
        //     "gateway": {
        //         "gatewaySN": "7330164",
        //         "gatewayType": 1
        //     },
        //     "org": {
        //         "orgId": 39,
        //         "orgName": null
        //     },
        //     "plant": {
        //         "plantId": null,
        //         "province": "北京市",
        //         "city": "北京市",
        //         "district": "海淀区"
        //     },
        //     "subscription": {
        //         "subscriptionId": -10,
        //         "subscriptionLevel": "标准版",
        //         "subscriptionType": "体验",
        //         "validPeriodTime": "1个月"
        //     }
        // }

        // this.props.form.resetFields();
        // this.props.submitmodel(deviceData)


        // return

        let that = this;
        let url = {};
        let nodeid = '';
        let neta21devicetype = '';
        let iccid = '';
        let deviceApplication = ''
        if (val.nodeid === undefined) {
            nodeid = ''
        } else {
            nodeid = val.nodeid.trim();
        }

        if (val.iccid === undefined) {
            iccid = ''
        } else {
            iccid = val.iccid.trim();
        }

        if (val.neta21devicetype === undefined) {
            neta21devicetype = ''
        } else {
            neta21devicetype = val.neta21devicetype.trim();
        }
        if (val.applyType === "其他") {
            deviceApplication = val.applyTypeText === undefined ? '' : val.applyTypeText
        } else {
            deviceApplication = val.applyType
        }

        let code = ''
        if (val.code === undefined) {
            code = ''
        } else {
            code = val.code.trim();
        }
        url = {
            "deviceGatewayType": type,
            "assetType": 2,
            "assetName": val.name,
            "assetGroupID": that.props.deviceGroupId,
            "plantID": val.plant,
            "deviceSrNo": code,
            "isNoDeviceSN": inputmessage,
            "deviceModel": val.devicetype,
            "deviceNodeId": nodeid,
            "deviceICCID": iccid,
            "gatewaySN": neta21devicetype,
            "deviceApplication": deviceApplication
        }


        checkCreateasset(url).then((res) => {

            console.log("校验设备成功----", res)

            this.setState({
                disabled: false,
                loading: false
            })

            if (res !== undefined) {
                let deviceData = res.data.data;

                // let  deviceData = {
                //     "createId": "d274a29e-6a47-4c04-adda-ccd95fe5",
                //     "serialNumber": "312420", // 设备序列号
                //     "userInputSN": "3124200",
                //     "backendIdentifier": "",
                //     "assetName": "000",
                //     "deviceSource": 1,
                //     "objectId": null,
                //     "applicationName": "电机",
                //     "assetGroupId": null,
                //     "isHasSub": true,  // 是否拥有订阅额度
                //     "isNoSerialNumber": false,
                //     "createSource": 2,
                //     "isApplyTasteSub":false,
                //     "gateway": {
                //         "gatewaySN": "7330164",
                //         "gatewayType": 1
                //     },
                //     "org": {
                //         "orgId": 39,
                //         "orgName": null
                //     },
                //     "plant": {
                //         "plantId": null,
                //         "province": "北京市",
                //         "city": "北京市",
                //         "district": "海淀区"
                //     },
                //     "subscription": {
                //         "subscriptionId": -10,
                //         "subscriptionLevel": "标准版",
                //         "subscriptionType": "体验",
                //         "validPeriodTime": "1个月"
                //     }
                // }

                this.props.form.resetFields();
                this.props.submitmodel(deviceData)
            }

        }).catch(err => {
            this.setState({
                disabled: false,
                loading: false
            })

            console.log("校验设备成功----", err)
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data.errorMessage)
            } else {
                message.error('添加失败，请联系客服或稍后再试')
            }
        })
    }
    onChange = (e) => {
        this.setState({
            inputval: e.target.value
        })
    }
    devicedetail = (devicedetail) => {
        if (this.state.assettypedata === '') {
            message.warning("请选择设备类型")
        } else if (this.state.assettypedata === '电机') {
            message.warning("请选择传动设备")
        } else {
            this.setState({
                devicedetail
            })
            this.hidemodel(false)
        }
    }
    onChangetype = (e) => {
        if (e === 2) {
            this.setState({
                assettypedata: '传动'
            })
        } else if (e === 3) {
            this.setState({
                assettypedata: "电机"
            })
        }
    }
    onChangefrom = (e) => {
        let val = e.target.value
        this.setState({
            devicevalue: val,
            serialnumber: false,
            addSN: 2
        })
        this.props.form.resetFields();
    }
    onChangeSNAddType = (e) => {
        let val = e.target.value
        this.setState({
            addSN: val,
            serialnumber: val === 2 ? false : true
        })
        this.props.form.resetFields();
    }
    onChangeApplytype = (e) => {
        let that = this;
        setTimeout(() => {
            console.log("eeeeeeeeeee", e)
            if (e === '其他') {
                that.setState({
                    isShowApplyType: true
                })
            } else {
                that.setState({
                    isShowApplyType: false
                })
            }
        }, 0)
    }

    //  返回标题信息
    _renderHeaderByTitle = (isMust, title, rightfun) => {
        return <div className='section_item'>
            <div className='left'>
                {
                    isMust && <div className='section_icon'></div>
                }
                <div >{title}</div>
            </div>


            {
               rightfun &&  rightfun()
            }

        </div>

    }

    render() {
        const that = this;
        const { showmodel, getplant } = that.props;
        const { getFieldDecorator } = that.props.form;
        const { isShowApplyType, applyType, disabled } = that.state;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showmodel.title,
            centered: showmodel.centered,
            width: showmodel.width,
            visible: showmodel.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <div className='addDeviceUnSubscription_footer_content'>

                    <div className="sub_bottom_content">
                        <ABB.Icon style={{ cursor: "auto" }} name="abb/warning-triangle" sizeClass='small' color='#F03040' />
                        <div className='sub_bottom_title'>点击“关闭”按钮后填写的信息将无法保存</div>
                    </div>

                    <ABB.Button
                        onClick={() => that.submitmodel()}
                        type="primary-blue"
                        disabled={disabled}
                        sizeClass='small' theme="filled"
                        text="下一步"
                    />
                </div>
            ]
        }
        let required = true;
        if (this.state.serialnumber === true) {
            required = false;
        } else {
            required = true;
        }


        // let required = this.state.serialnumber;
        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >

                    <Spin spinning={this.state.loading} style={{ minHeight: '50vh' }}>
                        <AddDeviceProcess></AddDeviceProcess>

                        <Row gutter={24}>
                            <Col span={3}>
                                <div>网关类型:</div>
                            </Col>
                            <Col span={18}>
                                <Radio.Group style={{ marginBottom: 16 }} value={this.state.devicevalue} onChange={(e) => this.onChangefrom(e)}>
                                    <Radio style={{ width: 140 }} value={2}>loT Panel</Radio>
                                    <Radio style={{ width: 230 }} value={1}>NETA-21网关</Radio>
                                </Radio.Group>
                            </Col>
                        </Row>

                        <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>


                            <Row gutter={24}>

                                <Col span={8}>
                                    <div>
                                        {
                                            this._renderHeaderByTitle(false, "设备类型:")
                                        }

                                        <Form.Item >
                                            {getFieldDecorator('type', {
                                                rules: [
                                                    { required: false, message: '' },
                                                ],
                                                initialValue: that.state.assettypedata
                                            })(
                                                <Input className="model_input" disabled={true} placeholder='传动' />,
                                            )}
                                        </Form.Item>
                                    </div>

                                </Col>
                                <Col span={8}>


                                    <div>
                                        {
                                            this._renderHeaderByTitle(false, "应用类型:")
                                        }
                                        <Form.Item  >
                                            {getFieldDecorator('applyType', {

                                            })(
                                                <Select placeholder="请选择应用类型"
                                                    onChange={(e) => this.onChangeApplytype(e)}
                                                    suffixIcon={
                                                        <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                                    }>
                                                    {
                                                        applyType.map((item, index) => {
                                                            return (
                                                                <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>,
                                            )}
                                        </Form.Item>
                                    </div>




                                </Col>
                                {
                                    isShowApplyType ? (
                                        <Col span={8}>
                                            <Form.Item label=":" className='addDeviceApplayType'>
                                                {getFieldDecorator('applyTypeText', {
                                                    rules: [
                                                        { required: false, message: '' },
                                                        {
                                                            max: 20,
                                                            message: '字符最大长度为20'
                                                        },
                                                        {
                                                            whitespace: true,
                                                            message: '禁止输入空格',
                                                        }
                                                    ],
                                                })(
                                                    <Input className="model_input" placeholder='请输入应用类型' />,
                                                )}
                                            </Form.Item>
                                        </Col>
                                    ) : ''
                                }

                            </Row>
                            {
                                this.state.devicevalue === 2 ? (
                                    <div>
                                        <Row gutter={24}>
                                            <Col span={3}>
                                                <div>添加方式:</div>
                                            </Col>
                                            <Col span={18}>
                                                <Radio.Group style={{ marginBottom: 16 }} value={this.state.addSN} onChange={(e) => this.onChangeSNAddType(e)}>
                                                    <Radio style={{ width: 140 }} value={2}>传动序列号</Radio>
                                                    <Radio style={{ width: 230 }} value={1}>节点ID+ICCID（序列号添加失败时）</Radio>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <Row gutter={24}>
                                            <Col span={21}>
                                                <a href="https://sycfxrsxgy.jiandaoyun.com/sharedoc/69E6khYbxMQKhys4ZLiWiH" target="_blank" >
                                                    如何找到传动序列号，节点ID和ICCID
                                                </a>
                                            </Col>
                                        </Row>
                                        <br></br>
                                    </div>
                                ) : ''
                            }
                            <Row gutter={24}>
                                <Col span={8}>
                                    <div>
                                        {
                                            this._renderHeaderByTitle(true, "传动名称:")
                                        }
                                        <Form.Item  >
                                            {getFieldDecorator('name', {
                                                rules: [

                                                    {
                                                        required: true,
                                                        message: '传动名称不能为空',
                                                    },
                                                    {
                                                        max: 30,
                                                        message: '字符最大长度为30'
                                                    },
                                                    {
                                                        whitespace: true,
                                                        message: '禁止输入空格',
                                                    }
                                                ],
                                            })(
                                                <Input placeholder="请输入传动名称" className="model_input" />,
                                            )}
                                        </Form.Item>
                                    </div>



                                </Col>
                                <Col span={8}>
                                    <div>
                                        {
                                            this._renderHeaderByTitle(true, "传动序列号:")
                                        }
                                        <Form.Item >
                                            {getFieldDecorator('code', {
                                                rules: [
                                                    { required: true, message: '传动序列号不能为空' },
                                                    {
                                                        max: 30,
                                                        message: '字符最大长度为30'
                                                    },
                                                    {
                                                        whitespace: true,
                                                        message: '禁止输入空格',
                                                    }
                                                ],
                                            })(
                                                // <div className="deviceformbox">
                                                <Input
                                                    placeholder="请输入传动序列号"
                                                    className="model_input"
                                                // disabled={this.state.serialnumber === true ? true : false}
                                                />

                                            )}
                                        </Form.Item>
                                    </div>

                                </Col>
                                {/* <Col span={8}>
                                    <div>
                                        {
                                            this._renderHeaderByTitle(true, "位置:")
                                        }
                                        <Form.Item

                                            className="deviceformbox"
                                        >
                                            {getFieldDecorator('plant', {
                                                rules: [{ required: true, message: '位置不能为空' }],
                                            })(

                                                <Select placeholder="请选择位置"
                                                    suffixIcon={
                                                        <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                                    }>
                                                    {
                                                        getplant.map((item, index) => {
                                                            return (
                                                                <Select.Option value={item.id}>{item.name}</Select.Option>
                                                            )
                                                        })
                                                    }
                                                </Select>,

                                            )}
                                        </Form.Item>
                                    </div>

                                    <div className="address_button">
                                        <Addplant />
                                    </div>
                                </Col> */}

                            </Row>

                            {
                                this.state.devicevalue === 2 ? (
                                    this.state.serialnumber === true ? (
                                        <Row gutter={24}>
                                            <Col span={8}>
                                                <div>
                                                    {
                                                        this._renderHeaderByTitle(true, "设备型号:")
                                                    }

                                                    <Form.Item  >
                                                        {getFieldDecorator('devicetype', {
                                                            rules: [

                                                                {
                                                                    required: true,
                                                                    message: '设备型号不能为空',
                                                                },
                                                                {
                                                                    max: 30,
                                                                    message: '字符最大长度为30'
                                                                }
                                                            ],
                                                        })(
                                                            <Select placeholder="请选择设备型号"
                                                                suffixIcon={
                                                                    <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                                                }>
                                                                {
                                                                    unittype.map(item => {
                                                                        return (
                                                                            <Select.Option value={item.value}>{item.value}</Select.Option>
                                                                        )
                                                                    })
                                                                }

                                                            </Select>
                                                        )}
                                                    </Form.Item>

                                                </div>


                                            </Col>
                                            <Col span={8}>
                                                <div>
                                                    {
                                                        this._renderHeaderByTitle(true, "传动节点ID:")
                                                    }
                                                    <Form.Item  >
                                                        {getFieldDecorator('nodeid', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: '传动节点ID不能为空'
                                                                },
                                                                {
                                                                    max: 30,
                                                                    message: '字符最大长度为30'
                                                                },
                                                                {
                                                                    min: 1,
                                                                    message: '字符最小长度为1'
                                                                },
                                                                {
                                                                    whitespace: true,
                                                                    message: '禁止输入空格',
                                                                }
                                                            ],
                                                        })(
                                                            <Input type="Number" placeholder="请输入传动节点ID" className="model_input" />
                                                        )}
                                                    </Form.Item>
                                                </div>

                                            </Col>
                                            <Col span={8}>
                                                <div>
                                                    {
                                                        this._renderHeaderByTitle(true, "ICCID:")
                                                    }
                                                    <Form.Item  >
                                                        {getFieldDecorator('iccid', {
                                                            rules: [{ required: true, message: 'ICCID不能为空' },
                                                            {
                                                                whitespace: true,
                                                                message: '禁止输入空格',
                                                            }],
                                                        })(
                                                            <Input placeholder="请输入ICCID" className="model_input" />
                                                        )}
                                                    </Form.Item>
                                                </div>

                                            </Col>
                                        </Row>
                                    ) : ''
                                ) : (
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            <div>
                                                {
                                                    this._renderHeaderByTitle(true, "NETA-21序列号:")
                                                }
                                                <Form.Item  >
                                                    {getFieldDecorator('neta21devicetype', {
                                                        rules: [

                                                            {
                                                                required: true,
                                                                message: 'NETA-21序列号不能为空',
                                                            },
                                                            {
                                                                max: 15,
                                                                message: '字符最大长度为15'
                                                            },
                                                            {
                                                                whitespace: true,
                                                                message: '禁止输入空格',
                                                            }
                                                        ],
                                                    })(
                                                        <Input placeholder="请输入NETA-21序列号" className="model_input" />,
                                                    )}
                                                </Form.Item>

                                            </div>


                                        </Col>
                                        <Col span={8}>
                                            <div>
                                                {
                                                    this._renderHeaderByTitle(true, "传动节点ID:", () => {
                                                        return (
                                                            <a href="https://sycfxrsxgy.jiandaoyun.com/sharedoc/74MdQ1XMwI2mZKbhkDXb0l" target="_blank" >
                                                                <ABB.Icon className="right_icon" name="abb/help-circle-2" sizeClass='small' />
                                                            </a>
                                                        )
                                                    })
                                                }
                                                <Form.Item >
                                                    {getFieldDecorator('nodeid', {
                                                        rules: [{ required: true, message: '传动节点ID不能为空' },
                                                        {
                                                            max: 30,
                                                            message: '字符最大长度为30'
                                                        },
                                                        {
                                                            min: 2,
                                                            message: '字符最小长度2'
                                                        },
                                                        {
                                                            whitespace: true,
                                                            message: '禁止输入空格',
                                                        }
                                                        ],
                                                    })(

                                                        <Input
                                                            placeholder="请输入传动节点ID"
                                                            // placeholder={trendplaceholder}
                                                            type="Number"
                                                            className="model_input"
                                                        />

                                                    )}
                                                </Form.Item>
                                            </div>

                                        </Col>

                                    </Row>

                                )
                            }

                        </Form>

                    </Spin>
                </Modal>
            </>

        );
    }
}
const WrappedRegistrationForm = Form.create({ name: 'register' })(DeviceModel);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setplant: data => {
        dispatch(setplant(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WrappedRegistrationForm));

