import { message } from 'antd';
import { orgtree } from '@/api/orgtree.js';
import { EUequipment, equipmentdetail, eventlistfilter, APIsingledeviceList } from '@/api/device.js';
import { plantList } from '@/api/plant.js';
import moment from 'moment';
import { trendsCharts } from '@/api/trends.js';
import { APIsubscriptionlist, APIdevicesubscriptionlist } from '@/api/subscription.js';
import { APICmdOnlyDevicesList } from '@/api/tools.js';
// import axios from 'axios'
const linechartsoption = {
	// backgroundColor : '#042b53',
	// 标题
	title: {
		text: '图表',
		left: 15,
		align: 'right',
		textStyle: {
			//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}

			fontSize: 14,
			color: '#1F1F1F'
		}
	},
	grid: {
		top: '40px',
		left: '3%',
		right: '4%',
		bottom: '10px',
		containLabel: true
	},
	tooltip: {
		trigger: 'axis',
		backgroundColor: 'rgba(255,255,255,0.8)', //通过设置rgba调节背景颜色与透明度
		color: 'black',
		borderWidth: '1',
		borderColor: 'gray',
		textStyle: {
			color: 'black'
		}
	},

	// dataZoom: [
	//     { type: "inside" },
	//     // { type: 'slider' }
	// ],
	toolbox: {
		show: true,
		feature: {
			dataZoom: {
				// show: false,
				yAxisIndex: 'none',
				icon: {
					zoom:
						'path://M14.0343806,8.04813286 L11.0412567,8.04813286 L11.0412567,11.0412567 L8.04813286,11.0412567 L8.04813286,14.0343806 L11.0412567,14.0343806 L11.0412567,17.0275045 L14.0343806,17.0275045 L14.0343806,14.0343806 L17.0275045,14.0343806 L17.0275045,11.0412567 L14.0343806,11.0412567 L14.0343806,8.04813286 L14.0343806,8.04813286 Z M29,26.0068761 L20.5593907,17.5662668 C23.1336593,13.4686605 22.2196876,8.09123718 18.4361858,5.07422697 C14.652684,2.05721677 9.20672895,2.36315733 5.78494314,5.78494314 C2.36315733,9.20672895 2.05721677,14.652684 5.07422697,18.4361858 C8.09123718,22.2196876 13.4686605,23.1336593 17.5662668,20.5593907 L26.0068761,29 L29,26.0068761 Z M5.05500898,12.5378187 C5.05500898,8.40517699 8.40517699,5.05500898 12.5378187,5.05500898 C16.6704604,5.05500898 20.0206284,8.40517699 20.0206284,12.5378187 C20.0206284,16.6704604 16.6704604,20.0206284 12.5378187,20.0206284 C8.40517699,20.0206284 5.05500898,16.6704604 5.05500898,12.5378187 L5.05500898,12.5378187 Z',
					back: 'path://M25,19 C25,15.21 22.06,12 18.85,12 L11,12 L15,15.8 L13,18 L5,10.5 L13,3 L15,5.2 L11,9 L18.85,9 C23.69,9 28,13.58 28,19 C28,24.42 23.69,29 18.85,29 L7,29 L7,26 L18.85,26 C22.06,26 25,22.79 25,19 Z'
				},
				iconStyle: {
					color: '#1F1F1F',
					borderWidth: 0,
					borderColor: '#1F1F1F'
				},
				emphasis: {
					iconStyle: {
						color: '#3366ff',
						borderColor: '#3366ff'
					}
				},
				title: {
					zoom: '区域缩放',
					back: '区域缩放还原'
				}
			},

			restore: {
				show: false,
				title: '还原',
				emphasis: {
					color: '#3366ff'
				},
				iconStyle: {
					opacity: 0
				}
			}

			//其他功能性按钮查看官网进行增加，包括（显示数据，下载图片，改为柱状图等）
		},
		top: 0,
		right: '30px'
		// tooltip: {
		//     show: true,
		//     formatter: function (param) {
		//         return '<div style="height:20">' + param.title + '</div>'; // 自定义的 DOM 结构
		//     },
		//     backgroundColor: '#ffffff',
		// }
	},
	//x轴数据
	xAxis: {
		type: 'category',
		boundaryGap: false,
		axisLine: {
			lineStyle: {
				color: '#BABABA'
			}
		},
		//  改变x轴字体颜色和大小
		axisLabel: {
			textStyle: {
				color: '#686868',
				fontSize: '11'
			}
		},
		axisTick: {
			//y轴刻度线
			show: false
		},
		data: ['00:30', '01:00', '01:30']
	},
	yAxis: {
		type: 'value',
		axisLine: {
			//y轴
			show: false,
			lineStyle: {
				color: '#BABABA'
			}
		},
		//  改变x轴字体颜色和大小
		axisLabel: {
			textStyle: {
				color: '#686868',
				fontSize: '11'
			}
		},
		axisTick: {
			//y轴刻度线
			show: false
		}
	},
	series: [
		{
			name: '最大',
			type: 'line',
			symbol: 'none',
			// stack: '总量',
			itemStyle: {
				normal: {
					color: '#D98F45',
					lineStyle: {
						color: '#D98F45',
						width: 0.7 // 0.1的线条是非常细的了
					}
				}
			},
			data: ['0', '0', '0']
			// markLine: {
			//     silent: true,
			//     lineStyle: {
			//       normal: {
			//         color: '#333'                  // 这儿设置安全基线颜色
			//       }
			//     },
			//     data: [{
			//         yAxis: 123     //这儿定义基准线的数值为多少
			//     }],
			//     label: {
			//       normal: {
			//         formatter: '安全\n基线'           // 这儿设置安全基线
			//       }
			//     }

			// }
		},
		{
			name: '最小',
			type: 'line',
			symbol: 'none',
			// stack: '总量',
			itemStyle: {
				normal: {
					color: '#5ABFAE',
					lineStyle: {
						color: '#5ABFAE',
						width: 0.7 // 0.1的线条是非常细的了
					}
				}
			},
			// areaStyle:{
			//     color: 'rgba(137,128,25,0.5)'
			// },
			data: ['0', '0', '0']
		},
		{
			name: '平均',
			type: 'line',
			symbol: 'none',
			// stack: '总量',
			itemStyle: {
				normal: {
					color: '#E64997',
					lineStyle: {
						color: '#E64997',
						width: 0.7 // 0.1的线条是非常细的了
					}
				}
			},
			// areaStyle:{
			//     color: 'rgba(137,128,25,0.5)'
			// },
			data: ['0', '0', '0']
		}
	]
};
//组织结构接口数据
function myOrgtree(nodeId, func) {
	let url = ``;
	orgtree(url)
		.then(res => {
			if (res !== undefined) {
				let orgArr = [];
				let resdata = res.data;

				orgArr.push(resdata);
				func(orgArr);
			}
		})
		.catch(err => {
			if (err.status === 400) {
				message.warning(err.data);
			} else {
				// message.error('获取组织树失败');
			}
		});
}

function strdata(str, num) {
	var last = 0;
	var all = str.length;
	var fisrt = str.substring(0, 5);
	// 没有中文括号（
	if (str.lastIndexOf('（') === -1) {
		// 也没有英文括号(
		if (str.lastIndexOf('(') === -1) {
			last = all - 3;
		} else {
			// 有英文括号(，就从英文括号开始截取
			last = all - 3;
		}
	} else {
		last = all - 3;
	}
	// 如果长度大于num个字符才显示省略号
	if (all > num) {
		str = fisrt + " ... " + str.substring(last, all)
	}
	return str;
}
function deviceData(data, num) {
	let isVisiableData = [];
	data.forEach((item, index) => {
		item.assets.forEach((items, index) => {
			items.devicetype = 'noPowerTrain';
			items.deviceGroupId = item.id;
			items.deviceGroupName = item.name;
			items.index = index + 1;
			let assetName = strdata(items.assetName, num);
			items.assetNamestr = assetName;

			if (items.assetType === '传动' || items.assetType === '变频器') {
				if (items.assetSrNo === null || items.assetSrNo === ' ' || items.assetSrNo === '') {
					items.assetSrNo = '--';
				}
				if (items.driveType === null || items.driveType === ' ' || items.driveType === '') {
					items.driveType = '--';
				}
			} else {
				items.assetSrNo = '--';
				items.driveType = '--';
			}

			if (items.subscriptionLevel === null) {
				items.subscriptionLevel = '--';
			}

			if (items.type === 'PowerTrain') {
				// items.type = "传动链"
				items.activatedDateTime = '--';
				items.expiredDateTime = '--';
				items.subscriptionType = '--';
				items.subscriptionStatus = '--';
				items.devices.map((devicesItem) => {
					devicesItem.deviceGroupId = items.deviceGroupId
					devicesItem.deviceGroupName = items.deviceGroupName
				})
			} else {
				items.expiredDateTime = moment.parseZone(items.expiredDateTime).local().format('YYYY-MM-DD HH:mm:ss');
				items.activatedDateTime = moment.parseZone(items.activatedDateTime).local().format('YYYY-MM-DD HH:mm:ss');
			}
			if (items.devices.length <= 0) {
				delete items.devices;
			} else {
				items.devices.forEach((devicelist, index) => {
					devicelist.devicetype = 'PowerTrain';
					devicelist.index = index + 1;
					devicelist.expiredDateTime = moment.parseZone(devicelist.expiredDateTime).local().format('YYYY-MM-DD HH:mm:ss');
					devicelist.activatedDateTime = moment.parseZone(devicelist.activatedDateTime).local().format('YYYY-MM-DD HH:mm:ss');
					if (devicelist.assetType === '变频器' || devicelist.assetType === '传动') {
						if (devicelist.assetSrNo === null || devicelist.assetSrNo === ' ' || devicelist.assetSrNo === '') {
							devicelist.assetSrNo = '--';
						}
						if (devicelist.driveType === null || devicelist.driveType === ' ' || devicelist.driveType === '') {
							devicelist.driveType = '--';
						}

						if (devicelist.deviceSrNo !== ' ' || devicelist.deviceSrNo !== '') {
							devicelist.assetSrNo = devicelist.deviceSrNo;
						}
					} else {
						devicelist.assetSrNo = '--';
						devicelist.driveType = '--';
					}
				});
			}
		});

		if (item.isVisiable === true) {
			isVisiableData.push(item);
		}
	});

	return isVisiableData;
}
//点击获取设备列表接口数据所有的
const deviceList = (val, orgId, num, type, func) => {
	let url = `?organizationId=${orgId}&condition=true&filter=${val}`;
	if (type === 2) {
		url = `?organizationId=${orgId}&condition=true&filter=${val}&isGatewaySn=true`;
	}
	if (type === 0) {
		url = `?organizationId=${orgId}&condition=true&filter=${''}`;
	}
	if (orgId !== undefined && orgId !== '') {
		EUequipment(url)
			.then(res => {
				if (res !== undefined) {
					let resdata = res.data;
					if (resdata.length !== 0) {
						let deviceDatararr = deviceData(resdata, num);
						func(deviceDatararr);
					} else if (resdata.length === 0) {
						let deviceDatararr = deviceData(resdata, num);
						func(deviceDatararr);
					} else {
						let resdataArr = [];
						resdataArr.push(resdata);
						let deviceDatararr = deviceData(resdataArr, num);
						func(deviceDatararr);
						// deviceData(resdataArr,num)
						// func(resdataArr)
					}
				}
			})
			.catch(err => {
				if (err.status === 400) {
					message.warning(err.data);
				} else {
					message.error('获取设备列表失败');
				}
			});
	}
};

//点击获取设备列表接口数据单个的
const singledeviceList = (val, orgId, agId, num, type, func) => {
	let url = `/${agId}?organizationId=${orgId}&condition=true&filter=${encodeURIComponent(val)}`;
	if (type === 2) {
		url = `/${agId}?organizationId=${orgId}&condition=true&filter=${encodeURIComponent(val)}&isGatewaySn=true`;
	}
	if (type === 0) {
		url = `/${agId}?organizationId=${orgId}&condition=true&filter=${encodeURIComponent('')}`;
	}
	if (orgId !== undefined && orgId !== '') {
		APIsingledeviceList(url)
			.then(res => {
				if (res !== undefined) {
					let resdata = res.data;
					if (resdata.length !== 0) {
						deviceData(resdata, num);
						func(resdata);
					} else if (resdata.length === 0) {
						deviceData(resdata, num);
						func(resdata);
					} else {
						let resdataArr = [];
						resdataArr.push(resdata);
						deviceData(resdataArr, num);
						func(resdataArr);
					}
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data);
				} else {
					message.error('获取设备列表失败');
				}
			});
	}
};

//点击获取plant接口数据
const plantListdata = (id, func) => {
	let url = `/${id}`;
	plantList(url)
		.then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				for (let i = 0; i < resdata.length; i++) {
					resdata[i].createdOn = moment.parseZone(resdata[i].createdOn).local().format('YYYY-MM-DD HH:mm:ss');
					resdata[i].addresslocation = resdata[i].longitude + ',' + resdata[i].latitude;
				}
				func(resdata);
				// this.props.setplant(resdata)
			}
		})
		.catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data);
			} else {
				message.error('获取位置列表失败');
			}
		});
};

//获取事件list
function Fneventlist(datatime, pagesize, pageindex, EventType, id, isSubAsset, func) {
	let url = `${id}&isSubAsset=${isSubAsset}&PageSize=${pagesize}&Pageindex=${pageindex}&EventTypes=${EventType}&StartTime=${datatime.startime}&EndTime=${datatime.endtime}`;

	eventlistfilter(url)
		.then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				if (resdata.data !== null && resdata.data.length > 0) {
				}
				resdata.data.forEach(item => {
					if (item.eventCode === '' || item.eventCode === null) {
						item.eventCode = '--';
					}
				});
				func(resdata);
			}
		})
		.catch(err => {
			// message.error('获取事件列表失败')
		});
}

//根据id获取详情
function FndeviceDetail(id, isSubDevice, func) {
	 
	let url = `/${id}${isSubDevice}`;
	equipmentdetail(url)
		.then(res => {
 
			let resdata = res;
			let trendsCode = '';
			if (resdata !== undefined && resdata.data !== null) {
				let conditions = [];
				if (resdata.data.trends !== null) {
					for (let i = 0; i < resdata.data.trends.length; i++) {
						trendsCode += '&Trends=' + resdata.data.trends[i].code;
					}
					resdata.data.conditions.forEach((item, index) => {
						if (item.type === 'reliability') {
							conditions.splice(2, 0, item);
						} else if (item.type === 'availability') {
							conditions.splice(0, 0, item);
						} else if (item.type === 'environment') {
							conditions.splice(1, 0, item);
						} else if (item.type === 'stress') {
							conditions.splice(3, 0, item);
						} else if (item.type === 'overall') {
							conditions.splice(4, 0, item);
						}
					});
					resdata.data.conditions = conditions;
					func(resdata.data, trendsCode);
				} else {
					func(resdata.data);
				}
			}
		})
		.catch(err => {
			func({
				assetId: 0,
				assetType: 2,
				assetName: '',
				details: {},
				devices: [],
				trends: [],
				conditions: [],
				eventlist: { totalCount: 0, data: [] },
				trendsignal: []
			});
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data);
			} else {
				message.error('设备详情接口异常');
			}
		});
}
//获取详情图biao
function FntrendsCharts(datatime, data, id, code, isSubAsset, func) {
	let startime = datatime.startime;
	let endtime = datatime.endtime;
	let trends = code;
	if (startime === undefined || endtime === undefined) {
		message.warning('时间选择问题，请刷新页面！');
		// _self.props.loading('none')
	} else {
		let url = `?AssetId=${id}${trends}&From=${startime}&To=${endtime}${isSubAsset}&isZoom=false`;

		trendsCharts(url)
			.then(res => {
				let resdata = res;
				if (resdata !== undefined) {
					if (resdata.data.trend !== null) {
						for (let i = 0; i < resdata.data.trend.length; i++) {
							var dataTrend = resdata.data.trend[i];
							dataTrend.trendsCharts = JSON.parse(JSON.stringify(linechartsoption));

							let mytimeStamp = [];
							for (let z = 0; z < dataTrend.timestamp.length; z++) {
								mytimeStamp.push(
									moment
										.parseZone(parseInt(dataTrend.timestamp[z] * 1000))
										.local()
										.format('MM/DD HH:mm')
								);
							}

							dataTrend.trendsCharts.series[0].data = dataTrend.max;
							dataTrend.trendsCharts.series[1].data = dataTrend.min;
							dataTrend.trendsCharts.series[2].data = dataTrend.avg;
							dataTrend.trendsCharts.xAxis.data = mytimeStamp;

							if (dataTrend.config.unit === '' || dataTrend.config.unit === null || dataTrend.config.unit === undefined) {
								dataTrend.trendsCharts.title.text = dataTrend.config.label;
							} else {
								dataTrend.trendsCharts.title.text = dataTrend.config.label + '（' + dataTrend.config.unit + '）';
							}
							// dataTrend.trendsCharts.title.text = dataTrend.config.label + ' (' + dataTrend.config.unit + ')';
						}

						data.trendsignal = resdata.data.trend;
						func(data);
					}
				}
			})
			.catch(err => {
				data.trendsignal = [];
				func(data);
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data);
				} else {
					message.error('获取信号时序失败');
				}
			});
	}
}

//获取订阅
const subscriptionlist = (val, id, type, gatewaySn, func) => {
	let url = `${id}/${type}?filter=${val}`;
	if (gatewaySn === 2) {
		url = `${id}/${type}?filter=${val}&isGatewaySn=true`;
	}
	APIsubscriptionlist(url)
		.then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				resdata.forEach((item, index) => {
					item.subscriptions.forEach((items, index) => {
						items.group = item;
						items.index = index + 1;
						if (items.assetType === '变频器' || items.assetType === '传动') {
							if (items.deviceSrNo === null || items.deviceSrNo === ' ' || items.deviceSrNo === '') {
								items.deviceSrNo = '--';
							}
							if (items.deviceDriveType === null || items.deviceDriveType === ' ' || items.deviceDriveType === '') {
								items.deviceDriveType = '--';
							}
						} else {
							items.deviceSrNo = '--';
							items.deviceDriveType = '--';
						}

						if (items.expiredDateTime === null || items.expiredDateTime === '') {
							items.expiredDateTime = '--';
						} else {
							items.expiredDateTime = moment.parseZone(items.expiredDateTime).local().format('YYYY-MM-DD HH:mm:ss');
						}
						if (items.activatedDateTime === null || items.activatedDateTime === '') {
							items.activatedDateTime = '--';
						} else {
							items.activatedDateTime = moment.parseZone(items.activatedDateTime).local().format('YYYY-MM-DD HH:mm:ss');
						}
					});
				});
				func(resdata);
			}
		})
		.catch(err => {
			if (err.status === 400 || err.status === 404) {
				// message.warning(err.data)
			} else {
				message.error('订阅列表获取失败');
			}
		});
};

//获取订阅设备组的
const Devicesubscriptionlist = (val, id, type, gatewaySn, func) => {
	let url = `${id}/${type}?filter=${encodeURIComponent(val)}`;
	if (gatewaySn === 2) {
		url = `${id}/${type}?filter=${encodeURIComponent(val)}&isGatewaySn=true`;
	}
	APIdevicesubscriptionlist(url)
		.then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				resdata.forEach((item, index) => {
					item.subscriptions.forEach((items, index) => {
						items.group = item;
						items.index = index + 1;
						if (items.assetType === '变频器' || items.assetType === '传动') {
							if (items.deviceSrNo === null || items.deviceSrNo === ' ' || items.deviceSrNo === '') {
								items.deviceSrNo = '--';
							}
							if (items.deviceDriveType === null || items.deviceDriveType === ' ' || items.deviceDriveType === '') {
								items.deviceDriveType = '--';
							}
						} else {
							items.deviceSrNo = '--';
							items.deviceDriveType = '--';
						}

						if (items.expiredDateTime === null || items.expiredDateTime === '') {
							items.expiredDateTime = '--';
						} else {
							items.expiredDateTime = moment.parseZone(items.expiredDateTime).local().format('YYYY-MM-DD HH:mm:ss');
						}
						if (items.activatedDateTime === null || items.activatedDateTime === '') {
							items.activatedDateTime = '--';
						} else {
							items.activatedDateTime = moment.parseZone(items.activatedDateTime).local().format('YYYY-MM-DD HH:mm:ss');
						}
					});
				});
				func(resdata);
			}
		})
		.catch(err => {
			if (err.status === 400 || err.status === 404) {
				// message.warning(err.data)
			} else {
				message.destroy()
				message.error('接口获取失败');
			}
		});
};

//获取仅在CMD存在的设备列表
const CmdOnlyDeviceList = func => {
	APICmdOnlyDevicesList()
		.then(res => {
			if (res !== undefined) {
				let resdata = [];
				res.data.devices.forEach((item, index) => {
					resdata.push({
						deviceSrNo: item,
						index: index + 1
					});
				});

				func(resdata);
				// return resdata;
			}
		})
		.catch(err => {
			if (err.status === 400 || err.status === 404) {
				// message.warning(err.data)
			} else {
				message.error('接口获取失败');
			}
		});
};

export { Devicesubscriptionlist, subscriptionlist, singledeviceList, myOrgtree, deviceList, plantListdata, Fneventlist, FndeviceDetail, FntrendsCharts, CmdOnlyDeviceList };
