import React, { Component } from 'react';
import { Modal, Button, Input, message,Form } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import copy from "copy-to-clipboard";  
// import './CopyBoard.css'; 

const { Search } = Input;
class AfreshSecretkeyclass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: false,
            isZn_CH: false
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = () => {
        
        this.props.hidemodel()
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
       
        this.props.form.validateFields((err, fieldsValue) => {
            
            if (!err) {
                this.setState({
                    disabled: true
                })
                if(data.apikey === fieldsValue.apikey.trim()){
                    this.props.submitmodel(1)
                    setTimeout(()=>{
                        this.props.form.resetFields();
                        this.setState({
                            disabled: false
                        })
                    },3000)
                    
                }
                else{
                    message.warning("输入的API Key不一致，请重新输入！")
                    this.setState({
                        disabled: false
                    })
                }
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };
    copySk = (sk) => {
        message.success("复制成功！")
        copy(sk);
    }
    render() {
        const that = this;
        const { showmodel } = this.props;
        const { getFieldDecorator } = that.props.form;

        const modelProps = {
            title: '重新生成Secret Key',
            centered: true,
            width: 504,
            maskClosable: false,
            visible: showmodel.visible,
            onOk: () => { that.hidemodel() },
            onCancel: () => { that.hidemodel() },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel()}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodel)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    disabled={that.state.disabled}
                    text="确定"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div className="Afresh_sk_tip">
                重新生成Secret Key后，当前正在使用的Secret密钥信息将不可恢复。请在输入框中输入完整的API key以确认。
                </div>
                <Form onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>
                    <Form.Item label="">
                        {getFieldDecorator('apikey', {
                            initialValue: showmodel.value,
                            rules: [

                                {
                                    required: true,
                                    message: 'API Key不能为空',
                                },
                        
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            <Input placeholder="请输入完整的API Key" className="model_input" />,
                        )}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}
const AfreshSecretkey = Form.create({ name: 'register' })(AfreshSecretkeyclass);

export default AfreshSecretkey;
