
import React from 'react';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { hot } from 'react-hot-loader/root';
import Router from './router/index';
import store from '@/redux/store';
import './assets/css/app';
import './assets/css/common';
import './assets/css/antstyle';
import './assets/css/abbstyle';
import './assets/css/charts';
import intl from 'react-intl-universal';
import zh_CN from 'antd/es/locale/zh_CN';
import en_US from 'antd/es/locale/en_US';
import 'abb-common-ux-react/styles.css';
import moment from 'moment';
import 'moment/locale/zh-cn';
import * as ABB from 'abb-common-ux-react';

const locales = {
  "en-US": require('./locales/en-US.json'),
  "zh-CN": require('./locales/zh-CN.json'),
};

const customizeRenderEmpty = () => (
	//这里面就是我们自己定义的空状态
	<div className="renderEmpty" >
		<ABB.Icon style={{ marginBottom: '16px' }} name="abb/event" sizeClass='large' />
		<p>暂无数据</p>
	</div>
);

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			antdLang: zh_CN,  // 修改antd  组件的国际化
		}
	}
	UNSAFE_componentWillMount() {
		if(process.env.REACT_APP_PROD === 'prod'){
			console.log = function(){}
		}
		//
		this.loadLocales(localStorage.getItem('lang')|| 'zh-CN'); // 初始化语言
	}
	
	loadLocales(lang) {
		intl.init({
			currentLocale: lang,  // 设置初始语音
			locales,
		}).then(() => {
			this.setState({
				antdLang: lang === 'zh-CN' ? zh_CN : en_US
			});
			moment.locale('zh-cn');
		});
	}
	
	render() {
		return (
			<ConfigProvider
				locale={this.state.antdLang}
				renderEmpty={customizeRenderEmpty}
			>
				<Provider store={store}>
					<Router/>
					
				</Provider>
			</ConfigProvider>
			
		);
	}
}

export default hot(App);
