import React from 'react';
import {

    Input,
    Modal,
    message,
    Row,
    Col,
} from 'antd';
import { getsubscriptionCode } from '@/api/subscription';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { boundsubscription } from '@/api/subscription';
class BoundsubForm extends React.Component {
    state = {
        inputval: '',
        valiededCode: '',
        subdata: {},
        disabled: false
    };

    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.setState({
            inputval: '',
            subdata: {}
        })
    }
    //绑定订阅接口
    resboundSub(deviceData) {

        this.setState({
            disabled: true
        })
        let params = {
            "assetId": deviceData.assetId,
            "subscriptionCode": this.state.subdata.subscriptionCode,
            "isSubAsset": deviceData.isSubAsset,
            "isActivatedNow": false   //当前订阅为体验版或者不小于新增订阅,可以直接请求绑定接口,此时传false,否则需要提示用户是否马上生效
        }
        boundsubscription(params).then(res => {
            message.success("绑定订阅成功")
            this.props.submitmodel()
            this.setState({
                disabled: false,
                inputval: '',
                subdata: {}
            })
        }).catch(err => {
            
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data.errorMessage)
            }
            else {
                message.warning("绑定订阅失败")
            }
            this.setState({
                disabled: false
            })
        })

    }
    submitmodel = (deviceData) => {
       
        let objnull = (JSON.stringify(this.state.subdata) === "{}")
        if (this.state.inputval === '') {
            message.warning("请输入订阅序列号")
        }
        else if (this.state.inputval.replace(/\s*/g,'') !== this.state.valiededCode.trim()  || this.state.valiededCode.trim() === '') {
            message.warning("请先验证订阅序列号")
        }
        else if (objnull !== false) {
            message.warning("请先验证订阅序列号")
        }
        else {
            this.props.hidemodel(false)
            if (this.state.subdata.isNeedNowActive) {
                console.log("传出参数subdata-----", this.state.subdata, "device data====", deviceData)
                this.props.showModelSubConfig(this.state.subdata, deviceData)
            } else {
                console.log("直接绑定-------------",deviceData)
                this.resboundSub(deviceData)
            }
        }
    }

    onSearch = () => {
        console.log("订阅序列号验证")
        let val = this.state.inputval.toUpperCase().replace(/\s*/g, "");
        if (val === '') {
            message.warning("请输入订阅序列号")
        }
        else {
            this.getsubscriptionCode(val.trim())
        }

    }
    getsubscriptionCode = (e) => {
        console.log("订阅序列号验证请求", this.props.showmodelboundsub)
        const { showmodelboundsub } = this.props
        let url = `/${e}?assetId=${showmodelboundsub.assetId}&isSubAsset=${showmodelboundsub.isSubAsset}`

        getsubscriptionCode(url).then(res => {
            let resdata = res.data.data
            console.log("resdata=====",resdata)
            resdata.validDateTime = moment.parseZone(resdata.validDateTime).local().format("YYYY-MM-DD HH:mm:ss")
            this.setState({
                subdata: resdata,
                valiededCode: e,
            })
        }).catch(err => {
           
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data.errorMessage)
                this.setState({
                    subdata: {}
                })
            } else {
                message.error("获取订阅失败")
            }
        })
    }
    onChange = (e) => {
        if (e.target.value === '') {
            this.setState({
                inputval: e.target.value,
                subdata: {}
            })
            return false;
        }
        this.setState({
            inputval: e.target.value
        })
    }
    render() {
        let that = this;
        const subdata = that.state.subdata;
        const { showmodelboundsub } = that.props;
        console.log("showmodelboundsub", showmodelboundsub)
        const modelProps = {
            title: showmodelboundsub.title,
            centered: showmodelboundsub.centered,
            width: showmodelboundsub.width,
            maskClosable: false,
            visible: showmodelboundsub.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelboundsub)}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <div className='sub_box'>
                        <div className='sub_title'>当前设备<span style={{ color: '#36f' }}>{showmodelboundsub.deviceData.assetName}</span>（序列号：{showmodelboundsub.deviceData.assetSrNo}）</div>
                        {
                            showmodelboundsub.subscriptionData.length > 0 ? (
                                <>
                                    <div className='sub_text'>已绑定的订阅信息如下：</div>
                                    {
                                        showmodelboundsub.subscriptionData.map((item, index) => {
                                            return (
                                                <div className='sub_list'>
                                                    <div> {index + 1}. {item.level}-{item.status} </div>
                                                    <div> 订阅结束时间：{item.expiredDateTime === null ? '--' : moment.parseZone(item.expiredDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div>
                                                </div>
                                            )
                                        })
                                    }
                                </>
                            ) : <></>
                        }

                    </div>


                    <Row gutter={16}>
                        <Col span={24}>
                            <Col><div className='sub_title'>绑定新订阅</div></Col>
                            <Col span={24} >
                                订阅序列号
                            </Col>
                            <Col span={24}>
                                <Input
                                    className="model_input"
                                    style={{ height: 32, width: 310, marginRight: 13 }}
                                    value={that.state.inputval}
                                    placeholder='订阅序列号'
                                    min='1'
                                    onChange={that.onChange}
                                />
                                <ABB.Button

                                    onClick={() => { that.onSearch() }}
                                    type="ghost"
                                    sizeClass='small'
                                    text="验证"
                                // icon="abb/search"
                                />
                            </Col>

                        </Col>
                        <Col span={12} className="gutter-row">
                            <Col span={24} >
                                订阅类型
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subdata.subscriptionType} placeholder='' />
                            </Col>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Col span={24} >
                                订阅等级
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subdata.subscriptionLevel} placeholder='' />
                            </Col>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Col span={24} >
                                订阅有效期
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subdata.validDateTime} placeholder='' />
                            </Col>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Col span={24} >
                                订阅适用设备
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subdata.deviceType} placeholder='' />
                            </Col>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Col span={24} >
                                订阅服务期限
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subdata.validPeriodTime} placeholder='' />
                            </Col>
                        </Col>
                    </Row>

                </Modal>
            </>

        );
    }
}

export default BoundsubForm;