import $axios from '../axios/$axios';

export function customersList(data) {
   return $axios({
      url: '/api/v1/user/customers' + data,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function APIcreateCP(data) {
   return $axios({
      url: '/api/v1/organization/cp',
      method: 'post',
      type: 'json',
      data: data
   })
}

export function APIchangeCP(data) {
   return $axios({
      url: '/api/v1/grant/cp',
      method: 'put',
      type: 'json',
      data: data
   })
}

export function organizationedit(data) {
   return $axios({
      url: '/api/v2/organization/edit/org/' + data,
      method: 'post',
      type: 'json',
      data: ''
   })
}

export function organizationKeywordSearch(data) {
   return $axios({
      url: `/newgen/api/portal/organization/search/by-keyword?keyword=${data}`,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function OrganizationRegInfo(organizationId) {
   return $axios({
      url: `/newgen/api/portal/organization/${organizationId}/reginfo`,
      method: 'get',
      type: 'json',
      data: ''
   })
}

export function EditCustomerName(data) {
   return $axios('/newgen/api/portal/organization/update', {
      method: 'post',
      type: 'json',
      data,
   })
}