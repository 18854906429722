import React from 'react';
import {
    Form,
    Input,
    Select,
    Modal,
    message,
    Tooltip
} from 'antd';
import { getuserInfo, createEU, APIQueryIndestryList } from '@/api/user.js';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import guild from '@/utils/guild1'

import { setEditOrgConfig, setIndustryList, setShowIndustriesConfig, setShowIndustryList, setToolEditOrgConfig } from '../../../store/actionCreators';
 
import { APIUpdateOrg } from '../../../../../api/user';
import IndustriesModel from '../../org/components/industriesModel';


const { Option } = Select;

class EditOrgForm extends React.Component {
    state = {
        confirmDirty: false,
        createOrgdata: false,
        autoCompleteResult: [],
        userName: '',
        userPhone: '',
        disabled: false,
        selectChildrendata: []
    };
    componentDidMount() {

        this._queryIndustry()
    }


    _queryIndustry = () => {
        APIQueryIndestryList().then(res => {
            if (res !== undefined) {
                let resData = res.data
                this.props.updateIndestryList(resData.data)

                this.props.updateShowIndestryList([...resData.data, ...[{
                    firstIndustry: "其它",
                    secondIndustry: "其它"
                }]])
            }

        }).catch(err => {

            if (err && err.message) {
                console.log(err.message);
            }

            // message.error('行业信息获取失败')
        })
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };

    handleConfirmBlur = e => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };




    FncreateOrg(createOrgdata) {
        if (this.state.disabled === false) {
            this.setState({ createOrgdata: createOrgdata });
            this.props.form.resetFields();
        }
        else {
            message.warning("当前用户是管理员，不需要创建组织！")
        }
    }

    rescreateOrg = () => {


        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {

            if (!err) {

                let Industries = fieldsValue.industry.split('-')

                if (Industries.length !== 2) {

                    message.error('行业信息格式有误')
                    return
                }

                let url = {

                    "firstIndustry": Industries[0],//一级行业
                    "secondIndustry": Industries[1],//二级行业
 
                };
                const config = this.props.getToolEditOrgConfig
               
                this.setState({
                    disabled: true
                })

                APIUpdateOrg(config.orgInfo.orgId, url).then(res => {
                    this.setState({
                        disabled: false
                    })

                    if (res !== undefined) {
                        message.success('用户组织更新成功')
                        this.onSubmit()
                       
                        // that.setState({
                        //     createOrgdata: false
                        // })
                        // that.updateOrg(1)
                        // that.props.form.resetFields();
                    }
                }).catch(err => {
                    console.log("err========",err)
                    this.setState({
                        disabled: false
                    })
                    if (err.data.code === 400 || err.data.code === 404) {
                        message.warning(err.data.errorMessage)
                    } 
                    else {
                        message.error('组织更新失败！')
                    }

                })
            }
        });

    }
    updateOrg = (val) => {
        this.props.updateOrg(val)
    }
    handleSearch = (e) => {

       
        const industries = [...this.props.getIndustryList]
      
        let data = [];

        if (e === ''){

            data = [...industries]
 
        } else {
            if (industries.length != 0 && e) {

                let str = `\S*${e}\S*`;
                let reg = new RegExp(str, 'i');//不区分大小写
    
                industries.map(item => {
                    if (reg.test(`${item.firstIndustry}-${item.secondIndustry}`)) {
                        data.push(item);
                    }
                })
            }
          
        }
       

        console.log("输入内容=============", industries, e)

        data.push({
            firstIndustry: "其它",
            secondIndustry: "其它"
        })
  
        if (data.length > 0) {
            this.props.updateShowIndestryList(data)

        }
        console.log("输入内容============data=", data)

    }
    onChangeselect = (e) => {

    }


    //  返回标题信息
    _renderHeaderByTitle = (isMust, title) => {
        return <div className='section_item'>
            {isMust === true ? (<div className='section_icon'></div>) : null}
            <div >{title}</div>
        </div>

    }

    _gotoIndustry = () => {

        let config = { ...this.props.getShowIndustriesConfig }
        config.visible = true
        this.props.updateShowIndustriesConfig(config)

    }
    onSubmit = () => {
        this.props.submitmodel()
        this.hidemodel()
    }

    hidemodel = (data) => {
        this.props.form.resetFields()

        let config = { ...this.props.getToolEditOrgConfig }
        config.visible = false
        this.props.updateToolEditOrgInfoConfig(config)
    }


    render() {
        let that = this;
        const { getFieldDecorator } = that.props.form;
        const {  disabled } = that.state;
        const industries = [...this.props.getShowIndustryList]
        const config = this.props.getToolEditOrgConfig
       
        let orgInfo =  this.props.orgInfo
        console.log("orgInfo----------------", orgInfo)
        let userOrgTitle = ''
        
        if (orgInfo.lastModifyRecord !== null && orgInfo.lastModifyRecord !== undefined) {
           
            let userOrgType = orgInfo.lastModifyRecord.userOrgType
            if (userOrgType === 1) {
                userOrgTitle = "ABB"
            } else if (userOrgType === 3) {
                userOrgTitle = "本组织"
            }
        }

       
        
        console.log("getOrginformation-----====",config)
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };

        return (
            <>


                <Modal
                    title="编辑组织信息"
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                    centered
                    width={config.width}
                    maskClosable={false}
                    visible={config.visible}
                    onOk={() => that.hidemodel()}
                    onCancel={() => that.hidemodel()}
                    footer={[
                        <ABB.Button
                            onClick={() => that.hidemodel()}
                            type="discreet-black"
                            sizeClass='small' theme="filled"
                            text="取消"
                            disabled={disabled}
                        />,
                        <ABB.Button
                            onClick={() => that.rescreateOrg()}
                            type="primary-blue"
                            sizeClass='small' theme="filled"
                            disabled={disabled}
                            text="确定"
                        />,

                    ]}

                >
                   {
                        orgInfo.lastModifyRecord !== null &&  orgInfo.lastModifyRecord !== undefined ?
                         <div className='userOrg'>上次修改由<span className='userOrgTitle'>{userOrgTitle}</span> <span className='userTitle'>用户{orgInfo.lastModifyRecord.userName}</span>于{orgInfo.lastModifyRecord.modifyDate}修改</div>
                        : null
                    }
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <div className="from_list_sub" >
                            {
                                this._renderHeaderByTitle(false, '组织名称')
                            }

                            <Form.Item  >
                                {getFieldDecorator('name', {

                                })(
                                    <Input className="model_input"
                                        placeholder={config.orgInfo.orgName} disabled={true}
                                        style={{ width: 290, marginRight: "64px" }}
                                    />
                                )}
                            </Form.Item>
                        </div>

                        <div className="from_list_sub" >
                            <div className='title_name_box'>
                                {
                                    this._renderHeaderByTitle(false, '所属行业')
                                }

                                <Tooltip placement="bottom" title={
                                    <span>全部行业</span>
                                }>
                                    <div className="orgToolTip" onClick={this._gotoIndustry}>
                                        <ABB.Icon name="abb/information-circle-2" sizeClass='small' />
                                    </div>
                                </Tooltip>
                            </div>

                            <Form.Item  >
                                {getFieldDecorator('industry', {
                                     initialValue: config.orgInfo.orgIndustry,
                                    rules: [
                                        { required: true, message: '请选择所属行业' },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        placeholder="请选择所属行业"
                                        suffixIcon={
                                            <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                        }
                                        onChange={this.onChangeselect}
                                        style={{ width: 290, marginRight: "64px" }}
                                      
                                        
                                        defaultActiveFirstOption={false}
                                        showArrow={true}
                                        // filterOption={false}
                                        onSearch={this.handleSearch}
                                        // notFoundContent={null}
 
                                    >
                                        {
                                            industries.map((item, index) =>
                                                <Option value={item.firstIndustry + '-' + item.secondIndustry}>{item.firstIndustry}-{item.secondIndustry}</Option>
                                            )}
                                    </Select>,
                                )}
                            </Form.Item>
                        </div>


                        <div className="from_list_sub" >
                            {
                                this._renderHeaderByTitle(false, '组织管理员')
                            }

                            <Form.Item  >
                                {getFieldDecorator('company', {

                                })(
                                    <Input className="model_input"
                                        placeholder={config.orgInfo.orgAdministrator} disabled={true}
                                        style={{ width: 290, marginRight: "64px" }} />
                                )}
                            </Form.Item>


                        </div>
                        
                        <div className="model_list_sub" >
                            <div className='from_list_sub'>
                                {
                                    this._renderHeaderByTitle(false, '组织类型')
                                }

                                <Form.Item  >
                                    {getFieldDecorator('admin', {

                                    })(<Input className="model_input"
                                        style={{ width: 290, marginRight: "64px" }} disabled={true}
                                        placeholder={ config.orgInfo.orgType || '' } />)}
                                </Form.Item>
                            </div>

                        </div>
                        {/* <Form.Item label="公司地址">
                            {getFieldDecorator('city', {
                                initialValue: ['zhejiang', 'hangzhou', 'xihu'],
                                rules: [
                                    { type: 'array', required: true, message: 'Please select your habitual residence!' },
                                ],
                            })(<Cascader options={citytree} />)}
                        </Form.Item> */}



                    </Form>
                </Modal>

 
                <IndustriesModel />

            </>

        );
    }
}

const WrappedEditOrgForm = Form.create({ name: 'register' })(EditOrgForm);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({

    setUserInfo: data => {
        dispatch(setUserInfo(data))
    },
    updateIndestryList: data => {
        dispatch(setIndustryList(data))
    },
    updateShowIndestryList: data => {
        dispatch(setShowIndustryList(data))
    },
    updateShowIndustriesConfig: data => {
        dispatch(setShowIndustriesConfig(data))
    },
    updateToolEditOrgInfoConfig: data => {
        dispatch(setToolEditOrgConfig(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WrappedEditOrgForm));
