import React, { Component } from 'react';
import { Modal, Button, Input, message, Table, DatePicker } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import moment from "moment";
import { userCheckDevice, deviceDetail, checkAllgen, eventDetaildevice } from '@/redux/actions/device';
import { backuplist, backupdownload } from "@/api/backup";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NoData from '@/components/NoData'

const div_nowrap = {
    display: 'inline'
}
const { Search } = Input;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD HH:mm:ss';

class BackupParameters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startime: moment().subtract(14, 'day'),
            endtime: moment(new Date(), dateFormat),
            getBackupParameters: [],
            columns: [

                {
                    title: '参数列表',
                    dataIndex: 'timestamp',
                    key: 1,
                    render: (text, record) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <ABB.Icon name="abb/folder" style={{ marginRight: 16 }} sizeClass='medium' />
                            <span>{moment.parseZone(record.timestamp).local().format(dateFormat)}</span>
                        </div>
                    )
                },
                {
                    title: '操作',
                    dataIndex: 'name',
                    key: 2,
                    width: 70,
                    render: (text, record) => (
                        <div style={div_nowrap} onClick={() => this.Fndownload(record.parameterSetId)}>

                            <ABB.Icon name="abb/download" sizeClass='small' />
                        </div>
                    )
                },

            ],
            feature: ''
        };

    }
    Fndownload = async (id) => {
        const hide = message.loading('正在下载', 0);
        let { _deviceDetail, getAssetIsPowertrain } = this.props;
        let isSubAsset = ``;
        let url;
        if (getAssetIsPowertrain === true) {
            isSubAsset = `?isSubAsset=true`;
        }
        else {
            isSubAsset = ``
        }
        url = `/${_deviceDetail.assetId}/${id}${isSubAsset}`;


        backupdownload(url).then(res => {
            let resdata = res.data.data;

            const w = window.open('about:blank');
            w.location.href = resdata.tempDownloadUrl
            // let blob = resdata.tempDownloadUrl;

            // this.download(blob, resdata.filename);
            hide();
        }).catch(err => {
            hide();
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                // message.error('下载失败')
            }
        })
    }

    // base64转Blob
    dataURLtoBlob = (dataurl) => {
        // console.log("dataurl",dataurl)
        let aaa = "data:text/plain;base64," + dataurl
        var arr = aaa.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    download = (blobUrl, filename) => {
        // let blob = this.dataURLtoBlob(blobUrl);
        // let url = window.URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.style.display = 'none';
        // a.download = filename;
        // a.href = blobUrl;
        // a.click();
        // document.body.removeChild(a);
    }
    componentDidMount() {
        let { startime, endtime } = this.state;
        let { getsubscriptioncof } = this.props;
        let today = new Date();
        let _today = moment(today).format("YYYY-MM-DD")
        let _todayminute = moment(today).format("HH:mm:ss")
        let _startime = moment(startime._d).format("YYYY-MM-DD")
        let _endtime = moment(endtime._d).format("YYYY-MM-DD")
        if (_today === _endtime) {
            _endtime = _endtime + " " + _todayminute
        }
        else {
            _endtime = _endtime + " 23:59:59"
        }
        let currentUTCTimeString = moment(_endtime).format("YYYY-MM-DD HH:mm:ss");
        let dateUTCTimeString = moment(_startime).format("YYYY-MM-DD HH:mm:ss");
        let datetime = {
            startime: dateUTCTimeString,
            endtime: currentUTCTimeString
        }

        let feature = {}
        getsubscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 13) {
                    feature.backupdownload = items.limitValue
                }
                if (items.featureCode === 16) {
                    feature.backuptime = items.limitValue
                }
            })

        })
        this.setState({
            feature: feature
        })
        if (feature.backupdownload === true) {
            this.setState({
                columns: [

                    {
                        title: '参数列表',
                        dataIndex: 'timestamp',
                        key: 1,
                        render: (text, record) => (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ABB.Icon name="abb/folder" style={{ marginRight: 16 }} sizeClass='medium' />
                                <span>{moment.parseZone(record.timestamp).local().format(dateFormat)}</span>
                            </div>
                        )
                    },
                    {
                        title: '操作',
                        dataIndex: 'name',
                        key: 2,
                        width: 70,
                        render: (text, record) => (
                            <div style={div_nowrap} onClick={() => this.Fndownload(record.parameterSetId)}>
                                <ABB.Icon name="abb/download" sizeClass='small' />
                            </div>
                        )
                    },

                ],
            })
        }
        else {
            this.setState({
                columns: [

                    {
                        title: '参数列表',
                        dataIndex: 'timestamp',
                        key: 1,
                        render: (text, record) => (
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <ABB.Icon name="abb/folder" style={{ marginRight: 16 }} sizeClass='medium' />
                                <span>{moment.parseZone(record.timestamp).local().format(dateFormat)}</span>
                            </div>
                        )
                    },
                    // {
                    //     title: '操作',
                    //     dataIndex: 'name',
                    //     key: 2,
                    //     width: 70,
                    //     render: (text, record) => (
                    //         <div style={div_nowrap} onClick={() => this.Fndownload(record.parameterSetId)}>
                    //             <ABB.Icon name="abb/download" sizeClass='small' />
                    //         </div>
                    //     )
                    // },

                ],
            })
        }
        this.getbackuplist(datetime);
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    getbackuplist = (datatime) => {
        //接口数据
        let { _deviceDetail, getAssetIsPowertrain } = this.props;
        let url;
        let isSubAsset = '';
        if (getAssetIsPowertrain === true) {
            isSubAsset = `&isSubAsset=true`;
        }
        else {
            isSubAsset = ``
        }
        url = `/${_deviceDetail.assetId}?startDatetime=${datatime.startime}&endDatetime=${datatime.endtime}${isSubAsset}`
        backuplist(url).then(res => {
            console.log("res.data-------------", res.data.data)
            if (res !== undefined) {
                let resdata = res.data.data;
                this.setState({
                    getBackupParameters: resdata 
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("getbackuplist--backuplist--接口异常")
                message.error('接口异常')
            }
        })

    }
    onChangeTime = (dates, dateStrings) => {
        let that = this;

        if (dates[0] === undefined || dates[1] === undefined) {
            that.setState({
                startime: '',
                endtime: ''
            }, () => {
                this.getbackuplist({
                    startime: '',
                    endtime: ''
                })
            })

        }
        else {
            let today = new Date();
            let _today = moment(today).format("YYYY-MM-DD")
            let _todayminute = moment(today).format("HH:mm:ss")
            let _startime = moment(dateStrings[0]).format("YYYY-MM-DD")
            let _endtime = moment(dateStrings[1]).format("YYYY-MM-DD")
            if (_today === _endtime) {
                _endtime = _endtime + " " + _todayminute
            }
            else {
                _endtime = _endtime + " 23:59:59"
            }
            let currentUTCTimeString = moment(_endtime).format("YYYY-MM-DD HH:mm:ss")
            let dateUTCTimeString = moment(_startime).format("YYYY-MM-DD HH:mm:ss");

            this.setState({
                startime: dateUTCTimeString,
                endtime: currentUTCTimeString,
            }, () => {
                this.getbackuplist({
                    startime: dateUTCTimeString,
                    endtime: currentUTCTimeString
                })
            })
        }
    }
    render() {
        const that = this;
        let { startime, endtime, feature } = that.state;
        const { _deviceDetail } = that.props;
        console.log('moment().endOf', _deviceDetail)
        const disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(this.state.feature.backuptime, 'day') || (current && _deviceDetail.createdOn && current < moment(_deviceDetail.createdOn).add(-1, 'd').endOf('day'));
        return (
            <>
                {
                    feature != "" ? (
                        <div>
                            <div className="backup_top_box">
                                <div>

                                    <span style={{ marginRight: 16 }}>显示范围</span>
                                    <RangePicker
                                        disabledDate={disabledDate}
                                        className="model_input"
                                        style={{ margin: '0 10px 0 0' }}

                                        suffixIcon={
                                            <ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
                                        }
                                        separator='—'
                                        defaultValue={[startime, endtime]}

                                        format={dateFormat}
                                        allowClear={false}
                                        format="YYYY/MM/DD"
                                        onChange={this.onChangeTime}
                                    // onOk={this.onOk}
                                    />
                                    <span>最长{feature.backuptime}天</span>
                                </div>


                                <div></div>
                            </div>
                            <div style={{ position: 'relative', minHeight: '60vh' }}>
                                {
                                    that.state.getBackupParameters.length > 0 ? (
                                        <Table
                                            // showHeader={false}
                                            size='small'

                                            // scroll={{ y: 200 }}
                                            className='org_right'
                                            ellipsis={true}
                                            columns={that.state.columns}
                                            dataSource={that.state.getBackupParameters}
                                            pagination={{
                                                pageSize: 10
                                            }}
                                            rowKey={(record, item) => record.parameterSetId}
                                        />
                                    ) : <NoData />
                                }
                            </div>

                        </div>


                    ) : ''
                }
            </>

        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(BackupParameters));
// export default BackupParameters;
