import React, { Component } from 'react';
import { Modal, Button, Input, Checkbox, Row, Col, Select, message } from 'antd';
import { setmotor } from '@/api/device';
import { connect } from 'react-redux';
import { setplant } from '@/redux/actions/plant';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const Option = Select;
class EventModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            motorlist: [],
            checkoutlist: [],
            disabled: false
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    //导入电机设备
    resSetmotor = (data) => {

        let ssarr = []
        this.state.checkoutlist.forEach((item, index) => {
            ssarr.push({
                id: item.id,
                name: item.assetName
            })
        })

        if (ssarr.length === 0) {
            message.warning("电机设备不能为空")
            return false;
        }
        else {
            this.setState({
                disabled: true
            })
            let url = {
                "assetGroupId": data.deviceGroupId,
                "newAsset": ssarr
            }
            setmotor(this.props.getSubid.orgId, url).then(res => {
                message.success("导入电机设备成功")
                this.props.submitmodel()
                this.setState({
                    disabled: false
                })
            }).catch(err => {
                if (err.status === 400 || err.status === 404) {
                    message.warning(err.data)
                }
                else {
                    message.warning("导入电机设备失败")
                }
                this.setState({
                    disabled: false
                })
            })
        }

    }
    // 
    hidemodel = (data) => {
        this.props.hidemodel(data)
    }
    submitmodel = (data) => {
        this.resSetmotor(data)
    }
    onChange = (e) => {
        this.setState({
            inputval: e,
            checkoutlist: []
        })
    }
    onChangecheckout = (e) => {
        this.setState({
            checkoutlist: e
        })
    }
    render() {
        const that = this;
        const { showmodelmotor, getplant } = this.props;
        const modelProps = {
            title: showmodelmotor.title,
            centered: showmodelmotor.centered,
            width: showmodelmotor.width,
            maskClosable: false,
            visible: showmodelmotor.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelmotor)}
                    type="primary-blue"
                    disabled={that.state.disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                {/* <div className="model_list">
                    <div className="model_title">设备名称</div>
                    <Input className="model_input" disabled={true} placeholder={showmodelmotor.value}/>
                </div> */}

                {
                    showmodelmotor.motorlist.length > 0 ? (
                        <>
                            <div className="model_title">选择设备</div>
                            <Checkbox.Group style={{ width: '100%' }}
                                value={this.state.checkoutlist}
                                onChange={that.onChangecheckout}
                                className="motor_box org_right">
                                <Row>
                                    {
                                        showmodelmotor.motorlist.map((item, index) => {
                                            return (
                                                <Col span={24}>
                                                    <div className="box_flex motor_checkout">
                                                        <div>
                                                            <Checkbox value={item}></Checkbox>
                                                        </div>
                                                        <div>

                                                            SS设备名：{item.assetName}
                                                            <br />
                                                            传感器ID：{item.sensorId}

                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Checkbox.Group>
                        </>
                    ) : '没有可供添加的电机设备，请联系管理员导入'
                }

            </Modal>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setplant: data => {
        dispatch(setplant(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EventModel));
// export default EventModel;
