import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setCBMdata, setCBMtype } from '@/redux/actions/cbm'
import { withRouter } from 'react-router-dom';
import { userCheckDevice, deviceDetail, setActiveTab } from '@/redux/actions/device';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import Chartsdetail from "./chartsdetail/index";
import TrendChartsdetail from "./trendcharts/index"
import { Tabs } from 'antd';
import Downloadeventlogger from "./dowloadeventlogger/index"
import Diagnosticparameters from "./diagnosticparameters/index"
const { TabPane } = Tabs;
const dateFormat = 'YYYY/MM/DD';

class EventLoggerTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: 0,
            seriesData: {},
            isUpdateform: false,
            assetId: this.props._deviceDetail.assetId,
            CBMtype: this.props.getCBMtype,
            // cbmdata: props.cbmdata,
            lifedata: {
                visible: false,
                cbmunitdata: {
                    startDate: '',
                    year: '',
                    month: ''
                }
            },
            cbmunitdata: {},
            eventloggerCharts: false,
            eventloggerExport: false
        };
    }
    callback(newIndex) {
        this.setState({
            activeTab: newIndex
        }, () => {
            this.props.setActiveTab(newIndex)
        });

    }
    isShowtabtype = () => {
        let { eventloggerCharts, eventloggerExport } = this.state;
        let { getEventDetail } = this.props;
        if (getEventDetail.isHasEventLogger === false && getEventDetail.isHasTrendChart === false) {
            //都不显示v
            return 0
        } else if (getEventDetail.isHasEventLogger === false && getEventDetail.isHasTrendChart === true) {
            //仅显示趋势曲线
            return 1
        } else if (getEventDetail.isHasEventLogger === true && getEventDetail.isHasTrendChart === true) {
            //显示趋势曲线 eventlogger 下载列表
            if (eventloggerCharts === true && eventloggerExport === true) {
                return 4;
            }
            //显示趋势曲线 eventlogger
            if (eventloggerCharts === true) {
                return 2
            }
            //显示趋势曲线  下载列表

            if (eventloggerExport === true) {
                return 3
            }
        } else if (getEventDetail.isHasEventLogger === true && getEventDetail.isHasTrendChart === false) {
            if (eventloggerCharts === true && eventloggerExport === true) {
                //显示eventlogger  下载列表
                return 5
            }
            //仅显示eventlogger
            if (eventloggerCharts === true) {
                return 6
            }
            //仅显示下载列表
            if (eventloggerExport === true) {
                return 7
            }
        }
    }
    isShowtab = () => {
        let { eventloggerCharts, eventloggerExport } = this.state;
        let { getEventDetail } = this.props;
        let type = this.isShowtabtype();
        // if (type === 0) {
        //     return (
        //         <div style={{ height: '64vh', backgroundColor: '#fff', textAlign: 'center', lineHeight: '60vh' }}>暂无权限</div>
        //     )
        // }
        // else if (type === 1) {
        //     return (
        //         <ABB.TabControl
        //             type="primary"
        //             activeTab={this.state.activeTab}
        //             className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
        //             onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
        //             padLeft={false}
        //         >
        //             <ABB.TabItem title="诊断参数">
        //                 <Diagnosticparameters/>
        //             </ABB.TabItem>
        //             <ABB.TabItem title="趋势曲线">
        //                 <TrendChartsdetail />
        //             </ABB.TabItem>
        //         </ABB.TabControl>
        //     )
        // }
        // else if (type === 4) {
        //     return (
        //         <ABB.TabControl
        //             type="primary"
        //             activeTab={this.state.activeTab}
        //             className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
        //             onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
        //             padLeft={false}
        //         >
        //             <ABB.TabItem title="趋势曲线">
        //                 <TrendChartsdetail />
        //             </ABB.TabItem>
        //             <ABB.TabItem title="事件记录曲线">
        //                 <Chartsdetail />
        //             </ABB.TabItem>
        //             <ABB.TabItem title="下载事件记录">
        //                 <Downloadeventlogger />
        //             </ABB.TabItem>

        //         </ABB.TabControl>
        //     )
        // }
        // else if (type === 2) {
        //     return (
        //         <ABB.TabControl
        //             type="primary"
        //             activeTab={this.state.activeTab}
        //             className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
        //             onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
        //             padLeft={false}
        //         >
        //             <ABB.TabItem title="趋势曲线">
        //                 <TrendChartsdetail />
        //             </ABB.TabItem>
        //             <ABB.TabItem title="事件记录曲线">
        //                 <Chartsdetail />
        //             </ABB.TabItem>

        //         </ABB.TabControl>
        //     )
        // }
        // else if (type === 3) {
        //     return (
        //         <ABB.TabControl
        //             type="primary"
        //             activeTab={this.state.activeTab}
        //             className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
        //             onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
        //             padLeft={false}
        //         >
        //             <ABB.TabItem title="趋势曲线">
        //                 <TrendChartsdetail />
        //             </ABB.TabItem>
        //             <ABB.TabItem title="下载事件记录">
        //                 <Downloadeventlogger />
        //             </ABB.TabItem>

        //         </ABB.TabControl>
        //     )
        // }
        // else if (type === 5) {
        //     return (
        //         <ABB.TabControl
        //             type="primary"
        //             activeTab={this.state.activeTab}
        //             className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
        //             onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
        //             padLeft={false}
        //         >
        //             <ABB.TabItem title="事件记录曲线">
        //                 <Chartsdetail />
        //             </ABB.TabItem>
        //             <ABB.TabItem title="下载事件记录">
        //                 <Downloadeventlogger />
        //             </ABB.TabItem>

        //         </ABB.TabControl>
        //     )
        // }
        // else if (type === 6) {
        //     return (
        //         <ABB.TabControl
        //             type="primary"
        //             activeTab={this.state.activeTab}
        //             className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
        //             onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
        //             padLeft={false}
        //         >
        //             <ABB.TabItem title="事件记录曲线">
        //                 <Chartsdetail />
        //             </ABB.TabItem>

        //         </ABB.TabControl>
        //     )
        // }
        // else if (type === 7) {
        //     return (
        //         <ABB.TabControl
        //             type="primary"
        //             activeTab={this.state.activeTab}
        //             className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
        //             onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
        //             padLeft={false}
        //         >
        //             <ABB.TabItem title="下载事件记录">
        //                 <Downloadeventlogger />
        //             </ABB.TabItem>

        //         </ABB.TabControl>
        //     )
        // }
        return (
            <Tabs defaultActiveKey="1" type="card" size={"small"}>
                {
                    getEventDetail.isHasAttachParameter ? (
                        <TabPane tab="诊断参数" key="1">
                            <Diagnosticparameters />
                        </TabPane>
                    ) : ''
                }
                {
                    getEventDetail.isHasTrendChart ? (
                        <TabPane tab="趋势曲线" key="2">
                            <TrendChartsdetail />
                        </TabPane>
                    ) : ''
                }
                {
                    getEventDetail.isHasEventLogger ? (
                        <TabPane tab="事件记录曲线" key="3">
                            <Chartsdetail />
                        </TabPane>
                    ) : ''
                }
                {
                    getEventDetail.isHasEventLogger ? (
                        <TabPane tab="下载事件记录" key="4">
                            <Downloadeventlogger />
                        </TabPane>
                    ) : ''
                }


            </Tabs>
        )
    }
    componentDidMount() {
        let { getsubscriptioncof } = this.props;
        getsubscriptioncof.map(item => {
            item.config.map(items => {

                if (items.featureCode === 10) {
                    this.setState({
                        eventloggerCharts: items.limitValue
                    })

                }
                if (items.featureCode === 11) {
                    this.setState({
                        eventloggerExport: items.limitValue
                    })
                }
            })

        })
    }

    render() {
        return (
            <div className="lifebox" style={{ marginTop: '16px' }}>
                {/* <div className="lifestatus_title">
                事件记录日志
                    
                </div> */}
                {
                    this.isShowtab()
                }
                {/* <ABB.TabControl
                    type="primary"
                    activeTab={this.state.activeTab}
                    className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
                    onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
                    padLeft={false}
                >
                    
                    <ABB.TabItem title="事件记录曲线">
                        <Chartsdetail/>
                    </ABB.TabItem>
                    <ABB.TabItem title="下载事件记录">
                       <Downloadeventlogger/>
                    </ABB.TabItem>
                    
                </ABB.TabControl> */}

            </div>
        )
    }
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setActiveTab: data => {
        dispatch(setActiveTab(data))
    },
    setCBMdata: data => {
        dispatch(setCBMdata(data))
    },
    setCBMtype: data => {
        dispatch(setCBMtype(data))
    },
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EventLoggerTab));

// export default Predictive;