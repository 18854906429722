import React, { Component } from 'react';
import { Modal, Button, Input, message, Select, Form } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { editdevicepowertrain, editdevice, getApplyType } from '@/api/device.js';
import AddAddress from './address'
class DeviceEditModelForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            groupId: '',
            deviceGroupName: undefined,
            disabled: false,
            isZn_CH: false,
            applyType: [],
            isShowApplyType: false,
            showDeviceEditModel: {
                movegrouplist: [],
                editInfo: {
                    assetLocation: {
                        address: "",
                        latitude: "0",
                        longitude: "0",
                        modifiedBy: "ABB系统管理员",
                        source: 1
                    }
                },
                applyType: []
            },
            applicationNameSelect: '其他',
            applicationName: '',
            assetId: '',
            submitAddress: {
                //经度
                "longitude": "0",
                //纬度
                "latitude": "0",
                //地址
                "address": "",
                //定位数据来源 1 历史数据 2 运营商API 3 高德API
                "source": 1,
            }
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    componentDidMount() {
        this.getTypeList();
        this.props.onRef(this)
    }
    getTypeList = () => {
        getApplyType('').then(res => {
            if (res !== undefined) {
                this.setState({
                    applyType: res.data.data
                })
            }
        })
    }
    getDeviceInfo = (info) => {
        console.log("编辑设备获取详情info----", info)

        let applicationName = info.editInfo.applicationName;
        // let isShowApplyType = false
        let applicationNameSelect = ''

        this.state.applyType.map((item, index) => {
            if (applicationName === item.name) {
                applicationNameSelect = applicationName
            }
        }
        )

        if (applicationNameSelect === '' || applicationNameSelect === '其他') {
            this.setState({
                applicationNameSelect: '其他',
                applicationName: applicationName,
                isShowApplyType: true,
                showDeviceEditModel: info,
                assetId: info.assetId,
                submitAddress: info.editInfo.assetLocation

            })
        } else {
            this.setState({
                applicationNameSelect: applicationNameSelect,
                applicationName: '',
                isShowApplyType: false,
                showDeviceEditModel: info,
                assetId: info.assetId,
                submitAddress: info.editInfo.assetLocation
            })
        }


    }
    hidemodel = (data) => {
        // this.props.hidemodel(data)
        this.setState({
            showDeviceEditModel: { ...this.state.showDeviceEditModel, ...{ visible: false, } }
        })
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.editAssetName(fieldsValue)
            }
        });
    }
    editAssetName = (val) => {
        let { showDeviceEditModel, assetId } = this.state;
        console.log(showDeviceEditModel, "editAssetName----------", val)

        // if (showDeviceEditModel.value === val.deviceName && showDeviceEditModel.deviceGroupName === val.deviceGroupName) {
        //     // message.warning("没有修改值！");
        //     this.setState({
        //         showDeviceEditModel: {
        //             visible: false,
        //             title: '',
        //             value: '',
        //             width: 354,
        //             centered: true,
        //             deviceGroupName: '',
        //             movegrouplist: [],

        //             editInfo: {},
        //             applyType: []
        //         },

        //     })
        //     return false;
        // }

        // else {

        this.setState({
            disabled: true
        })
        if (this.state.showDeviceEditModel.type === "PowerTrain") {
            let url = {
                "assetId": assetId,
                "assetName": val.deviceName.trim(),
                "assetGroupId": val.deviceGroupName,
                "plantId": val.deviceAddress,
            }
            console.log(" this.state.showDeviceEditModel.type========")
            editdevicepowertrain(url).then(res => {
                let resdata = res.data;
                if (resdata.code === 200) {
                    message.success('修改设备成功')
                    this.setState({
                        disabled: false,
                        showDeviceEditModel: {
                            ...this.state.showDeviceEditModel, ...{
                                visible: false,
                            }
                        }
                    })
                    this.props.submitmodel();
                    this.props.form.resetFields();
                }
                else {
                    message.warning("编辑失败")
                }
            }).catch(err => {

            })
        }
        else {

            console.log("showDeviceEditModel===========", this.state.showDeviceEditModel)
            let url = {}
            if (showDeviceEditModel.editInfo.gatewayType === 1 && showDeviceEditModel.editInfo.serialNumber.indexOf("_") !== -1) {
                let serialNumber = val.netacode.trim() + "_" + val.nodeid.trim();
                url = {
                    "assetId": assetId,
                    "assetName": val.deviceName.trim(),
                    "applicationName": val.applayType === "其他" ? val.applayTypetext : val.applayType,
                    "assetGroupId": val.deviceGroupName,
                    "plantId": val.deviceAddress,
                    "assetType": showDeviceEditModel.editInfo.assetType,
                    "isSubAsset": showDeviceEditModel.editInfo.isSubAsset,
                    "gatewayType": showDeviceEditModel.editInfo.gatewayType,
                    "serialNumber": serialNumber,
                    assetLocation: this.state.showDeviceEditModel.editInfo.assetLocation

                }
            }
            else {
                url = {
                    "assetId": assetId,
                    "assetName": val.deviceName.trim(),
                    "applicationName": val.applayType === "其他" ? val.applayTypetext : val.applayType,
                    "assetGroupId": val.deviceGroupName,
                    "plantId": val.deviceAddress,
                    "assetType": showDeviceEditModel.editInfo.assetType,
                    "isSubAsset": showDeviceEditModel.editInfo.isSubAsset,
                    "serialNumber": showDeviceEditModel.editInfo.serialNumber,
                    "gatewayType": showDeviceEditModel.editInfo.gatewayType,
                    assetLocation: this.state.showDeviceEditModel.editInfo.assetLocation

                }
            }
            console.log('url---------------------', url)
            editdevice(url).then(res => {
                if (res.data.code === 200) {
                    message.success('修改设备成功')
                    this.setState({
                        disabled: false,
                        showDeviceEditModel: {
                            ...this.state.showDeviceEditModel, ...{
                                visible: false,
                            }
                        }

             
                    })
                    this.props.submitmodel();
                    this.props.form.resetFields();
                }
                else {
                    message.warning('修改设备名称失败')
                }
            }).catch(err => {
                if (err.status === 400 || err.status === 404) {
                    message.warning(err.data)
                }
                else {
                    message.error('修改设备名称失败')
                }
                this.setState({
                    disabled: false
                })
            })
        }

        // }

    }
    groupChange = (e) => {
        this.setState({
            groupId: e,
            deviceGroupName: e
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
            }
        });
    };
    onChangeApplytype = (e) => {
        let that = this;
        setTimeout(() => {
            if (e === '其他') {
                that.setState({
                    isShowApplyType: true
                })
            }
            else {
                that.setState({
                    isShowApplyType: false
                })
            }
        }, 0)
    }
    _renderLocationView = (showDeviceEditModel, editInfo) => {

        if (editInfo.assetType !== 1 && editInfo.isSubAsset !== true) {

            return <Form.Item label="位置">

                <>
                    {
                        editInfo.assetLocation.address === '' ? <div className='editdevice_address_content'>
                            <div className="sub_bottom_content">
                                <ABB.Icon style={{ cursor: "auto", marginRight: '5px' }} name="abb/warning-triangle" sizeClass='small' color='#F03040' />
                                <div className='sub_bottom_title'>尚未获取到位置信息，请手动修改</div>
                            </div>

                            <AddAddress assetLocation={editInfo.assetLocation} editInfo={editInfo} onSubmit={(address) => {

                                let showDeviceEditModel = { ...this.state.showDeviceEditModel }
                                showDeviceEditModel.editInfo.assetLocation = address
                                //更新提交数据
                                console.log('提交位置信息=====', showDeviceEditModel)
                                this.setState({
                                    showDeviceEditModel
                                })


                            }}></AddAddress>

                        </div> : <div className='editdevice_address_content'>
                            <div className="sub_bottom_content">

                                <div className='sub_bottom_title'>{editInfo.assetLocation.address}</div>
                            </div>

                            <AddAddress assetLocation={editInfo.assetLocation} editInfo={editInfo} onSubmit={(address) => {

                                let showDeviceEditModel = { ...this.state.showDeviceEditModel }
                                showDeviceEditModel.editInfo.assetLocation = address
                                //更新提交数据
                                console.log('提交位置信息=====', showDeviceEditModel)
                                this.setState({
                                    showDeviceEditModel
                                })


                            }}></AddAddress>
                        </div>
                    }
                </>



            </Form.Item>
        }

        return null


    }
    render() {
        const that = this;
        // const { disabled } = this.props;
        const { getFieldDecorator } = that.props.form;
        const { disabled, applicationNameSelect, applicationName, applyType, isShowApplyType, showDeviceEditModel } = that.state;
        const { editInfo } = showDeviceEditModel;

        console.log('showDeviceEditModel============', showDeviceEditModel)

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showDeviceEditModel.title,
            centered: showDeviceEditModel.centered,
            width: showDeviceEditModel.width,
            maskClosable: false,
            visible: showDeviceEditModel.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    disabled={disabled}
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>
                    <Form.Item label="设备名称">
                        {getFieldDecorator('deviceName', {
                            initialValue: showDeviceEditModel.value,
                            rules: [

                                {
                                    required: true,
                                    message: '设备名称不能为空',
                                },
                                {
                                    max: 20,
                                    message: '设备名称长度不能大于20',
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            <Input placeholder="请输入设备名称" className="model_input" />,
                        )}
                    </Form.Item>
                    {
                        editInfo.isSubAsset !== true ? (
                            <Form.Item label="移动到设备组">
                                {getFieldDecorator('deviceGroupName', {
                                    initialValue: showDeviceEditModel.deviceGroupName,
                                    rules: [{ required: true, message: '请选择设备组' }],
                                })(
                                    <Select

                                        suffixIcon={
                                            <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                        }

                                        style={{ 'width': '100%' }}

                                    >
                                        {
                                            showDeviceEditModel.movegrouplist.map(item => {

                                                return (
                                                    <Select.Option value={item.id}>{item.name}</Select.Option>
                                                )
                                            })
                                        }


                                    </Select>
                                )}
                            </Form.Item>
                        ) : ''
                    }

                    {
                        editInfo.assetType !== 1 ? (
                            <>
                                <Form.Item label="应用类型">
                                    {getFieldDecorator('applayType', {
                                        initialValue: applicationNameSelect,
                                        rules: [{ required: true, message: '请选择应用类型' }],
                                    })(
                                        <Select placeholder="请选择应用类型"
                                            onChange={(e) => this.onChangeApplytype(e)}
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }>
                                            {
                                                applyType.map((item, index) => {
                                                    return (
                                                        <Select.Option key={index} value={item.name}>{item.name}</Select.Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                                {
                                    isShowApplyType ? (
                                        <Form.Item>
                                            {getFieldDecorator('applayTypetext', {
                                                initialValue: applicationName,
                                                rules: [

                                                    {
                                                        required: false,
                                                        message: '',
                                                    },
                                                    {
                                                        max: 30,
                                                        message: '设备名称长度不能大于30',
                                                    }
                                                ],
                                            })(
                                                <Input placeholder="请输入应用类型" className="model_input" />,
                                            )}
                                        </Form.Item>
                                    ) : ''
                                }

                            </>
                        ) : ''
                    }

                    {
                        (editInfo.gatewayType === 1 && editInfo.serialNumber.indexOf("_") !== -1) ? (
                            <>
                                <Form.Item label="NETA-21序列号">
                                    {getFieldDecorator('netacode', {
                                        initialValue: showDeviceEditModel.neta21sn,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入NETA-21序列号'
                                            },
                                            {
                                                max: 15,
                                                message: '字符最大长度为15'
                                            },
                                            {
                                                whitespace: true,
                                                message: '禁止输入空格',
                                            }
                                        ],
                                    })(
                                        <Input className="model_input"
                                            placeholder="请输入NETA-21序列号"
                                        />
                                    )}
                                </Form.Item>
                                <Form.Item label="传动节点ID">
                                    {getFieldDecorator('nodeid', {
                                        initialValue: showDeviceEditModel.nodeid,
                                        rules: [
                                            {
                                                required: true,
                                                message: '请输入传动节点ID'
                                            },
                                            {
                                                min: 2,
                                                message: '字符最小长度2'
                                            },
                                            {
                                                whitespace: true,
                                                message: '禁止输入空格',
                                            }
                                        ],
                                    })(

                                        <Input
                                            placeholder="请输入传动节点ID"
                                            type="Number"
                                            className="model_input"
                                        />
                                    )}
                                </Form.Item>
                            </>
                        ) : ''
                    }


                    {
                        this._renderLocationView(showDeviceEditModel, editInfo)
                    }
                </Form>
            </Modal>


        );
    }
}
const DeviceEditModel = Form.create({ name: 'register' })(DeviceEditModelForm);

export default DeviceEditModel;
