import React from 'react';
import {
    Form,
    Input,
    Select,
    Modal,
    message,
    DatePicker,
    Col,
    Row,
    Radio,
} from 'antd';
import moment, { months } from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { seiralnumberlist, templatelist, devicereportcreate } from "@/api/report";
import { setAssetDetail } from '@/redux/actions/device';

const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY/MM/DD';

class CreateReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value: 0,
            devicelist: [],
            selectList: [props.getAssetDetail.deviceName + '（' + props.getAssetDetail.sn + '）'],
            templist: [],
            templatetype: 1,
            startime: moment().subtract(6, 'day'),
            endtime: moment(new Date(), dateFormat),
            reporttime: props.reporttime,
            reporttype: this.props.reporttype,
            selectIds: [],
            keepday: props._deviceDetail.keepDays,
            days: false,
            disabled: false,
            disabledSelect: false,
            minDeviceName: props.getAssetDetail.deviceName
        };
    }

    componentDidMount() {
        this.gettemplatelist();
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.props.form.resetFields();
    }
    onChangefrom = (e, orgId) => {
        this.props.form.resetFields();
        let val = e.target.value
        if (val === 1) {
            this.getdevicelist(orgId);
        }
        this.setState({
            value: val,
            templatetype: Number(val + 1),
            days: false
        }, () => {
            this.props.changereporttime('')
            this.gettemplatelist();
        })
    }
    getdevicelist = (orgId) => {
        let url = `?orgId=${orgId}`;
        seiralnumberlist(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                this.setState({
                    devicelist: resdata
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {

                console.log("getdevicelist -- seiralnumberlist --接口异常")

                message.error('接口异常')
            }
        })

    }
    gettemplatelist = () => {
        let { _deviceDetail } = this.props;
        let url = `/${this.state.templatetype}`;
        templatelist(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                this.setState({
                    templist: resdata
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("gettemplatelist -- templatelist --接口异常")
                message.error('接口异常')
            }
        })

    }
    createreportdata = (data, id, type) => {
        let { value, selectIds } = this.state;
        let ids, isFleetReport, isSubAsset;
        let starttimetmp = Date.parse(data.date[0]._d);
        let endtimetmp = Date.parse(data.date[1]._d);
        if (endtimetmp < starttimetmp) {
            message.warning("结束日期不能小于开始日期！")
            return false;
        }
        if (value == 0) {
            ids = [id];
            isFleetReport = false;
        }
        if (value == 1) {
            ids = selectIds;
            isFleetReport = true;
        }

        if (type === 'PowerTrain') {
            isSubAsset = `?isSubAsset=true`;
        }
        else {
            isSubAsset = ``
        }

        let today = new Date();
        let _today = moment(today).format("YYYY-MM-DD")
        let _todayminute = moment(today).format("HH:mm:ss")
        let _startime = moment(data.date[0]._d).format("YYYY-MM-DD")
        let _endtime = moment(data.date[1]._d).format("YYYY-MM-DD")
        if (_today === _endtime) {
            _endtime = _endtime + " " + _todayminute
        }
        else {
            _endtime = _endtime + " 23:59:59"
        }
        let currentUTCTimeString = moment(_endtime).format("YYYY-MM-DD HH:mm:ss")
        let dateUTCTimeString = moment(_startime).format("YYYY-MM-DD HH:mm:ss");


        let url = {
            ids: ids,
            isFleetReport: isFleetReport,
            startDateTime: dateUTCTimeString,
            endDateTime: currentUTCTimeString,
            templatedId: data.model,
            title: data.title,
            subTitle: data.subtitle
        };
        this.setState({
            disabled: true
        })
        devicereportcreate(url, isSubAsset).then(res => {
            if (res !== undefined) {
                message.success("生成成功！")
                setTimeout(() => {
                    this.props.hidemodel(false);
                    this.props.form.resetFields();
                    this.setState({
                        disabled: false
                    })
                }, 1000)

            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("createreportdata -- devicereportcreate --接口异常")
                message.error('接口异常')
            }
            this.props.hidemodel(false);
            this.props.form.resetFields();
            this.setState({
                disabled: false
            })
        })

    }
    submitmodel = (id, type) => {
        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.createreportdata(fieldsValue, id, type);

            }
        });
    }
    reporttypefn = (orgId, data) => {

        return (
            <Radio.Group value={this.state.value} onChange={(e) => this.onChangefrom(e, orgId)}>
                {/* <Radio value={0}>已选择的传动</Radio>
                <Radio value={1}>多个传动</Radio> */}
                {
                    data.map((item, index) => {
                        if (item.id == 1) {
                            return (
                                <Radio key={index} value={0}>已选择的传动</Radio>
                            )
                        }
                        if (item.id == 2) {
                            return (
                                <Radio key={index} value={1}>多个传动 (最多可选择15台传动)</Radio>
                            )
                        }
                    })
                }
            </Radio.Group>
        )
    }
    onChangetime = (e) => {
        let that = this;
        setTimeout(() => {
            let getFieldsValue = that.props.form.getFieldsValue();
            let starttimetmp = Date.parse(getFieldsValue.date[0]._d);
            let endtimetmp = Date.parse(getFieldsValue.date[1]._d);
            let endtime = Date.parse(new Date());
            var days = (endtimetmp - starttimetmp) / (1 * 24 * 60 * 60 * 1000);
            let maxday = (endtime - starttimetmp) / (1 * 24 * 60 * 60 * 1000);
            let myday = Math.round(days + 1);
            let _maxday = Math.round(maxday + 1);
            if (endtimetmp < starttimetmp) {
                message.warning("结束日期不能小于开始日期！")
                return false;
            }
            if (myday < 7) {
                message.warning(`日期不能小于7天！`)
                that.setState({
                    // startime: that.state.startime,
                    // endtime: that.state.endtime,
                    days: false,
                }, () => {
                    this.props.form.setFieldsValue({
                        'date': [
                            this.state.startime, this.state.endtime
                        ]
                    })
                })
                // this.props.form.resetFields();
                // return false;
            }
            else if (_maxday > this.state.keepday) {
                message.warning(`选择日期不能大于设备创建日期！`)
                that.setState({
                    startime: moment().subtract(this.state.keepday - 1, 'day'),
                    endtime: getFieldsValue.date[1],
                    days: true,
                }, () => {
                    this.props.form.setFieldsValue({
                        'date': [
                            this.state.startime, getFieldsValue.date[1]
                        ]
                    })
                })
                // this.props.form.resetFields();
                // return false;
            }
            else {
                this.setState({
                    days: false
                })
            }
        }, 100);
    }
    onChangeselect = (e, id) => {
        setTimeout(() => {
            let getFieldsValue = this.props.form.getFieldsValue();
            let selectIds = [];
            let keepdayarr = [];
            id.map(item => {
                selectIds.push(item.props.myid);
                keepdayarr.push(item.props.mykeepdays)
            })
            var minkeepdayarr = Math.min.apply(null, keepdayarr);
            let arr = [];
            let disabledSelect = false;
            console.log("disabledSelectdisabledSelectdisabledSelect", getFieldsValue.device)
            // if(getFieldsValue.device.length > 2){
            //     disabledSelect = true
            //     message.warn("最多选15个设备")
            //     return
            // }
            // else{
            //     disabledSelect = false
            // }
            this.state.devicelist.map(item => {
                getFieldsValue.device.map(items => {
                    if (item.name + "（" + item.sn + "）" === items) {
                        arr.push(item)
                    }
                })
            })
            let dataArray = arr;
            let maxArray = '';
            let minDeviceName = ''
            if (dataArray.length > 0) {
                maxArray = dataArray[0].limitIntervalDay;
            }
            for (let i = 0; i < dataArray.length; i++) {
                let tempArray = dataArray[i].limitIntervalDay;
                if (dataArray[i].keepDays == minkeepdayarr) {
                    minDeviceName = dataArray[i].name;
                }
                if (tempArray < maxArray) {
                    maxArray = tempArray;
                }
            }
            let starttimetmp = Date.parse(getFieldsValue.date[0]._d);
            let endtime = Date.parse(new Date());
            let maxday = (endtime - starttimetmp) / (1 * 24 * 60 * 60 * 1000);
            let _maxday = Math.round(maxday + 1);
            if (_maxday > minkeepdayarr) {
                message.warning(`选择日期不能大于设备创建日期！`)
                this.setState({
                    startime: moment().subtract(minkeepdayarr - 1, 'day'),
                    endtime: this.state.endtime,
                    days: true,
                }, () => {
                    this.props.form.setFieldsValue({
                        'date': [
                            this.state.startime, this.state.endtime
                        ]
                    })
                })
            }
            else {
                this.setState({
                    days: false
                })
            }
            this.setState({
                reporttime: maxArray,
                selectIds: selectIds,
                keepday: minkeepdayarr,
                minDeviceName: minDeviceName,
                // disabledSelect: disabledSelect
            })
            this.props.changereporttime(maxArray)
        }, 0);
    }
    changeValues = (rule, value, callback) => {

        const { setFieldsValue } = this.props.form;

        let newArr;

        if (value.length > 15) {

            newArr = [].concat(value.slice(0, 2), value.slice(-1));

            setFieldsValue({

                "languages": newArr,

            })

            callback('最多选择15台传动')

        } else {

            newArr = value;

            callback()

        }

    }

    render() {
        let that = this;
        let { _deviceDetail, reportdata, reporttime, reporttype } = that.props;
        const { getFieldDecorator } = that.props.form;
        let { startime, endtime, selectList, disabled, disabledSelect } = this.state;
        let disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(reporttime, 'day') || (current && _deviceDetail.createdOn && current < moment(_deviceDetail.createdOn).add(-1, 'd').endOf('day'));

        return (
            <>
                <Modal
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                    title="生成报告"
                    centered
                    maskClosable= {false}
                    width={504}
                    visible={reportdata.visible}
                    onOk={() => that.hidemodel(false)}
                    onCancel={() => that.hidemodel(false)}
                    footer={[
                        <ABB.Button
                            onClick={() => that.hidemodel(false)}
                            type="discreet-black"
                            sizeClass='small' theme="filled"
                            text="取消"
                        />,
                        <ABB.Button
                            onClick={() => that.submitmodel(reportdata.assetId, reportdata.isSubAsset)}
                            type="primary-blue"
                            disabled={disabled}
                            sizeClass='small' theme="filled"
                            text="确定"
                        />,
                    ]}

                >
                    {
                        reporttype.length > 0 ? (
                            <Row gutter={24} style={{ marginBottom: 16 }}>
                                <Col span={24}>
                                    {
                                        that.reporttypefn(reportdata.orgId, reporttype)
                                    }
                                </Col>
                            </Row>
                        ) : ''
                    }
                    <Form style={{maxHeight:'600px',overflowY:'auto',padding: '0 8px',overflowX:'hidden'}}>
                        <Row gutter={24}>

                            {
                                this.state.value === 1 ? (
                                    <Col span={24}>
                                        <Form.Item label="选择传动">
                                            {getFieldDecorator('device', {
                                                initialValue: selectList,
                                                rules: [
                                                    { required: true, message: '请选择传动设备'},{
                                                        required: true, message: '最多可选择15台传动',
                                                        validator : this.changeValues 
                                                    }
                                                ],
                                            })(
                                                <Select placeholder="请选择传动设备"
                                                    mode="multiple"
                                                    onChange={(e, option) => this.onChangeselect(e, option)}
                                                    showArrow={true}
                                                    showSearch={false}
                                                    disabled={disabledSelect}
                                                    suffixIcon={
                                                        <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                                    }
                                                >
                                                    {
                                                        this.state.devicelist.map((item, index) => {
                                                            return (
                                                                <Option myid={item.id} mykeepdays={item.keepDays} key={item.id} value={item.name + '（' + item.sn + '）'}>{item.name}（{item.sn}）</Option>
                                                            )
                                                        })
                                                    }

                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                ) : ''
                            }
                            {
                                reporttime !== 1 ? (
                                    <Col span={24}>
                                        <Form.Item label="日期范围">
                                            {getFieldDecorator('date', {
                                                initialValue: [startime, endtime],
                                                rules: [
                                                    { required: true, message: '请选择' },
                                                ],
                                            })(
                                                <RangePicker
                                                    className="model_input"
                                                    style={{ width: "100%" }}
                                                    suffixIcon={
                                                        <ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
                                                    }
                                                    separator='—'
                                                    disabledDate={disabledDate}
                                                    onChange={() => this.onChangetime()}
                                                    format={dateFormat}
                                                    allowClear={false}

                                                />
                                            )}
                                        </Form.Item>
                                        {
                                            this.state.days === true ? (
                                                <div style={{ marginTop: "-20px" }}>
                                                    <span style={{ color: '#36f' }}>{this.state.minDeviceName}</span>设备于{moment(this.state.startime._d).format("YYYY-MM-DD")}添加，不支持选择此日期之前的日期作为报告起始时间。
                                                </div>
                                            ) : ''
                                        }

                                    </Col>

                                ) : "null"
                            }

                            <Col span={24}>
                                <Form.Item label="模板">
                                    {getFieldDecorator('model', {
                                        rules: [
                                            { required: true, message: '请选择一个模板' },
                                        ],
                                    })(
                                        <Select placeholder="请选择一个模板"
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }

                                        >
                                            {
                                                this.state.templist.map((item, index) => {
                                                    return (
                                                        <Option key={index} value={item.id}>{item.templateName}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="报告标题">
                                    {getFieldDecorator('title', {
                                        rules: [
                                            { required: true, message: '请输入报告标题' },
                                            {
                                                max: 50,
                                                message: "报告标题字符不能超过50"
                                            },
                                            {
                                                whitespace: true,
                                                message: '禁止输入空格',
                                            }
                                        ],
                                    })(
                                        <Input
                                            className="model_input"
                                            placeholder="请输入报告标题" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="报告副标题">
                                    {getFieldDecorator('subtitle', {
                                        rules: [
                                            { required: true, message: '请输入报告副标题' },
                                            {
                                                max: 50,
                                                message: "报告副标题字符不能超过50"
                                            },
                                            {
                                                whitespace: true,
                                                message: '禁止输入空格',
                                            }
                                        ],
                                    })(
                                        <Input
                                            className="model_input"
                                            placeholder="请输入报告副标题" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <div>根据所选日期范围，报告生成预计耗时<span style={{ color: '#36f' }}>10~20</span>分钟</div>
                            </Col>

                        </Row>

                    </Form>
                </Modal>
            </>

        );
    }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(CreateReport);
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setAssetDetail: data => {
        dispatch(setAssetDetail(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WrappedRegistrationForm));