import React, { Component } from 'react'
import './style.scss';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { userCheckDevice, deviceDetail, eventDetaildevice, checkAllgen } from '@/redux/actions/device';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import EeventDetail from './eventdetail/index'
import EventLoggerTab from './eventlogger/index'


class EventLogger extends Component {
    backuper = () => {
        let { getassettype, eventDetail } = this.props;
        if (getassettype === "PowerTrain") {
            if (eventDetail === '1') {
                this.props.userCheckDevice(3)
            }
            else {
                this.props.eventDetaildevice('2')
                this.props.userCheckDevice(9);
            }

        }
        else if (getassettype === "noPowerTrain") {
            this.props.userCheckDevice(11)
            if (eventDetail === '1') {
                this.props.eventDetaildevice('1')
            }
            else {
                this.props.eventDetaildevice('2')
                this.props.userCheckDevice(9);
            }
        }
        else {
            // this.props.userCheckDevice(0);
            if (eventDetail === '1') {
                this.props.eventDetaildevice('1')
                this.props.userCheckDevice(0);
            }
            else {
                this.props.eventDetaildevice('2')
                this.props.userCheckDevice(9);
            }
        }

    }
    render() {
        let { getEventDetail } = this.props;
        return (
            <div>
                <div className="life_back">
                    <div className='backup_button' onClick={() => this.backuper()}>
                        <ABB.Icon name="abb/left" sizeClass='medium' />事件详情</div>
                </div>
                <EeventDetail />
                {/* <EventLoggerTab /> */}
                {
                    getEventDetail.isHasAttachParameter !== false || getEventDetail.isHasTrendChart !== false || getEventDetail.isHasEventLogger !== false ? (
                        <EventLoggerTab />
                    ) : <div style={{ height: '64vh', backgroundColor: '#fff', marginRight: '16px' }}></div>
                }

            </div>
        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EventLogger));

