//编辑数字化连接调试信息
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Modal,
    Col,
    Row,
} from 'antd';

import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { setSupportRecordConfig } from './store/actionCreators';

class SupportRecordModel extends React.Component {
    state = {
        inputval: '',
        valiededCode: '',
        subdata: {},
        disabled: false
    };

    hidemodel = (data) => {
        this.props.hidemodel()
    }

    // 4G路由器信息 / NETA-21信息 / 变频器信息
    _renderSectionHeaderByTitle = (isMust, title) => {
        return <Row >
            <div className='section_item'>
                {isMust === true ? (<div className='section_icon'></div>) : null}
                <div >{title}</div>
            </div>
        </Row>
    }

    _renderEventType = (assetEventType) => {
        if (assetEventType === 'Fault') {
            return (
                <>
                    <ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
                    故障
                </>
            );
        }
        if (assetEventType === 'Warning') {
            return (
                <>
                    <ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
                    告警
                </>
            );
        }
        if (assetEventType === 'Notification') {
            return (
                <>
                    <ABB.Icon style={{ 'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
                    通知
                </>
            );
        }
        if (assetEventType === 'Custom') {
            return (
                <>
                    <div className='customevent'></div>
                    自定义
                </>
            );
        }
    }


    render() {
        let that = this;
    
        const { showmodelboundsub } = that.props;
        const eventData = showmodelboundsub.eventData
 
        const modelProps = {
            title: "支持记录",
            centered: true,
            width: 550,
            maskClosable: false,
            visible: showmodelboundsub.visible,

            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: []
        }

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <div className='message_wrapper'>
                        <div className='left'>事件类型:</div><div className='right'>{
                            this._renderEventType(eventData.assetEventType)
                        }</div>
                    </div>
                    <div className='message_wrapper'>
                        <div className='left'>设备端时间:</div>
                        <div className='right'>{
                            eventData.occurrentTimeIsValidFormat === true ? (
                                <div>
                                    {moment.parseZone(Number(eventData.eventOccurrentTime)).local().format("YYYY-MM-DD HH:mm:ss")}
                                </div>
                            ) : (
                                <div>{eventData.eventOccurrentTime || '-'}</div>
                            )

                        }</div>
                    </div>
                    <div className='message_wrapper'>
                        <div className='left'>上传时间:</div>
                        <div className='right'><div>{eventData.eventDateTime !== undefined ? moment.parseZone(eventData.eventDateTime).local().format("YYYY-MM-DD HH:mm:ss") + "（UTC+8）" : '-'} </div></div>


                    </div>
                    <div className='message_wrapper'>
                        <div className='left'>事件描述:</div><div className='right'>{eventData.content || '-'}</div>
                    </div>
                    <div className='message_wrapper'>
                        <div className='left'>事件代码:</div><div className='right'>{eventData.eventCode || '-'}</div>
                    </div>

                    <div className='record_wrapper'>
                        <div className='record_header'>
                            <Row gutter={24}>
                                <Col span={12}><div>发起时间</div></Col>
                                <Col span={12}><div>发起人</div></Col>
                            </Row>
                        </div>
                        <div className='record_headline'></div>

                        {
                            eventData.calledRecords.map((item, index) => {
                                return <div className='record_item'>
                                    <Row gutter={24}>
                                        <Col span={12}><div>{moment(item.calledTime).local().format("YYYY-MM-DD HH:mm:ss")}</div></Col>
                                        <Col span={12}><div>{item.calledUserName}</div></Col>
                                    </Row>

                                </div>
                            })
                        }

                    </div>
                </Modal>
            </>
        );
    }
}




const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
    updataSupportRecordConfig: data => {
        dispatch(setSupportRecordConfig(data))
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SupportRecordModel));

