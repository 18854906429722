import React, { Component } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import EUevent from '@/components/eventcomponent/EUevent';
import Map from './components/plantmap';
import EchartsLine from './components/LineChart';
import EchartsPie from './components/PieChart';
import { assetsandsubscriptions } from '@/api/chart.js'

const ResponsiveGridLayout = WidthProvider(Responsive);

const CPhomepage_num = {
	lineHeight: '100px',
	fontSize: '50px',
	color: '#1F1F1F',
	textAlign: 'center'
}

class EUindex extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isEUorCP: 'CP',
			EUlayout: [
				{ i: 'g', x: 0, y: 0, w: 6, h: 10 },
				{ i: 'a', x: 0, y: 10, w: 6, h: 10 },
				{ i: 'b', x: 6, y: 0, w: 6, h: 10 },
				{ i: 'c', x: 6, y: 10, w: 3, h: 10 },
				{ i: 'd', x: 9, y: 10, w: 3, h: 10 },
				{ i: 'e', x: 0, y: 20, w: 3, h: 10 },
				{ i: 'f', x: 3, y: 20, w: 3, h: 10 },
				{ i: 'h', x: 6, y: 20, w: 3, h: 10 },
				{ i: 'l', x: 9, y: 20, w: 3, h: 10 },
				{ i: 'i', x: 0, y: 30, w: 3, h: 5 },
				{ i: 'j', x: 3, y: 30, w: 3, h: 5 },
				{ i: 'k', x: 6, y: 30, w: 3, h: 5 },
			],
			assetsandsubscriptions: {
				assets: {},
				subscriptions: ''
				
			}
		};
		this.fetch = this.fetch.bind(this);
	}

	fetch = () => {
		assetsandsubscriptions().then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				this.setState({
					assetsandsubscriptions: resdata
				})
			}
		})
	}

	componentDidMount() {
		this.getUserInfo();
		this.fetch();
	};
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	isEqual = (arr1, arr2) => {
		if (arr1 === null) {
			return false
		}
		if (arr1.length !== arr2.length) {
			return false
		}
		else if (arr1.length === arr2.length) {
			for (let i = 0; i < arr1.length; i++) {
				const item1 = arr1[i]
				const item2 = arr2[i]
				if (item1['i'] !== item2['i']) {
					return true
				}
			}
		}

		return true
	}
	getUserInfo = () => {
		var _EUlayoutArr = JSON.parse(localStorage.getItem("EUlayoutArr"))

		let islayoutlength = this.isEqual(_EUlayoutArr, this.state.EUlayout)
		if (_EUlayoutArr === null || _EUlayoutArr === undefined || (islayoutlength === false)) {
			this.setState({
				EUlayout: [
					{ i: 'g', x: 0, y: 0, w: 6, h: 10 },
					{ i: 'a', x: 0, y: 10, w: 6, h: 10 },
					{ i: 'b', x: 6, y: 0, w: 6, h: 10 },
					{ i: 'c', x: 6, y: 10, w: 3, h: 10 },
					{ i: 'd', x: 9, y: 10, w: 3, h: 10 },
					{ i: 'e', x: 0, y: 20, w: 3, h: 10 },
					{ i: 'f', x: 3, y: 20, w: 3, h: 10 },
					{ i: 'h', x: 6, y: 20, w: 3, h: 10 },
					{ i: 'l', x: 9, y: 20, w: 3, h: 10 },
					{ i: 'i', x: 0, y: 30, w: 3, h: 5 },
					{ i: 'j', x: 3, y: 30, w: 3, h: 5 },
					{ i: 'k', x: 6, y: 30, w: 3, h: 5 },
				]
			})

		}
		else {
			this.setState({
				EUlayout: _EUlayoutArr
			})
			// this.state.EUlayout = _EUlayoutArr
		}
	}

	//存储拖拽移动的位置到缓存
	onLayoutChange = (layout) => {
		let EUlayoutArr = [];

		var index = -1;
		localStorage.removeItem('CPlayoutArr')
		localStorage.removeItem('ABBlayoutArr')
		layout.forEach(({ i, x, y, w, h }) => {
			index++;
			EUlayoutArr[index] = { i, x, y, w, h }
		})
		localStorage.setItem('EUlayoutArr', JSON.stringify(EUlayoutArr))
	}

	render() {

		return (
			<>
				<div className="dashboardContent">
					<ResponsiveGridLayout className="layout" layouts={{ lg: this.state.EUlayout }} rowHeight={30}
						breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
						cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
						isResizable={false}
						onLayoutChange={this.onLayoutChange}
						margin={[8, 8]}
					>
						<div className='dragcontent' key="g">
							<div id='dragcontent_a'>设备分布</div>
							<Map title="map" />
						</div>


						<div className='dragcontent' key="b">

							<div id='dragcontent_a'>事件统计(最近七天)</div>
							{/* <hr id='line2'></hr> */}
							<EchartsLine />
						</div>
						<div className='dragcontent' key="c">

							<div id='dragcontent_a'>可用性指标</div>
							<EchartsPie content={1} />
						</div>
						<div className='dragcontent' key="d">

							<div id='dragcontent_a'>环境指标</div>
							<EchartsPie content={2} />
						</div>
						<div className='dragcontent' key="e">

							<div id='dragcontent_a'>可靠性指标</div>
							<EchartsPie content={3} />
						</div>
						<div className='dragcontent' key="f">

							<div id='dragcontent_a'>负荷指标</div>
							<EchartsPie content={4} />
						</div>
						<div className='dragcontent' key="h">

							<div id='dragcontent_a'>设备数量统计（按订阅类型、状态）</div>
							<EchartsPie content={6} />
						</div>
						<div className='dragcontent' key="l">

							<div id='dragcontent_a'>激活设备统计（按正式订阅等级）</div>
							<EchartsPie content={7} />
						</div>
						<div className='dragcontent' key="i">
							<div id='dragcontent_a'>传动设备数</div>
							<div style={CPhomepage_num}>{this.state.assetsandsubscriptions.assets.drive}</div>
						</div>
						<div className='dragcontent' key="j">
							<div id='dragcontent_a'>电机设备数</div>
							<div style={CPhomepage_num}>{this.state.assetsandsubscriptions.assets.motor}</div>
						</div>
						<div className='dragcontent' key="k">
							<div id='dragcontent_a'>传动链设备数</div>
							<div style={CPhomepage_num}>{this.state.assetsandsubscriptions.assets.powertrain}</div>
						</div>
						<div className='dragcontent' key="a">

							<div id='dragcontent_a'>最新事件列表</div>
							<EUevent />
						</div>
					</ResponsiveGridLayout>
				</div>
			</>
		);
	}
}

export default EUindex;
