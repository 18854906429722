import React, { Component } from 'react';
import { Modal, message, Checkbox } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { hidecomplimentary } from "@/api/device";


class GetSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            isChecked: false
        };
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodeldelete = () => {
        this.setState({
            isChecked: false,
            disabled: true
        })
        this.props.hidemodel()
    }
    submitmodel = () => {
        // this.props.hidemodel()
        if (this.state.isChecked === true) {
            this.gethidecomplimentary()
        }
        else{
            this.props.hidemodel()
        }

    }
    gethidecomplimentary = () => {
        this.setState({
            disabled: true
        })
        hidecomplimentary().then(res => {
            // console.log("hidecomplimentary-----",res.data)
            if (res.data === true) {
                message.success("操作成功")
                this.setState({
                    isChecked: false,
                })
                this.props.hidemodel()
            }
        }).catch(err => {

            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('操作失败')
            }
        })
    }
    onChange = (e) => {
        // console.log("eeeeeeeeee",e.target.checked)
        if(e.target.checked === true){
            this.setState({
                isChecked: e.target.checked,
                disabled: false
            })
        }
        else{
            this.setState({
                isChecked: e.target.checked,
                disabled: true
            })
        }
    }
    render() {
        const that = this;
        const { showmodel } = this.props;
        const modelProps = {
            title: '获赠订阅提醒',
            centered: 'true',
            width: 504,
            maskClosable: false,
            visible: showmodel.visible,
            // visible: true,
            closable: false,
            onOk: () => { that.hidemodeldelete() },
            onCancel: () => { that.hidemodeldelete() },
            footer: [
                // <ABB.Button
                //     onClick={() => that.hidemodeldelete()}
                //     type="discreet-black"
                //     sizeClass='small' theme="filled"
                //     text="取消"
                // />,
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    // disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        return (
            <Modal
                {...modelProps}
                // closeIcon={
                //     <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                // }
                >
                <div className="migration-text">此设备的网关固件已升级到最新版本，并已获赠1年免费的基础版订阅。此次固件升级涉及后台功能调整。调整期间，此设备历史数据会逐步补充完整。给您带来的不便，敬请谅解！</div>

                <div className='subscription-box'>
                    <Checkbox onChange={that.onChange} checked={this.state.isChecked}>不再显示此类消息</Checkbox>
                </div>
            </Modal>
        );
    }
}

export default GetSubscription;
