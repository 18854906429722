import React, { Component } from 'react';
import * as ABB from 'abb-common-ux-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';


class AddDeviceProcess extends Component {
    constructor(props) {
        super(props);
        const process = props.getAddDeviceProcess //默认0   0:没有进度  1:填写设备信息   2:绑定订阅  3:完成

        var processList = [
            {
                title: "填写设备信息",
                className: `icon_content icon_normal`,
                titleClassName: `title_9f9f9f`
            },
            {
                title: "绑定订阅",
                className: `icon_content `,
                titleClassName: `title_9f9f9f`
            },
            {
                title: "完成",
                className: `icon_content icon_normal`,
                titleClassName: `title_9f9f9f`
            },
        ]
        switch (process) {
            case 0:
                break

            case 1:
                processList[0].className = `icon_content icon_current`;
                processList[0].titleClassName = `title_1f1f1f`;
                break
            case 2:
                processList[0].className = `icon_content icon_selected`;
                processList[0].titleClassName = `title_1f1f1f`;
                processList[1].className = `icon_content icon_current`;
                processList[1].titleClassName = `title_1f1f1f`;
                break
            case 3:
                processList[0].className = `icon_content icon_selected`;
                processList[0].titleClassName = `title_1f1f1f`;

                processList[1].className = `icon_content icon_selected`;
                processList[1].titleClassName = `title_1f1f1f`;

                processList[2].className = `icon_content icon_current`;
                processList[2].titleClassName = `title_1f1f1f`;
                break
            default:
                break
        }
        this.state = {
            processList
        }


    }


    render() {
        return (
            <div className='addDevice_process_box'>
                <div className='addDevice_process_line'></div>
                <div className='textContent'>
                    {
                        this.state.processList.map((item, index) => {
                            return (
                                <div key={item.title}>
                                    <div className={`${item.className}`}>
                                        <div className='icon_style'>
                                            <ABB.Icon style={{ color: '#ffffff', cursor: "auto"}} name="abb/check-mark-light" sizeClass='small' />
                                        </div>
                                    </div>
                                    <div className={`${item.titleClassName}`}>{item.title}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddDeviceProcess));
