import React, { Component } from 'react';
import { Table, Icon, Modal, Button, Input, Select, Menu, Spin, message } from 'antd';
import { loading } from '@/redux/actions/setting';
import { getexportdevice, getssmotor } from '@/api/device';
import { connect } from 'react-redux';
import { setBatchasset } from '@/redux/actions/device';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import { bindSSOrganization, getBindSSOrganization } from '@/api/user.js';
import * as ABB from 'abb-common-ux-react';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import NoData from '@/components/NoData'
//import { ABBAADAuthProvider } from "../../lib/ABBAADAuthProvider";

const { Search } = Input;
const { Option } = Select;
const icon_center = {
	margin: '0 10px'
}

class Batchimport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabledata: [],
			pagination: {},
			loading: false,
			ssName: null,
			orgId: this.props.getSubid.orgId,
			columns: [
				{
					title: '原设备名',
					dataIndex: 'assetName',
					key: '0',
					width: '55',

				},
				{
					title: '设备组',
					dataIndex: 'assetGroupName',
					key: '1',

				},
				{
					title: '设备类型',
					dataIndex: 'type',
					key: '2',

				},
				{
					title: '传感器ID',
					dataIndex: 'sensorIdentifier',
					key: '3',

				},


				{
					title: '导入状态',
					dataIndex: 'importStatus',
					key: '4',

					// render: (text, record) => (
					// <div>
					//     已导入
					// </div>
					// ),
				},
				{
					title: '设备名',
					dataIndex: 'name',
					key: '5',

				},

			]
		};
	}

	checkDevice(id) {

		this.props.userCheckDevice(1)
	}
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}

	getBindStatus = () => {
		let { getSubid } = this.props;
		this.setState({
			loading: true
		})
		if (getSubid.type == 3 || getSubid.type == 100) {
			getBindSSOrganization(getSubid.orgId).then(res => {
				this.setState({ ssName: res.data.ssOrgName });
				this.fetch();
			}).catch(err => {
				 
				if (err.status === 400 || err.status === 404) {
					this.setState({ ssName: null });
					this.props.setBatchasset([])
					message.destroy()
					message.warning(err.data)
				}
				this.setState({
					loading: false
				})
			})
		}

	}

	componentDidMount() {
		this.setState({
			loading: false
		})
		if (this.props.getBatchImport == 3) {
			this.getBindStatus();
		}

	}
	componentDidUpdate(nextProps, prevState) {

		if (
			(prevState.orgId !== this.props.getSubid.orgId) &&
			this.props.getBatchImport == 3 &&
			(this.props.getSubid.type == 3 || this.props.getSubid.type == 100)) {
			this.setState({
				orgId: this.props.getSubid.orgId
			}, () => {
				this.getBindStatus();
			})
		}
	}
	getssmotor = () => {
		let url = `${this.props.getSubid.orgId}`
		getssmotor(url).then(res => {
			this.fetch()
			message.success("电机导入成功")
		}).catch(err => {
			if (err.status === 400) {
				message.warning(err.data)
			}
			else if (err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('导入失败')
			}
		})
	}
	fetch = () => {

		let { getSubid } = this.props;
		if (getSubid.type == 3 || getSubid.type == 100) {
			let url = `/${getSubid.orgId}`;
			getexportdevice(url).then(res => {
				this.props.setBatchasset(res.data)
				this.setState({
					loading: false
				})
			}).catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.destroy()
					message.warning(err.data)
				}
				else {
					message.error('导入失败')
				}
				this.setState({
					loading: false
				})
			})
		}

	};
	Fnexport = () => {
		this.getssmotor()
	}
	render() {
		let mytablecolumns = this.state.columns;
		let _self = this;
		let { equipmentdata, getBatchasset, userInfo } = _self.props;

		return (
			<div>
				<div>
					<div className="user_box" style={{ marginTop: "10px" }}>
						<span>设备列表</span>
						<div>
							{
								userInfo.organizationType === "EU" ? (
									<ABB.Button
										disabled={this.state.ssName != null ? true : false}
										onClick={() => {
											bindSSOrganization().then(res => {
												if (res !== undefined) {
													message.success('绑定成功')

													_self.getBindStatus();
													_self.fetch();

													_self.setState({
														createOrgdata: false
													})
												}
											}).catch(err => {
												if (err.status === 400) {
													message.warning(err.data)
												}
												else if (err.status === 404) {
													message.warning(err.data)
												}
												else {
													message.error('绑定失败')
												}

											})
										}}
										type="primary-blue"
										sizeClass='small' theme="filled"
										text="绑定 smartsensor 组织"
										style={{ marginRight: "10px" }}
									/>
								) : ''
							}

							<ABB.Button
								disabled={this.state.ssName != null ? false : true}
								onClick={() => _self.Fnexport()}
								type="primary-blue"
								sizeClass='small' theme="filled"
								text="导入 smartsensor 列表"
							/>
						</div>
					</div>
					<Spin spinning={this.state.loading} style={{ height: "77vh" }}>
						<div style={{ position: 'relative', minHeight: "77vh" }}>
							{
								getBatchasset.length > 0 ? (
									<Table
										size='small'

										columns={mytablecolumns}
										dataSource={getBatchasset}
										pagination={false}
										rowKey={(record, item) => record.id}
									/>
								) : <NoData />
							}
						</div>
					</Spin>


				</div>
				{/* <AzureAD provider={ABBAADAuthProvider}>
					{
						({ login, logout, authenticationState, error, accountInfo }) => {
							switch (authenticationState) {
								case AuthenticationState.Authenticated:
									return (
										<div>
											<div className="user_box">
												<span>设备列表</span>
												<div onClick={() => _self.Fnexport()}>
													<ABB.Icon name="abb/refresh" sizeClass='small' />
												</div>
											</div>
											<Table
												size='small'

												columns={mytablecolumns}
												dataSource={_self.state.tabledata}
												pagination={false}
												rowKey={(record, item) => record.id}
											/>
										</div>
									);
								case AuthenticationState.Unauthenticated:
									return (
										<Button onClick={login}>ABB 用户登录</Button>
									);
								case AuthenticationState.InProgress:
									return null;
							}
						}
					}
				</AzureAD> */}
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	loading: data => {
		dispatch(loading(data))
	},
	setBatchasset: data => {
		dispatch(setBatchasset(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Batchimport));

// export default EUsubscription;

