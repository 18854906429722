import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { connect } from 'react-redux';
import { setCBMdata, setCBMtype } from '@/redux/actions/cbm'
import { withRouter } from 'react-router-dom';
import { userCheckDevice, deviceDetail } from '@/redux/actions/device';


class AnnualAging extends Component {
    constructor(props) {
        super(props);
        this.state = {


            cbmdata: props.cbmdata,
            seriesData: {},
            CBMtype: this.props.getCBMtype
        };
    }

    componentDidMount() {
        // this.fnCBMdata();
        this.chartsData(this.state.cbmdata, this.state.CBMtype);

    }
    
    componentDidUpdate(nextProps, prevState) {
        if (prevState.CBMtype !== this.props.getCBMtype) {
            this.setState({
                CBMtype: this.props.getCBMtype
            }, () => {
                this.chartsData(this.state.cbmdata, this.props.getCBMtype)
            })
        }
    }
    chartsData = (data, type) => {
        // console.log("Data:=====", Data);

        if (data !== "") {
            let resdata = data.data;
            resdata.map(item => {
                if (item.unitType === type) {
                    this.optiondata(item);
                }

            })
        }

    }

    starttime = (starttime) => {
        let date = new Date(starttime);
        let year = date.getFullYear();
        let _year = Number(year);
        let newdate = _year + "-01-01";
        let _startPointDate = Date.parse(newdate);
        return _startPointDate;
    }

    endtime = (endtime) => {
        let date = new Date(endtime);
        let year = date.getFullYear();
        let _year = Number(year) + 1;
        let newdate = _year + "-01-01";

        let _endPointDate = Date.parse(newdate);
        
        return _endPointDate;
    }

    datetime = (starttime, endtime) => {

        let _endPointDate = this.endtime(endtime);

        let _startPointDate = this.starttime(starttime);

        let total = (_endPointDate - _startPointDate) / 1000 / 60 / 60 / 24;
        // console.log("total",total)

        return total;
    }
    optiondata = (item) => {
        let activationDate = Date.parse(item.activationDate);//绿色
        let predictedStartPlanDate = Date.parse(item.predictedStartPlanDate);//黄
        let predictedRunDate = Date.parse(item.predictedRunDate);//红色
        let startPointDate = Date.parse(item.startPointDate);//色
        let endPointDate = Date.parse(item.endPointDate);//色
        let currentMonth = Date.parse(item.currentMonth);//色
        let RulPoints = item.rulPoints;
        let arr = [];
        let tsArray = [];
        let xAxisDatanum = [];
        let starttime = this.starttime(item.startPointDate);

        let datastarttime = (startPointDate - starttime) / 1000 / 60 / 60 / 24;
        // let total = 0;
        RulPoints.map((item, index) => {
            tsArray.push(Date.parse(item.date));
            arr.push(item.value);
        })

        let total = this.datetime(item.startPointDate, item.endPointDate);


        let RulPointsdate = [datastarttime];

        for (let i = 0; i < tsArray.length; i++) {

            if (i > 0) {
                let num = (tsArray[i] / 1000 / 60 / 60 / 24) - (starttime / 1000 / 60 / 60 / 24);
                // console.log("num", num)
                // total += num;
                RulPointsdate.push(num)
            }
        }

        let newarr = arr.map((key, index) => [RulPointsdate[index], key])

        xAxisDatanum = Array.from(new Array(total).keys())

        let newarr1 = JSON.parse(JSON.stringify(newarr));
        let [first] = newarr1;
        let [last] = [...newarr1].reverse();
        first[1] = "-";
        last[1] = "-";
        // let graybar = (startPointDate - starttime) / 1000 / 60 / 60 / 24;
        let currentDate = (currentMonth - starttime) / 1000 / 60 / 60 / 24;

        let preventiveMaintenanceDate = (Date.parse(item.preventiveMaintenanceDate) - starttime) / 1000 / 60 / 60 / 24;
        let greenbar = (activationDate - starttime) / 1000 / 60 / 60 / 24;

        let yellowbar = (predictedStartPlanDate - starttime) / 1000 / 60 / 60 / 24;

        let redbar = (predictedRunDate - starttime) / 1000 / 60 / 60 / 24;

        if(predictedStartPlanDate>predictedRunDate){
            yellowbar = -1000;
        }
        // console.log("greenbar=-----",greenbar)

        let marklinedata = [

            [
                {
                    // name: '2',
                    lineStyle: {
                        width: 2,
                        color: '#1f1f1f',
                        type: 'solid'
                    },
                    coord: [currentDate, 0]
                },
                {
                    coord: [currentDate, 100]
                }
            ],

            [
                {
                    // name: '1',
                    lineStyle: {
                        width: 2,
                        color: '#BABABA',
                    },
                    coord: [total, 0]
                },
                {
                    coord: [total, 100]
                }
            ],
            [
                {
                    // name: '2',
                    lineStyle: {
                        width: 2,
                        color: '#BABABA',
                    },
                    coord: [RulPointsdate[0], 0]
                },
                {
                    coord: [RulPointsdate[0], 100]
                }
            ],
            [
                {
                    // name: '2',
                    lineStyle: {
                        width: 2,
                        color: '#477A43',
                    },
                    coord: [greenbar, 0]
                },
                {
                    coord: [greenbar, 100]
                }
            ],
            [
                {
                    // name: '2',
                    lineStyle: {
                        width: 2,
                        color: '#FFD800',
                    },
                    coord: [yellowbar, 0]
                },
                {
                    coord: [yellowbar, 100]
                }
            ],
            [
                {
                    // name: '2',
                    lineStyle: {
                        width: 2,
                        color: '#F13040',
                    },
                    coord: [redbar, 0]
                },
                {
                    coord: [redbar, 100]
                }
            ],
        ];


        let xAxisData = [];

        var DateYearstart = new Date(startPointDate);
        var startyear = DateYearstart.getFullYear();

        var DateYearend = new Date(endPointDate);
        var endtyear = DateYearend.getFullYear();

        for (let i = startyear; i <= endtyear + 1; i++) {
            xAxisData.push(i);
        }


        this.setState({
            seriesData: {
                title: {
                    text: '年度老化状态',
                },
                tooltip: {
                    trigger: '',
                    showContent: false
                },
                grid: {
                    left: '8px',
                    right: '20px',
                    top: '60px',
                    bottom: '0px',
                    containLabel: true
                },
                xAxis: [
                    {
                        // name: 'data1',
                        type: 'category',
                        axisLine: {
                            lineStyle: {
                                color: '#686868'
                            }
                        },
                        // max: total,
                        axisLabel: {
                            show: false,
                            formatter: "{value}",
                            color: '#686868',
                            interval: 0,

                        },
                        // axisTick: {
                        //     length: 3,//刻度线的长度
                        //     interval: ((total)/xAxisData.length)/12,  //该{}中的data全部显示
                        //     lineStyle: {
                        //         color: '#686868',
                        //         fontSize: '12px'
                        //     }
                        // },

                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },

                        data: xAxisDatanum,

                    },
                    {

                        type: 'category',
                        data: xAxisData,
                        boundaryGap: false,
                        position: "bottom",
                        axisLabel: {
                            // formatter: '{value}%',
                            // fontFamily: 'ABBvoiceCNSG-Regular',
                            // interval: 0,
                        },

                    },

                ],
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value}%',
                        fontFamily: 'ABBvoiceCNSG-Regular',

                    },

                },
                series: [
                    {
                        name: "line1",
                        data: [[datastarttime, 100], [preventiveMaintenanceDate, 0]],
                        type: 'line',
                        areaStyle: {
                            color: "#d9f2ff"
                        },
                        lineStyle: {
                            color: '#999',
                            width: 1,
                            type: 'solid'
                        },
                        xAxisIndex: 0,
                        silent: true,
                    },
                    {
                        name: "line",
                        data: newarr,
                        type: 'line',
                        // areaStyle: {
                        //     color: "#eeeeee"
                        // },
                        lineStyle: {
                            color: '#5470C6',
                            width: 2,
                            type: 'dotted'
                        },
                        xAxisIndex: 0,
                        silent: true,
                    },
                    {
                        name: "line",
                        data: newarr1,
                        type: 'line',
                        xAxisIndex: 0,
                        // areaStyle: {
                        //     color: "#eeeeee"
                        // },
                        lineStyle: {
                            color: '#5470C6',
                            width: 2,
                            type: 'solid'
                        },
                        markLine: {
                            lineStyle: {
                                width: 1,
                                color: '#F7B500',
                            },
                            // label: {
                            //     show: true,
                            //     position: 'end',
                            //     // formatter: '',
                            //     color: '#32383B',
                            //     height: 10,
                            //     padding: [12, 12, 7, 12],
                            //     lineHeight: 10,
                            //     borderWidth: 2,
                            //     borderColor: '#F7B500',
                            //     borderRadius: 2,
                            // },
                            silent: true, // 鼠标悬停事件, true悬停不会出现实线
                            symbol: 'none', // 去掉箭头
                            data: marklinedata
                        }

                    }

                ]
            }
        })
    }
    render() {
        return (
            <div className="charts_flex">
                <div className="charts_flex_left_annualaging">

                    <ReactEcharts
                        option={this.state.seriesData}
                        style={{ height: '100%', width: '100%' }}
                        opts={{ renderer: 'svg' }}
                        className='react_for_echarts'
                    />
                </div>
                <div className="charts_flex_right">

                    <div className="list_icon6">标准老化速度</div>
                    <div className="list_icon5">监测前的老化预测</div>
                    <div className="list_icon7">实际老化速度</div>
                    <div className="list_icon10">预测平均值</div>
                    <div className="list_icon9">当前月份</div>
                    <div className="list_icon8">部件启用日期</div>
                    <div className="list_icon11">预测性维护已激活</div>
                    <div className="list_icon12">开始计划预防性维护</div>
                    <div className="list_icon13">执行预防性维护</div>
                </div>

            </div>

        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setCBMdata: data => {
        dispatch(setCBMdata(data))
    },
    setCBMtype: data => {
        dispatch(setCBMtype(data))
    },
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AnnualAging));
// export default AnnualAging;