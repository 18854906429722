import React from 'react';
import ReactDOM from 'react-dom';
import { Map, Marker, InfoWindow } from 'react-amap';
import { Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import { setaddress,setaddressChange } from '@/redux/actions/plant'
import { withRouter } from 'react-router-dom';
import rawtree from '@/utils/tree.json'

const amapkey = process.env.REACT_APP_AMAP_KEY
class Amap extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			address: '',
			// 设置坐标点，就会在地图上显示一个 标记点
			markerPosition: { longitude: 120, latitude: 35 },
		}
		// 高德地图 Marker 实例
		this.markerInstance = undefined
		// 高德地图 Map 实例
		this.mapInstance = undefined

		this.amapEvents = {
			created: mapInstance => {
				console.log('高德地图 Map 实例创建成功；如果你要亲自对实例进行操作，可以从这里开始。比如：');
				console.log('缩放级别：', mapInstance.getZoom());
				this.mapInstance = mapInstance

				//eslint-disable-next-line 
				AMap.plugin(['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.CitySearch', 'AMap.Geocoder'], () => {
					// 实例化Autocomplete
					const autoOptions = {
						// city 限定城市，默认全国
						// city: '025',
						// input 为绑定输入提示功能的input的DOM ID
						input: 'amapInput',
					}
					//eslint-disable-next-line 
					const autoComplete = new AMap.Autocomplete(autoOptions);
					// 无需再手动执行search方法，autoComplete会根据传入input对应的DOM动态触发search
					//eslint-disable-next-line 
					const placeSearch = new AMap.PlaceSearch({
						// city: '南京',
						map: mapInstance,
					})
					 

					// 监听下拉框选中事件
					//eslint-disable-next-line 
					AMap.event.addListener(autoComplete, 'select', e => {
						let poi = e.poi
						// TODO 针对选中的poi实现自己的功能
						placeSearch.setCity(poi.adcode)
						placeSearch.search(poi.name)

						this.oncitydata(poi)
						
						
						
					})

					//eslint-disable-next-line 
					const citySearch = new AMap.CitySearch()
					citySearch.getLocalCity((status, result) => {
						if (status === 'complete' && result.info === 'OK') {
							// 查询成功，result即为当前所在城市信息
							if (result && result.city && result.bounds) {
								// 当前城市名称
								// const cityinfo = result.city;

								// 当前城市位置信息
								const citybounds = result.bounds;
								// document.getElementById('info').innerHTML = '您当前所在城市：'+cityinfo;
								// 地图显示当前城市
								mapInstance.setBounds(citybounds);
								// 需要在设置坐标成功后，重新设置 缩放级别
								mapInstance.setZoom(13)
							}
						}
					})
				})

				// 实例点击事件
				mapInstance.on('click', e => {
					const lngLat = `${e.lnglat.getLat()},${e.lnglat.getLng()}`
					//eslint-disable-next-line 
					const geocoder = new AMap.Geocoder({
						radius: 1000, //以已知坐标为中心点，radius为半径，返回范围内兴趣点和道路信息
						extensions: "all"//返回地址描述以及附近兴趣点和道路信息，默认"base"
					})
					geocoder.getAddress(e.lnglat, (status, result) => {
						if (status === 'complete'&&result.regeocode) {
                            let address = result.regeocode.formattedAddress;
							let poi = result.regeocode.addressComponent
							this.props.setaddress({
								address: address,
								latitude: e.lnglat.getLat(),
								longitude: e.lnglat.getLng(),
								district: '',
								provinces: poi.province,
								cities: poi.city,
								description: '',
								district: poi.district,
							})
							if(this.props.getaddressChange.addressDisabled===false){
								this.props.setaddressChange({
									address: address,
									addressDisabled: false,
									latitude: e.lnglat.getLat(),
									longitude: e.lnglat.getLng(),
									provinces: poi.province,
									cities: poi.city,
									description: '',
									district: poi.district,
								})
							}
							else{
								this.props.setaddressChange({
									address: '',
									addressDisabled: true,
									latitude: e.lnglat.getLat(),
									longitude: e.lnglat.getLng(),
									provinces: poi.province,
									cities: poi.city,
									description: '',
									district: poi.district,
								})
							}
                        }
					})

				});
			},
		};
		this.markersEvents = {
			click: (MapsOption, marker) => {
				const extData = marker.getExtData();

			},
		};

	}
	oncitydata=(val)=>{
		let tree = JSON.parse(JSON.stringify(rawtree));
		let cityData = this.searchData(tree,val.adcode)
		this.props.setaddress({
			latitude: val.location.lat,
			longitude: val.location.lng,
			provinces: cityData.length > 0 ? cityData[0].label : cityData[0].label,
			cities: cityData[0].children.length>0 ? cityData[0].children[0].label : '',
			description: val.name,
			district: cityData[0].children[0].children.length>0 ? cityData[0].children[0].children[0].label : '',
			address: val.name
		})
	}
	searchData = (dataArray,value) => {
        let array = [];
        for (let i=0;i< dataArray.length;i++) {
            let _data = dataArray[i]

            if (_data.value!==null && _data.value.indexOf(value) !== -1) {
                array.push(_data);
            } else {
            if (_data.children.length > 0) {
                let childrenNewData = this.searchData(_data.children,value);
                if (childrenNewData.length > 0) {
                    _data.children = childrenNewData;
                    array.push(_data);
                }
            }
           }
        }
        return array;
    }
	onChange = (e) => {
		
		this.props.setaddress({
			address: e.target.value
		})
	}

	render() {
		let { getaddressChange, getaddress } = this.props;
		const position = {
			longitude: getaddressChange.longitude,
			latitude: getaddressChange.latitude
		}//需要定位的经纬度
		return (
			<>
				<div style={{ position: 'relative', width: '100%', height: '260px' }}>
					<Map plugins={['ToolBar']}
						events={this.amapEvents}
						amapkey={amapkey}
						zoom={15}
						center={position}>

						{/* <Marker position={position} events={this.markersEvents} /> */}
					</Map>
					<div className='amapserch'>
						<Input id="amapInput"
							className="model_input"
							value={getaddress.address}
							autoComplete = "off"
							disabled={getaddressChange.addressDisabled}
							placeholder={getaddressChange.address === '' ? '请输入位置地址/或点击选择地址' : getaddressChange.address}
							onChange={this.onChange}
							style={{ width: 536 }} />
					</div>
				</div>
			</>
		)
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setaddress: data => {
		dispatch(setaddress(data))
	},
	setaddressChange: data =>{
		dispatch(setaddressChange(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Amap));
