import * as React from 'react';

function SvgAbbTrend332(props) {
    return (
        <svg viewBox="0 0 32 32" width="32px" height="32px" >
            <defs>
                <style>{'.abb_trend-3_32_svg__cls-2{fill:currentColor}'}</style>
            </defs>
            <path fill="none" d="M0 0h32v32H0z" id="abb_trend-3_32_svg__Box" />
            <g id="abb_trend-3_32_svg__Drive">
                <path className="abb_trend-3_32_svg__cls-2" d="M4 25V4H2v23h28v-2H4z" />
                <path className="abb_trend-3_32_svg__cls-2" d="M6.71 19.71l4.88-4.89a2.45 2.45 0 00.81.18 2.48 2.48 0 001.6-.55l3 3v.08a2.5 2.5 0 104.82-.91l7.89-7.88-1.42-1.45-7.88 7.89a2.44 2.44 0 00-2.45.36l-3-3a2.5 2.5 0 00-5-.18 2.62 2.62 0 00.17 1l-4.84 4.93z" />
            </g>
        </svg>
    );
}

export default SvgAbbTrend332;