import React, { Component } from 'react';
import { Table, message, Spin } from 'antd';
import { customerNew10 } from '@/api/chart.js';
import moment from 'moment';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import NoData from '@/components/NoData'

class Customerlist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			size: 5,
			admin: [],
			pagination: {},
			loading: false,
			modal2Visible: false,
			selectedRowKeys: [],
			columns: [
				{
					title: '认证客户名称',
					dataIndex: 'aliasName',
					key: 'aliasName',
					width: '40%',
					ellipsis: true,
				},
				{
					title: '地址信息',
					dataIndex: 'address',
					key: 'address',
					width: '40%',
					ellipsis: true,
				},
				
				{
					title: '创建时间',
					dataIndex: 'createdOn',
					key: 'createdOn',
					width: '20%',
					ellipsis: true,
					render: createdOn => {
						return (
							moment.parseZone(createdOn).local().format('YYYY-MM-DD')
						);
					}
				},
			]
		};
	}
	componentDidMount() {
		if (this.props.userInfo.organizationType === "CP" || this.props.userInfo.organizationType === "ABB") {
			this.setState({
				columns: this.state.columns,
			})
		}
		this.fetch();
	};
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	fetch = () => {
		this.setState({
			loading: true
		})
		let url = ``
		customerNew10(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data.data;
				let resdataArr = []
				if (resdata.length > 10) {
					for (let i = 0; i < 10; i++) {
						resdataArr.push(resdata[i])
					}
					this.setState({
						admin: resdataArr
					})
				}
				else {
					this.setState({
						admin: resdata
					})
				}
				this.setState({
					loading: false
				})
			}
		}).catch(err => {
			this.setState({
				loading: false
			})
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('客户列表接口获取错误')
			}

		})
	};

	render() {

		return (
			<Spin spinning={this.state.loading} style={{ height: '260px' }}>


				<div style={{ "width": '100%', position: 'relative', minHeight: 260 }} className='org_right Abb_customerlist'>
					{
						this.state.admin.length > 0 ? (
							<Table
								size="small"
								y='true'
								columns={this.state.columns}
								dataSource={this.state.admin}
								pagination={false}
								expandIconAsCell={false}
								expandIconColumnIndex={-1}
								rowKey={(record, index) => index}
							/>
						) : <NoData />
					}

				</div>
			</Spin>
		);
	}
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	// setNewsmodelIsShow: data => {
	// 	dispatch(setNewsmodelIsShow(data))
	// },
	// setRouterLinkIndex: data => {
	// 	dispatch(setRouterLinkIndex(data))
	// }
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Customerlist));
