import React, { Component } from 'react';
import { Modal, message } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { eventfilterDelete } from '@/api/eventfilter.js';

class MigrationModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        };
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodeldelete = () => {
        this.props.hidemodel()
    }
    submitmodel = () => {
        this.props.hidemodel()
    }

    render() {
        const that = this;
        const { showmodel } = this.props;
        const modelProps = {
            title: '1',
            centered: 'true',
            width: 504,
            maskClosable: false,
            visible: showmodel.visible,
            onOk: () => { that.hidemodeldelete() },
            onCancel: () => { that.hidemodeldelete() },
            footer: [
                // <ABB.Button
                //     onClick={() => that.hidemodeldelete()}
                //     type="discreet-black"
                //     sizeClass='small' theme="filled"
                //     text="取消"
                // />,
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        return (
            <Modal
                {...modelProps}
                className="migration-model"
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                {
                    showmodel.number === -1 ? (
                        <>
                            <div className='migration-title migration-title-model'><ABB.Icon style={{ color: '#ff0000' }} name="abb/error-circle-1" sizeClass='small' /><span style={{ marginLeft: '8px' }}>导入失败</span></div>
                            {/* <div className="migration-text">有部分序列号在处理时存在错误，请导出查看。</div> */}
                            <div className="migration-text">请按照网页上的指引通过设备报表查看全部设备迁移状态</div>
                        </>
                    ) : (
                        <>

                            <div className='migration-title migration-title-model'><ABB.Icon style={{ color: '#0ca919' }} name="abb/check-mark-circle-1" sizeClass='small' /><span style={{ marginLeft: '8px' }}>导入成功</span></div>
                            <div className="migration-text">请按照网页上的指引通过设备报表查看全部设备迁移状态</div>
                            {/* <div className="migration-text">已导入 {showmodel.number} 台网关序列号。<br />网关固件升级的状态请通过报表查看。</div> */}
                        </>
                    )
                }
                
                
            </Modal>
        );
    }
}

export default MigrationModel;
