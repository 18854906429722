import React, { Component } from 'react';
import { Menu, Dropdown, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setassetId, setAssetIsPowertrain, deviceDetail, checkAllgen, eventDetaildevice, userCheckDevice, setsubscriptioncof } from '@/redux/actions/device';
import { loading } from '@/redux/actions/setting';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import moment from 'moment';
import { exception } from "@/api/device";
import CustomAlarm from "../../../customalarms/index"
import CbmCustomAlarm from "../../../customalarms/cbmcustomalarms"
import AbilityCustomAlarms from "../../../customalarms/abilitycustomalarms"
import './style.scss'

const div_nowrap = {
    display: 'inline'
}

class DeviceDetailTitle extends Component {
    state = {
        assetId: this.props._deviceDetail.assetId,
        deviceDetails: {},
        subscriptioncof: this.props.getsubscriptioncof,
        exceptiondata: '',
        showModel: { trends: [] },
        showModelAbility: { trends: [] },
        menu: (
            <Menu>
                <Menu.Item onClick={() => this.checkall()}>
                    设备信息
                </Menu.Item>
                {/* <Menu.Item onClick={() => this.ReportList()}>
                    报告列表
                </Menu.Item> */}
                <Menu.Item onClick={() => this.BackupParameters()}>
                    传动参数
                </Menu.Item>
                <Menu.Item onClick={() => this.ToCBM()}>
                    预测性维护
                </Menu.Item>

            </Menu>
        ),
    };
    ToCBM = () => {
        this.props.userCheckDevice(6);
    }
    checkall = () => {
        this.props.checkAllgen('0');
        this.props.eventDetaildevice('1');
        this.props.userCheckDevice(10)
    }
    BackupParameters = () => {

        this.props.userCheckDevice(4)
    }
    ReportList = () => {

        this.props.userCheckDevice(5)
    }
    //设备列表设备类型icon(24)渲染
    assetIcon24 = (data) => {
        var iconName = ""
        if (data.assetType === 3) {
            iconName = "abb/motor"
        } else if (data.assetType === 2) {
            iconName = "abb/drive"
        } else if (data.assetType === 1) {
            iconName = "abb/powertrain"
        }
        return <ABB.Icon className="asset_icon" style={{ margin: '0 8px 0 0' }} name={iconName} sizeClass='medium' />
    }

    devicedetailsMenu = () => {
        this.setState({
            menu: (
                <Menu>
                    <Menu.Item onClick={() => this.checkall()}>
                        <div className="devicemenulist">
                            <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/information-circle-2" sizeClass='small' />
                            <span>设备信息</span>
                        </div>
                    </Menu.Item>
                </Menu>
            )
        })
    }
    ToCustomalarms = () => {
        // if(this.props._deviceDetail.trends.length <= 0 || this.props._deviceDetail.trends === null){
        //     // message.warning("信号时序参数为空，无法设置自定义告警规则");
        // }
        let { _deviceDetail } = this.props;
        if (_deviceDetail.deviceSource === 2) {
            this.setState({
                showModelAbility: {
                    visible: true
                }
            }, () => {
                // console.log("this.props._deviceDetail.trends-----",this.props._deviceDetail.trends)
                this.child1._getAlarms(this.props._deviceDetail.assetId, this.props.getAssetIsPowertrain);
            })
        }
        else {
            this.setState({
                showModel: {
                    visible: true,
                    trends: this.props._deviceDetail.trends
                }
            }, () => {
                // console.log("this.props._deviceDetail.trends-----",this.props._deviceDetail.trends)
                this.child._getAlarms(this.props._deviceDetail.assetId, this.props.getAssetIsPowertrain);
            })
        }

    }
    menulimits = (getsubscriptioncof) => {
        let { _deviceDetail } = this.props;
        let feature = {};
        getsubscriptioncof.map(item => {
            item.config.map(items => {
                if (items.featureCode === 13) {
                    feature.backup = items.limitValue
                }
                if (items.featureCode === 17) {
                    feature.reportlist = items.limitValue
                }
                if (items.featureCode === 22) {
                    feature.cbmpage = items.limitValue
                }
                if (items.featureCode === 3) {
                    feature.CustomAlarm = items.limitValue
                }
            })

        })

        // if (this.props.userInfo.organizationType === "ABB" && this.props.userInfo.isOrgAdministrator === true) {
        this.setState({
            menu: (
                <Menu>
                    <Menu.Item onClick={() => this.checkall()}>
                        <div className="devicemenulist">
                            <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/information-circle-2" sizeClass='small' />
                            <span>设备信息</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item disabled={_deviceDetail.keepDays < 7 ? true : false} style={{ display: feature.reportlist === false ? "none" : "block" }} onClick={() => this.ReportList()}>
                        {
                            _deviceDetail.keepDays < 7 ? (
                                <Tooltip placement="left" title={
                                    <span>此设备添加时间小于7天，暂时无法创建监测报告。</span>
                                }>
                                    <div className="devicemenulist">
                                        <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/reports" sizeClass='small' />
                                        <span>报告列表<span style={{ color: '#36f' }}>(Beta)</span></span>
                                    </div>
                                </Tooltip>
                            ) : (
                                <div className="devicemenulist">
                                    <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/reports" sizeClass='small' />
                                    <span>报告列表<span style={{ color: '#36f' }}>(Beta)</span></span>
                                </div>
                            )
                        }

                    </Menu.Item>
                    <Menu.Item style={{ display: feature.backup === false ? "none" : "block" }} onClick={() => this.BackupParameters()}>

                        <div className="devicemenulist">
                            <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/database" sizeClass='small' />
                            {/* <div style={{ margin: '0px 14px 0 2px' }} className="devicemenulistIcon"></div> */}
                            <span>传动参数</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item style={{ display: feature.cbmpage === false ? "none" : "block" }} onClick={() => this.ToCBM()}>

                        <div className="devicemenulist">
                            <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/bar-chart" sizeClass='small' />
                            <span>预测性维护</span>
                        </div>
                    </Menu.Item>
                    <Menu.Item style={{ display: feature.CustomAlarm === false ? "none" : "block" }} onClick={() => this.ToCustomalarms()}>

                        <div className="devicemenulist">
                            <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/target-value-range" sizeClass='small' />
                            <span>自定义告警</span>
                        </div>
                    </Menu.Item>

                </Menu>
            )
        })
        // }
        // else {
        //     this.setState({
        //         menu: (
        //             <Menu>
        //                 <Menu.Item onClick={() => this.checkall()}>
        //                     <div className="devicemenulist">
        //                         <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/information-circle-2" sizeClass='small' />
        //                         <span>设备信息</span>
        //                     </div>
        //                 </Menu.Item>

        //                 <Menu.Item style={{ display: feature.backup === false ? "none" : "block" }} onClick={() => this.BackupParameters()}>

        //                     <div className="devicemenulist">
        //                         <div style={{ margin: '0px 14px 0 2px' }} className="devicemenulistIcon"></div>
        //                         <span>参数备份</span>
        //                     </div>
        //                 </Menu.Item>
        //                 <Menu.Item style={{ display: feature.cbmpage === false ? "none" : "block" }} onClick={() => this.ToCBM()}>

        //                     <div className="devicemenulist">
        //                         <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/bar-chart" sizeClass='small' />
        //                         <span>预测性维护</span>
        //                     </div>
        //                 </Menu.Item>
        //             </Menu>
        //         )
        //     })
        // }
    }
    //判断组织信息显示
    isShowMenu = (getsubscriptioncof) => {
        let { getassettype, _deviceDetail, userInfo } = this.props;

        if (getassettype === 'Drive' || getassettype === 'PowerTrain' || getassettype === 'noPowerTrain') {
            if (userInfo.organizationType === "ABB") {
                this.menulimits(getsubscriptioncof);
            }
            else {
                if (_deviceDetail.assetType === 2 && _deviceDetail.subscription.subscriptionStatus !== "已过期") {
                    this.menulimits(getsubscriptioncof);
                }
                else {
                    this.setState({
                        menu: (
                            <Menu>
                                <Menu.Item onClick={() => this.checkall()}>
                                    <div className="devicemenulist">
                                        <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/information-circle-2" sizeClass='small' />
                                        <span>设备信息</span>
                                    </div>
                                </Menu.Item>
                            </Menu>
                        )
                    })
                }
            }


        }
        // else if (getassettype === 'PowerTrain') {
        //     if (_deviceDetail.assetType === 2 && _deviceDetail.subscription.subscriptionStatus !== "已过期") {
        //         this.menulimits(getsubscriptioncof);
        //     }
        //     else {
        //         this.setState({
        //             menu: (
        //                 <Menu>
        //                     <Menu.Item onClick={() => this.checkall()}>
        //                         <div className="devicemenulist">
        //                             <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/information-circle-2" sizeClass='small' />
        //                             <span>设备信息</span>
        //                         </div>
        //                     </Menu.Item>
        //                 </Menu>
        //             )
        //         })
        //     }
        // }
        // else if (getassettype === 'noPowerTrain') {

        //     if (_deviceDetail.assetType == 2 && _deviceDetail.subscription.subscriptionStatus !== "已过期") {
        //         this.menulimits(getsubscriptioncof);
        //     }
        //     else {
        //         this.setState({
        //             menu: (
        //                 <Menu>
        //                     <Menu.Item onClick={() => this.checkall()}>
        //                         <div className="devicemenulist">
        //                             <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/information-circle-2" sizeClass='small' />
        //                             <span>设备信息</span>
        //                         </div>
        //                     </Menu.Item>
        //                 </Menu>
        //             )
        //         })
        //     }
        // }
        else {
            this.setState({
                menu: (
                    <Menu>
                        <Menu.Item onClick={() => this.checkall()}>
                            <div className="devicemenulist">
                                <ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/information-circle-2" sizeClass='small' />
                                <span>设备信息</span>
                            </div>
                        </Menu.Item>
                    </Menu>
                )
            })
        }

    }

    componentDidMount() {
        // this.subscriptioncofdata()
        this.isShowMenu(this.state.subscriptioncof);
        this.exceptionFn()
    }
    componentDidUpdate(nextProps, prevState) {
        
        if (prevState.assetId !== this.props._deviceDetail.assetId) {
            console.log("prevState.assetId",prevState.assetId)
			console.log("this.props._deviceDetail.assetId",this.props._deviceDetail.assetId)
            this.setState({
                assetId: this.props._deviceDetail.assetId,
                subscriptioncof: this.props.getsubscriptioncof
            }, () => {

                this.isShowMenu(this.props.getsubscriptioncof);

            })

        }
    }
    exceptionFn = () => {
        let { getAssetIsPowertrain, getassetId, _deviceDetail } = this.props;
        let isSubAsset = ``;
        if (_deviceDetail.assetType === 2) {
            if (getAssetIsPowertrain === true) {
                isSubAsset = `?isSubAsset=true`;
            }
            else {
                isSubAsset = ``
            }
            let url = `${_deviceDetail.assetId}${isSubAsset}`;
            exception(url).then(res => {
                let resdata = res.data;
                this.setState({
                    exceptiondata: resdata
                })
            }).catch(err => {

                if (err.status === 400 || err.status === 404) {
                    message.warning(err.data)
                }
                else {
                    console.log("exceptionFn -- exception --接口异常")
                    message.error('接口异常')
                }
            })
        }

    }
    backupper = () => {
        let { getassetId, UsercheckDeviceDetail } = this.props;
        this.props.deviceDetail({});
        this.props.setAssetIsPowertrain(false)

        this.props.setassetId(this.props.getAssetIdCopy)
        if (UsercheckDeviceDetail === 11) {
            this.props.userCheckDevice(0)
        }
        else {
            this.props.userCheckDevice(2)
            // this.FndeviceDetail(getassetId, 'PowerTrain')
        }

    }
    hidemodel = () => {
        this.setState({
            showModel: {
                visible: false,
                trends: []
            },
            showModelAbility: {
                visible: false,
                trends: []
            }
        })
    }
    onRef = (ref) => {
        this.child = ref
    }
    onRef1 = (ref) => {
        this.child1 = ref
    }
    IsshowComplimentarySubscription = (migrationStatus) => {

        switch (migrationStatus) {
            case 0:
                return (
                    <div className='data-migration'>
                        <div className='migration-blue migration-blue-left'></div>
                        <div className='migration-blue migration-blue-center'></div>
                        <div className='migration-blue migration-blue-right'></div>
                        <div>后台数据调整中</div>
                    </div>
                )
            case 3:
                return (
                    <div className='data-migration-Expired'>
                        <ABB.Icon style={{ 'color': '#F03040', marginRight: "4px" }} name="abb/error-circle-2" sizeClass='small' />
                        <div>数据未做迁移</div>
                    </div>
                )
            default:
                return <></>
        }
    }

    //根据订阅信息,显示订阅版本  :基础版 / 标准版
    _renderSubscriptionStatus = (deviceDetail) => {

        console.log("deviceDetail===============",deviceDetail.subscription  )
        const {subscription} = deviceDetail

        if ("已过期" === subscription.subscriptionStatus) {
            return null
        }

        if ("基础版" === subscription.subscriptionLevel) {
            return <div className='subscribe_type1'>{subscription.subscriptionLevel}</div>
        }

        if ("入门版" === subscription.subscriptionLevel) {
            return <div className='subscribe_type2'>{subscription.subscriptionLevel}</div>
        }
        if ("标准版" === subscription.subscriptionLevel) {
            return <div className='subscribe_type3'>{subscription.subscriptionLevel}</div>
        }
        if ("高级版" === subscription.subscriptionLevel) {
            return <div className='subscribe_type4'>{subscription.subscriptionLevel}</div>
        }
    }

    //设备详情头部信息,包括图标 名称 过期状态 版本  
    _renderHeader = (deviceDetail, isExpired) => {
        let { userInfo } = this.props;
        return (<div className='deviceHeader'>
            {this.assetIcon24(deviceDetail)}
            {deviceDetail.assetName + (isExpired ? '（订阅过期）' : '')}
            {
                this._renderSubscriptionStatus(deviceDetail)
            }
            {
                //临时关闭专家服务
              userInfo.organizationType !== 'ABB' &&  (deviceDetail.expertServiceStatus === 0 ||
                deviceDetail.expertServiceStatus === 3 )? <div className='subscribe_service'>专家服务</div> : null
            }
        </div>)
    }

 


    render() {
        let _self = this;
        const { getAssetlabel, _deviceDetail, UsercheckDeviceDetail, getsubscriptioncof, getAssetStatus } = _self.props;

        let _subscription = _deviceDetail.subscription;
        let { exceptiondata } = this.state;
        let isExpired = false;
        try {
            isExpired = _deviceDetail.isExpired;
        } catch (e) {
            console.log("cannot get subscription status", e);
        }
    
        return (
            <>
                {
                    (_subscription != undefined) ? (
                        <>
                            <div className={isExpired ? 'deviceDetail-title-box_red' : 'deviceDetail-title-box'}>
                                <div className={(_subscription.subscriptionStatus !== "已过期") ? 'assetName' : 'hide'}>

                                    {
                                        // 标题 :包含 图标 设备名称 "基础版" "此设备暂不支持专家服务" 
                                        this._renderHeader(_deviceDetail, isExpired)
                                    }

                                    {
                                        _deviceDetail.showComplimentarySubscriptionTip === true ? (
                                            <Tooltip placement="bottom" title={
                                                <div className='migration-Tooltip'>
                                                    <div className='migration-title'>获赠订阅提醒</div>
                                                    <div className='migration-text'>此设备的网关固件已升级到最新版本，并已获赠1年免费的基础版订阅。此次固件升级涉及后台功能调整。调整期间，此设备历史数据会逐步补充完整。给您带来的不便，敬请谅解！</div>
                                                </div>
                                            } >
                                                {this.IsshowComplimentarySubscription(_deviceDetail.migrationStatus)}
                                            </Tooltip>) : (
                                            <>
                                                {this.IsshowComplimentarySubscription(_deviceDetail.migrationStatus)}
                                            </>
                                        )
                                    }
                                </div>

                                {/* </div> */}

                                <div className={isExpired ? 'assetNameExpired' : 'hide'}>
                                    {
                                        // 标题 :包含 图标 设备名称 "基础版" "此设备暂不支持专家服务"
                                        this._renderHeader(_deviceDetail, isExpired)
                                    }

                                    {
                                        _deviceDetail.showComplimentarySubscriptionTip === true ? (
                                            <Tooltip placement="bottom" title={
                                                <div className='migration-Tooltip'>
                                                    <div className='migration-title'>获赠订阅提醒</div>
                                                    <div className='migration-text'>此设备的网关固件已升级到最新版本，并已获赠1年免费的基础版订阅。此次固件升级涉及后台功能调整。调整期间，此设备历史数据会逐步补充完整。给您带来的不便，敬请谅解！</div>
                                                </div>
                                            } >
                                                {this.IsshowComplimentarySubscription(_deviceDetail.migrationStatus)}
                                            </Tooltip>) : (
                                            <>
                                                {this.IsshowComplimentarySubscription(_deviceDetail.migrationStatus)}
                                            </>
                                        )
                                    }
                                </div>

                                <div
                                    className='device_setting_icon' >
                                    {
                                        exceptiondata !== "" && exceptiondata.isHasException === true ? (
                                            <Tooltip placement="bottom" title={

                                                <>
                                                    {
                                                        exceptiondata.exceptionList.map(item => {
                                                            return (
                                                                <div className="exceptionBox">
                                                                    <div>{item.content}</div>
                                                                    {
                                                                        item.exceptionType === 1 ? (
                                                                            <a style={{ paddingTop: 3 }} href="https://sycfxrsxgy.jiandaoyun.com/sharedoc/2eoXwrPrD2OINgWgwSgWA0" target="_blank" >
                                                                                <ABB.Icon style={{ color: "#1f1f1f", margin: "0 0 0 8px" }} name="abb/help-circle-2" sizeClass='small' />
                                                                            </a>
                                                                        ) : (
                                                                            <a style={{ paddingTop: 3 }} href="https://sycfxrsxgy.jiandaoyun.com/sharedoc/3uHkXETtiKYFO5erK0N6j5" target="_blank" >
                                                                                <ABB.Icon style={{ color: "#1f1f1f", margin: "0 0 0 8px" }} name="abb/help-circle-2" sizeClass='small' />
                                                                            </a>
                                                                        )
                                                                    }

                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </>

                                            } >
                                                <div style={div_nowrap} style={{ marginRight: 14 }}>
                                                    <ABB.Icon style={{ 'color': '#FF7300' }} name="abb/warning-circle-2" sizeClass='medium' />
                                                </div>
                                            </Tooltip>
                                        ) : ''
                                    }


                                    <Dropdown

                                        overlay={this.state.menu}
                                        placement="bottomRight"
                                        trigger={['click']}>
                                        <div>
                                            <ABB.Icon name="abb/menu" sizeClass='medium' style={{ 'color': '#1F1F1F' }} />
                                        </div>
                                    </Dropdown>
                                </div>
                                

                            </div>

                            
                        </>
                    ) : ''
                }

                {
                    UsercheckDeviceDetail === 11 || UsercheckDeviceDetail === 3 ? (
                        <div className="deviceDetail_status_top" style={{ background: '#fff', padding: '8px 0' }}>
                            <div className="EUorg_title_back" >
                                <div className='backup_button' onClick={() => this.backupper()}>
                                    <ABB.Icon name="abb/left" sizeClass='medium' />
                                    {
                                        getAssetlabel.map((item, index) => {
                                            return (
                                                <>
                                                    <span>{item}</span>
                                                    {
                                                        index == (getAssetlabel.length - 1) ? '' : '>'
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </div>


                            </div>
                        </div>
                    ) : ''
                }
                <CbmCustomAlarm
                    showModel={this.state.showModel}
                    hidemodel={this.hidemodel}
                    onRef={this.onRef}
                />
                <AbilityCustomAlarms
                    showModelAbility={this.state.showModelAbility}
                    hidemodel={this.hidemodel}
                    onRef={this.onRef1}
                />


            </>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setassetId: data => {
        dispatch(setassetId(data));
    },
    setAssetIsPowertrain: data => {
        dispatch(setAssetIsPowertrain(data));
    },
    setsubscriptioncof: data => {
        dispatch(setsubscriptioncof(data));
    },
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    loading: data => {
        dispatch(loading(data))
    },
   

});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(DeviceDetailTitle));

