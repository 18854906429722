import * as actionTypes from '../constants/index';
const _getCMDdata = [];

const getCMDdata = (state = _getCMDdata, action) => {
	switch (action.type) {
		case actionTypes.CMD_DATA:
			return action.data;
		default:
			return state;
	}
};

export { getCMDdata };
