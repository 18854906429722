import React from 'react';
import {
    Form,
    Select,
    Modal,
    Col,
    Row,
    message
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class LifeSetting extends React.Component {
    state = {
        value: 0,
        devicelist: [],
        templist: [],
        templatetype: 1,
        yeardata: []
    };
    componentDidMount() {
        var DateYearstart = new Date();
        var startyear = DateYearstart.getFullYear();
        let yeardata = [];
        for (let i = startyear - 15; i <= startyear; i++) {
            yeardata.push(i);
        }
        this.setState({
            yeardata: yeardata.reverse()
        })
    }

    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.props.form.resetFields();
    }

    submitmodel = () => {

        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                let date = fieldsValue.year + "-" + fieldsValue.month + "-01"
                if(Date.parse(date) > Date.parse(new Date())){
                    message.warning("启动日期大于当前的日期")
                    return false;
                }
                this.props.submitmodel(date);
                setTimeout(()=>{
                    this.props.form.resetFields();
                },1000)
                
                // if (props.isUpdateform === true) {
                //     this.props.form.resetFields();
                // }
            }
        });
    }
    render() {
        let that = this;
        let { lifedata, isUpdateform } = that.props;
        const { getFieldDecorator } = that.props.form;

        return (
            <>
                <Modal
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                    title="寿命维护设置"
                    centered
                    width={504}
                    visible={lifedata.visible}
                    onOk={() => that.hidemodel(false)}
                    onCancel={() => that.hidemodel(false)}
                    footer={[
                        <ABB.Button
                            onClick={() => that.hidemodel(false)}
                            type="discreet-black"
                            sizeClass='small' theme="filled"
                            text="取消"
                        />,
                        <ABB.Button
                            onClick={() => that.submitmodel()}
                            type="primary-blue"
                            sizeClass='small' theme="filled"
                            text="应用"
                        />,
                    ]}

                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <div className="lifesetting_text">当您更换元件时，元件寿命应从更换之日起重新计算。</div>
                            <div className="lifesetting_text">当系统无法自动获取元件启用日期时，您可自行选择元件启用的日期。</div>
                            <div className="lifesetting_text">部件启用日期：{lifedata.cbmunitdata.startDate}</div>
                            <div className="lifesetting_text" style={{fontSize: 12}}>重新选择部件启用日期后，将会在明天生效</div>
                        </Col>
                    </Row>


                    <Form >
                        <Row gutter={24}>
                            
                            <Col span={12}>
                                <Form.Item label="日期范围">
                                    {getFieldDecorator('year', {
                                        initialValue: lifedata.cbmunitdata.year,
                                        rules: [
                                            { required: true, message: '请选择' },
                                        ],
                                    })(
                                        <Select placeholder="年"
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }
                                           

                                        >
                                            {

                                                this.state.yeardata.map((item, index) => {
                                                    return (
                                                        <Select.Option value={item} key={index}>{item}</Select.Option>
                                                    )
                                                })
                                            }

                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item >
                                    {getFieldDecorator('month', {
                                        initialValue: lifedata.cbmunitdata.month,
                                        rules: [
                                            { required: true, message: '请选择月份' },
                                        ],
                                    })(
                                        <Select placeholder="月"
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }
                                            style={{ marginTop: 25 }}
                                        >

                                            <Select.Option value={1}>一月</Select.Option>
                                            <Select.Option value={2}>二月</Select.Option>
                                            <Select.Option value={3}>三月</Select.Option>
                                            <Select.Option value={4}>四月</Select.Option>
                                            <Select.Option value={5}>五月</Select.Option>
                                            <Select.Option value={6}>六月</Select.Option>
                                            <Select.Option value={7}>七月</Select.Option>
                                            <Select.Option value={8}>八月</Select.Option>
                                            <Select.Option value={9}>九月</Select.Option>
                                            <Select.Option value={10}>十月</Select.Option>
                                            <Select.Option value={11}>十一月</Select.Option>
                                            <Select.Option value={12}>十二月</Select.Option>

                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>
                </Modal>
            </>

        );
    }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(LifeSetting);

export default WrappedRegistrationForm;