import React, { Component } from 'react'
import Lifestatus from "./components/lifestatus";
import Predictive from "./components/PredictiveProtection/index";
import './style.scss';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { userCheckDevice, deviceDetail,checkAllgen, eventDetaildevice } from '@/redux/actions/device';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setCBMdata, setCBMtype } from '@/redux/actions/cbm'

class CBM extends Component {
    backuper =()=>{
        let { getassettype,getRouterLinkIndex } = this.props;

        // console.log("getassettype 12",getassettype);
        this.props.setCBMtype("fan");
        if (getassettype === "PowerTrain") {
            if(getRouterLinkIndex === 2){
                this.props.userCheckDevice(11)
            }else{
                this.props.userCheckDevice(3)
            }
            
        }
		else if(getassettype === "noPowerTrain"){
			this.props.userCheckDevice(11)
		}
		else {
			this.props.userCheckDevice(0);
		}
        this.props.eventDetaildevice('0')
        this.props.checkAllgen('0')
    }
    render() {
        return (
            <div>
                <div className="life_back" >
                    <div className='backup_button' onClick={() => this.backuper()}>
                    <ABB.Icon name="abb/left" sizeClass='medium' />预测性维护
                    </div>
                </div>

                    <div className='lifebox' style={{backgroundColor:'#fff'}}>
                        {/* <div className="lifestatus_title">寿命状态：</div> */}
                        <Lifestatus />
                    </div>
                {/* <Lifestatus/> */}
                <Predictive />
            </div>
        )
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deviceDetail: data => {
        dispatch(deviceDetail(data));
    },
    userCheckDevice: data => {
        dispatch(userCheckDevice(data))
    },
    setCBMtype: data => {
        dispatch(setCBMtype(data))
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    }

});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(CBM));
