import React, { Component } from 'react';
import { Input, Table, message, InputNumber } from 'antd';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class PageComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageIndex: 1,
            searchValue: '',
            isFirstPage: true,
            isLastPage: false
        };
        this.setIsLast.bind(this)
    }

    previousPage = () => {
        console.log("上一页")
        let { pageIndex, isFirstPage } = this.state;

        if (isFirstPage) {
            message.warning("当前已经是首页了")
            return
        }


        if (pageIndex === '') {
            message.warning("页码不能为空")
            return
        }

        if (pageIndex - 1 === 1) { //第一页
            this.setState({
                pageIndex: pageIndex - 1,
                isFirstPage: true,
                isLastPage: false
            },()=>{
                const {confirmInputValue} = this.props;
                confirmInputValue(this.state.pageIndex);
            })
        } else {
            this.setState({
                pageIndex: pageIndex - 1,
                isLastPage: false
            },()=>{
                const {confirmInputValue} = this.props;
                confirmInputValue(this.state.pageIndex);
            })
        }


        // this.fetch(Number(pageIndex) - 1)
    }
    nextPage = () => {
        console.log("下一页")
        let { pageSize, loglist, pageIndex, isLastPage } = this.state;

        if (isLastPage) {
            message.warning("当前已经是最后一页了")
            return
        }
        if (pageIndex === '') {
            message.warning("页码不能为空")
            return
        }
        this.setState({
            pageIndex: pageIndex + 1,
            isFirstPage: false
        },()=>{
            const {confirmInputValue} = this.props;
            confirmInputValue(this.state.pageIndex);
        })

        // this.fetch(Number(pageIndex) + 1)
    }
    onChange = (e) => {

        let value = e.target.value;
        console.log("e===================",value);
        const reg = /[^(\-)0-9]/g;
        if (reg.test(value)) {
        	value = value.replace(reg, '')

        	message.warning("只能输入数字")
         
        } else {
          
            if (value === '') {
                this.setState({
                    pageIndex:value,
                    isFirstPage:true,
                    isLastPage:true
                })
            } else if (value === '1') {
                this.setState({
                    pageIndex:Number(value),
                    isFirstPage:true,
                    isLastPage:false
                })
            } else {
                this.setState({
                    pageIndex:Number(value),
                    isFirstPage:false,
                    isLastPage:false
                })
            }
        }
 

    }
    onPressEnter = (e) => {
        // this.fetch(this.state.pageIndex)
        console.log("----------------",e.target.value)
        const {confirmInputValue} = this.props;
         
        let value = e.target.value;
        if (value === '') {
            message.warning("页码不能为空")
            return
        }  
        confirmInputValue(this.state.pageIndex);
    }
    pageIndexFn = (pageIndex) =>{
        if(pageIndex === 1) {
            this.setState({
                pageIndex: pageIndex,
                isFirstPage:true
            })
        } else {
            this.setState({
                pageIndex: pageIndex,
                isFirstPage: false
            })
        }
       
    }
    setIsLast = (islast) =>{

        let that = this;
        // console.log('e=========================================================',islast)
        that.setState({
            isLastPage:islast
        },()=>{
            // console.log("e========================================================",that.state)
        })
      
    }
    render() {
        let { pageIndex, isFirstPage, isLastPage } = this.state;
        
        return (
            <>


                <div style={{ marginTop: '12px', background: "#fff", height: '40px', width: '100%', display: "flex", justifyContent: 'right' }}>
                    <div style={{ marginRight: '12px', width: '180px', background: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
                        <div onClick={() => this.previousPage()}>
                            <ABB.Icon style={{ color: isFirstPage ? '#d3cece' : '#1F1F1F' }} name="abb/left" sizeClass='small' />
                        </div>
                        <div><Input
                            value={pageIndex}
                            placeholder="请输入"
                            onChange={this.onChange}
                            className='model_input'
                            onPressEnter={this.onPressEnter}
                            style={{ width: "80px", border: '1px solid #bababa' }} /></div>
                        <div onClick={() => this.nextPage()}>
                            <ABB.Icon style={{ color: isLastPage ? '#d3cece' : '#1F1F1F' }} name="abb/right" sizeClass='small' />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PageComponent;
