import React, { Component } from 'react';
import {
    Form,
    Modal,
    Input,
    message,
    Col,
    Row,
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { cancelPermission } from '@/api/user'

class CancelFrom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: true,
            dataSource: []
        };
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }

    hidemodel = () => {
        this.props.hidemodel()
        this.setState({
            inputval: ''
        })
    }
    submitmodel = (data) => {
        // let url = "?euId="+data.euId+"&cpId="+data.cpId
        let url = {
            euId: data.euId,
            cpId: data.cpId
        }

        cancelPermission(url).then(res => {
            if (res.data.code === 200) {
                message.success("取消授权成功")
                this.props.showmodelCancelAuthFn()
                this.Fnorgtree()
            }
            else{
                message.warning(res.data.errorMessage)
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('取消授权失败！')
            }

        })
        
    }
    onChange = (e) => {

    }
    //获取搜索用户框内容
    onChangeSerch = (e) => {
        this.setState({
            inputval: e
        })
    }
    render() {
        const that = this;
        const { showmodelCancelAuth } = this.props;
        const { getFieldDecorator } = that.props.form;
        const modelProps = {
            title: "取消授权",
            centered: true,
            width: 504,
            visible: showmodelCancelAuth.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="我再想想"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelCancelAuth)}
                    type="primary-blue"
                    style={{
                        background: '#FF000F',
                        border: '1px solid #FF000F'
                    }}
                    sizeClass='small' theme="filled"
                    text="确认取消"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div className='tranfer-title'>取消授权后将不能继续享受当前服务商提供的设备管理服务，确定取消对当前服务商的授权吗？</div>
            </Modal>
        );
    }
}
const CancelAuth = Form.create({ name: 'register' })(CancelFrom);
export default CancelAuth; 
