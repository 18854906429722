import React from 'react';
import { Modal, message,} from 'antd';
import { applyHistoryData } from '@/api/trends';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class ApplyHistoryDataFrom extends React.Component {
    state = {
        disabled: false
    };

    hidemodel = () => {
        this.props.hidemodel()
    }
    
    submitmodel = ( deviceDetail,isSubAsset) => {
       console.log("申请查看更多数据==========")
       let requestParams = {
        id:deviceDetail.assetId,
        isSubAsset:isSubAsset
       }
       applyHistoryData(requestParams).then((res) => {
 
            if (res !== undefined) {
                message.success('申请成功！')
                this.props.hidemodel()
            } else {
                message.error('申请同步更多的历史数据失败')
            }
        }).catch(err => {
            let errorMessage =  ""
            if (err.status === 400 || err.status === 404) {
                errorMessage = err.data 
            }
            errorMessage = errorMessage.length > 0 ? errorMessage : "网络请求失败,请稍后重试"
           message.error(errorMessage)
        })
    }

    render() {
        let that = this;
        const {visible,endTime,deviceDetail,isSubAsset} = that.props.showmodelboundsubConfig;
 
        const modelProps = {
            title: '历史数据',
            centered: true,
            width: 504,
            maskClosable: false,
            visible: visible,
           
            onOk: () => { that.hidemodel() },
            onCancel: () => { that.hidemodel() },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel()}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    disabled={this.state.disabled}
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(deviceDetail,isSubAsset)}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="立即申请"
                />,
            ]
        }

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium'  />
                    } >
                    <div className='applyRight_box'>
                        <div  className='sub_Title'>系统当前可查看<span className='sub_blueTitle'>{endTime}</span>之后的设备监测数据，如需查看更多的历史数据，可点击“<span className='sub_blueTitle'>立即申请</span>”请求ABB管理员同步更多的历史数据。</div>
                    </div>
                </Modal>
            </>

        );
    }
}

export default ApplyHistoryDataFrom;
