import React, { Component } from 'react';
import GetJson from "@/utils/link.js"

const footerstyle = {
	minWidth: '1171px',
	textAlign: 'center',
	height: '27px',
	borderTop: '1px solid #BABABA',
	fontSize: '12px',
	fontFamily: 'ABBvoiceCNSG-Regular',
	fontWeight: '400',
	color: '#686868',
	opacity: '0.8',
}
class BottomFooter extends Component {
	state = {
		nowYear: ''
	}
	componentDidMount() {
		let date = new Date();
		let nowYear = date.getFullYear();
		this.setState({
			nowYear: nowYear
		})
	}
	render() {

		return (
			<div style={footerstyle}>
				© Copyright {this.state.nowYear} ABB
				|  <a target="_blank" href="http://beian.miit.gov.cn/">京ICP备13053479号-2</a> | <a target="_blank" href="http://www.beian.gov.cn/portal/index">京公网安备 11010502037293号</a> | 版本：{GetJson.version}
			</div>
		);
	}
}
export default BottomFooter;
