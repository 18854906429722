import React, { Component } from 'react';
import { Modal } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

class RemoweModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputval: ''
		};
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    hidemodeldelete=()=>{
        this.props.hidemodeldelete()
    }
    submitmodel=()=>{
        this.props.submitmodel()
    }
    
	render() {
        const that = this;
        const {showmodelRemove,disabled} = this.props;
        const modelProps = {
            title: '删除设备',
            centered: 'true',
            width:'504',
            maskClosable: false,
            visible:showmodelRemove.visible,
            onOk:() => {that.hidemodeldelete()},
            onCancel:() => {that.hidemodeldelete()},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodeldelete()}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    disabled={disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,        
            ]
        }
		return (
			<Modal 
            {...modelProps} 
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                <div className="remove_model">删除成功后当前设备将接收推送消息，确定删除当前设备？</div>
            </Modal>
		);
	}
}

export default RemoweModel;
