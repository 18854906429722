//添加设备有订阅弹框

import React, { Component } from 'react';

import { unUseSubscriptionRequest, addDeviceRequest } from '@/api/device.js';


import { connect } from 'react-redux';
import { setplant } from '@/redux/actions/plant';
import { withRouter } from 'react-router-dom';
import {
    Form,
    Modal,
    message,
    Spin,
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import Addplant from '../../plant/components/addplant'
import AddDeviceProcess from './addDeviceProcess';

class AddDeviceSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            loading: false
        };
    }

    //暂不使用订阅,此接口调用后,service不保存此次添加设备信息,仅记录暂不使用行为
    unUseSubscription = () => {
        if (this.state.loading === true) {
            return
        }
        message.destroy()
        message.warning("添加失败，原因：未绑定订阅")
        unUseSubscriptionRequest(this.props.showmodel.data).then((res) => { }).catch(err => { })
        this.props.hidemodel()
    }

    //体验版订阅点击

    experienceClick = () => {
        let params = { ...this.props.showmodel.data, ...{ isApplyTasteSub: true, subscription: {} } }
        this.submitmodel(params)
    }

    //完成按钮点击
    doneClick = () => {
        this.submitmodel(this.props.showmodel.data)
    }

    //点击完成,添加设备
    submitmodel = (params) => {

        this.setState({
            disabled: true,
            loading: true
        })
        addDeviceRequest(params).then((res) => {
            this.setState({
                disabled: false,
                loading: false
            })

            if (res !== undefined) {
                message.destroy()
                message.success('设备添加成功！')
                this.props.hidemodel()
                this.props.submitmodel()
                return
            } else {
                message.destroy()
                message.error('添加失败，请联系客服或稍后再试')
            }
        }).catch(err => {
            this.setState({
                disabled: false,
                loading: false
            })

            if (err.hasOwnProperty("data")) {

                console.log('1---===', err)

                let errorData = err.data

                if (errorData.hasOwnProperty("code")) {
                    let errorCode = errorData.code
                    if (errorCode === 400 || errorCode === 404) {
                        message.warning(errorData.errorMessage)
                        return
                    }
                    // message.error('添加失败，请联系客服或稍后再试')
                    // return
                }
                message.destroy()
                message.error('添加失败，请联系客服或稍后再试')
                return

                // if (errorData.hasOwnProperty("status")) {
                //     let errorCode = errorData.status
                //     if (errorCode === 400 || errorCode === 404) {
                //         message.warning(errorData.title)
                //         return
                //     }
                //     message.error('添加失败，请联系客服或稍后再试')
                //     return
                // }

            } else {
                message.error('添加失败，请联系客服或稍后再试')
            }



        })
    }

    //绑定其他订阅
    _bindOtherSubscription = () => {
        this.props.bindOtherSubscription()
    }

    _renderFooterButton = (isBindable, data) => {


        const that = this;
        const { disabled } = that.state;


        if (data.isHasSub === false) {
            return <div className='bindsub-box'>

                {/* <ABB.Button
                    onClick={() => that.experienceClick()}
                    type="discreet-blue"
                    disabled={disabled}
                    sizeClass='small' theme="filled"
                    text="申请体验版订阅"
                /> */}
                {
                    disabled ? (
                        <div className="bindsub-disabled">申请体验版订阅</div>
                    ) : (
                        <div onClick={() => that.experienceClick()}>申请体验版订阅</div>
                    )
                }
                <ABB.Button
                    onClick={() => that._bindOtherSubscription()}
                    type="primary-blue"
                    disabled={disabled}
                    sizeClass='small' theme="filled"
                    text="绑定订阅"
                />
                {/* type: 'normal' | 'primary-blue' | 'primary-black' | 'primary-red' | 'ghost' | 'discreet-blue' | 'discreet-black'; */}
            </div>
        }


        if (isBindable === true) {
            return <div>
                <ABB.Button
                    onClick={() => that.unUseSubscription()}
                    type="ghost"
                    disabled={disabled}
                    sizeClass='small' theme="filled"
                    text="暂不使用"
                />
                <ABB.Button
                    onClick={() => that.doneClick()}
                    type="primary-blue"
                    disabled={disabled}
                    sizeClass='small' theme="filled"
                    text="完成"
                />
            </div>
        } else {
            return <div className='bindsub-box'>
                {/* <ABB.Button
                    onClick={() => that.experienceClick()}
                    type="discreet-blue"
                    disabled={disabled}
                    sizeClass='small' theme="filled"
                    text="申请体验版订阅"
                /> */}
                {
                    disabled ? (
                        <div className="bindsub-disabled">申请体验版订阅</div>
                    ) : (
                        <div onClick={() => that.experienceClick()}>申请体验版订阅</div>
                    )
                }
                <ABB.Button
                    onClick={() => that._bindOtherSubscription()}
                    type="primary-blue"
                    disabled={disabled}
                    sizeClass='small' theme="filled"
                    text="绑定订阅"
                />
            </div>
        }

    }

    //返回设备订阅详细信息
    _renderSubscriptionDetail = (data) => {
        console.log("subscription-----------", data)


        if (data.isHasSub === true) {
            const { subscription } = data
            if (subscription.isBindable === true) {
                return (
                    <div className='subscription_data'>
                        <div>订阅等级:<span>{subscription.subscriptionLevel !== null ? subscription.subscriptionLevel : '--'}</span></div>
                        <div>订阅类型:<span>{subscription.subscriptionType !== null ? subscription.subscriptionType : '--'}</span></div>
                        <div>订阅有效期:<span>{subscription.validPeriodTime !== null ? subscription.validPeriodTime : '--'}</span></div>
                    </div>
                )
            }

            return (
                <div className='subscription_unbindable'>
                    {/* 当前传动序列号与设备序列号不匹配，此网关仅可用于{subscription.deviceSerialNum}的传动时方可使用订阅额度。 */}
                    当前网关订阅额度已在设备{subscription.deviceSerialNum}上使用，请绑定订阅或申请体验版订阅(30天)
                </div>
            )
        }

        if (data.isHasSub === false) {
            return (
                <div className='subscription_notavailable_bindable'>
                    暂无可使用的设备订阅，请绑定订阅或选择申请体验版订阅<span style={{ color: '#686868', fontFamily: 'ABBvoiceCNSG-Regular' }}>(仅可使用30天)</span>
                </div>
            )
        }


    }


    render() {
        const that = this;
        const { showmodel } = that.props;
        const { disabled } = that.state;

        const subscription = showmodel.data.subscription


        const modelProps = {
            title: showmodel.title,
            centered: showmodel.centered,
            width: showmodel.width,
            visible: showmodel.visible,
            maskClosable: false,
            onOk: () => { that.unUseSubscription() },
            onCancel: () => { that.unUseSubscription() },
            footer: [

                <div className='addDeviceUnSubscription_footer_content'>
                    <div className="sub_bottom_content">
                        <ABB.Icon style={{ cursor: "auto" }} name="abb/warning-triangle" sizeClass='small' color='#F03040' />
                        <div className='sub_bottom_title'>点击“关闭”按钮后填写的信息将无法保存</div>
                    </div>
                    {
                        this._renderFooterButton(subscription.isBindable, showmodel.data)
                    }


                </div>

            ]
        }



        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <Spin spinning={this.state.loading} style={{ minHeight: '50vh' }}>
                        <AddDeviceProcess></AddDeviceProcess>
                        <div className='addDeviceUnSubscription_content'>

                            <div className='subscription_title'>设备订阅</div>

                            {
                                this._renderSubscriptionDetail(showmodel.data)

                            }


                        </div>
                    </Spin>
                </Modal>
            </>
        );
    }
}
const WrappedRegistrationForm = Form.create({ name: 'register' })(AddDeviceSubscription);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setplant: data => {
        dispatch(setplant(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WrappedRegistrationForm));

