import React, { Component } from 'react';
import { Select, DatePicker, Icon, message, Spin, Input, Checkbox, Row, Col, Collapse, } from 'antd';
import { connect } from 'react-redux';
import { checkAllgen, deviceDetail, eventDetaildevice, setevent, userCheckDevice } from '@/redux/actions/device';
import { withRouter } from 'react-router-dom';
import EventModel from '@/components/eventcomponent/eventModel';
import { geteventDetail, closeevent } from '@/api/device.js';
import { eventDetailData, chartConfig, eventlistfilter,easemobim_parameter} from '@/api/event.js'
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import NoData from '@/components/NoData'
import { setEventDetail, setEventDetailStatus, setEventData } from '@/redux/actions/event';
import { timeConfig } from "@/utils/index";
import EeventTable from './components/eventTable';

const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY/MM/DD HH:mm:ss';

const event_list_box = {
	margin: '10px 0 0 0'
}

class Eventlist extends Component {


	constructor(props) {
		super(props);

		this.state = {
			eventName: this.props.getEventDetailStatus.eventName,
			forEventRecord: this.props.getEventDetailStatus.forEventRecord,
			forComment: this.props.getEventDetailStatus.forComment,
			group: this.props.getEventDetailStatus.group,
			assetId: this.props._deviceDetail.assetId,
			startime: this.props.getEventDetailStatus.startime,
			endtime: this.props.getEventDetailStatus.endtime,
			assetEventid: '',
			pageindex: this.props.getEventDetailStatus.page,
			pageSize: 12,
			showmodel: {},
			EventType: this.props.getEventDetailStatus.EventType,
			feature: '',
			loading: false,
			loadingTip: '加载中...',
			eventtypelist: [],
			eventListGroupData: [{ content: '', data: [] }],
			eventlistGroupCopy: [],
			defaultActiveKey: this.props.getEventDetailStatus.defaultActiveKey,
			refkey: moment().valueOf()
		};
	}

	getEventDetailFn = (data) => {
		let url = `${data.id}?userId=${this.props.userInfo.userId}`;
		eventDetailData(url).then(res => {
			if (res.data.code === 200) {
				this.getTrendConfig(res.data.data)
			}

		})
	}
	getTrendConfig = (data) => {
		// console.log("this.props.getEventDetail----",data)
		let url = "?eventId=" + data.eventId
		chartConfig(url).then(res => {
			// console.log("chartConfigchartConfig---",res.data.data)
			this.props.setEventDetail({ ...data, trendconfig: res.data.data });

		})
	}
	eventlogger = (data) => {
		let { pageindex, EventType, startime, endtime, eventName, forComment, forEventRecord, group, defaultActiveKey } = this.state;
		this.props.setEventDetail({})
		this.props.userCheckDevice(8);
		this.props.eventDetaildevice('2');
		this.getEventDetailFn(data)

		this.props.setEventDetailStatus({
			startime: startime,
			endtime: endtime,
			page: pageindex,
			EventType: EventType,
			eventName: eventName,
			forComment: forComment,
			forEventRecord: forEventRecord,
			group: group,
			defaultActiveKey: defaultActiveKey
		})
	}

	componentDidMount() {
		this.props.setevent([])
		let { getsubscriptioncof, _deviceDetail, getEventDetailStatus } = this.props;
		let { pageindex, EventType } = this.state;
		let feature = {}
		getsubscriptioncof.map(item => {
			item.config.map(items => {
				if (items.featureCode === 6) {
					let stringResult = items.limitValue.split(',');
					let arr = [
						{
							name: "全部类型",
							id: 0
						}
					];
					stringResult.map(itemarr => {
						if (itemarr === "1") {
							let obj1 = {
								name: "故障",
								id: 1
							}
							arr.push(obj1)
						}
						if (itemarr === "2") {
							let obj2 = {
								name: "告警",
								id: 2
							}
							arr.push(obj2)
						}
						if (itemarr === "3") {
							let obj2 = {
								name: "通知",
								id: 3
							}
							arr.push(obj2)
						}
						if (itemarr === "8") {
							let obj2 = {
								name: "自定义",
								id: 8
							}
							arr.push(obj2)
						}
					})
					feature.eventtype = arr;
				}
				if (items.featureCode === 12) {
					feature.eventtime = items.limitValue
				}
			})

		})
		if (getEventDetailStatus.startime === "") {
			let _startime = moment(moment().subtract(29, 'day')).format("YYYY-MM-DD") + " 00:00:00"
			let _endtime = moment(new Date()).format("YYYY-MM-DD") + " 23:59:59"
			let currentUTCTimeString = moment(_endtime).utc().format("YYYY-MM-DD HH:mm:ss")
			let dateUTCTimeString = moment(_startime).utc().format("YYYY-MM-DD HH:mm:ss");

			if (Date.parse(_deviceDetail.createdOn) > Date.parse(dateUTCTimeString)) {
				dateUTCTimeString = moment(_deviceDetail.createdOn).format("YYYY-MM-DD HH:mm:ss")
			}

			this.props.setEventDetailStatus({
				startime: dateUTCTimeString,
				endtime: currentUTCTimeString,
				page: 1,
				EventType: this.state.EventType,
				eventName: this.state.eventName,
				forComment: this.state.forComment,
				forEventRecord: this.state.forEventRecord,
				group: this.state.group,
				defaultActiveKey: this.state.defaultActiveKey
			})

			this.setState({
				feature: feature,
				eventtypelist: feature.eventtype,
				startime: dateUTCTimeString,
				endtime: currentUTCTimeString,
			}, () => {
				this.Fneventlist(pageindex, EventType)
			})
		}
		else {
			this.setState({
				feature: feature,
				eventtypelist: feature.eventtype,

			}, () => {
				this.Fneventlist(pageindex, EventType)
			})
		}
	}

	returendevice = () => {
		this.props.setEventDetailStatus({
			startime: '',
			endtime: '',
			page: 1,
			EventType: 0,
			eventName: '',
			forComment: true,
			forEventRecord: false,
			group: false,
			defaultActiveKey: []
		})
		let { getassettype, getRouterLinkIndex } = this.props;
		if (getassettype === "PowerTrain") {
			if (getRouterLinkIndex === 2) {
				this.props.userCheckDevice(11)
			} else {
				this.props.userCheckDevice(3)
			}
		} else if (getassettype === "noPowerTrain") {
			this.props.userCheckDevice(11)
		} else {
			this.props.userCheckDevice(0);
		}
		this.props.eventDetaildevice('0')
		this.props.checkAllgen('0')
	}

	//获取事件list
	Fneventlist(pageindex, EventType) {
		this.setState({
			loading: true
		})
		let that = this;

		let { eventName, startime, endtime, forEventRecord, forComment, pageSize, loadingTip } = that.state;
		const { _deviceDetail, getassetId, getAssetIsPowertrain, } = that.props;
		let assetId = _deviceDetail.assetId;
		let isSubAsset = ''
		const datatime = {
			startime: startime,
			endtime: endtime
		}

		if (Date.parse(datatime.endtime) - Date.parse(datatime.startime) > 30 * 24 * 3600 * 1000) {
			this.setState({
				loadingTip: '当前选择的查询时间区间超过30天，尝试数据加载中，请稍候...'
			})
		}
		else {
			this.setState({
				loadingTip: '加载中...'
			})
		}
		if (getAssetIsPowertrain === true) {
			isSubAsset = `&isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}

		let url = `${assetId}/events/all?startTime=${datatime.startime}&endTime=${datatime.endtime}${isSubAsset}`
		eventlistfilter(url).then((res) => {
			if (res !== undefined) {
				let resdata = res.data;
				if (resdata.data !== null && resdata.data.length > 0) {

				}
				resdata.data.forEach((item, index) => {
					//临时代码,模拟设备状态
					// item.deviceWorkStatus = index % 4
					if (item.eventCode === '' || item.eventCode === null) {
						item.eventCode = '--'
					}
					if (item.deviceWorkStatus === 1) {
						item.deviceWorkStatusval = '运行'
					}
					else if (item.deviceWorkStatus === 2) {
						item.deviceWorkStatusval = '待机'
					}
					else if (item.deviceWorkStatus === 3) {
						item.deviceWorkStatusval = '计划停机'
					}
					else if (item.deviceWorkStatus === 4) {
						item.deviceWorkStatusval = '非计划停机'
					}
					else if (item.deviceWorkStatus === 5) {
						item.deviceWorkStatusval = '未知'
					}
					else {
						item.deviceWorkStatusval = '没有'
					}
				})
				that.props.setEventData(resdata.data)
				this.eventDatafilter()

				that.setState({
					loading: false,
					pageindex: pageindex
				})
			}
		}).catch(err => {
			that.setState({
				loading: false
			})
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('查询的事件数据量过大，数据加载超时。请缩短查询时间区间后，重新尝试。')
			}
		})
	}


	filterType = (person) => {

		//筛选事件描述,   事件类型     仅包含事件记录  	   未处理
		let { eventName, EventType, forEventRecord, forComment } = this.state;


		console.log('筛选条件===============', eventName, "---", EventType, "---", forEventRecord, "---", forComment, "---")

		const eventTypeMap = {
			"Fault": 1,
			"Warning": 2,
			"Notification": 3,
			"Custom": 8

		}

		if (EventType !== 0) {
			if (eventTypeMap[person.assetEventType] !== EventType) {
				return false
			}
		}
		if (forComment && person.assetEventStatus !== "Open") {
			return false
		}
		if (forEventRecord && person.isHasEventLogger === false) {
			return false
		}

		if (eventName === '') {
			return true
		} else {

			if (person.content.toLowerCase().indexOf(eventName.toLowerCase().trim()) === -1) {
				return false
			}
		}

		return true
	};
	eventOnChange = (e) => {

		this.setState({
			pageindex: 1,
			EventType: e
		}, () => {

			this.props.setEventDetailStatus({
				startime: this.state.startime,
				endtime: this.state.endtime,
				page: 1,
				EventType: e,
				eventName: this.state.eventName,
				forComment: this.state.forComment,
				forEventRecord: this.state.forEventRecord,
				group: this.state.group,
				defaultActiveKey: this.state.defaultActiveKey
			})
			this.eventDatafilter()
		})
	}
	eventDatafilter = () => {
		let { getEventData } = this.props;
		let { pageindex, EventType, startime, endtime, eventName, forComment, forEventRecord, group, defaultActiveKey } = this.state;
		this.props.setEventDetailStatus({
			startime: startime,
			endtime: endtime,
			page: pageindex,
			EventType: EventType,
			eventName: eventName,
			forComment: forComment,
			forEventRecord: forEventRecord,
			group: group,
			defaultActiveKey: defaultActiveKey,
			refkey:moment().valueOf()
		})
		let newEvent = [...getEventData]
		// console.log("getEventData--------------",getEventData)
		const filteredAllPersons = newEvent.filter(this.filterType)
		// console.log("filteredAllPersons-11111111-----", filteredAllPersons)
		if (group === true) {
			this.sortArr(filteredAllPersons, "content")
			// console.log("sortArr----")
		}
		else {
			this.props.setevent(filteredAllPersons);
			// console.log("setevent----")


		}
	}

	//综合说明图标
	eventIcon = (data) => {
		if (data === 1) {
			return (
				<Icon type="info-circle" />
			)
		}
		else if (data === 2) {
			return (
				<Icon type="bell" theme="filled" />
			)
		}
		else if (data === 1) {
			return (
				<Icon type="info-circle" />
			)
		}
	}
	//点击时间存储选中时间
	onOk = () => {
		this.setState({
			pageindex: 1
		})
		this.Fneventlist(1, this.state.EventType)
	}

	onChangeTime = (dates, dateStrings) => {
		let that = this;

		if (dates[0] === undefined || dates[1] === undefined) {
			that.setState({
				startime: '',
				endtime: ''
			}, () => {
				// if (that.state.group === false) {
				this.Fneventlist(1, this.state.EventType)
				this.props.setEventDetailStatus({
					startime: '',
					endtime: '',
					page: 1,
					EventType: this.state.EventType,
					eventName: this.state.eventName,
					forComment: this.state.forComment,
					forEventRecord: this.state.forEventRecord,
					group: this.state.group,
					defaultActiveKey: this.state.defaultActiveKey
				})
				// } else {
				// 	// this.sortArr([...this.state.eventlistGroupCopy], 'content')
				// }

			})

		}
		else {
			let time = timeConfig({
				startime: dateStrings[0],
				endtime: dateStrings[1],
			})
			this.setState({
				startime: time.startime,
				endtime: time.endtime,
			})
			that.props.setEventDetailStatus({
				startime: time.startime,
				endtime: time.endtime,
				page: 1,
				EventType: this.state.EventType,
				eventName: this.state.eventName,
				forComment: this.state.forComment,
				forEventRecord: this.state.forEventRecord,
				group: this.state.group,
				defaultActiveKey: this.state.defaultActiveKey
			})
			setTimeout(() => {
				// if (that.state.group === false) {
				that.Fneventlist(1, that.state.EventType)
				// }
				// else {
				// 	that.sortArr([...that.state.eventlistGroupCopy], 'content')
				// }
			}, 200)


		}


	}
	//关闭事件接口
	closeevent = (val) => {
		if (this.state.assetEventid !== '') {

			let url = {
				id: this.state.assetEventid,
				closeReason: val
			}
			closeevent(url).then(res => {
				if (res !== undefined) {
					message.success('操作成功')
					this.setState({
						showmodel: {
							visible: false,
							title: '',
							width: 504,
							value: '',
							centered: true,
						},
					})
					this.Fneventlist(this.state.pageindex, this.state.EventType)
				}
			}).catch(err => {
				if (err.status === 400) {
					message.warning(err.data)
				}
				else {
					message.error('关闭事件失败')
				}

			})
		}

	}
	// 组件传值
	hidemodel = (data) => {
		this.setState({
			showmodel: {
				visible: data,
				title: '',
				width: 504,
				value: '',
				centered: true,
			},

		})
	}
	submitmodel = (val) => {
		if (val !== '') {
			this.closeevent(val)
		}
		else {
			message.warning('处理方案不能为空！')
		}
	}
	showmodel = (showmodel, title, id) => {
		this.geteventDetail(showmodel, title, id)
	}
	geteventDetail = (showmodel, title, id) => {
		let url = `/${id}`;
		geteventDetail(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				let eventDateTime = moment.parseZone(resdata.eventDateTime).local().format('YYYY-MM-DD HH:mm:ss')
				let eventCloseTime = moment.parseZone(resdata.closeAt).local().format('YYYY-MM-DD HH:mm:ss')
				this.setState({
					// eventdetail: `${eventDateTime} ${resdata.content}`,
					assetEventid: `${resdata.id}`,
					showmodel: {
						visible: showmodel,
						title: title,
						value: `${eventDateTime} ${resdata.content}`,
						width: 504,
						centered: true,
						closeUser: `${resdata.closeUserOrganization}, ${resdata.closeUser}, ${eventCloseTime}`,
						closeReason: resdata.closeReason,
						assetEventStatus: `${resdata.assetEventStatus}`
					}
				})
			}
		})
	}
	selectName = (e) => {
		// console.log("eeeee",e.target.value)
		this.setState({
			eventName: e.target.value
		})
	}

	sortArr = (arr, str) => {
		var _arr = [],
			_t = [],
			// 临时的变量
			_tmp;


		// 按照特定的参数将数组排序将具有相同值得排在一起
		arr = arr.sort(function (a, b) {
			var s = a[str],
				t = b[str];

			return s < t ? -1 : 1;
		});
		let tempArray = []
		for (var i in arr) {
			let item = arr[i]

			tempArray.push(item)

		}
		arr = tempArray


		if (arr.length) {
			_tmp = arr[0][str];
		}
		// console.log( arr );
		// 将相同类别的对象添加到统一个数组
		for (var i in arr) {
			if (arr[i][str] === _tmp) {
				_t.push(arr[i]);
			} else {
				_tmp = arr[i][str];
				_arr.push(_t);
				_t = [arr[i]];
			}
		}
		// 将最后的内容推出新数组
		_arr.push(_t); //获取到所有整合数据
		// console.log("_arr---------",_arr);//可以得到图三
		// 终极整合
		var integration = [];
		_arr.forEach((item) => {
			var eee = { content: "", data: [] };
			item.forEach((self) => {
				if (eee.content !== self.content) {
					eee.content = self.content;
					eee.data.push(self);
				} else {
					eee.data.push(self);
				}
			});

			eee.number = eee.data.length
			integration.push(eee);
		});
		console.log("integration---", integration);//图二

		this.setState({
			eventListGroupData: []
		},()=>{
this.setState({
	eventListGroupData: integration
})
		})
 
		// return integration;
	}

	onPressEnter = (e) => {

		this.setState({
			eventName: e.target.value
		}, () => {
			this.eventDatafilter()
			// if (this.state.group === false) {
			// 	this.Fneventlist(this.state.pageindex, this.state.EventType)
			// }
			// else {
			// 	this.sortArr([...this.state.eventlistGroupCopy], 'content')
			// }
		})


	}
	onChangecheckoutgroup = (e) => {
		let val = e.target.checked;
		this.setState({
			group: val
		}, () => {
			// this.eventDatafilter()
			if (val === true) {
				this.sortArr(this.props.getevent, "content")
			}
			else {
				this.eventDatafilter()
			}
		})
	}
	onChangecheckoutforEventRecord = (e) => {
		this.setState({
			forEventRecord: e.target.checked
		}, () => {
			this.eventDatafilter()
			// console.log("this.state.group", this.state.group)
			// if (this.state.group === false) {
			// 	this.Fneventlist(this.state.pageindex, this.state.EventType)
			// }
			// else {
			// 	// this.searchEventList();
			// 	this.sortArr([...this.state.eventlistGroupCopy], 'content')
			// }
		})

	}
	onChangecheckoutforComment = (e) => {

		this.setState({
			forComment: e.target.checked
		}, () => {
			this.eventDatafilter()
			// if (this.state.group === false) {
			// 	this.Fneventlist(this.state.pageindex, this.state.EventType)
			// }
			// else {
			// 	this.sortArr([...this.state.eventlistGroupCopy], 'content')
			// }
		})


	}
	assetEventType = (type) => {
		if (type === 'Fault') {
			return (
				<ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
			);
		}
		if (type === 'Warning') {
			return (
				<ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
			);
		}
		if (type === 'Notification') {
			return (
				<ABB.Icon style={{ 'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
			);
		}
		if (type === 'Custom') {
			return (
				<div className='customevent'></div>
			);
		}
	}
	onChangeCollapse = (e) => {
		console.log("onChangeCollapse----------", e)
		this.setState({
			defaultActiveKey: e
		})
		this.props.setEventDetailStatus({ ...this.props.getEventDetailStatus, defaultActiveKey: e })
	}
	// disabledDate = current => current && current > moment().endOf('day');
	render() {
		let that = this;
 
		console.log('refkey==================',that.state.refkey)

		let { loadingTip, EventType, forComment, forEventRecord, eventName, group, eventListGroupData, defaultActiveKey } = this.state;
		const { _deviceDetail, getevent, getEventDetailStatus } = that.props;

		const disabledDate = current => current && current > moment().endOf('day') || current < moment().subtract(this.state.feature.eventtime, 'day') || (current && _deviceDetail.createdOn && current < moment(_deviceDetail.createdOn).add(-1, 'd').endOf('day'));
		// let objnull = (JSON.stringify(getevent) === "{}");
		let times = [];
		if (getEventDetailStatus.startime !== '') {
			let _startime = moment.parseZone(getEventDetailStatus.startime).local().format(dateFormat);
			let _endtime = moment.parseZone(getEventDetailStatus.endtime).local().format(dateFormat);

			times = [moment(_startime, dateFormat), moment(_endtime, dateFormat)]
		}

		return (
			<div className='deviceDetail_status' >
				<EventModel
					showmodel={that.state.showmodel}
					hidemodel={that.hidemodel}
					submitmodel={that.submitmodel} />
				{/* <div style={new_box}> */}
				<div className="EUorg_title_back">
					<div className='backup_button' onClick={this.returendevice}>
						<ABB.Icon name="abb/left" sizeClass='medium' />
						<span>全部事件</span>

					</div>
				</div>

				{/* </div> */}
				<div style={event_list_box} className='event_list_box'>
					<div className='event_list_select'>
						<div>事件类型</div>
						<Select defaultValue="全部类型"
							suffixIcon={
								<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
							}
							style={{ width: 120 }}
							value={EventType}
							onChange={this.eventOnChange}>
							{
								this.state.eventtypelist.map((item, index) => {
									return (
										<Option value={item.id} key={index}>{item.name}</Option>
									)
								})
							}
						</Select>

					</div>
					<div className='event_list_select'>
						<div>时间</div>
						<RangePicker
							disabledDate={disabledDate}
							className="model_input"
							style={{ margin: '0 10px 0 0px', width: '260px' }}
							value={times}
							suffixIcon={
								<ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
							}
							separator='—'

							allowClear={false}
							format="YYYY/MM/DD"
							onChange={this.onChangeTime}
							onOk={this.onOk}
						/>
					</div>
					<div className='event_list_select'>
						<div>筛选事件描述</div>
						<Input placeholder="请输入事件描述" value={eventName} onChange={this.selectName} onPressEnter={this.onPressEnter} className="model_input" />
					</div>
				</div>

				<div className='event_list_box'>
					<span style={{ marginRight: '24px' }}>显示事件</span>
					<div style={{ width: '369px' }}>
						<Row>
							<Col span={6}>
								<Checkbox onChange={that.onChangecheckoutgroup} checked={group} value='A'>分组</Checkbox>
							</Col>
							<Col span={10}>
								<Checkbox onChange={that.onChangecheckoutforEventRecord} checked={forEventRecord} value='B'>仅包含事件记录</Checkbox>
							</Col>
							<Col span={8}>
								<Checkbox onChange={that.onChangecheckoutforComment} checked={forComment} value={"c"}>未处理</Checkbox>
							</Col>
						</Row>
					</div>
				</div>
				<Spin spinning={this.state.loading} tip={loadingTip} style={{ height: '88vh' }}>
					<div style={{ position: 'relative', minHeight: '80vh' }}>
						{
							group === false ? (<EeventTable isGroup={false} item={
								{ content: '', data: getevent }
							} tableData={getevent} eventlogger={this.eventlogger} refreshData={() => {
								console.log('刷新数据了')
								that.Fneventlist(this.state.pageindex, EventType)
							}} />)
								: (
									<div className='grouptable'>

										{
											eventListGroupData.length > 0 ? (
												<Collapse
													bordered={false}
													defaultActiveKey={defaultActiveKey}
													expandIconPosition={"right"}
													onChange={this.onChangeCollapse}
													expandIcon={({ isActive }) =>
														// <ABB.Icon    name="abb/caret-left_24" sizeClass='medium' />
														<Icon type="right" rotate={isActive ? -90 : 90} />
													}
												 
												>
													{
														eventListGroupData.map((item, index) => {
															// return <Panel header={item.content} key={String(index)} >
															return <Panel header={
																<div className='group_section'>
																	<div className='left'>{item.content}</div>
																	<div className='right'>共{item.number}条</div>
																</div>

															} key={item.content + that.state.refkey} >

																<EeventTable
																	style={{minHeight:'300px'}}
																	index={index}
																	isGroup={group}
																	item={item}
																	tableData={item.data}
																	eventlogger={this.eventlogger}
																	updateNumber={(index, number) => {
																		console.log('update number', index, number)
																		let eventListGroupData = this.state.eventListGroupData

																		eventListGroupData[index].number = number

																		this.setState({
																			eventListGroupData: eventListGroupData
																		})

																	}}

																	refreshData={() => {
																		console.log('刷新数据了')
																		that.Fneventlist(this.state.pageindex, EventType)
																	}}

																/>


															</Panel>

														})
													}
												</Collapse>
											) : <NoData />
										}

									</div>
								)
						}
					</div>
				</Spin>

			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	checkAllgen: data => {
		dispatch(checkAllgen(data))
	},
	setEventData: data => {
		dispatch(setEventData(data))
	},
	eventDetaildevice: data => {

		dispatch(eventDetaildevice(data));
	},
	deviceDetail: data => {
		dispatch(deviceDetail(data))
	},
	setevent: data => {
		dispatch(setevent(data))
	},
	userCheckDevice: data => {
		dispatch(userCheckDevice(data))
	},
	setEventDetail: data => {
		dispatch(setEventDetail(data))
	},
	setEventDetailStatus: data => {
		dispatch(setEventDetailStatus(data))
	},

});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Eventlist));
// export default Eventlist;

