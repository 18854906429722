import React, { Component } from 'react';
import Generalnformation from './components/generalnformation';
import Eventlist from '../../event/eventList';
import { message, Spin } from 'antd';
import CBM from "../../CBM/index";
import Sinal from '../../signal/signal';
import DeviceMessage from './components/deviceMessage';
import EventDetail from '../../event/eventDetail';
import DeviceAlltype from './components/deviceAlltype';
import DeviceDetailTitle from './components/deviceDetailTitle';
import DeviceAlltypePowertrain from './components/deviceAlltypePowertrain';
import { connect } from 'react-redux';
import { setChartsTime, setAssetDetail, checkAllgen, deviceDetail, eventDetaildevice, userCheckDevice, setResTrendsCode, setsubscriptioncof } from '@/redux/actions/device';
import { setDebugInformationConfig, setDebugInformationRemindConfig, setExpertsConfig } from '../store/actionCreators';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import NoData from '@/components/NoData'
import NoPermission from '@/components/NoPermission'
import BackUp from '../../backup/index';
import ReportList from '../../report/ReportList';
import Lifestatus from '../../CBM/components/lifestatus';
import Chartsdetail from '../../signal/components/chartsdetail/chartsdetail'
import EventLogger from '../../event/components/index'
import { subscriptioncof, equipmentdetail } from "@/api/device";
import unittype from '../../../../../utils/unittype';
import { timeThursdays } from 'd3';
import GetSubscription from './components/getSubscription'
import moment from 'moment';
import ConnectStatus from './components/connectStatus';
import DataInspection from './components/DataInspection/DataInspection';
import DebuggingInformationModel from './components/debuggingInformationModel';
import RemindModel from './components/remindModel'
import ServiceModel from './components/serviceModel';

import { APIGetContact, APIGetNeta21Info } from '../../../../../api/device';
import { _deviceDetail } from '../../../../../redux/reducers/device';

const EUorg_title = {
	lineHeight: '40px',
	fontSize: '18px',
	display: 'flex',
	justifyContent: 'left',
	alignItems: 'center',
	color: '#686868',
	cursor: 'pointer'
}
class EquuipmentList extends Component {

	constructor(props) {
		super(props)
		this.state = {
			assetId: this.props.getassetId,
			loading: false,
			UsercheckDeviceDetail: this.props.UsercheckDeviceDetail,
			assetstatus: '',
			PermissionType: 0,
			showmodel: {
				visible: false,
				time: ''
			},
			SubTime: '',
			isShowModel: true,
			jurisdictionConfig: {
				isShowSignalTrend: false,
				isShowIndicators: false,
				isShowEventList: false,
				isShowCustomAlarm: false,
				isShowCBM: false,
				isShowReportList: false,
				isDownLoadBackUp: false,
				isShowParamChange: false,
				isShowInspection: false,
				isShowWorkStatus:false
			},



		};
	}

	backupper = () => {
		this.props.userCheckDevice(0)
	}
	subscriptionconfig = (data) => {
		console.log("subscriptionconfig-----------",data)
		let jurisdictionConfig = {
			isShowSignalTrend: false,
			isShowIndicators: false,
			isShowEventList: false,
			isShowCustomAlarm: false,
			isShowCBM: false,
			isShowReportList: false,
			isDownLoadBackUp: false,
			isShowParamChange: false,
			isShowInspection: false,
			isShowWorkStatus:false
		}

		let eventTypes = [
			{
				name: "全部",
				id: 0
			}
		];
		data.forEach(element => {

			switch (element.featureCode) {
				case 1: {
					break
					// { featureCode: 1,  featureName: '信号趋势曲线中的可选择日期范围', limitValue: 30 }
				}
				case 2: {
					break
					// { featureCode: 2, featureName: '可查询的历史信号数据', limitValue: 365 }
				}
				case 3: {
					// { featureCode: 3, featureName: '设置信号警戒线', limitValue: true }
					//自定义告警
					jurisdictionConfig.isShowCustomAlarm = element.limitValue;
					break
				}
				case 4: { break }
				case 5: { break }
				case 6: {
					// { featureCode: 6, featureName: '事件列表查看事件类型', limitValue: '1,2,3' }
					console.log("element.limitValue====", element.limitValue)

					const stringResult = element.limitValue.split(',');
					stringResult.map(itemarr => {
						switch (itemarr) {
							case "1": {
								eventTypes.push({
									name: "故障",
									id: 1
								})
								break
							}
							case "2": {
								eventTypes.push({
									name: "告警",
									id: 2
								})

								break
							}
							case "3": {
								eventTypes.push({
									name: "通知",
									id: 3
								})
								break
							}
						}
					})
					break
				}
				case 7: { break }
				case 8: { break }
				case 9: {
					break
					// { featureCode: 9, featureName: '事件时刻信号趋势数据', limitValue: true }
				}
				case 10: {
					break
					// { featureCode: 10,featureName: 'EventLogger数据可视化', limitValue: true }
				}
				case 11: {
					break
					//  { featureCode: 11,featureName: 'EventLogger数据导出',limitValue: true }
				}
				case 12: {
					// { featureCode: 12,featureName: '可查询的事件选择的时间范围', limitValue: 365 }
				}
				case 13: {
					// { featureCode: 13,featureName: '查看参数备份列表和参数备份下载',limitValue: true }
					jurisdictionConfig.isDownLoadBackUp = element.limitValue;
					break
				}
				case 14: {
					// { featureCode: 14, featureName: '参数变化列表', limitValue: true }
					jurisdictionConfig.isShowParamChange = element.limitValue;
					break
				}
				case 15: { break }
				case 16: {
					break
					//{ featureCode: 16,featureName: '可查询的参数备份选择的时间范围',limitValue: 365 }
				}
				case 17: {
					//{ featureCode: 17, featureName: '查看报告列表', limitValue: false }
					jurisdictionConfig.isShowReportList = element.limitValue;
					break
				}
				case 18: {
					break
					//   { featureCode: 18, featureName: '查看和创建报告类型',limitValue: '1,2,3,4' }
				}
				case 19: { break }
				case 20: {
					break
					//  { featureCode: 20, featureName: '创建报告可选择的时间范围', limitValue: 140 }
				}
				case 21: {
					break
					// { featureCode: 21,  featureName: '可查几种类型的健康状态指标', limitValue: '1,2,3,4,5' }
				}
				case 22: {
					//  { featureCode: 22, featureName: '预测性维护页面', limitValue: false }
					jurisdictionConfig.isShowCBM = element.limitValue;
					break
				}
				case 23: { break }
				case 24: {
					//  { featureCode: 24, featureName: '是否可以查看信号趋势数据',limitValue: true }
					jurisdictionConfig.isShowSignalTrend = element.limitValue
					break
				}
				case 25: {
					//  { featureCode: 25, featureName: '是否可以查看设备健康状态指标',limitValue: true }
					jurisdictionConfig.isShowIndicators = element.limitValue
					break
				}
				case 26: {
					//  { featureCode: 26, featureName: '是否可以查看事件列表', limitValue: true }
					jurisdictionConfig.isShowEventList = element.limitValue
					break
				}
				case 27: {
					//  { featureCode: 27, featureName: '是否可以支持设备远程巡检', limitValue: true }
					jurisdictionConfig.isShowInspection = element.limitValue
					break
				}
				case 28: {
					//    {featureCode: 28, featureName: '设备工作状态', limitValue: true}
					jurisdictionConfig.isShowWorkStatus = element.limitValue
					break
				}
			}
		});


		this.setState({
			jurisdictionConfig: jurisdictionConfig
		},()=>{
			// this.props.deviceDetail({...this.props._deviceDetail,jurisdictionConfig:jurisdictionConfig});
		})
		// this.child.getEventList(jurisdictionConfig)
	}

	subscriptioncofdata = () => {

		let { getAssetIsPowertrain, getassetId } = this.props;
		let isSubAsset = ``;
		let url;
		if (getAssetIsPowertrain === true) {
			isSubAsset = `?isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}
		if (getassetId !== "") {
			this.setState({
				loading: true
			})
			url = `${getassetId}${isSubAsset}`

			subscriptioncof(url).then(res => {
				this.setState({
			 
					loading: false
				})
			 
				if (res !== undefined) {
					// this.isShowMenu(res.data);
					
					this.detailrequest(getassetId);
					this.props.setsubscriptioncof(res.data);
					this.subscriptionconfig(res.data[0].config);
					this._getContact()
				}
			}).catch(err => {
				this.setState({
			 
					loading: false
				})
				if (err.status === 400 || err.status === 404) {
					// message.warning(err.data)
					
					if (err.data === "无权限操作") {
						message.destroy()
						message.warning(err.data)
						this.setState({
							PermissionType: 1,
							 
						})
						this.props.checkAllgen(5)
					}
				}
				else {
					console.log("subscriptioncofdata -- subscriptioncof --接口异常")
					message.error('接口异常')
				}
			})
		}

	}
	detailrequest = (id) => {
		//设备详情信息请求
 
		let { getAssetIsPowertrain, UsercheckDeviceDetail } = this.props;
		let isSubAsset = '';
		if (getAssetIsPowertrain === true) {
			isSubAsset = `&isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}
		let url = `?id=${id}${isSubAsset}`;
		 
		equipmentdetail(url).then(res => {
			  
			let resdata = res.data;
			if (resdata.data === null) return
 
			// resdata.data.deviceWorkStatus = 0 //增加新字段,判断设备运行状态

			let trendsCode = [];
			if (resdata !== undefined) {
				let conditions = []
				if (resdata.data.trends !== null) {
					for (let i = 0; i < resdata.data.trends.length; i++) {
						trendsCode.push(resdata.data.trends[i].code);
					}

				}
			
				resdata.data.conditions.forEach((item, index) => {
					if (item.type === 'reliability') {
						conditions.splice(2, 0, item)
					}
					else if (item.type === 'availability') {
						conditions.splice(0, 0, item)
					}
					else if (item.type === 'environment') {
						conditions.splice(1, 0, item)
					}
					else if (item.type === 'stress') {
						conditions.splice(3, 0, item)
					}
					else if (item.type === 'overall') {
						conditions.splice(4, 0, item)
					}
				})
				
				if (resdata.data.signals === null) {
					resdata.data.signals = []
				}
				if (resdata.data.subscription === null) {
					resdata.data.subscription = {
						activatedDateTime: 1652067493,
						expiredDateTime: 1683603493,
						subscriptionLevel: "高级版",
						subscriptionStatus: "已激活",
						subscriptionType: "正式",
						validPeriodTime: "1年"
					}
				}

				resdata.data.conditions = conditions;
  
				this.props.setResTrendsCode(trendsCode)
				this.props.deviceDetail(resdata.data);

				
				if (resdata.data.gatewayType === 1) {
					this._getNeta21Info(resdata.data.serialNumber)
				}
			
				this.props.setAssetDetail({
					deviceName: resdata.data.assetName,
					sn: resdata.data.serialNumber
				})
			

				this.setState({
					loading: false,
					showmodel: {
						visible: (UsercheckDeviceDetail === 0 || UsercheckDeviceDetail === 11 || UsercheckDeviceDetail === 3) ? resdata.data.showComplimentarySubscription : false,
						// visible: true,
						time: resdata.data.endMigrationTimstamp !== null ? moment(resdata.data.endMigrationTimstamp).format('YYYY-MM-DD') : ''
					},
					SubTime: resdata.data.endMigrationTimstamp !== null ? moment(resdata.data.endMigrationTimstamp).format('YYYY-MM-DD') : ''

				}) 
			} 
		}).catch(err => {
			this.setState({
				loading: false
			})
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else { 
				message.error('设备详情接口异常')
			}
		})
	}

	_getNeta21Info = (neta21Sn) => {
		 
		APIGetNeta21Info(neta21Sn).then(res => {
			console.log("APIGetNeta21Info===============", res)
			let resdata = res.data;

			if (resdata !== undefined && resdata.data !== null) {

				let showDebuggingInformationConfig = { ...this.props.getDebugInformationConfig }
				let nataData = resdata.data
			 
				let ips = nataData.netaIPAddress.split(".")
				let defaultData = {}
				if (ips.length === 4) {
					defaultData = {
						netaIPAddress: nataData.netaIPAddress, //NETA IP地址
						netaUserName: nataData.netaUserName, //NETA UserName
						netaPassword: nataData.netaPassword, //NETA Password
						routerSerialNo: "", //路由器序列号
						routerPassword: "", //路由器密码
						routerMACAddress: "", //路由器MAC地址
						sIMICCID: "", //SIM卡ICCID
						deviceVersion: "", //设备版本号
						deviceNodeId:nataData.deviceNodeId,
						isShowNeta21Remind:true,
						isShowRouterRemind:false,
						ip1: ips[0],
						ip2: ips[1],
						ip3: ips[2],
						ip4: ips[3]
					}
				} else {
					defaultData = {
						netaIPAddress: nataData.netaIPAddress, //NETA IP地址
						netaUserName: nataData.netaUserName, //NETA UserName
						netaPassword: nataData.netaPassword, //NETA Password
						routerSerialNo: "", //路由器序列号
						routerPassword: "", //路由器密码
						routerMACAddress: "", //路由器MAC地址
						sIMICCID: "", //SIM卡ICCID
						deviceVersion: "", //设备版本号
						deviceNodeId:"",
						isShowNeta21Remind:false,
						isShowRouterRemind:false,
						ip1: "",
						ip2: "",
						ip3: "",
						ip4: ""
					}
				}

				showDebuggingInformationConfig.defaultData = defaultData

				this.props.updateDebugInformationConfig(showDebuggingInformationConfig)

			} else {
				this._resetDebugInformationConfig()
			}
		}).catch(err => {
			console.log("APIGetNeta21Info===============err", err)
			this._resetDebugInformationConfig()

			// this.setState({
			// 	loading: false
			// })
			// if (err.status === 400 || err.status === 404) {
			// 	message.warning(err.data)
			// }
			// else {
			// 	message.error('neta信息获取异常')
			// }
		})
	}
	_resetDebugInformationConfig = () => {
		let showDebuggingInformationConfig = { ...this.props.getDebugInformationConfig }
		showDebuggingInformationConfig.defaultData = {
			netaIPAddress: "", //NETA IP地址
			netaUserName: "", //NETA UserName
			netaPassword: "", //NETA Password
			routerSerialNo: "", //路由器序列号
			routerPassword: "", //路由器密码
			routerMACAddress: "", //路由器MAC地址
			sIMICCID: "", //SIM卡ICCID
			deviceVersion: "", //设备版本号
			ip1: "",
			ip2: "",
			ip3: "",
			ip4: ""
		}

		this.props.updateDebugInformationConfig(showDebuggingInformationConfig)
	}

	_configDefaultData = (data) => {

		console.log("_configDefaultData===========",data)


		var config = { ...this.props.getExpertsConfig }
		
  
		config.deviceContacts = [...data.deviceContacts]
		config.defaultDeviceContacts =  [...data.deviceContacts]

		let deviceContacts =  [
            {
                "title": "首选联系人", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 0, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: true
            },
            {
                "title": "备选联系人1", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 1, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: true
            },
            {
                "title": "备选联系人2", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 2, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: false
            },
            {
                "title": "备选联系人3", //标题
                "name": "", // 联系人名称
                "phone": "", // 联系人电话
                "priority": 3, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
                isMust: false
            },
        ]
		let orgContacts = data.orgContacts

		for (let index = 0; index < orgContacts.length; index++) {
			const element = orgContacts[index];
		 
            deviceContacts[index] = { ...deviceContacts[index], ...element }
		}

		config.orgContacts = deviceContacts 

		console.log("newconfig=========", config)
		return config

	}


	//获取联系人
	_getContact = (_requestData) => {
 
		let { getAssetIsPowertrain, getassetId } = this.props;
		let isSubAsset = 'false';
		if (getAssetIsPowertrain === true) {
			isSubAsset = `true`;
		}

		APIGetContact(getassetId, isSubAsset).then(res => {
			console.log("url============APIGetContact11111111111===", res)
			let resdata = res.data;
			let trendsCode = [];
			if (resdata !== undefined) {
				let data = resdata.data


				var config = this._configDefaultData(data)

				console.log("config=================",config)

				this.setState({
					loading: false,
				}, () => {
					this.props.updateExpertsConfig(config)
				})
			}
		}).catch(err => {
			console.log("url============APIGetCerror===", err)
			this.setState({
				loading: false
			})
			if (err.data.status === 400 || err.data.status === 404) {
				message.warning(err.data.title)
			}
			else {
				message.error('联系人接口异常')
			}
		})
	}
	componentDidMount() {

		this.setState({
			loading: false
		})
		this.subscriptioncofdata();

	}
	componentDidUpdate(nextProps, prevState) {

		if (prevState.assetId !== this.props.getassetId) {

			this.props.setChartsTime({
				startime: new Date(new Date().toLocaleDateString()),
				endtime: new Date(),
			})
			this.setState({
				assetId: this.props.getassetId,
			}, () => {
				console.log("更新了数据")
				this.props.setsubscriptioncof([])
				this.subscriptioncofdata();
				 
			})

		}

		// let objnull = JSON.stringify(this.props._deviceDetail) === "{}";

		// if (objnull === false) {

		if (prevState.UsercheckDeviceDetail !== this.props.UsercheckDeviceDetail) {

			this.setState({
				UsercheckDeviceDetail: this.props.UsercheckDeviceDetail,
			}, () => {
				this.props.setsubscriptioncof([])
				this.subscriptioncofdata();
			})

		}
		// }

	}
	isdeviceOrpowertrain = (checkAll, eventDetail, assetType, UsercheckDeviceDetail) => {
		let _assetType = assetType;
		let { getsubscriptioncof } = this.props;
		let feature = {};
		let { jurisdictionConfig } = this.state;
		
		if (getsubscriptioncof.length > 0) {
			getsubscriptioncof.map(item => {
				item.config.map(items => {
					if (items.featureCode === 22) {
						feature.cbmpage = items.limitValue
					}
				})

			})
		}

		if (_assetType === 1 && (UsercheckDeviceDetail === 2 || UsercheckDeviceDetail === 3)) {
			return (
				<div style={{ position: 'relative', height: '86vh' }}>
					{
						this.props.getsubscriptioncof.length > 0 ? (
							<>
								<DeviceAlltypePowertrain getsubscriptioncof={getsubscriptioncof} />
								<Eventlist onRef={(ref) => { this.eventlist = ref }} jurisdictionConfig={jurisdictionConfig} />
							</>
						) : <NoData />
					}
				</div>
			)
		}
		else if ((UsercheckDeviceDetail === 0 || UsercheckDeviceDetail === 11 || UsercheckDeviceDetail === 3) && (_assetType === 3 || _assetType === 2)) {
			return (
				<div style={{ position: 'relative', height: '86vh' }}>


					{
						this.props.getsubscriptioncof.length > 0 ? (
							<>
								<DeviceDetailTitle subscriptioncof={this.props.getsubscriptioncof} SubTime={this.state.SubTime} />

								<ConnectStatus subscriptioncof={this.props.getsubscriptioncof} jurisdictionConfig={this.state.jurisdictionConfig} refreshData={() => {
									this.subscriptioncofdata()
								}} showDebuggingInformation={() => {

									const {_deviceDetail} = this.props

									console.log("显示数字化连接调试信息")
									let showDebuggingInformationConfig = { ...this.props.getDebugInformationConfig, ...this.props.getDebugInformationConfig.defaultData }
									showDebuggingInformationConfig.visible = true
									showDebuggingInformationConfig.title = "补充数字化连接调试信息"
									if (showDebuggingInformationConfig.routerSerialNo.length > 0) {
										showDebuggingInformationConfig.checkRouterDisable = false
									} else {
										showDebuggingInformationConfig.checkRouterDisable = true
									}
									showDebuggingInformationConfig.serialNumber = _deviceDetail.serialNumber //传动序列号
									showDebuggingInformationConfig.gatewaySn = _deviceDetail.gatewaySn//NETA-21序列号 

									console.log("显示数字化连接调试信息====",showDebuggingInformationConfig)

									this.props.updateDebugInformationConfig(showDebuggingInformationConfig)

								}} />

								{
									this.state.jurisdictionConfig.isShowIndicators ? <DeviceAlltype subscriptioncof={this.props.getsubscriptioncof} /> : ''
								}

								{
									(assetType === 2 && feature.cbmpage === true) ? (
										<div className='lifebox lifebox_device'>
											<div className="lifestatus_title">预测性维护：</div>
											<Lifestatus subscriptioncof={this.props.getsubscriptioncof} />
										</div>

									) : ""
								}
								{
									// console.log("this.props===========",this.props._deviceDetail)
									// 临时关闭实时数据巡检
									// this.state.jurisdictionConfig.isShowInspection === true ? <DataInspection/> : null
								}
								{
									//最新事件列表
									jurisdictionConfig.isShowEventList ? <Eventlist onRef={(ref) => { this.eventlist = ref }} jurisdictionConfig={jurisdictionConfig} /> : ''
								}
								{
									//信号时序
									jurisdictionConfig.isShowSignalTrend ? <Sinal /> : ''
								}
								{
									//订阅过期
									(jurisdictionConfig.isShowSignalTrend === false && jurisdictionConfig.isShowEventList === false && jurisdictionConfig.isShowIndicators === false) ? (
										<div className='deviceDetail_status_bottom' style={{ height: '82vh' }}>
											<NoPermission data={"设备订阅已过期"} />
										</div>
									) : ''
								}
							</>
						) : <NoData />
					}

				</div >
			)
		}
		else if (UsercheckDeviceDetail === 4) {
			return (
				<BackUp />
			)
		}
		else if (UsercheckDeviceDetail === 5) {
			return (
				<ReportList />
			)
		}
		else if (UsercheckDeviceDetail === 6) {
			return (
				<CBM />
			)
		}
		else if (UsercheckDeviceDetail === 7) {
			return (
				<Chartsdetail />
			)
		}
		else if (UsercheckDeviceDetail === 8) {
			return (
				<EventLogger/>
			)
		}
		else if (UsercheckDeviceDetail === 9) {
			return (
				<EventDetail />
			)
		}
		else if (UsercheckDeviceDetail === 10) {
			return (
				<DeviceMessage />

			)
		}
	}
	componentWillUnmount() {
		// this.setState({
		// 	showmodel: {
		// 		visible: false,
		// 		time: '',
		// 		isShowModel: true
		// 	},
		// })
	}
	hidemodel = () => {
		this.setState({
			showmodel: {
				visible: false,
				time: ''
			},
			// isShowModel: false
		})
	}
	submitmodel = () => {

	}

	//提示同步调试信息到设备
	_updateRemindConfig = (data, index) => {
 
		if (data.devices.length < index + 1) {
			return
		}

		let typeData = data.devices[index]

		if (typeData.matchList.length < 1) {
			this._updateRemindConfig(data, index + 1)
		} else {

			const config = { ...this.props.getDebugInformationRemindConfig }

			config.visible = true
			config.index = index
			config.type = typeData.matchType
			config.deviceData = data
			config.devices = typeData.matchList

			this.props.updateDebugInformationRemindConfig(config)

		}

	}

	render() {
		const { _deviceDetail, checkAll, eventDetail, UsercheckDeviceDetail,getExpertsConfig } = this.props;
		let objnull = (JSON.stringify(_deviceDetail) === "{}")

		return (
			<div>

				{
					objnull === false ? (
						<Spin spinning={this.state.loading} style={{ height: '90vh' }}>
							{
								this.isdeviceOrpowertrain(checkAll, eventDetail, _deviceDetail.assetType, UsercheckDeviceDetail)
							}
						</Spin>
					) : (

						<Spin spinning={this.state.loading} style={{ height: '90vh' }}>
							<div className='deviceDetail_status_bottom' style={{ minHeight: '90vh', textAlign: 'center' }}>
								{
									this.props.UsercheckDeviceDetail === 11 ? (
										<div style={EUorg_title} onClick={() => this.backupper()}>
											<ABB.Icon name="abb/left" sizeClass='medium' />
										</div>
									) : ''
								}
								<div className='deviceDetail_status_bottom' style={{ height: '80vh' }}>
									{

										checkAll === 5 ? <NoPermission data={"设备无权限"} /> : <NoData />
									}
								</div>


							</div>
						</Spin>

					)
				}
				<GetSubscription
					showmodel={this.state.showmodel}
					hidemodel={this.hidemodel}
					submitmodel={this.submitmodel}
				/>

				<DebuggingInformationModel
					showmodelboundsub={this.props.getDebugInformationConfig}
					hidemodel={() => {

					}}
					submitmodel={(data) => {
						//更新设备详情
						this.detailrequest(this.props.getassetId)
						this._updateRemindConfig(data, 0)

					}}
				/>
				<RemindModel
					showmodelboundsub={this.props.getDebugInformationRemindConfig}
					hidemodel={(data) => {

						let param = {
							routerSerialNo: data.deviceData.routerSerialNo,
							gatewaySn: data.deviceData.gatewaySn,
							devices: data.deviceData.devices,
							deviceSn:data.deviceData.deviceSn
						}

						this._updateRemindConfig(param, data.index + 1)
					}}
					submitmodel={() => { }}
					showModelSubConfig={() => { }} />
					{
getExpertsConfig.visible ? <ServiceModel
showmodelboundsub={this.props.getExpertsConfig}
hidemodel={() => { }}
submitmodel={() => {
	this.subscriptioncofdata()
	this.eventlist.refreshEventList()
}}
showServiceType={() => {

}} /> : null
					}
				
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	 
	updateExpertsConfig: data => {
		dispatch(setExpertsConfig(data))
	},
	setChartsTime: data => {
		dispatch(setChartsTime(data))
	},
	setAssetDetail: data => {
		dispatch(setAssetDetail(data));
	},
	setResTrendsCode: data => {

		dispatch(setResTrendsCode(data));
	},
	deviceDetail: data => {

		dispatch(deviceDetail(data));
	},
	eventDetaildevice: data => {
		dispatch(eventDetaildevice(data))
	},
	userCheckDevice: data => {
		dispatch(userCheckDevice(data))
	},
	setsubscriptioncof: data => {
		dispatch(setsubscriptioncof(data))
	},
	checkAllgen: data => {
		dispatch(checkAllgen(data))
	},
	updateDebugInformationConfig: data => {
		dispatch(setDebugInformationConfig(data))
	},
	updateDebugInformationRemindConfig: data => {
		dispatch(setDebugInformationRemindConfig(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EquuipmentList));
// export default EquuipmentList;

