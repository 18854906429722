import * as actionTypes from '../constants/index';
const setSubscription = (data) => {
    return {
        type: actionTypes.SET_SUBSCRIPTION,
        data
    }
};
const setSubid = (data) => {
    return {
        type: actionTypes.SET_SUBID,
        data
    }
};
const setSubselect = (data) => {
    return {
        type: actionTypes.SET_SUBSELECT,
        data
    }
}
export {setSubscription,setSubid,setSubselect};