import React, { Component } from 'react';
import { Modal,message } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { resolutionPowertrain } from '@/api/device';

// const { Search } = Input;
class Powertrainmodelbreak extends Component {
	constructor(props) {
		super(props);
		this.state = {
            inputval: '',
            PowertrainmotorsId: '',
            PowertraindrivesId: '',
            assetName: '',
            disabled: false
		};
		
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    //拆分传动链接口
	resolutionPowertrain(data) {
        this.setState({
            disabled: true
        })
		let url = `/${data.powertrainId}`
		resolutionPowertrain(url).then(res => {
			if (res !== undefined) {
				message.success('拆分传动链成功')
                this.props.submitmodel();
                this.setState({
                    disabled: false
                })
			}
		}).catch(err => {
			message.error('操作失败')
            this.setState({
                disabled: false
            })
		})
	}
    hidemodel=(data)=>{
        this.props.hidemodel(data)
    }
    submitmodel=(data)=>{
        this.resolutionPowertrain(data)
    }
    onChange=(e)=>{
        this.setState({
            inputval: e.target.value
        })
    }
    //电机id
	motorsId=(e)=>{
		this.setState({
			PowertrainmotorsId: e
		})
	}
	//传动id
	drivesId=(e)=>{
		this.setState({
			PowertraindrivesId: e
		})
    }
    //get assetname
	powertrainName=(e)=>{
		this.setState({
			assetName: e.target.value
		})
	}
	render() {
        const that = this;
        const {showPowertrainmodelbreak} = this.props;
        const modelProps = {
            title: showPowertrainmodelbreak.title,
            centered: showPowertrainmodelbreak.centered,
            width: showPowertrainmodelbreak.width,
            visible: showPowertrainmodelbreak.visible,
            maskClosable: false,
            onOk:() => {that.hidemodel(false)},
            onCancel:() => {that.hidemodel(false)},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showPowertrainmodelbreak)}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,        
            ]
        }
		return (
			<Modal {...modelProps}
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>

            }>
                {
                    showPowertrainmodelbreak.resolutiondevice.map((item,index)=>{
                        return (
                            <div className="model_list">
                               <div className="model_title">{item.type}</div>
                               <div className="model_title" style={{color: '#1F1F1F'}}>{item.assetName}</div>
                            </div>
                            
                        )
                    })
                }
            </Modal>
		);
	}
}

export default Powertrainmodelbreak;
