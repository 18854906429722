import * as actionTypes from '../constants/index';
const _getSubscription = []
const _getSubid = {
    type:'',
    id: '',
	orgId: ''
}
const _getSubselect = '0'
const getSubscription = (state = _getSubscription, action) => {
	switch (action.type) {
		case actionTypes.SET_SUBSCRIPTION:
			return action.data;
		default:
			return state;
	}
};

const getSubid = (state = _getSubid, action) => {
	switch (action.type) {
		case actionTypes.SET_SUBID:
			return action.data;
		default:
			return state;
	}
};

const getSubselect = (state = _getSubselect, action) => {
	switch (action.type) {
		case actionTypes.SET_SUBSELECT:
			return action.data;
		default:
			return state;
	}
};

export {getSubscription,getSubid, getSubselect};