import axios from 'axios';
import { message } from 'antd';
import { getAuthProvider } from '@/lib/AuthProvider';

const authProvider = getAuthProvider();

axios.defaults.headers.post['Content-Type'] = 'application/json'; //配置请求头
axios.defaults.headers.get['Content-Type'] = 'application/json'; //配置请求头
axios.defaults.headers.delete['Content-Type'] = 'application/json'; //配置请求头
axios.defaults.headers.put['Content-Type'] = 'application/json'; //配置请求头

const $adminAxios = axios.create({
	// baseURL: BASEURL,
	baseURL: process.env.REACT_APP_ADMIN_TOOLS_BASE_URL,
	timeout: 20000,
	retry: 4,
	retryDelay: 1000
});

//请求拦截
$adminAxios.interceptors.request.use(
	async function (config) {
		var token = await authProvider.getIdToken();
		// console.log("token----------------------",token.idToken.rawIdToken);
		// 在发送请求之前做些什么
		// 通过reudx的store拿到拿到全局状态树的token ，添加到请求报文，后台会根据该报文返回status
		// 此处应根据具体业务写token
		// const token = localStorage.getItem('token');
		config.headers["pragma"]= "no-cache";
		config.headers['Authorization'] = "Bearer "+ token.idToken.rawIdToken;
		return config;
	},
	function (error) {
		// 对请求错误做些什么
		message.error(error);
		return Promise.reject(error);
	}
);

// 添加响应拦截器
$adminAxios.interceptors.response.use(
	//请求响应成功后
	function (response) {
		// console.log(response,'---response----')
		// 对响应数据做点什么
		if (response.data.success === false) {
			message.error(response);
		}
		return response;
	},
	//请求失败后
	function (error) {
		// console.log(error, '----err--code----');
		if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
			// message.error('请求超时');
			var config = error.config;
			config.__retryCount = config.__retryCount || 0;
			// console.log(config.__retryCount, '>=', config.retry, '---config---');
			if (config.__retryCount >= config.retry) {
				// Reject with the error
				// window.location.reload();
				return Promise.reject(error);
			}

			// Increase the retry count
			config.__retryCount += 1;
			var backoff = new Promise(function (resolve) {
				setTimeout(function () {
					//console.log('resolve');
					resolve();
				}, config.retryDelay || 1);
			});

			return backoff.then(function () {
				return axios(config);
			});
		} else if (error.response.status === 401) {
			message.warning('TOKEN失效，重新登录！');
			authProvider.clearCache();
			// geturl();
		}
		// else if(error.response.status === 400){
		// 	message.warning(error.response.data)
		// }
		// else if(error.response.status === 404){
		// 	message.warning(error.response.data)
		// }
		else {
			return Promise.reject(error.response);
		}
	}
);
//获取当前域名‘
// var url = ''
// function geturl() {
// 	const href = window.location.href
// 	console.log(href)
// 	var reg = new RegExp(/(\w+):\/\/([^/:]+)(:\d*)?/)
// 	let matchObj = href.match(reg)
// 	url = matchObj[0]
// 	window.location.href = url+"/#/login"
// };
export default $adminAxios;
