import * as actionTypes from '../constants/index';
let _userdata = []

const _getplant = []
const _getaddress = {}
const _getAddplanttype = 0;
const _getaddressChange = {}
const _getPlantisShow = 0
const _addDeviceProcess = 0;


const getplant = (state = _getplant, action) => {
	switch (action.type) {
		case actionTypes.SET_PLANT:
			return action.data;
		default:
			return state;
	}
};
const getaddress = (state = _getaddress, action) => {
	switch (action.type) {
		case actionTypes.SET_ADDRESS:
			return action.data;
		default:
			return state;
	}
};

const getAddplanttype = (state = _getAddplanttype, action) => {
	switch (action.type) {
		case actionTypes.SET_ADDPLANTTYPE:
			return action.data;
		default:
			return state;
	}
};
const getAddDeviceProcess = (state = _addDeviceProcess, action)=>{
	switch (action.type) {
		case actionTypes.SET_ADDDEVICEPROCESS:
			return action.data;
		default:
			return state;
	}
};
const getaddressChange = (state = _getaddressChange, action) => {
	switch (action.type) {
		case actionTypes.SET_ADDRESSCHANGE:
			return action.data;
		default:
			return state;
	}
};

const getPlantisShow = (state = _getPlantisShow, action) => {
	switch (action.type) {
		case actionTypes.SET_PLANTISSHOW:
			return action.data;
		default:
			return state;
	}
};

export {getplant,getaddress,getAddplanttype,getaddressChange,getPlantisShow,getAddDeviceProcess};