import React, { Component } from 'react';
import { Modal,Button,Input,Icon } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Search } = Input;
class RemoweModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputval: ''
		};
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    hidemodel=(data)=>{
        this.props.hidemodel(data)
    }
    submitmodel=(data)=>{
        this.props.submitmodel(data)
    }
    onChange=(e)=>{
        this.setState({
            inputval: e.target.value
        })
    }
	render() {
        const that = this;
        const {showmodelRemove, disabled} = this.props;
        const modelProps = {
            title: showmodelRemove.title,
            centered: showmodelRemove.centered,
            width:showmodelRemove.width,
            maskClosable: false,
            visible:showmodelRemove.visible,
            onOk:() => {that.hidemodel(false)},
            onCancel:() => {that.hidemodel(false)},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(that.state.inputval)}
                    type="primary-blue"
                    disabled = {disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,        
            ]
        }
		return (
			<Modal {...modelProps} 
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                <div className="remove_model">{showmodelRemove.value}</div>
                
            </Modal>
		);
	}
}

export default RemoweModel;
