import React, { Component } from 'react';
import { Modal,message } from 'antd';
import { organizationLeave } from '@/api/user';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { connect } from 'react-redux';
import { setLogoutOrganizationConfig } from '../../../store/actionCreators';

class Logoutgroup extends Component {
	 
	 
    //确认添加位置接口
	logoutres(){
	    	let url = ''
			organizationLeave(url).then(res=>{
				if(res!==undefined){
					this._hidemodel()
                    message.success("退出成功!")
                    setTimeout(()=>{
                        window.location.reload()
                    },1500)
				}
			}).catch(err=>{
				if(err.status===400 || err.status===404){
					message.warning(err.data)
				}
				else{
					message.error('退出组织失败')
				}
				
			})
		
    }
 
	_hidemodel = () => {
		const config = {...this.props.getLogoutOrganizationConfig}
        config.visible = false

        this.props.updateLogoutOrganizationConfig(config)
    }
    
	render() {
        const that = this;
		 
		const config = this.props.getLogoutOrganizationConfig
        
		return (
            <>
            
                                    
            <Modal
                    title="提示"
					centered
					closeIcon={
						<ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
					}
                    width={354}
					maskClosable= {false}
                    visible={config.visible}
                    onOk={() => that._hidemodel()}
					onCancel={() => that._hidemodel()}
					footer={[
						<ABB.Button
							onClick={() => that._hidemodel()}
                            type="discreet-black"
                            sizeClass='small' theme="filled"
                            text="取消"
                        />,
						<ABB.Button
						    onClick={() => that.logoutres()}
							type="primary-blue"
                            sizeClass='small' theme="filled"
                            text="确定"
                        />,     
					  ]}
                    >
					确定要退出组织吗?
                </Modal>
            </>
			
		);
	}
}
 

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
      
    updateLogoutOrganizationConfig: data => {
        dispatch(setLogoutOrganizationConfig(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logoutgroup);

