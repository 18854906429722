import moment from 'moment';
const timeConfig = (time) => {
	let today = new Date();
	let _today = moment(today).format("YYYY-MM-DD")
	let _todayminute = moment(today).format("HH:mm:ss")
	let _startime = moment(time.startime).format("YYYY-MM-DD")
	let _endtime = moment(time.endtime).format("YYYY-MM-DD")
	if (_today === _endtime) {
		_endtime = _endtime + " " + _todayminute
	}
	else {
		_endtime = _endtime + " 23:59:59"
	}
	let dateUTCTimeString = moment(_startime).utc().format("YYYY-MM-DD HH:mm:ss");
	let currentUTCTimeString = moment(_endtime).utc().format("YYYY-MM-DD HH:mm:ss");

	let timeobj = {
		startime: dateUTCTimeString,
		endtime: currentUTCTimeString
	}
	return timeobj;
}

export { timeConfig };
