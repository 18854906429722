import React, { Component } from 'react';
import { Input, Table, Spin, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { setcustomersList } from '@/redux/actions/userlist';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { customersList, APIcreateCP, APIchangeCP, EditCustomerName, OrganizationRegInfo } from '@/api/client'
import { ABBorCPcreateEU } from '@/api/user.js';
import WrappedRegistrationForm from './components/createCP'
import CreateEUForm from './components/createEU'
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import UserModel from './components/adduserGroup'
import { APICreateOrg, createEU } from '../../../../api/user';

const { Search } = Input;
const icon_center = {
    margin: '0 10px'
}
const Tooltip_change = <span>编辑</span>;

class ABBclient extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientlist_copy: [],
            customersid: '',
            customers: {},
            modeltype: '',
            showmodelCP: {},
            showmodelEU: {},
            clientlist: [],
            pagination: {},
            showmodel: { value: {} },
            loading: false,
            disabled: false,
            columns: [
                {
                    title: '',
                    dataIndex: '',
                    key: '0',
                    width: 20,

                },
                {
                    title: '编号',
                    dataIndex: 'index',
                    key: '1',
                    width: 50,

                },
                {
                    title: '客户名称',
                    dataIndex: 'name',
                    key: '2',
                    width: 200
                },
                {
                    title: '认证客户名称',
                    dataIndex: 'aliasName',
                    key: '3',
                    width: 150
                },
                {
                    title: '地址信息',
                    dataIndex: 'address',
                    key: '4',
                    width: 200
                },
                {
                    title: '管理员',
                    dataIndex: 'adminContact',
                    key: '5',
                    width: 150,
                },
                {
                    title: '联系电话',
                    dataIndex: 'contactPhone',
                    key: '6',
                    width: 150,
                },
                {
                    title: '创建时间',
                    dataIndex: 'createdOn',
                    key: '7',
                    width: 150,
                },
                {
                    title: '操作',
                    dataIndex: '',
                    key: '6',
                    width: '5%',
                    render: (text, record) => (
                        <>
                            {
                                this.props.userInfo.organizationSubType !== "Float" ? (
                                    <Tooltip placement="bottom" title={Tooltip_change}>
                                        <span onClick={() => this.onchangeName(record)}>
                                            <ABB.Icon name="abb/edit" sizeClass='small' />
                                        </span>
                                    </Tooltip>
                                ) : ''
                            }
                        </>

                    )
                }
            ]
        };
    }


    UNSAFE_componentWillMount() {
        this.fetch();
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }

    //搜索客户
    onSearch = (val) => {
        let _self = this
        let clientlist = _self.state.clientlist_copy;//原始数据
        let deviceArr = []
        const value = val;//获取输入值
        if (val !== '') {

            //搜索内容
            let _value = val.toLowerCase().trim()

            //检查原始数据中是否有该内容
            for (let i = 0; i < clientlist.length; i++) {
                let device = clientlist[i]
                if (
                    (device.name !== null && device.name.toLowerCase().indexOf(_value) !== -1) ||
                    (device.aliasName !== null && device.aliasName.toLowerCase().indexOf(_value) !== -1) ||
                    (device.contactPhone !== null && device.contactPhone.toLowerCase().indexOf(_value) !== -1)
                ) {
                    deviceArr.push(device)

                }
                else {

                    let _children = device.children;
                    let newChildren = [];

                    if (_children !== undefined) {
                        _children.forEach((item, index) => {
                            if (
                                (item.name !== null && item.name.toLowerCase().indexOf(_value) !== -1) ||
                                (item.aliasName !== null && item.aliasName.toLowerCase().indexOf(_value) !== -1) ||
                                (item.contactPhone !== null && item.contactPhone.toLowerCase().indexOf(_value) !== -1)
                            ) {

                                newChildren.push(item);
                            }
                        })

                        if (newChildren.length > 0) {
                            device.children = newChildren;
                            deviceArr.push(device);
                        }


                    }

                }



            }



            _self.props.setcustomersList(deviceArr)
        } else {
            _self.fetch()
        }
    }
    onChange = (e) => {
        if (e.target.value === '') {
            this.fetch()
        }
    }
    onchangeName = (data) => {
        OrganizationRegInfo(data.id).then(res => {
            const resdata = res.data.payload
            this.setState({
                customersid: data.id,
                customers: resdata.creditCode && { label: resdata.aliasName, key: resdata.creditCode } || undefined
            }, () => {
                this.showmodel(true, '编辑组织名称', 1)
            })
        })

    }
    customersListNull = (item) => {
        if (item.address === '' || item.address === null) {
            item.address = '--'
        }
        if (item.aliasName === '' || item.aliasName === null) {
            item.aliasName = '--'
        }
        if (item.createdOn === '' || item.createdOn === null) {
            item.createdOn = '--'
        }
        else {
            item.createdOn = moment.parseZone(item.createdOn).local().format('YYYY-MM-DD HH:mm:ss');
        }
        if (item.adminContact === '' || item.adminContact === null) {
            item.adminContact = '--'
        }
        if (item.contactPhone === '' || item.contactPhone === null) {
            item.contactPhone = '--'
        }
        return item;
    }
    //获取客户列表
    fetch = () => {
        this.setState({
            loading: true
        })
        let url = "";
        customersList(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                resdata.forEach((item, index) => {
                    item.index = index + 1;
                    let myitem = this.customersListNull(item)
                    item = myitem
                    if (item.children.length <= 0) {
                        delete item.children
                    }
                    else {
                        item.children.forEach((items, index) => {
                            items.index = item.index + '.' + (index + 1);
                            if (items.children.length <= 0) {
                                delete items.children
                            }
                            let myitems = this.customersListNull(items)
                            items = myitems
                        })
                    }
                });
                this.setState({
                    clientlist_copy: resdata,
                    clientlist: resdata,
                    loading: false
                })
                this.props.setcustomersList(resdata)
            }
        }).catch(err => {
            this.setState({
                loading: false
            })
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('客户列表接口异常')
            }
        })
    };
    APIcreateCP = (val, func) => {
        this.setState({
            disabled: true
        })


        let Industries = val.industry.split('-')

        if (Industries.length !== 2) {

            message.error('行业信息格式有误')
            return
        }

        let url = {
            "organizationType": 2,
            "name": val.company.label,
            "creditCode": val.company.key.indexOf('null-') === -1 ? val.company.key : '',

            "firstIndustry": Industries[0],//一级行业
            "secondIndustry": Industries[1],//二级行业

            "address": val.companyaddress.trim(),
            "contact": '',
            "phone": val.phone.trim(),

        };

        APICreateOrg(url).then(res => {
            message.success("添加成功")
            func()
            this.setState({
                disabled: false,
                showmodelCP: {
                    visible: false,
                    title: '',
                    value: '',
                    width: 354,
                    centered: true,
                }
            })
            this.fetch()
        }).catch(err => {
            console.log("er========", err)
            if (err.data.code === 400 || err.data.code === 404) {
                message.warning(err.data.errorMessage)
            }
            else {
                message.error('添加客户接口异常')
            }
            this.setState({
                disabled: false
            })
        })
    }
    APIcreateEU = (val, func) => {
        console.log("--------APIcreateEU")


        let Industries = val.industry.split('-')

        if (Industries.length !== 2) {

            message.error('行业信息格式有误')
            return
        }

        let url = {
            "organizationType": 3,
            "name": val.company.label,
            "creditCode": val.company.key.indexOf('null-') === -1 ? val.company.key : '',

            "firstIndustry": Industries[0],//一级行业
            "secondIndustry": Industries[1],//二级行业

            "address": val.companyaddress.trim(),
            "contact": '',
            "phone": val.phone.trim(),

        };



        this.setState({
            disabled: true
        })
        APICreateOrg(url).then(res => {
            message.success("终端用户添加成功")
            func()
            this.setState({
                disabled: false,
                showmodelEU: {
                    visible: false,
                    title: '',
                    value: '',
                    width: 354,
                    centered: true,
                }
            })
            this.fetch()
        }).catch(err => {
            console.log("er========", err)
            if (err.data.code === 400 || err.data.code === 404) {
                message.warning(err.data.errorMessage)
            }
            else {
                message.error('添加客户接口异常')
            }
            this.setState({
                disabled: false
            })
        })
    }
    APIchangeCPFn = (val, func) => {
        if (this.state.showmodel.value && val.company.label === this.state.showmodel.value.label) {
            this.setState({
                showmodel: {
                    visible: false,
                    title: '',
                    value: '',
                    width: 354,
                    centered: true,
                }
            })
            return false;
        }
        this.setState({
            disabled: true
        })
        EditCustomerName({
            organizationId: this.state.customersid,
            organizationName: val.company.label,
            creditCode: val.company.key.indexOf('null-') === -1 ? val.company.key : '',
        }).then(res => {
            message.success("编辑成功")
            func()
            this.setState({
                disabled: false,
                showmodel: {
                    visible: false,
                    title: '',
                    value: {},
                    width: 354,
                    centered: true,
                }
            })
            this.fetch()
        }).catch(err => {
            if (err.status === 400) {
                message.warning(err.data)
            }
            else if (err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('编辑客户接口异常')
            }
            this.setState({
                disabled: false
            })
        })

    }
    hidemodel = (data) => {
        this.setState({
            showmodelCP: {
                visible: data,
                title: '',
                value: '',
                width: 354,
                centered: true,
            },
            showmodelEU: {
                visible: data,
                title: '',
                value: '',
                width: 354,
                centered: true,
            },
            showmodel: {
                visible: data,
                title: '',
                value: {},
                width: 354,
                centered: true,
            }
        })
    }
    submitmodel = (val, func) => {
        let modeltype = this.state.modeltype;
        if (modeltype === 0) {
            this.APIcreateCP(val, func)
        }
        if (modeltype === 1) {
            this.APIchangeCPFn(val, func)
        }
        if (modeltype === 2) {
            this.APIcreateEU(val, func)
        }
    }
    //添加CP
    showmodelCP = (showmodel, title, type) => {
        this.setState({
            modeltype: type,
            showmodelCP: {
                visible: showmodel,
                title: title,
                value: '',
                width: 354,
                centered: true
            }
        })
    }
    //添加CP
    showmodelEU = (showmodel, title, type) => {

        const industries = [...this.props.getShowIndustryList]



        console.log("添加客户-------", industries)
        this.setState({
            modeltype: type,
            showmodelEU: {
                visible: showmodel,
                title: title,
                value: '',
                width: 354,
                centered: true,
            }
        })
    }
    //添加CP
    showmodel = (showmodel, title, type) => {
        this.setState({
            modeltype: type,
            showmodel: {
                visible: showmodel,
                title: title,
                value: this.state.customers,
                width: 354,
                centered: true,
            }
        })
    }
    customExpandIcon = (props) => {

        if (props.record.children !== undefined) {
            if (props.expanded) {
                return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
                    props.onExpand(props.record, e);
                }}><ABB.Icon name="abb/down" sizeClass='small' /></a>
            } else {
                return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
                    props.onExpand(props.record, e);
                }}><ABB.Icon name="abb/right" sizeClass='small' /></a>
            }
        } else {
            return <span style={{ marginRight: 8 }}></span>
        }
    }
    render() {
        let that = this;
        let { getcustomersList, userInfo } = that.props;
        return (
            <>
                <div className="user_box ABB_tab_marginTop">
                    {
                        (userInfo.isOrgAdministrator === true && userInfo.organizationSubType !== "Float") ? (

                            <div>
                                <ABB.Button
                                    style={{ marginRight: 32 }}
                                    onClick={() => this.showmodelEU(true, '添加终端用户', 2)}
                                    type="primary-blue"
                                    sizeClass='small' theme="filled"
                                    text="添加客户"
                                    icon="abb/plus-in-circle"
                                />
                                <ABB.Button
                                    // className="ABB_tab_marginTop"
                                    onClick={() => this.showmodelCP(true, '添加渠道伙伴', 0)}
                                    type="primary-blue"
                                    sizeClass='small' theme="filled"
                                    text="添加渠道"
                                    icon="abb/plus-in-circle"
                                />
                            </div>







                        ) : (<div></div>)
                    }
                    <Search
                        placeholder="请输入客户名称/手机号"
                        onSearch={value => this.onSearch(value)}
                        onChange={this.onChange}
                        style={{ width: 200 }}
                    />
                </div>
                <Spin spinning={this.state.loading} style={{ height: '90vh' }}>
                    < Table
                        size='small'
                        scroll={{ x: 1000 }
                        }
                        className='org_right abb_client_class'
                        ellipsis={true}
                        expandIcon={(props) => that.customExpandIcon(props)
                        }
                        columns={that.state.columns}
                        dataSource={getcustomersList}
                        pagination={false}
                        rowKey={(record, index) => record.id}
                    />
                </Spin>


                <WrappedRegistrationForm
                    showmodelCP={that.state.showmodelCP}
                    hidemodel={that.hidemodel}
                    disabled={that.state.disabled}
                    submitmodel={that.submitmodel}
                />
                <CreateEUForm
                    showmodelEU={that.state.showmodelEU}
                    hidemodel={that.hidemodel}
                    disabled={that.state.disabled}
                    submitmodel={that.submitmodel}
                />
                <UserModel
                    showmodel={that.state.showmodel}
                    hidemodel={that.hidemodel}
                    disabled={that.state.disabled}
                    submitmodel={that.submitmodel}
                />
            </>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setcustomersList: data => {
        dispatch(setcustomersList(data));
    },
    setUserInfo: data => {
        dispatch(setUserInfo(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ABBclient));

