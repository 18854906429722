/**
 * Created by hao.cheng on 2017/4/21.
 */
import React, { Component } from 'react';
import ReactEcharts from 'echarts-for-react';
import { assetsandsubscriptions, conditionAvailability, conditionEnvironment, conditionReliability, conditionStress, assetByType, assetBySubscription } from '@/api/chart.js'

class EchartsPie extends Component {
    constructor(props) {
        super(props);
        this.state = {
            colorList: ['#0CA919', '#FFD800', '#F03040', '#DBDBDB', '#999'],
            seriesData: {},
            availability: [
                { value: 0, name: '良好' },
                { value: 0, name: '达标' },
                { value: 0, name: '不良' },
                { value: 0, name: '未知' },
                { value: 0, name: '不支持' },
            ],
            environment: [
                { value: 0, name: '良好' },
                { value: 0, name: '达标' },
                { value: 0, name: '不良' },
                { value: 0, name: '未知' },
                { value: 0, name: '不支持' },
            ],
            reliability: [
                { value: 0, name: '良好' },
                { value: 0, name: '达标' },
                { value: 0, name: '不良' },
                { value: 0, name: '未知' },
                { value: 0, name: '不支持' },
            ],
            stress: [
                { value: 0, name: '良好' },
                { value: 0, name: '达标' },
                { value: 0, name: '不良' },
                { value: 0, name: '未知' },
                { value: 0, name: '不支持' },
            ],
            assetByType: [
                { value: 0, name: '传动' },
                { value: 0, name: '电机' },
                { value: 0, name: '传动链' },
            ],
            assetBySubscription: [
                { value: 0, name: '正式' },
                { value: 0, name: '试用' },
                // { value: 0, name: '体验' },
                { value: 0, name: '过期' },
            ],
            assetBySubscriptionlevel: [
                { value: 0, name: '入门' },
                { value: 0, name: '基础' },
                { value: 0, name: '标准' },
                { value: 0, name: '高级' },
            ]
        };
        // this.changeData  = this.changeData.bind(this);
        this.fetch = this.fetch.bind(this);
    }

    // changeData(){
    //     this.setState({
    //         seriesData: {}
    //     });
    // }

    fetch = () => {
        if (this.props.content === 1) {
            conditionAvailability().then(res => {
                if (res !== undefined) {
                    let resdata = res.data.data;
                    let availability = [
                        { value: 0, name: '良好' },
                        { value: 0, name: '达标' },
                        { value: 0, name: '不良' },
                        { value: 0, name: '未知' },
                        { value: 0, name: '不支持' },
                    ];
                    for (var i in resdata) {
                        if (resdata[i].conditionStatus === 'good') {
                            availability[0].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'tolerable') {
                            availability[1].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'poor') {
                            availability[2].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'unknown') {
                            availability[3].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'notsupport') {
                            availability[4].value = resdata[i].deviceCount;
                        }
                    }

                    let option = {

                        tooltip: {
                            trigger: 'item',
                            // formatter: "{a} <br/>{b} : {c} ({d}%)"
                        },

                        legend: {  //图例
                            show: false,
                        },
                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: ['50%', '75%'],
                                center: ['50%', '50%'],
                                clockwise: false,
                                data: availability,
                                label: {
                                    show: false
                                },

                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            //自定义颜色
                                            var colorList = ['#0CA919', '#FFD800', '#F03040', '#DBDBDB', '#999'];
                                            return colorList[params.dataIndex]
                                        },
                                        // shadowBlur: 200,
                                        // shadowColor: '#777'
                                    }
                                },

                                animationType: 'scale',
                                animationEasing: 'elasticOut',
                                animationDelay: function (idx) {
                                    return Math.random() * 200;
                                }
                            }
                        ]
                    }

                    this.setState({
                        seriesData: option,
                        availability: availability
                    })
                }
            })
        }
        if (this.props.content === 2) {
            conditionEnvironment().then(res => {
                if (res !== undefined) {
                    let resdata = res.data.data;
                    let environment = [
                        { value: 0, name: '良好' },
                        { value: 0, name: '达标' },
                        { value: 0, name: '不良' },
                        { value: 0, name: '未知' },
                        { value: 0, name: '不支持' },
                    ];
                    for (var i in resdata) {
                        if (resdata[i].conditionStatus === 'good') {
                            environment[0].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'tolerable') {
                            environment[1].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'poor') {
                            environment[2].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'unknown') {
                            environment[3].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'notsupport') {
                            environment[4].value = resdata[i].deviceCount;
                        }
                    }
                    let option = {

                        tooltip: {
                            trigger: 'item',
                            // formatter: "{a} <br/>{b} : {c} ({d}%)"
                        },

                        legend: {  //图例
                            show: false,

                        },

                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: ['50%', '75%'],
                                center: ['50%', '50%'],
                                clockwise: false,
                                data: environment,
                                label: {
                                    show: false
                                },

                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            //自定义颜色
                                            var colorList = ['#0CA919', '#FFD800', '#F03040', '#DBDBDB', '#999'];
                                            return colorList[params.dataIndex]
                                        },
                                        // shadowBlur: 200,
                                        // shadowColor: '#777'
                                    }
                                },

                                animationType: 'scale',
                                animationEasing: 'elasticOut',
                                animationDelay: function (idx) {
                                    return Math.random() * 200;
                                }
                            }
                        ]
                    }
                    this.setState({
                        seriesData: option,
                        environment: environment
                    })
                }
            })
        }
        if (this.props.content === 3) {
            conditionReliability().then(res => {
                if (res !== undefined) {
                    let resdata = res.data.data;
                    let reliability = [
                        { value: 0, name: '良好' },
                        { value: 0, name: '达标' },
                        { value: 0, name: '不良' },
                        { value: 0, name: '未知' },
                        { value: 0, name: '不支持' },
                    ];
                    for (var i in resdata) {
                        if (resdata[i].conditionStatus === 'good') {
                            reliability[0].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'tolerable') {
                            reliability[1].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'poor') {
                            reliability[2].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'unknown') {
                            reliability[3].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'notsupport') {
                            reliability[4].value = resdata[i].deviceCount;
                        }
                    }
                    let option = {

                        tooltip: {
                            trigger: 'item',
                            // formatter: "{a} <br/>{b} : {c} ({d}%)"
                        },

                        legend: {  //图例
                            show: false
                        },

                        // color: ['#1790cf','#1bb2d8','#99d2dd','#88b0bb','#1c7099','#038cc4','#75abd0','#afd6dd'],
                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: ['50%', '75%'],
                                center: ['50%', '50%'],
                                clockwise: false,
                                data: reliability,
                                label: {
                                    show: false
                                },

                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            //自定义颜色
                                            var colorList = ['#0CA919', '#FFD800', '#F03040', '#DBDBDB', '#999'];
                                            return colorList[params.dataIndex]
                                        },
                                        // shadowBlur: 200,
                                        // shadowColor: '#777'
                                    }
                                },

                                animationType: 'scale',
                                animationEasing: 'elasticOut',
                                animationDelay: function (idx) {
                                    return Math.random() * 200;
                                }
                            }
                        ]
                    }
                    this.setState({
                        seriesData: option,
                        reliability: reliability
                    })
                }
            })
        }
        if (this.props.content === 4) {
            conditionStress().then(res => {
                if (res !== undefined) {
                    let resdata = res.data.data;
                    let stress = [
                        { value: 0, name: '良好' },
                        { value: 0, name: '达标' },
                        { value: 0, name: '不良' },
                        { value: 0, name: '未知' },
                        { value: 0, name: '不支持' }
                    ];
                    for (var i in resdata) {
                        if (resdata[i].conditionStatus === 'good') {
                            stress[0].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'tolerable') {
                            stress[1].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'poor') {
                            stress[2].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'unknown') {
                            stress[3].value = resdata[i].deviceCount;
                        }
                        if (resdata[i].conditionStatus === 'notsupport') {
                            stress[4].value = resdata[i].deviceCount;
                        }
                    }
                    let option = {

                        tooltip: {
                            trigger: 'item',
                            // formatter: "{a} <br/>{b} : {c} ({d}%)"
                        },

                        legend: {  //图例
                            show: false
                        },

                        // color: ['#1790cf','#1bb2d8','#99d2dd','#88b0bb','#1c7099','#038cc4','#75abd0','#afd6dd'],
                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: ['50%', '75%'],
                                center: ['50%', '50%'],
                                clockwise: false,
                                data: stress,
                                label: {
                                    show: false
                                },

                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            //自定义颜色
                                            var colorList = ['#0CA919', '#FFD800', '#F03040', '#DBDBDB', '#999'];
                                            return colorList[params.dataIndex]
                                        },
                                        // shadowBlur: 200,
                                        // shadowColor: '#777'
                                    }
                                },

                                animationType: 'scale',
                                animationEasing: 'elasticOut',
                                animationDelay: function (idx) {
                                    return Math.random() * 200;
                                }
                            }
                        ]
                    }

                    // let data = option.series.data
                    // this.setState({
                    //     data: 
                    // });
                    this.setState({
                        seriesData: option,
                        stress: stress
                    })
                }
            })
        }
        if (this.props.content === 5) {
            assetByType().then(res => {
                if (res !== undefined) {
                    let resdata = res.data;
                    let assetByType = [
                        { value: 0, name: '传动' },
                        { value: 0, name: '电机' },
                        { value: 0, name: '传动链' },
                    ];
                    assetByType[0].value = resdata.drive;
                    assetByType[1].value = resdata.motor;
                    assetByType[2].value = resdata.powertrain;
                    let option = {

                        tooltip: {
                            trigger: 'item',
                            // formatter: "{a} <br/>{b} : {c} ({d}%)"
                        },

                        legend: {  //图例
                            show: false
                        },

                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: ['50%', '75%'],
                                center: ['50%', '50%'],
                                clockwise: false,
                                data: assetByType,
                                label: {
                                    show: false
                                },

                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            //自定义颜色
                                            var colorList = ['#0CA919', '#FFD800', '#F03040'];
                                            return colorList[params.dataIndex]
                                        },
                                        // shadowBlur: 200,
                                        // shadowColor: '#777'
                                    }
                                },

                                animationType: 'scale',
                                animationEasing: 'elasticOut',
                                animationDelay: function (idx) {
                                    return Math.random() * 200;
                                }
                            }
                        ]
                    }

                    this.setState({
                        seriesData: option,
                        assetByType: assetByType
                    })
                }
            })
        }
        if (this.props.content === 6) {
            assetsandsubscriptions().then(res => {
                if (res !== undefined) {
                    let resdata = res.data.subscriptions;
                    let assetBySubscription = [
                        { value: 0, name: '正式' },
                        { value: 0, name: '试用' },
                        // { value: 0, name: '体验' },
                        { value: 0, name: '过期' },
                    ];
                    assetBySubscription[0].value = resdata.activeTypes.official;
                    assetBySubscription[1].value = resdata.activeTypes.trial+resdata.activeTypes.taste;
                    // assetBySubscription[2].value = resdata.activeTypes.taste;
                    assetBySubscription[2].value = resdata.expired;


                    let option = {

                        tooltip: {
                            trigger: 'item',
                            // formatter: "{a} <br/>{b} : {c} ({d}%)"
                        },

                        legend: {  //图例
                            show: false
                        },

                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: ['50%', '75%'],
                                center: ['50%', '50%'],
                                clockwise: false,
                                data: assetBySubscription,
                                label: {
                                    show: false
                                },

                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            //自定义颜色

                                            var colorList = ['#0CA919', '#FFD800', '#FF7300', '#F03040'];
                                            return colorList[params.dataIndex]
                                        },
                                        // shadowBlur: 200,
                                        // shadowColor: '#777'
                                    }
                                },

                                animationType: 'scale',
                                animationEasing: 'elasticOut',
                                animationDelay: function (idx) {
                                    return Math.random() * 200;
                                }
                            }
                        ]
                    }

                    this.setState({
                        seriesData: option,
                        assetBySubscription: assetBySubscription
                    })
                }
            })
        }
        if (this.props.content === 7) {
            assetsandsubscriptions().then(res => {
                if (res !== undefined) {
                    let resdata = res.data.subscriptions;
                    let assetBySubscription = [
                        { value: 0, name: '入门' },
                        { value: 0, name: '基础' },
                        { value: 0, name: '标准' },
                        { value: 0, name: '高级' },
                    ];
                    assetBySubscription[0].value = resdata.officalLevels.entry;
                    assetBySubscription[1].value = resdata.officalLevels.basic;
                    assetBySubscription[2].value = resdata.officalLevels.standard;
                    assetBySubscription[3].value = resdata.officalLevels.premium;

                    let option = {

                        tooltip: {
                            trigger: 'item',
                            // formatter: "{a} <br/>{b} : {c} ({d}%)"
                        },

                        legend: {  //图例
                            show: false
                        },

                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: ['50%', '75%'],
                                center: ['50%', '50%'],
                                clockwise: false,
                                data: assetBySubscription,
                                label: {
                                    show: false
                                },

                                itemStyle: {
                                    normal: {
                                        color: function (params) {
                                            //自定义颜色
                                            var colorList = ['#5EE469', '#28D236', '#0ca919', '#088212'];
                                            return colorList[params.dataIndex]
                                        },
                                        // shadowBlur: 200,
                                        // shadowColor: '#777'
                                    }
                                },

                                animationType: 'scale',
                                animationEasing: 'elasticOut',
                                animationDelay: function (idx) {
                                    return Math.random() * 200;
                                }
                            }
                        ]
                    }

                    this.setState({
                        seriesData: option,
                        assetBySubscriptionlevel: assetBySubscription
                    })
                }
            })
        }
    }
    componentDidMount() {
        this.fetch();
    }
    pieElement = (data, colorList) => {
        return (
            <div className='Piecharts_box'>
                {
                    data.map((item, index) => {
                        return (
                            <div className='charts_list'>
                                <div className='charts_num'>{item.value}</div>
                                <div className='charts_content'>
                                    <div className='charts_icon' style={{ background: colorList[index] }}></div>
                                    <div>{item.name}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    isShow = (type) => {
        let { availability, environment, reliability, stress, assetByType, assetBySubscription, assetBySubscriptionlevel, colorList } = this.state;
        if (type === 1) {
            return this.pieElement(availability, colorList)
        }
        if (type === 2) {
            return this.pieElement(environment, colorList)
        }
        if (type === 3) {
            return this.pieElement(reliability, colorList)
        }
        if (type === 4) {
            return this.pieElement(stress, colorList)
        }
        if (type === 5) {
            return this.pieElement(assetByType, ['#0CA919', '#FFD800', '#F03040'])
        }
        if (type === 6) {
            return this.pieElement(assetBySubscription, ['#0CA919', '#FFD800', '#FF7300', '#F03040'])
        }
        if (type === 7) {
            return this.pieElement(assetBySubscriptionlevel, ['#5EE469', '#28D236', '#0ca919', '#088212'])
        }
    }
    render() {

        let { content } = this.props;
        return (
            <>
                <ReactEcharts
                    option={this.state.seriesData}
                    style={{ height: '200px', width: '100%' }}
                    className={'react_for_echarts'}
                />
                {
                    this.isShow(content)
                }

            </>

        );
    }
}
export default EchartsPie;