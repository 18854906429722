import React, { Component } from 'react';
import { message } from 'antd';
import { secretkey, applicationkey, createsecretkey } from '@/api/apikey'

import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo'
import { withRouter } from 'react-router-dom';

import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import moment from 'moment';
import CreateSecretkey from './components/createsecretkey'
import AfreshSecretkey from './components/AfreshSecretkey'

class APIauth extends Component {
    constructor(props) {
        super(props)
        this.state = {
            secretkeydata: '',
            applicationkeydata: '',
            disabled: false,
            disabledSK: false,
            showmodel: {
                visible: false,
                sk: '',
            },
            showmodelAfreshSecretkey: {
                visible: false,
                apikey: ''
            }
        }
    };
    componentDidMount() {
        this.props.onRef(this)
    }
    getOrgData = () =>{
        this.getapplicationkey();
        this.fetch();
    }
    fetch = () => {
        if (this.props.userInfo === "") {
            setTimeout(() => {
                this.fetch()
            }, 400)
        }
        else {
            let url = ``;
            secretkey(url).then(res => {
                if (res !== undefined) {
                    this.setState({
                        secretkeydata: res.data
                    })
                }

            }).catch(err => {
                message.error('获取失败！')
            })
        }

    }

    getapplicationkey = () => {

        let url = '';
        applicationkey(url).then(res => {
            if (res !== undefined) {
                this.setState({
                    applicationkeydata: res.data
                })
            }

        }).catch(err => {
            message.error('获取失败！')
        })
    }
    hidemodel = () => {
        this.setState({
            showmodel: {
                visible: false,
                sk: '',
            },
            showmodelAfreshSecretkey: {
                visible: false,
                apikey: ''
            }
        })
        this.fetch();
        this.getapplicationkey();
    }
    submitmodel = (type) => {
        this.fetch();
        this.getapplicationkey();
        if (type === 0) {
            this.setState({
                showmodel: {
                    visible: false,
                    sk: '',
                }
            })
        }
        if (type === 1) {
            let url = '';
            this.setState({
                disabledSK: true
            })
            createsecretkey(url).then(res => {
                message.success("重新生成Secret Key成功！")
                this.setState({
                    disabledSK: false,
                    showmodelAfreshSecretkey: {
                        visible: false,
                        apikey: ''
                    },
                    showmodel: {
                        visible: true,
                        sk: res.data.secretText,
                    }
                })
            }).catch(err => {
                message.error('生成失败！')
                this.setState({
                    disabledSK: false
                })
            })

        }
    }
    onCreateSecretkey = () => {
        let url = '';
        this.setState({
            disabled: true,
            disabledSK: true,
        })
        createsecretkey(url).then(res => {
            message.success("生成Secret Key成功！")
            this.setState({
                showmodel: {
                    visible: true,
                    sk: res.data.secretText,
                },
                disabled: false,
                disabledSK: false
            })
            
        }).catch(err => {
            message.error('生成失败！')
            this.setState({
                disabled: false,
                disabledSK: false
            })
        })
    }
    onAfreshsk = () => {
        this.setState({
            showmodelAfreshSecretkey: {
                visible: true,
                apikey: this.state.applicationkeydata.applicationKey
            }
        })
    }
    render() {
        let { applicationkeydata, secretkeydata, showmodel, showmodelAfreshSecretkey,disabledSK } = this.state;
        let objnull = (JSON.stringify(secretkeydata) === "{}");
        return (
            <div className="shadow-box">
                {
                    applicationkeydata !== '' ? (
                        <>
                            <div className="api_title">
                                API访问权限
                            </div>
                            <div className="api_list">
                                <div className="api_label">API Key：</div>
                                <div className="api_text">{applicationkeydata.applicationKey}</div>
                            </div>

                            <div className="api_list">
                                <div className="api_label">API授权日期：</div>
                                <div className="api_text">{moment.parseZone(applicationkeydata.activeAuthrizationDate).local().format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div>


                            <div className="api_list">
                                <div className="api_label">API授权截止日期：</div>
                                <div className="api_text">{moment.parseZone(applicationkeydata.expiredDate).local().format('YYYY-MM-DD HH:mm:ss')}</div>
                            </div>
                        </>
                    ) : ''
                }

                {
                    applicationkeydata.authrizationExpired === false ? (
                        <>

                            {
                                objnull === true ? (
                                    
                                        <div className="api_list">
                                            <ABB.Button
                                                onClick={() => this.onCreateSecretkey()}
                                                disabled={this.state.disabled}
                                                
                                                type="primary-blue"
                                                sizeClass='small' theme="filled"
                                                text="生成Secret Key"
                                            />
                                        </div>
                                   
                                ) : (
                                    <>
                                    <div className="api_title" style={{ marginTop: 32 }}>
                                            Secret Key
                                        </div>
                                        <div className="api_list">
                                            <div className="api_label">Secret Key：</div>
                                            <div className="api_text">******</div>
                                            <div className="api_Secret_key"
                                                onClick={() => this.onAfreshsk()}
                                            >重新生成</div>
                                        </div>
                                        <div className="api_list">
                                            <div className="api_label">Secret Key生成日期：</div>
                                            <div className="api_text">{moment.parseZone(secretkeydata.secretKeyGenerateKeyDate).local().format('YYYY-MM-DD HH:mm:ss')}</div>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    ) : ''
                }


                <CreateSecretkey
                    showmodel={showmodel}
                    hidemodel={this.hidemodel}
                    disabled = {disabledSK}
                    submitmodel={this.submitmodel}
                />
                <AfreshSecretkey
                    showmodel={showmodelAfreshSecretkey}
                    hidemodel={this.hidemodel}
                    disabled = {disabledSK}
                    submitmodel={this.submitmodel}
                />
            </div>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({

    setUserInfo: data => {
        dispatch(setUserInfo(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(APIauth));
