import React, { Component } from 'react';
import { Input, Table, Select, message } from 'antd';
 
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { APIuploadAddresses } from '@/api/tools.js';
import { APIQueryAssetLocations } from '@/api/tools.js';
import { setDebugingList, setToolAssetLocations } from '../../store/actionCreators';
 
 
 
import Dragger from 'antd/lib/upload/Dragger';
import AddressModel from './components/addressModel'
const div_nowrap = {
	display: 'inline',
	margin: '0 30px 0 0'
};

const { Search } = Input;

class LocationManagement extends Component {
	state = {
		showModel: {
			visible: false,
			type: 0,
			message: ''
		},
		pagination: {},
		loading: false,
		modal2Visible: false,
		selectedRowKeys: [],
		pageIndex: 0,
		pageSize: 10,
		total: 0,
		search: {
			type: 0,
			searchTitle: ''
		},
		columns: [
			{
				title: '编号',
				dataIndex: 'deviceName',
				key: '0',
				width: '5%',
				render: (text, record, index) => (
					<span>{index + 1}</span>
				)
			},
			{
				title: '传动序列号',
				dataIndex: 'deviceSn',
				key: '1',
				width: '15%'
			},
			{
				title: '经纬度',
				dataIndex: 'longitude',
				key: '2',
				width: '13%',
				render: (text, record, index) => (
					<span>{record.longitude},{record.latitude}</span>
				)
			},
			{
				title: '省市区',
				dataIndex: 'address',
				key: '3',
				width: '20%'
			},

			{
				title: '添加人',
				dataIndex: 'modifiedBy',
				key: '4',
				width: '10%'
			},
			{
				title: '添加时间',
				dataIndex: 'lastUpdate',
				key: '5',
				width: '10%',
				render: (text, record, index) => (
					<span>{moment.parseZone(record.lastUpdate).local().format("YYYY/MM/DD HH:mm:ss")}</span>
				)
			},

		]
	};

	componentDidMount() {
		const { search, pageIndex, pageSize } = this.state
		this._requestDebugingList(pageIndex, pageSize)
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}



	hidemodel = () => {
		console.log("dsfgsdfgdfgsd")
		this.setState({
			showModel: {
				visible: false,
				type: -1,
				message: ''
			}

		})
	}
	submitmodel = () => {

	}

	//接口数据
	_requestDebugingList = (pageIndex, pageSize) => {

		this.setState({
			loading: true
		});
		APIQueryAssetLocations(pageIndex, pageSize)
			.then(res => {

				console.log("APIQueryDebugingList======================", res)

				if (res !== undefined) {
					let resdata = res.data;
					this.setState({
						total: resdata.data.total
					})
					this.props.updateAssetLocations(resdata.data.list)

				}
			})
			.catch(err => {
				console.log("APIQueryDebugingList=============err=========", err)
				// if (err.data.status === 400 || err.data.status === 404) {
				// 	message.warning(err.data.title);
				// } else {
				// 	message.error('列表接口异常');
				// }
			});
	};

	onChange = (e) => {
		// console.log("eeee", e)
		this.setState({
			pageIndex: e - 1
		}, () => {
			this._requestDebugingList(this.state.pageIndex, this.state.pageSize)
		})
	}

	configAlertData = (data) => {
		const { totalCount, successCount } = data

		if (successCount === 0) {
			return {
				type: 2,
				message: `导入失败，共${totalCount}条位置记录`
			}
		}

		if (successCount === totalCount) {
			return {
				type: 0,
				message: `导入成功，共${successCount}条位置记录`
			}
		}
		if (totalCount > successCount) {
			return {
				type: 1,
				message: `导入完成，成功导入${successCount}条位置记录，失败了${totalCount - successCount}条位置记录`
			}
		}
	}

	customRequest = async (options) => {

		const formData = new FormData();

		formData.append('File', options.file);

		let hide = message.loading('正在上传...', 0);


		APIuploadAddresses(formData).then(res => {
			console.log("APIuploadAddresses---", res.data)

			let resdata = res.data;
			if (resdata.code === 200) {
				hide()
				// message.success('位置导入成功')
				let data = this.configAlertData(resdata.data)

				this.setState({
					showModel: {
						visible: true,
						...data
					}
				})
				this._requestDebugingList(this.state.pageIndex, this.state.pageSize)
			}
			else {
				hide() 
				message.warning("上传失败，请检查文件内容")
			}
		}).catch(err => {
			message.error('上传失败，请检查文件内容')
			hide()
		})
	}
 
	_impotAddress = () => {

	}

	render() {
		let { pageSize, pageIndex, total } = this.state;

		const props = {
			name: 'file',
			multiple: true,
			showUploadList: false,
			accept: '.csv',
			// beforeUpload: this.beforeUpload,
			customRequest: this.customRequest

		};

		let locations = this.props.getToolAssetLocations
		// console.log("locations==============", locations)
		return (
			<>
				<div className="table_header ABB_tab_marginTop gatewaymin">

					<a href="https://prod-columbus-front-end.obs.cn-north-4.myhuaweicloud.com/assetmodel.csv">
						<div className='button_box' >
							<ABB.Icon className='button_icon' name="abb/download" sizeClass='small' />
							下载模板
						</div>
					</a>


					<Dragger {...props}  >
						<div className='button_box right' onClick={this._impotAddress}>
							<ABB.Icon className='button_icon' name="abb/import" sizeClass='small' />
							导入位置信息</div>

					</Dragger>
				</div>


				<Table
					size="small"
					scroll={{ x: 740 }}
					columns={this.state.columns}
					dataSource={locations}
					ellipsis={true}
					pagination={{
						onChange: page => this.onChange(page),
						pageSize: pageSize,
						current: pageIndex + 1,
						total: total
					}}
					rowKey={(record, index) => index}
				/>

				<AddressModel showModel={this.state.showModel} hidemodel={this.hidemodel} />
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	updateAssetLocations: data => {
		dispatch(setToolAssetLocations(data));
	}

});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LocationManagement));
