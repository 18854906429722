export const SET_USERINFO = 'SET_USERINFO';
export const ADD_TAG = 'ADD_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const EMPTY_TAG = 'EMPTY_TAG';
export const SET_BREADCRUMB = 'SET_BREADCRUMB';
export const SET_TAGS = 'SET_TAGS';
export const SET_THEME = 'SET_THEME';
export const SET_COLLAPSE = 'SET_COLLAPSE';
export const SELECT_USERGROUP = 'SELECT_USERGROUP';
export const SELECT_EQUIPMENT = 'SELECT_EQUIPMENT';
export const DEVICE_DETAIL = 'DEVICE_DETAIL';
export const CHECKALL = 'CHECKALL';
export const EVENT_DETAIL = 'EVENT_DETAIL';
export const DEVICE_TIME = 'DEVICE_TIME';
export const LOADING = 'LOADING';
export const USER_CHECK_DETAIL = 'USER_CHECK_DETAIL';
export const LANGUAGE = 'LANGUAGE';
export const GET_SELECT = 'GET_SELECT';
export const SET_PLANT = 'SET_PLANT';
export const SET_TREE = 'SET_TREE';
export const SET_TOPDEVICE = 'SET_TOPDEVICE';
export const SET_FILTERDEVICE = 'SET_FILTERDEVICE';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_SERCHUSER = 'SET_SERCHUSER';
export const SET_ASSETID = 'SET_ASSETID';
export const SET_EVENT = 'SET_EVENT';
export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';
export const SET_SUBID = 'SET_SUBID';
export const SET_ASSETTYPE = 'SET_ASSETTYPE';
export const SET_BATCHASSET = 'SET_BATCHASSET';
export const SET_SUBSELECT = 'SET_SUBSELECT';
export const SET_ORGINFORMATION = 'SET_ORGINFORMATION';
export const SIGNAL_THREE = 'SIGNAL_THREE';
export const SET_TREESTATUS = 'SET_TREESTATUS';
export const SET_TREESELECT = 'SET_TREESELECT';
export const SET_ADDPLANTTYPE = 'SET_ADDPLANTTYPE';
export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const SET_ADDRESSCHANGE = 'SET_ADDRESSCHANGE';
export const SET_TREECOPY = 'SET_TREECOPY';
export const SET_TREVALUE = 'SET_TREVALUE';
export const SET_PLANTISSHOW = 'SET_PLANTISSHOW';
export const SET_ADDDEVICEPROCESS = 'SET_ADDDEVICEPROCESS';
export const NEWS_MODELISSHOW = 'NEWS_MODELISSHOW';
export const SET_EVENTCOUNT = 'SET_EVENTCOUNT';
export const ROUTER_LINK = 'ROUTER_LINK';
export const CBM_DATA = 'CBM_DATA';
export const CBM_TYPE = 'CBM_TYPE';
export const SET_TRENDSCODE = 'SET_TRENDSCODE';
export const SET_TRENDSTAG = 'SET_TRENDSTAG';
export const CHARTS_TIME = 'CHARTS_TIME';
export const SET_SUBSCRIPTIONCOF = 'SET_SUBSCRIPTIONCOF';
export const RADIOVALUE = 'RADIOVALUE';
export const CMD_DATA = 'CMD_DATA';
export const CMD_DEBUG = 'CMD_DEBUG';
export const EVENTDETAIL = 'EVENTDETAIL';
export const EVENTDETAIL_STATUS = 'EVENTDETAIL_STATUS';
export const RES_TRENDSCODE = 'RES_TRENDSCODE';
export const TRENDSDATA = 'TRENDSDATA';
export const ASSETSTATUS = 'ASSETSTATUS';
export const ASSET_ISPOWERTRAIN = 'ASSET_ISPOWERTRAIN';
export const ASSET_IDCOPY = 'ASSET_IDCOPY';
export const BATCHIMPORT = 'BATCHIMPORT';
export const ASSET_LABEL = 'ASSET_LABEL';
export const ASSET_DETAIL = 'ASSET_DETAIL';
export const TREND_EVENTTAG = 'TREND_EVENTTAG';
export const TREND_EVENTCODE = 'TREND_EVENTCODE';
export const ACTIVE_TAB = 'ACTIVE_TAB';
export const EVENTDATA = 'EVENTDATA';

//设备巡检数据
export const INSPECTION = 'INSPECTION'
 

//组织信息
export const MAIN_ORGINFO = 'MAIN_ORGINFO'