import React, { Component } from 'react';
import { Modal, Button, Input, message, Select, Form } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Search } = Input;
class DeviceEditModelNetaForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            groupId: '',
            deviceGroupName: undefined,
            disabled: false,
            isZn_CH: false
        };

    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.props.form.resetFields();
    }

    submitmodel = (data) => {
        let that = this;
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.props.submitmodel(fieldsValue)
                setTimeout(() => {
                    this.props.form.resetFields();
                }, 1000)
            }
        });
    }

    groupChange = (e) => {
        this.setState({
            groupId: e,
            deviceGroupName: e
        })
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
            }
        });
    };
    render() {
        const that = this;
        const { showassetGroupmodel,disabled } = this.props;
        const { getFieldDecorator } = that.props.form;


        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showassetGroupmodel.title,
            centered: showassetGroupmodel.centered,
            width: showassetGroupmodel.width,
            visible: showassetGroupmodel.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    disabled={disabled}
                    onClick={() => that.submitmodel()}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>



                <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>
                    <Form.Item label="设备名称">
                        {getFieldDecorator('deviceName', {
                            initialValue: showassetGroupmodel.value,
                            rules: [

                                {
                                    required: true,
                                    message: '设备名称不能为空',
                                },
                                {
                                    max: 20,
                                    message: '设备名称长度不能大于20',
                                }
                            ],
                        })(
                            <Input placeholder="请输入设备名称" className="model_input" />,
                        )}
                    </Form.Item>

                    <Form.Item label="移动到设备组">
                        {getFieldDecorator('deviceGroupName', {
                            initialValue: showassetGroupmodel.deviceGroupName,
                            rules: [{ required: true, message: '请选择设备组' }],
                        })(
                            <Select
                                // placeholder={showassetGroupmodel.deviceGroupName}
                                suffixIcon={
                                    <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                }
                                // disabled={showassetGroupmodel.isOrgAdministrator === true ? false : true}
                                // value={this.state.deviceGroupName}
                                style={{ 'width': '100%' }}
                            // onChange={that.groupChange}
                            >
                                {
                                    showassetGroupmodel.movegrouplist.map(item => {

                                        return (
                                            <Select.Option value={item.id}>{item.name}</Select.Option>
                                        )
                                    })
                                }


                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label="NETA-21序列号">
                        {getFieldDecorator('netacode', {
                            initialValue: showassetGroupmodel.neta21sn,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入NETA-21序列号'
                                },
                                {
                                    max: 15,
                                    message: '字符最大长度为15'
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            <Input className="model_input"
                                placeholder="请输入NETA-21序列号"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label="传动节点ID">
                        {getFieldDecorator('nodeid', {
                            initialValue: showassetGroupmodel.nodeid,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入传动节点ID'
                                },
                                {
                                    min: 2,
                                    message: '字符最小长度2'
                                },
                                {
                                    whitespace: true,
                                    message: '禁止输入空格',
                                }
                            ],
                        })(
                            
                            <Input
                                placeholder="请输入传动节点ID"
                                type="Number"
                                className="model_input"
                            />
                        )}
                    </Form.Item>

                </Form>


                <div className="deviceformbox"
                style={{
                    position: 'absolute',
                    top: 318,
                    right: 32
                }}
                >
                    <a href="https://sycfxrsxgy.jiandaoyun.com/sharedoc/74MdQ1XMwI2mZKbhkDXb0l" target="_blank" >
                        <ABB.Icon className="deviceformcheckboxIcon" name="abb/help-circle-2" sizeClass='small' />
                    </a>
                </div>

            </Modal>
        );
    }
}


const DeviceEditModelNeta = Form.create({ name: 'register' })(DeviceEditModelNetaForm);

export default DeviceEditModelNeta;
// export default DeviceEditModelNeta;
