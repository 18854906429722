//添加位置
import React, { Component } from 'react';
import { Modal, Button, Input, message } from 'antd';
import { addplant, plantList } from '@/api/plant';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import Amap from './plantaddressmap';
import { connect } from 'react-redux';
import { setplant, setaddress, setaddressChange } from '@/redux/actions/plant';
import { withRouter } from 'react-router-dom';
import { plantListdata } from '@/utils/devicesData';

const { Search } = Input;
class Addplant extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputval: '',
			disabled: false,
			isZn_CH: false,
			addPlantdata: false,
			addressObj: {},
			addPlantval: '',
			latitude: '',
			longitude: '',
		};

	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	//确认添加位置接口
	resaddplant() {
		let that = this;
		let { getaddress, userInfo } = that.props;
		let objnull = (JSON.stringify(getaddress) === "{}")
		let namelength = that.state.addPlantval.trim().length

		if (that.state.addPlantval === '') {
			message.warning("请输入位置名称！")
			return false
		}
		else if (objnull !== false) {
			message.warning("请选择位置地址！")
			return false
		}
		else if (getaddress.latitude === undefined || getaddress.longitude === undefined) {
			message.warning("请通过地图选点或搜索地址获取位置!")
			return false
		}
		else if (namelength > 30) {
			message.warning("位置名称长度不能超过30！")
			return false
		}
		else {
			that.setState({
				disabled: true
			})
			let url = {
				name: that.state.addPlantval.trim(),
				latitude: getaddress.latitude,
				longitude: getaddress.longitude,
				address: getaddress.address,
				provinces: getaddress.provinces,
				cities: getaddress.cities,
				description: getaddress.description,

				districts: getaddress.district,
				organizationId: that.props.getSubid.orgId
			};
			addplant(url).then(res => {
				if (res !== undefined) {
					this.plantList()
					message.success('位置创建成功！')

					that.setState({
						addPlantdata: false,
						addPlantval: '',

					})
					setTimeout(() => {
						that.setState({
							disabled: false,
						})
					}, 1000)
				}
			}).catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data)
				}
				else {
					message.error('位置添加失败')
				}
				that.setState({
					disabled: false
				})
			})
		}
	}
	//显示添加位置弹出框
	addPlant(addPlantdata) {
		this.setState({
			addPlantdata,
			addPlantval: '',
		})
		this.props.setaddressChange({
			address: '',
			addressDisabled: false,
			latitude: 39.909729,
			longitude: 116.399928,
		})
		this.props.setaddress({})
	}
	//确认添加位置按钮
	subaddplant() {
		this.resaddplant()
	}
	//点击获取plant接口数据刷新数据
	plantList = () => {

		let that = this;
		plantListdata(that.props.getSubid.orgId,function (data) {
			that.props.setplant(data)
		})

	}
	//获取位置name
	onChangeAddplant = (e) => {
		this.setState({
			addPlantval: e.target.value
		})
		if (e.type === 'compositionstart') {
			this.setState({
				isZn_CH: true
			})
			return;
		}
		if (e.type === "compositionend") {
			this.setState({
				isZn_CH: false
			})
			//获取输入框中的文字,截取前十个,重新赋值给输入框
			if (e.target.value.trim().length > 30) {
				message.warning("输入内容不能超过30")
				this.setState({
					addPlantval: e.target.value,
					disabled: true
				})
			}
			else {
				this.setState({
					addPlantval: e.target.value,
					disabled: false
				})
			}
			return;
		}
		if (!this.state.isZn_CH) {
			if (e.target.value.trim().length > 30) {
				message.warning("输入内容不能超过30")
				this.setState({
					addPlantval: e.target.value.trim(),
					disabled: true
				})
				return false
			}
			this.setState({
				addPlantval: e.target.value.trim(),
				disabled: false
			})
		}
	}

	render() {
		const _self = this;
		const { getAddplanttype } = _self.props;
		let planttype;
		// if (getAddplanttype === 1) {
		// 	planttype = "primary-blue"
		// }
		// else {
		// 	planttype = "ghost"
		// }
		return (
			<>
				{
					getAddplanttype === 1 ? (
						<ABB.Button
							onClick={() => this.addPlant(true)}
							type="primary-blue"
							sizeClass='small' theme="filled"
							text="添加位置"
							icon="abb/plus-in-circle"
						/>
					) : (
						<ABB.Button
							onClick={() => this.addPlant(true)}
							style={{border:'none'}}
							type="ghost"
							sizeClass='small' theme="filled"
							text="添加位置"
							icon="abb/plus-in-circle"
						/>
					)
				}

				<Modal
					title="添加位置"
					centered
					closeIcon={
						<ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
					}
					width={600}
					maskClosable = {false}
					visible={_self.state.addPlantdata}
					onOk={() => _self.addPlant(false)}
					onCancel={() => _self.addPlant(false)}
					footer={[
						<ABB.Button
							onClick={() => this.addPlant(false)}
							type="discreet-black"
							sizeClass='small' theme="filled"
							text="取消"
						/>,
						<ABB.Button
							onClick={() => _self.subaddplant()}
							type="primary-blue"
							disabled={_self.state.disabled}
							sizeClass='small' theme="filled"
							text="确定"
						/>,
					]}
				>
					<div className='model_list'>
						<div className='model_title'>位置名称</div>
						<Input placeholder="请输入位置名称"
							className="model_input"
							autoComplete = "off"
							value={_self.state.addPlantval}
							style={{ width: '100%' }}
							onChange={_self.onChangeAddplant}
							onCompositionStart={_self.onChangeAddplant}
							onCompositionEnd={_self.onChangeAddplant}
						/>
					</div>
					<div className='model_list'>
						<div className='model_title'>详细地址</div>
						<Amap />
					</div>
				</Modal>
			</>

		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setplant: data => {
		dispatch(setplant(data))
	},

	setaddress: data => {
		dispatch(setaddress(data))
	},
	setaddressChange: data => {
		dispatch(setaddressChange(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Addplant));

