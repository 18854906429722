import React, { Component } from 'react';
import moment from 'moment';
import { message, Radio, DatePicker, Select, Checkbox, Table, Collapse, TimePicker } from 'antd';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import AddDevice from './adddevice';
import { blockdevice, deletedevice, changedevicefault, changenodisturb, changedeviceblock, notificationprofile, notificationprofileblock, notificationprofileenable, nodisturb, updateCustomLimit } from "@/api/pushsetting";
import RemoweModel from './removeModel';
import { data } from 'jquery';
import NoData from '@/components/NoData'

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Panel } = Collapse;
class SettingComponents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showmodelRemove: {
                visible: false
            },
            datelist: [
                {
                    id: 1,
                    name: '周一'
                },
                {
                    id: 2,
                    name: '周二'
                },
                {
                    id: 3,
                    name: '周三'
                },
                {
                    id: 4,
                    name: '周四'
                },
                {
                    id: 5,
                    name: '周五'
                },
                {
                    id: 6,
                    name: '周六'
                },
                {
                    id: 0,
                    name: '周日'
                },
            ],
            aisleValue: false,
            dateValue: false,
            dateTimeValue: 1,
            quantumValue: false,
            CheckboxValue: false,
            columns: [
                {
                    title: 'deviceName',
                    dataIndex: 'deviceName',
                    key: 0,
                    width: '20%'
                },
                {
                    title: 'deviceBlockEnable',
                    dataIndex: 'deviceBlockEnable',
                    key: 1,
                    width: '20%',
                    render: (text, record) => {

                        return (
                            <ABB.ToggleSwitch
                                label=""
                                showIcons={true}
                                onChange={(e) => {
                                    this.onChangedeviceBlockEnable(record, e)
                                }}
                                monochrome={true}
                                value={record.deviceBlockEnable === true ? true : false}
                            />
                        );
                    }

                },

                {
                    title: 'faultEventEnable',
                    dataIndex: 'faultEventEnable',
                    key: 3,
                    width: '20%',
                    render: (text, record) => {
                        return (
                            <div className="malfunction_switch">
                                <span>故障事件</span>

                                <ABB.ToggleSwitch
                                    label=""
                                    showIcons={true}
                                    onChange={(e) => {
                                        this.onChangedeviceStatus(record, 1, e)
                                    }}
                                    monochrome={false}
                                    value={record.faultEventEnable === true ? true : false}
                                />
                            </div>
                        );
                    }

                },
                {
                    title: 'warningEventEnable',
                    dataIndex: 'warningEventEnable',
                    key: 4,
                    width: '20%',
                    render: (text, record) => {
                        return (
                            <div className="malfunction_switch">
                                <span>告警事件</span>

                                <ABB.ToggleSwitch
                                    label=""
                                    showIcons={true}
                                    onChange={(e) => {
                                        this.onChangedeviceStatus(record, 2, e)
                                    }}
                                    monochrome={false}
                                    value={record.warningEventEnable === true ? true : false}
                                />
                            </div>
                        );
                    }

                },
                {
                    title: 'warningEventEnable',
                    dataIndex: 'warningEventEnable',
                    key: 4,
                    width: '20%',
                    render: (text, record) => {
                        return (
                            <div className="malfunction_switch">
                                <span>自定义告警</span>

                                <ABB.ToggleSwitch
                                    label=""
                                    showIcons={true}
                                    onChange={(e) => {
                                        this._updateCustomLimit(record, e)
                                    }}
                                    monochrome={false}
                                    value={record.customLimitEnable === true ? true : false}
                                />
                            </div>
                        );
                    }

                },
                {
                    title: 'SMS',
                    dataIndex: 'channels',
                    key: 5,
                    width: '20%',
                    render: (text, record) => {
                        return (
                            <span onClick={() => this.deviceDelete(record)}>
                                <ABB.Icon name="abb/trash" sizeClass='small' />
                            </span>
                        );
                    }

                }
            ],
            pushdata: [
                {
                    id: 1,
                    name: '设备名11'
                },
                {
                    id: 1,
                    name: '设备名22'
                }
            ],
            showdata: {
                visible: false,
                devicelist: []
            },
            eventBlockProfiles: [],
            startopen: false,
            endopen: false,
            weeksList: [],
            startHour: 0,
            endHour: 0,
            startMinute: 0,
            endMinute: 0,
            quantumStartTime: undefined,
            quantumEndTime: undefined,
            startTime: undefined,
            endTime: undefined,
            deviceId: 0,
            disabled: false
        };
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    componentDidMount() {
        this.getnotificationprofile()
        this.getnodisturb();
        this.getnotificationprofileblock();
        this.getdevicelist();
    }
    deviceDelete = (data) => {
        this.setState({
            deviceId: data.deviceId,
            showmodelRemove: {
                visible: true
            }
        })
    }
    onChangedeviceBlockEnable = (data, e) => {
        this.changeBlockEnable(data.deviceId, e);
    }
    changeBlockEnable = (deviceId, status) => {
        let url = `${deviceId}?enable=${status}`;
        changedeviceblock(url).then(res => {
            this.getnotificationprofileblock();
            message.success("操作成功！")
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("changeBlockEnable -- changedeviceblock --接口异常")
                message.error('接口异常')
            }
        })
    }
    _updateCustomLimit = (data, e) => {
        var param = {
            "userId": this.props.userInfo.userId,
            "deviceId": data.deviceId,
            "isOpen": e
        }

        console.log("param============", param);

        updateCustomLimit(param).then(res => {
            if (res.data.code === 200) {
                console.log("res==========", res);
                this.getnotificationprofileblock();
                message.success("操作成功！")
            } else {
                message.error('操作失败')
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("_updateCustomLimit -- updateCustomLimit --接口异常")
                message.error('接口异常')
            }
        })

    }
    onChangedeviceStatus = (data, type, e) => {
        this.changeBlockfault(data.deviceId, e, type);
    }
    changeBlockfault = (deviceId, status, type) => {
        let url = `${deviceId}/${type}?enable=${status}`;
        changedevicefault(url).then(res => {
            this.getnotificationprofileblock();
            message.success("操作成功！")
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("changeBlockfault -- changedevicefault --接口异常")
                message.error('接口异常')
            }
        })
    }
    onChangedate = (e) => {
        let { dateTimeValue, startTime, endTime } = this.state;
        let val = dateTimeValue;
        let data = {};
        if (val === 1) {
            data = {
                "dateType": val,
                "dateNoDisturbProfile": {
                    "startDate": moment(startTime).utc().format(),
                    "endDate": moment(endTime).utc().format(),
                    "selected": true
                },
                "enbale": e
            };
        }
        if (val === 2) {
            data = {
                "dateType": val,
                "weekNoDisturbProfile": {
                    "weeks": this.state.weeksList,
                    "selected": true
                },
                "enbale": e
            };
        }
        this.changenodisturbFn(data);
    }
    changenodisturbFn = (data) => {
        let url = data;
        changenodisturb(url).then(res => {
            this.getnodisturb();
            message.success("操作成功！")
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("changenodisturbFn -- changenodisturb --接口异常")
                message.error('接口异常')
            }
        })
    }
    getnodisturb = () => {
        let url = ``;
        nodisturb(url).then(res => {
            let resdata = res.data;
            resdata.map((item, index) => {
                if (item.selected === true && item.noDisturbDateType !== 3) {
                    this.setState({
                        dateValue: item.enable
                    })
                }
                if (item.noDisturbDateType === 1) {
                    if (item.profile.startDate !== '' || item.profile.endDate !== '') {
                        this.setState({
                            startTime: moment.parseZone(item.profile.startDate).local().format('YYYY/MM/DD'),
                            endTime: moment.parseZone(item.profile.endDate).local().format('YYYY/MM/DD'),
                        })
                    }

                    if (item.profile.selected === true) {
                        this.setState({
                            dateTimeValue: 1,

                        })
                    }
                }
                if (item.noDisturbDateType === 2) {
                    this.setState({
                        weeksList: item.profile.weeks
                    })
                    if (item.profile.selected === true) {
                        this.setState({
                            dateTimeValue: 2
                        })
                    }
                }
                if (item.noDisturbDateType === 3) {
                    this.setState({
                        quantumValue: item.enable,
                        CheckboxValue: item.profile.isAllDay,
                        startHour: item.profile.startHour,
                        endHour: item.profile.endHour,
                        startMinute: item.profile.startMinute,
                        endMinute: item.profile.endMinute,
                        quantumStartTime: moment(item.profile.startHour + ":" + item.profile.startMinute, 'HH:mm'),
                        quantumEndTime: moment(item.profile.endHour + ":" + item.profile.endMinute, 'HH:mm'),
                    })
                }
            })
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("getnodisturb -- nodisturb --接口异常")
                message.error('接口异常')
            }
        })
    }
    getnotificationprofileblock = () => {
        let url = ``;
        notificationprofileblock(url).then(res => {
            let resdata = res.data;
            this.setState({
                eventBlockProfiles: resdata
            })
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("getnotificationprofileblock -- notificationprofileblock --接口异常")
                message.error('接口异常')
            }
        })
    }
    getnotificationprofile = () => {
        let url = ``;
        notificationprofile(url).then(res => {
            let resdata = res.data;
            resdata.map((item, index) => {
                if (item.id === 4) {
                    this.setState({
                        aisleValue: item.enable
                    })
                }
            })
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("getnotificationprofile -- notificationprofile --接口异常")
                message.error('接口异常')
            }
        })
    }
    onChangeaisle = (e) => {
        let url = `4/${e}`;
        notificationprofileenable(url).then(res => {

            this.setState({
                aisleValue: e
            })
            message.success("操作成功！")
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("onChangeaisle -- notificationprofileenable --接口异常")
                message.error('接口异常')
            }
        })
    }
    onChangeRadio = (e) => {
        let val = e.target.value;
        let data = {};
        if (val === 1) {
            data = {
                "dateType": val,
                "dateNoDisturbProfile": {
                    "startDate": moment(this.state.startTime).utc().format(),
                    "endDate": moment(this.state.endTime).utc().format(),
                    "selected": true
                },
                "enbale": true
            };

        }
        if (val === 2) {
            data = {
                "dateType": val,
                "weekNoDisturbProfile": {
                    "weeks": this.state.weeksList,
                    "selected": true
                },
                "enbale": true
            };
        }

        this.changenodisturbFn(data);
    }
    onChangequantum = (e) => {
        let { startHour, endHour, startMinute, endMinute } = this.state;

        let data = {
            "dateType": 3,
            "timeNoDisturbProfile": {
                "startHour": startHour,
                "endHour": endHour,
                "startMinute": startMinute,
                "endMinute": endMinute,
                "isAllDay": this.state.CheckboxValue
            },
            "enbale": e
        };
        this.changenodisturbFn(data);
    }
    onChangeCheckboxValue = (e) => {
        let { startHour, endHour, startMinute, endMinute } = this.state;
        let data = {
            "dateType": 3,
            "timeNoDisturbProfile": {
                "startHour": startHour,
                "endHour": endHour,
                "startMinute": startMinute,
                "endMinute": endMinute,
                "isAllDay": e.target.checked
            },
            "enbale": true
        };
        this.changenodisturbFn(data);
    }
    onChangeselect = (e) => {
        this.setState({
            weeksList: e
        }, () => {
            let data = {
                "dateType": this.state.dateTimeValue,
                "weekNoDisturbProfile": {
                    "weeks": this.state.weeksList,
                    "selected": true
                },
                "enbale": true
            };
            this.changenodisturbFn(data);
        })

    }
    customExpandIcon = (props) => {
        if (props.isActive) {
            return <div style={{ color: 'black', marginRight: 8 }} onClick={e => {
                props.onExpand(props.record, e);
            }}><ABB.Icon name="abb/down" sizeClass='small' /></div>
        } else {
            return <div style={{ color: 'black', marginRight: 8 }} onClick={e => {
                props.onExpand(props.record, e);
            }}><ABB.Icon name="abb/right" sizeClass='small' /></div>
        }
    }
    AddDeviceFn = () => {
        // this.getdevicelist();
        this.setState({
            showdata: {
                visible: true,
                devicelist: this.state.devicelist
            }
        })
    }
    hidemodel = (type) => {
        if (type === false) {
            this.setState({
                showdata: {
                    visible: false,
                    devicelist: []
                }
            })
        }
        else {
            this.setState({
                showdata: {
                    visible: false,
                    devicelist: []
                }
            })
            this.getnotificationprofileblock();
            this.getdevicelist()
        }

    }
    starthandleOpenChange = startopen => {
        this.setState({ startopen });
    };

    starthandleClose = () => {
        let { startHour, endHour, startMinute, endMinute, quantumStartTime, quantumEndTime } = this.state;
        this.setState({
            startopen: false
        })
        let _quantumStartTime = Date.parse(quantumStartTime._d);
        let _quantumEndTime = Date.parse(quantumEndTime._d);
        if (_quantumStartTime < _quantumEndTime) {
            let data = {
                "dateType": 3,
                "timeNoDisturbProfile": {
                    "startHour": startHour,
                    "endHour": endHour,
                    "startMinute": startMinute,
                    "endMinute": endMinute,
                    "isAllDay": false
                },
                "enbale": true
            };
            this.changenodisturbFn(data);
        } else {
            this.setState({
                quantumStartTime: quantumStartTime
            })
            message.warning("开始时间不能大于结束时间！")
        }
    };
    onChangequantumstartime = (e) => {
        this.setState({
            // quantumstartime: moment(e._d).format("HH:ss:mm"),
            startHour: moment(e._d).format("HH"),
            // endHour: moment(e._d).format("HH"),
            startMinute: moment(e._d).format("mm"),
            quantumStartTime: moment(e._d, 'HH:mm')
        })
    }
    onChangequantumendtime = (e) => {
        this.setState({
            // startHour: moment(e._d).format("HH"),
            endHour: moment(e._d).format("HH"),
            // startMinute: moment(e._d).format("ss"),
            endMinute: moment(e._d).format("mm"),
            quantumEndTime: moment(e._d, 'HH:mm'),
        })
    }
    endhandleOpenChange = endopen => {
        this.setState({ endopen });
    };

    endhandleClose = () => {
        let { startHour, endHour, startMinute, endMinute, quantumStartTime, quantumEndTime } = this.state;
        let _quantumStartTime = Date.parse(quantumStartTime._d);
        let _quantumEndTime = Date.parse(quantumEndTime._d);

        this.setState({
            endopen: false
        })




        if (_quantumStartTime < _quantumEndTime) {
            let data = {
                "dateType": 3,
                "timeNoDisturbProfile": {
                    "startHour": startHour,
                    "endHour": endHour,
                    "startMinute": startMinute,
                    "endMinute": endMinute,
                    "isAllDay": false
                },
                "enbale": true
            };
            this.changenodisturbFn(data);
        } else {
            this.setState({
                quantumEndTime: quantumEndTime
            })
            message.warning("结束时间不能小于开始时间！")
        }

    };
    onChangedateTime = (e) => {
        let startTime = moment(e[0]._d).format("YYYY/MM/DD")
        let endTime = moment(e[1]._d).format("YYYY/MM/DD");
        this.setState({
            startTime: startTime,
            endTime: endTime
        }, () => {
            let data = {
                "dateType": 1,
                "dateNoDisturbProfile": {
                    "startDate": moment(startTime).utc().format(),
                    "endDate": moment(endTime).utc().format(),
                    "selected": true
                },
                "enbale": true
            };
            this.changenodisturbFn(data);
        })
    }
    hidemodeldelete = () => {
        this.setState({
            showmodelRemove: {
                visible: false
            }
        })
    }
    submitmodel = () => {
        this.setState({
            disabled: true
        })
        let url = `${this.state.deviceId}`
        deletedevice(url).then(res => {
            message.success("操作成功！")
            this.setState({
                disabled: false,
                showmodelRemove: {
                    visible: false
                }
            })
            this.getnotificationprofileblock();
            this.getdevicelist()
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("submitmodel -- deletedevice --接口异常")
                message.error('接口异常')
            }
            this.setState({
                disabled: false
            })
        })




    }
    getdevicelist = (orgId) => {

        let url = ``;
        blockdevice(url).then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                this.setState({
                    devicelist: resdata
                })
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                console.log("getdevicelist -- blockdevice --接口异常")
                message.error('接口异常')
            }
        })

    }
    disabledDate = current => current && current <= moment().subtract(1, 'day');
    render() {
        let { disabled, startTime, endTime, quantumEndTime, quantumStartTime, weeksList, dateValue, aisleValue, dateTimeValue, quantumValue, CheckboxValue, eventBlockProfiles } = this.state;
        let _startTime = undefined;
        let _endTime = undefined;
        if (startTime !== undefined) {
            _startTime = moment(startTime, 'YYYY/MM/DD')
            _endTime = moment(endTime, 'YYYY/MM/DD')
        }
        return (
            <>
                {/* <div className='shadow-radius'> */}
                {/* <div className='org_right notification_box' style={{ overflowY: 'scroll' }}> */}

                {/* <div className="notification_setting">消息设置</div> */}
                <div style={{ paddingBottom: 8, background: '#fff' }}>
                    <div className="push_setting">推送设置</div>
                    <div className="push_list">
                        <div className="push_title">推送通道</div>
                        <div className="push_switch">
                            <span>App</span>
                            <ABB.ToggleSwitch
                                label=""
                                showIcons={true}
                                onChange={this.onChangeaisle}
                                monochrome={true}
                                value={aisleValue}
                            />
                        </div>
                    </div>
                    <div className="push_list">
                        <div className="push_title">设置免打扰日期</div>
                        <div className="push_switch">
                            <span></span>
                            <ABB.ToggleSwitch
                                label=""
                                showIcons={true}
                                onChange={this.onChangedate}
                                monochrome={true}
                                value={dateValue}
                            />
                        </div>
                        <div className="push_switch Nodisturbing">
                            <Radio.Group value={dateTimeValue} onChange={this.onChangeRadio} disabled={dateValue === true ? false : true}>
                                <div className="push_radio">


                                    <Radio key={1} value={1}>免打扰日期</Radio>
                                    <RangePicker
                                        disabled={(dateValue === true && dateTimeValue === 1) ? false : true}
                                        className="model_input"
                                        style={{ margin: '0 50px 0 0', width: '240px' }}
                                        value={[_startTime, _endTime]}
                                        suffixIcon={
                                            <ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
                                        }
                                        disabledDate={this.disabledDate}
                                        separator='—'
                                        allowClear={false}
                                        format="YYYY/MM/DD"
                                        onChange={this.onChangedateTime}
                                    />
                                </div>
                                <div className="push_radio">
                                    <Radio key={2} value={2}>免打扰周期</Radio>
                                    <Select
                                        value={weeksList}
                                        placeholder="请选择免打扰日期"
                                        style={{ width: '20vw' }}
                                        mode="multiple"
                                        showArrow={true}
                                        showSearch={false}
                                        disabled={(dateValue === true && dateTimeValue === 2) ? false : true}
                                        onChange={this.onChangeselect}
                                        suffixIcon={
                                            <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                        }
                                    >
                                        {
                                            this.state.datelist.map((item, index) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            })
                                        }

                                    </Select>
                                </div>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="push_list">
                        <div className="push_title">设置免打扰时间段</div>
                        <div className="push_switch">
                            <span></span>
                            <ABB.ToggleSwitch
                                label=""
                                showIcons={true}
                                onChange={this.onChangequantum}
                                monochrome={true}
                                value={quantumValue}
                            />
                        </div>
                        <div className="push_switch Nodisturbing">

                            <TimePicker
                                value={quantumStartTime}
                                allowClear={false}
                                suffixIcon={
                                    <ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
                                }
                                format="HH:mm"
                                disabled={quantumValue === true && CheckboxValue !== true ? false : true}
                                style={{ margin: '0 8px 0 0', width: 120 }}
                                open={this.state.startopen}
                                onOpenChange={this.starthandleOpenChange}
                                onChange={this.onChangequantumstartime}
                                addon={() => (
                                    <div
                                        onClick={() => this.starthandleClose()}
                                        className="timepickerbutton"
                                    >确定</div>
                                )}
                            />
                            <span className="timeline">—</span>
                            <TimePicker
                                value={quantumEndTime}
                                allowClear={false}
                                disabled={quantumValue === true && CheckboxValue !== true ? false : true}
                                suffixIcon={
                                    <ABB.Icon style={{ color: '#000' }} name="abb/calendar" sizeClass='small' />
                                }
                                style={{ margin: '0 16px 0 8px', width: 120 }}
                                open={this.state.endopen}
                                format="HH:mm"
                                onChange={this.onChangequantumendtime}
                                onOpenChange={this.endhandleOpenChange}
                                addon={() => (
                                    <div
                                        onClick={() => this.endhandleClose()}
                                        className="timepickerbutton"
                                    >确定</div>
                                )}
                            />
                            <Checkbox
                                checked={CheckboxValue}
                                disabled={quantumValue === true ? false : true}
                                onChange={this.onChangeCheckboxValue}
                                className="Nodisturbingcheckbox">全天免打扰</Checkbox>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="push_setting">消息屏蔽设置
                        <span style={{ fontSize: 12, color: "#686868" }}>（以下列表中的设备事件消息将不会在消息中心中显示）</span>
                    </div>

                    <div className="shield_setting">
                        <Collapse
                            bordered={false}
                            // defaultActiveKey={['1']}
                            expandIconPosition={'right'}
                            expandIcon={(props) => this.customExpandIcon(props)}
                        >
                            {
                                eventBlockProfiles.length > 0 ? (
                                    eventBlockProfiles.map((item, index) => {
                                        return (
                                            <Panel header={item.groupName} key={index}>
                                                <Table
                                                    size='small'
                                                    showHeader={false}
                                                    columns={this.state.columns}
                                                    dataSource={item.eventBlockProfiles}
                                                    pagination={false}
                                                    rowKey={(record, item) => item.deviceId}
                                                />
                                            </Panel>
                                        )
                                    })
                                ) : <div style={{ textAlign: 'center', color: '#1f1f1f', lineHeight: '100px' }}>暂无数据</div>

                            }

                        </Collapse>
                        <div className="adddevicebutton">
                            <ABB.Button
                                onClick={() => this.AddDeviceFn()}
                                // disabled={that.state.disabled}
                                type="primary-blue"
                                sizeClass='small' theme="filled"
                                text="添加"
                            />
                        </div>

                    </div>
                </div>
                {/* </div> */}
                {/* </div> */}
                <AddDevice
                    hidemodel={this.hidemodel}
                    showdata={this.state.showdata}
                />
                <RemoweModel
                    showmodelRemove={this.state.showmodelRemove}
                    hidemodeldelete={this.hidemodeldelete}
                    disabled={disabled}
                    submitmodel={this.submitmodel}
                />
            </>

        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setUserInfo: data => {
        dispatch(setUserInfo(data));
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SettingComponents));