import React, { Component } from 'react';
import { Table, Spin, message } from 'antd';
import { eventTop } from '@/api/chart.js';
import { EUeventdetail } from '@/api/event.js'
import moment from 'moment';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { setNewsmodelIsShow, setRouterLinkIndex } from '@/redux/actions/setting';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import NoData from '@/components/NoData'

class EUevent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			size: 5,
			admin: [],
			pagination: {},
			loading: false,
			modal2Visible: false,
			selectedRowKeys: [],
			columns: [
				{
					title: '类型',
					dataIndex: 'assetEventType',
					key: 0,
					width: 60,
					// align: 'center',
					render: assetEventType => {
						if (assetEventType === 'Custom') {
							return (
								<div className='customevent'></div>
							);
						}
						if (assetEventType === 'Fault') {
							return (
								<ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Warning') {
							return (
								<ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Notification') {
							return (
								<ABB.Icon style={{ 'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
							);
						}

					},
				}, 
				{
					title: '设备状态',
					dataIndex: 'deviceWorkStatus',

					width: '15%',
					ellipsis: true,
					render: deviceWorkStatus => {

						if (deviceWorkStatus === undefined || deviceWorkStatus === 0) return '--'

						if (deviceWorkStatus === 1) return '运行'

						if (deviceWorkStatus === 2) return '待机'

						if (deviceWorkStatus === 3) return '计划停机'

						if (deviceWorkStatus === 4) return '非计划停机'

					}
				},

				{
					title: '网关时间',
					dataIndex: 'eventDateTime',
					key: 1,
					width: '15%',
					ellipsis: true,
					render: assetEventType => {
						return (
							moment.parseZone(assetEventType).local().format('MM-DD HH:mm')
						);
					}
				},
				{
					title: '客户名称',
					dataIndex: 'customerName',
					key: 2,
					width: '15%',
					ellipsis: true
				},
				{
					title: '设备名称',
					dataIndex: 'deviceName',
					key: 3,
					width: '20%',
					ellipsis: true,
				},
				{
					title: '事件描述',
					dataIndex: 'content',
					key: 4,
					width: '20%',
					ellipsis: true,
				},
				{
					title: '事件代码',
					dataIndex: 'eventCode',
					key: 5,
					ellipsis: true,
					width: '10%',
					render: (text, record) => {
						// console.log("text==============",text)
						return  text !== undefined && text !== '' ? text : '--'
					},
				},
				{
					title: '处理状态',
					dataIndex: 'assetEventStatus',
					key: 7,
					width: 70,
					render: (text, record) => {
						if (record.assetEventStatus === 'Open') {
							return (
								<div style={{ color: '#3366FF', cursor: 'pointer' }}
									onClick={() => this.jumpNews(record)}
								>未处理</div>
							);
						}
						if (record.assetEventStatus === 'Close') {
							return (
								<div
									style={{ cursor: 'pointer' }}
									onClick={() => this.jumpNews(record)}
								>已处理</div>
							);
						}
					},
				}
			]
		};
	}
	componentDidMount() {
		if (this.props.userInfo.organizationType === "EU") {
			this.setState({
				columns: [
					{
						title: '类型',
						dataIndex: 'assetEventType',
						key: 'assetEventType',
						width: 60,
						align: 'center',
						render: assetEventType => {
							if (assetEventType === 'Custom') {
								return (
									<div className='customevent'></div>
								);
							}
							if (assetEventType === 'Fault') {
								return (
									<ABB.Icon style={{ 'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
								);
							}
							if (assetEventType === 'Warning') {
								return (
									<ABB.Icon style={{ 'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
								);
							}
							if (assetEventType === 'Notification') {
								return (
									<ABB.Icon style={{ 'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
								);
							}

						},
					},
					{
						title: '设备状态',
						dataIndex: 'deviceWorkStatus',

						width: '17%',
						ellipsis: true,
						render: deviceWorkStatus => {

							if (deviceWorkStatus === undefined || deviceWorkStatus === 0) return '--'

							if (deviceWorkStatus === 1) return '运行'

							if (deviceWorkStatus === 2) return '待机'

							if (deviceWorkStatus === 3) return '计划停机'

							if (deviceWorkStatus === 4) return '非计划停机'

						}
					},
					{
						title: '网关时间',
						dataIndex: 'eventDateTime',
						key: 'eventDateTime',
						width: '17%',
						ellipsis: true,
						render: assetEventType => {
							return (
								moment.parseZone(assetEventType).local().format('MM-DD HH:mm')
							);
						}
					},
					{
						title: '设备名称',
						dataIndex: 'deviceName',
						key: 'deviceName',
						width: '18%',
						ellipsis: true,
					},
					{
						title: '事件描述',
						dataIndex: 'content',
						key: 'content',
						ellipsis: true,
					},
					{
						title: '事件代码',
						dataIndex: 'eventCode',
						key: 'eventCode',
						ellipsis: true,
						width: 70,
					},
					{
						title: '处理状态',
						dataIndex: 'assetEventStatus',
						key: 7,
						width: 70,
						render: (text, record) => {
							if (record.assetEventStatus === 'Open') {
								return (
									<div style={{ color: '#3366FF', cursor: 'pointer' }}
										onClick={() => this.EUjumpNews(record)}
									>未处理</div>
								);
							}
							if (record.assetEventStatus === 'Close') {
								return (
									<div
										style={{ cursor: 'pointer' }}
										onClick={() => this.EUjumpNews(record)}
									>已处理</div>
								);
							}
						},
					}
				]
			})
		}
		this.fetch();
	};
	// componentDidCatch() {
	// 	// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
	// 	this.setState = () => {
	// 		return;
	// 	};
	// }

	EUjumpNews = (record) => {
		localStorage.setItem('menukey', 7)
		// let url = `/${record.id}`;
		// EUeventdetail(url).then(res=>{
		// 	if(res!==undefined){
		// 		let resdata = res.data;
		// 	    resdata.eventDateTime = moment.parseZone(resdata.eventDateTime).local().format('YYYY-MM-DD HH:mm:ss')
		// 		this.props.setNewsmodelIsShow(resdata)
		//         this.props.history.push('/news');
		// 	}
		// })
		this.props.setNewsmodelIsShow(record)
		this.props.setRouterLinkIndex(7)
		this.props.history.push('/Notification');
	}
	jumpNews = (record) => {
		localStorage.setItem('menukey', 7)
		this.props.setNewsmodelIsShow(record)
		this.props.setRouterLinkIndex(7)
		this.props.history.push('/Notification');
	}
	fetch = () => {
		this.setState({
			loading: true
		})
		let url = `${5}`
		eventTop(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				console.log('eventTop---------------', resdata)

				//临时代码,模拟数据
				// resdata =	resdata.map((item,index) =>{
				// 		item.deviceWorkStatus = index
				// 		return item
				// 	})

				this.setState({
					admin: resdata,
					loading: false
				})
			}
		}).catch(err => {
			this.setState({
				loading: false
			})
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('最新事件列表接口获取错误')
			}

		})
	};

	render() {

		return (
			<Spin spinning={this.state.loading} style={{ height: '260px' }}>

				<div style={{ "width": '100%', position: 'relative', minHeight: 260 }} className='org_right'>
					{
						this.state.admin.length > 0 ? (
							<Table
								size="small"
								y='true'
								style={{ cursor: 'pointer' }}
								columns={this.state.columns}
								dataSource={this.state.admin}
								pagination={false}
								onRowClick={(record) =>
									this.EUjumpNews(record)
								}
								rowKey={(record, index) => index}
							/>
						) : <NoData />
					}

				</div>
			</Spin>

		);
	}
}

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	},
	setNewsmodelIsShow: data => {
		dispatch(setNewsmodelIsShow(data))
	},
	setRouterLinkIndex: data => {
		dispatch(setRouterLinkIndex(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EUevent));
