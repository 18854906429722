import * as actionTypes from './constants';


const showContactConfig = {
    visible: false,
    title: '',
    value: '',
    width: 504,
    centered: true,
    isRequestData: false,
    contactsUpdateTime : 0,
    contactsUpdateUser: "",
    deviceContacts: [
        {
            "title": "首选联系人", //标题
            "name": "", // 联系人名称
            "phone": "", // 联系人电话
            "priority": 0, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
            isMust: true
        },
        {
            "title": "备选联系人1", //标题
            "name": "", // 联系人名称
            "phone": "", // 联系人电话
            "priority": 1, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
            isMust: true
        },
        {
            "title": "备选联系人2", //标题
            "name": "", // 联系人名称
            "phone": "", // 联系人电话
            "priority": 2, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
            isMust: false
        },
        {
            "title": "备选联系人3", //标题
            "name": "", // 联系人名称
            "phone": "", // 联系人电话
            "priority": 3, //优先级 0-首选, 1-备选1, 2-备选2, 3-备选3
            isMust: false
        },
    ],
    orgContacts: [


    ]
}

const showReminContactConfig = {
    visible: false,
    title: '提示',
    value: '',
    width: 504,
    centered: true,
    isRequestData: false,
}

const showLogoutOrganizationConfig = {
    visible: false,
    title: '提示',
    value: '',
    width: 504,
    centered: true,
    isRequestData: false,
}

const defaultIndustriesConfig = {
    visible: false,
    title: '全部行业',
    value: '',
    width: 1200,
    centered: true,
    requestData: [],
    showData: []
}

const defaultEditOrgInfoConfig = {
    visible: false,
    title: '编辑组织信息',
    value: '',
    width: 354,
    centered: true,
    requestData: [],
    showData: []
}

const defaultToolEditOrgInfoConfig = {
    visible: false,
    title: '编辑组织信息',
    value: '',
    width: 354,
    centered: true,
    orgInfo:{
        orgName: "",
        orgType: "",
        SSAdministratorEmail: "",
        SSOrgName: "",
        grantedOrgName: "",
        isBoundSS: "",
        isGranted: "",
        orgAdministrator: "",
        orgCreatedDate: "",
        orgId:""
    }
}

//设备巡检数据
const getContactConfig = (state = showContactConfig, action) => {
    switch (action.type) {
        case actionTypes.CONTACT_MODEL_CONFIG:
            return action.data;
        default:
            return state;
    }
}

// 更新完组织联系人后提示 组织联系人信息更新成功，您是否需要将组织联系人信息批量更新至设备联系人？
const getRemindUpdateContactConfig = (state = showReminContactConfig, action) => {
    switch (action.type) {
        case actionTypes.REMIND_UPDATE_CONTACT_MODEL_CONFIG:
            return action.data;
        default:
            return state;
    }
}

// 更新完组织联系人后提示 组织联系人信息更新成功，您是否需要将组织联系人信息批量更新至设备联系人？
const getDebugingList = (state = [], action) => {
    switch (action.type) {
        case actionTypes.DEBUGING_LIST:
            return action.data;
        default:
            return state;
    }
}

//  退出组织弹框配置
const getLogoutOrganizationConfig = (state = showReminContactConfig, action) => {
    switch (action.type) {
        case actionTypes.ORGANIZATION_LOGOUT:
            return action.data;
        default:
            return state;
    }
}

//  获取行业信息列表
const getIndustryList = (state = [], action) => {
    switch (action.type) {
        case actionTypes.ORGANIZATION_INDUSTRY:
            return action.data;
        default:
            return state;
    }
}
//  获取显示行业信息列表
const getShowIndustryList = (state = [], action) => {
    switch (action.type) {
        case actionTypes.ORGANIZATION_SHHOWINDUSTRY:
            return action.data;
        default:
            return state;
    }
}
//  获取显示行业信息列表
const getShowIndustriesConfig = (state = defaultIndustriesConfig, action) => {
    switch (action.type) {
        case actionTypes.ORGANIZATION_INDUSTRIES_MODEL_CONNFIG:
            return action.data;
        default:
            return state;
    }
}

//  获取显示行业信息列表
const getEditOrgConfig = (state = defaultEditOrgInfoConfig, action) => {
    switch (action.type) {
        case actionTypes.ORGANIZATION_EDIT:
            return action.data;
        default:
            return state;
    }
}
//工具箱编辑组织信息
const getToolEditOrgConfig = (state = defaultToolEditOrgInfoConfig, action) => {
    switch (action.type) {
        case actionTypes.TOOL_ORGANIZATION_EDIT:
            return action.data;
        default:
            return state;
    }
}

//工具箱位置管理列表
const getToolAssetLocations = (state = [], action) => {
    switch (action.type) {
        case actionTypes.TOOL_ASSET_LOCATIONS:
            return action.data;
        default:
            return state;
    }
}

export {
    getContactConfig,
    getRemindUpdateContactConfig,
    getDebugingList,
    getLogoutOrganizationConfig,
    getIndustryList,
    getShowIndustryList,
    getShowIndustriesConfig,
    getEditOrgConfig,
    getToolEditOrgConfig,
    getToolAssetLocations
};