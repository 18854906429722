import React, { Component } from 'react';
import moment from 'moment';
import { Input } from 'antd';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import SettingComponents from './components/setting'
import './style.scss';

class Setting extends Component {

	constructor(props) {
		super(props);
		this.state = {
			activeTab: 0
		};
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	callback(newIndex) {

        this.setState({ activeTab: newIndex })
        
    }
	render() {
		
		return (
			// <>
			// 	<SettingComponents/>
			// </>
<div className='shadow-radius'>
                <div className='org_right setting_abb'>
				<ABB.TabControl
                    type="primary"
                    activeTab={this.state.activeTab}
                    className={'fullHeight mainTabControlPaddings tabHeaderAnimation'}
                    onTabChange={(oldIndex, newIndex) => this.callback(newIndex)}
                    padLeft={false}
                >
                    <ABB.TabItem title="消息设置">
					<SettingComponents/>
                    </ABB.TabItem>
                    
                </ABB.TabControl>
                </div>
            </div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Setting));