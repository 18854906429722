import React, { Component } from 'react';
import {
    Form,
    Modal,
    Input,
    message,
    Col,
    Row,
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { migrationOrganization } from '@/api/user'
import { ABBcreatesubscription } from '@/api/subscription';

class MigrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: true,
            dataSource: []
        };
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }

    hidemodel = () => {
        this.props.hidemodel()
        this.setState({
            inputval: ''
        })
    }
    submitmodel = (data) => {
        const param = {
            "fromOrgId": data.orgId,
            "toOrgId": data.id
          }
        migrationOrganization(param).then(res => {
            if (res.data.code === 200) {
                message.success("迁移组织成功").then(()=>{
                    window.location.reload();
                })
            }
            else{
                message.warning(res.data.errorMessage)
            }
        }).catch(err => {
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('组织信息获取失败！')
            }
        })
        
    }
    onChange = (e) => {

    }
    //获取搜索用户框内容
    onChangeSerch = (e) => {
        this.setState({
            inputval: e
        })
    }
    render() {
        const that = this;
        const { showmodelMigrationOk } = this.props;
        const { getFieldDecorator } = that.props.form;
        const modelProps = {
            title: "迁移组织",
            centered: true,
            width: 504,
            visible: showmodelMigrationOk.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="我再想想"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelMigrationOk)}
                    type="primary-blue"
                    style={{
                        background: '#FF000F',
                        border: '1px solid #FF000F'
                    }}
                    sizeClass='small' theme="filled"
                    text="确认迁移"
                />,

            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div className='tranfer-title'>您即将进行迁移组织操作。组织迁移后，<span style={{color:'#36f'}}>{showmodelMigrationOk.orgMessage}</span>的全部设备和用户将合并至新的组织<span style={{color:'#36f'}}>{showmodelMigrationOk.orgName} </span>组织中。设备组、用户组及授权关系需要在新的组织中重新配置。请确认是否迁移？</div>
            </Modal>
        );
    }
}
const MigrationFormOk = Form.create({ name: 'register' })(MigrationForm);
export default MigrationFormOk;
