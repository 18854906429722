import React, { Component } from 'react';
import {
    Modal, message, Form,
    Upload
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { uploadStoreFile } from '@/api/device';
import axios from 'axios';

const { Dragger } = Upload;
class addDevices extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: false,
            isZn_CH: false,

            modelProps: {
                visible: true,
                title: '',
                value: '',
                width: 354,
                centered: true,
                deviceGroupName: '',
                movegrouplist: []
            }
        };

        this.setState({

        })
    }

    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        // this.props.submitmodel()
        // this.props.form.resetFields();
    }
    submitmodel = (data) => {
        // this.props.submitmodel()
        this.props.hidemodel(false)
        this.props.submitmodel(data)
    }
   
    beforeUpload = (file: any) => {
        console.log("file----------", file)
        const isJpgOrPng = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        if (!isJpgOrPng) {
            message.error('请上传 .XLSX 类型的文件!');
        }
        const isLt2M = file.size / 1024 < 2000;
        if (!isLt2M) {
            message.error('文件不能大于200k!');
        }
        return isJpgOrPng && isLt2M;
    }

    customRequest = async (options) => {
        console.log("info---------", options)
        const formData = new FormData();

        formData.append('File', options.file);
        let hide = message.loading('上传成功，正在解析...', 0);
        uploadStoreFile(formData).then(res => {
            if(res.data.code === 200){
                hide()
                this.submitmodel(res.data.data);
            }
            else{
                hide()
                message.warning("上传失败，请检查文件内容")
            }
        }).catch(err=>{
            message.error('上传失败，请检查文件内容')
            hide()
        })

    }
    render() {
        const that = this;
        const { showassetGroupmodel } = this.props;
        const { getFieldDecorator } = that.props.form;
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: "批量添加",
            maskClosable: false,
            centered: showassetGroupmodel.centered,
            width: showassetGroupmodel.width,
            visible: showassetGroupmodel.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [

            ]
        }
        const props = {
            name: 'file',
            multiple: true,
            showUploadList: false,
            accept: '.xlsx,.xls',
            beforeUpload: this.beforeUpload,
            customRequest: this.customRequest
            // onChange(info) {
            //     const { status } = info.file;
            //     if (status !== 'uploading') {
            //         console.log(info.file, info.fileList);
            //     }
            //     if (status === 'done') {
            //         message.success(`${info.file.name} file uploaded successfully.`);
            //     } else if (status === 'error') {
            //         message.error(`${info.file.name} file upload failed.`);
            //     }
            // },
        };
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>

                <Dragger {...props} className='deviceBorder'>
                    <div className='deviceUpLoad'>
                        <div className='deviceUpLoad_icon'>
                            <ABB.Icon style={{ color: '#1F1F1F' }} className="uploadData" name="abb/folder-open" sizeClass='medium' />
                        </div>
                        <div style={{ textAlign: 'center' }}>把文件放在这里或<span style={{ color: '#36f' }}>选择文件</span></div>
                    </div>
                </Dragger>

                <div style={{ textAlign: 'center', fontSize: '12px', color: '#1f1f1f' }}>点击开始上传文件（支持.xls和.xlsx文件）</div>
                {/* <div className='downLoadTemplate' onClick={() => {
                    console.log("asdfasfasfsdfdsafasfsdf")
                    that.hidemodel()
                }}>下载模板文件</div> */}
                <a className='downLoadTemplate' href="https://digital.motion.abb.com.cn/批量添加设备模板.xlsx">下载模板文件</a>

            </Modal>
        );
    }
}
const AddDevicesScreen = Form.create({ name: 'register' })(addDevices);

export default AddDevicesScreen;
