import $axios from '../axios/$axios';

export function devicecbm(data) {
    return $axios({
        url: '/api/v1/devicecbm/' + data,
        method: 'get',
        type: 'json',
        data: data
    })
}

export function devicecbmunitall(data) {
    return $axios({
        url: '/api/v1/devicecbm/unit/all/' + data,
        method: 'get',
        type: 'json',
        data: data
    })
}


export function devicecbmunit(data) {
    return $axios({
        url: '/api/v1/devicecbm/unit/' + data,
        method: 'get',
        type: 'json',
        data: data
    })
}

export function updatedevicecbmunit(data) {
    return $axios({
        url: '/api/v1/devicecbm/unit/' + data,
        method: 'put',
        type: 'json',
        data: ""
    })
}