import * as actionTypes from '../constants/index';
const _userInfo = ''

const orgInfo = {
    "id": 0,
    "name": "",
    "industry": "",
    "address": "",
    "code": "",
    "createdTime": 0,
    "cpId": 0,
    "cpName": "",
    "cpContact": "",
    "cpContactPhone": "",
    "canMigration": false,
    "canHandover": false,
    "canTransOrganization": false,
    "canCancelCpPermission": false,
    "isDisplayOrgCode": false,
    "contactsUpdateTime" : 0,
    "contactsUpdateUser": "",
    "contacts": [],
    "lastModifyRecord" : {
        "modifyDate":"",
        "userName":"",
        "userOrgType":0
    }
}
const userInfo = (state = _userInfo, action) => {
	switch (action.type) {
		case actionTypes.SET_USERINFO:
			
			return action.data;
		default:
			return state;
	}
};

const getOrgInfo = (state = orgInfo, action) => {
	switch (action.type) {
		case actionTypes.MAIN_ORGINFO:
			
			return action.data;
		default:
			return state;
	}
};


export   {userInfo,getOrgInfo};
