import React, { Component } from 'react';
import { Modal } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { connect } from 'react-redux';
import { setShowIndustriesConfig } from '../../../store/actionCreators';
import { APIQueryIndestryList } from '../../../../../api/user';

class IndustriesModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: true,
            dataSource: []
        };

        
    }
    componentDidMount(){
        this._requestIndustries()
    }

    _requestIndustries = () => {

        APIQueryIndestryList().then(res => {
            if (res !== undefined) {
                let requestData = res.data.data
 
                let config = { ...this.props.getShowIndustriesConfig }
                config.requestData = requestData

                if (requestData.length === 1) {
                    let item = requestData[0]

                    config.showData = [{
                        firstIndustry: item.firstIndustry,
                        secondIndustries: [item.secondIndustry]
                    }]

                } else if (requestData.length > 1) {

                    let showData = []

                    let industry = {
                        firstIndustry: '',
                        secondIndustries: []
                    }
                    requestData.map((item, index) => {
 
                        if (index === 0) {
                            industry = {
                                firstIndustry: item.firstIndustry,
                                secondIndustries: [item.secondIndustry
                                ]
                            }
                        } else {
                            if (industry.firstIndustry === item.firstIndustry) {
                                industry.secondIndustries.push(item.secondIndustry)

                            } else {
                                showData.push(industry)
                                industry = {
                                    firstIndustry: item.firstIndustry,
                                    secondIndustries: [item.secondIndustry]
                                }
                            }

                        }


                    })

                    showData.push(industry)

                    config.showData = showData

                }
 
                this.props.updateShowIndustriesConfig(config)

            }

        }).catch(err => {

            if (err && err.message) {
                console.log(err.message);
            }

            // message.error('行业信息获取失败')
        })
    }

    hidemodel = (data) => {
        let config = {...this.props.getShowIndustriesConfig}
        config.visible = false
        this.props.updateShowIndustriesConfig(config)
    
    }
 
    render() {
        const that = this;

        let config = this.props.getShowIndustriesConfig

        const modelProps = {
            title: config.title,
            centered: config.centered,
            width: config.width,
            visible: config.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel() },
            onCancel: () => { that.hidemodel() },
            footer: [


            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div className='industry_box'>
                    <div className='industry_header'>
                        <div className='left'>一级行业</div>
                        <div className='right'>二级行业</div>
                    </div>

                    {
                        config.showData.map((item, index) => {
                            return <div className='item_box'>
                                <div className='left'>{item.firstIndustry}</div>
                                <div className='right'>
                                    {
                                        item.secondIndustries.map((item, index) => {
                                            return <div>{item}</div>
                                        })
                                    }
                                </div>


                            </div>
                        })
                    }

                </div>

            </Modal>
        );
    }
}



const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    updateShowIndustriesConfig: data => {

        dispatch(setShowIndustriesConfig(data))
    }

});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IndustriesModel);

