import React, { Component } from 'react';
import { Table, Menu, Dropdown, message, Tooltip } from 'antd';
import { getApplyType, editInfo, assetgroupsample, APImovegroup, removeassetgroup, editassetgroup, getmotor, combinationPowertrain, createDevice, deleteasset, APIMoveOrgGroup } from '@/api/device.js';
import { loading } from '@/redux/actions/setting';
import { setUserInfo } from '@/redux/actions/userInfo';
import { selectequipment, selectUsergroup } from '@/redux/actions/userlist';
import { setSubscription, setSubid, setSubselect } from '@/redux/actions/subscription'
import { setEventDetailStatus } from '@/redux/actions/event';
import { setAssetDetail, setAssetlabel, setTopdevice, settreeSelect,settreeCopy, setAssetIdCopy, setAssetIsPowertrain, setAssetStatus, setTrendsData, setassettype, deviceDetail, deviceTime, setselect, settree, setassetId, setevent, eventDetaildevice, checkAllgen } from '@/redux/actions/device';
import { connect } from 'react-redux';
import { userCheckDevice } from '@/redux/actions/device';
import { setplant, setAddplanttype, setAddDeviceProcess } from '@/redux/actions/plant';
import { withRouter } from 'react-router-dom';
import DeviceModel from './components/deviceGroup';
import { myOrgtree, singledeviceList, plantListdata, deviceList, subscriptionlist, Devicesubscriptionlist } from '@/utils/devicesData';
import AddassetGroup from './components/addassetGroup';
import AddDevicesScreen from './components/addDevices';
import DeviceEditModel from './components/DeviceEditModel';
import DeviceEditModelNeta from './components/DeviceEditModelNeta';
import Movegroup from './components/movegroup';
import MoveOrgGroup from './components/moveOrgGroup'
import Powertrainmodel from './components/powertrainModel';
import BoundsubForm from './components/boundsub';
import AddDeviceListScreen from './components/addDeviceList';
import Powertrainmodelbreak from './components/powertrainModelbreak';
import Motormodel from './components/Motormodel';
import { subscriptionInformation } from '@/api/subscription';
import RemoweModel from '@/components/remove/removeModel';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import moment from 'moment';
import NoData from '@/components/NoData'
import BoundsubConfigForm from './components/boundsubConfig'
import './style.scss';
import AddDeviceSubscription from './components/addDeviceSubscription';
import AddDeviceUnSubscription from './components/addDeviceUnSubscription';

import { EmptyData } from './style'


const icon_center = {
	margin: '0 10px 0 0',
	display: 'inline'
}
const div_nowrap = {
	display: 'inline'
}
const Tooltip_powertrain = <span>拆分传动链</span>;
const Tooltip_devicedetail = <span>查看</span>;
const Tooltip_deviceedit = <span>编辑</span>;
const Tooltip_sub = <span>订阅</span>;
const Tooltip_change = <span>移动设备组</span>;
const Tooltip_remove = <span>删除设备组</span>;
const Tooltip_remove_asset = <span>删除设备</span>;
const Tooltip_move_org = <span>移动设备组织</span>;
class EUsubscription extends Component {
	constructor(props) {
		super(props);


		props.setProcess(1)

		this.state = {
			isSubAsset: '',
			listassetId: '',
			assetId: '',
			orgId: '',
			modeltype: '',
			disabled: false,
			showmodelmotor: {
				plantlist: [],
				motorlist: [],
			},
			showmodelRemove: {},
			showmodelMove: {
				movegrouplist: []
			},
			showmodelOrgMove: {
			},
			showmodelRemoveAssetGroup: {},
			showPowertrainmodel: {
				powertraindata: {
					motors: [],
					drives: []
				}
			},
			showPowertrainmodelbreak: {
				resolutiondevice: []
			},
			showmodelboundsub: {
				deviceData: {},
				subscriptionData: []
			},
			showmodelboundsubConfig: {
				deviceData: {
					deviceData: {
						subscriptionLevel: "",
						expiredDateTime: ""
					}
				},
				subdata: {}
			},
			showassetGroupmodel: '',
			showDeviceEditModel: {
				movegrouplist: [],
				plantList: [],
				editInfo: {},
				applyType: []
			},
			showDeviceEditModelNeta: {
				movegrouplist: []
			},
			showAddDeviceModel: {
				movegrouplist: []
			},
			showAddDeviceListModel: {
				movegrouplist: []
			},
			showmodel: {
				plantlist: []
			},
			showDeviceSubscriptionModel: {
				visible: false,
				title: "添加传动设备",
				data: {
					subscription: {
						subscriptionId: 0,
						subscriptionLevel: "--",
						subscriptionType: "--",
						validPeriodTime: "--",
						deviceSerialNum: "--",
						// 当前设备是否能绑定该订阅 
						isBindable: false,
					}
				},
				width: 756,
				centered: true,

			},
			showAddDeviceUnSubscriptionModel: {
				visible: false,
				title: "添加传动设备",
				width: 756,
				centered: true,
				data: {
					"createId": "",
					"serialNumber": "", // 设备序列号
					"userInputSN": "",
					"backendIdentifier": "",
					"assetName": "000",
					"deviceSource": 1,
					"objectId": null,
					"applicationName": "电机",
					"assetGroupId": null,
					"isHasSub": false,  // 是否拥有订阅额度
					"isNoSerialNumber": false,
					"IsApplyTasteSub": false, //是否申请体验版订阅
					"createSource": 2,
					"gateway": {
						"gatewaySN": "7330164",
						"gatewayType": 1
					},

					"subscription": {
						subscriptionId: 0,
						subscriptionLevel: "--",
						subscriptionType: "--",
						validPeriodTime: "--"
					}
				}

			},
			PowertrainIds: [],
			PowertrainmotorsId: '',
			PowertraindrivesId: '',
			assetName: '',
			deviceGroupId: '',
			powertrainId: '',
			pagination: {},
			addChildname: '',
			loading: false,
			exportdata: false,
			changeequipment: false,
			mobileequipment: false,
			resolution: false,
			assetGroupName: '',
			creatassetGroupName: '',
			selectedRowKeys: [],
			resolutiondevice: [],
			motorlist: [],
			motors: [],
			drives: [],
			onedeviceData: {},
			menu: (
				<Menu>
					<Menu.Item onClick={() => this.showmodel(true, '添加传动设备', 1)}>


						<ABB.Icon className='icon_normal' name="abb/drive" sizeClass='small' />
						<span>添加传动设备</span>


					</Menu.Item>
					<Menu.Item onClick={() => this.showmodelmotor(true, '添加电机设备', 6)}>

						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/motor" sizeClass='small' />
						<span>添加电机设备</span>

					</Menu.Item>
					<Menu.Item style={{ borderBottom: "1px solid #BABABA", borderTop: "1px solid #BABABA" }} onClick={() => this.showPowertrainmodel(true, '组合传动链', 2)}>


						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/powertrain" sizeClass='small' />
						<span>组合传动链</span>


					</Menu.Item>
					<Menu.Item onClick={() => this.showassetGroupmodel(true, '编辑设备组', 7)}>

						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/edit" sizeClass='small' />
						<span>编辑设备组</span>


					</Menu.Item>
					<Menu.Item onClick={() => this.showDeleteAssetGroupModel(true, '删除设备组', 9, '删除成功后将清除该设备组所有信息，确定删除当前设备组？')}>


						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/trash" sizeClass='small' />
						<span>删除设备组</span>


					</Menu.Item>
				</Menu>
			),

			columns: [
				{
					title: '',
					dataIndex: '',
					key: 0,
					width: 20,

				},
				{
					title: '编号',
					dataIndex: 'index',
					key: 1,
					width: 50,

				},
				{
					title: '设备名称',
					dataIndex: 'assetName',
					key: 2,
					width: 160,
				},
				{
					title: '设备序列号',
					dataIndex: 'assetSrNo',
					key: 3,
					width: 100,
				},
				{
					title: '型号',
					dataIndex: 'driveType',
					key: 4,
					width: 90
				},
				{
					title: '设备类型',
					dataIndex: 'assetType',
					key: 5,
					width: 80,
				},
				{
					title: '订阅等级',
					dataIndex: 'subscriptionLevel',
					key: 6,
					width: 80,
				},
				{
					title: '订阅类型',
					dataIndex: 'subscriptionType',
					key: 11,
					width: 80,
				},
				{
					title: '订阅状态',
					dataIndex: 'subscriptionStatus',
					key: 7,
					width: 80,
				},
				{
					title: '订阅开始时间',
					dataIndex: 'activatedDateTime',
					key: 8,
					width: 120,
				},
				{
					title: '订阅结束时间',
					dataIndex: 'expiredDateTime',
					key: 9,
					width: 120,
				},
				{
					title: '',
					dataIndex: 'isRenewSubscription',
					key: 20,
					width: 50,
					render: (text, record) => (
						<>
							{
								record.isRenewSubscription === true ? (
									<div className="isRenewSubscription">已续订</div>
								) : ''
							}
						</>
					)
				},
				{
					title: '操作',
					dataIndex: '44',
					key: 10,
					width: 110,
					render: (text, record) => (
						<>
							{
								record.type === 'PowerTrain' ? (
									<div>
										<div style={icon_center}>
											{
												(this.props.userInfo.organizationSubType !== "Float") ? (
													<Tooltip placement="bottom" title={Tooltip_powertrain} >
														<div style={div_nowrap} onClick={() => this.resolution(true, record)}>
															<ABB.Icon name="abb/cut" sizeClass='small' />
														</div>
													</Tooltip>
												) : ''
											}
										</div>
										<div style={icon_center}>
											{
												this.props.userInfo.organizationSubType !== "Float" ? (
													<Tooltip placement="bottom" title={Tooltip_deviceedit}>
														<div style={div_nowrap} onClick={() => this.editDevice(record)}>
															<ABB.Icon name="abb/edit" sizeClass='small' />
														</div>
													</Tooltip>
												) : ''
											}
										</div>


									</div>
								) : (
									<div>
										<div style={icon_center}>
											<Tooltip placement="bottom" title={Tooltip_devicedetail}>
												<div style={div_nowrap} onClick={() => this.checkDevice(record)}>
													<ABB.Icon name="abb/information-circle-2" sizeClass='small' />
												</div>
											</Tooltip>
										</div>

										{
											this.props.userInfo.organizationSubType !== "Float" ? (
												<div style={icon_center}>
													<Tooltip placement="bottom" title={Tooltip_deviceedit}>
														<div style={div_nowrap} onClick={() => this.editDevice(record)}>
															<ABB.Icon name="abb/edit" sizeClass='small' />
														</div>
													</Tooltip>
												</div>
											) : ''

										}


										{/* {
													(this.props.userInfo.organizationType === 'EU' && record.devicetype!=="PowerTrain") ? (
														<div style={icon_center}>
															<Tooltip placement="bottom" title={Tooltip_change}>
																<div style={div_nowrap} onClick={() => this.movedevicegroup(record)}>
																	<ABB.Icon name="abb/rearrange" sizeClass='small' />
																</div>
															</Tooltip>
														</div>
													) : ''
												} */}


										{
											this.props.userInfo.organizationSubType !== "Float" ? (
												<div style={icon_center}>
													<Tooltip placement="bottom" title={Tooltip_sub}>
														<div style={div_nowrap} onClick={() => this.boundsub(record, true, '绑定订阅', 4)}>
															<ABB.Icon name="abb/link" sizeClass='small' />
														</div>
													</Tooltip>
												</div>
											) : ''
										}

										{
											((record.devicetype !== 'PowerTrain' && this.props.userInfo.organizationSubType !== "Float")) ? (
												<div style={icon_center}>
													<Tooltip placement="bottom" title={Tooltip_remove_asset}>
														<div style={div_nowrap} onClick={() => this.FnshowmodelRemove(
															record.id,
															record.deviceGroupId,
															record.isSubAsset,
															true,
															'删除设备',
															5,
															'删除成功后将清除该设备所有信息，确定删除当前设备？')}>
															<ABB.Icon name="abb/trash" sizeClass='small' />
														</div>
													</Tooltip>
												</div>
											) : ''
										}
										{
											((this.props.userInfo.organizationType === 'ABB' || this.props.userInfo.organizationType === 'CP') && this.props.userInfo.isOrgAdministrator && record.devicetype !== "PowerTrain") ? (
												<div style={icon_center}>
													<Tooltip placement="bottom" title={Tooltip_move_org}>
														<div style={div_nowrap} onClick={() => this.movedeviceOrggroup(record)}>
															<ABB.Icon name="abb/rearrange" sizeClass='small' />
														</div>
													</Tooltip>
												</div>
											) : ''
										}

									</div>
								)
							}
						</>

					),
				}
			]
		};
	}
	backendIdentifierFn = (data, state) => {
		if (data !== "" && data !== null) {
			let index = data.lastIndexOf("\_");

			if (state == 0) {
				data = data.substring(0, index);
			} else {
				data = data.substring(index + 1, data.length);
			}


			// data=data.substring(index+1,data.length);
		}
		return data;
	}

	getTypeList = () => {
		getApplyType('').then(res => {
			if (res !== undefined) {
				this.setState({
					applyType: res.data.data
				})
			}
		})
	}
	//修改设备组
	editassetgroup = (val) => {
		if (val.deviceGroupName === this.state.showassetGroupmodel.value) {
			this.setState({
				showassetGroupmodel: {
					visible: false,
					title: '',
					value: '',
					width: 354,
					centered: true,
				}
			})
			return false;
		}
		let url = {
			"id": this.state.deviceGroupId,
			"assetGroupName": val.deviceGroupName.trim(),
		}
		this.setState({
			disabled: true
		})
		editassetgroup(url).then(res => {

			if (res !== undefined) {
				message.success('修改设备组成功')
				this.fetch();
				this.setState({
					disabled: false,
					showassetGroupmodel: {
						visible: false,
						title: '',
						value: '',
						width: 354,
						centered: true,
					}
				})
				let getSubid = this.props.getSubid;

			this.props.settreeSelect({
				isselectId: this.props.gettreeStatus,
				selectedKeys: this.props.gettreeStatus
			})
			// this.deviceList(getSubid.orgId)
			// this.getsublist('/' + getSubid.orgId, 0)
			// this.props.setSubid({
			// 	id: getSubid.orgId,
			// 	orgId: getSubid.orgId,
			// 	type: 3,
			// })
				this.Fnassettype()
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('修改设备组失败')
			}
			this.setState({
				disabled: false
			})
		})
	}
	//点击获取plant接口数据刷新数据
	plantList = (showmodel, title, type) => {
		let that = this;
		this.props.setAddplanttype(0)
		plantListdata(that.props.getSubid.orgId, function (data) {
			that.props.setplant(data)
			if (type === 3) {
				// return data;
			}
			if (type === 1) {
				that.setState({
					modeltype: type,
					showmodel: {
						visible: showmodel,
						title: title,
						value: that.state.creatassetGroupName,
						width: 756,
						plantlist: data,
						centered: true,
						deviceGroupId: that.state.deviceGroupId
					}
				})
			}
			if (type === 6) {
				let url = `/${that.props.getSubid.orgId}`;
				getmotor(url).then(res => {
					if (res !== undefined) {
						if (res.data !== '') {

							that.setState({
								modeltype: type,
								showmodelmotor: {
									visible: showmodel,
									title: title,
									value: that.state.creatassetGroupName,
									width: 354,
									motorlist: res.data,
									plantlist: data,
									centered: true,
									deviceGroupId: that.state.deviceGroupId
								},
							})
						}
					}
				})
			}
		})

	}
	//获取订阅列表
	getsublist = (id, type) => {
		let that = this;
		let { getRadiovalue, gettreeValue } = this.props;
		if (getRadiovalue === 0) {
			subscriptionlist('', id, type, getRadiovalue, function (data) {

				that.props.setSubscription(data)
			})
		}
		else {
			subscriptionlist(gettreeValue, id, type, getRadiovalue, function (data) {

				that.props.setSubscription(data)
			})
		}
	};
	FnDevicesubscriptionlist = (id, type) => {
		let that = this;

		let { getRadiovalue, gettreeValue } = this.props;
		if (getRadiovalue === 0) {
			Devicesubscriptionlist('', id, type, getRadiovalue, function (data) {

				that.props.setSubscription(data)
			})
		}
		else {
			Devicesubscriptionlist(gettreeValue, id, type, getRadiovalue, function (data) {

				that.props.setSubscription(data)
			})
		}

	};
	movedevicegroup = (record) => {
		let movegrouplist = []
		this.props.gettree[0].children.map((item) => {
			let obj = {
				name: item.name,
				id: item.id
			}
			if (item.id !== record.deviceGroupId) {
				movegrouplist.push(obj)
			}

		})
		this.setState({
			onedeviceData: record,
			assetId: record.id,
			modeltype: 10,
			isSubAsset: record.isSubAsset,
			deviceGroupId: record.deviceGroupId,
			// movegrouplist: movegrouplist,
			showmodelMove: {
				visible: true,
				title: "编辑设备",
				value: "",
				width: 354,
				movegrouplist: movegrouplist,
				centered: true,
			}
		})
	}
	movedeviceOrggroup = (record) => {
		this.setState({
			onedeviceData: record,
			assetId: record.id,
			assetSrNo: record.assetSrNo,
			modeltype: 12,
			isSubAsset: record.isSubAsset,
			deviceGroupId: record.deviceGroupId,
			// movegrouplist: movegrouplist,
			showmodelOrgMove: {
				visible: true,
				title: "移动设备到其他组织",
				value: "",
				width: 354,
				centered: true,
			}
		})
	}
	APIMoveDeviceOrgGroup = (data) => {
		let url = {
			serialnumber: data.serialnumber,
			targetAssetGroupId: data.targetAssetGroupId,
		 
		}
		APIMoveOrgGroup(url).then(res => {
			if (res !== undefined) {
				message.success('移动设备组成功')
				this.Fnassettype()

			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('移动设备到其他组织失败')
			}

		})
	}
	//移动设备组接口
	moveAssetGroup = (val) => {
		let { showDeviceEditModel, showDeviceEditModelNeta } = this.state;
		if (val == showDeviceEditModel.deviceGroupName ||
			val == showDeviceEditModelNeta.deviceGroupName || val === "") {
			return false;
		}
		else {
			let url = {
				"assetGroupId": val,
				"assetId": this.state.assetId
			}
			// if (this.props.userInfo.isOrgAdministrator === true) {
			APImovegroup(url).then(res => {

				if (res !== undefined) {
					message.success('移动设备组成功')
					this.fetch();
					this.setState({
						showDeviceEditModel: {
							visible: false,
							title: '',
							value: '',
							width: 354,
							centered: true,
							deviceGroupName: '',
							movegrouplist: [],
							plantList: [],
							editInfo: {},
							applyType: []
						},
						showDeviceEditModelNeta: {
							visible: false,
							title: '',
							value: '',
							neta21sn: '',
							nodeid: '',
							width: 354,
							centered: true,
							deviceGroupName: '',
							isOrgAdministrator: '',
							movegrouplist: [],
						}
					})
					this.Fnassettype()
				}
			}).catch(err => {
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data)
				}
				else {
					message.error('移动设备组失败')
				}

			})
			// }
		}

	}
	//编辑设备基本数据获取
	getEditInfo = (id, isSubAsset) => {
		console.log("state================")
		editInfo(id, isSubAsset).then(res => {
			const resObj = res.data
			if (200 === resObj.code) {
				const editInfo = resObj.data;
				return editInfo;
			} else {

			}
		}).catch(e => {

		})
	}
	editDevice = (record) => {
		let movegrouplist = []
		let neta21sn = '';
		let nodeid = '';


		neta21sn = this.backendIdentifierFn(record.assetSrNo, 0)
		nodeid = this.backendIdentifierFn(record.assetSrNo, 1)

		assetgroupsample(this.props.getSubid.orgId).then(res => {
			let resdata = res.data;
			let obj = {};
			resdata.map(item => {
				obj = {
					name: item.groupName,
					id: item.id
				}
				movegrouplist.push(obj)

			})
			this.plantList('', '', 3);
			this.getTypeList();
			let addresslist = this.props.getplant;
			var isSubAsset = false;
			console.log("addresslist----------", record)
			if (record.isSubAsset === true) {
				isSubAsset = true
			}
			else {
				isSubAsset = false
			}

			editInfo(record.id, isSubAsset).then(res => {
				const resObj = res.data
				if (200 === resObj.code) {
					const editInfo = resObj.data;

					console.log('editInfo===================', editInfo)

					let info = {
						visible: true,
						title: '编辑设备',
						value: record.assetName,
						width: 354,
						centered: true,
						type: record.type,
						neta21sn: neta21sn,
						nodeid: nodeid,
						deviceGroupName: record.deviceGroupId,
						isOrgAdministrator: this.props.userInfo.isOrgAdministrator,
						movegrouplist: movegrouplist,
						plantList: addresslist,
						editInfo: editInfo,
						applyType: this.state.applyType,
						assetId: record.id,

					}
					this.child.getDeviceInfo(info)
					this.setState({
						onedeviceData: record,
						assetId: record.id,
						modeltype: 8,
						isSubAsset: record.isSubAsset,
						deviceGroupId: record.deviceGroupId,
					})
				} else {

				}
			}).catch(e => {

			})

		})

	}
	//组合设备获取接口
	getcombinationPowertrain(showmodel, title, type) {
		let url = `${this.props.getSubid.orgId}/${this.state.deviceGroupId}`
		combinationPowertrain(url).then(res => {
			if (res !== undefined) {
				this.setState({
					motors: res.data.motors,
					drives: res.data.drives
				}, () => {
					this.setState({
						modeltype: type,
						showPowertrainmodel: {
							visible: showmodel,
							title: title,
							value: '',
							width: 354,
							centered: true,
							powertraindata: {
								motors: this.state.motors,
								drives: this.state.drives
							}
						}
					})
				})
			}
		})
	}
	//拆分传动链
	resolution = (resolution, data) => {
		if (data !== undefined) {
			this.setState({
				// resolution,
				deviceGroupId: data.deviceGroupId,
				resolutiondevice: data.devices,
				powertrainId: data.id
			}, () => {
				this.showPowertrainmodelbreak(true, '拆分传动链', 3)
			})
		}
	}
	checkDevice(data) {
		this.props.eventDetaildevice('0')
		this.props.checkAllgen('0');
		this.props.setEventDetailStatus({
			startime: '',
			endtime: '',
			page: 1,
			EventType: 0,
			eventName: '',
			forComment: true,
			forEventRecord: false,
			group: false,
			defaultActiveKey: []
		})
		// this.props.setAssetDetail({
		// 	deviceName: data.assetName,
		// 	sn: data.assetSrNo
		// })
		if (data.type === 'PowerTrain') {
			this.props.setassetId('')
			this.props.userCheckDevice(2)
			this.FndeviceDetail(data.id, data.type, data.devicetype, data.subscriptionStatus)
			this.props.setAssetlabel(["设备管理", "传动链", "设备详情"])
		}
		else {
			this.props.setassetId('')
			this.props.userCheckDevice(11)
			this.FndeviceDetail(data.id, data.type, data.devicetype, data.subscriptionStatus)
			if (data.devicetype === "PowerTrain") {
				this.props.setAssetlabel(["设备管理", "设备详情"])
			}
			else {
				this.props.setAssetlabel(["设备管理", "设备详情"])
			}

		}
	}
	treechildren = (data, id) => {
		data.map(item => {
			item.key = item.id + "/" + item.type;
			item.orgId = id;
			if (item.children.length > 0 && item.ag.length <= 0) {
				if (item.keyWord === null) {
					item.children.map(item1 => {
						item1.key = item1.id + "/" + item1.type;
						item1.orgId = id;
						this.treechildren([item1], item1.orgId)
					})
				}
				else {
					return false
				}

			}
			if (item.children.length <= 0 && item.ag.length > 0) {
				if (item.keyWord === null) {
					item.children.map(item1 => {
						item1.key = item1.id + "/" + item1.type;
						item1.orgId = id;
						this.treechildren([item1], item1.orgId)
					})
				}
				else {
					return false
				}

				item.children = [...item.ag];

			}
		})

		return data;
	}
	//组织结构接口数据
	fetch = () => {
		let that = this;
		myOrgtree('', function (data) {
			let dataid = data[0].id


			let treedata = that.treechildren([...data], dataid);
			that.props.settreeCopy(treedata)
			that.props.settree(treedata)
		})

		
	};
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	//创建设备组
	createDeviceGroup(val) {
		let that = this;
		if (val !== '') {
			this.setState({
				disabled: true
			})
			let url = {
				"organizationId": that.props.getSubid.orgId,
				"assetGroupName": val.deviceGroupName.trim(),
				"description": ''
			}
			createDevice(url).then((res) => {
				if (res !== undefined) {
					message.success('创建设备组成功！')
					that.fetch();
					that.setState({
						disabled: false,
						showassetGroupmodel: {
							visible: false,
							title: '',
							value: '',
							width: 354,
							centered: true,
						}
					})
					let getSubid = this.props.getSubid;

			this.props.settreeSelect({
				isselectId: this.props.gettreeStatus,
				selectedKeys: this.props.gettreeStatus
			})
			// this.deviceList(getSubid.orgId)
			// this.getsublist('/' + getSubid.orgId, 0)
			// this.props.setSubid({
			// 	id: getSubid.orgId,
			// 	orgId: getSubid.orgId,
			// 	type: 3,
			// })
					that.Fnassettype()
				}

			}).catch(err => {
				if (err.status === 400) {
					message.warning(err.data)
				}
				else if (err.status === 404) {
					message.warning(err.data)
				}
				else {
					message.error('创建设备组失败')
				}
				this.setState({
					disabled: false
				})
			})
		}
		else {
			message.error("设备组名称不能为空！")
		}
	}
	//点击获取设备列表接口数据
	deviceList = (org) => {
		let that = this;
		let { getRadiovalue, gettreeValue } = this.props;
		if (org !== undefined && org !== '') {
			deviceList(gettreeValue, org, 25, getRadiovalue, function (data) {
				that.props.selectequipment(data)
			})
		}
	}
	//点击获取设备列表接口数据
	FnsingledeviceList = (org, agId) => {
		let that = this;
		let { getRadiovalue, gettreeValue } = this.props;
		// let url = `?nodeId=${nodeId}&condition=true`
		// if (org !== undefined && org !== '') {
		singledeviceList(gettreeValue, org, agId, 25, getRadiovalue, function (data) {
			that.props.selectequipment(data)
		})
		// }
	}


	//判断是否是设备组还是组织
	Fnassettype = () => {
		let getSubid = this.props.getSubid;
		if (getSubid.type === 100) {
			this.FnDevicesubscriptionlist('/' + this.state.deviceGroupId, 0)
			this.FnsingledeviceList(getSubid.orgId, this.state.deviceGroupId);
		}
		else if (getSubid.type === 1) {
		}
		else {
			this.deviceList(getSubid.id)
			this.getsublist('/' + getSubid.id, 0)
		}
	}
	//根据id获取详情
	FndeviceDetail(id, type, devicetype, status) {
		let that = this;
		that.props.setassetId(id);
		that.props.setAssetIdCopy(id);
		that.props.setassettype(devicetype);
		if (devicetype === "PowerTrain") {
			that.props.setAssetIsPowertrain(true)
		}
		else {
			that.props.setAssetIsPowertrain(false)
		}
		that.props.setAssetStatus(status)
		if (that.props.getassetId !== id) {
			that.props.deviceDetail({})
			that.props.setTrendsData([]);
			that.props.setevent({})
		}
		// that.props.userCheckDevice(11)

	}
	//根据id获取详情
	changeDevicenameFndeviceDetail(id, type, devicetype) {

	}

	//删除设备i
	deleteasset = () => {
		if (this.state.isSubAsset == true) {
			message.warning("请先拆分传动链");
			this.setState({
				showmodelRemove: {
					visible: false,
					title: '',
					value: '',
					width: 504,
					centered: true,
				}
			});
			return;
		}
		let url = `/${this.state.listassetId}`
		this.setState({
			disabled: true
		})
		deleteasset(url).then(res => {
			message.success("删除成功")
			this.setState({
				disabled: false,
				showmodelRemove: {
					visible: false,
					title: '',
					value: '',
					width: 504,
					centered: true,
				}
			})
			this.Fnassettype()
			// this.FnsingledeviceList(this.props.userInfo.orgId,this.state.deviceGroupId);
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.warning("删除失败")
			}
			this.setState({
				disabled: false,
				showmodelRemove: {
					visible: false,
					title: '',
					value: '',
					width: 504,
					centered: true,
				}
			})
		})
	}
	//隐藏弹出框
	hidemodel = (data) => {
		console.log("开始隐藏了吗")
		this.setState({
			showmodelboundsubConfig: {
				visible: false,
				deviceData: {
					deviceData: {
						subscriptionLevel: "",
						expiredDateTime: ""
					}
				},
				subdata: {}
			},
			showDeviceEditModel: {
				visible: data,
				title: '',
				value: '',
				width: 354,
				centered: true,
				deviceGroupName: '',
				movegrouplist: [],
				plantList: [],
				editInfo: {},
				applyType: []
			},
			showAddDeviceModel: {
				visible: data,
				title: '',
				value: '',
				width: 354,
				centered: true,
				deviceGroupName: '',
				movegrouplist: []
			},
			showAddDeviceListModel: {
				visible: data,
				title: '',
				value: '',
				width: 354,
				centered: true,
				deviceGroupName: '',
				movegrouplist: []
			},
			showDeviceEditModelNeta: {
				visible: data,
				title: '',
				value: '',
				neta21sn: '',
				nodeid: '',
				width: 354,
				centered: true,
				deviceGroupName: '',
				movegrouplist: []
			},
			showmodel: {
				visible: data,
				title: '',
				width: 756,
				value: '',
				plantlist: [],
				centered: true,
			},
			showassetGroupmodel: {
				visible: data,
				title: '',
				value: '',
				width: 354,
				centered: true,
			},
			showPowertrainmodel: {
				visible: data,
				title: '',
				value: '',
				width: 354,
				centered: true,
				powertraindata: {
					motors: [],
					drives: []
				}
			},
			showPowertrainmodelbreak: {
				visible: data,
				title: '',
				value: '',
				width: 354,
				centered: true,
				resolutiondevice: []
			},
			showmodelboundsub: {
				visible: data,
				title: '',
				value: '',
				width: 354,
				centered: true,
				deviceData: {},
				subscriptionData: []
			},
			showmodelRemove: {
				visible: false,
				title: '',
				width: 504,
				centered: true,
			},
			showmodelMove: {
				visible: false,
				title: '',
				width: 354,
				centered: true,
				movegrouplist: []
			},
			showmodelOrgMove: {
				visible: false,
				title: '',
				width: 354,
				centered: true,
			},
			showmodelRemoveAssetGroup: {
				visible: false,
				title: '',
				width: 504,
				centered: true,
			},
			showmodelmotor: {
				visible: false,
				title: '',
				value: '',
				plantlist: [],
				motorlist: [],
				width: 354,
				centered: true,
			}
		})
	}
	//显示添加的设备列表
	showuploadDeviceList = (data) => {
		this.child1.getDeviceList(data, this.props.getSubid.orgId);
		this.setState({
			showAddDeviceListModel: {
				visible: true,
				title: '',
				value: '',
				width: 354,
				centered: true,
				deviceGroupName: '',
				devicelist: data
			}
		})
	}

	//编辑设备
	submitmodelEditDevice = () => {

		console.log("刷新列表数据")

		let onedeviceData = this.state.onedeviceData;
		// this.fetch();
		this.Fnassettype()
		this.changeDevicenameFndeviceDetail(onedeviceData.id, onedeviceData.type, onedeviceData.devicetype)



	}

	//提交数据
	submitmodel = (val, PowertraindrivesId, PowertrainmotorsId, type, inputmessage) => {
		let modeltype = this.state.modeltype;
		console.log(val, "modeltype-----", modeltype)
		let onedeviceData = this.state.onedeviceData;
		if (modeltype === 0) {
			this.createDeviceGroup(val)
		}
		if (modeltype === 1) {
			this.setState({
				showmodel: {
					visible: false,
					title: '',
					value: '',
					width: 764,
					centered: true,
					plantlist: []
				}
			})
			this.Fnassettype()
		}
		if (modeltype === 2) {
			this.setState({
				showPowertrainmodel: {
					visible: false,
					title: '',
					value: '',
					width: 354,
					centered: true,
					powertraindata: {
						motors: [],
						drives: []
					}
				}
			})
			this.Fnassettype()
		}
		if (modeltype === 3) {
			this.setState({
				showPowertrainmodelbreak: {
					visible: false,
					title: '',
					value: '',
					width: 354,
					centered: true,
					resolutiondevice: []
				}
			})
			this.Fnassettype()
		}
		if (modeltype === 4) {
			console.log("绑定后刷新页面")
			this.setState({
				showmodelboundsub: {
					visible: false,
					title: '',
					value: '',
					width: 504,
					centered: true,
					deviceData: {},
					subscriptionData: []
				},
				showmodelboundsubConfig: {
					visible: false,
					deviceData: {
						deviceData: {
							subscriptionLevel: "",
							expiredDateTime: ""
						}
					},
					subdata: {}
				}


			})
			this.Fnassettype()
		}
		if (modeltype === 5) {
			this.deleteasset()
		}
		if (modeltype === 6) {
			this.setState({
				showmodelmotor: {
					visible: false,
					title: '',
					value: '',
					plantlist: [],
					motorlist: [],
					width: 350,
					centered: true,
				}
			})
			this.Fnassettype()
		}
		if (modeltype === 7) {
			this.editassetgroup(val)
		}
		if (modeltype === 8) {
			this.fetch();
			this.Fnassettype()
			this.changeDevicenameFndeviceDetail(onedeviceData.id, onedeviceData.type, onedeviceData.devicetype)
		}
		if (modeltype === 9) {
			this.deleteAssetGroup();
		}
		if (modeltype === 10) {
			this.moveAssetGroup(val);
		}
		if (modeltype === 11) {
			// this.editAssetNeta(val);
			// this.moveAssetGroup(val.deviceGroupName)
		}
		if (modeltype === 12) {
			const data = {
				serialnumber: this.state.assetSrNo,
				targetAssetGroupId: val.targetAssetGroupId,
				targetPlantId: val.targetPlantId
			}
			this.APIMoveDeviceOrgGroup(data);
			this.setState({
				showmodelOrgMove: {
					visible: false,
					title: "",
					value: "",
					width: 354,
					centered: true,
				}
			})
		}
	}

	checkDeviceCallBack = (data) => {


		this.props.setProcess(2)
		// if (data.isHasSub === true) {
		//显示已经有订阅model
		this.setState({
			showmodel: {
				visible: false,
				title: '',
				value: '',
				width: 764,
				centered: true,
				plantlist: []
			},
			showDeviceSubscriptionModel: {
				visible: true,
				title: "添加传动设备",
				data: data,
				width: 756,
				centered: true,
				deviceGroupId: ''
			},

		})
		// } else {
		// 	//显示没有订阅model

		// 	this.setState({
		// 		showmodel: {
		// 			visible: false,
		// 			title: '',
		// 			value: '',
		// 			width: 764,
		// 			centered: true,
		// 			plantlist: []
		// 		},
		// 		showAddDeviceUnSubscriptionModel: {
		// 			visible: true,
		// 			title: "添加传动设备",
		// 			width: 756,
		// 			centered: true,
		// 			data: data,
		// 		},

		// 	})
		// }


	}
	//添加设备
	showmodel = (showmodel, title, type) => {
		this.plantList(showmodel, title, type)
	}

	showDeleteAssetGroupModel = (showmodel, title, type, value) => {
		this.setState({
			modeltype: type,
			showmodelRemoveAssetGroup: {
				visible: showmodel,
				title: title,
				value: value,
				width: 504,
				centered: true,
			}
		})
	}
	//删除设备组接口
	deleteAssetGroup = () => {
		let url = `/${this.state.deviceGroupId}`;
		this.setState({
			disabled: true
		})
		removeassetgroup(url).then(res => {
			message.success("删除设备组成功")
			this.fetch();
			this.setState({
				disabled: false,
				showmodelRemoveAssetGroup: {
					visible: false,
					title: '',
					value: '',
					width: 504,
					centered: true,
				}
			})
			let getSubid = this.props.getSubid;

			this.props.settreeSelect({
				isselectId: this.props.gettreeStatus,
				selectedKeys: this.props.gettreeStatus
			})
			this.deviceList(getSubid.orgId)
			this.getsublist('/' + getSubid.orgId, 0)
			this.props.setSubid({
				id: getSubid.orgId,
				orgId: getSubid.orgId,
				type: 3,
			})
			this.props.setTopdevice(true);


		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.warning("删除设备组失败")
			}
			this.fetch();
			this.setState({
				disabled: false,
				showmodelRemoveAssetGroup: {
					visible: false,
					title: '',
					value: '',
					width: 350,
					centered: true,
				}
			})
			this.Fnassettype();

		})
	}

	//添加设备组
	showassetGroupmodel = (showmodel, title, type) => {
		let value = ''
		if (type === 0) {
			value = undefined
		}
		if (type === 7) {
			value = this.state.creatassetGroupName
		}
		this.setState({
			modeltype: type,
			showassetGroupmodel: {
				visible: showmodel,
				title: title,
				value: value,
				width: 354,
				centered: true,
			}
		})
	}
	//组合传动链
	showPowertrainmodel = (showmodel, title, type) => {
		this.getcombinationPowertrain(showmodel, title, type)
	}
	//拆分
	showPowertrainmodelbreak = (showmodel, title, type) => {
		// this.getcombinationPowertrain()
		this.setState({
			modeltype: type,
			showPowertrainmodelbreak: {
				visible: showmodel,
				title: title,
				value: '',
				width: 354,
				centered: true,
				resolutiondevice: this.state.resolutiondevice,
				powertrainId: this.state.powertrainId
			}
		})
	}
	//绑定订阅
	boundsub = (data, showmodel, title, type) => {


		console.log("当前订阅信息-------------", data)

		let url = data.id + "?isSubAsset=" + data.isSubAsset
		subscriptionInformation(url).then(res => {
			this.setState({
				assetId: data.id,
				modeltype: type,
				isSubAsset: data.isSubAsset,
				deviceGroupId: data.deviceGroupId,
				showmodelboundsub: {
					visible: showmodel,
					title: title,
					value: '',
					width: 504,
					centered: true,
					assetId: data.id,
					isSubAsset: data.isSubAsset,
					deviceData: data,
					subscriptionData: res.data.data
				}
			})
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.warning("失败")
			}

		})

	}

	//显示是否立即生效
	// subdata 验证订阅序列号返回的订阅数据
	// deviceData  当前设备信息
	showModelSubConfig = (subdata, deviceData) => {
		console.log("showModelSubConfig------------------", subdata, "devicedata====", deviceData)


		this.setState({
			showmodelboundsubConfig: {
				visible: true,
				deviceData: deviceData,
				subdata: subdata
			}
		})
	}
	//删除
	FnshowmodelRemove = (id, deviceGroupId, isSubAsset, showmodel, title, type, value) => {
		this.setState({
			deviceGroupId: deviceGroupId,
			modeltype: type,
			isSubAsset: isSubAsset,
			listassetId: id,
			showmodelRemove: {
				visible: showmodel,
				title: title,
				value: value,
				width: 504,
				centered: true,
			}
		})
	}
	showmodelmotor = (showmodel, title, type) => {
		this.plantList(showmodel, title, type)
	}
	//获取设备组id保存
	getdeviceGroupId = (data, name) => {
		// console.log("datadatadatadata---",data)
		let menu = '';
		if (data.assetGroupType === 1) {
			menu = (
				<Menu>
					<Menu.Item onClick={() => this.showmodel(true, '添加传动设备', 1)}>

						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/drive" sizeClass='small' />
						<span>添加传动设备</span>

					</Menu.Item>
					<Menu.Item onClick={() => this.showmodelmotor(true, '添加电机设备', 6)}>


						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/motor" sizeClass='small' />
						<span>添加电机设备</span>

					</Menu.Item>
					<Menu.Item style={{ borderBottom: "1px solid #BABABA", borderTop: "1px solid #BABABA" }} onClick={() => this.showPowertrainmodel(true, '组合传动链', 2)}>


						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/powertrain" sizeClass='small' />
						<span>组合传动链</span>


					</Menu.Item>
					<Menu.Item onClick={() => this.showassetGroupmodel(true, '编辑设备组', 7)}>


						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/edit" sizeClass='small' />
						<span>编辑设备组</span>


					</Menu.Item>
					<Menu.Item onClick={() => this.showDeleteAssetGroupModel(true, '删除设备组', 9, '删除成功后将清除该设备组所有信息，确定删除当前设备组？')}>

						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/trash" sizeClass='small' />
						<span>删除设备组</span>


					</Menu.Item>
				</Menu>
			)
		}
		else {
			menu = (
				<Menu>
					<Menu.Item onClick={() => this.showmodel(true, '添加传动设备', 1)}>

						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/drive" sizeClass='small' />
						<span>添加传动设备</span>


					</Menu.Item>
					<Menu.Item onClick={() => this.showmodelmotor(true, '添加电机设备', 6)}>

						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/motor" sizeClass='small' />
						<span>添加电机设备</span>


					</Menu.Item>
					<Menu.Item style={{ borderBottom: "1px solid #BABABA", borderTop: "1px solid #BABABA" }} onClick={() => this.showPowertrainmodel(true, '组合传动链', 2)}>

						<ABB.Icon style={{ margin: '0px 14px 0 0px' }} name="abb/powertrain" sizeClass='small' />
						<span>组合传动链</span>


					</Menu.Item>
				</Menu>
			)
		}
		this.setState({
			deviceGroupId: data.id,
			creatassetGroupName: name,
			menu: menu
		})
	}
	customExpandIcon = (props) => {

		if (props.record.devices !== undefined) {
			if (props.expanded) {
				return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
					props.onExpand(props.record, e);
				}}><ABB.Icon name="abb/down" sizeClass='small' /></a>
			} else {
				return <a style={{ color: 'black', marginRight: 8 }} onClick={e => {
					props.onExpand(props.record, e);
				}}><ABB.Icon name="abb/right" sizeClass='small' /></a>
			}
		} else {
			return <span style={{ marginRight: 8 }}></span>
		}
	}
	onRef = (ref) => {
		this.child = ref
	}

	reloadData = () => {
		this.setState({
			showAddDeviceListModel: {
				visible: false,
				title: '',
				value: '',
				width: 354,
				centered: true
			},
		})
		this.Fnassettype()
	}

	//关闭已绑定订阅model
	closeAddDeviceSubscription = () => {

		let model = this.state.showDeviceSubscriptionModel

		this.setState({

			showDeviceSubscriptionModel: {
				...model, visible: false
			}
		})
	}

	//关闭未绑定订阅model
	closeAddDeviceUnSubscription = () => {

		console.log("关闭model")
		const config = { ...this.state.showAddDeviceUnSubscriptionModel }
		config.visible = false
		this.setState({
			showAddDeviceUnSubscriptionModel: config
		})
	}

	//绑定其他订阅
	bindOtherSubscription = () => {
		console.log("绑定其他订阅bindOtherSubscription")
		let newshowDeviceSubscriptionModel = { ...this.state.showDeviceSubscriptionModel }
		newshowDeviceSubscriptionModel.visible = false;
		newshowDeviceSubscriptionModel.data.subscription = {
			subscriptionId: 0,
			subscriptionLevel: "--",
			subscriptionType: "--",
			validPeriodTime: "--"
		}
		this.setState({
			showDeviceSubscriptionModel: {
				visible: false,
				title: "添加传动设备",
				data: {
					subscription: {
						subscriptionId: 0,
						subscriptionLevel: "--",
						subscriptionType: "--",
						validPeriodTime: "--"
					}
				},
				width: 756,
				centered: true,

			},
			showAddDeviceUnSubscriptionModel: {
				visible: true,
				title: "添加传动设备",
				width: 756,
				centered: true,
				data: newshowDeviceSubscriptionModel.data,
			},
		})
	}

	render() {
		let mytablecolumns = this.state.columns;
		let _self = this;
		let { disabled } = _self.state;
		let { equipmentdata, userInfo, getTopdevice, isloading } = _self.props;
		let isOrgAdministrator = false;

		if (userInfo.organizationType === "EU" && userInfo.isOrgAdministrator === false) {
			isOrgAdministrator = true;
		}
		return (
			<>
				{/* <ABB.Icon name="abb/alarm-bell" sizeClass='small' /> */}
				{
					(getTopdevice === true && _self.props.getSubid.type == 3 && isOrgAdministrator == false && userInfo.organizationSubType !== "Float") ? (
						<ABB.Button
							className="ABB_tab_marginTop"
							// disabled={isOrgAdministrator}
							onClick={() => _self.showassetGroupmodel(true, '添加设备组', 0)}
							type="primary-blue"
							sizeClass='small' theme="filled"
							text="添加设备组"
							icon="abb/plus-in-circle"
						/>

					) : ''
				}
				{/* <Spin spinning={isloading} style={{ height: "90vh" }}> */}


				<div style={{ position: 'relative', minHeight: '70vh' }}>
					{
						equipmentdata.length > 0 ? (
							equipmentdata.map(function (item, index) {
								return (
									<>

										<div className='EUorg_title'>
											<span>{item.name}
												{/* <Icon type="down" className="icon_down"/> */}
											</span>
											{
												((userInfo.organizationSubType !== "Float")) ? (
													<Dropdown
														overlay={_self.state.menu}
														placement="bottomRight"
														trigger={['click']}>
														<div onClick={() => _self.getdeviceGroupId(item, item.name)}>
															<ABB.Icon name="abb/menu" sizeClass='small' style={{ 'color': '#1F1F1F' }} />
														</div>
													</Dropdown>
												) : ''
											}

										</div>
										<div style={{ position: 'relative', minHeight: 60 }}>
											{
												item.assets.length > 0 ? (
													<Table
														size='small'
														scroll={{ x: false }}
														className='org_right'
														ellipsis={true}
														childrenColumnName="devices"
														expandIcon={(props) => _self.customExpandIcon(props)}
														columns={mytablecolumns}
														dataSource={item.assets}
														pagination={{
															pageSize: 10
														}}
														indentSize='30'
														rowKey={(record, item) => `complete${record.id}_${index}`}
													/>
												) : <EmptyData>
													<div className="renderEmpty_center">
														<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
														<p>暂无数据</p>
													</div>
												</EmptyData>
											}
										</div>
									</>
								)
							})
						) : <NoData />
					}
				</div>
				{/* </Spin> */}
				{/* 移动设备组 */}
				<Movegroup
					showmodelMove={_self.state.showmodelMove}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel} />

				<MoveOrgGroup
					showmodelMove={_self.state.showmodelOrgMove}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel} />

				{/*  删除设备*/}
				<RemoweModel
					showmodelRemove={_self.state.showmodelRemove}
					hidemodel={_self.hidemodel}
					disabled={disabled}
					submitmodel={_self.submitmodel} />

				<RemoweModel
					showmodelRemove={_self.state.showmodelRemoveAssetGroup}
					hidemodel={_self.hidemodel}
					disabled={disabled}
					submitmodel={_self.submitmodel} />

				{/* 电机设备 */}
				<Motormodel
					showmodelmotor={_self.state.showmodelmotor}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel} />

				{/* 绑定订阅 */}
				<BoundsubForm
					showmodelboundsub={_self.state.showmodelboundsub}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel}
					showModelSubConfig={_self.showModelSubConfig} />

				<BoundsubConfigForm
					showmodelboundsubConfig={_self.state.showmodelboundsubConfig}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel}
				// showModelSubConfig={()=>_self.showModelSubConfig()}
				/>
				{/* 添加设备 */}
				<DeviceModel
					showmodel={_self.state.showmodel}
					hidemodel={_self.hidemodel}
					deviceGroupId={this.state.deviceGroupId}
					submitmodel={_self.checkDeviceCallBack} />

				{/* {添加设备:-绑定订阅-有订阅} */}
				<AddDeviceSubscription
					showmodel={this.state.showDeviceSubscriptionModel}
					hidemodel={this.closeAddDeviceSubscription}
					deviceGroupId={this.state.deviceGroupId}
					submitmodel={() => { this.Fnassettype() }}
					bindOtherSubscription={this.bindOtherSubscription}
				/>

				{/* {添加设备:-绑定订阅-无订阅} */}
				<AddDeviceUnSubscription
					showmodel={this.state.showAddDeviceUnSubscriptionModel}
					hidemodel={this.closeAddDeviceUnSubscription}
					deviceGroupId={this.state.deviceGroupId}
					submitmodel={() => { this.Fnassettype() }} />

				{/* 添加设备组 */}
				<AddassetGroup
					showassetGroupmodel={_self.state.showassetGroupmodel}
					hidemodel={_self.hidemodel}
					disabled={disabled}
					submitmodel={_self.submitmodel} />

				<AddDevicesScreen
					showassetGroupmodel={_self.state.showAddDeviceModel}
					hidemodel={() => {
						_self.hidemodel(false)
						// _self.showuploadDeviceList()
					}}
					disabled={disabled}
					submitmodel={(data) => {
						_self.showuploadDeviceList(data)
					}} />

				<AddDeviceListScreen
					showassetGroupmodel={_self.state.showAddDeviceListModel}
					hidemodel={_self.hidemodel}
					disabled={disabled}
					onRef={(ref) => { this.child1 = ref }}
					submitmodel={_self.reloadData} />

				{/* 设备改名 */}
				<DeviceEditModel
					showassetGroupmodel={_self.state.showDeviceEditModel}
					hidemodel={_self.hidemodel}
					disabled={disabled}
					submitmodel={_self.submitmodelEditDevice}
					onRef={this.onRef} />

				{/* 设备改名neta21 */}
				<DeviceEditModelNeta
					showassetGroupmodel={_self.state.showDeviceEditModelNeta}
					hidemodel={_self.hidemodel}
					disabled={disabled}
					submitmodel={_self.submitmodel} />

				{/* 组合传动链 */}
				<Powertrainmodel
					showPowertrainmodel={_self.state.showPowertrainmodel}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel} />

				{/* 拆分 */}
				<Powertrainmodelbreak
					showPowertrainmodelbreak={_self.state.showPowertrainmodelbreak}
					hidemodel={_self.hidemodel}
					submitmodel={_self.submitmodel} />
			</>
		);
	}
}


const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setAssetDetail: data => {
		dispatch(setAssetDetail(data));
	},
	setAssetlabel: data => {
		dispatch(setAssetlabel(data));
	},
	setTopdevice: data => {
		dispatch(setTopdevice(data))
	},
	settreeSelect: data => {
		dispatch(settreeSelect(data))
	},
	setAssetIdCopy: data => {
		dispatch(setAssetIdCopy(data));
	},
	setAssetIsPowertrain: data => {
		dispatch(setAssetIsPowertrain(data));
	},
	setAssetStatus: data => {
		dispatch(setAssetStatus(data));
	},
	setTrendsData: data => {
		dispatch(setTrendsData(data));
	},
	selectequipment: data => {
		dispatch(selectequipment(data));
	},
	deviceDetail: data => {
		dispatch(deviceDetail(data));
	},
	selectUsergroup: data => {
		dispatch(selectUsergroup(data));
	},
	setAddplanttype: data => {
		dispatch(setAddplanttype(data))
	},
	deviceTime: data => {
		dispatch(deviceTime(data))
	},
	loading: data => {
		dispatch(loading(data))
	},
	setselect: data => {
		dispatch(setselect(data))
	},
	userCheckDevice: data => {
		dispatch(userCheckDevice(data))
	},
	setUserInfo: data => {
		dispatch(setUserInfo(data))
	},
	settree: data => {
		dispatch(settree(data))
	},
	settreeCopy: data => {
		dispatch(settreeCopy(data))
	},
	setassetId: data => {
		dispatch(setassetId(data))
	},
	setplant: data => {
		dispatch(setplant(data))
	},
	setassettype: data => {
		dispatch(setassettype(data))
	},
	setevent: data => {
		dispatch(setevent(data))
	},
	setSubscription: data => {
		dispatch(setSubscription(data));
	},
	setSubid: data => {
		dispatch(setSubid(data));
	},
	setSubselect: data => {
		dispatch(setSubselect(data))
	},
	setEventDetailStatus: data => {
		dispatch(setEventDetailStatus(data))
	},
	checkAllgen: data => {
		dispatch(checkAllgen(data))
	},
	eventDetaildevice: data => {
		dispatch(eventDetaildevice(data))
	},
	setProcess: data => {
		dispatch(setAddDeviceProcess(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EUsubscription));

// export default EUsubscription;

