import React, { Component } from 'react';
import { Select, Icon, message, Table, Tooltip, Spin } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deviceDetail, checkAllgen, eventDetaildevice, userCheckDevice, setevent } from '@/redux/actions/device';
import { setEventDetail } from '@/redux/actions/event';
import { Fneventlist } from '@/utils/devicesData'
import { eventDetailData, chartConfig, easemobim_parameter } from '@/api/event.js'
import EventModel from '@/components/eventcomponent/eventModel'
import { geteventDetail, closeevent, eventlistfilter } from '@/api/device.js'
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import NoData from '@/components/NoData'
import SvgAbbTrend316 from '@/components/trend3_16';
import CallPromptModel from './callPromptModel';
import SupportRecordModel from './supportRecordModel';
import { APICallPrompt } from '../../../../api/device';
// const { RangePicker } = DatePicker;
const { Option } = Select;
// const dateFormat = 'YYYY/MM/DD';
const new_box = {
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	flexFlow: 'row'
}

const checkdetail = {
	color: "#3366FF",
	lineHeight: '30px',
	fontSize: '14px',
	cursor: 'pointer',
	margin: '0 6px 0 0',
	position: 'relative',
	zIndex: '1000'
}
const event_list_box = {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	flexFlow: 'row'
}
const icon_center = {
	margin: '0 10px 0 0',
	display: 'inline'
}
const div_nowrap = {
	display: 'inline'
}
class Eventlist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assetId: this.props._deviceDetail.assetId,
			startime: '',
			endtime: '',
			assetEventid: '',
			PageSize: 5,
			EventType: 0,
			showmodel: {},
			eventtypelist: [],
			loading: false,
			disabled: false,
			columns: [
				{
					title: '类型',
					dataIndex: 'assetEventType',
					key: 0,
					width: 50,
					render: assetEventType => {
						if (assetEventType === 'Fault') {
							return (
								<ABB.Icon style={{ cursor: "text",'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Warning') {
							return (
								<ABB.Icon style={{ cursor: "text",'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Notification') {
							return (
								<ABB.Icon style={{ cursor: "text",'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Custom') {
							return (
								<div className='customevent'></div>
							);
						}
					},
				},
				{
					title: '设备状态',
					dataIndex: 'deviceWorkStatus',
					key: 'eventDateTime',
					width: '13%',
					ellipsis: true,
					render: deviceWorkStatus => {
						if (deviceWorkStatus === 1) {
							return (
								<div className="device_status_box">
									<ABB.Icon style={{ cursor: "text",'color': '#0CA919' }} name="abb/drive" sizeClass='small' />&nbsp;
									<div style={{ 'color': '#0CA919' }}>运行</div>
								</div>
							)
						}
						if (deviceWorkStatus === 2) {
							return (
								<div className="device_status_box">
									<ABB.Icon style={{ cursor: "text",'color': '#0CA919' }} name="abb/drive" sizeClass='small' />&nbsp;

									<div style={{ 'color': '#0CA919' }}>待机</div>
								</div>
							)
						}
						if (deviceWorkStatus === 3) {
							return (
								<div className="device_status_box">
									<ABB.Icon style={{ cursor: "text",'color': '#BABABA' }} name="abb/drives-error" sizeClass='small' />&nbsp;
									<div style={{ 'color': '#BABABA' }}>计划停机</div>
								</div>
							)
						}
						if (deviceWorkStatus === 4) {
							return (

								<div className="device_status_box">
									<ABB.Icon style={{ cursor: "text",'color': '#F03040' }} name="abb/drives-error" sizeClass='small' />&nbsp;
									<div style={{ 'color': '#F03040' }}>非计划停机</div>
								</div>

							)
						}
						return '--'

					}
				},
				{
					title: () => (
						<div className="deviceTime-box">
							<span style={{ marginRight: '8px' }}>设备端时间</span>

							<Tooltip placement="bottom" title={
								<>
									<div>设备时间与上报时间出现不一致？</div>
									<div>1. 设备端日期及时钟未被校准； </div>
									<div>2. 网络传输延迟</div>
								</>

							}>
								<div style={icon_center}>
									<ABB.Icon style={{ 'color': '#1f1f1f' }} name="abb/help-circle-2" sizeClass='small' />
								</div>
							</Tooltip>
						</div>
					),
					dataIndex: 'eventOccurrentTime',
					key: 1,
					width: 100,
					ellipsis: true,
					render: (text, record) => {
						return (
							<>
								{
									record.occurrentTimeIsValidFormat === true ? (

										<div>

											{moment.parseZone(Number(record.eventOccurrentTime)).local().format("YYYY-MM-DD HH:mm:ss")}

										</div>
									) : (
										<div>{record.eventOccurrentTime}</div>
									)
								}
							</>
						)
					}
				},
				{
 
					title: '网关时间(UTC+8)',
					dataIndex: 'eventDateTime',
					key: 2,
					width: 100,
					ellipsis: true,
					render: (text, record) => (
						<div>{moment.parseZone(record.eventDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div>
					)
				},
				{
					title: '事件描述',
					dataIndex: 'content',
					key: 3,
					width: 240,
					ellipsis: true,
				},
				{
					title: '事件代码',
					dataIndex: 'eventCode',
					key: 4,
					width: 80,
					ellipsis: true,
				},
				{
					title: '详情',
					dataIndex: 'eventCode',
					key: 5,
					width: 50,
					render: (text, record) => (
						<div style={{ display: 'flex' }}>
							{/* <div style={icon_center}>
								<Tooltip placement="bottom" title='查看'>
									<div style={div_nowrap} onClick={() => this.eventlogger(record)}>
										<ABB.Icon name="abb/information-circle-2" sizeClass='small' />
									</div>
								</Tooltip>

							</div> */}
							{
								record.isHasEventLogger === true ? (
									<Tooltip placement="bottom" title='详情'>
										<div style={icon_center} onClick={() => this.eventlogger(record)}>
											<Icon style={{ 'color': '#0CA919' }} component={SvgAbbTrend316} />
										</div>

									</Tooltip>

								) : <Tooltip placement="bottom" title='详情'>
									<div style={icon_center} onClick={() => this.eventlogger(record)}>
										<ABB.Icon name="abb/information-circle-2" sizeClass='small' />
									</div>

								</Tooltip>
							}

							{
								this._renderCallStatusUI(record)

							}

						</div>

					)
				}
			],
			showCallPromptConfig: {
				visible: false,
				title: '',
				value: '',
				width: 700,
				centered: true,
				eventData: {},
				timeLength: 0
			},
			showSupportRecordConfig: {
				visible: false,
				title: '',
				value: '',
				width: 700,
				centered: true,
				eventData: {
					calledRecords: []
				},

			}
		};
	}


	_renderCallStatusUI = (record) => {
		//临时关闭专家服务 设备详情
		// return null
   
		if (record.assetEventType !== 'Fault') return null   //非故障类事件 不显示专家服务
		if (record.calledStatus === 0) return null
		if (record.calledStatus === 1) {
			return <Tooltip placement="bottom" title='一键呼叫'>
				<div style={icon_center} onClick={() => this._callPromptRequest(record)}>
					<ABB.Icon name="abb/call" sizeClass='small' />
				</div>

			</Tooltip>
		}
		if (record.calledStatus === 2) {
			return <Tooltip placement="bottom" title='支持记录'>
				<div style={icon_center} onClick={() => this._supportRecord(record)}>
					<ABB.Icon name="abb/list" sizeClass='small' />
				</div>
			</Tooltip>
		}
		if (record.calledStatus === 3) {
			return <Tooltip placement="bottom" title='一键呼叫'>
				<div style={icon_center} onClick={() => this.callEasemob(record)}>
					<ABB.Icon name="abb/call" sizeClass='small' />
				</div>
			</Tooltip>
		}
	}

	//一键呼叫请求
	_callPromptRequest = (record) => {
		const that = this
		APICallPrompt(record.id).then((res) => {
			if (res !== undefined) {
				if((res.data?.data?.pushResultMessage||'').length==0){
				 that._showCallPrompt(record)
			    }else{ 
				 message.warning("操作失败，"+res.data.data.pushResultMessage)
				}
			}
		}).catch(err => {
			if (err === undefined) {
				return
			}
			that.setState({
				loading: false
			})
			if (err.data.code === 400 || err.data.code === 404) {
				message.warning(err.data.errorMessage)
			}
			else {
				message.error('一键呼叫请求异常')
			}
		})
	}

	renderEventName = (eventCode) => {
		if (eventCode === 1) {
			return '故障'
		}
		if (eventCode === 2) {
			return '告警'
		}
		if (eventCode === 3) {
			return '通知'
		}
		if (eventCode === 8) {
			return '自定义'
		}
		return "--"
	}


	//调用环信机器人
	callEasemob = (record) => {
		window.easemobim.bind({
			configId: "198e7ac1-4dfc-4e01-a043-037569957e4e",
			dialogWidth: "500px",
			hide:true
		})
		return

//环信机器人,暂时关闭发送卡片功能
		this.setState({
			loading: true
		})
		easemobim_parameter(record.id).then(res => {

			this.setState({
				loading: false
			})
			console.log('res======================', res, '---------------', record)

			//临时代码 模拟设备状态 设备详情
			if (res.data.code === 200) {
				let resdata = res.data.data


				window.easemobim.bind({
					configId: "198e7ac1-4dfc-4e01-a043-037569957e4e",
					dialogWidth: "500px"
				})

				window.easemobim.sendExt({
					ext: {
						"imageName":`{${resdata.productImageName}}`,
						//custom代表自定义消息，无需修改
						"type": "custom",
						"msgtype": {
							"track": {
								"title": `{[${resdata.orgName}]}{${resdata.productName}}{${this.renderEventName(resdata.eventType)}}`,  // 公司名称 / 型号 / 事件类型
								"price": `故障代码：${resdata.eventCode || "--"}`,    // 故障代码、辅助代码
								"desc": `序列号：${resdata.deviceSn || "--"}；故障描述：${resdata.eventContent || "--"}`,  // 序列号 / 描述
								"img_url": `https://img2.baidu.com/it/u=3202947311,1179654885&fm=253&fmt=auto&app=138&f=JPEG?w=800&h=500`,  // 产品图片地址
							 
							}
						}
					}
				});

			} else {
				message.destroy()
				message.error('事件信息获取失败')
			}
		}).catch((err) => {

			this.setState({
				loading: false
			})
			message.destroy()
			message.error('事件信息获取失败')
		})


		console.log('呼叫机器人+++++++++++++++++++++++++++')

	}
	//一键呼叫请求
	_showCallPrompt = (record) => {
		//一键呼叫网络请求
		var config = {
			...this.state.showCallPromptConfig,
			visible: true,
			eventData: record,
			timeLength: 5
		}

		this.setState({
			showCallPromptConfig: config
		}, () => {
			this.child1.udpateTimeLength()
		})
	}
	//支持记录
	_supportRecord = (record) => {
		var config = {
			...this.state.showSupportRecordConfig,
			visible: true,
			eventData: record
		}
		this.setState({
			showSupportRecordConfig: config
		})
	}

	getEventDetailFn = (data) => {
		let url = `${data.id}?userId=${this.props.userInfo.userId}`;
		eventDetailData(url).then(res => {
			//临时代码 模拟设备状态 设备详情
			if (res.data.code === 200) {
				let resdata = res.data.data
				// resdata.deviceWorkStatus = 1
				this.getTrendConfig(resdata)
			}
		})
	}
	getTrendConfig = (data) => {
		// console.log("this.props.getEventDetail----",data)
		let url = "?eventId=" + data.eventId
		chartConfig(url).then(res => {
			// console.log("chartConfigchartConfig---",res.data.data)
			this.props.setEventDetail({ ...data, trendconfig: res.data.data });
			this.props.userCheckDevice(8);
			this.props.eventDetaildevice('1');
		})
	}

	eventlogger = (data) => {
		this.props.setEventDetail({})
		this.getEventDetailFn(data)
	}

	componentDidMount() {
		this.props.onRef && this.props.onRef(this)
		this.props.setevent({})
		let { getsubscriptioncof, jurisdictionConfig } = this.props;
		// console.log("jurisdictionConfig---------",jurisdictionConfig)
		let feature = {}
		let PageSize = 5;
		let _columns = []
		if (jurisdictionConfig.isShowSignalTrend === false && jurisdictionConfig.isShowEventList === true && jurisdictionConfig.isShowIndicators === false) {
			PageSize = 13
		}
		else {
			PageSize = 5
		}

		if(jurisdictionConfig.isShowWorkStatus === false){
			_columns = [
				{
					title: '类型',
					dataIndex: 'assetEventType',
					key: 0,
					width: 50,
					render: assetEventType => {
						if (assetEventType === 'Fault') {
							return (
								<ABB.Icon style={{ cursor: "text",'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Warning') {
							return (
								<ABB.Icon style={{ cursor: "text",'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Notification') {
							return (
								<ABB.Icon style={{ cursor: "text",'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Custom') {
							return (
								<div className='customevent'></div>
							);
						}
					},
				},
				
				{
					title: () => (
						<div className="deviceTime-box">
							<span style={{ marginRight: '8px' }}>设备端时间</span>

							<Tooltip placement="bottom" title={
								<>
									<div>设备时间与上报时间出现不一致？</div>
									<div>1. 设备端日期及时钟未被校准； </div>
									<div>2. 网络传输延迟</div>
								</>

							}>
								<div style={icon_center}>
									<ABB.Icon style={{ 'color': '#1f1f1f' }} name="abb/help-circle-2" sizeClass='small' />
								</div>
							</Tooltip>
						</div>
					),
					dataIndex: 'eventOccurrentTime',
					key: 1,
					width: 100,
					ellipsis: true,
					render: (text, record) => {
						return (
							<>
								{
									record.occurrentTimeIsValidFormat === true ? (

										<div>

											{moment.parseZone(Number(record.eventOccurrentTime)).local().format("YYYY-MM-DD HH:mm:ss")}

										</div>
									) : (
										<div>{record.eventOccurrentTime}</div>
									)
								}
							</>
						)
					}
				},
				{
					title: '网关时间(UTC+8)',
					dataIndex: 'eventDateTime',
					key: 2,
					width: 100,
					ellipsis: true,
					render: (text, record) => (
						<div>{moment.parseZone(record.eventDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div>
					)
				},
				{
					title: '事件描述',
					dataIndex: 'content',
					key: 3,
					width: 240,
					ellipsis: true,
				},
				{
					title: '事件代码',
					dataIndex: 'eventCode',
					key: 4,
					width: 80,
					ellipsis: true,
				},
				{
					title: '详情',
					dataIndex: 'eventCode',
					key: 5,
					width: 50,
					render: (text, record) => (
						<div style={{ display: 'flex' }}>
							{/* <div style={icon_center}>
								<Tooltip placement="bottom" title='查看'>
									<div style={div_nowrap} onClick={() => this.eventlogger(record)}>
										<ABB.Icon name="abb/information-circle-2" sizeClass='small' />
									</div>
								</Tooltip>

							</div> */}
							{
								record.isHasEventLogger === true ? (
									<Tooltip placement="bottom" title='详情'>
										<div style={icon_center} onClick={() => this.eventlogger(record)}>
											<Icon style={{ 'color': '#0CA919' }} component={SvgAbbTrend316} />
										</div>

									</Tooltip>

								) : <Tooltip placement="bottom" title='详情'>
									<div style={icon_center} onClick={() => this.eventlogger(record)}>
										<ABB.Icon name="abb/information-circle-2" sizeClass='small' />
									</div>

								</Tooltip>
							}

							{
								this._renderCallStatusUI(record)

							}

						</div>

					)
				}
			]
		}
		else{
			_columns = [
				{
					title: '类型',
					dataIndex: 'assetEventType',
					key: 0,
					width: 50,
					render: assetEventType => {
						if (assetEventType === 'Fault') {
							return (
								<ABB.Icon style={{ cursor: "text",'color': '#F03040' }} name="abb/error-circle-1" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Warning') {
							return (
								<ABB.Icon style={{ cursor: "text",'color': '#FFD800' }} name="abb/warning-triangle" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Notification') {
							return (
								<ABB.Icon style={{ cursor: "text",'color': '#3366ff' }} name="abb/alarm-bell" sizeClass='medium' />
							);
						}
						if (assetEventType === 'Custom') {
							return (
								<div className='customevent'></div>
							);
						}
					},
				},
				{
					title: '设备状态',
					dataIndex: 'deviceWorkStatus',
					key: 'eventDateTime',
					width: '13%',
					ellipsis: true,
					render: deviceWorkStatus => {
						if (deviceWorkStatus === 1) {
							return (
								<div className="device_status_box">
									<ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drive" sizeClass='small' />&nbsp;
									<div style={{ 'color': '#1f1f1f' }}>运行</div>
								</div>
							)
						}
						if (deviceWorkStatus === 2) {
							return (
								<div className="device_status_box">
									<ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drive" sizeClass='small' />&nbsp;

									<div style={{ 'color': '#1f1f1f' }}>待机</div>
								</div>
							)
						}
						if (deviceWorkStatus === 3) {
							return (
								<div className="device_status_box">
									<ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drives-error" sizeClass='small' />&nbsp;
									<div style={{ 'color': '#1f1f1f' }}>计划停机</div>
								</div>
							)
						}
						if (deviceWorkStatus === 4) {
							return (

								<div className="device_status_box">
									<ABB.Icon style={{ cursor: "text",'color': '#1f1f1f' }} name="abb/drives-error" sizeClass='small' />&nbsp;
									<div style={{ 'color': '#1f1f1f' }}>非计划停机</div>
								</div>

							)
						}
						return '--'

					}
				},
				{
					title: () => (
						<div className="deviceTime-box">
							<span style={{ marginRight: '8px' }}>设备端时间</span>

							<Tooltip placement="bottom" title={
								<>
									<div>设备时间与上报时间出现不一致？</div>
									<div>1. 设备端日期及时钟未被校准； </div>
									<div>2. 网络传输延迟</div>
								</>

							}>
								<div style={icon_center}>
									<ABB.Icon style={{ 'color': '#1f1f1f' }} name="abb/help-circle-2" sizeClass='small' />
								</div>
							</Tooltip>
						</div>
					),
					dataIndex: 'eventOccurrentTime',
					key: 1,
					width: 100,
					ellipsis: true,
					render: (text, record) => {
						return (
							<>
								{
									record.occurrentTimeIsValidFormat === true ? (

										<div>

											{moment.parseZone(Number(record.eventOccurrentTime)).local().format("YYYY-MM-DD HH:mm:ss")}

										</div>
									) : (
										<div>{record.eventOccurrentTime}</div>
									)
								}
							</>
						)
					}
				},
				{
					title: '网关时间(UTC+8)',
					dataIndex: 'eventDateTime',
					key: 2,
					width: 100,
					ellipsis: true,
					render: (text, record) => (
						<div>{moment.parseZone(record.eventDateTime).local().format("YYYY-MM-DD HH:mm:ss")}</div>
					)
				},
				{
					title: '事件描述',
					dataIndex: 'content',
					key: 3,
					width: 240,
					ellipsis: true,
				},
				{
					title: '事件代码',
					dataIndex: 'eventCode',
					key: 4,
					width: 80,
					ellipsis: true,
				},
				{
					title: '详情',
					dataIndex: 'eventCode',
					key: 5,
					width: 50,
					render: (text, record) => (
						<div style={{ display: 'flex' }}>
							{/* <div style={icon_center}>
								<Tooltip placement="bottom" title='查看'>
									<div style={div_nowrap} onClick={() => this.eventlogger(record)}>
										<ABB.Icon name="abb/information-circle-2" sizeClass='small' />
									</div>
								</Tooltip>

							</div> */}
							{
								record.isHasEventLogger === true ? (
									<Tooltip placement="bottom" title='详情'>
										<div style={icon_center} onClick={() => this.eventlogger(record)}>
											<Icon style={{ 'color': '#0CA919' }} component={SvgAbbTrend316} />
										</div>

									</Tooltip>

								) : <Tooltip placement="bottom" title='详情'>
									<div style={icon_center} onClick={() => this.eventlogger(record)}>
										<ABB.Icon name="abb/information-circle-2" sizeClass='small' />
									</div>

								</Tooltip>
							}

							{
								this._renderCallStatusUI(record)

							}

						</div>

					)
				}
			]
		}
		getsubscriptioncof.map(item => {
			item.config.map(items => {
				if (items.featureCode === 6) {
					let stringResult = items.limitValue.split(',');
					let arr = [
						{
							name: "全部类型",
							id: 0
						}
					];
					stringResult.map(itemarr => {
						if (itemarr === "1") {
							let obj1 = {
								name: "故障",
								id: 1
							}
							arr.push(obj1)
						}
						if (itemarr === "2") {
							let obj2 = {
								name: "告警",
								id: 2
							}
							arr.push(obj2)
						}
						if (itemarr === "3") {
							let obj2 = {
								name: "通知",
								id: 3
							}
							arr.push(obj2)
						}
						if (itemarr === "8") {
							let obj2 = {
								name: "自定义",
								id: 8
							}
							arr.push(obj2)
						}
					})
					feature.eventtype = arr;
				}
			})

		})
		this.setState({
			// feature: feature,
			columns: _columns,
			eventtypelist: feature.eventtype,
			PageSize: PageSize,
			EventType: 1
		}, () => {
			this.Fneventlist(1, 1)
		})
		// this.props.onRef(this)

	}
	componentDidUpdate(nextProps, prevState) {
		// if (prevState.assetId !== this.props._deviceDetail.assetId) {

		// 	this.setState({
		// 		assettype: this.props._deviceDetail.assetId
		// 	}, () => {
		// 		this.Fneventlist(1, 0)
		// 	})

		// }
	}

	checkEventdetail = () => {
		this.props.checkAllgen('0');
		this.props.eventDetaildevice('2');
		this.props.userCheckDevice(9)
	}
	eventIcon = (data) => {
		if (data === 1) {
			return (
				<Icon type="info-circle" />
			)
		}
		else if (data === 2) {
			return (
				<Icon type="bell" theme="filled" />
			)
		}
		else if (data === 1) {
			return (
				<Icon type="info-circle" />
			)
		}
	}
	//获取事件list
	Fneventlist = (pageindex, EventType) => {
		// let { getAssetStatus, _deviceDetail } = this.props;
		// if (getAssetStatus !== "已过期") {
		this.setState({
			loading: true
		})
		const { getassetId, getAssetIsPowertrain } = this.props;
		let that = this;
		let assetId = "?AssetId=" + getassetId
		let isSubAsset = ''
		const datatime = {
			startime: that.state.startime,
			endtime: that.state.endtime
		}
		if (getAssetIsPowertrain === true) {
			isSubAsset = `&isSubAsset=true`;
		}
		else {
			isSubAsset = ``
		}

		let url = `${assetId}${isSubAsset}&PageSize=${this.state.PageSize}&Pageindex=${pageindex}&EventTypes=${EventType}&StartTime=${datatime.startime}&EndTime=${datatime.endtime}`
		console.log("url===============", url)
		eventlistfilter(url).then((res) => {

			console.log("设备详情 最新事件列表请求========================", res)
			if (res !== undefined) {
				let resdata = res.data;
				if (resdata.data !== null && resdata.data.length > 0) {

				}
				resdata.data.forEach((item, index) => {
					//临时代码,模拟设备状态
					// item.deviceWorkStatus = index


					if (item.eventCode === '' || item.eventCode === null) {
						item.eventCode = '--'
					}
				})
				that.props.setevent(resdata.data);
				that.setState({
					loading: false
				})
			}
		}).catch(err => {
			that.setState({
				loading: false
			})
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('事件接口异常')
			}
		})
		// }


		// Fneventlist(datatime, 5, pageindex, EventType, assetId, isSubAsset, function (data) {
		// 	data.data.forEach((item, index) => {
		// 		item.eventDateTime = moment.parseZone(item.eventDateTime).local().format('YYYY-MM-DD HH:mm:ss')
		// 	})
		// 	that.props.setevent(data);
		// 	that.setState({
		// 		loading: false
		// 	})
		// })
	}
	//通过状态筛选
	eventOnChange = (e) => {
		this.setState({
			EventType: e
		})
		this.Fneventlist(1, e)
	}
	//点击时间存储选中时间
	onOk = () => {
		this.Fneventlist(1, this.state.EventType)
	}

	//刷新事件列表,供外部调用
	refreshEventList = () => {
		this.Fneventlist(1, this.state.EventType)
		console.log("refreshEventList=============")
	}
	//选取时间改变
	onChangeTime = (dates, dateStrings) => {
		let that = this;
		if (dates[0] === undefined || dates[1] === undefined) {
			that.setState({
				startime: '',
				endtime: ''
			}, () => {

				this.Fneventlist(1, this.state.EventType)
			})

		}
		else {

			let _startime = moment(dateStrings[0]).format("YYYY-MM-DD") + " 00:00:00"
			let _endtime = moment(dateStrings[1]).format("YYYY-MM-DD") + " 23:59:59"
			let currentUTCTimeString = moment(_endtime).utc().format("YYYY-MM-DD HH:mm:ss")
			let dateUTCTimeString = moment(_startime).utc().format("YYYY-MM-DD HH:mm:ss");
			this.setState({
				startime: dateUTCTimeString,
				endtime: currentUTCTimeString,
			}, () => {
				this.Fneventlist(1, this.state.EventType)
			})
		}
	}
	//关闭事件接口
	closeevent = (val) => {
		if (this.state.assetEventid !== '') {

			let url = {
				id: this.state.assetEventid,
				closeReason: val
			}
			this.setState({
				disabled: true
			})
			closeevent(url).then(res => {
				if (res !== undefined) {
					message.success('操作成功')
					this.setState({
						disabled: false,
						showmodel: {
							visible: false,
							title: '',
							width: 504,
							value: '',
							centered: true,
						},
					})
					this.Fneventlist(1, this.state.EventType)
				}
			}).catch(err => {
				if (err.status === 400) {
					message.warning(err.data)
				}
				else {
					message.error('关闭事件失败')
				}
				this.setState({
					disabled: false
				})
			})
		}

	}
	// 组件传值
	hidemodel = (data) => {
		this.setState({
			showmodel: {
				visible: data,
				title: '',
				width: 504,
				value: '',
				centered: true,
			},

		})
	}
	submitmodel = (val) => {
		if (val !== '') {
			this.closeevent(val)
		}
		else {
			message.warning('处理方案不能为空！')
		}
	}
	showmodel = (showmodel, title, id) => {
		this.geteventDetail(showmodel, title, id)
		// this.setState({
		// 	modeltype: type,
		// })
	}
	geteventDetail = (showmodel, title, id) => {
		let url = `/${id}`;
		geteventDetail(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				// let eventDateTime = moment(resdata.eventDateTime).utc().format("YYYY-MM-DD HH:mm:ss")
				let eventDateTime = moment.parseZone(resdata.eventDateTime).local().format('YYYY-MM-DD HH:mm:ss')
				let eventCloseTime = moment.parseZone(resdata.closeAt).local().format('YYYY-MM-DD HH:mm:ss')
				this.setState({
					// eventdetail: `${eventDateTime} ${resdata.content}`,
					assetEventid: `${resdata.id}`,
					showmodel: {
						visible: showmodel,
						title: title,
						value: `${eventDateTime} ${resdata.content}`,
						width: 504,
						closeReason: resdata.closeReason,
						closeUser: `${resdata.closeUserOrganization}, ${resdata.closeUser}, ${eventCloseTime}`,
						centered: true,
						assetEventStatus: `${resdata.assetEventStatus}`
					}
				})
			}
		})
	}
	disabledDate = current => current && current > moment().endOf('day');
	render() {
		let that = this;
		let mytablecolumns = that.state.columns;
		const { getevent, getAssetStatus } = that.props;
		console.log('getevent===========', getevent)
		// let objnull = (JSON.stringify(getevent) === "{}")
		return (
			<div className='deviceDetail_status'>
				<EventModel
					showmodel={that.state.showmodel}
					hidemodel={that.hidemodel}
					disabled={that.state.disabled}
					submitmodel={that.submitmodel} />
				<div style={new_box}>
					<div style={event_list_box}>
						<div className='asset_title'
							style={{ fontSize: 16, margin: '8px 8px 0 0' }}
						>最新事件列表</div>

						<Select defaultValue="故障"
							suffixIcon={
								<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
							}
							onChange={that.eventOnChange}
							style={{ minWidth: 150 }}>
							{
								this.state.eventtypelist.map((item, index) => {
									return (
										<Option value={item.id} key={index}>{item.name}</Option>
									)
								})
							}
						</Select>
					</div>
					{/* {
						getAssetStatus !== "已过期" ? ( */}
					<div style={checkdetail} onClick={() => that.checkEventdetail()}>
						全部
						{/* <Icon type="right" /> */}
					</div>
					{/* ) : ''
					} */}

				</div>
				<Spin spinning={this.state.loading} style={{ height: '260px' }}>
					<div style={{ position: 'relative', minHeight: 260 }}>

						{/* {
							(objnull === false) ? ( */}
						<>
							{
								(getevent.length > 0) ? (
									<Table
										size='small'
										scroll={{ x: 500 }}
										className='org_right'
										ellipsis={true}
										columns={mytablecolumns}
										dataSource={getevent}
										pagination={false}
										rowKey={(record, item) => record.id}
									/>
								) : <NoData />

							}
						</>


						{/* ) : <NoData />
						} */}
					</div>
				</Spin>

				<CallPromptModel
					onRef={(ref) => { this.child1 = ref }}
					showmodelboundsub={this.state.showCallPromptConfig}
					hidemodel={() => {
						that.setState({ showCallPromptConfig: { ...that.state.showCallPromptConfig, visible: false } })
						this.Fneventlist(1, that.state.EventType)
					}}
				/>

				<SupportRecordModel
					showmodelboundsub={that.state.showSupportRecordConfig}
					hidemodel={() => {
						that.setState({
							showSupportRecordConfig: { ...that.state.showSupportRecordConfig, visible: false }
						})
					}} />
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	deviceDetail: data => {
		dispatch(deviceDetail(data));
	},
	checkAllgen: data => {
		dispatch(checkAllgen(data))
	},
	eventDetaildevice: data => {
		dispatch(eventDetaildevice(data))
	},
	setevent: data => {
		dispatch(setevent(data))
	},
	userCheckDevice: data => {
		dispatch(userCheckDevice(data))
	},
	setEventDetail: data => {
		dispatch(setEventDetail(data))
	},

});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Eventlist));
// export default Eventlist;
