import React, { Component } from 'react';
// import $axios from '../../axios/$axios';
// import {equipmentdetail} from '@/api/device.js'

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { deviceDetail,checkAllgen,eventDetaildevice } from '@/redux/actions/device';

const generalnformation = {
    border: '1px solid #d8d8d8',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    // alignItems: 'center',
    padding: '10px',
    marginBottom: '20px'
}
const EUorg_title = {
	lineHeight: '40px',
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexFlow: 'row'
    // borderBottom: '1px solid #d8d8d8'
}
const checkdetail = {
    color: "rgb(24, 144, 255)",
    lineHeight: '30px',
}
const  gen_li = {
    width: '33.3333%',
    lineHeight: '30px'
}
class Generalnformation extends Component {
	state = {
        deviceDetails: {},
	};

	checkall=()=>{
        this.props.checkAllgen('1')
        this.props.eventDetaildevice('0')
    }
	render() {
        const {_deviceDetail} = this.props;
        let objnull = (JSON.stringify(_deviceDetail) === "{}")
		return (
			<div>
				<div style={EUorg_title}>
                    综合说明
                    <div style={checkdetail} onClick={this.checkall}>全部</div>
                </div>
                {
                   objnull===false ? (
                        <div style={generalnformation}>
                            <div style={gen_li}>型号:{_deviceDetail.details.productName}</div>
                            <div style={gen_li}>序列号:{_deviceDetail.details.serialnumber}</div>
                            <div style={gen_li}>设备描述: {_deviceDetail.details.description}</div>
                        </div>
                    ): "null"
                }                
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	deviceDetail: data => {
    
		dispatch(deviceDetail(data));
    },
    checkAllgen: data => {
        dispatch(checkAllgen(data))
    },
    eventDetaildevice: data => {
        dispatch(eventDetaildevice(data))
    }
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Generalnformation));
// export default Generalnformation;

