import React, { Component } from 'react';
import { getassetgroup} from '@/api/user.js';

import { Modal,Button,Input,Checkbox,Row, Col,message } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
const { Search } = Input;
class UnAuthorization extends Component {
	constructor(props) {
		super(props);
		this.state = {
            checkoutList: [],
            inputval: [],
            indeterminate: true,
            checkAll: false,
            ownedAssetGroups: []

		};

		
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    componentDidMount(){


        this.getassetgroupOwnedList();
       
    }
    //获取设备组列表接口
	getassetgroupOwnedList = () => {

		let url = `/${this.props.userGroupId}`;
		getassetgroup(url).then(res => {
			if (res !== undefined) {

				this.setState({
					assetGroups: res.data,
                    
				})
                res.data.owned.forEach((item,index) => {
                    this.setState({
                        inputval: [...this.state.inputval, item.id],
                        ownedAssetGroups: [...this.state.ownedAssetGroups, item],
                    })
                })
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.error(err.data)
			}
			else {
				message.error('获取已授权设备组列表失败')
			}

		})
	}

    groupListDiff = (newList, oldList) => {
		const diffAssetGroupList = oldList.filter(item => !newList.includes(item.id));

        return diffAssetGroupList
        // return diffAssetGroupList.map((item) =>{
        //     return item.id
        // });
	}
    hidemodel=(data)=>{
        this.props.hidemodel(data)
        this.setState({
            inputval: [],
            indeterminate: true,
            checkoutList: [],
            checkAll: false,
        })
    }
    submitmodel=(data)=>{
        // if(data.length>0){
            
        // }
        // else{
        //     message.warning("还未选择")
        // }
        const assetGroupListDiff = this.groupListDiff(data, this.state.ownedAssetGroups);
        this.props.submitmodel(assetGroupListDiff)
        setTimeout(()=>{
            this.setState({
                inputval: [],
                indeterminate: true,
                checkoutList: [],
                checkAll: false,
            })
        },1000)
        
    }
    
    onChange=(e)=>{
        this.setState({
            inputval: e,
        })
    }
	render() {
        const that = this;
        const {showmodelUnAuthorization,disabled} = this.props;

        const modelProps = {
            title: showmodelUnAuthorization.title,
            centered: showmodelUnAuthorization.centered,
            width:showmodelUnAuthorization.width,
            maskClosable: false,
            visible:showmodelUnAuthorization.visible,
            onOk:() => {that.hidemodel(false)},
            onCancel:() => {that.hidemodel(false)},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(that.state.inputval)}
                    type="primary-blue"
                    disabled = {disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,          
            ]
        }
		return (
			<Modal {...modelProps}
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                <div className='box_flex'>已授权设备组</div>
                <Checkbox.Group
                value={this.state.inputval}
                
                style={{ width: '100%',minHeight:100,maxHeight:200,overflow:'auto' }} onChange={that.onChange}>
                    <Row>
                    {
                        this.state.ownedAssetGroups.length>0 ? (
                            this.state.ownedAssetGroups.map((item,index)=>{
                                return (
                                    <Col span={24} key={index}>
                                        <Checkbox value={item.id} >{item.groupName}</Checkbox>
                                    </Col>
                                )
                            })
                        ): <div className="nodatagroup">暂无已授权设备组</div>
                        
                    }
                    </Row>
                </Checkbox.Group>
                
            </Modal>
		);
	}
}

export default UnAuthorization;
