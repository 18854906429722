//编辑数字化连接调试信息
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
    Form,
    Input,
    Modal,
    message,
    Row,
    Spin,
} from 'antd';

import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import '../style.scss'

import { setContactConfig } from '../../../store/actionCreators';
import { APISetOrgContact } from '../../../../../api/device'
import moment from 'moment';

class ContactModel extends React.Component {

    state = {
        disabled: false
    };

    hidemodel = (data) => {
        this.props.form.resetFields()
        var config = { ...this.props.getContactConfig }
        config.visible = false
        //专家服务方式弹框
        this.props.updateContactConfig(config)
    }

    submitmodel = () => {

        // let that = this;
        const config = { ...this.props.getContactConfig }

        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                let deviceContacts = config.deviceContacts
                let commitContacts = []

                for (let index = 0; index < deviceContacts.length; index++) {
                    const element = deviceContacts[index];
                    if (element.phone === '' || element.name === '') {
                        let contact = {
                            "name": "",
                            "phone": "",
                            "priority": element.priority
                        }
                        commitContacts.push(contact)
                    } else {
                        let contact = {
                            "name": element.name,
                            "phone": element.phone,
                            "priority": element.priority
                        }
                        commitContacts.push(contact)
                    }
                }

                this._commitServiceType(commitContacts)
            }
        });
    }

    _commitServiceType = (data) => {
        let { userInfo } = this.props;

        this.setState({
            disabled: true
        })
        APISetOrgContact(userInfo.orgId, data).then(res => {

            this.setState({
                disabled: false
            })

            let resdata = res.data;
            if (resdata !== undefined) {

                this.hidemodel()
                this.props.submitmodel()
            }
        }).catch(err => {
            this.setState({
                disabled: false
            })
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
            }
            else {
                message.error('接口异常')
            }
        })
    }


    _renderSectionHeaderByTitle = (isMust, title) => {
        return <Row >
            <div className='contact_section_item'>
                {isMust === true ? (<div className='section_icon'></div>) : null}
                <div >{title}</div>
            </div>
        </Row>
    }


    onChangeInput = (e, item, type) => {
        //type 1:姓名  2:电话
        console.log("e=========", e + "item=======", item)
        var config = { ...this.props.getContactConfig }
        var contacts = config.deviceContacts
        var contact = contacts[item.priority]

        if (type === 1) {
            contact.name = e.target.value
        }
        if (type === 2) {
            contact.phone = e.target.value
        }

        this.props.updateContactConfig(config)
    }


    render() {
        let that = this;

        const { showmodelboundsub } = that.props;
        const { getFieldDecorator } = that.props.form;

        const modelProps = {
            title: "编辑联系人",
            centered: true,
            width: showmodelboundsub.width,
            maskClosable: false,

            visible: showmodelboundsub.visible,

            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    disabled={this.state.disabled}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelboundsub)}
                    type="primary-blue"
                    disabled={this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }


        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <div>当前组织管理员为组织设定的联系人。</div>
                    {
                        showmodelboundsub.contactsUpdateUser !== '' && showmodelboundsub.contactsUpdateUser !== undefined && showmodelboundsub.contactsUpdateTime !== 0 ?
                            <div>当前联系人修改记录：管理员{showmodelboundsub.contactsUpdateUser}于{moment(showmodelboundsub.contactsUpdateTime).format("YYYY-MM-DD HH:mm:ss")}修改</div> : null
                    }
                    <div className='contact_content'>
                        <Form  >
                            {
                                showmodelboundsub.deviceContacts.map((item, index) => {

                                    return (<>
                                        <div className='input_content'>
                                            {/* <div className='item_title'>{item.title}</div> */}
                                            {
                                                this._renderSectionHeaderByTitle(item.isMust, item.title)
                                            }
                                            {
                                                item.isMust ? <div className='input_box'>
                                                    <div className='input_left'>
                                                        <Form.Item >
                                                            {getFieldDecorator(`name${index}`, {
                                                                initialValue: item.name,
                                                                rules: [

                                                                    {
                                                                        required: true,
                                                                        message: '联系人不能为空',
                                                                    },

                                                                    {
                                                                        whitespace: true,
                                                                        message: '禁止输入空格',
                                                                    }
                                                                ],
                                                            })(
                                                                <Input className="model_input" value={item.name} disabled={false} placeholder='请输入' onChange={(e) => {
                                                                    this.onChangeInput(e, item, 1)
                                                                }} />
                                                            )}
                                                        </Form.Item>

                                                    </div>
                                                    <div className='input_right'>
                                                        <Form.Item >
                                                            {getFieldDecorator(`phone${index}`, {
                                                                initialValue: item.phone,
                                                                rules: [

                                                                    {
                                                                        required: true,
                                                                        message: '联系方式不能为空',
                                                                    },

                                                                    {
                                                                        pattern: new RegExp(/^\s*1\s*[3456789]\s*(\d\s*){9}\s*$/, "g"),
                                                                        message: '手机号格式错误',
                                                                    },
                                                                    {
                                                                        whitespace: true,
                                                                        message: '禁止输入空格',
                                                                    }
                                                                ],
                                                            })(
                                                                <Input className="model_input" value={item.phone} disabled={false} placeholder='请输入电话号码' onChange={(e) => {
                                                                    this.onChangeInput(e, item, 2)
                                                                }} />
                                                            )}
                                                        </Form.Item>


                                                    </div>
                                                </div> :

                                                    <div className='input_box'>
                                                        <div className='input_left'>

                                                            <Form.Item >


                                                                {getFieldDecorator(`name${index}`, {
                                                                    initialValue: item.name,
                                                                    rules: [
                                                                        {
                                                                            whitespace: true,
                                                                            message: '禁止输入空格',
                                                                        }
                                                                    ],
                                                                })(
                                                                    <Input className="model_input" value={item.name} disabled={false} placeholder='请输入' onChange={(e) => {
                                                                        this.onChangeInput(e, item, 1)
                                                                    }} />
                                                                )}
                                                            </Form.Item>

                                                        </div>
                                                        <div className='input_right'>
                                                            <Form.Item >
                                                                {getFieldDecorator(`phone${index}`, {
                                                                    initialValue: item.phone,
                                                                    rules: [

                                                                        {
                                                                            pattern: new RegExp(/^\s*1\s*[3456789]\s*(\d\s*){9}\s*$/, "g"),
                                                                            message: '手机号格式错误',
                                                                        },
                                                                        {
                                                                            whitespace: true,
                                                                            message: '禁止输入空格',
                                                                        }
                                                                    ],
                                                                })(
                                                                    <Input className="model_input" value={item.phone} disabled={false} placeholder='请输入电话号码' onChange={(e) => {
                                                                        this.onChangeInput(e, item, 2)
                                                                    }} />
                                                                )}
                                                            </Form.Item>


                                                        </div>
                                                    </div>
                                            }



                                        </div>

                                    </>)
                                })
                            }
                        </Form>



                        {/* {
                                showmodelboundsub.deviceContacts.map((item, index) => {

                                    return (<>
                                        <div className='input_content'>
                                            {
                                                this._renderSectionHeaderByTitle(item.isMust,item.title)
                                            }
                                            
                                            <div className='input_box'>
                                                <div className='input_left'>
                                                    <Input className="model_input" defaultValue={item.name} disabled={false} placeholder='请输入' />
                                                </div>

                                                <div className='input_right'>
                                                    <Input className="model_input" defaultValue={item.phone}  disabled={false} placeholder='请输入' />
                                                </div>
                                            </div>
                                        </div>

                                    </>)

                                })
                            } */}

                    </div>
                </Modal>
            </>
        );
    }
}



const ContactModelForm = Form.create({ name: 'register' })(ContactModel);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    //专家服务方式弹框
    updateContactConfig: data => {
        dispatch(setContactConfig(data))
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ContactModelForm));

