import React, { Component } from 'react';
import { message, Input, Button, Table, Switch } from 'antd';
import { getuserInfo, changeuserInfo } from '@/api/user'
import { pushlist, closemessage, openmessage } from '@/api/event'
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo'
import { withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { getAuthProvider } from "../../../../lib/AuthProvider";
import { passResetParameter } from "@/lib/PassResetConfig";
import { IDPBindUnBindParameter } from "@/lib/IDPBindUnBindConfig";
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
const authProvider = getAuthProvider();
class PersonalCenter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userName: '',
            userPhone: '',
            disabled: false,
            isZn_CH: false,
            columns: [
                {
                    title: '消息类别',
                    dataIndex: 'notificationTypeNamevalue',
                    key: 0,

                },
                {
                    title: 'Portal',
                    dataIndex: 'channels',
                    key: 1,
                    render: channels => {

                        return (
                            <ABB.ToggleSwitch
                                label=""
                                showIcons={false}
                                disabled={true}
                                monochrome={true}
                                value={true}
                            />
                        );
                    }

                },
                {
                    title: 'APP',
                    dataIndex: 'channels',
                    key: 2,
                    render: (text, record) => {
                        if (record.notificationTypeName === 'AssetWarningEvent' || record.notificationTypeName === 'Subscription') {
                            return (
                                <ABB.ToggleSwitch
                                    label=""
                                    showIcons={false}
                                    disabled={false}
                                    monochrome={true}
                                    value={record.Appstatus}
                                    onChange={(newState) =>
                                        this.changemessage(record)
                                    }
                                />
                            );
                        }
                        else {
                            return (
                                <ABB.ToggleSwitch
                                    label=""
                                    showIcons={false}
                                    disabled={true}
                                    monochrome={true}
                                    value={true}
                                />

                            );
                        }
                    }

                },
                {
                    title: 'Email',
                    dataIndex: 'channels',
                    key: 3,
                    render: (record) => {
                        return (
                            <ABB.ToggleSwitch
                                label=""
                                showIcons={false}
                                disabled={true}
                                monochrome={false}
                                value={false}
                            />
                        );
                    }

                },
                {
                    title: 'SMS',
                    dataIndex: 'channels',
                    key: 4,
                    render: (record) => {
                        return (
                            <ABB.ToggleSwitch
                                label=""
                                showIcons={false}
                                disabled={true}
                                monochrome={true}
                                value={false}
                            />
                        );
                    }

                }
            ],
            jpush: [
                {
                    notificationTypeName: '',
                    channels: []
                }
            ]
        }
    };
    componentDidMount() {
        let that = this
        that.pushlist();
        if (that.props.userInfo.organizationType != "EU") {
            that.setState({
                columns: [
                    {
                        title: '消息类别',
                        dataIndex: 'notificationTypeNamevalue',
                        key: 0,

                    },
                    {
                        title: 'Portal',
                        dataIndex: 'channels',
                        key: 1,
                        render: channels => {

                            return (
                                <ABB.ToggleSwitch
                                    label=""
                                    showIcons={false}
                                    disabled={true}
                                    monochrome={true}
                                    value={false}
                                />
                            );
                        }

                    },
                    {
                        title: 'APP',
                        dataIndex: 'channels',
                        key: 2,
                        render: (record) => {
                            return (
                                <ABB.ToggleSwitch
                                    label=""
                                    showIcons={false}
                                    disabled={true}
                                    monochrome={false}
                                    value={false}
                                />
                            );
                        }

                    },
                    {
                        title: 'Email',
                        dataIndex: 'channels',
                        key: 3,
                        render: (record) => {
                            return (
                                <ABB.ToggleSwitch
                                    label=""
                                    showIcons={false}
                                    disabled={true}
                                    monochrome={false}
                                    value={false}
                                />
                            );
                        }

                    },
                    {
                        title: 'SMS',
                        dataIndex: 'channels',
                        key: 4,
                        render: (record) => {
                            return (
                                <ABB.ToggleSwitch
                                    label=""
                                    showIcons={false}
                                    disabled={true}
                                    monochrome={true}
                                    value={false}
                                />
                            );
                        }

                    }
                ]
            });
        }
        // setTimeout(()=>{
            that.fetch();
        // },1000)
    }
    //获取个人信息
    fetch = () => {
        if(this.props.userInfo===""){
            setTimeout(()=>{
                this.fetch()
            },400)
        }
        else{
            let url = `/${this.props.userInfo.userId}`;
            getuserInfo(url).then(res => {
                if (res !== undefined) {
                    this.setState({
                        userName: res.data.name,
                        userPhone: res.data.phone
                    })
                }
    
            }).catch(err => {
                message.error('获取失败！')
            })
        }
        
    }
    //修改用户名
    changeuserInfo = () => {
        let url = {
            "id": this.props.userInfo.userId,
            "name": this.state.userName,
        };
        this.setState({
            disabled: true
        })
        changeuserInfo(url).then(res => {
            message.success("修改个人信息成功")
            this.setState({
                disabled: false
            })
            window.location.reload()
        }).catch(err => {
            message.error('获取失败！')
            this.setState({
                disabled: false
            })
        })
    }
    //保存
    maintain = () => {
        this.changeuserInfo()
    }
    onChange = (e) => {
        this.setState({
            userName: e.target.value
        })
        if (e.type === 'compositionstart') {
            this.setState({
                isZn_CH: true
            })
            return;
        }
        if (e.type === "compositionend") {
            this.setState({
                isZn_CH: false
            })
            //获取输入框中的文字,截取前十个,重新赋值给输入框
            if (e.target.value.length > 10) {
                message.warning("输入内容不能超过10")
            }
            else {
                this.setState({
                    userName: e.target.value,
                })
            }
            return;
        }
        if (!this.state.isZn_CH) {
            if (e.target.value.length > 10) {
                message.warning("输入内容不能超过10")
                this.setState({
                    userName: e.target.value,
                })
                return false
            }
            this.setState({
                userName: e.target.value,
            })
        }
    }
    changePass = () => {
        // click change password, the page will jump out then jumb back, ant the parameters will be set back automatically
        authProvider.clearCache();
        // authProvider.logout();
        // change the authority url to change password
        authProvider.setAuthenticationParameters(passResetParameter);
        // invoke a login method, it will actully jump to password reset page
        authProvider.login();
    }

    IDPManangement = () => {
        // click change password, the page will jump out then jumb back, ant the parameters will be set back automatically
        authProvider.clearCache();
        // authProvider.logout();
        // change the authority url to change password
        authProvider.setAuthenticationParameters(IDPBindUnBindParameter);
        // invoke a login method, it will actully jump to password reset page
        authProvider.login();
    }

    logOut = () => {
        authProvider.clearCache();
        authProvider.logout();
    }
    //获取推送管理list
    pushlist = () => {
        pushlist().then(res => {
            if (res !== undefined) {
                let resdata = res.data;
                resdata.map((item) => {
                    item.channels.map((channelsitem, index) => {
                        if (channelsitem.name === 'Push') {
                            if (this.props.userInfo.organizationType === "EU") {
                                item.Appstatus = channelsitem.enable;
                                item.channelId = channelsitem.id;
                            }
                        }

                    })
                    if (item.notificationTypeName === 'Subscription') {
                        item.notificationTypeNamevalue = "订阅事件"
                    }
                    if (item.notificationTypeName === 'AssetFaultEvent') {
                        item.notificationTypeNamevalue = "设备故障事件"
                    }
                    if (item.notificationTypeName === 'AssetWarningEvent') {
                        item.notificationTypeNamevalue = "设备告警事件"
                    }
                    if (item.notificationTypeName === 'User') {
                        item.notificationTypeNamevalue = "用户事件"
                    }
                })
                this.setState({
                    jpush: resdata,

                })
            }
        })
    }
    //推送开启关闭
    changemessage = (data) => {
        let url = `/${data.notificationTypeId}?channelId=${data.channelId}`
        if (data.Appstatus === false) {
            openmessage(url).then(res => {
                if (res !== undefined) {
                    message.success("推送状态开启成功！")
                    this.pushlist()
                }
            }).catch(err => {
                if (err.status === 400 || err.status === 404) {
                    message.warning(err.data)
                }
                else {
                    message.error('接口错误')
                }
            })
        }
        else {
            closemessage(url).then(res => {
                if (res !== undefined) {
                    message.success("推送状态关闭成功！")
                    this.pushlist()
                }
            }).catch(err => {
                if (err.status === 400 || err.status === 404) {
                    message.warning(err.data)
                }
                else {
                    message.error('接口错误')
                }
            })
        }

    }

    render() {
        let that = this;

        return (
            <div className="shadow-box" style={{ margin: 8 }}>
                <div className="EUorg_title" style={{ marginTop: 8 }}>
                    <span>
                        个人中心
                    </span>
                </div>
                <div className='box_flex' style={{ 'justifyContent': 'left' }}>
                    姓&nbsp;&nbsp;&nbsp;&nbsp;名：
                    <Input className="model_input"
                        style={{ width: 200 }}
                        disabled={false}
                        onCompositionStart={that.onChange}
                        onCompositionEnd={that.onChange}
                        value={that.state.userName} onChange={that.onChange} />
                </div>
                <div className='box_flex' style={{ 'justifyContent': 'left' }}>
                    手机号：
                    <Input className="model_input" style={{ width: 200 }} disabled={true} value={that.state.userPhone} />
                </div>

                <div className='box_flex' style={{ 'justifyContent': 'left' }}>
                    <ABB.Button
                        onClick={() => that.maintain()}
                        disabled={that.state.disabled}
                        type="primary-blue"
                        sizeClass='small' theme="filled"
                        text="保存"
                    />
                </div>
                <div className='box_flex' style={{ 'justifyContent': 'left' }}>

                    <ABB.Button
                        onClick={() => that.changePass()}
                        type="ghost"
                        sizeClass='small' theme="filled"
                        text="修改密码"
                    />

                </div>
                <div className='box_flex' style={{ 'justifyContent': 'left' }}>
                    <ABB.Button
                        onClick={() => that.IDPManangement()}
                        type="ghost"
                        sizeClass='small' theme="filled"
                        text="管理关联账号"
                    />
                </div>
                {/* <div className='box_flex' style={{'justifyContent':'right'}}>
                <ABB.Button
                    onClick={()=>that.logOut()}
                    type="primary-red"
                    
                    sizeClass='small' theme="filled"
                    text="退出登录"
                />
                </div> */}
                {/* <div className="EUorg_title">
                    <span>
                        推送管理
                    </span>
                </div>
                <Table
                    size='small'
                    columns={this.state.columns}
                    dataSource={this.state.jpush}
                    pagination={false}
                    rowKey={(record, item) => record.id}
                /> */}
            </div>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({

    setUserInfo: data => {
        dispatch(setUserInfo(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PersonalCenter));
