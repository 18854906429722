import React, { Component } from 'react';
import { Input, Table, Tooltip, Checkbox, message, Form, Spin, Timeline } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { APIUnconfiguredDeviceList, APICMDDeviceCheck, APICheckDriveSNNotExist } from '@/api/tools.js';
import { APIUpdateDriveSN } from '@/api/device'
import { registerCoordinateSystem } from 'echarts';
import StatusModal from './components/statusModal';
import { fcumsum } from 'd3-array';

const div_nowrap = {
	display: 'inline',
	margin: '0 30px 0 0'
};

const { Search } = Input;

class ConfigDevice extends Component {
	state = {
		pagination: {},
		loading: false,
		modal2Visible: false,
		selectedRowKeys: [],
		pageindex: 1,
		pageSize: 10,
		location: '###',
		preCompleteConfigStatus: {},
		inputDriveSN: {},
		unconfiguredDeviceList: [],
		unconfiguredDeviceList_copy: [],
		editIndex: null,
		showCheckModal: false,
		checkDescription: null,
		activeDriveSN: null,
		reocrdData: {},
		snName: '',
		columns: [
			{
				title: '添加时间',
				dataIndex: 'addedDate',
				key: '0',
				width: '8%'
			},
			{
				title: '数据源',
				dataIndex: 'deviceSourceValue',
				key: '1',
				width: '5%'
			},
			{
				title: '网关类型',
				dataIndex: 'gatewayType',
				key: '2',
				width: '5%'
			},
			{
				title: '网关序列号',
				dataIndex: 'gatewaySN',
				key: '3',
				width: '10%'
			},

			{
				title: 'CMD管理员页面设备标识',
				dataIndex: 'deviceIdentifier',
				key: '4',
				width: '15%'
			},
			{
				title: '设备名称',
				dataIndex: 'deviceName',
				key: '5',
				width: '15%'
			},

			{
				title: '设备序列号',
				dataIndex: 'userInputDriveSN',
				key: '6',
				width: '10%',
				render: (text, record, index) => {
					return (
						<>
							<div style={div_nowrap}>
								{/* <Tooltip placement="bottom" title={Tooltip_devicedetail}>
									<div style={div_nowrap} onClick={() => this.checkDevice(record)}>
										<ABB.Icon name="abb/information-circle-2" sizeClass="small" />
									</div>
								</Tooltip> */}
								{/* value={this.state.unconfiguredDeviceList.drives[index].userInputDriveSN} */}
								<Input placeholder="输入设备序列号" className="model_input" style={{ width: "100%" }} value={this.state.inputDriveSN[record.deviceId]} onChange={e => this.onUserInputDriveSN(e, record.deviceId)} />
							</div>
						</>
					);
				}
			},

			{
				title: '检查设备配置 ',
				dataIndex: 'operate',
				key: '7',
				width: '10%',
				render: (text, record, index) => {
					return (
						<>
							<div style={div_nowrap}>
								{/* <Checkbox onChange={e => this.cmdConfigStatusOnChange(e, record.deviceId)} checked={this.state.preCompleteConfigStatus[record.deviceId]}>
									配置完成
								</Checkbox> */}
								{/* {this.state.preCompleteConfigStatus[record.deviceId] ? ( */}
									<ABB.Button
										onClick={() => {
											this.checkDeviceSN(record, record.deviceId);
										}}
										type="primary-blue"
										sizeClass="small"
										text="检查"
										disabled={this.state.inputDriveSN[record.deviceId]==='' ? true : false}
									/>
								{/*) : (
									 <ABB.Button onClick={() => { }} type="primary-blue" sizeClass="small" text="检查" disabled={true} /> */}
								{/* )} */}
							</div>
						</>
					);
				}
			}
		]
	};
	cmdConfigStatusOnChange = (e, index) => {
		let tempState = Object.assign({}, this.state.preCompleteConfigStatus, { [index]: e.target.checked });
		this.setState({
			preCompleteConfigStatus: tempState
		});
	};
	UNSAFE_componentWillMount() {
		this.fetch(1, '');
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}

	initdata = () => {
		this.fetch(1, '');
	};
	// 搜索数据
	onserch = value => {
		this.setState({
			snName: value
		},()=>{
			let _unconfiguredDeviceList_copy = this.state.unconfiguredDeviceList_copy.filter(this.filterType)
			this.setState({
				unconfiguredDeviceList: _unconfiguredDeviceList_copy
			})
		})
		
	};
	
	filterType = (person) => {
		let { snName } = this.state; 
		if (snName === '') {
			return true
		} else {
			if (person.gatewaySN===null || person.gatewaySN.toLowerCase().indexOf(snName.toLowerCase().trim()) === -1) {
				return false
			}
		}
		return true
	};
	checkDriveSNInCMD = (deviceSource,sn) => {
		let url = `${deviceSource}/${sn}`
		APICMDDeviceCheck(url).then(res => {
			// console.lo("res----",res)
			if (res.data.status === 0) {
				this.setState({
					checkDescription: (
						<Timeline.Item color="green" dot={<ABB.Icon name="abb/check-mark-circle-2" sizeClass="small" />}>
							<p>{res.data.results}</p>
							{/* <p>设备名：{res.data.results.driveName}</p>
							<p>设备类型：{res.data.results.driveType}</p>
							<p>设备型号：{res.data.results.driveProductCode ? res.data.results.driveProductCode : '-'}</p>
							<p>固件版本：{res.data.results.driveFirmwareVersion}</p> */}
						</Timeline.Item>
					),
					checkStatus: true
				});
				// 在CMD已经配置
			} else {
				this.setState({
					checkDescription: (
						<Timeline.Item color="red" dot={<ABB.Icon name="abb/error-circle-2" sizeClass="small" />}>
							{res.data.results}
						</Timeline.Item>
					),
					checkStatus: false
				});
				//在CMD已经配置，但是信息不完整
			}
		})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					this.setState({
						checkDescription: (
							<Timeline.Item color="red" dot={<ABB.Icon name="abb/error-circle-2" sizeClass="small" />}>
								【CMD】在CMD中无法找到改设备
							</Timeline.Item>
						),
						checkStatus: false
					});
					// CMD 不存在该设备
					// message.warning(err.data);
				} else {
					//内部异常
					// message.error('订阅列表接口异常');
					this.setState({
						checkDescription: (
							<Timeline.Item color="red" dot={<ABB.Icon name="abb/error-circle-2" sizeClass="small" />}>
								内部异常，请关闭窗口，重新检查
							</Timeline.Item>
						),
						checkStatus: false
					});
				}
			});
	};
	// 提交到Motion365后台
	updateDriveSN = (old_sn, new_sn, func) => {
		let {reocrdData} = this.state;
		const data = {
			diviceId: reocrdData.deviceId,
			deviceSource: reocrdData.deviceSource,
			deviceName: reocrdData.deviceName,
			newSN: new_sn,
			oldSN: old_sn
		};
		let callback;
		if (typeof func === 'function') {
			callback = func;
		}
		APIUpdateDriveSN(data).then(res => {
			message.success('配置设备序列号成功');
			if (callback) {
				callback();
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data.error.message);
			} else {
				message.error('订阅列表接口异常');
			}
		});
	};
	checkSNNotExist = (sn,source) => {
		const data = `${sn}`;
		APICheckDriveSNNotExist(data)
			.then(res => {
				
				if(res.data.status ===0){
					this.setState({
						checkSNDescription: (
							<Timeline.Item color="green" dot={<ABB.Icon name="abb/check-mark-circle-2" sizeClass="small" />}>
								【Motion365】{res.data.results}
							</Timeline.Item>
						),
						checkColumbusStatus: true
					});
				}
				else{
					this.setState({
						checkSNDescription: (
							<Timeline.Item color="red" dot={<ABB.Icon name="abb/error-circle-2" sizeClass="small" />}>
								【Motion365】{res.data.results}
							</Timeline.Item>
						),
						checkColumbusStatus: false
					});
				}
			})
			.catch(err => {
				if (err.status === 400 || err.status === 404) {
					this.setState({
						checkSNDescription: (
							<Timeline.Item color="red" dot={<ABB.Icon name="abb/error-circle-2" sizeClass="small" />}>
								【Motion365】{err.data.error.message}
							</Timeline.Item>
						),
						checkColumbusStatus: false
					});
				} else {
					//内部异常
					this.setState({
						checkSNDescription: (
							<Timeline.Item color="red" dot={<ABB.Icon name="abb/error-circle-2" sizeClass="small" />}>
								内部异常，请关闭窗口，重新检查
							</Timeline.Item>
						),
						checkColumbusStatus: false
					});
				}
			});
	};
	//接口数据
	fetch = (pageindex, value) => {
		let url = ``;
		this.setState({
			loading: true
		});
		APIUnconfiguredDeviceList(url)
			.then(res => {
				this.setState({
					loading: false
				})
				if (res !== undefined) {
					let resdata = res.data;
					this.setState({
						unconfiguredDeviceList_copy: [...resdata]
					});
					
					// console.log("resdata-----", resdata)
					let userInputDriveSNTemp = {};
					let preCompleteConfigStatusTemp = {};
					resdata.forEach((item, index) => {
						item.addedDate = moment.parseZone(item.addedDate).local().format('YYYY-MM-DD HH:mm:ss');
						userInputDriveSNTemp[item.deviceId] = item.userInputDriveSN;
						preCompleteConfigStatusTemp[item.deviceId] = false;
						if(item.deviceSource === 0){
							item.deviceSourceValue = "unkown"
						}
						else if(item.deviceSource === 1){
							item.deviceSourceValue = "CMD"
						}
						else if(item.deviceSource === 2){
							item.deviceSourceValue = "Ability"
						}
						else if(item.deviceSource === 3){
							item.deviceSourceValue = "Motor"
						}
					});
					
					
					this.setState({
						unconfiguredDeviceList: resdata,
						inputDriveSN: userInputDriveSNTemp,
						preCompleteConfigStatus: preCompleteConfigStatusTemp,
						
					});
				}
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				if (err.status === 400 || err.status === 404) {
					message.warning(err.data);
				} else {
					message.error('待配置设备列表接口异常');
				}
			});
	};
	checkDeviceSN = (reocrd, index) => {
		// alert(reocrd.)
		// alert(this.state.inputDriveSN[index]);
		const deviceSN = reocrd.deviceSN;
		const sn_reg = /^[a-z0-9A-Z]+$/i;
		if (this.state.inputDriveSN[index] !== null && this.state.inputDriveSN[index].trim() !== '') {
			if (sn_reg.test(this.state.inputDriveSN[index].trim())) {
				this.setState({
					reocrdData: reocrd,
					showCheckModal: true,
					activeDriveSN: this.state.inputDriveSN[index],
					oldDriveSN: deviceSN
				});
				this.checkDriveSNInCMD(reocrd.deviceSource,this.state.inputDriveSN[index].trim());
				this.checkSNNotExist(this.state.inputDriveSN[index].trim(),reocrd.deviceSource);
			} else {
				message.warning('序列号只能包含字母和数字');
			}
		} else {
			message.warning('序列号不能为空');
		}
	};

	onUserInputDriveSN = (e, deviceId) => {
		let inputDriveSN = Object.assign({}, this.state.inputDriveSN, { [deviceId]: e.target.value });
		this.setState({
			inputDriveSN: inputDriveSN
		});
	};
	onChange = e => {
		this.setState({
			pageindex: e
		});
		this.fetch(e, '');
	};
	changeCheckModalVisible = () => {
		this.setState({
			showCheckModal: !this.state.showCheckModal
		});
	};
	render() {
		let { pageSize, pageindex } = this.state;

		return (
			<>
				{this.state.loading ? (
					<div style={{ textAlign: 'center', padding: '0 0' }}>
						<Spin spinning={this.state.loading} size="large">
							<div className="user_box ABB_tab_marginTop">
								<Search placeholder="输入网关序列号" onSearch={this.onserch} style={{ width: 190 }} className="search_input" />
							</div>
							<div className="EUorg_title">
								<span>待配置设备列表</span>
							</div>
						</Spin>
					</div>
				) : (
					<>
						<div className="user_box ABB_tab_marginTop">
							<Search placeholder="输入网关序列号" onSearch={this.onserch} style={{ width: 190 }} className="search_input" />
						</div>
						<div className="EUorg_title">
							<span>待配置设备列表</span>
						</div>
						<Table
							size="small"
							scroll={{ x: 740 }}
							columns={this.state.columns}
							dataSource={this.state.unconfiguredDeviceList}
							ellipsis={true}
							pagination={{
								// onChange: page => this.onChange(page),
								pageSize: pageSize,
								// current: pageindex,
								// total: this.state.unconfiguredDeviceList.total
							}}
							rowKey={(record, index) => index}
						/>
					</>
				)}
				<StatusModal
					visible={this.state.showCheckModal}
					changeCheckModalVisible={this.changeCheckModalVisible}
					checkStatus={this.state.checkStatus}
					checkDescription={this.state.checkDescription}
					activeDriveSN={this.state.activeDriveSN}
					checkSNDescription={this.state.checkSNDescription}
					checkColumbusStatus={this.state.checkColumbusStatus}
					updateDriveSN={this.updateDriveSN}
					oldDriveSN={this.state.oldDriveSN}
					refreshModal={() => {
						this.initdata();
					}}
				/>
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfigDevice));
