import React, { Component } from 'react';
import { Input, Table, Tooltip, Select, message, Form, Spin, Timeline } from 'antd';

import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { eventfilter, alldrivetype } from '@/api/eventfilter.js';
import AddFilter from './components/addfilter';
import RemoweModel from './components/removefilter'
const div_nowrap = {
	display: 'inline',
	margin: '0 30px 0 0'
};
const icon_center = {
	margin: '0 10px 0 0',
	display: 'inline'
}
const { Search } = Input;

class Blacklist extends Component {
	state = {
		showmodel: {
			visible: false,
			title: '',
			alldrivetypelist: [],
			createdUser: '',
			userId: '',
			type: 0,
			detail: '',
			id: ''
		},
		showmodelRemove: {
			visible: false,
			blackId: ''
		},
		blacklistcopy: [],
		pagination: {},
		loading: false,
		pageindex: 1,
		pageSize: 10,
		location: '###',
		blacklist: [],
		alldrivetypelist: [],
		compareType: '-1',
		compareKeyword: '',
		compareStatus: 2,
		columns: [
			{
				title: '编号',
				dataIndex: 'addedDate',
				key: '1',
				width: '5%',
				render: (text, record, index) => (
					<span>{index + 1}</span>
				)
			},
			{
				title: '产品类型',
				dataIndex: 'driveType',
				key: '2',
				width: '9%'
			},
			{
				title: '状态',
				dataIndex: 'status',
				key: '3',
				width: '6%',
				render: (text, record, index) => {
					if (record.status === 0) {
						return (
							<span>禁用</span>
						)
					}
					else {
						return (
							<span>启用</span>
						)
					}
				}
			},

			{
				title: '描述',
				dataIndex: 'description',
				key: '4',
				width: '15%'
			},
			{
				title: '过滤关键词',
				dataIndex: 'keyWords',
				key: '5',
				width: '15%'
			},

			{
				title: '创建人',
				dataIndex: 'createdUser',
				key: '6',
				width: '10%',

			},
			{
				title: '创建时间',
				dataIndex: 'createdOn',
				key: '7',
				width: '13%',
				render: (text, record) => (
					<span>{moment.parseZone(record.createdOn).local().format("YYYY/MM/DD HH:mm:ss")}</span>
				)
			},
			{
				title: '操作',
				dataIndex: 'operate',
				key: '8',
				width: '7%',
				render: (text, record, index) => {
					return (
						<>
							<div style={icon_center}>
								<Tooltip placement="bottom" title={'编辑'}>
									<span onClick={() => this.changeBlack(record)}>
										<ABB.Icon name="abb/edit" sizeClass='small' />
									</span>
								</Tooltip>
							</div>
							<div style={icon_center}>
								<Tooltip placement="bottom" title={'复制'}>
									<span onClick={() => this.CopyBlack(record)}>
										<ABB.Icon name="abb/copy" sizeClass='small' />
									</span>
								</Tooltip>
							</div>
							<div style={icon_center}>
								<Tooltip placement="bottom" title={'删除'}>
									<span onClick={() => this.removeBlack(record.id)}>
										<ABB.Icon name="abb/trash" sizeClass='small' />
									</span>
								</Tooltip>
							</div>
						</>
					);
				}
			}
		]
	};

	componentDidMount() {
		this.fetch();
		this.getalldrivetype();
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}

	// compareStatus = (value, item) => {
	// 	let strings = String(item.status);
	// 	if (strings.indexOf(value) == -1) {
	// 		return false
	// 	} else {
	// 		return true
	// 	}
	// }
	compareType = (value, item) => {
		if (item.driveType.indexOf(value) === -1) {
			return false
		} else {
			return true
		}
	}
	compareKeyword = (e) => {
		this.setState({
			compareKeyword: e.target.value
		})
	}
	compareStatus = (item) => {
		let isContend = false;
		let { compareStatus, compareKeyword, compareType } = this.state;
		let strings = String(item.status);
		if (strings.indexOf(String(compareStatus)) !== -1 || compareStatus === 2) {
			isContend = true
		} else {
			isContend = false
		}

		if (isContend === true) {
			if (item.driveType.indexOf(compareType) !== -1 || compareType === '-1') {
				isContend = true
			}
			else {
				isContend = false
			}
		}

		if (isContend === true) {
			if (item.keyWords.indexOf(compareKeyword.trim()) !== -1 || compareKeyword === '') {
				isContend = true
			}
			else {
				isContend = false
			}
		}

		return isContend;

	}
	searchBlackList = (func) => {
		let blacklist = [...this.state.blacklistcopy];
		let filterblacklist = []
		for (let i = 0; i < blacklist.length; i++) {
			if (func(blacklist[i])) {
				filterblacklist.push(blacklist[i])
			} else {

			}
		}
		return filterblacklist;
	}

	changeBlack = (data) => {
		this.setState({
			showmodel: {
				visible: true,
				title: '编辑黑名单规则',
				alldrivetypelist: this.state.alldrivetypelist,
				userId: this.props.userInfo.userId,
				createdUser: this.props.userInfo.userName,
				type: 2,
				detail: data,
				id: data.id
			}
		})
	}
	CopyBlack = (data) => {
		this.setState({
			showmodel: {
				visible: true,
				title: '复制黑名单规则',
				alldrivetypelist: this.state.alldrivetypelist,
				userId: this.props.userInfo.userId,
				createdUser: this.props.userInfo.userName,
				type: 1,
				detail: data
			}
		})
	}
	removeBlack = (id) => {
		this.setState({
			showmodelRemove: {
				visible: true,
				blackId: id
			}
		})
	}
	getalldrivetype = () => {
		let url = ``;

		alldrivetype(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				this.setState({
					alldrivetypelist: resdata,

				});
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data);
			} else {
				console.log("getalldrivetype -- alldrivetype --接口异常")
				message.error('接口异常');
			}
		});
	}
	//接口数据
	fetch = () => {
		let url = ``;
		this.setState({
			loading: true
		});
		eventfilter(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				this.setState({
					blacklist: resdata,
					blacklistcopy: resdata,
					loading: false
				}, () => {
					this.setState({
						blacklist: this.searchBlackList(this.compareStatus)
					})
				});
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data);
			} else {
				console.log("fetch -- eventfilter --接口异常")
				message.error('接口异常');
			}
		});
	};
	hidemodel = () => {
		this.setState({
			showmodel: {
				visible: false,
				title: '',
				alldrivetypelist: [],
				type: 1,
				detail: ''
			},
			showmodelRemove: {
				visible: false,
				blackId: ''
			}
		})
	}
	submitmodel = () => {
		this.fetch();

	}
	showAddFilterModel = (text) => {
		this.setState({
			showmodel: {
				visible: true,
				title: text,
				alldrivetypelist: this.state.alldrivetypelist,
				userId: this.props.userInfo.userId,
				createdUser: this.props.userInfo.userName,
				type: 0,
				detail: ''
			}
		})
	}
	onChangePage = (e) => {
		console.log("e",e)
	}
	render() {
		let { compareKeyword, showmodelRemove, compareStatus, compareType, alldrivetypelist, showmodel, blacklistcopy } = this.state;

		return (
			<>
				<div className="user_box ABB_tab_marginTop" style={{ fontSize: 14 }}>

					<div style={{ display: 'flex' }}>

						<ABB.Button
							// className="ABB_tab_marginTop"
							// disabled={isOrgAdministrator}
							onClick={() => this.showAddFilterModel('添加黑名单规则')}
							type="primary-blue"
							sizeClass='small' theme="filled"
							text="添加黑名单规则"
							icon="abb/plus-in-circle"
						/>
						<div style={{ marginLeft: '60px' }}>


							<span style={{ marginRight: 16 }}>状态</span>
							<Select
								placeholder="请选择状态"
								suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
								style={{ width: 160, marginRight: '64px' }}
								value={compareStatus}
								onChange={(e) => {
									this.setState({
										compareStatus: e
									}, () => {
										// if (e === 2) {
										// 	this.setState({
										// 		blacklist: blacklistcopy
										// 	})
										// }
										// else {
										this.setState({
											blacklist: this.searchBlackList(this.compareStatus)
										})
										// }
									})


								}}
							>

								<Select.Option value={2} key={2}>
									全部
								</Select.Option>
								<Select.Option value={1} key={1}>
									启用
								</Select.Option>
								<Select.Option value={0} key={0}>
									禁用
								</Select.Option>
							</Select>
						</div>
						<div>
							<span style={{ marginRight: 16 }}>产品类型</span>
							<Select
								placeholder="请选择产品类型"
								suffixIcon={<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass="small" />}
								style={{ width: 160, marginRight: '64px' }}
								value={compareType}
								onChange={(e) => {

									this.setState({
										compareType: e
									}, () => {
										// if (e === '-1') {
										// 	this.setState({
										// 		blacklist: blacklistcopy
										// 	})
										// }
										// else {
										this.setState({
											blacklist: this.searchBlackList(this.compareStatus)
										})
										// }
									})
								}}
							>
								<Select.Option value={'-1'} key={'-1'}>
									全部
								</Select.Option>
								{
									alldrivetypelist.map((item, index) => {
										return (
											<Select.Option value={item} key={index}>
												{item}
											</Select.Option>
										)
									})
								}
							</Select>
						</div>
					</div>
					<Search
						onChange={this.compareKeyword}
						onSearch={(e) => {


							this.setState({
								blacklist: this.searchBlackList(this.compareStatus)
							})
						}
						} placeholder="请输入关键词" value={compareKeyword} style={{ width: 190 }} className="search_input" />



				</div>

				<Table
					size="small"
					scroll={{ x: 740 }}
					columns={this.state.columns}
					dataSource={this.state.blacklist}
					ellipsis={true}
					pagination={{
						onChange: page => this.onChangePage(page),
						pageSize: 10,
						// current: pageindex,
					}}
					// pagination={false}
					rowKey={(record, index) => index}
				/>
				<AddFilter
					showmodel={showmodel}
					hidemodel={this.hidemodel}
					submitmodel={this.submitmodel}
				/>
				<RemoweModel
					showmodelRemove={showmodelRemove}
					hidemodel={this.hidemodel}
					submitmodel={this.submitmodel}
				/>
			</>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setUserInfo: data => {
		dispatch(setUserInfo(data));
	}
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Blacklist));
