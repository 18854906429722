import * as actionTypes from '../constants/index';

const deviceDetail = data => {
	return {
		type: actionTypes.DEVICE_DETAIL,
		data
	};
};

const setChartsTime = data => {
	return {
		type: actionTypes.CHARTS_TIME,
		data
	};
};

const settreeStatus = data => {
	return {
		type: actionTypes.SET_TREESTATUS,
		data
	};
};

const setBatchImport = data => {
	return {
		type: actionTypes.BATCHIMPORT,
		data
	};
};

const settreeSelect = data => {
	return {
		type: actionTypes.SET_TREESELECT,
		data
	};
};

const settrendscode = data => {
	return {
		type: actionTypes.SET_TRENDSCODE,
		data
	};
};

const settrendstag = data => {
	return {
		type: actionTypes.SET_TRENDSTAG,
		data
	};
};

const checkAllgen = data => {
	return {
		type: actionTypes.CHECKALL,
		data
	};
};

const eventDetaildevice = data => {
	return {
		type: actionTypes.EVENT_DETAIL,
		data
	};
};

const deviceTime = data => {
	return {
		type: actionTypes.DEVICE_TIME,
		data
	};
};
const userCheckDevice = data => {
	return {
		type: actionTypes.USER_CHECK_DETAIL,
		data
	};
};
const setselect = data => {
	return {
		type: actionTypes.GET_SELECT,
		data
	};
};

const settree = data => {
	return {
		type: actionTypes.SET_TREE,
		data
	};
};
const settreeCopy = data => {
	return {
		type: actionTypes.SET_TREECOPY,
		data
	};
};

const settreeValue = data => {
	return {
		type: actionTypes.SET_TREVALUE,
		data
	};
};

const setTopdevice = data => {
	return {
		type: actionTypes.SET_TOPDEVICE,
		data
	};
};

const setFilterDevice = data => {
	return {
		type: actionTypes.SET_FILTERDEVICE,
		data
	};
};

const setassetId = data => {
	return {
		type: actionTypes.SET_ASSETID,
		data
	};
};

const setevent = data => {
	return {
		type: actionTypes.SET_EVENT,
		data
	};
};

const setassettype = data => {
	return {
		type: actionTypes.SET_ASSETTYPE,
		data
	};
};
const setBatchasset = data => {
	return {
		type: actionTypes.SET_BATCHASSET,
		data
	};
}

const setsubscriptioncof = data => {
	return {
		type: actionTypes.SET_SUBSCRIPTIONCOF,
		data
	};
}

const setRadiovalue = data => {
	return {
		type: actionTypes.RADIOVALUE,
		data
	};
}

const setResTrendsCode = data => {
	return {
		type: actionTypes.RES_TRENDSCODE,
		data
	};
}

const setTrendsData = data => {
	return {
		type: actionTypes.TRENDSDATA,
		data
	};
}
const setAssetStatus = data => {
	return {
		type: actionTypes.ASSETSTATUS,
		data
	};
}

const setAssetIsPowertrain = data => {
	return {
		type: actionTypes.ASSET_ISPOWERTRAIN,
		data
	};
}

const setAssetIdCopy = data => {
	return {
		type: actionTypes.ASSET_IDCOPY,
		data
	};
}

const setAssetlabel = data => {
	return {
		type: actionTypes.ASSET_LABEL,
		data
	};
}

const setAssetDetail = data => {
	return {
		type: actionTypes.ASSET_DETAIL,
		data
	};
}

const setTrendEventcode = data => {
	return {
		type: actionTypes.TREND_EVENTCODE,
		data
	};
}

const setActiveTab = data => {
	return {
		type: actionTypes.ACTIVE_TAB,
		data
	};
}

const setTrendEventTag = data => {
	return {
		type: actionTypes.TREND_EVENTTAG,
		data
	};
}

//设备巡检数据
const setInspection = data => {
	return {
		type: actionTypes.INSPECTION,
		data
	};
}

 

export { setActiveTab, setTrendEventTag,
	setTrendEventcode, setAssetDetail, 
	setAssetlabel, setBatchImport,
	setAssetIdCopy,setAssetIsPowertrain,
	setAssetStatus, setTrendsData, 
	setResTrendsCode, setRadiovalue,
	setsubscriptioncof,setChartsTime,
	settrendstag,settrendscode,
	settreeValue,settreeCopy,
	settreeSelect,settreeStatus,
	setBatchasset, setassettype, 
	setevent, setassetId,
	setFilterDevice,userCheckDevice,
	deviceDetail,eventDetaildevice,
	checkAllgen,deviceTime,
	setselect,settree,
	setTopdevice,setInspection };
