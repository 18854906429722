const unittype = [
    {
        value:"ACS530"
    },
    {
        value:"ACS580"
    },
    {
        value:"ACS880"
    },
    {
        value:"ACH531"
    },
    {
        value:"ACH580"
    },
    {
        value:"ACQ531"
    },
    {
        value:"ACQ580"
    },
    // {
    //     value:"ACS800"
    // },
    // {
    //     value:"ACS1000"
    // },
    // {
    //     value:"ACS2000"
    // },
    // {
    //     value:"ACS5000"
    // },
    // {
    //     value:"ACS6000"
    // },
    // {
    //     value:"ACS480"
    // },
    // {
    //     value:"ACQ480"
    // },
    // {
    //     value:"ACH480"
    // },
    // {
    //     value:"ACS580MV"
    // },
    // {
    //     value:"DCS800"
    // },
    // {
    //     value:"DCS880"
    // },
    // {
    //     value:"DCT880"
    // },
    // {
    //     value:"MEGASTAR-W"
    // },
    // {
    //     value:"ACS6080"
    // },
    // {
    //     value:"HES880"
    // },
    // {
    //     value:"PEC3-LCI"
    // },
    // {
    //     value:"CASCADE"
    // },
    // {
    //     value:"PCS6000"
    // },
    // {
    //     value:"ACS335-01"
    // },
    // {
    //     value:"ACS335-03"
    // },
    // {
    //     value:"ACS380-04"
    // },
    // {
    //     value:"ACS510-01"
    // },
    // {
    //     value:"ACS550-01"
    // },
]

export default unittype;