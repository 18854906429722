import React, { Component } from 'react';
import { Modal,Button,Input,AutoComplete, message,Icon } from 'antd';
import {getcpid} from '@/api/user';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Search } = Input;
const { Option } = AutoComplete;
class AuthorizationModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
            inputval: '',
            disabled: true,
            dataSource: []
		};
	}
	
	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
    }
    getcpid=(id)=>{
        let that = this;
        let url = `/${id.trim()}`
        getcpid(url).then(res=>{
            if(res!==undefined){
                let _dataSource = []

                that.setState({
                    disabled: false,
                    dataSource: [{
                        id: res.data.id,
                        name: res.data.name
                    }],
                });
                
            }
        }).catch(err=>{
            that.setState({
                disabled: true,
                dataSource: [],
            });
            // message.warning(err.data)
        })
    }
    renderOption(item) {
		return (
			<Option key={item.id} text={item.name}>
				{item.name}
			</Option>
		);
	}
    hidemodel=(data)=>{
        this.props.hidemodel(data)
        this.setState({
            inputval:''
        })
    }
    submitmodelcode=(data)=>{
        this.props.submitmodelcode(data)
        this.setState({
            inputval: ''
        })
    }
    onChange=(e)=>{
        this.getcpid(e);
    }
    //获取搜索用户框内容
	onChangeSerch = (e) => {
		this.setState({
			inputval: e
		})
	}
	render() {
        const that = this;
        const {showmodelcode} = this.props;
        const { dataSource } = this.state;
        const modelProps = {
            title: showmodelcode.title,
            centered: showmodelcode.centered,
            width:showmodelcode.width,
            visible:showmodelcode.visible,
            maskClosable: false,
            onOk:() => {that.hidemodel(false)},
            onCancel:() => {that.hidemodel(false)},
            footer:[
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodelcode(that.state.inputval)}
                    type="primary-blue"
                    disabled={that.state.disabled} 
                    sizeClass='small' theme="filled"
                    text="确定"
                />, 
                      
            ]
        }
		return (
			<Modal {...modelProps}
            closeIcon={
                <ABB.Icon style={{color:'#1F1F1F'}} className="closeButton" name="abb/close" sizeClass='medium'/>
            }>
                <div className="model_list">
                    <div className="model_title">选择服务商</div>
                    <AutoComplete
                    className="model_input"
                        dataSource={dataSource.map(that.renderOption)}
                        style={{ width: '100%' }}
                        value={that.state.inputval}
                        
                        onSearch={that.onChange}
                        onChange={that.onChangeSerch}
                        placeholder="code"
                    />
                </div>
                
            </Modal>
		);
	}
}

export default AuthorizationModel;
