//添加设备没有订阅弹框
import React, { Component } from 'react';
import { getsubscriptionWithCode } from '@/api/subscription';
import { addDeviceRequest, unUseSubscriptionRequest } from '@/api/device.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    Form,
    Input,
    Modal,
    message,
    Col,
    Row,
    Spin
} from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import AddDeviceProcess from './addDeviceProcess';

class AddDeviceUnSubscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            loading: false
        };
    }

    hidemodel = () => {
        if (this.state.loading === true) {
            return
        }
        this.setState({ disabled: true })
        this.props.form.resetFields();
        this.props.hidemodel();
    }

    //暂不使用订阅,此接口调用后,service不保存此次添加设备信息,仅记录暂不使用行为
    unUseSubscription = () => {
        
        this.props.form.resetFields()
        unUseSubscriptionRequest(this.props.showmodel.data).then((res) => { }).catch(err => { })
        this.props.hidemodel()
    }
    //添加设备接口
    submitmodel = () => {
        this.setState({
            disabled: true,
            loading: true
        })

        addDeviceRequest(this.props.showmodel.data).then((res) => {
            this.setState({
                disabled: false,
                loading: false
            })
            if (res !== undefined) {
                message.success('创建设备成功！')
                
                this.hidemodel()
                this.props.submitmodel()
            } else {
                message.error('添加失败，请联系客服或稍后再试')
            }
        }).catch(err => {
            this.setState({
                disabled: false,
                loading: false
            })
            if (err.data.code === 400 || err.data.code === 404) {
                message.warning(err.data.errorMessage)
            } else {
                message.error('添加失败，请联系客服或稍后再试')
            }
        })
    }

    onSearch = () => {
        //校验验证订阅码输入参数
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.getsubscriptionCode(fieldsValue.subscription_code.trim())
            }
        });
    }

    //校验验证订阅码网络请求
    getsubscriptionCode = (subscriptionCode) => {
        this.setState({
            loading:true,
            disabled:true
        })
        getsubscriptionWithCode(subscriptionCode).then(res => {
            this.setState({
                loading:false
            })
            if (res !== undefined) {
                let resdata = res.data;
                console.log("res=============", resdata);
                this.props.showmodel.data.subscription = {
                    "subscriptionId": resdata.id,
                    "subscriptionLevel": resdata.subscriptionLevel,
                    "subscriptionType": resdata.subscriptionType,
                    "validPeriodTime": resdata.validPeriodTime
                }
                this.setState({
                    disabled: false,
                })
            }
        }).catch(err => {
            this.setState({
                loading:false
            })

            if (err.status === 400 || err.status === 404) {
                message.warning(err.data)
                this.setState({
                    disabled: true,
                })
            } else {
                message.error("获取订阅失败")
            }
        })
    }

    render() {
        const that = this;
        const { showmodel } = that.props;
        const { disabled } = this.state
        const { getFieldDecorator } = that.props.form;
        const deviceData = showmodel.data;
        const { subscription } = deviceData

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
        const modelProps = {
            title: showmodel.title,
            centered: showmodel.centered,
            width: showmodel.width,
            visible: showmodel.visible,
            maskClosable: false,
            onOk: () => { that.unUseSubscription() },
            onCancel: () => { that.unUseSubscription() },
            footer: [
                <div className='addDeviceUnSubscription_footer_content'>

                    <div className="sub_bottom_content">
                        <ABB.Icon style={{ cursor: "auto"}} name="abb/warning-triangle" sizeClass='small' color='#F03040' />
                        <div className='sub_bottom_title'>点击“关闭”按钮后填写的信息将无法保存</div>
                    </div>

                    <ABB.Button
                        onClick={() => that.submitmodel()}
                        type="primary-blue"
                        disabled={disabled}
                        sizeClass='small' theme="filled"
                        text="完成"
                    />
                </div>
            ]
        }

        return (
            <>
                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color:  '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <Spin spinning={this.state.loading} style={{ minHeight: '50vh' }}>
                        <AddDeviceProcess></AddDeviceProcess>

                        <div className='addDeviceUnSubscription_content'>
                            <div className='item_content'>
                                <div className='item_left'>设备名称 : {deviceData.assetName}</div>
                                <div className='item_right'>设备序列号 : {deviceData.serialNumber}</div>
                            </div>

                            <div className='item_content'>
                                <div className='item_left'>网关类型 : {deviceData.gateway.gatewayType === 1 ? 'NETA-21' : "IoT-Panel"}</div>
                                <div className='item_right'>网关序列号 : {deviceData.gateway.gatewaySN}</div>
                            </div>

                            <div className='subscription_title'>设备订阅</div>

                            <div className='subscription_code'>订阅码</div>

                            <div className='subscription_input_box' >
                                {/* <input className='subscription_input'  type={"text"} placeholder="订阅码"></input> */}

                                <Form {...formItemLayout} onSubmit={() => { }} style={{ marginLeft: '8px' }}>

                                    <Row>
                                        <Col span={15}>
                                            <Form.Item label="">
                                                {getFieldDecorator('subscription_code', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: '订阅码不能为空',
                                                        },
                                                        {
                                                            max: 50,
                                                            message: '订阅码长度不能大于50',
                                                        },
                                                        {
                                                            whitespace: true,
                                                            message: '禁止输入空格',
                                                        },
                                                        {
                                                            pattern: new RegExp(/^[A-Za-z0-9]+$/, "g"),
                                                            message: '订阅码仅包含字母和数字',
                                                        }
                                                    ],
                                                })(

                                                    <Input placeholder="请输入订阅码" onChange={()=>{
                                                        this.props.showmodel.data.subscription = {
                                                            subscriptionId: 0,
                                                            subscriptionLevel: "--",
                                                            subscriptionType: "--",
                                                            validPeriodTime: "--"
                                                        }
                                                        this.setState({
                                                            disabled:true
                                                        })
                                                    }} className="model_input" />,
                                                )}
                                            </Form.Item>
                                            {/* <div>订阅码验证失败,请重新输入</div> */}
                                        </Col>

                                        <Col span={4}>
                                            <ABB.Button
                                                className='subscription_check_btn'
                                                onClick={() => that.onSearch()}
                                                type="ghost"
                                                sizeClass='small' theme="filled"
                                                text="验证"
                                            />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>


                            <div className='subscription_data'>
                                <div>订阅等级:<span>{subscription.subscriptionLevel}</span></div>
                                <div>订阅类型:<span>{subscription.subscriptionType}</span></div>
                                <div>订阅有效期:<span>{subscription.validPeriodTime}</span></div>

                            </div>
                        </div>
                    </Spin>
                </Modal>
            </>
        );
    }
}
const WrappedRegistrationForm = Form.create({ name: 'register' })(AddDeviceUnSubscription);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
 
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WrappedRegistrationForm));

