import React, { Component } from 'react';
import { Modal, Button, Input, message, Form } from 'antd';
import { plantList, changeplant } from '@/api/plant';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
// import Amap from './plantaddressmap';
import { connect } from 'react-redux';
import { setplant, setaddress, setaddressChange, setPlantisShow } from '@/redux/actions/plant';
import { withRouter } from 'react-router-dom';
import { plantListdata } from '@/utils/devicesData';

const { Search } = Input;
class ChangeplantForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			inputval: '',
			disabled: false,
			isZn_CH: false,
			changePlantdata: true,
			addressObj: {},
			addPlantval: '',
			latitude: '',
			longitude: '',
			changePlantdata: [],
			plantId: '',
			changePlantval: '',
		};

	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}
	//修改位置
	FnchangePlant(changePlantdata) {

		this.setState({
			changePlantdata
		})
		this.props.setPlantisShow(0)
	}
	changeplantFn =(val)=>{
		let that = this;
		const { getaddressChange } = that.props;
		if(val.AddressName === getaddressChange.plantName){
			this.props.setPlantisShow(0)
			return false;
		}
		let url = {
			id: getaddressChange.plantId,
			name: val.AddressName.trim(),
			latitude: getaddressChange.latitude,
			longitude: getaddressChange.longitude,
			address: getaddressChange.address,

			districts: '',
			organizationId: that.props.getSubid.orgId
		};
		that.setState({
			disabled: true
		})
		changeplant(url).then(res => {
			if (res !== undefined) {
				that.plantList()
				message.success('位置修改成功！')
				that.setState({
					changePlantdata: false,
					disabled: false
				})
				this.props.setPlantisShow(0)
				setTimeout(()=>{
                    this.props.form.resetFields();
                },1000)
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			that.setState({
				disabled: false,
			})
		})
	}
	//修改位置接口
	reschangeplant() {
		this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.changeplantFn(fieldsValue);
            }
        });
	}
	//点击获取plant接口数据刷新数据
	plantList = () => {
		let that = this;
		plantListdata(that.props.getSubid.orgId, function (data) {
			that.props.setplant(data)
		})
	}
	//获取位置name
	onChangeplant = (e) => {
		this.setState({
			changePlantval: e.target.value
		})
		if (e.type === 'compositionstart') {
			this.setState({
				isZn_CH: true
			})
			return;
		}
		if (e.type === "compositionend") {
			this.setState({
				isZn_CH: false
			})
			//获取输入框中的文字,截取前十个,重新赋值给输入框
			if (e.target.value.trim().length > 30) {
				message.warning("输入内容不能超过30")
				this.setState({
					changePlantval: e.target.value,
					disabled: true
				})
			}
			else {
				this.setState({
					changePlantval: e.target.value,
					disabled: false
				})
			}
			return;
		}
		if (!this.state.isZn_CH) {
			if (e.target.value.trim().length > 30) {
				message.warning("输入内容不能超过30")
				this.setState({
					changePlantval: e.target.value.trim(),
					disabled: true
				})
				return false
			}
			this.setState({
				changePlantval: e.target.value.trim(),
				disabled: false
			})
		}
	}

	render() {
		const _self = this;
		const { getaddressChange } = _self.props;
		const { getFieldDecorator } = _self.props.form;

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 24 },
            },
        };
		
		return (
			<>

				<Modal
					title="修改位置"
					centered
					closeIcon={
						<ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
					}
					width={354}
					maskClosable= {false}
					visible={this.state.changePlantdata}
					onOk={() => this.FnchangePlant(false)}
					onCancel={() => this.FnchangePlant(false)}
					footer={[
						<ABB.Button
							onClick={() => this.FnchangePlant(false)}
							type="discreet-black"
							sizeClass='small' theme="filled"
							text="取消"
						/>,
						<ABB.Button
							onClick={() => _self.reschangeplant()}
							type="primary-blue"
							disabled={_self.state.disabled} 
							sizeClass='small' theme="filled"
							text="确定"
						/>,
					]}
				>
					<Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginBottom: '-28px' }}>
						<Form.Item label="位置名称">
							{getFieldDecorator('AddressName', {
								initialValue: getaddressChange.plantName,
								rules: [

									{
										required: true,
										message: '位置名称不能为空',
									},
									{
										max: 20,
										message: '位置名称长度不能大于30',
									},
									{
										whitespace: true,
										message: '禁止输入空格',
									}
								],
							})(
								<Input placeholder="请输入位置名称名称" className="model_input" />,
							)}
						</Form.Item>

						<Form.Item label="详细地址">
							{getFieldDecorator('AddressDetail', {
								initialValue: getaddressChange.address,
								
							})(
								<Input disabled={true} className="model_input" />,
							)}
						</Form.Item>
					</Form>

					{/* <div className='model_list'>
					    <div className='model_title'>位置名称</div>
                        <Input 
                        placeholder={getaddressChange.plantName} 
						className="model_input"
						value={this.state.changePlantval}
						style={{ width: '100%' }}
						onChange = {_self.onChangeplant}
						onCompositionStart={_self.onChangeplant}
                        onCompositionEnd={_self.onChangeplant}
						/>
					</div>
					<div className='model_list'>
					    <div className='model_title'>详细地址</div>
						 <Amap/> 
                        <Input 
                        disabled={true}
                        placeholder={getaddressChange.address} 
						className="model_input"
						
						style={{ width: '100%' }}
						
						/>
					</div>
                 */}

				</Modal>
			</>

		);
	}
}

const Changeplant = Form.create({ name: 'register' })(ChangeplantForm);

const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setplant: data => {
		dispatch(setplant(data))
	},

	setaddress: data => {
		dispatch(setaddress(data))
	},
	setaddressChange: data => {
		dispatch(setaddressChange(data))
	},
	setPlantisShow: data => {
		dispatch(setPlantisShow(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Changeplant));

