import $adminAxios from '../axios/$adminAxios';
import $axios from '../axios/$axios';

export function APICmdOnlyDevicesList() {
	return $adminAxios({
		url: '/toolApi/v1/device/',
		method: 'get',
		type: 'json',
		data: ''
	});
}

// /toolApi/v2/device/cmd_only/
export function APICmdOnlyDevicesListV2(data) {
	return $adminAxios({
		url: '/toolApi/v2/device/cmd_only' + data,
		method: 'get',
		type: 'json',
		data: ''
	});
}


export function APIAbilityOnlyDevicesListV2(data) {
	return $adminAxios({
		url: '/toolApi/v2/device/ability_only' + data,
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function APISubscriptionGroup(data) {
	return $adminAxios({
		url: '/toolApi/v1/subscription/group' + data,
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function APISubscriptionDownload(data) {
	return $adminAxios({
		url: '/toolApi/v1/subscription/download' + data,
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function APIUserOrgMapping(data) {
	return $adminAxios({
		url: `/toolApi/v1/columbus/user/${data}/org`,
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function getOrgListByUserid(userId) {
    return $axios({
        url: '/api/v3/user/organization/all?userId=' + userId,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function APIUpdateUserOrgMapping(user_id, data) {
	return $axios({
		url: `/api/v2/organization/user/${user_id}/org`,
		method: 'post',
		type: 'json',
		data: data
	});
}

export function getDeviceLog(data) {
    return $axios({
        url: '/api/v3/user/act/record/add-device-log?' + data,
        method: 'get',
        type: 'json',
        data: ''
    })
}

export function APIUnconfiguredDeviceList(data) {
	return $axios({
		url: '/api/v1/admin/device/unconfig' + data,
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function APICMDDeviceCheck(data) {
	return $adminAxios({
		url: `/api/v1/admin/check/devicesn/${data}`,
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function CreateMigration(data) {
	return $axios({
		url: `/api/v3/gateway/migration/create`,
		method: 'post',
		type: 'json',
		data: data
	});
}

export function APIuploadAddresses(data) {
	return $axios({
		url: `/api/v4/device/asset-location/import`,
		method: 'post',
		type: 'json',
		data: data
	});
}

// /toolApi/v1/columbus/device/serial_number/update/
export function APIUpdateDriveSN(data) {
	return $axios({
		url: '/api/v1/admin/update/devicesn/list',
		method: 'post',
		type: 'json',
		data: data
	});
}

// /toolApi/v1/columbus/device/serial_number/check/
export function APICheckDriveSNNotExist(data) {
	return $adminAxios({
		url: `/api/v1/admin/check/devicesn/motion/${data}`,
		method: 'get',
		type: 'json',
		data: data
	});
}


// /toolApi/v1/organization/<org_id>/plant
export function APIOrgPlants(data) {
	return $adminAxios({
		url: `/toolApi/v1/organization/${data}/plant`,
		method: 'get',
		type: 'json',
		data: ''
	});
}

// /toolApi/v1/columbus/device/detail

export function APICheckDeviceInfo(data){
	return $adminAxios({
		url: '/toolApi/v1/columbus/device/detail' + data,
		method: 'get',
		type: 'json',
		data: ''
	});
}

// /toolApi/v1/cmd/device
// export function APICheckCMDDeviceInfo(data){
// 	return $adminAxios({
// 		url: '/toolApi/v1/cmd/device' + data,
// 		method: 'get',
// 		type: 'json',
// 		data: ''
// 	});
// }


export function APICheckCMDDeviceInfo(data){
	return $adminAxios({
		url: '/toolApi/v2/verify/device' + data,
		method: 'get',
		type: 'json',
		data: ''
	});
}


// /toolApi/v1/columbus/user
export function APICheckUserInfo(data){
	return $adminAxios({
		url: '/toolApi/v1/columbus/user' + data,
		method: 'get',
		type: 'json',
		data: ''
	});
}

// /toolApi/v1/columbus/org

export function APICheckOrgInfo(orgId){
	return $axios({
		url: '/api/v3/organization/' + orgId,
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function APICheckOrgList(){
	return $axios({
		url: '/api/v3/organization/all',
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function APICheckUserList(){
	return $adminAxios({
		url: '/toolApi/v1/columbus/user/list',
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function APIQueryDebugingList(searchType,searchKey){
	return $axios({
		url: '/api/v4/device/debuginfo/search?searchType=' + searchType + '&searchKey=' + searchKey,
		method: 'get',
		type: 'json',
		data: ''
	});
}

export function APIQueryAssetLocations(pageIndex = 0,pageSize = 20){
	return $axios({
		url: '/api/v4/device/asset-locations?' + 'pageIndex=' + pageIndex  + '&pageSize=' + pageSize,
		method: 'get',
		type: 'json',
		data: ''
	});
}


 