import React from 'react';
import {

    Input,
    Modal,
    message,
    Row,
    Col,
} from 'antd';
import moment from 'moment';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import { APIMoveSubscription } from '@/api/subscription'

export class MovesubForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            valiededCode: '',
            disabled: false,
            subdata: {}
        };
    }

    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.setState({
            inputval: ''
        })
    }
    submitmodel = (data) => {
        if (this.state.inputval === '') {
            message.warning("请输入设备序列号")
        }
        else {
            this.operateSubscription(data)
        }
    }
    operateSubscription = (data) => {
        const url = {
			"targetDeviceSN": this.state.inputval, 
			"subscriptionId": data.subscriptionId
		}
        this.setState({
            disabled: true
        })
        APIMoveSubscription(url)
            .then(res => {
                message.success('订阅迁移成功');
                this.props.submitmodel()
                this.setState({
                    inputval: '',
                    disabled: false
                })
            })
            .catch(err => {
                if (err.status === 400 || err.status === 404) {
                    message.warning(err.data);
                } else if (err.status === 403) {
                    message.error('没有权限');
                }
                else {
                    message.error('订阅迁移接口异常');
                }
                this.setState({
                    disabled: false
                })
            });
    }
    onChange = (e) => {
        if (e.target.value === '') {
            this.setState({
                inputval: e.target.value,
                subdata: {}
            })
            return false;
        }
        this.setState({
            inputval: e.target.value
        })
    }
    render() {
        let that = this;
        const { showmodelmovesub, subscriptionDetail } = that.props;
        const modelProps = {
            title: showmodelmovesub.title,
            centered: showmodelmovesub.centered,
            width: showmodelmovesub.width,
            visible: showmodelmovesub.visible,
            maskClosable: false,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <ABB.Button
                    onClick={() => that.submitmodel(showmodelmovesub)}
                    type="primary-blue"
                    disabled = {this.state.disabled}
                    sizeClass='small' theme="filled"
                    text="确定"
                />,

            ]
        }

        return (
            <>

                <Modal
                    {...modelProps}
                    closeIcon={
                        <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                    }
                >
                    <Row gutter={16}>

                        <Col span={12} >
                            <Col span={24} >
                                订阅类型
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subscriptionDetail.subscriptionType} placeholder='' />
                            </Col>
                        </Col>
                        <Col span={12} >
                            <Col span={24} >
                                订阅等级
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subscriptionDetail.subscriptionLevel} placeholder='' />
                            </Col>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Col span={24} >
                                订阅结束时间
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subscriptionDetail.expiredDateTime} placeholder='' />
                            </Col>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Col span={24} >
                                订阅适用设备
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subscriptionDetail.deviceType} placeholder='' />
                            </Col>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Col span={24} >
                                原传动序列号
                            </Col>
                            <Col span={24}>
                                <Input className="model_input" disabled={true} value={subscriptionDetail.orginDeviceSN} placeholder='' />
                            </Col>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Col span={24} >
                                新传动序列号
                            </Col>
                            <Col span={24}>
                                <Input
                                    className="model_input"
                                    style={{ height: 32, width: 310, marginRight: 13 }}
                                    value={that.state.inputval}
                                    placeholder='新传动序列号'
                                    min='1'
                                    onChange={that.onChange}
                                />

                            </Col>

                        </Col>
                    </Row>

                </Modal>
            </>

        );
    }
}
