import React, { Component } from 'react';
import { Modal, Input, message, Form, Col, Row, Select } from 'antd';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import RemoweModel from '@/components/remove/removeModel'
import { AbilitycustomAlarms, addAbilityCustomAlarm, deleteAbilityCustomAlarm, AbilitySignals } from '@/api/alarms.js'
const { Search } = Input;
const { Option } = Select;

class AbilityCustomAlarmsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alarms: [],
            Signals: [],
            selectSignal: {},
            NumberSelect: 0,
            continuousNumber: [
                {
                    name: "连续3次",
                    id: 3
                },
                {
                    name: "连续5次",
                    id: 5
                },
                {
                    name: "连续7次",
                    id: 7
                },
                {
                    name: "连续10次",
                    id: 10
                },
            ],
            columns: [
                {
                    title: '信号名称',
                    dataIndex: 'name',
                    key: 1,
                    width: '30%',
                },
                {
                    title: '告警条件',
                    dataIndex: 'name',
                    key: 2,
                    width: '70%',
                },
            ],
            dataSource: [
                // {
                //     id: 1,
                //     name: '111'
                // },
                // {
                //     id: 2,
                //     name: '222'
                // }
            ],
            showmodelRemove: {},
            trends: [],
            disabled: false,
            isZn_CH: false,
            deviceId: '',
            isSubAsset: false,
            itemData: {},
            removeDisabled: false
        };
    }
    componentDidMount() {
        this.props.onRef(this)
    }
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    deleteAlarm = (data) => {
        this.setState({
            itemData: data,
            showmodelRemove: {
                visible: true,
                title: '刪除告警规则',
                value: '删除规则后将不会继续提示满足当前告警规则的消息，确定删除当前告警规则吗？',
                width: 504,
                centered: true,
            }
        })
    }
    _modalConfirmEvent = () => {
        this.setState({
            removeDisabled: true
        })
        let url = `${this.state.itemData.id}`
        deleteAbilityCustomAlarm(url).then((res) => {
            if (res.data.code === 200) {
                message.success("删除成功")
                this._getAlarms(this.state.deviceId, this.state.isSubAsset);
            } else {
                message.warn("删除失败")
            }

        }).catch(err => {

        });
    }
    _getAlarms = (id, isSubAsset) => {
        let url = `?assetId=${id}&isSubAsset=${isSubAsset}`
        AbilitycustomAlarms(url).then((res) => {
            // console.log("customAlarms===============", res.data)
            var responseValue = res.data;
            if (responseValue.code === 200) {
                this.getTrends(id, isSubAsset)
                this.setState({
                    alarms: responseValue.data,
                    deviceId: id,
                    isSubAsset: isSubAsset,
                    disabled: false,
                    removeDisabled: false,
                    showmodelRemove: {
                        visible: false,
                        title: '',
                        value: '',
                        width: 504,
                        centered: true,
                    }
                })
            } else {

            }

        }).catch(err => {
            // console.log("customAlarms==========err=====", err)
        });

    }
    getTrends = (id, isSubAsset) => {
        let url = `?id=${id}&isSubAsset=${isSubAsset}`
        AbilitySignals(url).then((res) => {
            var resdata = res.data;
            if (resdata.code === 200) {
                this.setState({
                    Signals: resdata.data,
                    selectSignal: resdata.data.length > 0 ? resdata.data[0] : '',
                    NumberSelect: 3
                })
            } else {

            }
        }).catch(err => {

        });

    }
    _addCustomSignal = (data) => {
        let that = this;
        let label = that.state.Signals.filter(item => {
            if (item.parameterId === data.signalCode) {
                return item
            }
        })
        that.setState({
            disabled: true
        })
        // console.log(trends, "label--------", label[0].label)
        var param = {
            "deviceId": label[0].deviceId, //设备Id
            "signalCode": label[0].parameterId, //信号的code
            "signalName": label[0].name, //信号的名称
            "signalUnit": label[0].unit, //信号数据的单位
            "signalSampleInterval": data.number * label[0].sampleInterval,//信号的采集间隔 int类型
            "timeseriesIntervalLevel": label[0].sampleIntervalLevel,//信号的采集间隔单位 0:unknown;1:second;2:min;3:hour;4:day;枚举类型
            "number": data.number, //次数 int类型
            "compareType": data.type, //比较规则：1-大于;2-小于;枚举类型
            "compareValue": data.compareValue, //频率（规则值）double类型
            "content": data.content //告警内容
        }
        // let url = `${this.state.deviceId}?isSubAsset=${this.state.isSubAsset}`
        addAbilityCustomAlarm(param).then((res) => {
            if (res.data.code === 200) {
                that._getAlarms(this.state.deviceId, this.state.isSubAsset);
                message.success("添加成功")
                that.setState({
                    disabled: false
                })
                this.props.form.resetFields();
            } else {
                message.warning('添加告警规则失败')
            }

        }).catch(err => {
            that.setState({
                disabled: false
            })
            if (err.status === 400 || err.status === 404) {
                message.warning(err.data.errorMessage)
            }
            else {
                console.log("_addCustomSignal -- addAbilityCustomAlarm --接口异常")
                message.error('接口异常')
            }
        });
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
        this.props.form.resetFields();
    }
    submitmodel = (data) => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                console.log("12222222222", fieldsValue)
                this._addCustomSignal(fieldsValue)
            }
            else {
                console.log("err", data)
                if (data.trends.length <= 0) {
                    message.warning("信号时序参数为空，无法设置自定义告警规则")
                }
            }
        });
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
            }
        });
    };
    validateNoChinese = (rule, value, callback) => {
        let reg = /[^\-?\d.]/g;
        let regEmpty = /^\s*$/g;
        if (value && reg.test(value)) {
            callback('只能输入数值');
        } else if (value && regEmpty.test(value)) {
            callback('不能为空');
        } else {
            callback();
        }
    }
    selectTrend = (e) => {
        let that = this;
        setTimeout(() => {
            let label = this.state.Signals.filter(item => {
                if (item.parameterId === e) {
                    return item
                }
            })
            this.props.form.setFieldsValue({
                'unit': label[0].unit
            })

            console.log("label=================", label[0], e)

            that.setState({
                selectSignal: label[0]
            })
        }, 0)
    }
    selectNumber = (e) => {
        let that = this;
        setTimeout(() => {
            that.setState({
                NumberSelect: e
            })

        }, 0)
    }
    isShowStatus = (type) => {
        let { NumberSelect, selectSignal } = this.state;

        if (type === 0) {
            return <div></div>
        }
        else if (type === 1) {
            return <div className='abilitytext'>解释说明：{NumberSelect * selectSignal.sampleInterval}秒/{NumberSelect}个监测周期</div>
        }
        else if (type === 2) {
            return <div className='abilitytext'>解释说明：{NumberSelect * selectSignal.sampleInterval}分钟/{NumberSelect}个监测周期</div>
        }
        else if (type === 3) {
            return <div className='abilitytext'>解释说明：{NumberSelect * selectSignal.sampleInterval}小时/{NumberSelect}个监测周期</div>
        }
        else if (type === 4) {
            return <div className='abilitytext'>解释说明：{NumberSelect * selectSignal.sampleInterval}天/{NumberSelect}个监测周期</div>
        }
    }
    render() {
        const that = this;
        const { showModelAbility } = this.props;
        const { getFieldDecorator } = that.props.form;
        let { alarms, removeDisabled, disabled, Signals, selectSignal } = that.state;
        const modelProps = {
            title: '自定义告警',
            maskClosable: false,
            centered: true,
            width: 856,
            visible: showModelAbility.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="重置"
                />,
                <ABB.Button
                    disabled={disabled}
                    onClick={() => that.submitmodel(showModelAbility)}
                    type="primary-blue"
                    sizeClass='small' theme="filled"
                    text="确定"
                />,
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                <div>
                    <div className='abilitycostom_title'>已添加规则</div>
                    {
                        alarms.length > 0 ? (
                            <div className='costom_box'>
                                <div className='alarms-box'>
                                    {
                                        alarms.map((item, index) => {
                                            console.log("item==========",item)
                                            return (
                                                <Row className='costom_list' key={index}>

                                                    <Col span={7}>
                                                        {item.content}
                                                    </Col>

                                                    <Col span={4}>
                                                        {item.signalName}
                                                    </Col>

                                                    <Col span={4}>
                                                        {
                                                            "连续" + item.number + "次"
                                                        }
                                                    </Col>
                                                  
                                                    <Col span={3}>
                                                        {
                                                            item.compareType === 1 ? '大于' : '小于'
                                                        }
                                                    </Col>

                                                    <Col span={4}>
                                                        {item.compareValue + item.signalUnit}
                                                    </Col>


                                                    <Col span={1}>
                                                        <div onClick={() => this.deleteAlarm(item)}>
                                                            <ABB.Icon name="abb/trash" sizeClass='small' />
                                                        </div>

                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                        ) : <div className='abilitynodata'>暂无数据</div>
                    }
                </div>

                <div>
                    <div className='abilitycostom_title' style={{ margin: '20px 0' }}>添加告警规则</div>
                    <div className='costom_box'>

                        <Row>
                            <Col span={12}>
                                <Form.Item label="告警内容" colon={false}>
                                    {getFieldDecorator('content', {
                                        rules: [
                                            { required: true, message: '请输入告警内容' },
                                            {
                                                max: 20,
                                                message: '长度不能大于20',
                                            },
                                            {
                                                whitespace: true,
                                                message: '禁止输入空格',
                                            }
                                        ],
                                    })(
                                        <Input style={{ width: '90%', borderRadius: '0' }} placeholder="告警内容" className="model_input" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="监测对象" colon={false}>
                                    {getFieldDecorator('signalCode', {
                                        initialValue: Signals.length > 0 ? Signals[0].parameterId : '',
                                        rules: [
                                            { required: true, message: '请选择信号' },
                                        ],
                                    })(

                                        <Select placeholder="选择信号"
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }
                                            onChange={(e) => that.selectTrend(e)}
                                            style={{ width: '100%' }}>
                                            {
                                                Signals.map((item, index) =>
                                                    <Option key={item.parameterId} value={item.parameterId}>{item.name}</Option>
                                                )
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>

                            </Col>
                            <Col span={8}>
                                <Form.Item label="监测规则" colon={false}>
                                    {getFieldDecorator('number', {
                                        initialValue: 3,
                                        rules: [
                                            { required: true, message: '请选择连续次数' },
                                        ],
                                    })(
                                        <Select placeholder="选择次数"
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }
                                            onChange={that.selectNumber}
                                            style={{ width: '90%' }}>
                                            {
                                                this.state.continuousNumber.map((item, index) =>
                                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                                )
                                            }
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label=" " colon={false}>
                                    {getFieldDecorator('type', {
                                        initialValue: 1,
                                        rules: [
                                            { required: true, message: '请选择比较类型' },
                                        ],
                                    })(
                                        <Select placeholder="比较类型"
                                            suffixIcon={
                                                <ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
                                            }
                                            // onChange={that.eventOnChange}

                                            style={{ width: '90%' }}>
                                            <Option key="1" value={1}>大于</Option>
                                            <Option key="2" value={2}>小于</Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label=" " colon={false}>
                                    {getFieldDecorator('compareValue', {
                                        rules: [
                                            { required: true, message: '请输入频率' },
                                            {
                                                max: 20,
                                                message: '长度不能大于20',
                                            },
                                            {
                                                whitespace: true,
                                                message: '禁止输入空格',
                                            }
                                        ],
                                    })(
                                        <Input style={{ width: '100%', borderRadius: '0' }} placeholder="频率" className="model_input" />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item label=" " colon={false}>
                                    {getFieldDecorator('unit', {
                                        initialValue: Signals.length > 0 ? Signals[0].unit : '',
                                        rules: [
                                            { required: false, message: '请输入单位' },
                                            {
                                                max: 20,
                                                message: '长度不能大于20',
                                            },
                                            {
                                                whitespace: true,
                                                message: '禁止输入空格',
                                            }
                                        ],
                                    })(
                                        <Input style={{ width: '100%', borderRadius: '0', borderLeft: 'none' }} disabled={true} placeholder="" className="model_input" />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        {
                            this.isShowStatus(selectSignal.sampleIntervalLevel)
                        }
                    </div>
                </div>
                <RemoweModel
                    showmodelRemove={that.state.showmodelRemove}
                    hidemodel={() => {
                        this.setState({
                            showmodelRemove: {
                                visible: false,
                                title: '',
                                value: '',
                                width: 504,
                                centered: true,
                            }
                        })
                    }}
                    disabled={removeDisabled}
                    submitmodel={that._modalConfirmEvent}
                />
            </Modal>
        );
    }
}
const AbilityCustomAlarms = Form.create({ name: 'register' })(AbilityCustomAlarmsForm);

export default AbilityCustomAlarms;
