
import React, { Component } from 'react';
import { Spin, Table, Button, Modal, Select, message, Menu, Dropdown, AutoComplete, Tooltip } from 'antd';
import {
	CPgrantEU, ABBgetgrantlist, ABBgrantCP, getusergroup, removeinclude, addinclude,
	getassetgroup, changeUsergroup, deleteUsergroup, userinvite, usergrouplist, userdelete,
	userserch, createUsergroup, getrole, removeEUinclude, removeCPinclude
} from '@/api/user.js';
import { connect } from 'react-redux';
import { selectUsergroup, setserchUser } from '@/redux/actions/userlist';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import UserModel from './components/adduserGroup';
import RemoweModel from './components/removeModel';
import Authorization from './components/Authorization';

import UnAuthorization from './components/UnGrantAsset';
import UnGrantEU from './components/UnGrantEU';
import UnGrantCP from './components/UnGrantCP'

import CPAuthorizationEU from './components/CPAuthorizationEU';
import WrapAuth from '@/components/composedcomponent';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';

const { Option } = AutoComplete;
const Tooltip_remove = <span>删除</span>;
class EUuser extends Component {
	constructor(props) {
		super(props)
		this.state = {
			randerType: 0,
			disabled: false,
			selectUsergroup_copy: [],
			modeltype: '',
			showmodel: false,
			showmodelRemove: false,
			loading: false,
			showmodelusergroup: {
				userGroups: {
					owned: [],
					unOwned: []
				},
			},
			enableAuthorizationModal: false,
			showmodelAuthorization: {
				assetGroups: {
					owned: [],
					unOwned: [],
					displayDeviceGroupType: 0,
				},
			},

			userGroups: {},
			assetGroupList: [],
			isuserNull: '',
			userval: '',
			roleId: undefined,
			userId: '',
			roledata: [],
			dataSource: [],
			userGroupval: '',
			commonUser: [],
			adminUser: [],
			pagination: {},
			adduser: false,
			userGroupAuthorization: false,
			usergroupDelete: false,
			userDelete: false,
			selectedRowKeys: [],
			userIdToDelete: 0,
			userGroupId: '',
			userGroupIdThatUserNeedToBeDeleted: 0,
			cancelAuthorization: false,
			assetGroups: [],
			searchvalue: '',
			menu:
				(
					<Menu style={{ width: 167 }}>
						<Menu.Item onClick={() => this.showmodel(true, '编辑用户组', 1)}>
							编辑用户组
						</Menu.Item>
						<Menu.Item onClick={() => this.showmodelRemove(true, '删除用户组', 2, '删除成功后将清除该用户组所有信息，确定删除当前用户组？')}>
							删除用户组
						</Menu.Item>
						<Menu.Item onClick={() => this.userGroupAuthorization(true)}>
							授权设备组
						</Menu.Item>
						{/* <Menu.Item onClick={() => this.cancelAuthorization(true)}>
							取消授权设备组
					    </Menu.Item> */}
						<Menu.Item onClick={() => this.adduser(true)}>
							添加用户
						</Menu.Item>
					</Menu>
				),
			userGroupNameToBeCreated: '',
			columns: [

				{
					title: '用户名称',
					dataIndex: 'userName',
					key: 'name',
					width: 200
				},
				{
					title: '用户身份',
					dataIndex: 'userRole',
					key: 'role',
					width: 250
				},
				{
					title: '联系电话',
					dataIndex: 'phone',
					key: 'phone',
					width: 250
				},
				// {
				// 	title: '邮箱地址',
				// 	dataIndex: 'email',
				// 	key: 'email',
				// 	width: 320
				// },

				{
					title: '操作',
					key: '1',
					dataIndex: 'usergroupId',
					width: 50,
					render: (text, record) => (
						<>
							{
								this.isshowdelete(record)
							}
						</>
					),
				}
			],
			notifications: []
		};
		this.counter = 0;
		this.generate = this.generate.bind(this);
	}

	isshowdelete = (data) => {
		let { userInfo } = this.props;
		if (data.isuserNull !== 2 && data.isOwnManagedPermission === true) {
			return (
				<Tooltip placement="bottom" title={Tooltip_remove}>
					<span onClick={() => this.userDelete(true, data.userId, data.usergroupId)}>
						<ABB.Icon name="abb/trash" sizeClass='small' />
					</span>
				</Tooltip>
			)
		}
		else if (data.isuserNull === 2 && userInfo.organizationSubType !== "Float" && userInfo.isOrgAdministrator === true) {
			return (
				<Tooltip placement="bottom" title={Tooltip_remove}>
					<span onClick={() => this.userDelete(true, data.userId, data.usergroupId)}>
						<ABB.Icon name="abb/trash" sizeClass='small' />
					</span>
				</Tooltip>
			)
		}
		else {
			return ''
		}
	}
	componentDidMount() {
		// let { userInfo } = this.props;

		let _self = this;
		this.isOrgMenu()
		this.fetch();
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		this.setState = () => {
			return;
		};
	}

	//添加用户

	adduser(adduser, isAdminGroup) {
		if (adduser) {
			this.getrole(isAdminGroup)
		}
		this.setState({
			adduser: adduser,
			roleId: undefined,
			userval: ''
		});
	}
	//删除用户
	userDelete(userDelete, userId, userGroupId) {
		this.showmodelRemove(true, '删除用户', 3, '删除成功后将清除该用户所有信息，确定删除当前用户？')
		this.setState({
			// userDelete:userDelete,
			userIdToDelete: userId,
			userGroupIdThatUserNeedToBeDeleted: userGroupId,
		})
	}
	onChangeuserListSerch = (value) => {
		this.setState({
			searchvalue: value
		})
		// const { value } = e.target;//获取输入值
		// let {selectUsergroup_copy}  = this.state;
		// this.props.selectUsergroup(selectUsergroup_copy)
	}
	onSearchuserListSerch = (e) => {
		const value = e;//获取输入值
		this.userListSerch(value);
	}
	onPressEnteruserListSerch = (e) => {
		const { value } = e.target;//获取输入值
		this.userListSerch(value);
	}
	// 页面内搜索
	userListSerch = (value) => {
		// let { Usergroup } = this.props;
		let { selectUsergroup_copy } = this.state;
		let Usergroup = JSON.parse(JSON.stringify(selectUsergroup_copy));
		let adminuserArr = []
		if (value !== '') {
			let _value = value.toLowerCase().trim()
			for (let i = 0; i < Usergroup.length; i++) {
				let userval = Usergroup[i]
				let adminuserlist = []
				for (let j = 0; j < userval.users.length; j++) {
					let adminuser = userval.users[j]
					if (
						(adminuser.userName !== null && adminuser.userName.toLowerCase().indexOf(_value) !== -1) ||
						(adminuser.phone !== null && adminuser.phone.indexOf(_value) !== -1)
					) {
						adminuserlist.push(adminuser)
					}
				}
				if (adminuserlist.length > 0) {
					userval.users = adminuserlist
					adminuserArr.push(userval)
				}
			}
			this.props.selectUsergroup(adminuserArr)
		}
		else {
			this.props.selectUsergroup(selectUsergroup_copy)
		}
	}
	//添加用户组
	createUserGroup(val) {
		let that = this;

		if (val !== '') {
			let url = {
				name: val.userGroupName.trim()
			};
			this.setState({
				disabled: true
			})
			createUsergroup(url).then(function (res) {
				if (res !== undefined) {
					message.success('添加用户组成功')
					that.fetch();

					that.setState({
						userGroupval: '',
						disabled: false,
						showmodel: {
							visible: false,
							title: '',
							value: '',
							width: 354,
							centered: true,
						}
					})

				}

			}).catch(err => {
				if (err.status === 400) {
					message.warning(err.data)
				}
				else if (err.status === 404) {
					message.warning(err.data)
				}
				else {
					message.error('创建用户组失败！')
				}
				this.setState({
					disabled: false
				})
			})
		}
		else {
			message.error('用户组名称不能为空')
		}
	}
	//获取用户组名
	onChangecreateUsergroup = (e) => {
		this.setState({
			userGroupval: e.target.value
		})
	}
	renderOption(item) {
		return (
			<Option key={item.id} text={item.name}>
				{item.name}{item.phone}
			</Option>
		);
	}
	//获取用户权限信息
	getrole(isAdminGroup) {

		let url = ``;
		getrole(url).then(res => {
			if (res !== undefined) {
				let roledata = res.data
				let newData = roledata.map(item => {
					if (item.id === 1) {
						item.name = isAdminGroup ? "超级管理员" : "用户组管理员"
					}
					if (item.id === 3) {
						item.name = isAdminGroup ? '组织管理员' : '普通用户'
					}
					return item
				})

				this.setState({
					roledata: newData
				})
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.error(err.data)
			}
			else {
				message.error('获取用户权限失败')
			}
		})
	}
	//获取设备组列表接口
	getassetgroup = () => {

		let url = `/${this.state.userGroupId}`;
		getassetgroup(url).then(res => {
			if (res !== undefined) {
				this.setState({
					assetGroups: res.data,
				})
				this.showmodelAuthorization(true, '用户组授权设备组', 4)
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.error(err.data)
			}
			else {
				message.error('授权失败')
			}

		})
	}
	//获取用户授权列表接口
	getusergroup = () => {
		let url = `?userGroupId=${this.state.userGroupId}`;
		getusergroup(url).then(res => {
			if (res !== undefined) {

				this.setState({
					userGroups: res.data
				})
				this.showmodelusergroup(true, 'CP授权EU到用户组', 6)
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.error(err.data)
			}
			else {
				message.error('授权失败')
			}

		})
	}
	//选择权限报村
	roleChange = (e) => {
		this.setState({
			roleId: e
		})
	}
	//邀请用户至用户组
	invite() {
		let that = this;
		let { userval } = that.state;
		let reg = /^\s*1\s*[3456789]\s*(\d\s*){9}\s*$/;
		let _userval = reg.test(userval)
		if (_userval == false) {
			message.warning("请输入正确的手机号码")
			return false;
		}
		if (that.state.roleId !== "" || that.state.roleId !== undefined) {
			that.setState({
				disabled: true
			})
			let url = {
				"UserPhone": userval.trim(),
				"userGroupId": that.state.userGroupId,
				"roleId": that.state.roleId
			}
			userinvite(url).then(res => {
				if (res !== undefined) {
					message.success('用户添加成功！')
					that.fetch();
					that.setState({
						adduser: false,
						roleId: undefined,
						userval: '',
						disabled: false
					})
				}
			}).catch(err => {
				if (err.status === 400) {
					message.warning(err.data)
				}
				else if (err.status === 404) {
					message.warning(err.data)
				}
				else {
					message.error('用户添加失败！')
				}
				that.setState({
					disabled: false
				})
			})
		}
		else {
			message.warning("请选择权限用户组")
		}
	}
	//用户组删除
	usergroupDelete = (usergroupDelete) => {
		if (this.state.isuserNull === 1) {
			message.error('用户组内尚有未删除的用户，请先删除组内用户。')
		}
		else if (this.state.isuserNull === 2) {
			message.error('默认用户组不能删除')
		}
		else {
			this.setState({
				usergroupDelete
			})
		}

	}
	//判断组织信息显示
	isOrgMenu = () => {
		let { userInfo } = this.props;
		if (userInfo.organizationType === 'EU') {
			if (userInfo.isOrgAdministrator === true) {
				this.setState({
					menu: (
						<Menu style={{ width: 167 }}>
							<Menu.Item onClick={() => this.showmodel(true, '编辑用户组', 1)}>
								编辑用户组
							</Menu.Item>
							<Menu.Item onClick={() => this.showmodelRemove(true, '删除用户组', 2, '删除成功后将清除该用户组所有信息，确定删除当前用户组？')}>
								删除用户组
							</Menu.Item>
							<Menu.Item onClick={() => this.userGroupAuthorization(true)}>
								授权设备组
							</Menu.Item>
							<Menu.Item onClick={() => this.cancelAssetGroupAuthorization(true)}>
								取消授权设备组
							</Menu.Item>
							<Menu.Item onClick={() => this.adduser(true, false)}>
								添加用户
							</Menu.Item>
						</Menu>
					)
				})
			}
			else {
				this.setState({
					menu: (
						<Menu style={{ width: 167 }}>
							<Menu.Item onClick={() => this.showmodel(true, '编辑用户组', 1)}>
								编辑用户组
							</Menu.Item>
							{/* <Menu.Item onClick={() => this.showmodelRemove(true, '删除用户组', 2, '删除成功后将清除该用户组所有信息，确定删除当前用户组？')}>
								删除用户组
							</Menu.Item> */}
							{/* <Menu.Item onClick={() => this.userGroupAuthorization(true)}>
								授权设备组
							</Menu.Item> */}

							<Menu.Item onClick={() => this.adduser(true, false)}>
								添加用户
							</Menu.Item>
						</Menu>
					)
				})
			}
		}
		else if (userInfo.organizationType === 'CP') {
			if (userInfo.isOrgAdministrator === true) {
				this.setState({
					menu: (
						<Menu style={{ width: 167 }}>
							<Menu.Item onClick={() => this.showmodel(true, '编辑用户组', 1)}>
								编辑用户组
							</Menu.Item>
							<Menu.Item onClick={() => this.showmodelRemove(true, '删除用户组', 2, '删除成功后将清除该用户组所有信息，确定删除当前用户组？')}>
								删除用户组
							</Menu.Item>
							<Menu.Item onClick={() => this.CPAuthorizationuserGroup(true)}>
								授权客户
							</Menu.Item>
							<Menu.Item onClick={() => this.cancelEUGroupAuthorization(true)}>
								取消授权客户
							</Menu.Item>
							<Menu.Item onClick={() => this.adduser(true, false)}>
								添加用户
							</Menu.Item>
						</Menu>
					)
				})
			}
			else {
				this.setState({
					menu: (
						<Menu style={{ width: 167 }}>
							<Menu.Item onClick={() => this.showmodel(true, '编辑用户组', 1)}>
								编辑用户组
							</Menu.Item>
							{/* <Menu.Item onClick={() => this.showmodelRemove(true, '删除用户组', 2, '删除成功后将清除该用户组所有信息，确定删除当前用户组？')}>
								删除用户组
							</Menu.Item> */}
							{/* <Menu.Item onClick={() => this.CPAuthorizationuserGroup(true)}>
								授权客户
							</Menu.Item> */}
							<Menu.Item onClick={() => this.adduser(true, false)}>
								添加用户
							</Menu.Item>
						</Menu>
					)
				})
			}
		}
		else if (userInfo.organizationType === 'ABB') {
			if (userInfo.isOrgAdministrator === true) {
				this.setState({
					menu: (
						<Menu style={{ width: 167 }}>
							<Menu.Item onClick={() => this.showmodel(true, '编辑用户组', 1)}>
								编辑用户组
							</Menu.Item>
							<Menu.Item onClick={() => this.showmodelRemove(true, '删除用户组', 2, '删除成功后将清除该用户组所有信息，确定删除当前用户组？')}>
								删除用户组
							</Menu.Item>
							<Menu.Item onClick={() => this.ABBAuthorizationuserGroup(true)}>
								授权客户
							</Menu.Item>
							<Menu.Item onClick={() => this.cancelABBAuthorizationuserGroup(true)}>
								取消授权客户
							</Menu.Item>
							<Menu.Item onClick={() => this.adduser(true, false)}>
								添加用户
							</Menu.Item>
						</Menu>
					)
				})
			}
			else {
				this.setState({
					menu: (
						<Menu style={{ width: 167 }}>
							<Menu.Item onClick={() => this.showmodel(true, '编辑用户组', 1)}>
								编辑用户组
							</Menu.Item>
							{/* <Menu.Item onClick={() => this.showmodelRemove(true, '删除用户组', 2, '删除成功后将清除该用户组所有信息，确定删除当前用户组？')}>
								删除用户组
							</Menu.Item> */}
							{/* <Menu.Item onClick={() => this.ABBAuthorizationuserGroup(true)}>
								授权客户
							</Menu.Item> */}
							<Menu.Item onClick={() => this.adduser(true, false)}>
								添加用户
							</Menu.Item>
						</Menu>
					)
				})
			}
		}
	}
	//获取用户组id保存
	getuserGroupId = (data, isuserNull, userGroupName, type) => {

		this.setState({
			userGroupId: data,
			isuserNull: isuserNull,
			userGroupval: userGroupName,
		})
		console.log("type==============", type)
		if (type !== 'AdminGroup') {
			this.isOrgMenu()
		}
		else {
			this.setState({
				menu: (
					<Menu style={{ width: 167 }}>
						<Menu.Item onClick={() => this.showmodel(true, '编辑用户组', 1)}>
							编辑用户组
						</Menu.Item>
						{/* <Menu.Item onClick={() => this.showmodelRemove(true, '删除用户组', 2, '删除成功后将清除该用户组所有信息，确定删除当前用户组？')}>
							删除用户组
						</Menu.Item> */}
						<Menu.Item onClick={() => this.adduser(true, true)}>
							添加用户
						</Menu.Item>
					</Menu>
				)
			})
		}
	}
	//搜索用户添加
	Fnsearch = (value) => {

		let that = this;
		if (value !== '') {
			let url = "?keyWord=" + value.trim();
			userserch(url).then(res => {
				if (res !== undefined) {
					if (res.data.length > 0) {
						that.setState({
							dataSource: res.data,
						});
					}
					else {
						that.setState({
							dataSource: [],
						});
						that.props.setserchUser([])
						// message.error("搜索内容为空")
					}
				}
				else {
					that.setState({
						dataSource: [],
					});
					that.props.setserchUser([])
				}
			}).catch(err => {
				that.setState({
					dataSource: [],
				});
				// message.error("搜索内容为空")
			})
		}

	}
	//删除用户组
	Fndeleteusergroup(data) {
		this.setState({
			disabled: true
		})
		let url = `/${this.state.userGroupId}`
		deleteUsergroup(url).then(res => {
			if (res !== undefined) {
				this.setState({
					disabled: false,
					showmodelRemove: {
						visible: data,
						title: '',
						value: '',
						width: 504,
						centered: true,
					}
				})
				this.fetch();
			}
		}).catch(err => {
			if (err.status === 400) {
				message.warning(err.data)
			}
			else if (err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('删除用户组失败')
			}
			this.setState({
				disabled: false
			})
		})
	}
	//删除user
	Fndelete(data) {

		let url = {
			"userId": this.state.userIdToDelete,
			"userGroupId": this.state.userGroupIdThatUserNeedToBeDeleted
		}
		this.setState({
			disabled: true
		})
		userdelete(url).then(res => {
			if (res !== undefined) {
				// this.setState({userDelete:false});
				this.setState({
					disabled: false,
					showmodelRemove: {
						visible: data,
						title: '',
						value: '',
						width: 504,
						centered: true,
					}
				})
				this.fetch();
			}
		}).catch(err => {
			if (err.status === 400) {
				message.warning(err.data)
			}
			else if (err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('删除用户失败')
			}
			this.setState({
				disabled: false
			})
		})
	}
	//获取搜索用户框内容
	onChangeSerch = (e) => {

		this.setState({
			userval: e
		})
	}
	//用户授权设备
	userGroupAuthorization(cond) {

		this.getassetgroup()
	}
	//取消授权设备组
	cancelAssetGroupAuthorization() {
		this.setState({
			enableUnAuthzAssetModal: true
		})
		this.showmodelUnAuthorization(true, '取消授权设备组', 5);
		// this.getassetgroupinclude()
	}
	// 取消授权EU客户
	cancelEUGroupAuthorization() {
		this.setState({
			enableUnAuthzEUModal: true
		})
		this.showmodelUnAuthorizationEU(true, "取消授权EU组织", 8);
	}

	//取消授权接口
	removeinclude(AssetGroupList) {

		// let removeList = this.groupListDiff(newAssetGroupList, this.state.assetGroups.owned);
		let removeGroupIdList = [];
		AssetGroupList.forEach((item) => {
			removeGroupIdList.push(item.id);
		})
		let url = {
			"userGroupId": this.state.userGroupId,
			"assetGroupList": removeGroupIdList
		}
		this.setState({
			disabled: true
		})
		removeinclude(url).then(res => {
			if (res !== undefined) {
				message.success('取消授权设备组成功')
				this.setState({
					disabled: false,
					showmodelUnAuthorization: {
						visible: false,
						title: '',
						value: '',
						width: 354,
						centered: true,
					}
				})
			}
		}).catch(err => {
			if (err.status === 400) {
				message.warning(err.data)
			}
			else if (err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('取消授权设备组失败')
			}
			this.setState({
				disabled: false
			})
		})
	}
	// 取消授权EU组织接口
	removeEUinclude(EUGroupList) {
		let removeGroupIdList = [];
		EUGroupList.forEach((item) => {
			removeGroupIdList.push(item.id);
		})

		let url = {
			"cpUserGroupId": this.state.userGroupId,
			"euOrgId": removeGroupIdList
		}
		this.setState({
			disabled: true
		})
		removeEUinclude(url).then(res => {
			if (res !== undefined) {
				message.success('取消CP授权客户成功')
				this.setState({
					disabled: false,
					showmodelUnAuthorization: {
						visible: false,
						title: '',
						value: '',
						width: 354,
						centered: true,
					}
				})
			}
		}).catch(err => {
			if (err.status === 400) {
				message.warning(err.data)
			}
			else if (err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('取消CP授权客户失败')
			}
			this.setState({
				disabled: false
			})
		})
	}
	removeCPinclude(CPGroupList) {

		// let removeList = this.groupListDiff(newAssetGroupList, this.state.assetGroups.owned);
		let removeGroupIdList = [];
		CPGroupList.forEach((item) => {
			removeGroupIdList.push(item.id);
		})

		let url = {
			"abbUserGroupId": this.state.userGroupId,
			"cpOrgId": removeGroupIdList
		}
		this.setState({
			disabled: true
		})
		removeCPinclude(url).then(res => {
			if (res !== undefined) {
				message.success('取消ABB授权客户成功')
				this.setState({
					disabled: false,
					showmodelUnAuthorization: {
						visible: false,
						title: '',
						value: '',
						width: 354,
						centered: true,
					}
				})
			}
		}).catch(err => {
			if (err.status === 400) {
				message.warning(err.data)
			}
			else if (err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('取消ABB授权客户失败')
			}
			this.setState({
				disabled: false
			})
		})
	}



	submitmodelAddinclude = (val, displayType) => {
		this.addinclude(val, displayType)
	}

	// 授权设备组接口
	addinclude(val, displayType) {
		let url = {
			"userGroupId": this.state.userGroupId,
			"assetGroupList": val,
			"displayDeviceGroupType": displayType
		}
		this.setState({
			disabled: true,
			enableAuthorizationModal: false
		})
		addinclude(url).then(res => {
			if (res !== undefined) {
				message.success('授权设备组成功')
				this.setState({
					disabled: false,
					showmodelAuthorization: {
						visible: false,
						title: '',
						value: '',
						width: 354,
						assetGroups: {
							owned: [],
							unOwned: [],
							displayDeviceGroupType: 0,
						},
						centered: true,
					}
				})
			}
		}).catch(err => {
			if (err.status === 400) {
				message.warning(err.data)
			}
			else if (err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('授权设备组失败')
			}
			this.setState({
				disabled: false
			})

		})
	}
	FnCPgrantEU(val) {
		let url = {
			"cpUserGroupId": this.state.userGroupId,
			"euOrgId": val
		}
		this.setState({
			disabled: true
		})
		CPgrantEU(url).then(res => {
			if (res !== undefined) {
				message.success('授权EU成功')
				this.setState({
					disabled: false,
					showmodelusergroup: {
						visible: false,
						title: '',
						value: '',
						width: 354,
						userGroups: {
							owned: [],
							unOwned: []
						},
						centered: true,
					}
				})
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('授权EU失败')
			}
			this.setState({
				disabled: false
			})
		})
	}
	//abb授权客户到用户组
	FnABBgrantCP(val) {
		let url = {
			"abbUserGroupId": this.state.userGroupId,
			"cpOrgId": val
		}
		this.setState({
			disabled: true
		})
		ABBgrantCP(url).then(res => {
			if (res !== undefined) {
				message.success('授权客户成功')
				this.setState({
					disabled: false,
					showmodelusergroup: {
						visible: false,
						title: '',
						value: '',
						width: 354,
						userGroups: {
							owned: [],
							unOwned: []
						},
						centered: true,
					}
				})
			}
		}).catch(err => {
			if (err.status === 400) {
				message.warning(err.data)
			}
			else if (err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('授权客户失败')
			}
			this.setState({
				disabled: false
			})
		})
	}
	//编辑用户组
	changeUserGroup(val) {
		let that = this;
		if (that.state.userGroupval !== '') {
			let url = {
				userGroupId: that.state.userGroupId,
				name: val.userGroupName.trim()
			};
			that.setState({
				disabled: true
			})
			changeUsergroup(url).then(function (res) {
				if (res !== undefined) {
					message.success('编辑用户组成功')
					that.fetch();
					that.setState({
						userGroupval: '',
						disabled: false,
						showmodel: {
							visible: false,
							title: '',
							value: '',
							width: 354,
							centered: true,
						}
					})
				}
			}).catch(err => {
				if (err.status === 400) {
					message.warning(err.data)
				}
				else if (err.status === 404) {
					message.warning(err.data)
				}
				else {
					message.error('编辑用户组失败')
				}
				that.setState({
					disabled: false
				})
			})
		}
		else {
			message.warning('用户组名称不能为空')
		}
	}
	//用户list接口
	fetch = () => {
		let _self = this;
		_self.setState({
			loading: true,
		})
		let url = ``
		usergrouplist(url).then(res => {
			if (res !== undefined) {
				let resdata = res.data;
				resdata.userGroupInfos.forEach((ug, index) => {
					let id = ug.userGroupId;
					if (ug.users.length > 0) {
						ug.isuserNull = 1
					}
					else {
						ug.isuserNull = 0
					}
					ug.users.forEach((user, ____id) => {
						user.usergroupId = id;
						user.isuserNull = 1;
						user.isOwnManagedPermission = ug.isOwnManagedPermission;
						if (user.email === '' || user.email === null) {
							user.email = '--'
						}
					})
				});
				//增加isuserNull标识，判断是否有用户及是否是默认用户隐藏delete按钮
				resdata.ungroupedUsers.isuserNull = 2;
				resdata.ungroupedUsers.users.forEach((user, ____id) => {
					user.isuserNull = 2;
					user.isOwnManagedPermission = resdata.ungroupedUsers.isOwnManagedPermission;
					if (user.email === '' || user.email === null) {
						user.email = '--'
					}
				})
				resdata.userGroupInfos.push(resdata.ungroupedUsers)
				_self.setState({
					selectUsergroup_copy: resdata.userGroupInfos
				})
				_self.props.selectUsergroup([...resdata.userGroupInfos]);
				_self.setState({
					loading: false,
				})
			}
		}).catch(err => {
			_self.setState({
				loading: false,
			})
			if (err.status === 400) {
				message.warning(err.data)
			}
			else if (err.status === 404) {
				message.warning(err.data)
			}
			else {
				message.error('用户组获取失败')
			}
		})
	};
	//cp授权EU
	CPAuthorizationuserGroup = () => {
		this.getusergroup()
	}

	//获取ABB用户授权列表接口
	ABBgetgrantlist = () => {
		let url = `?userGroupId=${this.state.userGroupId}`;
		ABBgetgrantlist(url).then(res => {
			if (res !== undefined) {

				this.setState({
					userGroups: res.data
				})
				this.showmodelusergroup(true, '授权客户到用户组', 7)
			}
		}).catch(err => {
			if (err.status === 400 || err.status === 404) {
				message.error(err.data)
			}
			else {
				message.error('授权失败')
			}

		})
	}

	ABBAuthorizationuserGroup = () => {
		this.ABBgetgrantlist();
	}
	cancelABBAuthorizationuserGroup = () => {
		this.setState({
			enableUnAuthzCPModal: true
		})
		this.showmodelUnAuthorizationCP(true, "取消授权CP客户", 9);
	}

	//获取选中设备组
	onChange = (e) => {
		this.setState({
			assetGroupList: e
		})
	}
	// 组件传值
	hidemodel = (data) => {
		this.setState({
			userGroupval: '',
			showmodel: {
				visible: data,
				title: '',
				width: 354,
				lable: '',
				value: '',
				centered: true,
			},
			showmodelRemove: {
				visible: data,
				title: '',
				value: '',
				width: 504,
				centered: true,
			},
			showmodelAuthorization: {
				visible: false,
				title: '',
				value: '',
				width: 354,
				assetGroups: {
					owned: [],
					unOwned: [],
					displayDeviceGroupType: 0,
				},
				centered: true,
			},
			showmodelUnAuthorization: {
				visible: false,
				title: '',
				value: '',
				width: 354,
				centered: true,
			},
			showmodelUnAuthorizationEU: {
				visible: false,
				title: '',
				value: '',
				width: 354,
				centered: true,
			},
			showmodelUnAuthorizationCP: {
				visible: false,
				title: '',
				value: '',
				width: 354,
				centered: true,
			},
			showmodelusergroup: {
				visible: false,
				title: '',
				value: '',
				width: 354,
				userGroups: {
					owned: [],
					unOwned: []
				},
				centered: true,
			},
			enableUnAuthzAssetModal: false,
			enableUnAuthzEUModal: false,
			enableUnAuthzCPModal: false,
			enableAuthorizationModal: false,
		})
	}
	submitmodel = (val) => {
		// type 0 创建用户组
		// type 1 修改用户组
		// type 2 删除用户组
		// type 3 删除用户
		// type 4 授权
		// type 5 取消授权
		// type 8 取消授权EU
		// type 9 取消授权CP
		let modeltype = this.state.modeltype;
		if (modeltype === 0) {
			this.createUserGroup(val)
		}
		if (modeltype === 1) {
			if (val === '') {
				val = this.state.userGroupval
			}
			this.changeUserGroup(val)
		}
		if (modeltype === 2) {

			this.Fndeleteusergroup(false)
		}
		if (modeltype === 3) {

			this.Fndelete(false)
		}
		// if (modeltype === 4) {
		// 	this.addinclude(val)
		// }
		// 取消设备组授权
		if (modeltype === 5) {
			this.removeinclude(val)
			this.setState({
				enableUnAuthzAssetModal: false
			})
		}
		if (modeltype === 6) {
			this.FnCPgrantEU(val)
		}
		if (modeltype === 7) {
			this.FnABBgrantCP(val)
		}
		// 取消EU授权
		if (modeltype === 8) {
			// 调用接口
			this.removeEUinclude(val)
			this.setState({
				enableUnAuthzEUModal: false
			})
		}
		// 取消EU授权
		if (modeltype === 9) {
			// 调用接口
			this.removeCPinclude(val)
			this.setState({
				enableUnAuthzCPModal: false
			})
		}
	}
	showmodel = (showmodel, title, type) => {
		if (type === 0) {
			this.setState({
				modeltype: type,
				showmodel: {
					visible: showmodel,
					title: title,
					lable: '用户组名称',
					// value: '请输入用户组名称',
					value: undefined,
					width: 354,
					centered: true,
				}
			})
		}
		if (type === 1) {
			this.setState({
				modeltype: type,
				showmodel: {
					visible: showmodel,
					title: title,
					lable: '用户组名称',
					// value: '请输入用户组名称',
					value: this.state.userGroupval || undefined,
					width: 354,
					centered: true,
				}
			})
		}
	}
	showmodelRemove = (showmodel, title, type, value) => {
		this.setState({
			modeltype: type,
			showmodelRemove: {
				visible: showmodel,
				title: title,
				value: value,
				width: 504,
				centered: true,
			}
		})
	}
	showmodelAuthorization = (showmodel, title, type) => {
		this.setState({
			enableAuthorizationModal: true,
			modeltype: type,
			showmodelAuthorization: {
				visible: showmodel,
				title: title,
				value: '',
				width: 354,
				assetGroups: this.state.assetGroups,
				centered: true,

			}
		})
	}
	// 取消设备组授权
	showmodelUnAuthorization = (showmodel, title, type) => {
		this.setState({
			modeltype: type,
			showmodelUnAuthorization: {
				visible: showmodel,
				title: title,
				value: '',
				width: 354,
				centered: true,

			}
		})
	}
	// 取消EU授权
	showmodelUnAuthorizationEU = (showmodel, title, type) => {
		this.setState({
			modeltype: type,
			showmodelUnAuthorizationEU: {
				visible: showmodel,
				title: title,
				value: '',
				width: 354,
				centered: true,

			}
		})
	}
	// 取消CP授权
	showmodelUnAuthorizationCP = (showmodel, title, type) => {
		this.setState({
			modeltype: type,
			showmodelUnAuthorizationCP: {
				visible: showmodel,
				title: title,
				value: '',
				width: 354,
				centered: true,

			}
		})
	}
	showmodelusergroup = (showmodel, title, type) => {
		this.setState({
			modeltype: type,
			showmodelusergroup: {
				visible: showmodel,
				title: title,
				value: '',
				width: 354,
				userGroups: this.state.userGroups,
				centered: true,
			}
		})
	}
	generate() {
		this.counter++;
		const newNotification = {
			id: `id-${this.counter}`,
			type: 'banner',
			// Change the Notification type and severity by randomly:
			discreet: Math.random() > 0.7,
			severity: ['success', 'info', 'warn', 'alarm'][Math.floor(Math.random() * Math.floor(4))],
			timeout: 3000,
			text: `Random notification #${this.counter}`
		};
		this.setState({ notifications: this.state.notifications.concat(newNotification) });
	}
	render() {
		const { Usergroup, userInfo } = this.props;
		const mytablecolumns = this.state.columns;
		const { dataSource, loading } = this.state;
		const _self = this;
		const AuthButton = WrapAuth(Button, userInfo);
		return (
			<div>
				{/* <AuthButton type="primary" operCode={userInfo.isOrgAdministrator}>导出</AuthButton> */}
				<div className="user_box ABB_tab_marginTop">
					{
						(userInfo.organizationSubType !== "Float" && userInfo.isOrgAdministrator === true) ? (
							<ABB.Button
								onClick={() => _self.showmodel(true, '添加用户组', 0)}
								type="primary-blue"
								sizeClass='small' theme="filled"
								text="添加用户组"
								icon="abb/plus-in-circle"
							/>
						) : <div></div>
					}
					<ABB.Input
						type='discreet'
						dataType="text"
						placeholder="请输入用户名称/手机号"
						value={_self.state.searchvalue}
						style={{ width: 190 }}
						className="search_input"
						onIconClick={v => _self.onSearchuserListSerch(v)}
						onKeyUp={v => _self.onPressEnteruserListSerch(v)}
						onValueChange={v => _self.onChangeuserListSerch(v)}
						icon="abb/search"
					/>

				</div>
				{/* 用户列表接口 */}
				{
					Usergroup.map(function (item, index) {
						return (
							<div key={index}>
								{
									((userInfo.organizationSubType !== "Float") || (userInfo.organizationSubType === "Float" && userInfo.isOrgAdministrator === true)) ? (
										<div className="EUorg_title">
											<span>
												{item.userGroupName}

											</span>
											{
												item.isuserNull !== 2 && item.isOwnManagedPermission === true ? (
													<Dropdown
														overlay={_self.state.menu}
														placement="bottomRight"
														trigger={['click']}
													>
														<div
															onClick={() => _self.getuserGroupId(
																item.userGroupId,
																item.isuserNull,
																item.userGroupName,
																item.userGroupType
															)}>
															<ABB.Icon name="abb/menu" sizeClass='small'
																style={{ 'color': '#1F1F1F' }}
															/>
														</div>

													</Dropdown>
												) : ''
											}

										</div>
									) : ''
								}
								<Spin spinning={loading} style={{ height: '90vh' }}>


									<div style={{ position: 'relative', minHeight: 60 }}>
										{
											item.users.length > 0 ? (
												<Table
													scroll={{ x: 1000 }}
													className='org_right'
													ellipsis={true}
													size='small'
													columns={mytablecolumns}
													dataSource={item.users}
													pagination={false}
													rowKey={(record, index) => record.userId + index}
												/>
											) : <div className="renderEmpty" style={{ marginTop: 20, minHeight: 100 }}>
												<div className="renderEmpty_center">
													<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
													<p>暂无数据</p>
												</div>
											</div>
										}
									</div>
								</Spin>

							</div>
						)
					})
				}
				{/* model */}
				<UserModel
					showmodel={_self.state.showmodel}
					hidemodel={_self.hidemodel}
					disabled={_self.state.disabled}
					submitmodel={_self.submitmodel}
				/>
				<RemoweModel
					showmodelRemove={_self.state.showmodelRemove}
					hidemodel={_self.hidemodel}
					disabled={_self.state.disabled}
					submitmodel={_self.submitmodel}
				/>
				{this.state.enableAuthorizationModal ? (<Authorization
					showmodelAuthorization={_self.state.showmodelAuthorization}
					hidemodel={_self.hidemodel}
					disabled={_self.state.disabled}
					submitmodel={_self.submitmodelAddinclude}
				/>) : ('')}
				{this.state.enableUnAuthzAssetModal ? (<UnAuthorization
					showmodelUnAuthorization={_self.state.showmodelUnAuthorization}
					userGroupId={this.state.userGroupId}
					hidemodel={_self.hidemodel}
					disabled={_self.state.disabled}
					submitmodel={_self.submitmodel}
				/>) : ('')}

				<CPAuthorizationEU
					showmodelusergroup={_self.state.showmodelusergroup}
					hidemodel={_self.hidemodel}
					disabled={_self.state.disabled}
					submitmodel={_self.submitmodel}
				/>
				{this.state.enableUnAuthzEUModal ? (<UnGrantEU
					showmodelUnAuthorization={_self.state.showmodelUnAuthorizationEU}
					userGroupId={this.state.userGroupId}
					hidemodel={_self.hidemodel}
					disabled={_self.state.disabled}
					submitmodel={_self.submitmodel}
				/>) : ('')}

				{this.state.enableUnAuthzCPModal ? (<UnGrantCP
					showmodelUnAuthorization={_self.state.showmodelUnAuthorizationCP}
					userGroupId={this.state.userGroupId}
					hidemodel={_self.hidemodel}
					disabled={_self.state.disabled}
					submitmodel={_self.submitmodel}
				/>) : ('')}

				{/* 添加用户至用户组 */}
				<Modal
					title="添加用户"
					centered
					closeIcon={
						<ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
					}
					width={354}
					maskClosable={false}
					visible={this.state.adduser}
					onOk={() => _self.adduser(false)}
					onCancel={() => _self.adduser(false)}
					footer={[

						<ABB.Button
							onClick={() => _self.adduser(false)}
							type="discreet-black"
							sizeClass='small' theme="filled"
							text="取消"
						/>,
						<ABB.Button
							onClick={() => _self.invite()}
							type="primary-blue"
							disabled={_self.state.disabled}
							sizeClass='small' theme="filled"
							text="确定"
						/>,
					]}
				>
					<div className='model_list'>
						<div className='model_title'>用户角色</div>
						<Select
							placeholder="用户角色"
							suffixIcon={
								<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
							}
							value={_self.state.roleId}
							style={{ 'width': '100%' }}
							onChange={_self.roleChange}
						>
							{
								_self.state.roledata.map((item, index) =>
									item.id !== 2 ? (
										<Select.Option value={item.id}>{item.name}</Select.Option>
									) : ''
								)
							}
						</Select>
					</div>
					<div className='model_list'>
						<div className='model_title'>手机号</div>
						<AutoComplete
							dataSource={dataSource.map(_self.renderOption)}
							className="model_input"
							style={{ width: '100%' }}
							value={_self.state.userval}
							// onSearch={_self.Fnsearch}
							onChange={_self.onChangeSerch}
							placeholder="手机号"
						/>
					</div>

				</Modal>
			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	selectUsergroup: data => {
		dispatch(selectUsergroup(data));
	},
	setUserInfo: data => {
		dispatch(setUserInfo(data))
	},
	setserchUser: data => {
		dispatch(setserchUser(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EUuser));