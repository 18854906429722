//设置专家服务弹框
export const CONTACT_MODEL_CONFIG = "organization/CONTACT_MODEL_CONFIG";


//设置专家服务弹框
export const REMIND_UPDATE_CONTACT_MODEL_CONFIG = "organization/REMIND_UPDATE_CONTACT_MODEL_CONFIG";

//退出组织弹框
export const ORGANIZATION_LOGOUT = "organization/ORGANIZATION_LOGOUT";

//组织行业列表
export const ORGANIZATION_INDUSTRY = "organization/ORGANIZATION_INDUSTRY";

//显示用组织行业列表
export const ORGANIZATION_SHHOWINDUSTRY = "organization/ORGANIZATION_SHHOWINDUSTRY";

//显示全部行业
export const ORGANIZATION_INDUSTRIES_MODEL_CONNFIG = "organization/ORGANIZATION_INDUSTRIES_MODEL_CONNFIG";

//编辑组织洗洗
export const ORGANIZATION_EDIT = "organization/ORGANIZATION_EDIT";

//工具箱编辑组织信息
export const TOOL_ORGANIZATION_EDIT = "tool/ORGANIZATION_EDIT";

//工具箱位置管理列表
export const TOOL_ASSET_LOCATIONS = "tool/TOOL_ASSET_LOCATIONS";
 
//设置专家服务弹框
export const DEBUGING_LIST = "debuging/DEBUGING_LIST";


