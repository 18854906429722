import * as actionTypes from './constants';


const setContactConfig = data => {
	return {
		type: actionTypes.CONTACT_MODEL_CONFIG,
		data
	};
};
 
const setRemindUpdateContactConfig = data => {
	return {
		type: actionTypes.REMIND_UPDATE_CONTACT_MODEL_CONFIG,
		data
	};
};
 
const setDebugingList = data => {
	return {
		type: actionTypes.DEBUGING_LIST,
		data
	};
};
 
const setLogoutOrganizationConfig = data => {
	return {
		type: actionTypes.ORGANIZATION_LOGOUT,
		data
	};
};

const setIndustryList = data =>{
	return {
		type: actionTypes.ORGANIZATION_INDUSTRY,
		data
	};
};
 
const setShowIndustryList = data =>{
	//筛选后显示使用
	return {
		type: actionTypes.ORGANIZATION_SHHOWINDUSTRY,
		data
	};
}
 
//显示所有行业
const setShowIndustriesConfig = data =>{
	 
	return {
		type: actionTypes.ORGANIZATION_INDUSTRIES_MODEL_CONNFIG,
		data
	};
}
 
//编辑组织信息
const setEditOrgConfig = data =>{
 
	return {
		type: actionTypes.ORGANIZATION_EDIT,
		data
	};
}

 //工具箱编辑组织信息
const setToolEditOrgConfig = data =>{
 
	return {
		type: actionTypes.TOOL_ORGANIZATION_EDIT,
		data
	};
}
 
  //工具箱位置管理列表
const setToolAssetLocations = data =>{
 
	return {
		type: actionTypes.TOOL_ASSET_LOCATIONS,
		data
	};
}
 
export { setContactConfig,setRemindUpdateContactConfig,
	setDebugingList,setLogoutOrganizationConfig,
	setIndustryList,setShowIndustryList,
	setShowIndustriesConfig,setEditOrgConfig,
	setToolEditOrgConfig,setToolAssetLocations};