import React, { Component } from 'react';
import { List, Icon, Select, message, Spin } from 'antd';
// import $axios from '../../axios/$axios';
import { connect } from 'react-redux';
import { selectequipment, selectUsergroup } from '@/redux/actions/userlist';
import { loading } from '@/redux/actions/setting';
import { withRouter } from 'react-router-dom';
import { setAssetDetail, setAssetlabel, setAssetIdCopy, setAssetIsPowertrain, setTrendsData, setAssetStatus, setevent, setassettype, setassetId, eventDetaildevice, checkAllgen, deviceDetail, deviceTime, setselect, setFilterDevice, userCheckDevice } from '@/redux/actions/device';
import moment from 'moment';
import { FndeviceDetail, FntrendsCharts } from '@/utils/devicesData';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
import SvgAbbTrend316 from '@/components/trend3_16';
import { setCBMtype } from '@/redux/actions/cbm';
import { setEventDetailStatus } from '@/redux/actions/event';

const { Option } = Select;
const equilist_search = {
	display: 'flex',
	justifyContent: 'space-between',
	flexFlow: 'row',
	margin: '7px 0px 10px 9px',
	fontSize: '14px',
	color: '#3D3D3D',
	position: 'absolute',
	top: '12px',
	left: '0px',
	width: '246px'
}
const equ_list_icon = {
	display: 'flex',
	justifyContent: 'space-between',
}

class EquuipmentList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			clickIcon: 0,
			selectIcon: [],
			listData: this.props.listData,
			modal2Visible: false,
			selectedRowKeys: [],
			iconstyle: '',
			devicename: 0,
			assetClass: '',
			isloading: true,
			listheight: (document.body.clientHeight - 220) + "px"
		};
	}

	UNSAFE_componentWillMount() {
		// this.fetch();
		window.addEventListener('resize', this.handleSize);
	}

	componentWillUnmount() {
		// componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
		window.removeEventListener('resize', this.handleSize);
		this.setState = () => {
			return;
		};
	}
	// 自适应浏览器的高度
	handleSize = () => {
		this.setState({
			listheight: (document.body.clientHeight - 220) + "px"
		})

	}
	setModal2Visible(modal2Visible) {
		this.setState({ modal2Visible });
	}
	getDateTime = () => {
		let currentUTCTimeString = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss")
		let dateUTCTimeString = moment(new Date(new Date().toLocaleDateString())).utc().format("YYYY-MM-DD HH:mm:ss");


		this.props.deviceTime({
			startime: dateUTCTimeString,
			endtime: currentUTCTimeString,
		})
	}



	//根据id获取详情
	FndeviceDetail(data) {
		let that = this;
		if (this.state.isloading === true) {
			this.setState({
				isloading: false
			})
			setTimeout(() => {
				this.setState({
					isloading: true
				})
			}, 1000)
			that.props.setEventDetailStatus({
				startime: '',
				endtime: '',
				page: 1,
				EventType: 0,
				eventName: '',
				forComment: true,
				forEventRecord: false,
				group: false
			})
			this.props.setAssetIdCopy(data.id);
			this.props.setAssetIsPowertrain(false)
			that.props.setassetId(data.id);
			// this.props.setAssetDetail({
			// 	deviceName: data.assetName,
			// 	sn: data.assetSrNo
			// })
			that.props.setassettype(data.type);
			this.props.setCBMtype("fan");
			this.props.checkAllgen("0")//默认0不显示状态详情
			this.props.eventDetaildevice("0")//默认0不显示事件详情
			this.props.setAssetStatus(data.subscriptionStatus)
			if (that.props.getassetId !== data.id) {
				this.props.deviceDetail({})
				this.props.setTrendsData([]);
				this.props.setevent({})
			}
			if (data.type === 'PowerTrain') {
				that.props.userCheckDevice(2)//跳转传动链详情
			}
			else {
				that.props.userCheckDevice(0)//普通设备详情	

			}

		}
		else {
			// message.warning("操作过于频繁")
		}
	}

	//设备列表输入筛选
	updateDeviceArr = (data) => {
		let _self = this
		let { equipmentdata } = _self.props;
		const _equipmentdata = JSON.parse(JSON.stringify(equipmentdata));
		let objnull = (JSON.stringify(equipmentdata) === "{}")
		if (objnull !== false) {
			return false;
		}
		if (data.length > 0) {
			_self.props.setselect(1);
		} else {
			_self.props.setselect(0);
		}
		let assetArr = []
		for (let j = 0; j < _equipmentdata.length; j++) {
			let equipmentdataDevices = _equipmentdata[j];
			let deviceArr = []
			//检查原始数据中是否有该内容
			for (let i = 0; i < equipmentdataDevices.assets.length; i++) {
				let devices = equipmentdataDevices.assets[i]
				let condition = devices.condition
				let contants = true;
				let _conditionTag = false;
				for (let m = 0; m < condition.length; m++) {
					if (condition[m].type === "overall") {
						continue;
					}
					if (_self.state.devicename === 0) {
					}
					else {
						if (Number(condition[m].index) !== _self.state.devicename) {
							continue;
						}
					}

					for (let n = 0; n < data.length; n++) {
						let item = data[n];

						if (item === condition[m].value) {

							_conditionTag = true;
							break;
						}
					}



				}
				if (_conditionTag === false) {
					contants = false;
				}

				if (contants === true) {
					var _deviceTag = deviceArr.indexOf(devices)
					if (_deviceTag === -1) {
						deviceArr.push(devices)
					}
				}

			}
			if (deviceArr.length > 0) {
				equipmentdataDevices.assets = deviceArr
				assetArr.push(equipmentdataDevices)
			}

		}
		_self.props.setFilterDevice(assetArr)
	}
	//设备list条件筛选
	select_device = (data) => {
		let _self = this;
		var _selectTag = _self.state.selectIcon.indexOf(data)
		if (_selectTag === -1) {
			if (data === 3) {
				_self.setState({
					clickIcon5: data
				})
			}
			if (data === 4) {
				_self.setState({
					clickIcon4: data
				})
			}
			if (data === 5) {
				_self.setState({
					clickIcon3: data
				})
			}
			if (data === 2) {
				_self.setState({
					clickIcon2: data
				})
			}
			_self.setState({
				selectIcon: _self.state.selectIcon.concat(data)
			}, () => {
				_self.updateDeviceArr(_self.state.selectIcon)
			}
			)
		} else {
			if (data === 3) {
				_self.setState({
					clickIcon5: 0
				})
			}
			if (data === 4) {
				_self.setState({
					clickIcon4: 0
				})
			}
			if (data === 5) {
				_self.setState({
					clickIcon3: 0
				})
			}
			if (data === 2) {
				_self.setState({
					clickIcon2: 0
				})
			}
			_self.state.selectIcon.splice(_selectTag, 1)
			_self.updateDeviceArr(_self.state.selectIcon)
		}
	}
	//设备详情数据icon渲染
	iconlist = (data) => {
		// value = 1:   不显示图标 (notapplicable)
		// value = 2:   图标灰色 (unknown)
		// value = 3:   图标绿色 (good)
		// value = 4:   图标黄色 (tolerable)
		// value = 5:   图标红色 (poor)
		if (data.index === 5) {
			if (data.value === 5) {
				return (
					<Icon style={{ 'color': '#F03040', 'margin': '0 4px' }} component={SvgAbbTrend316} />
				)
			}
			else if (data.value === 4) {
				return (
					<Icon style={{ 'color': '#FFD800', 'margin': '0 4px' }} component={SvgAbbTrend316} />
				)
			}
			else if (data.value === 3) {
				return (
					<Icon style={{ 'color': '#0CA919', 'margin': '0 4px' }} component={SvgAbbTrend316} />
				)
			}
			else if (data.value === 2) {
				return (
					<Icon style={{ 'color': '#BABABA', 'margin': '0 4px' }} component={SvgAbbTrend316} />
				)
			}

		}
		else if (data.index === 4) {
			if (data.value === 5) {
				return (
					<ABB.Icon style={{ 'color': '#F03040', 'margin': '0 4px' }} name="abb/pie-chart" sizeClass='small' />
				)
			}
			else if (data.value === 4) {
				return (
					<ABB.Icon style={{ 'color': '#FFD800', 'margin': '0 4px' }} name="abb/pie-chart" sizeClass='small' />
				)
			}
			else if (data.value === 3) {
				return (
					<ABB.Icon style={{ 'color': '#0CA919', 'margin': '0 4px' }} name="abb/pie-chart" sizeClass='small' />
				)
			}
			else if (data.value === 2) {
				return (
					<ABB.Icon style={{ 'color': '#BABABA', 'margin': '0 4px' }} name="abb/pie-chart" sizeClass='small' />
				)
			}

		}
		else if (data.index === 3) {
			if (data.value === 5) {
				return (
					<ABB.Icon style={{ 'color': '#F03040', 'margin': '0 4px' }} name="abb/temperature" sizeClass='small' />
				)
			}
			else if (data.value === 4) {
				return (
					<ABB.Icon style={{ 'color': '#FFD800', 'margin': '0 4px' }} name="abb/temperature" sizeClass='small' />
				)
			}
			else if (data.value === 3) {
				return (
					<ABB.Icon style={{ 'color': '#0CA919', 'margin': '0 4px' }} name="abb/temperature" sizeClass='small' />
				)
			}
			else if (data.value === 2) {
				return (
					<ABB.Icon style={{ 'color': '#BABABA', 'margin': '0 4px' }} name="abb/temperature" sizeClass='small' />
				)
			}

		}
		else if (data.index === 2) {
			if (data.value === 5) {
				return (
					<ABB.Icon style={{ 'color': '#F03040', 'margin': '0 4px' }} name="abb/warning-circle-2" sizeClass='small' />
				)
			}
			else if (data.value === 4) {
				return (
					<ABB.Icon style={{ 'color': '#FFD800', 'margin': '0 4px' }} name="abb/warning-circle-2" sizeClass='small' />
				)
			}
			else if (data.value === 3) {
				return (
					<ABB.Icon style={{ 'color': '#0CA919', 'margin': '0 4px' }} name="abb/warning-circle-2" sizeClass='small' />
				)
			}
			else if (data.value === 2) {
				return (
					<ABB.Icon style={{ 'color': '#BABABA', 'margin': '0 4px' }} name="abb/warning-circle-2" sizeClass='small' />
				)
			}

		}

	}
	//设备列表设备类型icon(16)渲染
	assetIcon16 = (data) => {
		if (data.type === 'Motor') {
			return (
				<ABB.Icon className="asset_icon" name="abb/motor" sizeClass='small' />
			)
		}
		else if (data.type === 'Drive') {
			return (
				<ABB.Icon className="asset_icon" name="abb/drive" sizeClass='small' />
			)
		}
		else if (data.type === 'PowerTrain') {
			return (
				<ABB.Icon className="asset_icon" name="abb/powertrain" sizeClass='small' />
			)
		}
	}
	select_devicename = (e) => {
		this.setState({
			devicename: Number(e),
			clickIcon2: 0,
			clickIcon3: 0,
			clickIcon4: 0,
			clickIcon5: 0,
			selectIcon: []
		}, () => {
			this.updateDeviceArr([]);
		})
	}
	render() {
		let _self = this;
		const { clickIcon5, clickIcon4, clickIcon3, clickIcon2, listheight } = _self.state;
		let { equipmentdata, getFilterDevice, getselect, isloading } = _self.props;

		return (
			<div className="device_list_box">

				<div style={equilist_search}>
					<Select defaultValue="所有指数"
						suffixIcon={
							<ABB.Icon style={{ color: '#333333', width: 10 }} name="abb/caret-down" sizeClass='small' />
						}
						style={{ 'border': 'none', 'width': '135px' }}
						onChange={_self.select_devicename}>
						<Option value="0">所有指数</Option>
						<Option value="2">可用性</Option>
						<Option value="3">环境</Option>
						<Option value="4">可靠性</Option>
						<Option value="5">运行压力</Option>
					</Select>
					<div className='equ_search_li'>
						<div
							className={clickIcon5 === 3 ? "equ_search_li_select equ_search_li_select_click" : "equ_search_li_select"}
							onClick={() => _self.select_device(3)}>
							<div className="equ_search_li_div"></div>
						</div>
						<div
							className={clickIcon4 === 4 ? "equ_search_li_select equ_search_li_select_click" : "equ_search_li_select"}
							onClick={() => _self.select_device(4)}>
							<div className="equ_search_li_div"></div>
						</div>
						<div
							className={clickIcon3 === 5 ? "equ_search_li_select equ_search_li_select_click" : "equ_search_li_select"}
							onClick={() => _self.select_device(5)}>
							<div className="equ_search_li_div"></div>
						</div>
						<div
							className={clickIcon2 === 2 ? "equ_search_li_select equ_search_li_select_click" : "equ_search_li_select"}
							onClick={() => _self.select_device(2)}>
							<div className="equ_search_li_div"></div>
						</div>
					</div>
				</div>
				<Spin spinning={isloading} style={{ height: "90vh" }}>


					{
						getselect === 1 ? (
							<div className="device_scroll org_right" style={{ height: listheight }}>
								{
									getFilterDevice.length > 0 ? (
										getFilterDevice.map(function (items, index) {
											return (
												<>
													<List
														header={
															<div className='asset_list_title'>
																{items.name}
															</div>
														}
														key={index}
														split='false'
														size='small'
														className="list-news"
														dataSource={items.assets}
														renderItem={item => (
															<List.Item key={item.id}
																className={_self.props.getassetId === item.id ? "asset_list_active" : ""}
																onClick={() => _self.FndeviceDetail(item)}>

																<div
																	className="list-time"
																>
																	{_self.assetIcon16(item)}
																	{item.assetNamestr}
																	{/* {
																		item.devices!==undefined ? (
																			item.devices.map((items,index)=>{
																				return (
																					<div className='devicePovertrain' onClick={()=>_self.FndeviceDetail(items.id)}>{items.assetName}</div>
																				)
																				
																			})
																		): ''
																	} */}
																</div>
																{
																	item.subscriptionStatus == "已过期" ? (
																		<div style={equ_list_icon} style={{ border: '1px solid #ff0000', padding: '0 6px', color: '#ff0000', marginLeft: 6, borderRadius: 2, }}>订阅已到期</div>
																	) : (
																		<div style={equ_list_icon}>
																			{
																				item.condition.map(function (conitem, index) {
																					return (
																						<div key={index}>
																							{/* {conitem.index}-{conitem.value} */}
																							{
																								_self.iconlist(conitem)
																							}
																						</div>
																					)
																				})
																			}
																		</div>
																	)
																}

															</List.Item>
														)}
													/>
												</>
											)
										})
									) : <div className="renderEmpty renderEmpty_flex" >
										<div className="renderEmpty_center">
											<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
											<p>暂无数据</p>
										</div>
									</div>

								}
							</div>

						) : (
							<div className="device_scroll org_right" style={{ height: listheight }}>
								{
									equipmentdata.length > 0 ? (
										equipmentdata.map(function (items, index) {
											return (
												<>
													{
														items.assets.length > 0 ? (
															<List
																header={
																	<div className='asset_list_title'>
																		{items.name}
																	</div>
																}
																key={index}
																split='false'
																size='small'
																className="list-news"
																dataSource={items.assets}
																renderItem={item => (
																	<List.Item key={item.id} className={_self.props.getassetId === item.id ? "asset_list_active" : ""}
																		onClick={() => _self.FndeviceDetail(item)}>

																		<div
																			className="list-time"
																		>
																			{_self.assetIcon16(item)}
																			{item.assetNamestr}

																		</div>
																		{
																			item.subscriptionStatus == "已过期" ? (
																				<div style={equ_list_icon} style={{ border: '1px solid #ff0000', padding: '0 6px', color: '#ff0000', marginLeft: 6, borderRadius: 2, }}>订阅已到期</div>
																			) : (
																				<div style={equ_list_icon}>
																					{
																						item.condition.map(function (conitem, index) {
																							return (
																								<div key={index}>
																									{/* {conitem.index}-{conitem.value} */}
																									{
																										_self.iconlist(conitem)
																									}
																								</div>
																							)
																						})
																					}
																				</div>
																			)
																		}
																	</List.Item>
																)}
															/>
														) : ''
													}
												</>
											)


										})
									) : <div className="renderEmpty renderEmpty_flex" >
										<div className="renderEmpty_center">
											<ABB.Icon style={{ margin: '10px 0 16px 8px' }} name="abb/event" sizeClass='large' />
											<p>暂无数据</p>
										</div>
									</div>

								}
							</div>
						)
					}

				</Spin>

			</div>
		);
	}
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
	setAssetDetail: data => {
		dispatch(setAssetDetail(data));
	},
	setAssetlabel: data => {
		dispatch(setAssetlabel(data));
	},
	setAssetIdCopy: data => {
		dispatch(setAssetIdCopy(data));
	},
	setAssetIsPowertrain: data => {
		dispatch(setAssetIsPowertrain(data));
	},
	setTrendsData: data => {
		dispatch(setTrendsData(data));
	},
	setAssetStatus: data => {
		dispatch(setAssetStatus(data));
	},
	selectequipment: data => {
		dispatch(selectequipment(data));
	},
	setEventDetailStatus: data => {
		dispatch(setEventDetailStatus(data))
	},
	deviceDetail: data => {
		dispatch(deviceDetail(data));
	},
	selectUsergroup: data => {
		dispatch(selectUsergroup(data));
	},
	deviceTime: data => {
		dispatch(deviceTime(data))
	},
	loading: data => {
		dispatch(loading(data))
	},
	setCBMtype: data => {
		dispatch(setCBMtype(data))
	},
	setselect: data => {
		dispatch(setselect(data))
	},
	setFilterDevice: data => {
		dispatch(setFilterDevice(data))
	},
	checkAllgen: data => {
		dispatch(checkAllgen(data))
	},
	eventDetaildevice: data => {
		dispatch(eventDetaildevice(data))
	},
	userCheckDevice: data => {
		dispatch(userCheckDevice(data))
	},
	setassetId: data => {
		dispatch(setassetId(data))
	},
	setassettype: data => {
		dispatch(setassettype(data))
	},
	setevent: data => {
		dispatch(setevent(data))
	}
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(EquuipmentList));

// export default EquuipmentList;


