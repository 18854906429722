import React, { Component } from 'react';
import { Modal, Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import { setUserInfo } from '@/redux/actions/userInfo';
import { withRouter } from 'react-router-dom';
import 'abb-common-ux-react/styles.css';
import * as ABB from 'abb-common-ux-react';
const { Search, TextArea } = Input;
class EventModel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputval: '',
            disabled: false
        };

    }

    UNSAFE_componentWillMount() {
        // this.fetch();
    };
    componentWillUnmount() {
        // componentWillMount进行异步操作时且在callback中进行了setState操作时，需要在组件卸载时清除state
        this.setState = () => {
            return;
        };
    }
    hidemodel = (data) => {
        this.props.hidemodel(data)
    }
    submitmodel = (data) => {
       
        this.props.submitmodel(data)
    }
    onChange = (e) => {
        if (e.target.value.length > 200) {
            message.warning("输入内容不能超过200")
            this.setState({
                disabled: true
            })
            return false
        }
        this.setState({
            disabled: false,
            inputval: e.target.value
        })
    }
    ondeviceDetail = () => {
        this.props.ondeviceDetail()
    }
    render() {
        const that = this;
        const { showmodel, userInfo } = this.props;
        const modelProps = {
            title: showmodel.title,
            centered: showmodel.centered,
            width: showmodel.width,
            maskClosable: false,
            visible: showmodel.visible,
            onOk: () => { that.hidemodel(false) },
            onCancel: () => { that.hidemodel(false) },
            footer: [
                <>
                    {
                        showmodel.eventstatus === 1 ? (
                            <span
                                style={{
                                    color: '#36f',
                                    lineHeight: '32px',
                                    position: 'absolute',
                                    left: '25px',
                                    bottom: '8px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => this.ondeviceDetail()}
                            >设备详情</span>

                        ) : ''
                    }
                </>,
                <ABB.Button
                    onClick={() => that.hidemodel(false)}
                    type="discreet-black"
                    sizeClass='small' theme="filled"
                    text="取消"
                />,
                <>
                    {
                        showmodel.assetEventStatus === 'Open' ? (
                            <ABB.Button
                                disabled={that.state.disabled}
                                onClick={() => that.submitmodel(that.state.inputval)}
                                type="primary-blue"
                                sizeClass='small' theme="filled"
                                text="确定"
                            />
                        ) : (
                            <ABB.Button
                                onClick={() => that.hidemodel(false)}
                                type="primary-blue"
                                sizeClass='small' theme="filled"
                                text="确定"
                            />
                        )
                    }
                </>
            ]
        }
        return (
            <Modal {...modelProps}
                closeIcon={
                    <ABB.Icon style={{ color: '#1F1F1F' }} className="closeButton" name="abb/close" sizeClass='medium' />
                }>
                {/* <div className="eventTitle">
                    事件描述
                </div> */}
                <div className="eventTitle" style={{ color: '#1F1F1F' }}>
                    {showmodel.value}
                </div>

                {/* {
                    showmodel.assetEventStatus === 'Open' ? (
                        <>
                            <div className="eventTitle">处理方案：</div>
                            <TextArea
                                className="model_input"
                                onChange={this.onChange}
                                rows={4}
                                placeholder='请输入200字以内'
                                disabled={userInfo.organizationSubType === "Float" ? true : false} />

                        </>

                    ) : (
                        <>
                            <div className="eventTitle">处理方案：</div>
                            <div className="eventTitle" style={{ color: '#1F1F1F' }}>{showmodel.closeReason}</div>
                            <div className="eventTitle" style={{ fontSize: 10, color: '#686868' }}>{showmodel.closeUser}</div>
                        </>
                    )
                } */}

            </Modal>
        );
    }
}
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    setUserInfo: data => {
        dispatch(setUserInfo(data))
    }
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EventModel));
