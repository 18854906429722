import * as actionTypes from '../constants/index';
import moment from 'moment';

const mydeviceDetail = {}
const _checkAll = '0';//电机详情
const _eventDetail = '0';//事件i详情
const _UsercheckDeviceDetail = 0;

let currentUTCTimeString = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss")
let dateUTCTimeString = moment(new Date(new Date().toLocaleDateString())).utc().format("YYYY-MM-DD HH:mm:ss");

const _deviceTrendtime = {
	startime: dateUTCTimeString,
	endtime: currentUTCTimeString,
}

const _getselect = 0;
const _gettree = []
const _gettreeCopy = [];
const _gettreeValue = '';
const _getTopdevice = false;
const _getFilterDevice = []
const _getassetId = '';
const _getassettype = ''
const _getBatchasset = [];
const _gettreeStatus = [];
const _gettreeSelect = {
	selectedKeys: [],
	isselectId: ''
}

const _defaultInspection = {
	dataLifespan: '',
	inspections: [],
	timestamp: "",
	disabled: true,
	isLoading: false,
	isRequestFail: false,
	isRequestSuccess: false,
	errorMessage:"",
	disabled:false,
	requestTime:0
}

const getActiveTab = (state = 0, action) => {
	switch (action.type) {
		case actionTypes.ACTIVE_TAB:
			return action.data;
		default:
			return state;
	}
};

const getassettype = (state = _getassettype, action) => {
	switch (action.type) {
		case actionTypes.SET_ASSETTYPE:
			return action.data;
		default:
			return state;
	}
};

const getResTrendsCode = (state = '', action) => {
	switch (action.type) {
		case actionTypes.RES_TRENDSCODE:
			return action.data;
		default:
			return state;
	}
};

const getTrendEventTag = (state = '', action) => {
	switch (action.type) {
		case actionTypes.TREND_EVENTTAG:
			return action.data;
		default:
			return state;
	}
};

const getTrendEventcode = (state = [], action) => {
	switch (action.type) {
		case actionTypes.TREND_EVENTCODE:
			return action.data;
		default:
			return state;
	}
};

const getBatchImport = (state = 0, action) => {
	switch (action.type) {
		case actionTypes.BATCHIMPORT:
			return action.data;
		default:
			return state;
	}
};

const gettreeCopy = (state = _gettreeCopy, action) => {
	switch (action.type) {
		case actionTypes.SET_TREECOPY:
			return action.data;
		default:
			return state;
	}
};
const gettreeValue = (state = _gettreeValue, action) => {
	switch (action.type) {
		case actionTypes.SET_TREVALUE:
			return action.data;
		default:
			return state;
	}
};

const gettrendscode = (state = [], action) => {
	switch (action.type) {
		case actionTypes.SET_TRENDSCODE:
			return action.data;
		default:
			return state;
	}
};
const gettrendstag = (state = '', action) => {
	switch (action.type) {
		case actionTypes.SET_TRENDSTAG:
			return action.data;
		default:
			return state;
	}
};

const gettreeStatus = (state = _gettreeStatus, action) => {
	switch (action.type) {
		case actionTypes.SET_TREESTATUS:
			return action.data;
		default:
			return state;
	}
};

const gettreeSelect = (state = _gettreeSelect, action) => {
	switch (action.type) {
		case actionTypes.SET_TREESELECT:
			return action.data;
		default:
			return state;
	}
};

const getassetId = (state = _getassetId, action) => {
	switch (action.type) {
		case actionTypes.SET_ASSETID:
			return action.data;
		default:
			return state;
	}
};

const getevent = (state = [], action) => {
	switch (action.type) {
		case actionTypes.SET_EVENT:
			return action.data;
		default:
			return state;
	}
};

const getFilterDevice = (state = _getFilterDevice, action) => {
	switch (action.type) {
		case actionTypes.SET_FILTERDEVICE:
			return action.data;
		default:
			return state;
	}
};
const getTopdevice = (state = _getTopdevice, action) => {
	switch (action.type) {
		case actionTypes.SET_TOPDEVICE:

			return action.data;
		default:
			return state;
	}
};
const _deviceDetail = (state = mydeviceDetail, action) => {
	switch (action.type) {
		case actionTypes.DEVICE_DETAIL:

			return action.data;
		default:
			return state;
	}
};
const UsercheckDeviceDetail = (state = _UsercheckDeviceDetail, action) => {
	switch (action.type) {
		case actionTypes.USER_CHECK_DETAIL:
			return action.data;
		default:
			return state;
	}
};
const checkAll = (state = _checkAll, action) => {
	switch (action.type) {
		case actionTypes.CHECKALL:
			return action.data;
		default:
			return state;
	}
};
const eventDetail = (state = _eventDetail, action) => {
	switch (action.type) {
		case actionTypes.EVENT_DETAIL:
			return action.data;
		default:
			return state;
	}
};
const deviceTrendtime = (state = _deviceTrendtime, action) => {
	switch (action.type) {
		case actionTypes.DEVICE_TIME:
			return action.data;
		default:
			return state;
	}
};
const getselect = (state = _getselect, action) => {
	switch (action.type) {
		case actionTypes.GET_SELECT:
			return action.data;
		default:
			return state;
	}
};

const gettree = (state = _gettree, action) => {
	switch (action.type) {
		case actionTypes.SET_TREE:
			return action.data;
		default:
			return state;
	}
};

const getBatchasset = (state = _getBatchasset, action) => {
	switch (action.type) {
		case actionTypes.SET_BATCHASSET:
			return action.data;
		default:
			return state;
	}
};

const getChartsTime = (state = { startime: new Date(new Date().toLocaleDateString()), endtime: new Date() }, action) => {
	switch (action.type) {
		case actionTypes.CHARTS_TIME:
			return action.data;
		default:
			return state;
	}
};

const getsubscriptioncof = (state = [], action) => {
	switch (action.type) {
		case actionTypes.SET_SUBSCRIPTIONCOF:
			return action.data;
		default:
			return state;
	}
};
const getRadiovalue = (state = 1, action) => {
	switch (action.type) {
		case actionTypes.RADIOVALUE:
			return action.data;
		default:
			return state;
	}
};

const getTrendsData = (state = [], action) => {
	switch (action.type) {
		case actionTypes.TRENDSDATA:
			return action.data;
		default:
			return state;
	}
};

const getAssetStatus = (state = '', action) => {
	switch (action.type) {
		case actionTypes.ASSETSTATUS:
			return action.data;
		default:
			return state;
	}
};

const getAssetIsPowertrain = (state = false, action) => {
	switch (action.type) {
		case actionTypes.ASSET_ISPOWERTRAIN:
			return action.data;
		default:
			return state;
	}
};

const getAssetIdCopy = (state = '', action) => {
	switch (action.type) {
		case actionTypes.ASSET_IDCOPY:
			return action.data;
		default:
			return state;
	}
};

const getAssetlabel = (state = ['设备详情'], action) => {
	switch (action.type) {
		case actionTypes.ASSET_LABEL:
			return action.data;
		default:
			return state;
	}
};

const getAssetDetail = (state = { deviceName: '', sn: '' }, action) => {
	switch (action.type) {
		case actionTypes.ASSET_DETAIL:
			return action.data;
		default:
			return state;
	}
};


//设备巡检数据
const getInspection = (state = _defaultInspection, action) => {
	switch (action.type) {
		case actionTypes.INSPECTION:
			return action.data;
		default:
			return state;
	}
}

 
export {
	 
	getAssetDetail,
	getAssetlabel,
	getBatchImport,
	getAssetIdCopy,
	getAssetIsPowertrain,
	getAssetStatus,
	getTrendsData,
	getResTrendsCode,
	getRadiovalue,
	getsubscriptioncof,
	getChartsTime,
	gettreeValue,
	gettreeCopy,
	gettreeSelect,
	gettreeStatus,
	getBatchasset,
	getassettype,
	getevent,
	getassetId,
	getFilterDevice,
	gettree,
	_deviceDetail,
	eventDetail,
	checkAll,
	deviceTrendtime,
	UsercheckDeviceDetail,
	getselect,
	getTopdevice,
	gettrendscode,
	gettrendstag,
	getTrendEventcode,
	getTrendEventTag,
	getActiveTab,
	getInspection

};